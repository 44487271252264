import {useQuery} from "@tanstack/react-query";
import {BusinessesApi, FavoriteRestaurantsResponse, Token} from "@devour/client";
import getConfig from "@/utils/getConfig";

export const USE_GET_FAVOURITE_RESTAURANTS_KEY = "favourite-restaurants";

async function fetchFavouriteRestaurants(): Promise<FavoriteRestaurantsResponse> {
    return await new BusinessesApi(getConfig()).getFavoriteRestaurants();
}

function favouriteRestaurantsQuery(fullToken: Token) {
    return {
        queryKey: [
            USE_GET_FAVOURITE_RESTAURANTS_KEY,
            fullToken?.id,
        ],
        queryFn: () => fetchFavouriteRestaurants(),
        staleTime: 12 * 60 * 60000,
        enabled: !!fullToken,
    } as const;
}

export function useGetFavouriteRestaurants(fullToken: Token) {
    return useQuery(favouriteRestaurantsQuery(fullToken));
}
