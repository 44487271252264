/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CommunityRankingRecord
 */
export interface CommunityRankingRecord {
    /**
     * 
     * @type {number}
     * @memberof CommunityRankingRecord
     */
    month: number;
    /**
     * 
     * @type {number}
     * @memberof CommunityRankingRecord
     */
    year: number;
    /**
     * 
     * @type {number}
     * @memberof CommunityRankingRecord
     */
    monthlyTotalPoints: number;
    /**
     * 
     * @type {number}
     * @memberof CommunityRankingRecord
     */
    grandTotalPoints: number;
    /**
     * 
     * @type {number}
     * @memberof CommunityRankingRecord
     */
    endOfMonthRank: number;
}

export function CommunityRankingRecordFromJSON(json: any): CommunityRankingRecord {
    return CommunityRankingRecordFromJSONTyped(json, false);
}

export function CommunityRankingRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommunityRankingRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'month': json['month'],
        'year': json['year'],
        'monthlyTotalPoints': json['monthlyTotalPoints'],
        'grandTotalPoints': json['grandTotalPoints'],
        'endOfMonthRank': json['endOfMonthRank'],
    };
}

export function CommunityRankingRecordToJSON(value?: CommunityRankingRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'month': value.month,
        'year': value.year,
        'monthlyTotalPoints': value.monthlyTotalPoints,
        'grandTotalPoints': value.grandTotalPoints,
        'endOfMonthRank': value.endOfMonthRank,
    };
}


