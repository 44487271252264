import {EVMChain} from "@devour/client";

export function parseChain(chain: EVMChain): string {
    switch (chain) {
        case EVMChain.ETHEREUMMAINNET:
            return "Ethereum Mainnet";
        case EVMChain.POLYGON:
            return "Polygon";
        case EVMChain.GOERLI:
            return "Goerli";
        case EVMChain.AVALANCHE:
            return "Avalanche";
        case EVMChain.MUMBAI:
            return "Mumbai";
        default:
            return chain;
    }
}

export function getChains(skipTestNets = true) {
    return [
        {
            name: "Ethereum",
            nameShort: "ETH",
            chain: EVMChain.ETHEREUMMAINNET,
        },
        {
            name: "Polygon",
            nameShort: "MATIC",
            chain: EVMChain.POLYGON,
        },
        {
            name: "Avalanche",
            nameShort: "AVAX",
            chain: EVMChain.AVALANCHE,
        },
        ...!skipTestNets ? [{
            name: "Goerli",
            nameShort: "GOERLI",
            chain: EVMChain.GOERLI,
        },
        {
            name: "Mumbai",
            nameShort: "MUMBAI",
            chain: EVMChain.MUMBAI,
        }] : [],
        {
            name: "Base",
            nameShort: "BASE",
            chain: EVMChain.BASE,
        },
        {
            name: "Solana",
            nameShort: "SOL",
            chain: EVMChain.SOLANA,
        },
    ];
}

export function getOpenSeaChainUrl(chain: EVMChain): string {
    switch (chain) {
        case EVMChain.ETHEREUMMAINNET:
            return "ethereum";
        case EVMChain.POLYGON:
            return "matic";
        case EVMChain.GOERLI:
            return "ethereum";
        case EVMChain.AVALANCHE:
            return "avax";
        case EVMChain.MUMBAI:
            return "mumbai";
        case EVMChain.BASE:
            return "base";
        default:
            return chain;
    }
}
