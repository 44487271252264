import {useQuery} from "@tanstack/react-query";
import {MenusApi, GetMenuResponse} from "@devour/client";
import getConfig from "@/utils/getConfig";

async function fetchRestaurantMenu(restaurantId: string, asap): Promise<GetMenuResponse> {
    if (!restaurantId) {
        return;
    }

    return await new MenusApi(getConfig()).getMenu({
        id: restaurantId,
        asap,
    });
}

function restaurantMenuQuery(restaurantId: string, asap: boolean) {
    return {
        queryKey: [
            "restaurant-menu",
            restaurantId,
            asap,
        ],
        queryFn: () => fetchRestaurantMenu(restaurantId, asap),
        keepPreviousData: true,
        staleTime: 60000,
        refetchOnWindowFocus: false,
        enabled: Boolean(restaurantId),
    } as const;
}

export function useRestaurantMenu(restaurantId: string, asap: boolean = false) {
    // const queryClient = useQueryClient();

    return useQuery(restaurantMenuQuery(restaurantId, asap));

}
