/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddNftGroupingBody
 */
export interface AddNftGroupingBody {
    /**
     * 
     * @type {string}
     * @memberof AddNftGroupingBody
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof AddNftGroupingBody
     */
    sortPriority?: number;
    /**
     * 
     * @type {string}
     * @memberof AddNftGroupingBody
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof AddNftGroupingBody
     */
    discordUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof AddNftGroupingBody
     */
    twitterUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof AddNftGroupingBody
     */
    websiteUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof AddNftGroupingBody
     */
    hero?: string;
    /**
     * 
     * @type {string}
     * @memberof AddNftGroupingBody
     */
    icon?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AddNftGroupingBody
     */
    categories?: Array<string>;
}

export function AddNftGroupingBodyFromJSON(json: any): AddNftGroupingBody {
    return AddNftGroupingBodyFromJSONTyped(json, false);
}

export function AddNftGroupingBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddNftGroupingBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'sortPriority': !exists(json, 'sortPriority') ? undefined : json['sortPriority'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'discordUrl': !exists(json, 'discordUrl') ? undefined : json['discordUrl'],
        'twitterUrl': !exists(json, 'twitterUrl') ? undefined : json['twitterUrl'],
        'websiteUrl': !exists(json, 'websiteUrl') ? undefined : json['websiteUrl'],
        'hero': !exists(json, 'hero') ? undefined : json['hero'],
        'icon': !exists(json, 'icon') ? undefined : json['icon'],
        'categories': !exists(json, 'categories') ? undefined : json['categories'],
    };
}

export function AddNftGroupingBodyToJSON(value?: AddNftGroupingBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'sortPriority': value.sortPriority,
        'description': value.description,
        'discordUrl': value.discordUrl,
        'twitterUrl': value.twitterUrl,
        'websiteUrl': value.websiteUrl,
        'hero': value.hero,
        'icon': value.icon,
        'categories': value.categories,
    };
}


