import {AnchorHTMLAttributes, ReactElement, useEffect, useState} from "react";
import FrameButton from "../components/buttons/FrameButton";
import {AiFillRocket} from "react-icons/ai";
import {FiArrowLeft} from "react-icons/fi";
import {GiPresent} from "react-icons/gi";
import {GoGraph} from "react-icons/go";
import FrameOneSelect from "../components/inputs/FrameOneSelect";
import Toast from "@/components/Toast";
import {ToastPosition, ToastVariant} from "@/types/ToastConfig";
import FramePaginator, {FrontendPagination} from "@/components/paginator/FramePaginator.tsx";
import FrameOneSwitchInput from "@/components/inputs/FrameOneSwitchInput.tsx";
import ToggleSwitch from "@/components/ToggleSwitch";
import FrameOneCheckbox from "@/components/inputs/FrameOneCheckbox";
import FrameOneRadio from "@/components/inputs/FrameOneRadio";


function ComponentDemoPage(): ReactElement {
    const [
        showToast,
        setShowToast,
    ] = useState<boolean>(false);
    const [
        toastPosition,
        setToastPosition,
    ] = useState<ToastPosition>(undefined);
    const [
        toastVariant,
        setToastVariant,
    ] = useState<ToastVariant>(undefined);
    const [
        showToastWithoutButton,
        setShowToastWithoutButton,
    ] = useState<boolean>(false);

    const [toggleEnabled, setToggleEnabled] = useState<boolean>(true);
    const [
        paginatorOffset,
        setPaginatorOffset,
    ] = useState(0);
    const [
        paginationTheme,
        setPaginationTheme,
    ] = useState<"dark" | "light">("light");

    useEffect(() => {
        if (toastPosition) {
            setShowToast(true);
        }
    }, [
        toastPosition,
        showToast,
    ]);


    /*
     * If there are multiple Toast positions you want to trigger, it should be set up similarly to this.
     * If you want all Toasts to be in one position, you can just call setShowToast(true) without having
     * to set up the useEffect dependency.
     * You only need to pass in the toastPosition prop to the Toast if you want something other than
     * the default, which is bottom-center.
     */
    function handleShowToast(position?: ToastPosition) {
        if (position) {
            setToastPosition(position);
        } else {
            setToastPosition("bottom-center");
        }
    }

    function handleChangeToastVariant(variant?: ToastVariant, showButton = true) {
        setToastPosition("bottom-center");
        if (!showButton) {
            setShowToastWithoutButton(true);
        }
        if (variant) {
            setToastVariant(variant);
        } else {
            setToastVariant("success");
        }
    }

    function handleToastDismissal() {
        setToastPosition(undefined);
        setToastVariant(undefined);
        setShowToast(false);
        setShowToastWithoutButton(false);
    }

    function handleChangeOffset(newPagination: FrontendPagination) {
        setPaginatorOffset(newPagination.offset);
    }

    function handleChangePaginationTheme(theme) {
        setPaginationTheme(theme);
    }

    function handleToggleChange() {
        setToggleEnabled(!toggleEnabled);
    }


    return (
        <div className="demo-page">
            <h1>
				Component Demo Page
            </h1>


            <div>
                <FrameOneSwitchInput
                    name="demo-theme-options"
                    value={paginationTheme}
                    onToggle={handleChangePaginationTheme}
                    options={[
                        {
                            render: "Dark",
                            value: "dark",
                        },
                        {
                            render: "Light",
                            value: "light",
                        },
                    ]}
                />

                <div className={`demo-page_pagination theme-${paginationTheme}`}>
                    <FramePaginator
                        onPaginationChange={handleChangeOffset}
                        enablePrevious={paginatorOffset > 0}
                        enableNext={paginatorOffset < 9}
                        lowerRange={1}
                        upperRange={10}
                        total={100}
                        availableOffsets={[]}
                        limit={10}
                        offset={paginatorOffset}
                        showPaginatorLimit={false}
                        theme={paginationTheme}
                    />
                </div>
            </div>

            <div className="demo-page_spaced-components">
                <div>
                    <h1>Heading 1 - Hero</h1>
                    <h2>Heading 2 - Title</h2>
                    <h3>Heading 3 - Header</h3>
                    <h4>Heading 4 - Subheader</h4>
                    <h5>Heading 5 - Section Header</h5>
                    <p>P1 Body - Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut in purus lectus. Vivamus
						tempus blandit ante nec efficitur. Proin bibendum diam odio, porttitor iaculis odio congue
						eget.</p>
                    <p className="p2-sub-body">P2 Sub Body - Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
						in purus lectus. Vivamus tempus blandit ante nec efficitur. Proin bibendum diam odio, porttitor
						iaculis odio congue eget.</p>
                    <p className="p3-caption">P3 Caption - Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
						in purus lectus. Vivamus tempus blandit ante nec efficitur. Proin bibendum diam odio, porttitor
						iaculis odio congue eget.</p>
                </div>

                <div className="demo-page_spaced-components_row">
                    <FrameButton
                        <AnchorHTMLAttributes<HTMLAnchorElement>>
                        color="purple"
                        size="large"
                        href="https://www.frameonesoftware.com"
                        forwardProps={{
                            target: "_blank",
                        }}
                    >
						Large CTA Button
                    </FrameButton>

                    <FrameButton
                        color="purple-outline"
                        size="large"
                    >
						Large CTA Button Outline
                    </FrameButton>

                    <FrameButton
                        color="gray"
                        size="large"
                    >
						Large CTA Button Gray
                    </FrameButton>
                </div>

                <div className="demo-page_spaced-components_row">
                    <FrameButton
                        color="purple"
                        size="normal"
                    >
						Primary Button
                    </FrameButton>

                    <FrameButton
                        color="purple-outline"
                        size="normal"
                    >
						Primary Button Outline
                    </FrameButton>

                    <FrameButton
                        color="gray"
                        size="normal"
                    >
						Primary Button Gray
                    </FrameButton>
                </div>

                <div className="demo-page_spaced-components_row">
                    <FrameButton
                        color="purple"
                        size="narrow"
                    >
						Secondary Button
                    </FrameButton>

                    <FrameButton
                        color="purple-outline"
                        size="narrow"
                    >
						Secondary Button Outline
                    </FrameButton>

                    <FrameButton
                        color="gray"
                        size="narrow"
                    >
						Secondary Button Gray
                    </FrameButton>
                </div>

                <div className="demo-page_spaced-components_row">
                    <FrameButton
                        color="purple"
                        size="icon"
                        leftIcon={AiFillRocket}
                    />

                    <FrameButton
                        color="purple-outline"
                        size="icon"
                        leftIcon={GoGraph}
                    />

                    <FrameButton
                        color="gray"
                        size="icon"
                        leftIcon={FiArrowLeft}
                    />

                    <FrameButton
                        color="white-drop-shadow"
                        size="icon"
                        leftIcon={GiPresent}
                    />
                </div>

                <div className="demo-page_spaced-components_row">
                    <div>
                        <label>Standard Input</label>
                        <input
                            placeholder="Placeholder text..."
                        />
                    </div>

                    <div>
                        <label>Disabled Input</label>
                        <input
                            placeholder="Placeholder text..."
                            disabled={true}
                        />
                    </div>
                </div>

                <div className="demo-page_spaced-components_row">
                    <div>
                        <label>Standard Dropdown</label>
                        <FrameOneSelect>
                            <option value={1}>This is an option</option>
                            <option value={2}>This is another option</option>
                        </FrameOneSelect>
                    </div>

                    <div>
                        <label>Disabled Dropdown</label>
                        <FrameOneSelect disabled={true}>
                            <option value={1}>This is an option</option>
                            <option value={2}>This is another option</option>
                        </FrameOneSelect>
                    </div>
                </div>
            </div>

            <div>
                <Toast
                    message="This is a Toast! So cool amirite?!"
                    isOpen={showToast}
                    showButton={!showToastWithoutButton}
                    buttonMessage={showToastWithoutButton
                        ? undefined
                        : "Dismiss"}
                    onDismiss={handleToastDismissal}
                    toastPosition={toastPosition}
                    variant={toastVariant}
                />
                <br />
                <hr />
                <br />
                <h3>Toasts</h3>

                <p>
					Important: If you have multiple sources which toggle the same Toast component, only call the Toast
					ONCE at a time. If you want to show multiple Toasts at once, it would be better to just make multiple components
					and manage the states separately.
                </p>

                <p>
					These positions are limited by what react-hot-toast provides. <a href="https://react-hot-toast.com/">For more info see
					documentation here.</a> If we need to have a custom position beyond these, then they have to be manually
					calculated with margin top/left/etc.
                </p>

                <div style={{display: "flex",
                    alignItems: "center",
                    gap: "1rem",
                    flexWrap: "wrap"}}>
                    <FrameButton
                        color="purple"
                        size="normal"
                        forwardProps={{disabled: showToast}}
                        onClick={() => handleShowToast("top-left")}
                    >
						Top Left
                    </FrameButton>
                    <br />

                    <FrameButton
                        color="purple"
                        size="normal"
                        forwardProps={{disabled: showToast}}
                        onClick={() => handleShowToast("top-center")}
                    >
						Top Center
                    </FrameButton>
                    <br />

                    <FrameButton
                        color="purple"
                        size="normal"
                        forwardProps={{disabled: showToast}}
                        onClick={() => handleShowToast("top-right")}
                    >
						Top Right
                    </FrameButton>
                    <br />

                    <FrameButton
                        color="purple"
                        size="normal"
                        forwardProps={{disabled: showToast}}
                        onClick={() => handleShowToast("bottom-left")}
                    >
						Bottom Left
                    </FrameButton>
                    <br />

                    <FrameButton
                        color="purple"
                        size="normal"
                        forwardProps={{disabled: showToast}}
                        onClick={() => handleShowToast()}
                    >
						Bottom Center (DEFAULT)
                    </FrameButton>
                    <br />

                    <FrameButton
                        color="purple"
                        size="normal"
                        forwardProps={{disabled: showToast}}
                        onClick={() => handleShowToast("bottom-right")}
                    >
						Bottom Right
                    </FrameButton>
                </div>

                <br />
                <h3>Toast Variants</h3>
                <p>Show toast with functional button.</p>
                <div style={{display: "flex",
                    alignItems: "center",
                    gap: "1rem",
                    flexWrap: "wrap"}}>
                    <FrameButton
                        color="purple"
                        size="normal"
                        forwardProps={{disabled: showToast}}
                        onClick={() => handleChangeToastVariant()}
                    >
						Success Toast
                    </FrameButton>
                    <br />

                    <FrameButton
                        color="purple"
                        size="normal"
                        forwardProps={{disabled: showToast}}
                        onClick={() => handleChangeToastVariant("warning")}
                    >
						Warning Toast
                    </FrameButton>
                    <br />

                    <FrameButton
                        color="purple"
                        size="normal"
                        forwardProps={{disabled: showToast}}
                        onClick={() => handleChangeToastVariant("error")}
                    >
						Error Toast
                    </FrameButton>
                    <br />

                    <FrameButton
                        color="purple"
                        size="normal"
                        forwardProps={{disabled: showToast}}
                        onClick={() => handleChangeToastVariant("info")}
                    >
						Information Toast
                    </FrameButton>
                    <br />
                </div>

                <br/>
                <p>Show toast with the default dismissal button.</p>
                <div style={{display: "flex",
                    alignItems: "center",
                    gap: "1rem",
                    flexWrap: "wrap"}}>
                    <FrameButton
                        color="purple"
                        size="normal"
                        forwardProps={{disabled: showToast}}
                        onClick={() => handleChangeToastVariant("success", false)}
                    >
						Success Toast
                    </FrameButton>
                    <br />

                    <FrameButton
                        color="purple"
                        size="normal"
                        forwardProps={{disabled: showToast}}
                        onClick={() => handleChangeToastVariant("warning", false)}
                    >
						Warning Toast
                    </FrameButton>
                    <br />

                    <FrameButton
                        color="purple"
                        size="normal"
                        forwardProps={{disabled: showToast}}
                        onClick={() => handleChangeToastVariant("error", false)}
                    >
						Error Toast
                    </FrameButton>
                    <br />

                    <FrameButton
                        color="purple"
                        size="normal"
                        forwardProps={{disabled: showToast}}
                        onClick={() => handleChangeToastVariant("info", false)}
                    >
						Information Toast
                    </FrameButton>
                    <br />

                </div>

            </div>
            <div>
                <br />
                <h3>Select controls</h3>
                <br />
                <h5>Toggle switch</h5>
                <ToggleSwitch checked={toggleEnabled} onChange={handleToggleChange}/>
                <br />
                <h5>Checkbox</h5>
                <FrameOneCheckbox
                    onToggle={handleToggleChange}
                    checked={toggleEnabled}
                    background={"purple"}
                >
                  Agree
                </FrameOneCheckbox>
                <br />
                <h5>Radio</h5>
                <FrameOneRadio
                    value=""
                    onToggle={handleToggleChange}
                    checked={toggleEnabled}
                    name="externalDpaySelected"
                >
               Radio
                </FrameOneRadio>
            </div>
        </div>
    );
}

export default ComponentDemoPage;
