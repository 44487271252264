/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Address,
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
    BusinessType,
    BusinessTypeFromJSON,
    BusinessTypeFromJSONTyped,
    BusinessTypeToJSON,
    CreateBusinessBodyBase,
    CreateBusinessBodyBaseFromJSON,
    CreateBusinessBodyBaseFromJSONTyped,
    CreateBusinessBodyBaseToJSON,
    HandoffOptions,
    HandoffOptionsFromJSON,
    HandoffOptionsFromJSONTyped,
    HandoffOptionsToJSON,
    PhoneNumberBody,
    PhoneNumberBodyFromJSON,
    PhoneNumberBodyFromJSONTyped,
    PhoneNumberBodyToJSON,
    RestaurantBrandSize,
    RestaurantBrandSizeFromJSON,
    RestaurantBrandSizeFromJSONTyped,
    RestaurantBrandSizeToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreateBusinessBody
 */
export interface CreateBusinessBody extends CreateBusinessBodyBase {
    /**
     * 
     * @type {string}
     * @memberof CreateBusinessBody
     */
    icon?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBusinessBody
     */
    headerImage?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBusinessBody
     */
    stripeAccountId?: string;
}

export function CreateBusinessBodyFromJSON(json: any): CreateBusinessBody {
    return CreateBusinessBodyFromJSONTyped(json, false);
}

export function CreateBusinessBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateBusinessBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...CreateBusinessBodyBaseFromJSONTyped(json, ignoreDiscriminator),
        'icon': !exists(json, 'icon') ? undefined : json['icon'],
        'headerImage': !exists(json, 'headerImage') ? undefined : json['headerImage'],
        'stripeAccountId': !exists(json, 'stripeAccountId') ? undefined : json['stripeAccountId'],
    };
}

export function CreateBusinessBodyToJSON(value?: CreateBusinessBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...CreateBusinessBodyBaseToJSON(value),
        'icon': value.icon,
        'headerImage': value.headerImage,
        'stripeAccountId': value.stripeAccountId,
    };
}


