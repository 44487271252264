/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SimpleHashWebhookPayloadData
 */
export interface SimpleHashWebhookPayloadData {
    /**
     * 
     * @type {string}
     * @memberof SimpleHashWebhookPayloadData
     */
    from_address?: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleHashWebhookPayloadData
     */
    to_address?: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleHashWebhookPayloadData
     */
    transaction: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleHashWebhookPayloadData
     */
    chain: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleHashWebhookPayloadData
     */
    token_id: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleHashWebhookPayloadData
     */
    contract_address: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleHashWebhookPayloadData
     */
    nft_id: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleHashWebhookPayloadData
     */
    collection_id: string;
}

export function SimpleHashWebhookPayloadDataFromJSON(json: any): SimpleHashWebhookPayloadData {
    return SimpleHashWebhookPayloadDataFromJSONTyped(json, false);
}

export function SimpleHashWebhookPayloadDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimpleHashWebhookPayloadData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'from_address': !exists(json, 'from_address') ? undefined : json['from_address'],
        'to_address': !exists(json, 'to_address') ? undefined : json['to_address'],
        'transaction': json['transaction'],
        'chain': json['chain'],
        'token_id': json['token_id'],
        'contract_address': json['contract_address'],
        'nft_id': json['nft_id'],
        'collection_id': json['collection_id'],
    };
}

export function SimpleHashWebhookPayloadDataToJSON(value?: SimpleHashWebhookPayloadData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'from_address': value.from_address,
        'to_address': value.to_address,
        'transaction': value.transaction,
        'chain': value.chain,
        'token_id': value.token_id,
        'contract_address': value.contract_address,
        'nft_id': value.nft_id,
        'collection_id': value.collection_id,
    };
}


