import {ReactElement} from "react";

function CookiePolicyPage(): ReactElement {

    return (
        <div className="legal-page">
            <iframe
                title="Cookie Policy"
                src="https://app.termly.io/document/cookie-policy/9fe39a7c-0f47-4fc0-8d72-c1d0245b1099"
                className="legal-page_content"
            />
        </div>
    );
}

export default CookiePolicyPage;

