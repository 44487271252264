import {isAndroid, isIOS} from "react-device-detect";

export function isAndroidApp(): boolean {
    return navigator.userAgent.indexOf("gonative") > -1 && isAndroid;
}

export function isIOSApp(): boolean {
    return navigator.userAgent.indexOf("gonative") > -1 && isIOS;
}

export function isGoNative(): boolean {
    return navigator.userAgent.indexOf("gonative") > -1;
}

export function isPhantomApp(): boolean {
    return window.navigator.userAgent.includes("Phantom");
}
