import {MenuOrder} from "@devour/client";

export function getBlocksFromMenuOrder(menuOrder: MenuOrder) {
    if (menuOrder?.onChainDpay > 0) {
        if (menuOrder?.dpayFiatAtOrderTime && menuOrder?.dpayFiatAtOrderTime > 0) {
            const onChainInFiat = menuOrder.onChainDpay * menuOrder.dpayFiatAtOrderTime;
            if (onChainInFiat <= 50) {
                return 1;
            } else if (onChainInFiat <= 100) {
                return 2;
            } else {
                return 3;
            }
        }
    } else {
        return 0;
    }
}