/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NftOwnership,
    NftOwnershipFromJSON,
    NftOwnershipFromJSONTyped,
    NftOwnershipToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetUserRedeemableNftOwnershipsResponse
 */
export interface GetUserRedeemableNftOwnershipsResponse {
    /**
     * 
     * @type {Array<NftOwnership>}
     * @memberof GetUserRedeemableNftOwnershipsResponse
     */
    nftOwnerships: Array<NftOwnership>;
}

export function GetUserRedeemableNftOwnershipsResponseFromJSON(json: any): GetUserRedeemableNftOwnershipsResponse {
    return GetUserRedeemableNftOwnershipsResponseFromJSONTyped(json, false);
}

export function GetUserRedeemableNftOwnershipsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetUserRedeemableNftOwnershipsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nftOwnerships': ((json['nftOwnerships'] as Array<any>).map(NftOwnershipFromJSON)),
    };
}

export function GetUserRedeemableNftOwnershipsResponseToJSON(value?: GetUserRedeemableNftOwnershipsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nftOwnerships': ((value.nftOwnerships as Array<any>).map(NftOwnershipToJSON)),
    };
}


