import {ButtonHTMLAttributes, ReactElement} from "react";
import {isDesktop} from "react-device-detect";
import FrameButton from "@/components/buttons/FrameButton";
import GoFrensTopLeaderboardEmptyStateRows
    from "@/components/goFrens/topLeaderboard/GoFrensTopLeaderboardEmptyStateRows";
import useThemePreference from "@/hooks/useThemePreference";

interface Props {
    isCurrent: boolean;
    isModal?: boolean;
}

function GoFrensTopLeaderboardEmptyState(props: Props): ReactElement {
    const {isOnDarkMode} = useThemePreference();

    return (
        <div className="gofrens-top-leaderboard_empty-state">
            <div className="gofrens-top-leaderboard_empty-state_left" style={{width: props?.isModal
                ? "100%"
                : ""}}>
                <div className="gofrens-top-leaderboard_empty-state_left_graphic-container">
                    <img
                        src={import.meta.env.VITE_CDN_URL + "/images/gofrens-empty-state-medal.webp"}
                        alt="Empty state logo"
                        className="gofrens-top-leaderboard_empty-state_left_graphic"
                    />
                </div>
                <div className="gofrens-top-leaderboard_empty-state_left_description">
                    <h3>{props.isCurrent
                        ? "Become a Top Leader"
                        : "Whoops, there's no reigning champs..."}</h3>
                    <p>
                        {props.isCurrent
                            ? "We are waiting for community leaders to be listed on this Leaderboard. Start earning points to become a Top Leader of your community!"
                            : "We couldn't find any records of community leaders for this month. Please select another month to view the top community leaders!"
                        }
                    </p>
                    {isDesktop && props.isCurrent &&
                    <FrameButton
                        <ButtonHTMLAttributes<HTMLButtonElement>>
                        size="normal"
                        color={isOnDarkMode
                            ? "purple"
                            : "purple-blue-gradient"}
                    >
                            Start Earning Points
                    </FrameButton>
                    }
                </div>
            </div>
            {!props.isModal && <GoFrensTopLeaderboardEmptyStateRows />}
        </div>
    );
}

export default GoFrensTopLeaderboardEmptyState;
