import {CreateMenuOrderBody, MenuOrder, MenuOrderItem, MenuOrdersApi} from "@devour/client";
import getConfig from "@/utils/getConfig";

interface RecreateMenuOrderArgs {
    menuOrder: MenuOrder;
    orderItems?: Array<MenuOrderItem>;
    backgroundCallback?: boolean;
    createMenuOrderBody?: CreateMenuOrderBody;
}

export interface RecreateMenuOrderResult {
    menuOrder?: MenuOrder;
    isNew?: boolean;
    error?: any;
    devourIqNotification?: string;
}

export async function updateMenuOrder(args: RecreateMenuOrderArgs): Promise<RecreateMenuOrderResult> {

    const newMenuOrderBody = {
        business: args.menuOrder.business,
        handoff: args.menuOrder.handoff,
        address: args.menuOrder.address,
        deliveryNotes: args.menuOrder.deliveryNotes,
        orderItems: [
            ...args?.orderItems
                ? args.orderItems
                : [],
        ],
        ...args?.createMenuOrderBody
            ? args.createMenuOrderBody
            : {},
    };

    try {
        const result = await new MenuOrdersApi(getConfig()).updateMenuOrder({
            id: args.menuOrder.id,
            backgroundCallback: args?.backgroundCallback
                ? "true"
                : undefined,
            createMenuOrderBody: newMenuOrderBody,
        });
        return {
            menuOrder: result,
            isNew: false,
            devourIqNotification: result.devourIqNotification,
        };

    } catch (e) {
        if (e.status === 403) {
            /*
             * Order to update has already been sent to payment.
             * To prevent multi-session hacks, this order is now immutable from order content updates.
             * Clone the data and create a new order instance.
             */
            const newMenuOrder = await new MenuOrdersApi(getConfig()).createMenuOrder({
                createMenuOrderBody: newMenuOrderBody,
            });
            return {
                menuOrder: newMenuOrder,
                isNew: true,
                devourIqNotification: newMenuOrder.devourIqNotification,
            };
        }
        return {error: e};

    }

}