import {FC, useState} from "react";
import {useSelector} from "react-redux";
import {StripePaymentMethodObject} from "../types/Stripe";
import {AiFillCreditCard, AiOutlinePlus} from "react-icons/ai";
import {capitalize} from "lodash";
import {IoTrashBin} from "react-icons/io5";
import CardPaymentAddModal from "./modals/CardPaymentAddModal";
import CardPaymentRemoveModal from "./modals/CardPaymentRemoveModal";
import {IStore} from "../redux/defaultStore";
import classNames from "classnames";
import {useGetStripePaymentMethodList} from "@/hooks/useGetStripePaymentMethodList";

const UserPaymentMethods: FC = () => {
    const [
        removePayment,
        setRemovePayment,
    ] = useState<string>("");
    const [
        showCardPaymentAddModal,
        setShowCardPaymentAddModal,
    ] = useState<boolean>(false);
    const currentUser = useSelector((store: IStore) => store.metaStore.currentUser?.user);
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);

    /**
	 * Get the list of payment methods from our api.
	 *
	 */
    const {data: stripePaymentMethodListData, refetch: refetchStripePaymentMethodListData} = useGetStripePaymentMethodList(fullToken);
    const paymentMethods = (stripePaymentMethodListData?.paymentMethods) as Array<StripePaymentMethodObject>;

    async function onAddPaymentMethodSubmit(): Promise<void> {
        setShowCardPaymentAddModal(false);
        await refetchStripePaymentMethodListData();
    }

    function onRemovePaymentMethod() {
        setRemovePayment("");
        refetchStripePaymentMethodListData();
    }

    function renderPaymentMethod(payment: StripePaymentMethodObject): JSX.Element {
        return (
            <li
                key={payment.id}
                className="user-payment-methods_list_item"
            >
                <div className="user-payment-methods_list_item_icon">
                    <AiFillCreditCard/>
                </div>
                <div className={classNames("user-payment-methods_list_item_info", "sentry-mask")}>
                    <p><strong>{capitalize(payment.card.brand)}</strong></p>
                    <p>{payment.card.last4} Exp. {payment.card.exp_month}/{payment.card.exp_year}</p>
                </div>
                <div className="menu-order-payment-list-modal_body_list_item_selected">
                    {currentUser?.defaultPaymentMethodId === payment.id &&
                    <div className="menu-order-payment-list-modal_body_list_item_selected_text">
							Default
                    </div>
                    }
                </div>
                <button
                    title="Remove payment method"
                    className="user-payment-methods_list_item_remove"
                    onClick={() => setRemovePayment(payment.id)}
                >
                    <IoTrashBin/>
                </button>
            </li>
        );
    }


    return (
        <>
            <CardPaymentAddModal
                isOpen={showCardPaymentAddModal}
                onClose={() => setShowCardPaymentAddModal(false)}
                onSubmit={onAddPaymentMethodSubmit}
            />
            <CardPaymentRemoveModal
                paymentId={removePayment}
                isOpen={!!removePayment}
                onClose={onRemovePaymentMethod}
            />

            <div className="user-payment-methods">
                {paymentMethods?.length > 0 &&
                <ul className="user-payment-methods_list">
					    {paymentMethods.map(renderPaymentMethod)}
                </ul>
                }
                <button
                    className="user-payment-methods_add"
                    onClick={() => setShowCardPaymentAddModal(true)}
                >
                    <div className="user-payment-methods_add_icon">
                        <AiOutlinePlus/>
                    </div>
                    <div className="user-payment-methods_add_text">
                        <p>
                            <strong>Add new card</strong>
                        </p>
                    </div>
                </button>
            </div>
        </>
    );
};

export default UserPaymentMethods;
