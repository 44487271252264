import {useQuery} from "@tanstack/react-query";
import {BrandsApi, BrandMap} from "@devour/client";
import getConfig from "@/utils/getConfig";

async function fetchBrandMap(brandMapSlug: string): Promise<BrandMap> {
    if (!brandMapSlug) {
        return;
    }
    return await new BrandsApi(getConfig()).getBrandMap({
        slug: brandMapSlug,
    });
}

function brandMapQuery(brandMapSlug: string) {
    return {
        queryKey: [
            "brand-map",
            brandMapSlug,
        ],
        queryFn: () => fetchBrandMap(brandMapSlug),
        keepPreviousData: true,
        staleTime: 30 * 60000,
        refetchOnWindowFocus: false,
    } as const;
}

export function useGetBrandMap(brandMapSlug: string) {
    return useQuery(brandMapQuery(brandMapSlug));
}
