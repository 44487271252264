import {ButtonHTMLAttributes, ChangeEventHandler, FormEvent, ReactElement, useEffect, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {
    CreateWithdrawBody,
    GetTransactionsPaginatedResponse,
    GetWithdrawFeeAmountResponse,
    Token,
    WithdrawsApi,
    WithdrawWalletChain,
} from "@devour/client";
import FrameOneModal from "./modalComponents/FrameOneModal";
import {addError, decrementLoading, incrementLoading} from "@/redux/meta/metaActions";
import getConfig from "../../utils/getConfig";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameButton from "../buttons/FrameButton";
import NumberFormat from "react-number-format-legacy/dist/react-number-format";
import {ethers} from "ethers";
import FrameOneSelect from "@/components/inputs/FrameOneSelect";

export const defaultWithdrawForm: CreateWithdrawBody = {
    amount: 0,
    wallet: "",
    walletChain: WithdrawWalletChain.ETHEREUM,
};

interface StateProps {
    fullToken: Token;
}

interface Props {
    isOpen: boolean;
    onClose: () => void;
    onDone: () => Promise<void>;
    transactionsResponse: GetTransactionsPaginatedResponse;
}

function RequestWithdrawModal(props: RequestWithdrawModalProps): ReactElement {

    const [
        withdrawForm,
        setWithdrawForm,
    ] = useState<CreateWithdrawBody>(defaultWithdrawForm);
    const [
        withdrawFeeAmount,
        setWithdrawFeeAmount,
    ] = useState<GetWithdrawFeeAmountResponse>();

    /**
     * When the modal opens, reset the form and fetch the withdrawFeeAmount.
     *
     */
    useEffect(() => {
        if (props.isOpen) {
            setWithdrawForm(defaultWithdrawForm);
            getWithdrawFeeAmount()
                .then()
                .catch();
        } else {
            setWithdrawFeeAmount(undefined);
        }
    }, [props.isOpen]);

    /**
     * Updates the withdraw fee amount.
     */
    async function getWithdrawFeeAmount() {
        props.dispatch(incrementLoading());
        try {
            const res = await new WithdrawsApi(getConfig(props.fullToken)).getWithdrawFeeAmount();

            // check to see if the user has enough in order to pay the fee
            if (res.withdrawFeeInDpay >= props.transactionsResponse.balance) {
                props.onClose();
                throw {message: `Your ${import.meta.env.VITE_TOKEN_NAME}} balance is insufficient to cover the $3 withdrawal fee.`};
            }

            setWithdrawFeeAmount(res);
        } catch (err) {
            props.dispatch(await addError(err));
        }
        props.dispatch(decrementLoading());
    }

    /**
     * Calculates the max amount that can be withdrawn given the current fee and sets that in the amount field.
     */
    function setMaxAmount() {
        const maxAmount = props.transactionsResponse.balance - withdrawFeeAmount.withdrawFeeInDpay;
        setWithdrawForm((f) => {
            return {
                ...f,
                amount: maxAmount,
            };
        });
    }

    /**
     * Handle the number format input for the amount field on change.
     * If the entered amount is greater than the user's balance, set the input to match the balance.
     *
     * @param values
     */
    function amountOnChange(values): void {
        let newAmount = values.floatValue;
        if (newAmount > props.transactionsResponse?.balance) {
            newAmount = props.transactionsResponse?.balance;
        }

        setWithdrawForm((f) => {
            return {
                ...f,
                amount: newAmount,
            };
        });
    }

    /**
     * Handle chang events for standard inputs.
     *
     * @param key
     */
    function inputOnChange(key: keyof CreateWithdrawBody): ChangeEventHandler<HTMLInputElement | HTMLSelectElement> {
        return (e) => {
            setWithdrawForm((f) => {
                return {
                    ...f,
                    [key]: e.target.value,
                };
            });
        };
    }

    async function submitWithdrawalRequest(e: FormEvent<HTMLFormElement>): Promise<void> {
        e.preventDefault();
        props.dispatch(incrementLoading());

        try {
            await new WithdrawsApi(getConfig(props.fullToken)).createWithdrawRequest({
                createWithdrawBody: {
                    amount: withdrawForm.amount,
                    wallet: withdrawForm.wallet,
                    walletChain: withdrawForm.walletChain,
                },
            });
            await props.onDone();
            props.onClose();
        } catch (e) {
            props.dispatch(await addError(e));
        } finally {
            props.dispatch(decrementLoading());
        }
    }


    function getClosingBalance(): number {
        if (!withdrawForm.amount) {
            return props.transactionsResponse?.balance;
        }

        return props.transactionsResponse?.balance - withdrawForm.amount - withdrawFeeAmount?.withdrawFeeInDpay;
    }

    function isButtonDisabled(): boolean {
        if (!withdrawForm.walletChain || !withdrawForm.amount || withdrawForm.amount <= 0) {
            return true;
        }

        switch (withdrawForm.walletChain) {
            case WithdrawWalletChain.ETHEREUM:
                return !ethers.isAddress(withdrawForm.wallet);
            case WithdrawWalletChain.SOLANA:
                return withdrawForm.wallet?.length < 43;
            default:
                return false;
        }
    }

    function getWalletAddressMaxLength(): number {
        if (withdrawForm.walletChain === WithdrawWalletChain.ETHEREUM) {
            return 42;
        } else if (withdrawForm.walletChain === WithdrawWalletChain.SOLANA) {
            return 44;
        }
    }

    return (
        <>
            <FrameOneModal
                isOpen={props.isOpen && withdrawFeeAmount != null}
                toggle={props.onClose}
                contentClassName="request-withdraw-modal"
            >
                <FrameModalHeader
                    title="How much are you withdrawing?"
                    toggle={props.onClose}
                />

                <FrameModalBody className="request-withdraw-modal_body">
                    <form
                        onSubmit={submitWithdrawalRequest}
                        className="request-withdraw-modal_body_form"
                    >
                        <div className="request-withdraw-modal_body_form_opening-balance">
                            <label>Current balance</label>
                            <p>
                                {props.transactionsResponse?.balance} {import.meta.env.VITE_TOKEN_NAME}
                            </p>
                        </div>

                        <div className="request-withdraw-modal_body_form_amount-container">
                            <div className="request-withdraw-modal_body_form_amount-container_amount-label">
                                <label>
                                    Enter amount
                                </label>
                                <FrameButton
                                    <ButtonHTMLAttributes<HTMLButtonElement>>
                                    color="purple"
                                    size="pill"
                                    forwardProps={{
                                        type: "button",
                                    }}
                                    onClick={setMaxAmount}
                                    className="request-withdraw-modal_body_form_amount-container_amount-label_amount-label-button"
                                >
                                    Set Max Amount
                                </FrameButton>
                            </div>
                            <NumberFormat
                                placeholder="Amount to withdraw"
                                thousandSeparator={true}
                                value={withdrawForm.amount}
                                allowLeadingZeros={false}
                                allowNegative={false}
                                decimalScale={18}
                                onValueChange={amountOnChange}
                            />
                        </div>

                        <div className="request-withdraw-modal_body_form_address-container">
                            <label>Chain</label>
                            <FrameOneSelect
                                value={withdrawForm.walletChain}
                                onChange={inputOnChange("walletChain")}
                            >
                                <option disabled={true}>Select Chain</option>
                                {Object.values(WithdrawWalletChain).map((chain: WithdrawWalletChain) => <option key={chain} value={chain}>{chain}</option>)}
                            </FrameOneSelect>
                        </div>

                        <div className="request-withdraw-modal_body_form_address-container">
                            <label>Recipient Address</label>
                            <input
                                placeholder="Wallet Address"
                                value={withdrawForm.wallet}
                                onChange={inputOnChange("wallet")}
                                maxLength={getWalletAddressMaxLength()}
                            />
                        </div>

                        <div className="request-withdraw-modal_body_form_closing-balance">
                            <label>Balance after withdrawal</label>
                            <p>
                                {getClosingBalance()} {import.meta.env.VITE_TOKEN_NAME}
                            </p>
                        </div>

                        <FrameButton
                            <ButtonHTMLAttributes<HTMLButtonElement>>
                            color="purple"
                            size="large"
                            forwardProps={{
                                type: "submit",
                                disabled: isButtonDisabled(),
                            }}
                            showSpinner={true}
                            className="request-withdraw-modal_body_form_submit-btn"
                        >
                            Confirm Withdraw
                        </FrameButton>

                        <label><i>There is a fee of $3 in {import.meta.env.VITE_TOKEN_NAME} to withdraw.</i></label>

                        <p>
                            <strong>WITHDRAW REQUESTS ARE VERIFIED & CAN TAKE UP TO 24-48 HOURS TO PROCESS</strong>
                        </p>
                    </form>
                </FrameModalBody>
            </FrameOneModal>
        </>
    );
}

function connector() {
    return connect((store: IStore, props: Props): StateProps & Props => {
        return {
            fullToken: store.authStore.fullToken,
            ...props,
        };
    });
}

type RequestWithdrawModalProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(RequestWithdrawModal);
