import {useQuery} from "@tanstack/react-query";
import {GetUserResponse, Token, UsersApi} from "@devour/client";
import getConfig from "@/utils/getConfig";

export const USE_GET_USER_PROFILE_KEY = "user-profile";

async function fetchUserProfile(fullToken: Token): Promise<GetUserResponse> {
    return await new UsersApi(getConfig(fullToken)).getProfile();
}

function userProfileQuery(fullToken: Token) {
    return {
        queryKey: [
            USE_GET_USER_PROFILE_KEY,
            fullToken?.id,
        ],
        queryFn: () => fullToken ? fetchUserProfile(fullToken) : null,
        staleTime: 30 * 60000,
        keepPreviousData: true,
        enabled: !!fullToken,
    } as const;
}

export function useGetUserProfile(fullToken: Token) {
    return useQuery(userProfileQuery(fullToken));
}