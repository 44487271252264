/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DevourIQWebhookBodyUberEatsResult,
    DevourIQWebhookBodyUberEatsResultFromJSON,
    DevourIQWebhookBodyUberEatsResultFromJSONTyped,
    DevourIQWebhookBodyUberEatsResultToJSON,
    MenuOrderStatus,
    MenuOrderStatusFromJSON,
    MenuOrderStatusFromJSONTyped,
    MenuOrderStatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface DevourIQWebhookBody
 */
export interface DevourIQWebhookBody {
    /**
     * 
     * @type {string}
     * @memberof DevourIQWebhookBody
     */
    errorMessage?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DevourIQWebhookBody
     */
    isTest?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DevourIQWebhookBody
     */
    jobUuid?: string;
    /**
     * 
     * @type {string}
     * @memberof DevourIQWebhookBody
     */
    secret?: string;
    /**
     * 
     * @type {MenuOrderStatus}
     * @memberof DevourIQWebhookBody
     */
    status?: MenuOrderStatus;
    /**
     * 
     * @type {object}
     * @memberof DevourIQWebhookBody
     */
    orderCreationPayload?: object;
    /**
     * 
     * @type {DevourIQWebhookBodyUberEatsResult}
     * @memberof DevourIQWebhookBody
     */
    uberEatsResult?: DevourIQWebhookBodyUberEatsResult;
}

export function DevourIQWebhookBodyFromJSON(json: any): DevourIQWebhookBody {
    return DevourIQWebhookBodyFromJSONTyped(json, false);
}

export function DevourIQWebhookBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): DevourIQWebhookBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'errorMessage': !exists(json, 'errorMessage') ? undefined : json['errorMessage'],
        'isTest': !exists(json, 'isTest') ? undefined : json['isTest'],
        'jobUuid': !exists(json, 'jobUuid') ? undefined : json['jobUuid'],
        'secret': !exists(json, 'secret') ? undefined : json['secret'],
        'status': !exists(json, 'status') ? undefined : MenuOrderStatusFromJSON(json['status']),
        'orderCreationPayload': !exists(json, 'orderCreationPayload') ? undefined : json['orderCreationPayload'],
        'uberEatsResult': !exists(json, 'uberEatsResult') ? undefined : DevourIQWebhookBodyUberEatsResultFromJSON(json['uberEatsResult']),
    };
}

export function DevourIQWebhookBodyToJSON(value?: DevourIQWebhookBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'errorMessage': value.errorMessage,
        'isTest': value.isTest,
        'jobUuid': value.jobUuid,
        'secret': value.secret,
        'status': MenuOrderStatusToJSON(value.status),
        'orderCreationPayload': value.orderCreationPayload,
        'uberEatsResult': DevourIQWebhookBodyUberEatsResultToJSON(value.uberEatsResult),
    };
}


