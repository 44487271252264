/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NftCommunity,
    NftCommunityFromJSON,
    NftCommunityFromJSONTyped,
    NftCommunityToJSON,
    NftGrouping,
    NftGroupingFromJSON,
    NftGroupingFromJSONTyped,
    NftGroupingToJSON,
    RankedMember,
    RankedMemberFromJSON,
    RankedMemberFromJSONTyped,
    RankedMemberToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetUserCommunityInfoResponse
 */
export interface GetUserCommunityInfoResponse {
    /**
     * 
     * @type {NftCommunity}
     * @memberof GetUserCommunityInfoResponse
     */
    nftCommunity: NftCommunity;
    /**
     * 
     * @type {NftGrouping}
     * @memberof GetUserCommunityInfoResponse
     */
    nftGrouping: NftGrouping;
    /**
     * 
     * @type {number}
     * @memberof GetUserCommunityInfoResponse
     */
    nftCommunityMembers: number;
    /**
     * 
     * @type {number}
     * @memberof GetUserCommunityInfoResponse
     */
    nftCommunityRank?: number;
    /**
     * 
     * @type {Array<RankedMember>}
     * @memberof GetUserCommunityInfoResponse
     */
    members?: Array<RankedMember>;
}

export function GetUserCommunityInfoResponseFromJSON(json: any): GetUserCommunityInfoResponse {
    return GetUserCommunityInfoResponseFromJSONTyped(json, false);
}

export function GetUserCommunityInfoResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetUserCommunityInfoResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nftCommunity': NftCommunityFromJSON(json['nftCommunity']),
        'nftGrouping': NftGroupingFromJSON(json['nftGrouping']),
        'nftCommunityMembers': json['nftCommunityMembers'],
        'nftCommunityRank': !exists(json, 'nftCommunityRank') ? undefined : json['nftCommunityRank'],
        'members': !exists(json, 'members') ? undefined : ((json['members'] as Array<any>).map(RankedMemberFromJSON)),
    };
}

export function GetUserCommunityInfoResponseToJSON(value?: GetUserCommunityInfoResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nftCommunity': NftCommunityToJSON(value.nftCommunity),
        'nftGrouping': NftGroupingToJSON(value.nftGrouping),
        'nftCommunityMembers': value.nftCommunityMembers,
        'nftCommunityRank': value.nftCommunityRank,
        'members': value.members === undefined ? undefined : ((value.members as Array<any>).map(RankedMemberToJSON)),
    };
}


