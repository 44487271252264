/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
    TimeStamps,
    TimeStampsFromJSON,
    TimeStampsFromJSONTyped,
    TimeStampsToJSON,
    Transaction,
    TransactionFromJSON,
    TransactionFromJSONTyped,
    TransactionToJSON,
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
    WithdrawStatus,
    WithdrawStatusFromJSON,
    WithdrawStatusFromJSONTyped,
    WithdrawStatusToJSON,
    WithdrawWalletChain,
    WithdrawWalletChainFromJSON,
    WithdrawWalletChainFromJSONTyped,
    WithdrawWalletChainToJSON,
} from './';

/**
 * Represents a single withdraw request of dpay.
 * @export
 * @interface Withdraw
 */
export interface Withdraw {
    /**
     * 
     * @type {string}
     * @memberof Withdraw
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof Withdraw
     */
    createdAt: number;
    /**
     * 
     * @type {number}
     * @memberof Withdraw
     */
    updatedAt: number;
    /**
     * 
     * @type {WithdrawStatus}
     * @memberof Withdraw
     */
    status: WithdrawStatus;
    /**
     * 
     * @type {string}
     * @memberof Withdraw
     */
    notes?: string;
    /**
     * 
     * @type {User}
     * @memberof Withdraw
     */
    user: User;
    /**
     * 
     * @type {Transaction}
     * @memberof Withdraw
     */
    transaction?: Transaction;
    /**
     * 
     * @type {string}
     * @memberof Withdraw
     */
    wallet?: string;
    /**
     * 
     * @type {WithdrawWalletChain}
     * @memberof Withdraw
     */
    walletChain?: WithdrawWalletChain;
    /**
     * 
     * @type {number}
     * @memberof Withdraw
     */
    amount: number;
    /**
     * 
     * @type {number}
     * @memberof Withdraw
     */
    dpayFee: number;
    /**
     * 
     * @type {string}
     * @memberof Withdraw
     */
    hash?: string;
}

export function WithdrawFromJSON(json: any): Withdraw {
    return WithdrawFromJSONTyped(json, false);
}

export function WithdrawFromJSONTyped(json: any, ignoreDiscriminator: boolean): Withdraw {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'status': WithdrawStatusFromJSON(json['status']),
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'user': UserFromJSON(json['user']),
        'transaction': !exists(json, 'transaction') ? undefined : TransactionFromJSON(json['transaction']),
        'wallet': !exists(json, 'wallet') ? undefined : json['wallet'],
        'walletChain': !exists(json, 'walletChain') ? undefined : WithdrawWalletChainFromJSON(json['walletChain']),
        'amount': json['amount'],
        'dpayFee': json['dpayFee'],
        'hash': !exists(json, 'hash') ? undefined : json['hash'],
    };
}

export function WithdrawToJSON(value?: Withdraw | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'status': WithdrawStatusToJSON(value.status),
        'notes': value.notes,
        'user': UserToJSON(value.user),
        'transaction': TransactionToJSON(value.transaction),
        'wallet': value.wallet,
        'walletChain': WithdrawWalletChainToJSON(value.walletChain),
        'amount': value.amount,
        'dpayFee': value.dpayFee,
        'hash': value.hash,
    };
}


