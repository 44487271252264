import {ReactElement, useState} from "react";
import {NftGrouping} from "@devour/client";
import {truncateText} from "@/utils/truncateText";
import {getRankSuffix} from "@/utils/getRankSuffix";

interface Props {
    grouping: NftGrouping;
}

function DrawerCommunityInfo(props: Props): ReactElement {

    const [
        showAllText,
        setShowAllText,
    ] = useState<boolean>(false);

    return (
        <div className="gofrens-community-info-drawer_contents_info">
            {props?.grouping?.description &&
            <p className="gofrens-community-info-drawer_contents_info_description">
                {showAllText
                    ? `${props?.grouping?.description} `
                    : `${truncateText(140, props?.grouping?.description)} `
                }
                <span onClick={() => setShowAllText(!showAllText)}>
                    {showAllText
                        ? "Read Less"
                        : "Read More"}
                </span>
            </p>
            }
            <div className="gofrens-community-info-drawer_contents_info_community-stats">
                <div className="gofrens-community-info-drawer_contents_info_community-stats_section">
                    <p className="gofrens-community-info-drawer_contents_info_community-stats_section_header">
                        Community Rank
                    </p>
                    <div
                        className="gofrens-community-info-drawer_contents_info_community-stats_section_card">
                        <div>{props?.grouping?.totalPoints === 0
                            ? <span className="gofrens-community-info-drawer_contents_info_community-stats_section_card_unranked">
                                Unranked
                            </span>
                            : <>
                                <span
                                    className="gofrens-community-info-drawer_contents_info_community-stats_section_card_rank"
                                >
                                    {props?.grouping?.rank}
                                </span>
                                {` ${getRankSuffix(props?.grouping?.rank)}`}
                            </>
                        }
                        </div>
                    </div>
                </div>
                <div className="gofrens-community-info-drawer_contents_info_community-stats_section">
                    <p className="gofrens-community-info-drawer_contents_info_community-stats_section_header">
                        Total Members
                    </p>
                    <div
                        className="gofrens-community-info-drawer_contents_info_community-stats_section_card">
                        <div>
                            <span
                                className="gofrens-community-info-drawer_contents_info_community-stats_section_card_rank"
                            >
                                {props?.grouping?.numOfMembers}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DrawerCommunityInfo;
