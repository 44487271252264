import {AnchorHTMLAttributes, ReactElement, useEffect, useState} from "react";
import FrameButton from "./buttons/FrameButton";
import CopiedInput from "@/components/inputs/ReferralCopiedInput";
import {magicSolana} from "@/utils/magic";
import {MdOutlineContentCopy} from "react-icons/md";
import {isGoNative} from "@/utils/isGoNative";

function AccountMagicWalletPrivateKeyExportPanel(): ReactElement {

    const [
        revealSolanaAddress,
        setRevealSolanaAddress,
    ] = useState<boolean>(false);
    const [
        solanaAddress,
        setSolanaAddress,
    ] = useState<string>("");
    const [
        solanaCopied,
        setSolanaCopied,
    ] = useState<boolean>(false);

    useEffect(() => {
        getSolanaAddress().then((address) => {
            setSolanaAddress(address);
        });
    }, []);

    const getSolanaAddress = async () => {
        const metadata = await magicSolana.user.getMetadata();
        return metadata.publicAddress;
    };

    const copySolanaPublicKey = () => {
        setSolanaCopied(true);
        setTimeout(() => {
            setSolanaCopied(false);
        }, 3000);
        if (isGoNative()) {
            // @ts-ignore
            gonative.clipboard.set({"data": solanaAddress}); // for app to copy to clipboard
        } else {
            navigator.clipboard.writeText(solanaAddress);
        }
    };

    return (
        <>
            <h3>DevourGO Wallet</h3>
            <div className="acc-magic-export-panel">
                <div className="acc-magic-export-panel_text">
                    <p>
                        <strong>
                            Private key
                        </strong>
                    </p>
                    <p className="acc-magic-export-panel_text_description">
                        Click the button to reveal your DevourGO wallet's private key
                    </p>
                </div>

                <FrameButton
                    <AnchorHTMLAttributes<HTMLAnchorElement>>
                    color="purple"
                    size="narrow"
                    href="https://reveal.magic.link/devourgo"
                    forwardProps={{
                        target: "_blank",
                        rel: "noopener noreferrer",
                    }}
                >
                    Reveal Private Key
                </FrameButton>
            </div>
            <br/>
            <div className="acc-magic-export-panel">
                <div className="acc-magic-export-panel_text">
                    <p>
                        <strong>
                            Solana Wallet Address
                        </strong>
                    </p>
                    <p className="acc-magic-export-panel_text_description">
                        {revealSolanaAddress
                            ? <div className="input-container">
                                <CopiedInput
                                    className="referral-code-generation_input-container_input"
                                    disabled={true}
                                    value={solanaAddress}
                                    isCopiedVisible={solanaCopied}
                                />
                                <FrameButton
                                    color="gray"
                                    size="normal"
                                    leftIcon={MdOutlineContentCopy}
                                    onClick={() => copySolanaPublicKey()}>
                                    Copy
                                </FrameButton>
                            </div>
                            : "Click the button to reveal your Solana wallet's public key"
                        }

                    </p>
                </div>
                {!revealSolanaAddress &&
                <FrameButton
                    <AnchorHTMLAttributes<HTMLAnchorElement>>
                    color="purple"
                    size="narrow"
                    onClick={() => setRevealSolanaAddress(true)}
                >
                        Reveal Public Key
                </FrameButton>}
            </div>
        </>
    );
}

export default AccountMagicWalletPrivateKeyExportPanel;