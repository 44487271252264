/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DialCode,
    DialCodeFromJSON,
    DialCodeFromJSONTyped,
    DialCodeToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetGeography
 */
export interface GetGeography {
    /**
     * 
     * @type {Array<DialCode>}
     * @memberof GetGeography
     */
    countries: Array<DialCode>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof GetGeography
     */
    states: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof GetGeography
     */
    provinces: { [key: string]: string; };
}

export function GetGeographyFromJSON(json: any): GetGeography {
    return GetGeographyFromJSONTyped(json, false);
}

export function GetGeographyFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetGeography {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'countries': ((json['countries'] as Array<any>).map(DialCodeFromJSON)),
        'states': json['states'],
        'provinces': json['provinces'],
    };
}

export function GetGeographyToJSON(value?: GetGeography | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'countries': ((value.countries as Array<any>).map(DialCodeToJSON)),
        'states': value.states,
        'provinces': value.provinces,
    };
}


