import {useQuery} from "@tanstack/react-query";
import {GetUnclaimedSupplyResponse, ThirdwebApi} from "@devour/client";
import getConfig from "@/utils/getConfig";

async function fetchUnclaimedNFTSupply(): Promise<GetUnclaimedSupplyResponse> {
    return await new ThirdwebApi(getConfig()).getNftUnclaimedSupply();
}

function useUnclaimedNFTSupplyQuery() {
    return {
        queryKey: ["get-nft-supply"],
        queryFn: () => fetchUnclaimedNFTSupply(),
        staleTime: 10000,
    } as const;
}

export function useGetUnclaimedNFTSupply() {
    return useQuery(useUnclaimedNFTSupplyQuery());
}