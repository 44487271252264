import {ReactElement, useState} from "react";
import {FaCircleCheck, FaPlus} from "react-icons/fa6";
import {StripePaymentMethodObject} from "@/types/Stripe";
import {BsCreditCardFill} from "react-icons/bs";
import {capitalize} from "lodash";
import CheckoutPaymentAddCreditModal from "@/components/checkout/checkoutPayments/credit/CheckoutPaymentAddCreditModal";
import classNames from "classnames";
import {useAddCreditPaymentMethod} from "@/hooks/creditPaymentMethod/useAddCreditPaymentMethod";

interface Props {
    selectedPayment?: string;
    paymentMethods: Array<StripePaymentMethodObject>;
    onUpdateSelectedCreditCard: (stripeId: string) => Promise<void>;
    addCreditCard: ReturnType<typeof useAddCreditPaymentMethod>["mutate"];
}

export default function CheckoutPaymentSplitSetupCreditCard(props: Props): ReactElement {
    const [
        showAddCardModal,
        setShowAddCardModal,
    ] = useState<boolean>(false);

    function renderPaymentMethod(payment: StripePaymentMethodObject): ReactElement {
        return (
            <div
                className={classNames(
                    "checkout-split-setup-modal_body_credit-card_list_item",
                    {"is-selected": payment.id === props.selectedPayment},
                )}
                key={payment.id}
                onClick={() => props.onUpdateSelectedCreditCard(payment.id)}
            >
                <div className="checkout-split-setup-modal_body_credit-card_list_item_info">
                    <BsCreditCardFill/>
                    <strong>{capitalize(payment.card.brand)} ****{payment.card.last4}</strong>
                </div>
                {props.selectedPayment === payment.id &&
                <FaCircleCheck className="checkout-split-setup-modal_body_credit-card_list_item_selected"/>
                }
            </div>
        );
    }

    return (
        <>
            <CheckoutPaymentAddCreditModal
                isOpen={showAddCardModal}
                onClose={() => setShowAddCardModal(false)}
                addCreditCard={props.addCreditCard}
            />
            <div className="checkout-split-setup-modal_body_credit-card_list">
                {props.paymentMethods?.map(payment => renderPaymentMethod(payment))}
                <div
                    className="checkout-split-setup-modal_body_credit-card_list_item"
                    onClick={() => setShowAddCardModal(true)}
                >
                    <div className="checkout-split-setup-modal_body_credit-card_list_item_info">
                        <FaPlus/>
                        <strong>Add Credit Card</strong>
                    </div>
                </div>
            </div>
        </>
    );
}