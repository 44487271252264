import {MenuOrderItem, MenuOrderSubItem} from "@devour/client";

interface ItemTotal {

    /** total of item + customizations before tax */
    subtotal: number;

    /** total taxes of item + customizations */
    taxTotal: number;
}

/**
 * Calculate order items in a single nested loop level. Make sure that prices are coming from a trusted source (not just the client request).
 * @param orderItems
 * @param parentQuantity Since 3 large pizzas with 3 toppings each equal 9 toppings, we need to exponentially multiply the parent quantity
 */
export function calculateTotalLoop(orderItems: Array<MenuOrderItem | MenuOrderSubItem>, parentQuantity: number): ItemTotal {
    if (!orderItems.length) {
        return {
            subtotal: 0,
            taxTotal: 0,
        };
    }

    const subtotalCount: Array<number> = [];
    const taxtotalCount: Array<number> = [];

    for (const orderItem of orderItems) {
        const quantity = orderItem.quantity * parentQuantity;
        const itemPrice = orderItem.price;
        const taxRate = orderItem.taxRate;
        const itemTax = itemPrice * taxRate / 100; // Tax rate is percent amount, which means we need to divide by 100 to get percentage.

        subtotalCount.push(itemPrice * quantity);
        taxtotalCount.push(itemTax * quantity);

        if (orderItem.customizations?.length) {
            const customizationTotals = calculateTotalLoop(orderItem.customizations, quantity);
            subtotalCount.push(customizationTotals.subtotal);
            taxtotalCount.push(customizationTotals.taxTotal);
        }

    }

    // Sum up totals
    return {
        subtotal: subtotalCount.reduce((a, b) => a + b),
        taxTotal: taxtotalCount.reduce((a, b) => a + b),
    };
}
