import {ReactElement} from "react";

function PrivacyPolicyPage(): ReactElement {

    return (
        <div className="legal-page">
            <iframe
                title="Privacy Policy"
                src="https://app.termly.io/document/privacy-policy/714c69cf-5707-493b-af1e-7d69e9539e67"
                className="legal-page_content"
            />
        </div>
    );
}

export default PrivacyPolicyPage;

