import {ReactElement} from "react";
import Skeleton from "react-loading-skeleton";

function RestaurantSearchFiltersSkeleton(): ReactElement {
    return (
        <div className='restaurant-search-filters-skeleton restaurants-search-filters_inner'>
            {Array.from({length: 20}, (_, i) => <div className="restaurant-search-filters-skeleton_item" key={i}>
                <Skeleton width={67} height={67}/>
            </div>)}
        </div>
    );
}

export default RestaurantSearchFiltersSkeleton;