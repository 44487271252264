import {ButtonHTMLAttributes, ReactElement, useContext, useEffect, useState} from "react";
import {Address, BrandMap, BrandMapColorTheme, OrderDiscount, Restaurant, RestaurantSearchResult} from "@devour/client";
import {PickFieldsOfType} from "../utils/PickedFieldsOfType";
import FrameButton from "./buttons/FrameButton";
import classNames from "classnames";
import {useParams} from "react-router";
import {useGetBrandMap} from "@/hooks/useGetBrandMap";
import Tooltip from "@/components/Tooltip";
import {BrandMapContext} from "@/pages/brandMap/context/BrandMapContext";
import {BiSolidDiscount} from "react-icons/bi";

export function addPotentiallyEmptyAddressSection(_address: Address, key: keyof PickFieldsOfType<Address, string>): string {
    if (_address[key]?.length > 0) {
        return ` ${_address[key]}`;
    }

    return "";
}

export function concatAddressFields(_address: Address, fields: Array<keyof PickFieldsOfType<Address, string>> = [
    "line1",
    "line2",
    "locality",
    "administrativeArea",
    "postalCode",
]): string {
    return fields.slice(1).reduce((add, field) => {
        return add + addPotentiallyEmptyAddressSection(_address, field);
    }, _address[fields[0]] as string);
}

interface Props {
    business: Restaurant | RestaurantSearchResult;
    onSelectClick: (restaurant: Restaurant | RestaurantSearchResult) => void;
    isActive: boolean;
    brandMap: BrandMap;
    onOpenRestaurantMenu: () => void;
    isInitialState: boolean;
}

function MapLandingBusinessListCard(props: Props): ReactElement {
    const {slug} = useParams<{ slug: string }>();
    const {data: brandMap} = useGetBrandMap(slug);
    const isOpen = props.business.isOpen;
    const businessId = "_id" in props.business ? props.business._id : props.business.id;
    const {selectedPromo} = useContext(BrandMapContext);
    const [availablePromos, setAvailablePromos] = useState<Array<OrderDiscount>>([]);
    const [unavailablePromos, setUnavailablePromos] = useState<Array<OrderDiscount>>([]);

    useEffect(() => {
        if (!brandMap) return;

        const allPromos = (brandMap.promos ?? []).concat(brandMap.mainPromo ?? []);
        const available: Array<OrderDiscount> = [];
        const unavailable: Array<OrderDiscount> = [];

        allPromos.forEach(promo => {
            if (!promo.isBrandMapAutoApplied && promo.id !== selectedPromo) return;
            // Check if the promotion is available for the business
            const isAvailable = promo.isBrandMapValid && (!promo.businesses?.length || promo.businesses.includes(businessId));
            if (isAvailable) {
                available.push(promo);
            } else {
                unavailable.push(promo);
            }
        });

        setAvailablePromos(available);
        setUnavailablePromos(unavailable);
    }, [brandMap, selectedPromo]);


    function getRestaurantIcon() {
        if (typeof props.business.icon === "string") {
            return props.business.icon;
        }
        return props.business.icon?.url;
    }

    function renderTooltip(): ReactElement {
        // Hide the tag/tooltip if the business is not selected
        if (!props.isActive || props.isInitialState) {
            return;
        }

        // Show "No Promotions" when business is closed or no promotions are available
        if (!props.business?.isOpen || !availablePromos.length && !unavailablePromos.length) {
            return <span className="map-landing-bus-list-card_content_tooltip">No Promotions</span>;
        }

        const tooltipContent: ReactElement =
            <span className="map-landing-bus-list-card_content_tooltip brand-map-promotions_pill-color">
                <BiSolidDiscount size="1.125rem"/>
                {availablePromos.length || ""} Promotions Available
            </span>;

        return (
            <Tooltip
                outsideContent={tooltipContent}
                containerElementClassname="brand-map-locations_map_list"
            >
                <div className="map-landing-bus-list-card_content_tooltip_promos">
                    {availablePromos.length > 0 &&
                        <div>
                            <p>{availablePromos.length} Available Promotion(s)</p>
                            <ul>
                                {availablePromos.map(promo => <li key={`${businessId}_${promo.id}`}>{promo.label}</li>)}
                            </ul>
                        </div>
                    }

                    {unavailablePromos.length > 0 &&
                        <div>
                            <p>{unavailablePromos.length} Unavailable Promotion(s)</p>
                            <ul>
                                {unavailablePromos.map(promo => <li key={`${businessId}_${promo.id}`}>{promo.label}</li>)}
                            </ul>
                        </div>
                    }
                </div>
            </Tooltip>
        );
    }


    return (
        <div
            className={classNames("map-landing-bus-list-card", {
                "is-active": props.isActive,
                "is-open": isOpen,
            })}
            onClick={() => props.onSelectClick(props.business)}
        >
            <div className="map-landing-bus-list-card_image">
                <img
                    src={getRestaurantIcon() || `${import.meta.env.VITE_CDN_URL}/images/placeholderitem.webp`}
                    alt={props.business.name}
                />
            </div>

            <div className="map-landing-bus-list-card_content">
                <div>
                    <h5>{props.business.name}</h5>
                    <div className="map-landing-bus-list-card_content_address">
                        {concatAddressFields(props.business.address)}
                    </div>
                </div>
                {renderTooltip()}
            </div>

            {props.isActive && props.business.isOpen &&
                <div className="map-landing-bus-list-card_btn-con">
                    <FrameButton
                        <ButtonHTMLAttributes<HTMLButtonElement>>
                        color={brandMap.colorTheme === BrandMapColorTheme.DARK ? "gray-light" : "ghost"}
                        size="normal"
                        onClick={props.onOpenRestaurantMenu}
                    >
                        Order here
                    </FrameButton>
                </div>
            }
        </div>
    );
}


export default MapLandingBusinessListCard;
