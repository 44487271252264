/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateProfilePictureBody
 */
export interface UpdateProfilePictureBody {
    /**
     * 
     * @type {string}
     * @memberof UpdateProfilePictureBody
     */
    assetID?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProfilePictureBody
     */
    nftID?: string;
}

export function UpdateProfilePictureBodyFromJSON(json: any): UpdateProfilePictureBody {
    return UpdateProfilePictureBodyFromJSONTyped(json, false);
}

export function UpdateProfilePictureBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateProfilePictureBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'assetID': !exists(json, 'assetID') ? undefined : json['assetID'],
        'nftID': !exists(json, 'nftID') ? undefined : json['nftID'],
    };
}

export function UpdateProfilePictureBodyToJSON(value?: UpdateProfilePictureBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'assetID': value.assetID,
        'nftID': value.nftID,
    };
}


