import {Dispatch, ReactElement, SetStateAction, useEffect, useRef, useState} from "react";
import {BusinessTaxonomy, BusinessTaxonomiesApi} from "@devour/client";
import FrameButton from "../../../components/buttons/FrameButton";
import classNames from "classnames";
import {RestaurantSearchFiltersQuery} from "@/utils/restaurantsSearchFilter";
import {cloneDeep} from "lodash";
import {FiArrowLeft, FiArrowRight} from "react-icons/fi";
import {isDesktop} from "react-device-detect";

interface Props {
    restaurantFilters: RestaurantSearchFiltersQuery;
    updateRestaurantFilters: Dispatch<SetStateAction<RestaurantSearchFiltersQuery>>;
}

/**
 * Sort taxonomies
 * @param a
 * @param b
 */
function sortTaxonomy(a: BusinessTaxonomy, b: BusinessTaxonomy) {
    // Sort by priority descending
    if (a.priority > b.priority) return -1;
    if (a.priority < b.priority) return 1;

    // Sort by name alphabetically ascending
    return a?.name.localeCompare(b?.name);
}

function RestaurantSearchTaxonomy(props: Props): ReactElement {
    const [
        businessTaxonomies,
        setBusinessTaxonomies,
    ] = useState<Array<BusinessTaxonomy>>([]);

    useEffect(() => {
        void fetchBusinessTaxonomies();
    }, []);

    async function fetchBusinessTaxonomies() {
        const businessTaxonomies = await new BusinessTaxonomiesApi().getBusinessTaxonomies();
        setBusinessTaxonomies(businessTaxonomies.businessTaxonomies);
    }

    const [
        leftOffset,
        setLeftOffset,
    ] = useState({
        left: 0,
        max: false,
    });

    const btnListRef = useRef<HTMLDivElement>(null);

    /**
     * We run the scroll function once on mount just to check the total width of the container, so we know if we need
     * to show the "right" arrow.
     */
    useEffect(() => {
        if (btnListRef.current) {
            slideCards(true)();
        }
    }, [businessTaxonomies]);

    useEffect(() => {
        btnListRef.current.scroll({
            left: leftOffset.left,
            behavior: "smooth",
        });
    }, [leftOffset]);

    /**
     * Scroll the categories section by a certain amount when clicking the arrows.
     * On smaller screens, just scroll by 100px, and larger screens scroll 50% of the visible width of the section.
     * This implementation isn't perfect as it doesn't account for screen resizing to know if we need to show
     * the "right" button; as we try to determine that on initial render.
     *
     * @param left
     */
    function slideCards(left: boolean): () => void {
        return () => {
            const scrollAmountPercentage = btnListRef.current?.clientWidth * 0.5;
            let scrollAmountCalculated = scrollAmountPercentage > 100
                ? scrollAmountPercentage
                : 100;

            if (!left && leftOffset.left + scrollAmountCalculated >= btnListRef?.current?.scrollWidth - btnListRef?.current?.clientWidth) {
                scrollAmountCalculated = btnListRef?.current?.scrollWidth - btnListRef?.current?.clientWidth;
                setLeftOffset({
                    left: scrollAmountCalculated,
                    max: true,
                });
            } else {
                setLeftOffset({
                    left: btnListRef.current.scrollLeft + (left
                        ? scrollAmountCalculated * -1
                        : scrollAmountCalculated),
                    max: btnListRef.current.scrollWidth <= btnListRef.current.clientWidth,
                });
            }
        };
    }

    function onTaxonomyClick(taxonomy: BusinessTaxonomy) {
        // Attempt to find this option in the taxonomy IDs array
        const newTaxonomyIds: Array<string> = cloneDeep(props.restaurantFilters.taxonomyIds);
        const thisIndex = newTaxonomyIds.findIndex((id) => id === taxonomy.id);

        if (thisIndex > -1) {
            // Remove this option from list of selected items
            newTaxonomyIds.splice(thisIndex, 1);
        } else {
            // Add this option
            newTaxonomyIds.push(taxonomy.id);
        }

        props.updateRestaurantFilters({
            ...props.restaurantFilters,
            taxonomyIds: newTaxonomyIds,
        });
    }

    function renderBusinessTaxonomy(taxonomy: BusinessTaxonomy): JSX.Element {
        return (
            <button
                key={taxonomy.id}
                className={classNames("restaurants-search-taxonomy_list_item", {
                    "is-active": props.restaurantFilters.taxonomyIds?.includes(taxonomy.id),
                })}
                type="button"
                onClick={() => onTaxonomyClick(taxonomy)}
            >
                {taxonomy.image &&
                <span className="restaurants-search-taxonomy_list_item_image">
                    <img
                        src={taxonomy.image.url}
                        alt={taxonomy?.name}
                    />
                </span>
                }
                <span className="restaurants-search-taxonomy_list_item_label">
                    {taxonomy?.name}
                </span>
            </button>
        );
    }

    return (
        <div className="restaurants-search-taxonomy">
            <div
                ref={btnListRef}
                className={classNames("restaurants-search-taxonomy_list", {
                    "restaurants-search-taxonomy_list_desktop": isDesktop,
                })}
            >
                {businessTaxonomies
                    .filter((t) => t.image)
                    .sort(sortTaxonomy)
                    .map(renderBusinessTaxonomy)}
            </div>

            <div className="restaurants-search-taxonomy_arrows">
                <div
                    className={classNames("restaurants-search-taxonomy_arrows_left", {
                        "restaurants-search-taxonomy_arrows_left_hidden": !(leftOffset.left > 0),
                    })}
                >
                    <FrameButton
                        color="purple-outline"
                        size="icon"
                        leftIcon={FiArrowLeft}
                        onClick={slideCards(true)}
                    />
                </div>

                <div
                    className={classNames("restaurants-search-taxonomy_arrows_right", {
                        "restaurants-search-taxonomy_arrows_right_hidden": leftOffset.max,
                    })}
                >
                    <FrameButton
                        color="purple-outline"
                        size="icon"
                        leftIcon={FiArrowRight}
                        onClick={slideCards(false)}
                    />
                </div>
            </div>
        </div>
    );
}

export default RestaurantSearchTaxonomy;
