/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetCompletedLevelsResponse
 */
export interface GetCompletedLevelsResponse {
    /**
     * 
     * @type {Array<number>}
     * @memberof GetCompletedLevelsResponse
     */
    completedLevels: Array<number>;
}

export function GetCompletedLevelsResponseFromJSON(json: any): GetCompletedLevelsResponse {
    return GetCompletedLevelsResponseFromJSONTyped(json, false);
}

export function GetCompletedLevelsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCompletedLevelsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'completedLevels': json['completedLevels'],
    };
}

export function GetCompletedLevelsResponseToJSON(value?: GetCompletedLevelsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'completedLevels': value.completedLevels,
    };
}


