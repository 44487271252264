import {Dispatch, ReactElement, SetStateAction, useEffect, useState} from "react";
import FrameOneModal from "@/components/modals/modalComponents/FrameOneModal";
import FrameModalBody from "@/components/modals/modalComponents/FrameModalBody";
import FrameModalNoHeaderToggle from "@/components/modals/modalComponents/FrameModalNoHeaderToggle";
import { PiPlugsConnected } from "react-icons/pi";
import {useAccount} from "wagmi";
import {ActiveDpay} from "@/utils/getMenuOrderPaymentMethod";
import useThemePreference from "@/hooks/useThemePreference";

interface Props {
    isOpen: boolean;
    toggle: () => void;
    resetCartWrapper: () => Promise<void>;
    insufficientExternal: boolean;
    setActiveDpayMethod: Dispatch<SetStateAction<ActiveDpay>>;
}

function CheckoutWalletConnectModal(props: Props): ReactElement {
    const { isOnDarkMode } = useThemePreference();

    const account = useAccount();

    const [
        startingConnected,
        setStartingConnected,
    ] = useState<boolean>(account?.isConnected);

    useEffect(() => {
        if (!props.isOpen && startingConnected && !account?.isConnected) {
            props.setActiveDpayMethod(undefined);
            void props.resetCartWrapper();
        } else {
            setStartingConnected(account?.isConnected);
        }
    }, [props.isOpen]);

    useEffect(() => {
        if (props.isOpen) {
            if (!startingConnected && account?.isConnected) {
                props.toggle();
            }
        }

    }, [
        props.isOpen,
        account?.isConnected,
    ]);

    return (
        <FrameOneModal
            isOpen={props.isOpen}
            toggle={props.toggle}
            contentClassName="checkout-payments-insufficient-modal"
        >
            <FrameModalBody className="checkout-payments-insufficient-modal_body">

                <FrameModalNoHeaderToggle toggle={props.toggle} />

                <div className="checkout-payments-insufficient-modal_content">
                    <PiPlugsConnected className="checkout-payments-insufficient-modal_body_icon" />

                    <h3 className="checkout-payments-insufficient-modal_title">
                        Wallet Connect
                    </h3>

                    <p className="checkout-payments-insufficient-modal_body_description">
                       To proceed with your order, please connect your external wallet using the Wallet Connect button below.
                    </p>

                    <w3m-button/>
                </div>

            </FrameModalBody>

        </FrameOneModal>
    );
}

export default CheckoutWalletConnectModal;