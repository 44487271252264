/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TopLeaderboardCommunity,
    TopLeaderboardCommunityFromJSON,
    TopLeaderboardCommunityFromJSONTyped,
    TopLeaderboardCommunityToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetCurrentTopLeaderboardRankingsResponse
 */
export interface GetCurrentTopLeaderboardRankingsResponse {
    /**
     * 
     * @type {Array<TopLeaderboardCommunity>}
     * @memberof GetCurrentTopLeaderboardRankingsResponse
     */
    leaderboardRankings: Array<TopLeaderboardCommunity>;
}

export function GetCurrentTopLeaderboardRankingsResponseFromJSON(json: any): GetCurrentTopLeaderboardRankingsResponse {
    return GetCurrentTopLeaderboardRankingsResponseFromJSONTyped(json, false);
}

export function GetCurrentTopLeaderboardRankingsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCurrentTopLeaderboardRankingsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'leaderboardRankings': ((json['leaderboardRankings'] as Array<any>).map(TopLeaderboardCommunityFromJSON)),
    };
}

export function GetCurrentTopLeaderboardRankingsResponseToJSON(value?: GetCurrentTopLeaderboardRankingsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'leaderboardRankings': ((value.leaderboardRankings as Array<any>).map(TopLeaderboardCommunityToJSON)),
    };
}


