/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ImportUserResponseItem,
    ImportUserResponseItemFromJSON,
    ImportUserResponseItemFromJSONTyped,
    ImportUserResponseItemToJSON,
} from './';

/**
 * 
 * @export
 * @interface ImportUserResponse
 */
export interface ImportUserResponse {
    /**
     * 
     * @type {Array<ImportUserResponseItem>}
     * @memberof ImportUserResponse
     */
    results?: Array<ImportUserResponseItem>;
}

export function ImportUserResponseFromJSON(json: any): ImportUserResponse {
    return ImportUserResponseFromJSONTyped(json, false);
}

export function ImportUserResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImportUserResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'results': !exists(json, 'results') ? undefined : ((json['results'] as Array<any>).map(ImportUserResponseItemFromJSON)),
    };
}

export function ImportUserResponseToJSON(value?: ImportUserResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'results': value.results === undefined ? undefined : ((value.results as Array<any>).map(ImportUserResponseItemToJSON)),
    };
}


