import {useQuery} from "@tanstack/react-query";
import {MenuOrdersApi} from "@devour/client";
import getConfig from "@/utils/getConfig";

export const useCancelMenuOrderCheck = (menuOrderId: string) => {

    // Define the query function directly inside useQuery
    const result = useQuery({
        queryKey: [
            "menuOrderCancelCheck",
            menuOrderId,
        ],
        queryFn: async () => {
            return new MenuOrdersApi(getConfig()).cancelMenuOrderCheck({ id: menuOrderId });
        },
        // Options object
        enabled: !!menuOrderId, // Conditional fetching based on menuOrderId presence
    });

    return {
        data: result.data,
        refetch: result.refetch,
        isLoading: result.isLoading,
    };
};