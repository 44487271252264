import { useRestaurant } from "@/hooks/useRestaurant";
import useThemePreference from "@/hooks/useThemePreference";
import { completedTrackingStatuses, ongoingTrackingStatuses, OrderTrackerData } from "@/types/OrderTrackerData";
import decodePolyline from "@/utils/decodePolyline";
import getCoordinatesData from "@/utils/getCoordinatesData";
import { GeoPoint, MenuOrder } from "@devour/client";
import { GoogleMap, LoadScript, Marker, Polyline } from "@react-google-maps/api";
import { FC, useEffect, useMemo, useRef, useState } from "react";

// Common styles to hide POI and transit
const commonStyles = [
    {
        featureType: "poi",
        stylers: [{ visibility: "off" }],
    },
    {
        featureType: "transit",
        stylers: [{ visibility: "off" }],
    },
];

// Dark theme styles
const darkThemeStyles = [
    ...commonStyles,
    { elementType: "geometry",
        stylers: [{ color: "#242f3e" }] },
    { elementType: "labels.text.stroke",
        stylers: [{ color: "#242f3e" }] },
    { elementType: "labels.text.fill",
        stylers: [{ color: "#746855" }] },
    {
        featureType: "administrative.locality",
        elementType: "labels.text.fill",
        stylers: [{ color: "#d59563" }],
    },
    {
        featureType: "poi.park",
        elementType: "geometry",
        stylers: [{ color: "#263c3f" }],
    },
    {
        featureType: "poi.park",
        elementType: "labels.text.fill",
        stylers: [{ color: "#6b9a76" }],
    },
    {
        featureType: "road",
        elementType: "geometry",
        stylers: [{ color: "#38414e" }],
    },
    {
        featureType: "road",
        elementType: "geometry.stroke",
        stylers: [{ color: "#212a37" }],
    },
    {
        featureType: "road",
        elementType: "labels.text.fill",
        stylers: [{ color: "#9ca5b3" }],
    },
    {
        featureType: "road.highway",
        elementType: "geometry",
        stylers: [{ color: "#746855" }],
    },
    {
        featureType: "road.highway",
        elementType: "geometry.stroke",
        stylers: [{ color: "#1f2835" }],
    },
    {
        featureType: "road.highway",
        elementType: "labels.text.fill",
        stylers: [{ color: "#f3d19c" }],
    },
    {
        featureType: "transit",
        elementType: "geometry",
        stylers: [{ color: "#2f3948" }],
    },
    {
        featureType: "transit.station",
        elementType: "labels.text.fill",
        stylers: [{ color: "#d59563" }],
    },
    {
        featureType: "water",
        elementType: "geometry",
        stylers: [{ color: "#17263c" }],
    },
    {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [{ color: "#515c6d" }],
    },
    {
        featureType: "water",
        elementType: "labels.text.stroke",
        stylers: [{ color: "#17263c" }],
    },
];

// Light theme styles
const lightThemeStyles = [...commonStyles];

const generateCurvePoints = (
    start: { lat: number; lng: number },
    end: { lat: number; lng: number },
    curvature: number,
) => {
    const points: { lat: number; lng: number }[] = [];
    const numPoints = 100; // The number of points to generate along the curve
    for (let i = 0; i <= numPoints; i++) {
        const t = i / numPoints;

        // Calculate intermediate point using quadratic Bezier curve formula
        const lat = (1 - t) * (1 - t) * start.lat + 2 * (1 - t) * t * (start.lat + curvature) + t * t * end.lat;
        const lng = (1 - t) * (1 - t) * start.lng + 2 * (1 - t) * t * (start.lng + curvature) + t * t * end.lng;

        points.push({ lat,
            lng });
    }
    return points;
};

interface props {
    order: MenuOrder;
    trackerData?: OrderTrackerData;
}

const MenuOrderSuccessTrackerMap: FC<props> = ({ order, trackerData }) => {
    const [mapRef, setMapRef] = useState<google.maps.Map | null>(null);
    const {data: restaurant} = useRestaurant(order?.business);
    const { center, zoomLevel, distanceInMiles } = getCoordinatesData(
        order.address.location,
        restaurant?.address.location as GeoPoint,
    );
    useEffect(() => {
        if (mapRef) {
            mapRef.setCenter({
                lat: center.coordinates[1],
                lng: center.coordinates[0],
            });
            mapRef.setZoom(zoomLevel);
        }
    }, [mapRef]);

    const { isOnDarkMode, getThemedImageUrl } = useThemePreference();

    const canShowDelivery =
        trackerData?.runner && JSON.stringify(trackerData?.runner?.location?.coordinates) !== JSON.stringify([0, 0]);

    const renderTracePath = () => {
        const start = order.address.location;
        const end = restaurant?.address.location as GeoPoint;
        const isVisible = [...completedTrackingStatuses, ...ongoingTrackingStatuses].includes(trackerData.fulfillment.status);
        const isCompleted = completedTrackingStatuses.includes(trackerData.fulfillment.status);
        const lineSymbol: google.maps.Symbol = {
            path: "M 0,-1 0,1",
            strokeOpacity: isVisible ? 1 : 0,
            scale: 3,
            strokeColor: isOnDarkMode ? "#5D4C9A" : "#7859E6",
        };

        let polylinePath = generateCurvePoints(
            { lat: start.coordinates[1],
                lng: start.coordinates[0] },
            { lat: end.coordinates[1],
                lng: end.coordinates[0] },
            distanceInMiles > 1 ? 0.02 : 0,
        );
        if (trackerData.fulfillment.delivery_route && isVisible) {
            polylinePath = decodePolyline(trackerData.fulfillment.delivery_route);
        }

        return (
            <Polyline
                path={polylinePath}
                options={{
                    strokeOpacity: 0,
                    icons: [
                        {
                            icon: lineSymbol,
                            offset: "1%",
                            repeat: !isCompleted ? "13px" : "2px",
                        },
                    ],
                }}
            />
        );
    };

    return (
        <LoadScript googleMapsApiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY_FRONTEND_ONLY}>
            <GoogleMap
                onLoad={(map) => {
                    setMapRef(map);
                }}
                options={{
                    disableDefaultUI: true,
                    streetViewControl: false,
                    panControl: true,
                    keyboardShortcuts: true,
                    scaleControl: false,
                    clickableIcons: false,
                    zoomControl: true,
                    backgroundColor: "black",
                    styles: isOnDarkMode ? darkThemeStyles : lightThemeStyles,
                }}
                mapContainerClassName="menu-order-tracker-map"
                mapContainerStyle={{
                    width: "100%",
                    height: "15rem",
                    borderRadius: "0.625rem",
                }}
            >
                <>
                    {trackerData && renderTracePath()}
                    <Marker
                        key={`menu-order-tracker-data_addr_${order.id}`}
                        onClick={() => {}}
                        opacity={1}
                        icon={getThemedImageUrl(`${import.meta.env.VITE_CDN_URL}/images/home-marker.webp`)}
                        position={{
                            lat: order.address.location.coordinates[1],
                            lng: order.address.location.coordinates[0],
                        }}
                    />

                    {canShowDelivery && <Marker
                        key={`menu-order-tracker-data_addr_${order.id}`}
                        onClick={() => {}}
                        opacity={1}
                        icon={getThemedImageUrl(`${import.meta.env.VITE_CDN_URL}/images/delivery-marker.webp`)}
                        position={{
                            lat: trackerData?.runner?.location?.coordinates[1] ?? 0,
                            lng: trackerData?.runner?.location?.coordinates[0] ?? 0,
                        }}
                    />}

                    <Marker
                        key={`menu-order-tracker-data_merchant_${order.id}`}
                        onClick={() => {}}
                        opacity={1}
                        icon={getThemedImageUrl(`${import.meta.env.VITE_CDN_URL}/images/store-marker.webp`)}
                        position={{
                            lat: restaurant?.address.location.coordinates[1],
                            lng: restaurant?.address.location.coordinates[0],
                        }}
                    />
                </>
            </GoogleMap>
        </LoadScript>
    );
};

export default MenuOrderSuccessTrackerMap;
