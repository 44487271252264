/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './';

/**
 * 
 * @export
 * @interface DpayUserItem
 */
export interface DpayUserItem {
    /**
     * 
     * @type {User}
     * @memberof DpayUserItem
     */
    user_info: User;
    /**
     * 
     * @type {number}
     * @memberof DpayUserItem
     */
    totalDpay: number;
}

export function DpayUserItemFromJSON(json: any): DpayUserItem {
    return DpayUserItemFromJSONTyped(json, false);
}

export function DpayUserItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): DpayUserItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user_info': json['user_info'],
        'totalDpay': json['totalDpay'],
    };
}

export function DpayUserItemToJSON(value?: DpayUserItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user_info': value.user_info,
        'totalDpay': value.totalDpay,
    };
}


