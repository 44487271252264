/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BrandToMerchantPermissions,
    BrandToMerchantPermissionsFromJSON,
    BrandToMerchantPermissionsFromJSONTyped,
    BrandToMerchantPermissionsToJSON,
    GetBrandMerchantsResponseAllOf,
    GetBrandMerchantsResponseAllOfFromJSON,
    GetBrandMerchantsResponseAllOfFromJSONTyped,
    GetBrandMerchantsResponseAllOfToJSON,
    PaginatedResponse,
    PaginatedResponseFromJSON,
    PaginatedResponseFromJSONTyped,
    PaginatedResponseToJSON,
    PaginationInfo,
    PaginationInfoFromJSON,
    PaginationInfoFromJSONTyped,
    PaginationInfoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetBrandMerchantsResponse
 */
export interface GetBrandMerchantsResponse extends PaginatedResponse {
    /**
     * 
     * @type {Array<BrandToMerchantPermissions>}
     * @memberof GetBrandMerchantsResponse
     */
    brandMerchants: Array<BrandToMerchantPermissions>;
}

export function GetBrandMerchantsResponseFromJSON(json: any): GetBrandMerchantsResponse {
    return GetBrandMerchantsResponseFromJSONTyped(json, false);
}

export function GetBrandMerchantsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetBrandMerchantsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...PaginatedResponseFromJSONTyped(json, ignoreDiscriminator),
        'brandMerchants': ((json['brandMerchants'] as Array<any>).map(BrandToMerchantPermissionsFromJSON)),
    };
}

export function GetBrandMerchantsResponseToJSON(value?: GetBrandMerchantsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...PaginatedResponseToJSON(value),
        'brandMerchants': ((value.brandMerchants as Array<any>).map(BrandToMerchantPermissionsToJSON)),
    };
}


