import {ReactElement, useEffect, useState} from "react";
import {useRouteError, useLocation} from "react-router-dom";
import {FrontendErrorsApi} from "@devour/client";
import getConfig from "@/utils/getConfig";
import * as Sentry from "@sentry/react";

function ErrorPage(): ReactElement {
    const error = useRouteError();
    const location = useLocation();
    const [
        errorId,
        setErrorId,
    ] = useState<string>("");

    useEffect(() => {
        void postFrontendError();
    }, [error]);

    async function postFrontendError(): Promise<void> {
        if (error instanceof Error) {
            try {
                const res = await new FrontendErrorsApi(getConfig()).createFrontendError({
                    frontendErrorBody: {
                        stack: error.stack,
                        message: error.message,
                        route: location.pathname,
                        search: location.search,
                    },
                });
                setErrorId(res.errorId);
            } finally {
                Sentry.captureException(error);
            }
        }
    }

    return (
        <div className="error-page container">
            <h2>
				Something went wrong with your request.
            </h2>
            <h4>
				Error ID: {errorId}
            </h4>
            <p>
				An unexpected error has occurred. Please refresh the page and try again.
				If the issue persists, please contact support about this problem and include the above ID.
            </p>
        </div>
    );
}

export default ErrorPage;

