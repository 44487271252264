/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateNotificationsBody
 */
export interface UpdateNotificationsBody {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateNotificationsBody
     */
    notificationMarketing: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateNotificationsBody
     */
    notificationMenuOrderStatus: boolean;
}

export function UpdateNotificationsBodyFromJSON(json: any): UpdateNotificationsBody {
    return UpdateNotificationsBodyFromJSONTyped(json, false);
}

export function UpdateNotificationsBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateNotificationsBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'notificationMarketing': json['notificationMarketing'],
        'notificationMenuOrderStatus': json['notificationMenuOrderStatus'],
    };
}

export function UpdateNotificationsBodyToJSON(value?: UpdateNotificationsBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'notificationMarketing': value.notificationMarketing,
        'notificationMenuOrderStatus': value.notificationMenuOrderStatus,
    };
}


