import {ButtonHTMLAttributes, ReactElement, useContext, useState} from "react";
import {BrandMap, BrandMapColorTheme, LoginAccessPoint} from "@devour/client";
import FrameButton from "@/components/buttons/FrameButton";
import classNames from "classnames";
import {isDesktop, isTablet} from "react-device-detect";
import {FaArrowRightLong, FaMinus, FaPlus} from "react-icons/fa6";
import {truncateText} from "@/utils/truncateText";
import {BrandMapContext, BrandMapStates} from "@/pages/brandMap/context/BrandMapContext";
import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {BsArrowRight} from "react-icons/bs";

interface Props {
    brandMap: BrandMap;
    toggleEmailModal?: (showModal: boolean) => void;
}

function BrandLandingMainPromo(props: Props): ReactElement {
    const {brandMap, toggleEmailModal} = props;
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const [showAllDescription, setShowAllDescription] = useState<boolean>(false);
    const {
        brandMapState,
        setBrandMapState,
        selectedPromo,
        setSelectedPromo,
    } = useContext(BrandMapContext);
    const promoSelectionStep = brandMapState === BrandMapStates.SELECT_PROMOTION;
    const isTextDark = props.brandMap.colorTheme === BrandMapColorTheme.DARK;

    if (!brandMap?.mainPromo) {
        return null;
    }

    const availableQuantity = props.brandMap.mainPromo.maxRedemptions - props.brandMap.mainPromo.timesRedeemed;

    function redeemButtonOnClick() {
        if (promoSelectionStep) {
            return;
        }

        if (brandMap.loginAccessPoint === LoginAccessPoint.PAGE && !fullToken) {
            toggleEmailModal?.(true);
        } else {
            setBrandMapState(BrandMapStates.SELECT_PROMOTION);
        }
    }

    function renderRedeemButton() {
        if (promoSelectionStep) {
            return;
        }

        return (
            <FrameButton
                <ButtonHTMLAttributes<HTMLButtonElement>>
                color={isTextDark ? "gray-light" : "ghost"}
                size="large"
                className="brand-map-promotions_main-promo_redeem"
                onClick={redeemButtonOnClick}
            >
                Redeem Now <BsArrowRight />
            </FrameButton>
        );
    }

    function renderPromoDescription(): ReactElement {
        if (!brandMap.promoDescription) {
            return;
        }
        const textMaxLength = isDesktop || isTablet ? 225 : 140;
        return (
            <div className="brand-map-promotions_main-promo_description">
                {showAllDescription
                    ? brandMap.promoDescription
                    : `${truncateText(textMaxLength, brandMap.promoDescription)} `
                }
                {brandMap.promoDescription.length >= textMaxLength &&
                    <span onClick={() => setShowAllDescription(!showAllDescription)}>
                        {showAllDescription ? "Read Less" : "Read More"}
                    </span>
                }
            </div>
        );
    }

    function renderSelectPromoButton(): ReactElement {
        const isPromoSelected = selectedPromo === brandMap.mainPromo.id;

        if (brandMap.mainPromo.isBrandMapAutoApplied) {
            return <span className="brand-map-promotions_others_card_auto-applied-tag">Auto-applied</span>;
        }

        return (
            <FrameButton
                <ButtonHTMLAttributes<HTMLButtonElement>>
                color="gray"
                size="icon"
                rightIcon={isPromoSelected ? FaMinus : FaPlus}
                className={`brand-map-promotions_icon-btn ${isPromoSelected ? "deselect" : "select"}`}
                onClick={() => setSelectedPromo(isPromoSelected ? "" : brandMap.mainPromo.id)}
            />
        );
    }

    return (
        <div
            className={classNames(
                "brand-map-promotions_main-promo",
                "brand-map-promotions_card", {
                    "brand-map-promotions_main-promo_no-bg": !brandMap?.promoBackground,
                    "is-selected": selectedPromo === brandMap.mainPromo.id || brandMap.mainPromo.isBrandMapAutoApplied,
                },
            )}
            style={{
                backgroundImage: `url(${brandMap.promoBackground?.url})`,
            }}
        >
            {promoSelectionStep && renderSelectPromoButton()}
            <div className="brand-map-promotions_main-promo_hero">
                <img
                    src={brandMap.promoHero?.url || `${import.meta.env.VITE_CDN_URL}/images/landing-background.webp`}
                    alt={brandMap.promoTitle}
                />
            </div>

            <div className={classNames(
                "brand-map-promotions_main-promo_details",
                {"is-tablet": isTablet},
            )}>
                <div>
                    {availableQuantity > 0 &&
                        <div className="brand-map-promotions_main-promo_pill brand-map-promotions_pill-color">
                            {`${availableQuantity} Available`}
                        </div>
                    }
                    <div className="brand-map-promotions_main-promo_title">
                        {brandMap.promoTitle}
                    </div>

                    <div className="brand-map-promotions_main-promo_tagline">
                        {brandMap.promoTagline}
                    </div>

                    {isTablet && renderRedeemButton()}
                </div>


                {!isTablet &&
                    <>
                        {renderPromoDescription()}
                        {renderRedeemButton()}
                    </>
                }


                {isTablet && renderPromoDescription()}
            </div>

        </div>
    );
}

export default BrandLandingMainPromo;
