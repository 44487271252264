/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DpayPurchaseRequestBody
 */
export interface DpayPurchaseRequestBody {
    /**
     * 
     * @type {number}
     * @memberof DpayPurchaseRequestBody
     */
    dpay?: number;
    /**
     * 
     * @type {number}
     * @memberof DpayPurchaseRequestBody
     */
    fiat?: number;
    /**
     * 
     * @type {string}
     * @memberof DpayPurchaseRequestBody
     */
    paymentMethodId?: string;
}

export function DpayPurchaseRequestBodyFromJSON(json: any): DpayPurchaseRequestBody {
    return DpayPurchaseRequestBodyFromJSONTyped(json, false);
}

export function DpayPurchaseRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): DpayPurchaseRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dpay': !exists(json, 'dpay') ? undefined : json['dpay'],
        'fiat': !exists(json, 'fiat') ? undefined : json['fiat'],
        'paymentMethodId': !exists(json, 'paymentMethodId') ? undefined : json['paymentMethodId'],
    };
}

export function DpayPurchaseRequestBodyToJSON(value?: DpayPurchaseRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dpay': value.dpay,
        'fiat': value.fiat,
        'paymentMethodId': value.paymentMethodId,
    };
}


