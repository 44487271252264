/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BrandToMerchantPermissions,
    BrandToMerchantPermissionsFromJSON,
    BrandToMerchantPermissionsFromJSONTyped,
    BrandToMerchantPermissionsToJSON,
    GetMerchantBrandsResponseAllOf,
    GetMerchantBrandsResponseAllOfFromJSON,
    GetMerchantBrandsResponseAllOfFromJSONTyped,
    GetMerchantBrandsResponseAllOfToJSON,
    PaginatedResponse,
    PaginatedResponseFromJSON,
    PaginatedResponseFromJSONTyped,
    PaginatedResponseToJSON,
    PaginationInfo,
    PaginationInfoFromJSON,
    PaginationInfoFromJSONTyped,
    PaginationInfoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetMerchantBrandsResponse
 */
export interface GetMerchantBrandsResponse extends PaginatedResponse {
    /**
     * 
     * @type {Array<BrandToMerchantPermissions>}
     * @memberof GetMerchantBrandsResponse
     */
    merchantBrands: Array<BrandToMerchantPermissions>;
}

export function GetMerchantBrandsResponseFromJSON(json: any): GetMerchantBrandsResponse {
    return GetMerchantBrandsResponseFromJSONTyped(json, false);
}

export function GetMerchantBrandsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetMerchantBrandsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...PaginatedResponseFromJSONTyped(json, ignoreDiscriminator),
        'merchantBrands': ((json['merchantBrands'] as Array<any>).map(BrandToMerchantPermissionsFromJSON)),
    };
}

export function GetMerchantBrandsResponseToJSON(value?: GetMerchantBrandsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...PaginatedResponseToJSON(value),
        'merchantBrands': ((value.merchantBrands as Array<any>).map(BrandToMerchantPermissionsToJSON)),
    };
}


