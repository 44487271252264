import {ReactElement} from "react";
import {BsHouseDoorFill} from "react-icons/bs";
import {Address} from "@devour/client";
import FrameButton from "../buttons/FrameButton";
import {concatAddressFields} from "../MapLandingBusinessListCard";

interface Props {
    address: Address;
    onEdit: () => void;
}

function RestaurantAddressEntryPromptAddressDisplayCard(props: Props): ReactElement {

    return (
        <div className="rest-addy-p-d-card">
            <div className="rest-addy-p-d-card_left">
                <BsHouseDoorFill/>
            </div>

            <div className="rest-addy-p-d-card_center">
                <div className="rest-addy-p-d-card_center_details">
                    <h5>
                        {concatAddressFields(props.address, [
                            "line1",
                            "line2",
                        ])}
                    </h5>
                    <p>
                        {concatAddressFields(props.address, [
                            "locality",
                            "administrativeArea",
                        ])}
                    </p>
                </div>
            </div>

            <div className="rest-addy-p-d-card_right">
                <FrameButton
                    color="purple-text-no-border"
                    size="narrow"
                    onClick={props.onEdit}
                >
					Edit
                </FrameButton>
            </div>
        </div>
    );
}

export default RestaurantAddressEntryPromptAddressDisplayCard;