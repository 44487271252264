import {ReactElement} from "react";
import Skeleton from "react-loading-skeleton";
import FrameButton from "@/components/buttons/FrameButton";

interface Props {
    width?: number | string;
}

function GoFrensCardsSkeleton(props: Props): ReactElement {
    return (
        <>
            {Array.from({length: 6}, (_, i) => <div key={i}>
                <div
                    className="gofrens-card gofrens-card_skeleton-adjust"
                    style={{
                        minWidth: props.width,
                        maxWidth: props.width,
                    }}
                >
                    <div
                        className="react-loading-skeleton gofrens-card_image-container_skeleton-adjust"
                        style={{
                            minWidth: props.width,
                            maxWidth: props.width,
                        }}
                    />
                    <div
                        className="nft-ownership-card_thumb-con nft-ownership-card_thumb-con_loading"
                    />
                    <div className="gofrens-card_content">
                        <div className="gofrens-card_content_inner">
                            <h4 style={{flex: 1}}><Skeleton/></h4>

                            <p className="gofrens-card_content_description">
                                <Skeleton/>
                            </p>
                            <p className="gofrens-card_content_description">
                                <Skeleton/>
                            </p>
                            <FrameButton
                                color="gray"
                                size="narrow"
                                className="react-loading-skeleton gofrens-card_button-skeleton"
                            />
                        </div>
                    </div>
                </div>
            </div>)}
        </>
    );
}

export default GoFrensCardsSkeleton;