/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TransactionsPaginated,
    TransactionsPaginatedFromJSON,
    TransactionsPaginatedFromJSONTyped,
    TransactionsPaginatedToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetTransactionsPaginatedResponse
 */
export interface GetTransactionsPaginatedResponse {
    /**
     * 
     * @type {number}
     * @memberof GetTransactionsPaginatedResponse
     */
    balance: number;
    /**
     * 
     * @type {number}
     * @memberof GetTransactionsPaginatedResponse
     */
    dPayPricePerUsd?: number;
    /**
     * 
     * @type {number}
     * @memberof GetTransactionsPaginatedResponse
     */
    balanceInUsd?: number;
    /**
     * 
     * @type {TransactionsPaginated}
     * @memberof GetTransactionsPaginatedResponse
     */
    transactions: TransactionsPaginated;
}

export function GetTransactionsPaginatedResponseFromJSON(json: any): GetTransactionsPaginatedResponse {
    return GetTransactionsPaginatedResponseFromJSONTyped(json, false);
}

export function GetTransactionsPaginatedResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetTransactionsPaginatedResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'balance': json['balance'],
        'dPayPricePerUsd': !exists(json, 'dPayPricePerUsd') ? undefined : json['dPayPricePerUsd'],
        'balanceInUsd': !exists(json, 'balanceInUsd') ? undefined : json['balanceInUsd'],
        'transactions': TransactionsPaginatedFromJSON(json['transactions']),
    };
}

export function GetTransactionsPaginatedResponseToJSON(value?: GetTransactionsPaginatedResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'balance': value.balance,
        'dPayPricePerUsd': value.dPayPricePerUsd,
        'balanceInUsd': value.balanceInUsd,
        'transactions': TransactionsPaginatedToJSON(value.transactions),
    };
}


