import {ButtonHTMLAttributes, ReactElement, useEffect, useState} from "react";
import FrameOneAutoPanel from "@/components/modals/autoPanelComponents/FrameOneAutoPanel";
import FrameAutoPanelBody from "@/components/modals/autoPanelComponents/FrameAutoPanelBody";
import {GoFrensTutorialSteps} from "@/components/modals/GoFrensTutorialModal/GoFrensTutorialSteps";
import GoFrensTutorialInitial from "@/components/modals/GoFrensTutorialModal/GoFrensTutorialInitial";
import {IoClose} from "react-icons/io5";
import GoFrensTutorialNabNft from "@/components/modals/GoFrensTutorialModal/GoFrensTutorialNabNft";
import {MdOutlineKeyboardArrowLeft} from "react-icons/md";
import classNames from "classnames";
import FrameButton from "@/components/buttons/FrameButton";
import GoFrensTutorialRepCommunity from "@/components/modals/GoFrensTutorialModal/GoFrensTutorialRepCommunity";
import GoFrensTutorialRackExp from "@/components/modals/GoFrensTutorialModal/GoFrensTutorialRackExp";
import GoFrensTutorialUnlockRewards from "@/components/modals/GoFrensTutorialModal/GoFrensTutorialUnlockRewards";

interface Props {
    isOpen: boolean;
    toggle: () => void;
}

function GoFrensTutorialModal(props: Props): ReactElement {

    const [
        tutorialStep,
        setTutorialStep,
    ] = useState<GoFrensTutorialSteps>(GoFrensTutorialSteps.NAB_NFT);

    useEffect(() => {
        // Reset steps back to initial when modal opens / closes
        setTutorialStep(GoFrensTutorialSteps.INITIAL);
    }, [props.isOpen]);

    function renderBody(): ReactElement {
        switch (tutorialStep) {
            case GoFrensTutorialSteps.NAB_NFT:
                return (
                    <GoFrensTutorialNabNft
                        className="shown"
                    />
                );
            case GoFrensTutorialSteps.REP_COMMUNITY:
                return (
                    <GoFrensTutorialRepCommunity
                        toggle={props.toggle}
                        className="shown"
                    />
                );
            case GoFrensTutorialSteps.RACK_EXP:
                return (
                    <GoFrensTutorialRackExp
                        className="shown"
                    />
                );
            case GoFrensTutorialSteps.UNLOCK_REWARDS:
                return (
                    <GoFrensTutorialUnlockRewards
                        toggle={props.toggle}
                        className="shown"
                    />
                );
            default:
                return (
                    <GoFrensTutorialInitial
                        setTutorialStep={setTutorialStep}
                    />
                );
        }
    }

    function goBack(): void {
        switch (tutorialStep) {
            case GoFrensTutorialSteps.NAB_NFT:
                setTutorialStep(GoFrensTutorialSteps.INITIAL);
                break;
            case GoFrensTutorialSteps.REP_COMMUNITY:
                setTutorialStep(GoFrensTutorialSteps.NAB_NFT);
                break;
            case GoFrensTutorialSteps.RACK_EXP:
                setTutorialStep(GoFrensTutorialSteps.REP_COMMUNITY);
                break;
            case GoFrensTutorialSteps.UNLOCK_REWARDS:
                setTutorialStep(GoFrensTutorialSteps.RACK_EXP);
                break;
        }
    }

    function goNext(): void {
        switch (tutorialStep) {
            case GoFrensTutorialSteps.NAB_NFT:
                setTutorialStep(GoFrensTutorialSteps.REP_COMMUNITY);
                break;
            case GoFrensTutorialSteps.REP_COMMUNITY:
                setTutorialStep(GoFrensTutorialSteps.RACK_EXP);
                break;
            case GoFrensTutorialSteps.RACK_EXP:
                setTutorialStep(GoFrensTutorialSteps.UNLOCK_REWARDS);
                break;
            case GoFrensTutorialSteps.UNLOCK_REWARDS:
                props.toggle();
                break;
        }
    }

    return (
        <FrameOneAutoPanel
            isOpen={props.isOpen}
            toggle={props.toggle}
            contentClassName={classNames("gofrens-tutorial-modal", `step-${tutorialStep}`)}
            size="md"
            modalOnTablet={true}
        >
            {tutorialStep !== GoFrensTutorialSteps.INITIAL &&
            <div className="gofrens-tutorial-modal_back">
                <button
                    className="reset-button"
                    onClick={goBack}
                >
                    <MdOutlineKeyboardArrowLeft/>
                    <span>Back</span>
                </button>
            </div>
            }
            <div className="gofrens-tutorial-modal_close">
                <button
                    className="reset-button"
                    onClick={props.toggle}
                >
                    <IoClose/>
                </button>
            </div>
            <div className="gofrens-tutorial-modal_background-image gofrens-tutorial-modal_background-image-badge">
                <img
                    src={import.meta.env.VITE_CDN_URL + "/images/tutorial-badge.webp"}
                    alt="Badge"
                />
            </div>
            <div className="gofrens-tutorial-modal_background-image gofrens-tutorial-modal_background-image-diamond">
                <img
                    src={import.meta.env.VITE_CDN_URL + "/images/tutorial-diamond.webp"}
                    alt="Diamond"
                />
            </div>
            <div className="gofrens-tutorial-modal_background-image gofrens-tutorial-modal_background-image-game">
                <img
                    src={import.meta.env.VITE_CDN_URL + "/images/tutorial-game.webp"}
                    alt="Game"
                />
            </div>
            <div className="gofrens-tutorial-modal_background-image gofrens-tutorial-modal_background-image-gift">
                <img
                    src={import.meta.env.VITE_CDN_URL + "/images/Tutorial-Gift.webp"}
                    alt="Gift"
                />
            </div>
            <div className="gofrens-tutorial-modal_background-image gofrens-tutorial-modal_background-image-rank">
                <img
                    src={import.meta.env.VITE_CDN_URL + "/images/tutorial-rank.webp"}
                    alt="Rank"
                />
            </div>
            <div className="gofrens-tutorial-modal_background-image gofrens-tutorial-modal_background-image-trophy">
                <img
                    src={import.meta.env.VITE_CDN_URL + "/images/tutorial-trophy.webp"}
                    alt="Trophy"
                />
            </div>

            <FrameAutoPanelBody
                className="gofrens-tutorial-modal_body"
                modalOnTablet={true}
            >
                {renderBody()}

                {tutorialStep !== GoFrensTutorialSteps.INITIAL &&
                <>
                    <div className="gofrens-tutorial-modal_controls">
                        <button
                            className={classNames("reset-button", {
                                "is-active": tutorialStep === GoFrensTutorialSteps.NAB_NFT,
                            })}
                            onClick={() => setTutorialStep(GoFrensTutorialSteps.NAB_NFT)}
                            title="Nab an NFT"
                        >
                                &#x2022;
                        </button>
                        <button
                            className={classNames("reset-button", {
                                "is-active": tutorialStep === GoFrensTutorialSteps.REP_COMMUNITY,
                            })}
                            onClick={() => setTutorialStep(GoFrensTutorialSteps.REP_COMMUNITY)}
                            title="Rep a Community"
                        >
                                &#x2022;
                        </button>
                        <button
                            className={classNames("reset-button", {
                                "is-active": tutorialStep === GoFrensTutorialSteps.RACK_EXP,
                            })}
                            onClick={() => setTutorialStep(GoFrensTutorialSteps.RACK_EXP)}
                            title="Rack Up Exp"
                        >
                                &#x2022;
                        </button>
                        <button
                            className={classNames("reset-button", {
                                "is-active": tutorialStep === GoFrensTutorialSteps.UNLOCK_REWARDS,
                            })}
                            onClick={() => setTutorialStep(GoFrensTutorialSteps.UNLOCK_REWARDS)}
                            title="Unlock Rewards"
                        >
                                &#x2022;
                        </button>
                    </div>
                    <div className="gofrens-tutorial-modal_next">
                        <FrameButton
                            <ButtonHTMLAttributes<HTMLButtonElement>>
                            color="purple-outline"
                            size="large"
                            onClick={goNext}
                        >
                            {tutorialStep === GoFrensTutorialSteps.UNLOCK_REWARDS
                                ? "Begin Your Adventure!"
                                : "Next"}
                        </FrameButton>
                    </div>
                </>
                }

            </FrameAutoPanelBody>
        </FrameOneAutoPanel>
    );
}

export default GoFrensTutorialModal;
