import {ReactElement} from "react";
import {NftGrouping, NftOwnership} from "@devour/client";
import {useNftInformationFromUri} from "@/hooks/useNftInformationFromUri";

interface Props {
    ownership: NftOwnership
    grouping: NftGrouping;
}

function GoFrensOwnershipRowInfo(props: Props): ReactElement {

    const [
        _loading,
        nftInformation,
    ] = useNftInformationFromUri(props.ownership.tokenUri);

    return (
        <div className="gofrens-ownership-row">
            <div className="gofrens-ownership-row_graphic-container">
                {nftInformation?.imageBase64 && nftInformation?.imageBase64.startsWith("data:video")
                    ? <video
                        className="gofrens-ownership-row_graphic"
                        loop
                        autoPlay
                        src={nftInformation.imageBase64}
                        playsInline={true}
                    />
                    : nftInformation?.imageResolveUrl || nftInformation?.imageBase64
                        ? <img
                            className="gofrens-ownership-row_graphic"
                            alt="GoFrens Card Thumbnail"
                            src={nftInformation?.imageBase64 || nftInformation?.imageResolveUrl}
                        />
                        : null}
            </div>
            <p>{`${props.grouping.name} #${props.ownership.nftId}`}</p>
        </div>
    );
}

export default GoFrensOwnershipRowInfo;
