import {AnchorHTMLAttributes, ButtonHTMLAttributes, ReactElement, useEffect} from "react";
import FrameOneAutoPanel from "@/components/modals/autoPanelComponents/FrameOneAutoPanel";
import FrameAutoPanelHeader from "@/components/modals/autoPanelComponents/FrameAutoPanelHeader";
import FrameAutoPanelBody from "@/components/modals/autoPanelComponents/FrameAutoPanelBody";
import FrameButton from "@/components/buttons/FrameButton";
import useHasFocus from "@/hooks/useHasFocus";

interface Props {
    isOpen: boolean;
    onVerify: (displayError: boolean) => void;
    onClose: () => void;
    paymentNextAction: string;
}

function PaymentAuthenticationModal(props: Props): ReactElement {

    const hasFocus = useHasFocus();

    /**
	 * Execute function when user focuses on this tab
	 */
    useEffect(() => {
        if (hasFocus) {
            onFocus();
        }
    }, [hasFocus]);

    useEffect(() => {
        // Automatically open new tab with next steps when this modal is triggered
        if (props.isOpen && props.paymentNextAction) {
            window.open(props.paymentNextAction, "_blank")?.focus();
            // window.location = props.paymentNextAction;
        }
    }, [
        props.isOpen,
        props.paymentNextAction,
    ]);

    /**
	 * Check in background if payment has completed.
	 */
    function onFocus() {
        if (props.isOpen) {
            props.onVerify(false);
        }
    }

    return (
        <FrameOneAutoPanel
            isOpen={props.isOpen}
            contentClassName="payment-authentication-modal"
            size="xs"
        >
            <FrameAutoPanelHeader
                title="Payment Processing"
            />
            <FrameAutoPanelBody className="payment-authentication-modal_body">

                <p>
					For your security, your bank has requested additional authentication before completing your
					purchase.
                </p>

                <p>
					If the window does not automatically open, click the button below to go through the authentication.
                </p>

                <FrameButton
                    <AnchorHTMLAttributes<HTMLAnchorElement>>
                    color="purple"
                    size="normal"
                    href={props.paymentNextAction}
                    forwardProps={{
                        target: "_blank",
                    }}
                >
					Authenticate Payment
                </FrameButton>

                <p>
					If you have already authenticated then please click the button below to verify your payment status.
                </p>

                <FrameButton
                    <ButtonHTMLAttributes<HTMLButtonElement>>
                    color="purple-outline"
                    size="normal"
                    onClick={() => props.onVerify(true)}
                >
					Verify Payment
                </FrameButton>

                <button
                    className="payment-authentication-modal_body_cancel"
                    onClick={props.onClose}
                >
					Cancel
                </button>

            </FrameAutoPanelBody>
        </FrameOneAutoPanel>
    );
}

export default PaymentAuthenticationModal;
