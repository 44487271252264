/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetSEOResponse
 */
export interface GetSEOResponse {
    /**
     * 
     * @type {string}
     * @memberof GetSEOResponse
     */
    url: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetSEOResponse
     */
    urlEntryExists: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetSEOResponse
     */
    tags?: Array<string>;
}

export function GetSEOResponseFromJSON(json: any): GetSEOResponse {
    return GetSEOResponseFromJSONTyped(json, false);
}

export function GetSEOResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSEOResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'url': json['url'],
        'urlEntryExists': json['urlEntryExists'],
        'tags': !exists(json, 'tags') ? undefined : json['tags'],
    };
}

export function GetSEOResponseToJSON(value?: GetSEOResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'url': value.url,
        'urlEntryExists': value.urlEntryExists,
        'tags': value.tags,
    };
}


