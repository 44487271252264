import {ReactElement, useState, MouseEvent, ButtonHTMLAttributes} from "react";
import {
    MenuItem,
    NftGrouping,
} from "@devour/client";
import FrameButton from "../../../components/buttons/FrameButton";
import {verifyNftGroupOwnership} from "@/utils/verifyNftGroupOwnership";
import MenuItemCardNftGroupingsModal from "./MenuItemCardNftGroupingsModal";
import {useGetUnauthenticatedNftGroupings} from "@/hooks/useGetUnauthenticatedNftGroupings";
import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {useGetNftOwnershipsForUser} from "@/hooks/useGetNftOwnershipsForUser";

interface Props {
    menuItem: MenuItem;
}

function MenuItemCardNftGroupings(props: Props): ReactElement {

    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const [
        showAllNftGroupingsModal,
        setShowAllNftGroupingsModal,
    ] = useState<boolean>(false);
    const {data: allNftGroupings} = useGetUnauthenticatedNftGroupings(false);
    const {data: nftOwnershipResponse} = useGetNftOwnershipsForUser(fullToken);
    const menuItemNftGroupings: Array<NftGrouping> = props.menuItem.nftGroupings
        .map((menuGroupId) => {
            return allNftGroupings?.nftGroupings.find(grouping => grouping.id === menuGroupId);
        }).filter((grouping) => {
            return grouping && verifyNftGroupOwnership(grouping.id, nftOwnershipResponse);
        });

    function toggleAllNftGroupingsModal(): void {
        setShowAllNftGroupingsModal(s => !s);
    }

    function viewItemsOnClick(event: MouseEvent<HTMLButtonElement>): void {
        toggleAllNftGroupingsModal();
        event.stopPropagation();
    }

    function renderSingleNftGrouping(): JSX.Element {
        return (
            <div className="menu-item-card-nft-groupings_single">
                <div className="menu-item-card-nft-groupings_single_icon">
                    <div className="menu-item-card-nft-groupings_single_icon_inner">
                        {menuItemNftGroupings[0].icon &&
                        <img
							    src={menuItemNftGroupings[0].icon.url}
							    alt={`${menuItemNftGroupings[0]?.name} icon`}
                        />
                        }
                    </div>
                </div>
                <div className="menu-item-card-nft-groupings_single_name">
                    {menuItemNftGroupings[0]?.name}
                </div>
            </div>
        );
    }

    function renderMultipleNftGroupings(): JSX.Element {
        return (
            <div className="menu-item-card-nft-groupings_multiple">
                <div className="menu-item-card-nft-groupings_multiple_icons">
                    {(menuItemNftGroupings.map((grouping) => <div
                        key={grouping.id}
                        className="menu-item-card-nft-groupings_multiple_icons_outer"
                    >
                        <div className="menu-item-card-nft-groupings_multiple_icons_inner">
                            {grouping.icon &&
                            <img
									    src={grouping.icon.url}
									    alt={`${grouping?.name} icon`}
                            />
                            }
                        </div>
                    </div>))}
                </div>
                <div className="menu-item-card-nft-groupings_multiple_button">
                    <FrameButton
                        <ButtonHTMLAttributes<HTMLButtonElement>>
                        onClick={viewItemsOnClick}
                        color="gray"
                        size="pill"
                    >
						View Items
                    </FrameButton>
                </div>
            </div>
        );
    }

    if (!props.menuItem || menuItemNftGroupings?.length === 0) {
        return null;
    }

    return (
        <>
            <MenuItemCardNftGroupingsModal
                menuItem={props.menuItem}
                onToggle={toggleAllNftGroupingsModal}
                display={showAllNftGroupingsModal}
            />
            <div className="menu-item-card-nft-groupings">
                <p>
					Unlocked by {menuItemNftGroupings.length} item{menuItemNftGroupings.length !== 1 && "s"}
                </p>
                {menuItemNftGroupings.length === 1
                    ? renderSingleNftGrouping()
                    : renderMultipleNftGroupings()}
            </div>
        </>
    );
}

export default MenuItemCardNftGroupings;
