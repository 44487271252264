import useThemePreference from "@/hooks/useThemePreference";
import {ReactElement, useEffect} from "react";
import {useGate} from "statsig-react";

function CoinGeckoWidget(): ReactElement {
    const { value: dpayStatus } = useGate(import.meta.env.VITE_TOKEN_STATSIG_STATUS);
    const { isOnDarkMode } = useThemePreference();
    useEffect(() => {
        return;
        const script = document.createElement("script");
        script.src = "https://widgets.coingecko.com/gecko-coin-ticker-widget.js";
        let isMounted: boolean = false;

        if (dpayStatus) {
            isMounted = true;
            document.body.appendChild(script);
        }

        return () => {
            if (isMounted) {
                document.body.removeChild(script);
            }
        };
    });

    return (
        // @ts-ignore
        <gecko-coin-ticker-widget dark-mode={isOnDarkMode ? "true" : undefined} transparent-background="true" coin-id="devour-2" currency="usd" locale="en"></gecko-coin-ticker-widget>
    );
}

export default CoinGeckoWidget;