import {ReactElement, useEffect} from "react";
import classNames from "classnames";
import {NftGrouping, NftTracker} from "@devour/client";
import {useDispatch} from "react-redux";
import {decrementModalCount, incrementModalCount, toggleHideBottomNav} from "@/redux/meta/metaActions";
import DrawerHeaderGraphic from "@/components/goFrens/communityInfoDrawer/DrawerHeaderGraphic";
import DrawerCommunityInfo from "@/components/goFrens/communityInfoDrawer/DrawerCommunityInfo";
import DrawerOwnedNfts from "@/components/goFrens/communityInfoDrawer/DrawerOwnedNfts";
import DrawerFooterComponents from "@/components/goFrens/communityInfoDrawer/DrawerFooterComponents";

interface Props {
    show: boolean;
    toggle: () => void;
    grouping: NftGrouping;
    trackers: Array<NftTracker>;
    owned: number;
}

function GoFrensCommunityInfoDrawer(props: Props): ReactElement {
    const dispatch = useDispatch();

    useEffect(() => {
        if (props.show) {
            dispatch(incrementModalCount()); // prevent the body from being scrollable when drawer is open
            document.getElementById("drift-widget-wrapper")
                ?.classList.add("drift-frame-controller-hide");
            dispatch(toggleHideBottomNav(true));

        } else {
            document.getElementById("drift-widget-wrapper")
                ?.classList.remove("drift-frame-controller-hide");

            dispatch(toggleHideBottomNav(false));
        }

        return () => {
            document.getElementById("drift-widget-wrapper")?.classList
                .remove("drift-frame-controller-hide");
            dispatch(toggleHideBottomNav(false));
            dispatch(decrementModalCount());
        };

    }, [props.show]);

    return (
        <>
            <div
                className={classNames("gofrens-community-info-drawer-overlay", {
                    "is-active": props.show,
                })}
                onClick={props.toggle}
            />
            <div
                className={classNames("gofrens-community-info-drawer", {
                    "is-active": props.show,
                })}
            >
                <div className="gofrens-community-info-drawer_contents">
                    <DrawerHeaderGraphic
                        toggle={props.toggle}
                        grouping={props.grouping}
                    />
                    <div className="gofrens-community-info-drawer_contents_main">
                        <DrawerCommunityInfo
                            grouping={props.grouping}
                        />
                        <DrawerOwnedNfts
                            owned={props.owned}
                            grouping={props.grouping}
                            show={props.show}
                        />
                    </div>

                </div>
                <div className="gofrens-community-info-drawer_footer">
                    <DrawerFooterComponents
                        tracker={props.trackers.find(tracker => !tracker.isIneligibleForLeaderboards)}
                        grouping={props.grouping}
                        owned={props.owned}
                        show={props.show}
                    />
                    <button
                        onClick={props.toggle}
                        className="close-btn"
                    >
                        Close
                    </button>
                </div>
            </div>
        </>
    );
}

export default GoFrensCommunityInfoDrawer;
