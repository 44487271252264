import {ReactElement, useEffect, useState} from "react";
import GoVipLevelUpModal from "./modals/GoVipLevelUpModal";
import {useSelector} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {useGetTransactionsPaginated} from "@/hooks/useGetTransactionsPaginated";
import {lootboxAwardedLevels} from "@/utils/lootboxAwardedLevels";
import {AccountLevel} from "@devour/client";

function levelThreeCompleted(accountLevel: AccountLevel): boolean {
    return accountLevel && accountLevel.level === 3 && accountLevel.experienceRequiredForNextLevel <= 0;
}

function lootboxAwarded(accountLevel: AccountLevel): boolean {
    if (!accountLevel) {
        return;
    }

    return lootboxAwardedLevels.includes(accountLevel.level) ||
        accountLevel.level === 4 && !accountLevel.lootboxOnePreviouslyGained ||
        levelThreeCompleted(accountLevel);
}

function LevelUpHandler(): ReactElement {
    const currentUser = useSelector((store: IStore) => store.metaStore.currentUser);
    const accountLevel = useSelector((store: IStore) => store.metaStore.accountLevel);
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const [
        userLevel,
        setUserLevel,
    ] = useState<AccountLevel>(undefined);
    const [
        showGoVIPLevelUpModal,
        setShowGoVIPLevelUpModal,
    ] = useState(false);

    const {data: transactionsResponse} = useGetTransactionsPaginated(fullToken, currentUser?.user?.id);

    const isLootboxAwarded: boolean = lootboxAwarded(accountLevel);

    useEffect(() => {
        if (accountLevel && accountLevel.level) {
            if (!userLevel || accountLevel.level !== userLevel.level) {
                setUserLevel(accountLevel);
            }

            if (userLevel) {
                if (accountLevel.level > userLevel.level && !accountLevel.lootboxOnePreviouslyGained) {
                    setShowGoVIPLevelUpModal(true);
                } else if (levelThreeCompleted(accountLevel) && userLevel.experienceRequiredForNextLevel > 0) {
                    setShowGoVIPLevelUpModal(true);
                }
            }
        }
    }, [accountLevel]);

    function toggleShowLevelUpModal(): void {
        setShowGoVIPLevelUpModal(s => !s);
    }

    if (!userLevel) {
        return null;
    }

    const defaultDPayPricePerUSD: number = 0.0018460683865216495;

    const dpayGainedInFiat = levelThreeCompleted(accountLevel)
        ? userLevel.level
        : Math.max(1, userLevel.level - 1);
    const dpayGained = isLootboxAwarded
        ? dpayGainedInFiat
        : Math.floor(Math.max(1, userLevel.level - 1) / (transactionsResponse
            ? transactionsResponse?.dPayPricePerUsd
            : defaultDPayPricePerUSD));

    return (
        <GoVipLevelUpModal
            isOpen={showGoVIPLevelUpModal}
            toggle={toggleShowLevelUpModal}
            level={userLevel.level}
            dpayGained={dpayGained}
            isLootboxAwarded={isLootboxAwarded}
        />
    );
}

export default LevelUpHandler;
