import {ReactElement} from "react";
import Spacer from "@/components/Spacer";
import {IoWallet} from "react-icons/io5";
import {isDesktop, isTablet} from "react-device-detect";
import classNames from "classnames";
import {FaCircleCheck} from "react-icons/fa6";
import {ActiveDpay} from "@/utils/getMenuOrderPaymentMethod";

interface Props {
    insufficientMagic: boolean;
    activeDpayMethod: ActiveDpay;
    handleMethodOnClick: () => Promise<void>;
    isUnavailable?: boolean;
}

function CheckoutPaymentMagicCard(props: Props): ReactElement {

    return (
        <div
            className={classNames(
                "checkout-payment-option_card",
                {
                    "checkout-payments-dpay-modal_body_dpay-container_card-insufficient": props.insufficientMagic && props.activeDpayMethod !== ActiveDpay.MAGIC,
                    "checkout-payments-dpay-modal_body_dpay-container_card-inactive": !props.insufficientMagic && props.activeDpayMethod !== ActiveDpay.MAGIC,
                    "checkout-payments-dpay-modal_body_dpay-container_card-active": !props.insufficientMagic && props.activeDpayMethod === ActiveDpay.MAGIC,
                },
            )}
            onClick={async () => props.isUnavailable
                ? {}
                : await props.handleMethodOnClick()}
        >
            <IoWallet
                className="checkout-payment-option_card_icon checkout-payments-dpay-modal_body_wallet-icon"/>
            <p className="checkout-payment-option_card_method">DevourGO Wallet</p>
            {props.insufficientMagic &&
                <>
                    <Spacer/>
                    <div className="checkout-payments-dpay-modal_body_dpay-container_card-insufficient_tag">
                        <p className="checkout-payments-dpay-modal_body_title-row_subheader">
                            {`${isDesktop || isTablet
                                ? "Insufficient"
                                : "No"} Balance`}
                        </p>
                    </div>
                </>
            }
            {props.activeDpayMethod === ActiveDpay.MAGIC &&
                <>
                    <Spacer/>
                    <FaCircleCheck
                        className="checkout-payments-dpay-modal_body_dpay-container_card-active_icon"/>
                </>
            }
        </div>
    );
}

export default CheckoutPaymentMagicCard;
