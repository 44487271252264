import {ButtonHTMLAttributes, Dispatch, ReactElement, SetStateAction} from "react";
import FrameButton from "../../buttons/FrameButton";
import {useSelector} from "react-redux";
import {NftPurchaseRequestBody, NftPurchaseUpsert} from "@devour/client";
import {IStore} from "@/redux/defaultStore";
import {BsFillCreditCardFill} from "react-icons/bs";
import {StripePaymentMethodObject} from "@/types/Stripe";
import {capitalize} from "lodash";
import classNames from "classnames";
import {FiArrowRight, FiChevronLeft} from "react-icons/fi";
import CardPaymentsInput from "@/components/CardPaymentInputs";
import {useGetStripePaymentMethodList} from "@/hooks/useGetStripePaymentMethodList";
import Spacer from "@/components/Spacer";
import {IoIosCheckmarkCircle} from "react-icons/io";
import {PaymentStates} from "@/components/modals/mintIndustryPassModal/PaymentStates";

interface Props {
    onSubmit: () => void;
    getClaimableNft: (values: NftPurchaseRequestBody) => Promise<void>;
    setPaymentState: Dispatch<SetStateAction<PaymentStates>>;
    nftPurchase: NftPurchaseUpsert;
}

function MintIndustryPassModalCreditPayment(props: Props): ReactElement {

    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);

    const {data: stripePaymentMethodListData} = useGetStripePaymentMethodList(fullToken);

    const savedPaymentMethods = (stripePaymentMethodListData?.paymentMethods) as Array<StripePaymentMethodObject>;
    const selectedPayment = savedPaymentMethods?.find((payment) => payment.id === props.nftPurchase.paymentMethodId);

    function renderSavedCards(payment: StripePaymentMethodObject): ReactElement {
        return (
            <div
                className={classNames("mint-industry-credit-card_body_credit_saved-card", {
                    "mint-industry-credit-card_body_credit_selected": selectedPayment?.id === payment.id,
                })}
                key={payment.id}
                onClick={async () => {
                    await props.getClaimableNft({paymentMethodId: payment.id});
                }}
            >
                <div>
                    <BsFillCreditCardFill className="mint-industry-credit-card_body_credit_saved-card_card-info_icon"/>
                </div>
                <div
                    className={classNames("mint-industry-credit-card_body_credit_saved-card_card-info", "sentry-mask")}>
                    <p><strong>{capitalize(payment.card.brand)} *****{payment.card.last4}</strong></p>
                </div>
                <Spacer/>
                <div>
                    {selectedPayment?.id === payment.id &&
                    <IoIosCheckmarkCircle className="mint-industry-credit-card_body_credit_saved-card_selected"/>
                    }
                </div>
            </div>
        );
    }

    return (
        <>
            <div
                className="mint-industry-credit-card_body_payment-options_header"
                onClick={() => props.setPaymentState(PaymentStates.SELECT_PAYMENT)}
            >
                <FiChevronLeft className="mint-industry-credit-card_body_payment-options_header_back"/>
                <h4>Pay with Credit Card</h4>
            </div>

            <div className="mint-industry-credit-card_body_credit_section">
                <h5>Add Credit Card</h5>
                <CardPaymentsInput/>
            </div>

            {savedPaymentMethods?.length > 0 &&
            <div className="mint-industry-credit-card_body_credit_section">
				    <h5>Select from Saved Cards</h5>
				    <div>
				        {savedPaymentMethods.map(renderSavedCards)}
				    </div>
            </div>
            }

            <div className="mint-industry-credit-card_body_credit_footer">
                <FrameButton
                    <ButtonHTMLAttributes<HTMLButtonElement>>
                    color="purple"
                    size="large"
                    className="mint-industry-credit-card_body_credit_footer_button"
                    rightIcon={FiArrowRight}
                    showSpinner={true}
                    onClick={props.onSubmit}
                    forwardProps={{disabled: !selectedPayment}}
                >
					Purchase Now
                </FrameButton>
            </div>
        </>
    );

}

export default MintIndustryPassModalCreditPayment;
