import {ReactElement} from "react";
import {useSelector} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {useNavigate} from "react-router";

function GoVipPointsText(): ReactElement {

    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const accountLevel = useSelector((store: IStore) => store.metaStore.accountLevel);

    const navigate = useNavigate();

    function generatePointText() {
        if (!fullToken) {
            return (
                <p className="go-vip-top_header_exp_progress-container_level-text">
					1000 points to next level
                </p>
            );
        }

        if (accountLevel?.userRequiresIndustryCollectionNftToLevelUp && !accountLevel?.userOwnsIndustryCollectionNft &&
            accountLevel?.experienceRequiredForNextLevel <= 0) {
            return (
                <div className="go-vip-top_header_exp_counter-container_other-points-actions-container">
                    <button
                        className="reset-link go-vip-top_header_exp_progress-container_level-text"
                        style={{color: "white",
                            fontSize: "13px"}}
                        onClick={() => navigate("/the-industry-pass")}
                    >
						Mint Industry Pass NFT for more level-ups
                    </button>
                </div>
            );
        }

        return (
            <p className="go-vip-top_header_exp_progress-container_level-text">
                {accountLevel?.experienceRequiredForNextLevel <= 0
                    ? "Maximum points gained"
                    : `${accountLevel?.experienceRequiredForNextLevel} points to next level`}
            </p>
        );
    }

    return (
        <>
            {generatePointText()}
        </>
    );
}

export default GoVipPointsText;
