import {Dispatch, ReactElement, SetStateAction, useEffect, useRef} from "react";
import {NftOwnershipInformation, PaginationInfo, Token, RestaurantSearchResult} from "@devour/client";
import RestaurantCard from "./RestaurantCard";
import {connect, ConnectedProps, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {RestaurantSearchFiltersQuery} from "@/utils/restaurantsSearchFilter";
import {useParams} from "react-router";
import IndustryPassPromoBanner from "@/components/IndustryPassPromoBanner";
import {isMobile} from "react-device-detect";
import FramePaginator from "@/components/paginator/FramePaginator";
import useThemePreference from "@/hooks/useThemePreference";


interface StateProps {
    fullToken: Token;
}

interface Props {
    restaurants: Array<RestaurantSearchResult>;
    restaurantFilters: RestaurantSearchFiltersQuery;
    updateRestaurantFilters: Dispatch<SetStateAction<RestaurantSearchFiltersQuery>>;
    nftOwnershipResponse: NftOwnershipInformation;
    showTitle?: boolean;
    pagination?: PaginationInfo;
    onPaginationChange?: (newPagination: PaginationInfo) => void;
}

function RestaurantSearchResults(props: RestaurantSearchResultsProps): ReactElement {

    const {placeId} = useParams<{ placeId: string; }>();

    const accountLevel = useSelector((store: IStore) => store.metaStore.accountLevel);
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const searchResultsDiv = useRef(null);
    const { isOnDarkMode } = useThemePreference();


    useEffect(() => {
        if (props.pagination && searchResultsDiv.current) {
            const distanceToTop = searchResultsDiv.current.getBoundingClientRect().top;

            if (distanceToTop < 0) {
                // only scroll when already scrolled past the top of the div
                searchResultsDiv.current.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                });
            }
        }
    }, [JSON.stringify(props.pagination)]);

    function renderRestaurant(restaurant: RestaurantSearchResult): JSX.Element {
        return (
            <li
                key={restaurant._id}
                className="restaurants-search-results_list_item"
            >
                <RestaurantCard
                    placeId={placeId}
                    restaurant={restaurant}
                    restaurantFilters={props.restaurantFilters}
                    nftOwnershipResponse={props.nftOwnershipResponse}
                    showPromos="auto"
                />
            </li>
        );
    }

    function isIndustryBannerDisplayed(): boolean {
        return isMobile && (!fullToken || !accountLevel?.userOwnsIndustryCollectionNft);
    }

    return (
        <div className="restaurants-search-results">

            {isIndustryBannerDisplayed() && <IndustryPassPromoBanner />}

            {props.showTitle &&
            <>
				    {props.restaurants.length > 0 && props.restaurantFilters.textSearch
                    ? <h3 className="restaurants-search-results-title">
							Results for "{props.restaurantFilters.textSearch}"
                    </h3>
					 : props.restaurants.length > 0
                        ? <h3 className="restaurants-search-results-title">
							Restaurants in your Area
                        </h3>
					 : <h3 className="restaurants-search-results-closed">
							All restaurants in your area are closed at the moment. Please try again later.
                        </h3>
                }
            </>
            }
            <ul className="restaurants-search-results_list" ref={searchResultsDiv}>
                {props.restaurants.map(renderRestaurant)}
            </ul>
            {props.pagination &&
                <FramePaginator
                    {...props.pagination}
                    onPaginationChange={props.onPaginationChange}
                    showPaginatorLimit={true}
                    theme={isOnDarkMode ? "dark" : "light"}
                />
            }
        </div>
    );
}

function connector() {
    return connect((store: IStore, props: Props): StateProps & Props => {
        return {
            fullToken: store.authStore.fullToken,
            ...props,
        };
    });
}

type RestaurantSearchResultsProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(RestaurantSearchResults);
