import {ReactElement} from "react";
import {BrandMap, BrandMapAdvertisementBlock} from "@devour/client";

interface Props {
    brandMap: BrandMap;
}

function BrandLandingAdvertisementBlocks(props: Props): ReactElement {
    const advertisementBlocks = props.brandMap.advertisementBlocks.filter(block => block.image);

    if (!advertisementBlocks.length) {
        return null;
    }

    /**
     * Render each "card" for each advertisement block.
     *
     * @param block
     * @param i
     */
    function renderAdvertisementBlock(block: BrandMapAdvertisementBlock, i: number): ReactElement {
        return (
            <li
                key={`advertisement-block-${i}`}
                className={`brand-map-advertisement_list_item brand-map-advertisement_list_item-${i}`}
            >
                {block.url
                    ? <a
                        href={block.url.indexOf("http") === 0 ? block.url : `https://${block.url}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            src={block.image.url}
                            alt={`${props.brandMap.name} promotion`}
                        />
                    </a>
					 : <img
                        src={block.image.url}
                        alt={`${props.brandMap.name} promotion`}
                    />
                }
            </li>
        );
    }

    return (
        <div className="brand-map-advertisement">
            <div className="brand-map-promotions_header">
                <span className="brand-map-promotions_header_pill">Advertisement</span>
                <div className="brand-map-promotions_header_title">
                    <h3 className="brand-map-promotions_header_title_text">
                        {props.brandMap.advertisementTitle?.toUpperCase() || "EXPLORE FURTHER"}
                    </h3>
                </div>
            </div>

            <ul className="brand-map-advertisement_list">
                {advertisementBlocks.filter(block => block.image).map(renderAdvertisementBlock)}
            </ul>
        </div>
    );
}

export default BrandLandingAdvertisementBlocks;
