/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum OrderRefundReason {
    AUTOREFUND = 'AUTO_REFUND',
    WRONGORDERRECEIVED = 'WRONG_ORDER_RECEIVED',
    ORDERNEVERARRIVED = 'ORDER_NEVER_ARRIVED',
    ITEMQUALITYISSUES = 'ITEM_QUALITY_ISSUES',
    MISSINGORINCORRECTITEMS = 'MISSING_OR_INCORRECT_ITEMS',
    ORDERARRIVEDLATE = 'ORDER_ARRIVED_LATE',
    THIRDPARTYISSUED = 'THIRD_PARTY_ISSUED',
    OTHER = 'OTHER'
}

export function OrderRefundReasonFromJSON(json: any): OrderRefundReason {
    return OrderRefundReasonFromJSONTyped(json, false);
}

export function OrderRefundReasonFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderRefundReason {
    return json as OrderRefundReason;
}

export function OrderRefundReasonToJSON(value?: OrderRefundReason | null): any {
    return value as any;
}

