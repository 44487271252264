import {
    ButtonHTMLAttributes,
    ChangeEventHandler,
    FormEvent,
    ReactElement,
    useContext,
    useEffect,
    useState,
} from "react";
import FrameOneAutoPanel from "@/components/modals/autoPanelComponents/FrameOneAutoPanel";
import FrameAutoPanelBody from "@/components/modals/autoPanelComponents/FrameAutoPanelBody";
import {BrandMap, BrandMapColorTheme, GetUserResponse, LoginAccessPoint, LoginResponse, UsersApi} from "@devour/client";
import FrameButton from "@/components/buttons/FrameButton";
import {Link} from "react-router-dom";
import {
    addError,
    decrementLoading,
    incrementLoading,
    updateCurrentUser,
} from "@/redux/meta/metaActions";
import getConfig, {getMagicConfig} from "@/utils/getConfig";
import {magic} from "@/utils/magic";
import {useDispatch, useSelector} from "react-redux";
import classNames from "classnames";
import {login} from "@/redux/auth/authActions";
import * as Sentry from "@sentry/react";
import {useGetUserProfile} from "@/hooks/useGetUserProfile";
import {IStore} from "@/redux/defaultStore";
import {useNavigate, useParams} from "react-router";
import {isMobile, isTablet} from "react-device-detect";
import BrandSignUpModal from "@/components/brands/BrandSignUpModal";
import {useGetBrandMap} from "@/hooks/useGetBrandMap";
import {BrandMapContext, BrandMapStates} from "@/pages/brandMap/context/BrandMapContext";

interface Props {
    isOpen: boolean;
    toggle: (showModal: boolean) => void;
    brandMap: BrandMap;
}

function BrandEmailModal(props: Props): ReactElement {
    const {isOpen, toggle, brandMap} = props;

    const {slug} = useParams<{ slug: string }>();
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);


    const [magicCredential, setMagicCredential] = useState<string>(undefined);
    const [email, setEmail] = useState<string>("");
    const [showSignupModal, setShowSignUpModal] = useState<boolean>(false);

    const {refetch: refetchUserData} = useGetUserProfile(fullToken);
    const {refetch: refetchBrandMap} = useGetBrandMap(slug);

    const {brandMapState} = useContext(BrandMapContext);

    const isTextThemeLight = brandMap.colorTheme === BrandMapColorTheme.LIGHT;

    const cancelImageUrl = isTextThemeLight
        ? `${import.meta.env.VITE_CDN_URL}/images/cancel-light-gray.svg`
        : `${import.meta.env.VITE_CDN_URL}/images/cancel-medium-gray.svg`;

    useEffect(() => {
        if (props.isOpen) {
            setEmail("");
        }
    }, [props.isOpen]);

    async function updateUserAfterLogin(loginRes: LoginResponse, userRes: GetUserResponse): Promise<void> {
        //  save the token and profile into redux
        dispatch(login(loginRes.token));
        dispatch(updateCurrentUser(userRes));

        Sentry.setUser({
            email: userRes.user.email,
        });
        Sentry.setContext("userInfo", userRes);

        await refetchUserData();
    }

    async function loginWithOtp(e: FormEvent<HTMLFormElement>): Promise<void> {
        e.preventDefault();
        const magicCredentialFromLogin = await magic.auth.loginWithEmailOTP({email});
        setMagicCredential(magicCredentialFromLogin);

        try {
            /*
             * call the login api with the magic_credential, if the API returns 200 with a token,
             * then log the user in as normal
             */
            const loginRes = await new UsersApi(getMagicConfig(magicCredentialFromLogin)).magicLogin();

            // grab the profile to save into redux
            const userRes = await new UsersApi(getConfig(loginRes.token)).getProfile();

            await updateUserAfterLogin(loginRes, userRes);
            // re-fetch brand map before closing the modal
            dispatch(incrementLoading());
            await refetchBrandMap();
            dispatch(decrementLoading());
            toggle(false);
        } catch (err) {
            // if the API returns an error and the error has status 403, then the user will be prompted to sign in
            if (err.status === 401) {
                toggleSignupModal(false, true);
            } else {
                dispatch(await addError(err));
                navigate("/log-in", {replace: true});
            }
        }
    }

    function toggleSignupModal(resetMagicCred: boolean = false, showModal: boolean = false): void {
        setShowSignUpModal(showModal);

        if (showModal) {
            toggle(false); // Close email modal when opening signup modal
        } else if (isAuthenticationRequired() && !showModal) {
            // Only force log in if loginAccessPoint is PAGE ACCESS (open login modal after dismissing signup modal)
            toggle(true);
        }

        if (resetMagicCred) {
            setMagicCredential(undefined);
            setEmail("");
        }
    }

    const emailOnChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        setEmail(e.target.value);
    };

    const renderLegalLink = (to: string, text: string) =>
        <Link
            to={to}
            className="legal-link"
        >
            {text}
        </Link>;

    const renderDevourLogo = (colorTheme: BrandMapColorTheme) => {
        const src = colorTheme === BrandMapColorTheme.DARK
            ? `${import.meta.env.VITE_CDN_URL}/images/DevourGO-logo.webp`
            : `${import.meta.env.VITE_CDN_URL}/images/DevourGO_Final-White.webp`;

        return (
            <img
                className={classNames("brand-signup-modal_logo", {
                    "mobile": isMobile && !isTablet,
                })}
                src={src}
                alt="DevourGO Logo"
            />
        );
    };

    function isAuthenticationRequired(): boolean {
        return !fullToken && (
            brandMap?.loginAccessPoint === LoginAccessPoint.PAGE && brandMapState === BrandMapStates.NOT_INITIATED ||
            brandMap?.loginAccessPoint === LoginAccessPoint.ORDERING && brandMapState === BrandMapStates.SELECT_LOCATION
        );
    }
    return (
        <>
            <BrandSignUpModal
                isOpen={showSignupModal}
                toggle={toggleSignupModal}
                magicCredential={magicCredential}
                updateUserAfterLogin={updateUserAfterLogin}
                brandMap={brandMap}
                renderLegalLink={renderLegalLink}
                renderDevourLogo={renderDevourLogo}
            />

            <FrameOneAutoPanel
                isOpen={isOpen}
                toggle={() => toggle(false)}
                fullScreenHeight={true}
                contentClassName={classNames("brand-email-modal", {"mobile": isMobile && !isTablet})}
                size="sm2"
                modalOnTablet={true}
            >

                <img
                    onClick={() => toggle(false)}
                    src={cancelImageUrl}
                    alt="close"
                    className="brand-email-modal_close"
                />

                <FrameAutoPanelBody className={classNames("brand-email-modal_body", {
                    "mobile": isMobile && !isTablet,
                })}
                >
                    <img
                        src={`${import.meta.env.VITE_CDN_URL}/images/brand-purple-email.webp`}
                        alt="Purple Email Graphic"
                        className={classNames("brand-email-modal_email-graphic", {
                            "mobile": isMobile && !isTablet,
                        })}
                    />

                    <h1>Welcome</h1>

                    <p>Ready to unlock the ultimate experiences? It all begins with entering your email address.</p>

                    <form
                        onSubmit={loginWithOtp}
                        className="brand-email-modal_form"
                    >
                        <input
                            className="brand-email-modal_input"
                            type="email"
                            placeholder="Enter email address"
                            value={email}
                            onChange={emailOnChange}
                        />

                        <FrameButton
                            <ButtonHTMLAttributes<HTMLButtonElement>>
                            color="purple"
                            size="normal"
                            className="brand-email-modal_button button-margin"
                            showSpinner={true}
                            forwardProps={{
                                type: "submit",
                                disabled: email === "",
                            }}
                        >
                            Continue
                        </FrameButton>
                    </form>

                    <div className="brand-email-modal_legal">
                        <p>
                            By clicking "Continue" above or by using any of the methods under
                            "log in or sign up with" to continue to DevourGO,
                            you acknowledge that you have read and understood, and agree to DevourGO's{" "}
                            {renderLegalLink("/terms-of-use", "Terms of Use")},{" "}
                            {renderLegalLink("/privacy-policy", "Privacy Policy")}, and{" "}
                            {renderLegalLink("/cookie-policy", "Cookie Policy")}
                        </p>
                    </div>

                    {/* DEVOUR GO LOGO */}
                    {renderDevourLogo(brandMap.colorTheme)}

                </FrameAutoPanelBody>

            </FrameOneAutoPanel>
        </>
    );
}

export default BrandEmailModal;
