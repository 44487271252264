/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * the base64 response for an asset
 * @export
 * @interface AssetBase64Response
 */
export interface AssetBase64Response {
    /**
     * 
     * @type {string}
     * @memberof AssetBase64Response
     */
    data: string;
}

export function AssetBase64ResponseFromJSON(json: any): AssetBase64Response {
    return AssetBase64ResponseFromJSONTyped(json, false);
}

export function AssetBase64ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssetBase64Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': json['data'],
    };
}

export function AssetBase64ResponseToJSON(value?: AssetBase64Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data,
    };
}


