import {ReactElement} from "react";
import Spacer from "../Spacer";
import {FiCheck, FiChevronRight} from "react-icons/fi";
import classNames from "classnames";
import {useSelector} from "react-redux";
import {IStore} from "../../redux/defaultStore";

interface Props {
    reward: string;
    rewardSubtitle: string;
    stage: string;
    toggle: () => void;
    isDisabled?: boolean;
    isCompleted?: boolean;
}

function GoVipPlay2EarnRewardsCard(props: Props): ReactElement {

    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);

    return (
        <div
            className={classNames("go-vip-share2earn_action-card", {
                "go-vip-share2earn_action-card_hover": !props.isCompleted,
            })}
            onClick={() => {
                if (!props.isCompleted) {
                    props.toggle();
                }
            }}
        >
            <div className="go-vip-share2earn_action-card_content">
                {props.isCompleted
                    ? <div className="go-vip-levels_body_cards-container_card_square
                        go-vip-levels_body_cards-container_card_square_disabled"
                    >
                        <div className="go-vip-levels_body_cards-container_card_square_gleam">
                            <FiCheck className="go-vip-levels_body_cards-container_card_square_disabled_check"/>
                        </div>
                    </div>
				 : <div
                        className={classNames("go-vip-levels_body_cards-container_card_square", {
                            "go-vip-levels_body_cards-container_card_square_enabled": !props.isDisabled || !fullToken,
                            "go-vip-levels_body_cards-container_card_square_disabled": props.isDisabled && fullToken,
                        })}
                    >
                        <div className="go-vip-levels_body_cards-container_card_square_gleam">
                            <h4>{props.reward}</h4>
                            <p>{props.rewardSubtitle}</p>
                        </div>
                    </div>
                }

                <div className="go-vip-share2earn_action-card_content_description">

                    <p className={classNames("go-vip-share2earn_action-card_content_description_header", {
                        "go-vip-share2earn_action-card_content_description_header_completed": props.isCompleted,
                    })}>
                        <strong>{props.stage}</strong>
                    </p>
                    <p className="go-vip-share2earn_action-card_content_description_entries">
                        {props.isCompleted
                            ? "You've claimed this reward!"
                            : "Play to get this reward!"}
                    </p>
                </div>

                {!props.isCompleted &&
                <>
					    <Spacer/>
					    <FiChevronRight className="go-vip-top_header_dpay-card_content_chevron"/>
                </>
                }
            </div>
        </div>
    );
}

export default GoVipPlay2EarnRewardsCard;
