import {ReactElement} from "react";
import Skeleton from "react-loading-skeleton";

function RestaurantMenuPageHeaderSkeleton(): ReactElement {
    return (
        <div className='restaurant-menu-page-header-skeleton'>
            <Skeleton className='restaurant-menu-page-header-skeleton_component'/>
            <div className="restaurant-page_logo-container restaurant-page_section-margin">
                <div className="restaurant-page_header_logo">
                    <div className="react-loading-skeleton"/>
                </div>
            </div>
        </div>
    );
}

export default RestaurantMenuPageHeaderSkeleton;