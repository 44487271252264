/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiError,
    ApiErrorFromJSON,
    ApiErrorToJSON,
    BusinessTaxonomyBody,
    BusinessTaxonomyBodyFromJSON,
    BusinessTaxonomyBodyToJSON,
    GetBusinessTaxonomiesResponse,
    GetBusinessTaxonomiesResponseFromJSON,
    GetBusinessTaxonomiesResponseToJSON,
} from '../models';

export interface CreateBusinessTaxonomyRequest {
    businessTaxonomyBody?: BusinessTaxonomyBody;
}

export interface DeleteBusinessTaxonomyRequest {
    id: string;
}

export interface UpdateBusinessTaxonomyRequest {
    id: string;
    businessTaxonomyBody?: BusinessTaxonomyBody;
}

/**
 * BusinessTaxonomiesApi - interface
 * @export
 * @interface BusinessTaxonomiesApiInterface
 */
export interface BusinessTaxonomiesApiInterface {
    /**
     * Used by admins to create new business categories.
     * @param {BusinessTaxonomyBody} [businessTaxonomyBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessTaxonomiesApiInterface
     */
    createBusinessTaxonomyRaw(requestParameters: CreateBusinessTaxonomyRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Used by admins to create new business categories.
     */
    createBusinessTaxonomy(requestParameters: CreateBusinessTaxonomyRequest): Promise<void>;

    /**
     * Used by admins to delete existing categories.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessTaxonomiesApiInterface
     */
    deleteBusinessTaxonomyRaw(requestParameters: DeleteBusinessTaxonomyRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Used by admins to delete existing categories.
     */
    deleteBusinessTaxonomy(requestParameters: DeleteBusinessTaxonomyRequest): Promise<void>;

    /**
     * Used to get a list of all business categories.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessTaxonomiesApiInterface
     */
    getBusinessTaxonomiesRaw(): Promise<runtime.ApiResponse<GetBusinessTaxonomiesResponse>>;

    /**
     * Used to get a list of all business categories.
     */
    getBusinessTaxonomies(): Promise<GetBusinessTaxonomiesResponse>;

    /**
     * Used by admins to update existing business categories.
     * @param {string} id 
     * @param {BusinessTaxonomyBody} [businessTaxonomyBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessTaxonomiesApiInterface
     */
    updateBusinessTaxonomyRaw(requestParameters: UpdateBusinessTaxonomyRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Used by admins to update existing business categories.
     */
    updateBusinessTaxonomy(requestParameters: UpdateBusinessTaxonomyRequest): Promise<void>;

}

/**
 * no description
 */
export class BusinessTaxonomiesApi extends runtime.BaseAPI implements BusinessTaxonomiesApiInterface {

    /**
     * Used by admins to create new business categories.
     */
    async createBusinessTaxonomyRaw(requestParameters: CreateBusinessTaxonomyRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/business-taxonomies`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BusinessTaxonomyBodyToJSON(requestParameters.businessTaxonomyBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Used by admins to create new business categories.
     */
    async createBusinessTaxonomy(requestParameters: CreateBusinessTaxonomyRequest): Promise<void> {
        await this.createBusinessTaxonomyRaw(requestParameters);
    }

    /**
     * Used by admins to delete existing categories.
     */
    async deleteBusinessTaxonomyRaw(requestParameters: DeleteBusinessTaxonomyRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteBusinessTaxonomy.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/business-taxonomies/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Used by admins to delete existing categories.
     */
    async deleteBusinessTaxonomy(requestParameters: DeleteBusinessTaxonomyRequest): Promise<void> {
        await this.deleteBusinessTaxonomyRaw(requestParameters);
    }

    /**
     * Used to get a list of all business categories.
     */
    async getBusinessTaxonomiesRaw(): Promise<runtime.ApiResponse<GetBusinessTaxonomiesResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/business-taxonomies`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetBusinessTaxonomiesResponseFromJSON(jsonValue));
    }

    /**
     * Used to get a list of all business categories.
     */
    async getBusinessTaxonomies(): Promise<GetBusinessTaxonomiesResponse> {
        const response = await this.getBusinessTaxonomiesRaw();
        return await response.value();
    }

    /**
     * Used by admins to update existing business categories.
     */
    async updateBusinessTaxonomyRaw(requestParameters: UpdateBusinessTaxonomyRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateBusinessTaxonomy.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/business-taxonomies/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BusinessTaxonomyBodyToJSON(requestParameters.businessTaxonomyBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Used by admins to update existing business categories.
     */
    async updateBusinessTaxonomy(requestParameters: UpdateBusinessTaxonomyRequest): Promise<void> {
        await this.updateBusinessTaxonomyRaw(requestParameters);
    }

}
