import React, {ChangeEventHandler, FormEvent, ReactElement, useState} from "react";
import FrameModalHeader from "../modals/modalComponents/FrameModalHeader";
import FrameOneModal from "../modals/modalComponents/FrameOneModal";
import FrameModalBody from "../modals/modalComponents/FrameModalBody";
import {MenuOrder, OrderCancelBody} from "@devour/client";
import {addError} from "@/redux/meta/metaActions";
import {useCancelMenuOrder} from "@/hooks/menuOrder/useCancelMenuOrder";
import {useDispatch} from "react-redux";
import FrameButton from "../buttons/FrameButton";
import FrameModalFooter from "@/components/modals/modalComponents/FrameModalFooter";

const defaultValues: OrderCancelBody = {
    cancellationReason: "",
};

interface Props {
    isOpen: boolean;
    onClose: () => void;
    onDone: () => void;
    menuOrder: MenuOrder;
}

function OrderCancelModal(props: Props): ReactElement {
    const dispatch = useDispatch();
    const [formValues, setFormValues] = useState<OrderCancelBody>(defaultValues);

    /**
     * Use React Query to call API and update the menu order's delivery notes
     * isLoading is used to display a skeleton while the mutation is in progress
     */
    const {
        mutateAsync: cancelMenuOrder,
        isPending: isPendingCancelMenuOrder,
    } = useCancelMenuOrder(props.menuOrder.id);

    /**
     * Handle all text input onChange events.
     *
     * @param key
     */
    function inputOnChange(key: keyof OrderCancelBody): ChangeEventHandler<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement> {
        return (e) => {
            setFormValues({
                ...formValues,
                [key]: e.target.value,
            });
        };
    }

    /**
     * When form is submitted, close the modal and update the menu order's delivery notes.
     *
     * @param e
     */
    async function onFormSubmit(e: FormEvent<HTMLFormElement>): Promise<void> {
        e.preventDefault();
        if (isPendingCancelMenuOrder) {
            return;
        }
        try {
            // React Query's mutate function is used to call API and update the delivery notes
            await cancelMenuOrder(formValues);

            setFormValues(defaultValues);
            props.onDone();
        } catch (e) {
            dispatch(await addError(e));
        }
    }

    return (
        <FrameOneModal
            isOpen={props.isOpen}
            toggle={props.onClose}
            size="sm"
            contentClassName="order-cancel-modal"
        >
            <FrameModalHeader
                title="Cancel Order"
                toggle={props.onClose}
            />

            <form onSubmit={onFormSubmit}>
                <FrameModalBody className="order-cancel-modal_body">
                    <div>
                        <label>
														Cancellation Reason
                        </label>
                        <textarea
                            value={formValues.cancellationReason}
                            placeholder="Cancellation Reason..."
                            onChange={inputOnChange("cancellationReason")}
                            required={true}
                        />
                    </div>

                </FrameModalBody>
                <FrameModalFooter>
                    <FrameButton
                        <React.ButtonHTMLAttributes<HTMLButtonElement>>
                        color="danger"
                        size="normal"
                        forwardProps={{
                            type: "submit",
                            disabled: isPendingCancelMenuOrder,
                        }}
                    >
												Cancel Order
                    </FrameButton>
                </FrameModalFooter>
            </form>
        </FrameOneModal>
    );
}

export default OrderCancelModal;