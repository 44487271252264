import { useGetUserProfile } from "@/hooks/useGetUserProfile";
import { IStore } from "@/redux/defaultStore";
import { useSelector } from "react-redux";
import NftProfilePictureBadge from "./NftProfilePictureBadge";
import { FC, useEffect, useRef, useState } from "react";
import DevourAccountDropdownMenuBody from "./DevourAccountDropdownMenuBody";
import useClosePopups from "@/hooks/useClosePopups";
import { isDesktop, isMobile, isTablet } from "react-device-detect";
import { IoWalletSharp } from "react-icons/io5";
import DevourWalletDropdownMenuBody from "./DevourWalletDropdownMenuBody";
import { isPhantomApp } from "@/utils/isGoNative";
import { createPortal } from "react-dom";

interface props {
    onShowSignInToast: () => void;
    hideWalletOnMobile?: boolean;
    dropdownAnchorElement?: string;
    avatarOnly?: boolean;
}

const DevourTopNavUserData: FC<props> = ({ onShowSignInToast, hideWalletOnMobile, dropdownAnchorElement, avatarOnly = false}) => {
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const {data: userProfileData} = useGetUserProfile(fullToken);
    const accountDropdownRef = useRef<HTMLDivElement>(null);
    const walletDropdownRef = useRef<HTMLDivElement>(null);
    const [
        showAccountDropdown,
        setShowAccountDropdown,
    ] = useState<boolean>(false);
    const [
        showWalletDropdown,
        setShowWalletDropdown,
    ] = useState<boolean>(false);

    const getParentAnchor = () => {
        if (dropdownAnchorElement) {
            return document.querySelector(dropdownAnchorElement) ?? document.body;
        }
        return document.body;

    };

    function getCanShowWallet() {
        return !(hideWalletOnMobile && (isTablet || isMobile) || avatarOnly);
    }


    function closeDropdowns(event: MouseEvent, isModalActive: number) {

        if (!isModalActive) {
            if (walletDropdownRef.current && !walletDropdownRef.current.contains(event.target as Node)) {
                setShowWalletDropdown(false);
            }

            if (accountDropdownRef.current && !accountDropdownRef.current.contains(event.target as Node)) {
                setShowAccountDropdown(false);
            }
        }
    }

    function handleToggleWalletDropdown(): void {

        if (!fullToken) {
            onShowSignInToast();
        } else {
            setShowWalletDropdown((prev) => !prev);
            if (showAccountDropdown) {
                setShowAccountDropdown(false);
            }
        }
    }

    function handleToggleAccountDropdown(): void {
        if (!fullToken) {
            onShowSignInToast();
        } else {
            setShowAccountDropdown((prev) => !prev);
            if (showWalletDropdown) {
                setShowWalletDropdown(false);
            }
        }
    }

    function handleStopPropagation(e: React.MouseEvent | React.TouchEvent): void {
        e.stopPropagation();
    }

    useEffect(() => {
        if (isPhantomApp()) {
            setShowWalletDropdown(true);
        }
    }, []);
    useClosePopups(closeDropdowns);

    const renderProfilePicture = () => {
        return (
            <div className="devour-top-nav_navbar_user-data_image-container">
                {fullToken
                    ? <>
                        {userProfileData?.user?.gravatar &&
                        <img
                            src={userProfileData?.user?.gravatar}
                            alt="User Profile"
                        />
                        }
                        {userProfileData?.user.nftProfilePicture &&
                        <NftProfilePictureBadge size="sm" />
                        }
                    </>
                    : <img
                        src={`${import.meta.env.VITE_CDN_URL}/images/FUEL.webp`}
                        alt="User Profile"
                    />
                }
            </div>
        );

    };

    return <>
        <div className="devour-top-nav_navbar_user-data">

            {getCanShowWallet() && <div
                className="devour-top-nav_navbar_user-data_wallets"
                onClick={handleToggleWalletDropdown}
                onMouseDown={handleStopPropagation}
                onTouchStart={handleStopPropagation}
            >
                {(isDesktop || isTablet) && <p>My Wallets</p>}
                <IoWalletSharp/>
                <p>{userProfileData?.wallets?.length
                    ? userProfileData?.wallets?.length
                    : 0}</p>
            </div>}

            <div
                className="devour-top-nav_navbar_user-data_image-button"
                onClick={handleToggleAccountDropdown}
                onMouseDown={handleStopPropagation}
                onTouchStart={handleStopPropagation}
            >
                {renderProfilePicture()}
            </div>
        </div>
        {fullToken &&
        createPortal(<>
            <div
                className="devour-top-nav_dropdown devour-top-nav_dropdown_wallet"
                ref={walletDropdownRef}
                style={{
                    opacity: !showWalletDropdown
                        ? "0"
                        : "1",
                    transition: "all .2s",
                    visibility: !showWalletDropdown
                        ? "hidden"
                        : "visible",
                }}
            >
                <DevourWalletDropdownMenuBody/>
            </div>

            <div
                className="devour-top-nav_dropdown devour-top-nav_dropdown_account"
                ref={accountDropdownRef}
                style={{
                    opacity: !showAccountDropdown
                        ? "0"
                        : "1",
                    transition: "all .2s",
                    visibility: !showAccountDropdown
                        ? "hidden"
                        : "visible",
                }}
            >
                <DevourAccountDropdownMenuBody
                    renderProfilePicture={renderProfilePicture}
                    toggleWalletDropdown={handleToggleWalletDropdown}
                />
            </div>
        </>, getParentAnchor())
        }
    </>;

};

export default DevourTopNavUserData;