import {ButtonHTMLAttributes, ReactElement, ReactNode, useEffect, useState} from "react";
import {ApiError, Token, UsersApi} from "@devour/client";
import {connect, ConnectedProps, useSelector} from "react-redux";
import {useAccount, useSignMessage, useDisconnect} from "wagmi";
import {IStore} from "@/redux/defaultStore";
import {
    addError,
    dismissWalletConnectPrompt,
    updateCurrentUser,
} from "@/redux/meta/metaActions";
import getConfig from "../../utils/getConfig";
import FrameButton from "../buttons/FrameButton";
import FrameOneModal from "./modalComponents/FrameOneModal";
import FrameModalBody from "./modalComponents/FrameModalBody";
import {IoIosCheckmarkCircle} from "react-icons/io";
import {isAndroidApp} from "@/utils/isGoNative";
import {useGetUserProfile} from "@/hooks/useGetUserProfile";
import FrameModalFooter from "@/components/modals/modalComponents/FrameModalFooter";
import {useGetNftOwnerships} from "@/hooks/useGetNftOwnerships";
import {hexlify, toUtf8Bytes} from "ethers";
import {useGetNftOwnershipsForUser} from "@/hooks/useGetNftOwnershipsForUser";

enum WalletConnectModalStatus {
    CLOSED = "CLOSED",
    PROMPT_CONNECT = "PROMPT_CONNECT",
    LOADING_TRACKING_CODE = "LOADING_TRACKING_CODE",
    PROMPT_SIGNING = "PROMPT_SIGNING",
    LOADING_SIGNING = "LOADING_SIGNING",
    FINISHED = "FINISHED",
}

interface StateProps {
    fullToken: Token;
    dismissedWalletConnectPrompt: boolean;
}

interface Props {
    isOpen: boolean;
    toggle: (b?) => void;
    promptedNumber?: number; // god forgive me, @spencer we need to remake this stupid component. I use this to control via a button
}

function WalletConnectRegistryModal(props: WalletConnectRegistryModalProps): ReactElement {

    const account = useAccount();
    const [
        finishRegistry,
        setFinishRegistry,
    ] = useState(true);
    const [
        apiCallInProgress,
        setApiCallInProgress,
    ] = useState(false);
    const [
        apiError,
        setApiError,
    ] = useState<ApiError>(undefined);

    const [
        manualSignature,
        setManualSignature,
    ] = useState(undefined);
    const [
        signingError,
        setSigningError,
    ] = useState(undefined);

    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const {refetch: refetchUserProfileData} = useGetUserProfile(fullToken);
    const {refetch: refetchNftOwnerships} = useGetNftOwnerships(fullToken as Token);
    const {refetch: refetchNftOwnershipsForUser} = useGetNftOwnershipsForUser(fullToken as Token);

    const {disconnectAsync} = useDisconnect();

    const hexMessage = hexlify(toUtf8Bytes(account.address
        ? account.address
        : ""));
    const {
        data: signature,
        isPending: signingIsLoading,
        signMessageAsync,
    } = useSignMessage();

    async function manualSignMessage(): Promise<void> {
        try {
            const res = await signMessageAsync({
                account: account.address,
                message: hexMessage,
            });
            if (res !== undefined) {
                setManualSignature(res);
            }
        } catch (e) {
            setSigningError(e);
        }
    }

    async function close() {
        await disconnectAsync();
        props.toggle();
        props.dispatch(dismissWalletConnectPrompt());
        setApiCallInProgress(false);
        setApiError(undefined);
        setSigningError(undefined);
    }

    useEffect(() => {
        if (props.promptedNumber != null && props.promptedNumber > 0) {
            setFinishRegistry(false);
            setApiError(undefined);
        }
    }, [props.promptedNumber]);

    useEffect(() => {
        if (!account?.isConnected) {
            setManualSignature(undefined);
            setApiError(undefined);
        }
    }, [account]);

    useEffect(() => {
        if (props.fullToken && props.promptedNumber == null) {
            getWallets()
                .then()
                .catch(console.error);
        }
    }, [props?.fullToken?.token]);

    useEffect(() => {
        async function sendAddWalletRequest() {
            setApiCallInProgress(true);
            try {
                await new UsersApi(getConfig()).addWallet({
                    addWalletRequestBody: {
                        signature,
                        publicKey: account?.address,
                    },
                });
                await getWallets();
                setFinishRegistry(true);
            } catch (err) {
                setApiError((await addError(err)).payload);
            }
            setApiCallInProgress(false);
        }

        if (signature != null && account?.address) {
            sendAddWalletRequest()
                .then()
                .catch(console.error);
        }
    }, [manualSignature]);

    useEffect(() => {
        if (finishRegistry) {
            void refetchUserProfileData();
            void refetchNftOwnerships();
            void refetchNftOwnershipsForUser();
        }
    }, [finishRegistry]);

    async function getWallets() {
        const userRes = await new UsersApi(getConfig(props.fullToken)).getProfile();
        await props.dispatch(updateCurrentUser(userRes));
        if (userRes.wallets?.length > 0) {
            setFinishRegistry(true);
        } else {
            setFinishRegistry(false);
        }
    }

    /**
     * Helper to determine status for what content to show in the modal based on a bunch of state variables & the status
     * of the blockchain transaction.
     *
     */
    function getModalOpenStatus(): WalletConnectModalStatus {

        if (props.dismissedWalletConnectPrompt && !(props.promptedNumber > 0)) {
            return WalletConnectModalStatus.CLOSED;
        }

        // When user is completely done with modal, or while we're waiting on the getRegistrySigningsForUser api result which
        // tells us if the user has already signed or not; this will return and close the modal.

        // Logged in, haven't connected account with Wallet Connect.
        if (props.fullToken && !account.isConnected && !finishRegistry) {
            return WalletConnectModalStatus.PROMPT_CONNECT;

        } else if (signingIsLoading || apiCallInProgress) {
            return WalletConnectModalStatus.LOADING_SIGNING;

            // When user has finished signing the registry.
        } else if (props.fullToken && !finishRegistry) {
            return WalletConnectModalStatus.PROMPT_SIGNING;

            // Loading; waiting for the signing to complete.
        } else if (finishRegistry) {
            return WalletConnectModalStatus.FINISHED;
        }

        return WalletConnectModalStatus.CLOSED;
    }

    function renderWalletBody(): ReactNode {
        const walletConnectModalStatus = getModalOpenStatus();
        switch (walletConnectModalStatus) {
            case WalletConnectModalStatus.FINISHED:
                return renderWalletFinishedBody();
            case WalletConnectModalStatus.PROMPT_CONNECT:
                return renderWalletConnectBody();
            case WalletConnectModalStatus.PROMPT_SIGNING:
                return renderWalletSigningBody();
            case WalletConnectModalStatus.LOADING_TRACKING_CODE:
            case WalletConnectModalStatus.LOADING_SIGNING:
                return renderWalletLoadingBody();
            case WalletConnectModalStatus.CLOSED:
                // Modal is closed, body is empty
                return <div/>;
            default:
                // Shouldn't ever get here.
                console.error("Error in rendering Waller Body.");
                return <div/>;
        }
    }

    function renderWalletFinishedBody(): ReactNode {
        return (
            <div className="wallet-connect-registry-modal_body_finished">
                <div className="wallet-connect-registry-modal_body_finished_check-con">
                    <IoIosCheckmarkCircle
                        className="wallet-connect-registry-modal_body_finished_check-con_check"/>
                </div>

                <p className="wallet-connect-registry-modal_body_finished_status">
                    Wallet connected!
                </p>
            </div>
        );
    }

    function renderWalletConnectBody(): ReactNode {
        return (
            <div className="wallet-connect-registry-modal_body_prompt-connect">
                <h4>
                    Wallet Connect
                </h4>
                <p>
                    Don't have a wallet?{" "}
                    <a
                        href="https://metamask.io/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Click here to create one
                    </a>
                </p>

                <div className="wallet-connect-registry-modal_body_prompt-connect_web3-btn-con">
                    <w3m-button/>
                </div>
            </div>
        );
    }

    function renderWalletSigningBody(): ReactNode {
        return (
            <div className="wallet-connect-registry-modal_body_prompt-signing">
                <h4>
                    Verify Wallet
                </h4>
                <p>
                    Now, click to verify your wallet signature.
                </p>

                <div className="wallet-connect-registry-modal_body_prompt-signing_web3-btn-con">
                    <w3m-button/>
                </div>

                <FrameButton
                    <ButtonHTMLAttributes<HTMLButtonElement>>
                    color="purple"
                    size="normal"
                    onClick={manualSignMessage}
                    className="wallet-connect-registry-modal_body_prompt-signing_btn"
                    forwardProps={{disabled: signingIsLoading}}
                >
                    Verify
                </FrameButton>

                <br/>

                {signingError &&
                <p className="wallet-connect-registry-modal_body_prompt-signing_error">
                    {signingError?.["reason"]}
                </p>
                }

                {apiError &&
                <p className="wallet-connect-registry-modal_body_prompt-signing_error">
                    {
                        apiError?.errors?.[0].message === "The 'address' field must be unique."
                            ? "This wallet is already paired with another account. Please choose a different wallet."
                            : apiError?.message
                    }
                </p>
                }
            </div>
        );
    }

    function renderWalletLoadingBody(): ReactNode {
        return (
            <div className="wallet-connect-registry-modal_body_loading-signing">
                <div className="wallet-connect-registry-modal_body_loading-signing_spinner-con">
                    <div className="spinner"/>
                </div>

                <p className="wallet-connect-registry-modal_body_loading-signing_status">
                    Verifying...
                </p>
            </div>
        );
    }

    return (
        <>
            <FrameOneModal
                isOpen={props.isOpen}
                toggle={close}
                contentClassName="wallet-connect-registry-modal"
            >
                <FrameModalBody className="wallet-connect-registry-modal_body">
                    {renderWalletBody()}
                    {isAndroidApp() &&
                    <div className={"wallet-connect-registry-modal_body_android_app_disclaimer"}>
                            Should you encounter difficulties when attempting to connect a wallet, we suggest using
                            our{" "}
                        <a href={"https://devourgo.io"}>mobile web browser</a>
                        {" "}or the desktop version.
                    </div>
                    }
                </FrameModalBody>
                {getModalOpenStatus() !== WalletConnectModalStatus.FINISHED &&
                <FrameModalFooter>
                    <FrameButton
                        color="purple-outline"
                        size="normal"
                        onClick={close}
                        forwardProps={{disabled: signingIsLoading}}
                        className="buy-dpay-modal_footer_submit-button"
                    >
                            Cancel Connection
                    </FrameButton>
                </FrameModalFooter>
                }
            </FrameOneModal>
        </>
    );
}

function connector() {
    return connect((store: IStore, props: Props): StateProps & Props => {
        return {
            fullToken: store.authStore.fullToken,
            dismissedWalletConnectPrompt: store.metaStore.dismissedWalletConnectPrompt,
            ...props,
        };
    });
}

type WalletConnectRegistryModalProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(WalletConnectRegistryModal);
