import {ButtonHTMLAttributes, ReactElement, useState} from "react";
import FrameButton from "@/components/buttons/FrameButton";
import {useGetOwnReferralCode} from "@/hooks/useGetOwnReferralCode";
import {useSelector, useDispatch } from "react-redux";
import {IStore} from "@/redux/defaultStore";
import FrameOneAutoPanel from "@/components/modals/autoPanelComponents/FrameOneAutoPanel";
import FrameAutoPanelBody from "@/components/modals/autoPanelComponents/FrameAutoPanelBody";
import FrameAutoPanelFooter from "@/components/modals/autoPanelComponents/FrameAutoPanelFooter";
import CopiedInput from "@/components/inputs/ReferralCopiedInput";
import {MdOutlineContentCopy} from "react-icons/md";
import {isGoNative} from "@/utils/isGoNative";
import Toast from "@/components/Toast";
import {FiX} from "react-icons/fi";
import useThemePreference from "@/hooks/useThemePreference";
import { toggleLoginOpen } from "@/redux/meta/metaActions";

function GoFrensPromotionInviteCard(): ReactElement {
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const [
        isCodeCopied,
        setIsCodeCopied,
    ] = useState<boolean>(false);
    const [
        isLinkCopied,
        setIsLinkCopied,
    ] = useState<boolean>(false);
    const [
        isInviteModalOpen,
        setIsInviteModalOpen,
    ] = useState<boolean>(false);
    const [
        showToast,
        setShowToast,
    ] = useState<boolean>(false);
    const dispatch = useDispatch();
    const {data: ownReferralCodeData} = useGetOwnReferralCode(fullToken);
    const referralCode = ownReferralCodeData?.referralCodes?.[0].code;
    const inviteLink = `${import.meta.env.VITE_WEB_URL}/?ref=${encodeURI(referralCode)}`;

    const { getThemedImageUrl } = useThemePreference();

    return (
        <>
            <Toast
                message='Copied referral code!'
                isOpen={isCodeCopied}
                showButton={false}
                onDismiss={() => setIsCodeCopied(false)}
                duration={3000}
            />
            <Toast
                message='Copied referral link!'
                isOpen={isLinkCopied}
                showButton={false}
                onDismiss={() => setIsLinkCopied(false)}
                duration={3000}
            />
            <FrameOneAutoPanel
                isOpen={isInviteModalOpen}
                toggle={() => setIsInviteModalOpen(false)}
                contentClassName="gofrens_promotions_invite_modal"
            >
                <div className="gofrens_promotions_invite_modal_header">
                    {/* a trick to make the img center */}
                    <FiX
                        className="frame-one-modal_header_title-row_close-icon"
                        style={{visibility: "hidden"}}
                    />
                    <img
                        src={getThemedImageUrl(`${import.meta.env.VITE_CDN_URL}/images/gofriends-logo.png`)}
                        alt="GoFriends"
                    />
                    <FiX
                        className="frame-one-modal_header_title-row_close-icon"
                        onClick={() => setIsInviteModalOpen(false)}
                    />
                </div>
                <FrameAutoPanelBody className="gofrens_promotions_invite_modal_body">
                    <img
                        src={`${import.meta.env.VITE_CDN_URL}/images/gofrens_invite_modal_icon.svg`}
                        alt="GoFriends"
                    />
                    <div className="gofrens_promotions_invite_modal_body_title">Invite and earn rewards</div>
                    <div className="gofrens_promotions_invite_modal_body_description">Refer a friend to DevourGO and get
                        exclusive deals!
                    </div>
                </FrameAutoPanelBody>

                <FrameAutoPanelFooter className="gofrens_promotions_invite_modal_footer">
                    <FrameButton
                        color={"purple"}
                        size={"large"}
                        onClick={() => {
                            setIsCodeCopied(false);
                            setIsLinkCopied(true);
                            if (isGoNative()) {
                                // @ts-ignore
                                gonative.clipboard.set({"data": inviteLink}); // for app to copy to clipboard
                            } else {
                                navigator.clipboard.writeText(inviteLink);
                            }
                        }}>
                        Share invite link
                    </FrameButton>
                    <div className='gofrens_promotions_invite_modal_footer_description'>Or copy your referral code</div>
                    <div className='gofrens_promotions_invite_modal_footer_referral'>
                        <div className="gofrens_promotions_invite_modal_footer_referral_input">
                            <CopiedInput
                                disabled={true}
                                value={referralCode}
                                isCopiedVisible={false}
                            />
                        </div>
                        <FrameButton
                            color="gray"
                            size="normal"
                            leftIcon={MdOutlineContentCopy}
                            className="gofrens_promotions_invite_modal_footer_referral_button"
                            onClick={() => {
                                setIsCodeCopied(true);
                                setIsLinkCopied(false);
                                if (isGoNative()) {
                                    // @ts-ignore
                                    gonative.clipboard.set({"data": referralCode});
                                } else {
                                    navigator.clipboard.writeText(referralCode);
                                }
                            }}
                        >
                            Copy
                        </FrameButton>

                    </div>

                </FrameAutoPanelFooter>

            </FrameOneAutoPanel>
            <Toast
                message="Join DevourGO to refer friends and earn rewards!"
                isOpen={showToast}
                showButton={true}
                buttonMessage={"Sign Up"}
                buttonFunction={() => {
                    dispatch(toggleLoginOpen(true));
                }}
                onDismiss={() => setShowToast(false)}
            />
            <div
                className="gofrens_tutorial_invite"
                style={{
                    backgroundImage: `url(${`${import.meta.env.VITE_CDN_URL}/images/promo-invite.webp`})`,
                }}
            >
                <div className="gofrens_tutorial_invite_text">
                    <h4>
                        Invite & Earn Rewards!
                    </h4>
                    <p>
                        Refer a friend to join DevourGO and get exclusive deals.
                    </p>
                </div>

                <div className="gofrens_tutorial_invite_button">
                    <FrameButton
                        <ButtonHTMLAttributes<HTMLButtonElement>>
                        color="purple"
                        size="narrow"
                        onClick={() => {
                            if (fullToken) {
                                setIsInviteModalOpen(true);
                            } else {
                                setShowToast(true);
                            }
                        }}
                    >
                        Invite Now
                    </FrameButton>
                </div>
            </div>
        </>
    );
}

export default GoFrensPromotionInviteCard;
