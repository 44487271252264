import {ReactElement} from "react";
import FrameOneModal from "./modalComponents/FrameOneModal";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameButton from "../buttons/FrameButton";

interface Props {
    isOpen: boolean;
    toggle: () => void;
}

function WalletInfoModal(props: Props): ReactElement {

    return (
        <FrameOneModal
            isOpen={props.isOpen}
            toggle={props.toggle}
            contentClassName="go-vip-levels-info-modal"
        >
            <FrameModalHeader
                title="Adding Additional Wallets"
                toggle={props.toggle}
            />

            <FrameModalBody className="go-vip-levels-info-modal_body">
                <div className="go-vip-levels-info-modal_body_content">

                    <p className="go-vip-levels-info-modal_body_content_description">
                        To add an <strong>additional wallet,</strong> you need to first disconnect the wallet
                        currently attached from within the wallet application you used to connect.
                        All additional wallets connected will stay registered.
                    </p>

                    <div className="go-vip-levels-info-modal_body_content_close-btn-container">
                        <FrameButton
                            color="purple"
                            size="normal"
                            className="go-vip-levels-info-modal_body_content_close-btn-container_button"
                            onClick={props.toggle}
                        >
                            Dismiss
                        </FrameButton>
                    </div>
                </div>
            </FrameModalBody>
        </FrameOneModal>
    );
}

export default WalletInfoModal;
