/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InTheWeedsRequestBody
 */
export interface InTheWeedsRequestBody {
    /**
     * 
     * @type {number}
     * @memberof InTheWeedsRequestBody
     */
    stars_obtained: number;
    /**
     * 
     * @type {string}
     * @memberof InTheWeedsRequestBody
     */
    level_status: string;
    /**
     * 
     * @type {number}
     * @memberof InTheWeedsRequestBody
     */
    level_played: number;
}

export function InTheWeedsRequestBodyFromJSON(json: any): InTheWeedsRequestBody {
    return InTheWeedsRequestBodyFromJSONTyped(json, false);
}

export function InTheWeedsRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): InTheWeedsRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'stars_obtained': json['stars_obtained'],
        'level_status': json['level_status'],
        'level_played': json['level_played'],
    };
}

export function InTheWeedsRequestBodyToJSON(value?: InTheWeedsRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'stars_obtained': value.stars_obtained,
        'level_status': value.level_status,
        'level_played': value.level_played,
    };
}


