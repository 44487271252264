/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PreviousMenuOrderItem,
    PreviousMenuOrderItemFromJSON,
    PreviousMenuOrderItemFromJSONTyped,
    PreviousMenuOrderItemToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetOrderItemsResponse
 */
export interface GetOrderItemsResponse {
    /**
     * 
     * @type {Array<PreviousMenuOrderItem>}
     * @memberof GetOrderItemsResponse
     */
    orderItems: Array<PreviousMenuOrderItem>;
}

export function GetOrderItemsResponseFromJSON(json: any): GetOrderItemsResponse {
    return GetOrderItemsResponseFromJSONTyped(json, false);
}

export function GetOrderItemsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetOrderItemsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'orderItems': ((json['orderItems'] as Array<any>).map(PreviousMenuOrderItemFromJSON)),
    };
}

export function GetOrderItemsResponseToJSON(value?: GetOrderItemsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'orderItems': ((value.orderItems as Array<any>).map(PreviousMenuOrderItemToJSON)),
    };
}


