/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetUsersCsvResponseAllOf,
    GetUsersCsvResponseAllOfFromJSON,
    GetUsersCsvResponseAllOfFromJSONTyped,
    GetUsersCsvResponseAllOfToJSON,
    UserLvl,
    UserLvlFromJSON,
    UserLvlFromJSONTyped,
    UserLvlToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetUsersCsvResponse
 */
export interface GetUsersCsvResponse {
    /**
     * 
     * @type {Array<UserLvl>}
     * @memberof GetUsersCsvResponse
     */
    users: Array<UserLvl>;
}

export function GetUsersCsvResponseFromJSON(json: any): GetUsersCsvResponse {
    return GetUsersCsvResponseFromJSONTyped(json, false);
}

export function GetUsersCsvResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetUsersCsvResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'users': ((json['users'] as Array<any>).map(UserLvlFromJSON)),
    };
}

export function GetUsersCsvResponseToJSON(value?: GetUsersCsvResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'users': ((value.users as Array<any>).map(UserLvlToJSON)),
    };
}


