import {AddressBook, UtilsApi} from "@devour/client";
import {getDistanceFromLatLonInKm} from "./getDistanceFromLatLonInKm";
import * as Sentry from "@sentry/react";

/**
 * Check if HTML5 geolocation is enabled and this website is granted access
 */
export async function getGeolocationStatus(): Promise<boolean> {
    try {
        const geolocationStatus = await navigator.permissions.query({name: "geolocation"});
        return geolocationStatus.state === "granted";
    } catch {
        return false;
    }
}

async function onGeolocation(addressBooks: Array<AddressBook> = []): Promise<string | false> {
    try {
        // `navigator.geolocation.getCurrentPosition` isn't an actual promise.
        // Need to wrap with a real promise and await to show loading screen.
        const position: GeolocationPosition = await new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject);
        });

        // If current position is close to a position in the user's address book, use the address from the address book
        const originLng = position.coords.longitude;
        const originLat = position.coords.latitude;
        const sortedAddresses = addressBooks.filter(a => a.location !== undefined) // Filter out all the undefined locations
            .map(a => {
                const destinationLng = a.location.coordinates[0];
                const destinationLat = a.location.coordinates[1];
                const distance = getDistanceFromLatLonInKm(originLat, originLng, destinationLat, destinationLng);

                return {
                    distance: distance,
                    placeId: a.placeId,
                };
            })
            .sort((a, b) => a.distance - b.distance); // Get the closest Address in the AddressBook

        if (sortedAddresses && sortedAddresses.length > 0 && sortedAddresses[0].distance < 1) {
            return `/restaurants/search/${sortedAddresses[0].placeId}`;
        }

        const res = await new UtilsApi().getAddressFromLatLng({
            latitude: position.coords.latitude.toString(),
            longitude: position.coords.longitude.toString(),
        });
        return `/restaurants/search/${res?.placeId}`;
    } catch (e) {
        Sentry.captureException(e);
        return false;
    }
}

/**
 * Get the "landing" page route for restaurant search / food ordering.
 * Address priority: 1. Default address in book, 2. Geolocation, 3. Last searched place ID.
 * @param addressBooks
 * @param lastSearchedPlaceId
 * @return Route if geolocation, default address, or last searched place id is found. Else false.
 */
export async function restaurantSearchRedirect(addressBooks: Array<AddressBook> = [], lastSearchedPlaceId?: string): Promise<string | false> {
    const defaultAddress = addressBooks?.find((addressBook) => addressBook?.isDefault);
    let geoLocation: string | false;

    if (defaultAddress?.placeId || lastSearchedPlaceId) {
        return `/restaurants/search/${defaultAddress?.placeId || lastSearchedPlaceId}`;
    }

    const geolocationStatus = await getGeolocationStatus();
    if (geolocationStatus) {
        // only call this function if location is enabled
        geoLocation = await onGeolocation(addressBooks);
    }

    if (geoLocation) {
        return geoLocation;
    } else if (defaultAddress?.placeId || lastSearchedPlaceId) {
        return `/restaurants/search/${defaultAddress?.placeId || lastSearchedPlaceId}`;
    } else {
        return false;
    }
}
