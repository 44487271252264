import {ButtonHTMLAttributes, FormEvent, ReactElement, useState} from "react";
import {CommerceApi, UsersApi} from "@devour/client";
import FrameButton from "../buttons/FrameButton";
import FrameOneAutoPanel from "./autoPanelComponents/FrameOneAutoPanel";
import FrameAutoPanelBody from "./autoPanelComponents/FrameAutoPanelBody";
import {addError, decrementLoading, incrementLoading, updateCurrentUser} from "@/redux/meta/metaActions";
import getConfig from "../../utils/getConfig";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {PatternFormat} from "react-number-format";
import {NumberFormatValues} from "react-number-format/types/types";
import {FiX} from "react-icons/fi";
import {IoCardSharp} from "react-icons/io5";
import classNames from "classnames";
import {CreditCardFormValues, isCreditCardValid} from "@/utils/isCreditCardValid";

const defaultValues: CreditCardFormValues = {
    cardNumber: "",
    expDate: {
        floatValue: undefined,
        formattedValue: "",
        value: "",
    },
    securityCode: "",
    postalCode: "",
    country: "US",
};


interface CreditCardAddModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSubmit: (paymentMethodId: string) => void;
}

function CardPaymentAddModal(props: CreditCardAddModalProps): ReactElement {
    const [
        formValues,
        setFormValues,
    ] = useState<CreditCardFormValues>(defaultValues);
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const dispatch = useDispatch();

    function onCloseHelper(): void {
        setFormValues(defaultValues);
        props.onClose();
    }

    async function onFormSubmit(e: FormEvent<HTMLFormElement>): Promise<void> {
        e.preventDefault();
        dispatch(incrementLoading());
        try {
            const paymentMethod = await new CommerceApi(getConfig()).stripePaymentMethodAdd({
                createPaymentMethodBody: {
                    cardNumber: formValues.cardNumber,
                    expMonth: parseInt(formValues.expDate.formattedValue.split("/")[0]),
                    expYear: 2000 + parseInt(formValues.expDate.formattedValue.split("/")[1]),
                    cvc: formValues.securityCode,
                    postalCode: formValues.postalCode,
                    country: "US",
                },
            });
            setFormValues(defaultValues);
            props.onSubmit(paymentMethod.paymentMethodId);
            await new UsersApi(getConfig(fullToken)).updateDefaultPayment({
                defaultPaymentMethodId: paymentMethod.paymentMethodId,
            });
            const userRes = await new UsersApi(getConfig(fullToken)).getProfile();
            dispatch(updateCurrentUser(userRes));
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            dispatch(decrementLoading());
        }
    }

    /**
	 * Handle the card number input onChange.
	 *
	 * @param cardNumber
	 */
    function cardNumberOnChange(cardNumber: NumberFormatValues): void {
        setFormValues({
            ...formValues,
            cardNumber: cardNumber.value,
        });
    }

    /**
	 * Handle the card expiry input onChange.
	 *
	 * @param expDate
	 */
    function expiryOnChange(expDate: NumberFormatValues): void {
        setFormValues({
            ...formValues,
            expDate,
        });
    }

    /**
	 * Handle the card security input onChange.
	 *
	 * @param security
	 */
    function securityOnChange(security: NumberFormatValues): void {
        setFormValues({
            ...formValues,
            securityCode: security.value,
        });
    }

    /**
	 * Handle the postal code input onChange.
	 *
	 * @param postalCode
	 */
    function postalCodeOnChange(postalCode: NumberFormatValues): void {
        setFormValues({
            ...formValues,
            postalCode: postalCode.value,
        });
    }

    return (
        <FrameOneAutoPanel
            isOpen={props.isOpen}
            toggle={onCloseHelper}
            size="sm"
            contentClassName="card-payment-add-modal"
            modalOnTablet={true}
        >
            <div className="card-payment-add-modal_header">
                <div className="card-payment-add-modal_header_title">
                    <IoCardSharp />
                    <h4>Add Credit Card</h4>
                </div>
                <FiX
                    className="card-payment-add-modal_header_close-icon"
                    onClick={onCloseHelper}
                />
            </div>
            <FrameAutoPanelBody className="card-payment-add-modal_body" modalOnTablet={true}>
                <form onSubmit={onFormSubmit}>

                    <div className="card-payment-add-modal_body_card-number">
                        <label>Card Number</label>
                        <PatternFormat
                            value={formValues.cardNumber}
                            valueIsNumericString={true}
                            type="tel"
                            format="#### #### #### ####"
                            placeholder="0000 0000 0000 0000"
                            className="card-payment-add-modal_body_card-number_text"
                            onValueChange={cardNumberOnChange}
                        />
                    </div>

                    <div className="card-payment-add-modal_body_row">
                        <div className="card-payment-add-modal_body_row_mobile-row">
                            <div className="card-payment-add-modal_body_expiry">
                                <label>Exp. date</label>
                                <PatternFormat
                                    value={formValues.expDate.value}
                                    valueIsNumericString={true}
                                    type="tel"
                                    format="## / ##"
                                    placeholder="MM / YY"
                                    onValueChange={expiryOnChange}
                                />
                            </div>

                            <div className="card-payment-add-modal_body_security">
                                <label>Security Code</label>
                                <PatternFormat
                                    value={formValues.securityCode}
                                    valueIsNumericString={true}
                                    type="tel"
                                    format="###"
                                    placeholder="000"
                                    onValueChange={securityOnChange}
                                />
                            </div>
                        </div>

                        <div className="card-payment-add-modal_body_postal-code">
                            <label>Zip Code</label>
                            <PatternFormat
                                value={formValues.postalCode}
                                valueIsNumericString={true}
                                type="tel"
                                format="#####"
                                placeholder="00000"
                                onValueChange={postalCodeOnChange}
                            />
                        </div>

                    </div>

                    <div className="card-payment-add-modal_body_buttons">
                        <button
                            className={classNames(
                                "card-payment-add-modal_body_buttons_reset",
                                {"frame-one-button_disabled": !isCreditCardValid(formValues)},
                            )}
                            onClick={(e) => {
                                e.preventDefault();
                                setFormValues(defaultValues);
                            }}
                        >
							Reset
                        </button>
                        <FrameButton
                            <ButtonHTMLAttributes<HTMLButtonElement>>
                            color="purple"
                            size="narrow"
                            className="card-payment-add-modal_body_buttons_submit"
                            forwardProps={{
                                type: "submit",
                                disabled: !isCreditCardValid(formValues),
                            }}
                            showSpinner={true}
                        >
							Add Card
                        </FrameButton>
                    </div>

                </form>
            </FrameAutoPanelBody>
        </FrameOneAutoPanel>
    );
}

export default CardPaymentAddModal;
