import {ReactElement, useContext} from "react";
import {useParams} from "react-router";
import {useMenuOrder} from "@/hooks/menuOrder/useMenuOrder";
import Accordion from "@/components/Accordion";
import CheckoutOrderSummaryItem from "@/components/checkout/checkoutOrderSummary/CheckoutOrderSummaryItem";
import {MenuOrderItem} from "@devour/client";
import {RestaurantContext} from "@/pages/restaurants/context/RestaurantContext";

interface Props {
    onClick: () => void;
}

function CheckoutOrderSummaryItemsContainer(props: Props): ReactElement {
    const { menuOrderId: contextMenuOrderId } = useContext(RestaurantContext);
    const { menuOrderId: paramMenuOrderId } = useParams<{ menuOrderId: string }>();
    const menuOrderId = paramMenuOrderId || contextMenuOrderId;

    const {data: menuOrder} = useMenuOrder(menuOrderId);
    const sumQuantity: number = menuOrder?.orderItems.reduce((accumulator, object) => {
        return accumulator + object.quantity;
    }, 0);

    function renderItems(): Array<ReactElement> | ReactElement {

        if (!menuOrder.orderItems || menuOrder.orderItems.length === 0) {
            return <p>There are no items in the cart.</p>;
        }

        return menuOrder.orderItems.map((item: MenuOrderItem, index: number) => <CheckoutOrderSummaryItem
            key={`${item.menuItemId}-${index}`}
            quantity={item.quantity}
            name={item.name}
            price={item.price}
            customization={item.customizations}
        />);
    }

    return (
        <div className="checkout-summary_items">
            <Accordion
                onClick={props.onClick}
                header={<p>{sumQuantity ?? 0} {sumQuantity === 1
                    ? "Item"
                    : "Items"}</p>}
                body={renderItems()}
                showDivider={true}
                showBorder={false}
                showChevron={true}
            />
        </div>
    );
}

export default CheckoutOrderSummaryItemsContainer;

