import {ButtonHTMLAttributes, ReactElement, ReactNode, useState} from "react";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/metaActions";
import {
    Transaction,
    TransactionType,
    Withdraw,
    WithdrawsApi,
    WithdrawStatus, WithdrawWalletChain,
} from "@devour/client";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "../redux/defaultStore";
import getConfig from "../utils/getConfig";
import DpayBalanceCard from "../components/DpayBalanceCard";
import FrameButton from "../components/buttons/FrameButton";
import TransactionHistoryCard from "../components/TransactionHistoryCard";
import RequestWithdrawModal from "../components/modals/RequestWithdrawModal";
import moment from "moment/moment";
import {getTransactionSubjectCardLabel} from "../utils/getTransactionSubjectCardLabel";
import {parseWithdrawStatus} from "../utils/parseWithdrawStatus";
import {truncateMiddle} from "../utils/truncateMiddle";
import CoinGeckoWidget from "../components/CoinGeckoWidget";
import {useGetWithdrawRequestsOwn} from "@/hooks/useGetWithdrawRequestsOwn";
import {defaultFrontendPagination, FrontendPagination} from "@/components/paginator/FramePaginator";
import {useGetTransactionsPaginated} from "@/hooks/useGetTransactionsPaginated";

function WithdrawPage(): ReactElement {
    const dispatch = useDispatch();
    const currentUser = useSelector((store: IStore) => store.metaStore.currentUser);
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);

    const [
        showWithdrawModal,
        setShowWithdrawModal,
    ] = useState(false);
    const [
        withdrawalRequestsPagination,
        setWithdrawalRequestsPagination,
    ] = useState<FrontendPagination>(defaultFrontendPagination);
    const [
        transactionsPagination,
        setTransactionsPagination,
    ] = useState<FrontendPagination>(defaultFrontendPagination);

    const {
        data: withdrawRequestsOwnData,
        isLoading: isWithdrawRequestsOwnLoading,
        refetch: refetchWithdrawRequestsOwn,
    } = useGetWithdrawRequestsOwn(fullToken, {
        limit: withdrawalRequestsPagination.limit,
        offset: withdrawalRequestsPagination.offset,
    });

    const {
        data: transactionsPaginatedData,
        isLoading: isTransactionsPaginatedLoading,
        refetch: refetchTransactionsPaginated,
    } = useGetTransactionsPaginated(fullToken, currentUser?.user.id, {
        limit: transactionsPagination.limit,
        offset: transactionsPagination.offset,
    });

    async function getTransactionData(): Promise<void> {
        refetchTransactionsPaginated();
        refetchWithdrawRequestsOwn();
    }

    function increaseWithdrawalRequestsLimit(): void {
        setWithdrawalRequestsPagination({
            ...withdrawalRequestsPagination,
            limit: withdrawalRequestsPagination.limit + 10,
        });
    }

    function increaseTransactionsLimit(): void {
        setTransactionsPagination({
            ...transactionsPagination,
            limit: transactionsPagination.limit + 10,
        });
    }

    function renderWithdrawal(withdrawal: Withdraw): ReactElement {

        let transactionUrl;

        if (withdrawal.hash) {
            switch (withdrawal.walletChain) {
                case WithdrawWalletChain.SOLANA:
                    transactionUrl = `https://solscan.io/tx/${withdrawal.hash}`;
                    break;
                default:
                    transactionUrl = `https://etherscan.io/tx/${withdrawal?.hash}`;
            }
        }

        async function cancelApiRequest() {
            dispatch(incrementLoading());
            try {
                await new WithdrawsApi(getConfig(fullToken)).cancelWithdrawRequest({
                    id: withdrawal.id,
                    cancelWithdrawBody: {
                        notes: "Cancelled by user.",
                    },
                });
                await getTransactionData();
            } catch (err) {
                dispatch(await addError(err));
            }
            dispatch(decrementLoading());
        }

        return (
            <TransactionHistoryCard
                key={`withdraw_${withdrawal?.id}`}
                month={moment(withdrawal?.createdAt).format("MMM")}
                day={moment(withdrawal?.createdAt).format("D")}
                leftTitle={`Withdrawal Request - ${parseWithdrawStatus(withdrawal?.status)}`}
                leftSubtitle={`Recipient: ${withdrawal?.wallet}`}
                leftSubTitleHref={`https://etherscan.io/address/${withdrawal?.wallet}`}
                rightTitle={`${withdrawal?.amount} ${import.meta.env.VITE_TOKEN_NAME}`}
                rightSubtitle={withdrawal?.hash
                    ? truncateMiddle(withdrawal?.hash, 30)
                    : ""}
                rightSubtitleHref={transactionUrl ?? undefined}
            >
                {
                    withdrawal?.status === WithdrawStatus.PENDING &&
										<>
										    <br/>
										    <FrameButton
										        color="danger"
										        size="narrow"
										        onClick={cancelApiRequest}
										    >
														Cancel Request
										    </FrameButton>
										</>

                }
            </TransactionHistoryCard>
        );
    }

    function renderTransaction(transaction: Transaction): ReactNode {
        return (
            <TransactionHistoryCard
                key={`transaction_${transaction.id}`}
                month={moment(transaction.createdAt).format("MMM")}
                day={moment(transaction.createdAt).format("D")}
                leftTitle={transaction.type === TransactionType.DEBIT
                    ? `${import.meta.env.VITE_TOKEN_NAME} received`
                    : `${import.meta.env.VITE_TOKEN_NAME} withdrawn`}
                leftSubtitle={getTransactionSubjectCardLabel(transaction)}
                rightTitle={`${transaction.type === TransactionType.DEBIT
                    ? "+"
                    : "-"}${transaction.amount} ${import.meta.env.VITE_TOKEN_NAME}`}
                rightSubtitle={transaction.type === TransactionType.DEBIT
                    ? "Debit"
                    : "Credit"}
            />
        );
    }

    function toggleWithdrawModal(): void {
        setShowWithdrawModal(!showWithdrawModal);
    }

    return (
        <>
            <RequestWithdrawModal
                isOpen={showWithdrawModal}
                onClose={toggleWithdrawModal}
                onDone={getTransactionData}
                transactionsResponse={transactionsPaginatedData}
            />

            <div className="withdraw-page">
                <h3 className="withdraw-page_title">
										Withdraw {import.meta.env.VITE_TOKEN_NAME}
                </h3>

                <br/>
                <CoinGeckoWidget/>
                <br/>

                {isTransactionsPaginatedLoading && isWithdrawRequestsOwnLoading && transactionsPaginatedData === undefined
                    ? <div className="withdraw-page_loading">
                        <div className="spinner"/>
                    </div>
                    : <div className="withdraw-page_content">
                        <div className="withdraw-page_content_balance">
                            <h4 className="withdraw-page_content_balance_title">
																Your balance
                            </h4>

                            <DpayBalanceCard
                                cryptoBalance={transactionsPaginatedData?.balance}
                                usdBalance={transactionsPaginatedData?.balanceInUsd}
                            />

                            <div className="withdraw-page_content_balance_btn-con">
                                <FrameButton
                                    <ButtonHTMLAttributes<HTMLButtonElement>>
                                    color="purple"
                                    size="large"
                                    onClick={toggleWithdrawModal}
                                    forwardProps={{
                                        disabled: transactionsPaginatedData == null || transactionsPaginatedData?.balance <= 0,
                                    }}
                                >
																		Request Withdraw
                                </FrameButton>
                                <br/>
                                <label><i>There is a fee of $3
																		in {import.meta.env.VITE_TOKEN_NAME} to withdraw.</i></label>

                            </div>
                        </div>

                        {withdrawRequestsOwnData?.withdraws?.length > 0 &&
														<div className="withdraw-page_content_transactions">
														    <h4 className="withdraw-page_content_transactions_title">
																		Withdraw History
														    </h4>

														    <div className="withdraw-page_content_transactions_cards">
														        {withdrawRequestsOwnData?.withdraws?.map(renderWithdrawal)}
														    </div>

														    <div className="withdraw-page_load-more-button">
														        <FrameButton
														            color="purple"
														            size="large"
														            onClick={increaseWithdrawalRequestsLimit}
														        >
																				See more withdrawal requests
														        </FrameButton>
														    </div>
														</div>
                        }

                        {transactionsPaginatedData?.transactions?.data.length > 0 &&
														<div className="withdraw-page_content_transactions">
														    <h4 className="withdraw-page_content_transactions_title">
																		Transaction History
														    </h4>

														    <div className="withdraw-page_content_transactions_cards">
														        {transactionsPaginatedData?.transactions?.data.map(renderTransaction)}
														    </div>

														    <div className="withdraw-page_load-more-button">
														        <FrameButton
														            color="purple"
														            size="large"
														            onClick={increaseTransactionsLimit}
														        >
																				See more transactions
														        </FrameButton>
														    </div>

														</div>
                        }
                    </div>
                }
            </div>
        </>
    );
}

export default WithdrawPage;
