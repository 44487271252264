/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
    TimeStamps,
    TimeStampsFromJSON,
    TimeStampsFromJSONTyped,
    TimeStampsToJSON,
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './';

/**
 * Code that verifies user identity before account deletion.
 * @export
 * @interface DeletionVerificationCode
 */
export interface DeletionVerificationCode {
    /**
     * 
     * @type {string}
     * @memberof DeletionVerificationCode
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof DeletionVerificationCode
     */
    createdAt: number;
    /**
     * 
     * @type {number}
     * @memberof DeletionVerificationCode
     */
    updatedAt: number;
    /**
     * 
     * @type {string}
     * @memberof DeletionVerificationCode
     */
    code: string;
    /**
     * 
     * @type {number}
     * @memberof DeletionVerificationCode
     */
    expiration: number;
    /**
     * 
     * @type {User}
     * @memberof DeletionVerificationCode
     */
    user: User;
}

export function DeletionVerificationCodeFromJSON(json: any): DeletionVerificationCode {
    return DeletionVerificationCodeFromJSONTyped(json, false);
}

export function DeletionVerificationCodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeletionVerificationCode {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'code': json['code'],
        'expiration': json['expiration'],
        'user': UserFromJSON(json['user']),
    };
}

export function DeletionVerificationCodeToJSON(value?: DeletionVerificationCode | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'code': value.code,
        'expiration': value.expiration,
        'user': UserToJSON(value.user),
    };
}


