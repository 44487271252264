import {ButtonHTMLAttributes, Dispatch, ReactElement, SetStateAction} from "react";
import {
    RestaurantSearchResult,
    NftOwnershipInformation,
    GetGlobalOrderDiscountsResponse,
    OrderDiscount,
} from "@devour/client";
import {FiChevronLeft} from "react-icons/fi";
import RestaurantCard from "@/pages/restaurants/components/RestaurantCard";
import {useParams} from "react-router";
import {RestaurantSearchFiltersQuery} from "@/utils/restaurantsSearchFilter";
import {ViewStates} from "@/pages/restaurants/RestaurantSearchResultsPage";
import FrameButton from "@/components/buttons/FrameButton";
import GlobalPromoCard from "@/pages/restaurants/components/GlobalPromoCard";

interface Props {
    title: string;
    restaurants: Array<RestaurantSearchResult>;
    setViewState: Dispatch<SetStateAction<ViewStates>>;
    restaurantFilters: RestaurantSearchFiltersQuery;
    nftOwnershipResponse: NftOwnershipInformation;
    globalOrderDiscounts?: GetGlobalOrderDiscountsResponse;

}

function ViewRestaurantsPanel(props: Props): ReactElement {
    const {placeId} = useParams<{ placeId: string; }>();

    function renderGlobalPromo(promo: OrderDiscount): ReactElement {
        return (
            <GlobalPromoCard
                promo={promo}
                nftOwnershipResponse={props.nftOwnershipResponse}
                className="restaurants-search-results-page_view-all_list_item"
            />
        );
    }

    function renderRestaurant(restaurant: RestaurantSearchResult): JSX.Element {
        return (
            <div
                key={restaurant._id}
                className="restaurants-search-results-page_view-all_list_item"
            >
                <RestaurantCard
                    placeId={placeId}
                    restaurant={restaurant}
                    restaurantFilters={props.restaurantFilters}
                    nftOwnershipResponse={props.nftOwnershipResponse}
                    showPromos="auto"
                />
            </div>
        );
    }

    return (
        <div className="restaurants-search-results-page_view-all">
            <FrameButton
                <ButtonHTMLAttributes<HTMLButtonElement>>
                color="white-drop-shadow"
                size="pill"
                leftIcon={FiChevronLeft}
                className="restaurants-search-results-page_view-all_back-btn"
                onClick={() => props.setViewState(ViewStates.VIEW_ALL)}
            >
                Go Back
            </FrameButton>
            <h3>{props.title}</h3>
            <div className="restaurants-search-results-page_view-all_list">
                {props.globalOrderDiscounts?.orderDiscounts?.length > 0 && props.globalOrderDiscounts.orderDiscounts.map(renderGlobalPromo)}
                {props.restaurants?.length > 0 && props.restaurants.map(renderRestaurant)}
            </div>
        </div>
    );
}

export default ViewRestaurantsPanel;