/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AddressBook,
    AddressBookFromJSON,
    AddressBookFromJSONTyped,
    AddressBookToJSON,
    Asset,
    AssetFromJSON,
    AssetFromJSONTyped,
    AssetToJSON,
    PhoneNumber,
    PhoneNumberFromJSON,
    PhoneNumberFromJSONTyped,
    PhoneNumberToJSON,
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
    UserType,
    UserTypeFromJSON,
    UserTypeFromJSONTyped,
    UserTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface StandardUser
 */
export interface StandardUser extends User {
    /**
     * 
     * @type {boolean}
     * @memberof StandardUser
     */
    phoneNumberVerified: boolean;
}

export function StandardUserFromJSON(json: any): StandardUser {
    return StandardUserFromJSONTyped(json, false);
}

export function StandardUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): StandardUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...UserFromJSONTyped(json, ignoreDiscriminator),
        'phoneNumberVerified': json['phoneNumberVerified'],
    };
}

export function StandardUserToJSON(value?: StandardUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...UserToJSON(value),
        'phoneNumberVerified': value.phoneNumberVerified,
    };
}


