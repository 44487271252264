/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DpayPurchase,
    DpayPurchaseFromJSON,
    DpayPurchaseFromJSONTyped,
    DpayPurchaseToJSON,
    GetDpayPurchasesResponseAllOf,
    GetDpayPurchasesResponseAllOfFromJSON,
    GetDpayPurchasesResponseAllOfFromJSONTyped,
    GetDpayPurchasesResponseAllOfToJSON,
    PaginatedResponse,
    PaginatedResponseFromJSON,
    PaginatedResponseFromJSONTyped,
    PaginatedResponseToJSON,
    PaginationInfo,
    PaginationInfoFromJSON,
    PaginationInfoFromJSONTyped,
    PaginationInfoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetDpayPurchasesResponse
 */
export interface GetDpayPurchasesResponse extends PaginatedResponse {
    /**
     * 
     * @type {Array<DpayPurchase>}
     * @memberof GetDpayPurchasesResponse
     */
    dpayPurchases: Array<DpayPurchase>;
}

export function GetDpayPurchasesResponseFromJSON(json: any): GetDpayPurchasesResponse {
    return GetDpayPurchasesResponseFromJSONTyped(json, false);
}

export function GetDpayPurchasesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetDpayPurchasesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...PaginatedResponseFromJSONTyped(json, ignoreDiscriminator),
        'dpayPurchases': ((json['dpayPurchases'] as Array<any>).map(DpayPurchaseFromJSON)),
    };
}

export function GetDpayPurchasesResponseToJSON(value?: GetDpayPurchasesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...PaginatedResponseToJSON(value),
        'dpayPurchases': ((value.dpayPurchases as Array<any>).map(DpayPurchaseToJSON)),
    };
}


