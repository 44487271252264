/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum OrderDiscountCalculation {
    FIXEDAMOUNT = 'FIXED_AMOUNT',
    PERCENTAGE = 'PERCENTAGE'
}

export function OrderDiscountCalculationFromJSON(json: any): OrderDiscountCalculation {
    return OrderDiscountCalculationFromJSONTyped(json, false);
}

export function OrderDiscountCalculationFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderDiscountCalculation {
    return json as OrderDiscountCalculation;
}

export function OrderDiscountCalculationToJSON(value?: OrderDiscountCalculation | null): any {
    return value as any;
}

