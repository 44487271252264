import {useQuery} from "@tanstack/react-query";
import {Token, UsersApi} from "@devour/client";
import getConfig from "@/utils/getConfig";

async function fetchUserLeaderboardRank(fullToken: Token): Promise<{ rank: number }> {
    return await new UsersApi(getConfig(fullToken)).getUserLeaderboardRank();
}

function useUserLeaderboardRank(fullToken: Token) {
    return {
        queryKey: [
            "user-leaderboard-rank",
            fullToken?.id ?? "unauthorized",
        ],
        queryFn: () => fetchUserLeaderboardRank(fullToken),
        staleTime: 30 * 1000,
        enabled: !!fullToken,
    } as const;
}

export function useGetUserLeaderboardRank(fullToken: Token) {
    return useQuery(useUserLeaderboardRank(fullToken));
}