/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AdminDebitOrCreditLevelPointRequestBody
 */
export interface AdminDebitOrCreditLevelPointRequestBody {
    /**
     * 
     * @type {string}
     * @memberof AdminDebitOrCreditLevelPointRequestBody
     */
    userId: string;
    /**
     * 
     * @type {number}
     * @memberof AdminDebitOrCreditLevelPointRequestBody
     */
    amount: number;
    /**
     * Setting this to true will add points, setting to false will credit points.
     * @type {boolean}
     * @memberof AdminDebitOrCreditLevelPointRequestBody
     */
    debit: boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminDebitOrCreditLevelPointRequestBody
     */
    note?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminDebitOrCreditLevelPointRequestBody
     */
    adminNote?: string;
}

export function AdminDebitOrCreditLevelPointRequestBodyFromJSON(json: any): AdminDebitOrCreditLevelPointRequestBody {
    return AdminDebitOrCreditLevelPointRequestBodyFromJSONTyped(json, false);
}

export function AdminDebitOrCreditLevelPointRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminDebitOrCreditLevelPointRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': json['userId'],
        'amount': json['amount'],
        'debit': json['debit'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'adminNote': !exists(json, 'adminNote') ? undefined : json['adminNote'],
    };
}

export function AdminDebitOrCreditLevelPointRequestBodyToJSON(value?: AdminDebitOrCreditLevelPointRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'amount': value.amount,
        'debit': value.debit,
        'note': value.note,
        'adminNote': value.adminNote,
    };
}


