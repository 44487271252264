import {ReactElement} from "react";
import {NftGrouping} from "@devour/client";
import classNames from "classnames";
import {FiCheck} from "react-icons/fi";

interface Props {
    grouping: NftGrouping;
    onSelect: () => void;
    isSelected: boolean;
    merchantsSupporting: number;
}

function NftSelectCard2(props: Props): ReactElement {

    return (
        <div
            className={classNames("nft-select-card2", {
                "nft-select-card2_selected": props.isSelected,
            })}
            onClick={props.onSelect}
        >
            <div className="nft-select-card2_selected-indicator">
                <div className="nft-select-card2_selected-indicator_icon">
                    <FiCheck/>
                </div>
            </div>

            <div className="nft-select-card2_img">
                {props.grouping.icon &&
                <img
					    src={props.grouping.icon.url}
					    alt={`${props.grouping.name} icon`}
                />
                }
            </div>

            <div className="nft-select-card2_content">
                <h5 className="nft-select-card2_content_title">
                    {props.grouping.name}
                </h5>
                <h5 className="nft-select-card2_content_description">
                    {props.merchantsSupporting > 0
                        ? `${props.merchantsSupporting} partner${props.merchantsSupporting === 1
                            ? ""
                            : "s"}`
                        : ""}
                </h5>
            </div>
        </div>
    );
}

export default NftSelectCard2;
