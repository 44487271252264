export function getRankSuffix(rank: number): string {
    if (rank === undefined) {
        return "";
    }

    const rankToString = rank.toString();
    const suffix = rankToString[rankToString.length - 1];

    if (rank > 10 && rankToString[rankToString.length - 2] === "1" && (
        suffix === "1" || suffix === "2" || suffix === "3"
    )) {
        return "th";
    }

    switch (suffix) {
        case "1":
            return "st";
        case "2":
            return "nd";
        case "3":
            return "rd";
        default:
            return "th";
    }
}