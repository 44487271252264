/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
    TimeStamps,
    TimeStampsFromJSON,
    TimeStampsFromJSONTyped,
    TimeStampsToJSON,
} from './';

/**
 * Represents a point of sale system that we support.
 * @export
 * @interface Pos
 */
export interface Pos {
    /**
     * 
     * @type {string}
     * @memberof Pos
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof Pos
     */
    createdAt: number;
    /**
     * 
     * @type {number}
     * @memberof Pos
     */
    updatedAt: number;
    /**
     * 
     * @type {string}
     * @memberof Pos
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Pos
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof Pos
     */
    itsaCheckmateLabel?: string;
}

export function PosFromJSON(json: any): Pos {
    return PosFromJSONTyped(json, false);
}

export function PosFromJSONTyped(json: any, ignoreDiscriminator: boolean): Pos {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'itsaCheckmateLabel': !exists(json, 'itsaCheckmateLabel') ? undefined : json['itsaCheckmateLabel'],
    };
}

export function PosToJSON(value?: Pos | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'name': value.name,
        'description': value.description,
        'itsaCheckmateLabel': value.itsaCheckmateLabel,
    };
}


