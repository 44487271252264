/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BusinessServiceAvailability,
    BusinessServiceAvailabilityFromJSON,
    BusinessServiceAvailabilityFromJSONTyped,
    BusinessServiceAvailabilityToJSON,
    MenuItemSearchEssential,
    MenuItemSearchEssentialFromJSON,
    MenuItemSearchEssentialFromJSONTyped,
    MenuItemSearchEssentialToJSON,
} from './';

/**
 * 
 * @export
 * @interface MenuCategorySearchEssential
 */
export interface MenuCategorySearchEssential {
    /**
     * 
     * @type {string}
     * @memberof MenuCategorySearchEssential
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof MenuCategorySearchEssential
     */
    name: string;
    /**
     * 
     * @type {Array<MenuCategorySearchEssential>}
     * @memberof MenuCategorySearchEssential
     */
    menuSubcategories?: Array<MenuCategorySearchEssential>;
    /**
     * 
     * @type {Array<MenuItemSearchEssential>}
     * @memberof MenuCategorySearchEssential
     */
    menuItems?: Array<MenuItemSearchEssential>;
    /**
     * 
     * @type {Array<BusinessServiceAvailability>}
     * @memberof MenuCategorySearchEssential
     */
    serviceAvailabilities?: Array<BusinessServiceAvailability>;
}

export function MenuCategorySearchEssentialFromJSON(json: any): MenuCategorySearchEssential {
    return MenuCategorySearchEssentialFromJSONTyped(json, false);
}

export function MenuCategorySearchEssentialFromJSONTyped(json: any, ignoreDiscriminator: boolean): MenuCategorySearchEssential {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'menuSubcategories': !exists(json, 'menuSubcategories') ? undefined : ((json['menuSubcategories'] as Array<any>).map(MenuCategorySearchEssentialFromJSON)),
        'menuItems': !exists(json, 'menuItems') ? undefined : ((json['menuItems'] as Array<any>).map(MenuItemSearchEssentialFromJSON)),
        'serviceAvailabilities': !exists(json, 'serviceAvailabilities') ? undefined : ((json['serviceAvailabilities'] as Array<any>).map(BusinessServiceAvailabilityFromJSON)),
    };
}

export function MenuCategorySearchEssentialToJSON(value?: MenuCategorySearchEssential | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'menuSubcategories': value.menuSubcategories === undefined ? undefined : ((value.menuSubcategories as Array<any>).map(MenuCategorySearchEssentialToJSON)),
        'menuItems': value.menuItems === undefined ? undefined : ((value.menuItems as Array<any>).map(MenuItemSearchEssentialToJSON)),
        'serviceAvailabilities': value.serviceAvailabilities === undefined ? undefined : ((value.serviceAvailabilities as Array<any>).map(BusinessServiceAvailabilityToJSON)),
    };
}


