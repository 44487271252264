/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetOrderDiscountsResponseAllOf,
    GetOrderDiscountsResponseAllOfFromJSON,
    GetOrderDiscountsResponseAllOfFromJSONTyped,
    GetOrderDiscountsResponseAllOfToJSON,
    OrderDiscount,
    OrderDiscountFromJSON,
    OrderDiscountFromJSONTyped,
    OrderDiscountToJSON,
    PaginatedResponse,
    PaginatedResponseFromJSON,
    PaginatedResponseFromJSONTyped,
    PaginatedResponseToJSON,
    PaginationInfo,
    PaginationInfoFromJSON,
    PaginationInfoFromJSONTyped,
    PaginationInfoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetOrderDiscountsResponse
 */
export interface GetOrderDiscountsResponse extends PaginatedResponse {
    /**
     * 
     * @type {Array<OrderDiscount>}
     * @memberof GetOrderDiscountsResponse
     */
    orderDiscounts: Array<OrderDiscount>;
}

export function GetOrderDiscountsResponseFromJSON(json: any): GetOrderDiscountsResponse {
    return GetOrderDiscountsResponseFromJSONTyped(json, false);
}

export function GetOrderDiscountsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetOrderDiscountsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...PaginatedResponseFromJSONTyped(json, ignoreDiscriminator),
        'orderDiscounts': ((json['orderDiscounts'] as Array<any>).map(OrderDiscountFromJSON)),
    };
}

export function GetOrderDiscountsResponseToJSON(value?: GetOrderDiscountsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...PaginatedResponseToJSON(value),
        'orderDiscounts': ((value.orderDiscounts as Array<any>).map(OrderDiscountToJSON)),
    };
}


