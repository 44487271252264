import {ButtonHTMLAttributes, ReactElement} from "react";
import {useSelector} from "react-redux";
import {NftPurchaseUpsert} from "@devour/client";
import {IStore} from "@/redux/defaultStore";
import {HiSparkles} from "react-icons/hi2";
import {formatNumberWithCommas} from "@/utils/formatNumberWithCommas";
import MintIndustryPassModalWallet from "@/components/modals/mintIndustryPassModal/MintIndustryPassModalWallet";
import {StripePaymentMethodObject} from "@/types/Stripe";
import {capitalize} from "lodash";
import {useGetStripePaymentMethodList} from "@/hooks/useGetStripePaymentMethodList";
import FrameButton from "@/components/buttons/FrameButton.tsx";
import {isDesktop, isTablet} from "react-device-detect";

interface Props {
    nftPurchase: NftPurchaseUpsert;
    resetStates?: () => void;
}

function formatDate(dateString: string): string {
    const dateSplit = dateString.split(" ");
    return `${dateSplit[1]} ${dateSplit[2]}, ${dateSplit[3]}`;
}

function MintIndustryPassModalSuccess(props: Props): ReactElement {
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const {data: stripePaymentMethodListData} = useGetStripePaymentMethodList(fullToken);
    const savedPaymentMethods = (stripePaymentMethodListData?.paymentMethods) as Array<StripePaymentMethodObject>;
    const selectedPayment = savedPaymentMethods?.find((payment) => payment.id === props.nftPurchase.paymentMethodId);
    const selectedCreditCardNumber = `${capitalize(selectedPayment?.card.brand)} ****${selectedPayment?.card.last4}`;

    function paymentEnumToString() {
        if (props.nftPurchase?.stripeTotal > 0 && props.nftPurchase?.isCoinbase) {
            return "Coinbase";
        } else if (props.nftPurchase?.stripeTotal > 0 && props.nftPurchase?.dpay === 0) {
            return selectedCreditCardNumber;
        } else if (props.nftPurchase?.dpay > 0 && props.nftPurchase?.stripeTotal === 0) {
            return `My ${import.meta.env.VITE_TOKEN_NAME}`;
        } else {
            return `${selectedCreditCardNumber} | My ${import.meta.env.VITE_TOKEN_NAME}`;
        }
    }

    if (!fullToken || !props.nftPurchase) {
        return null;
    }

    return (
        <div className="mint-industry-credit-card_body_success">
            <div className="mint-industry-credit-card_body_success_header">
                <HiSparkles className="mint-industry-credit-card_body_success_header_icon"/>
                <h3>Congratulations!</h3>
                <p>
                    You have successfully purchased the Industry Pass, and it is minted under your DevourGo wallet.
                </p>
            </div>

            <div className="mint-industry-credit-card_body_success_mint">
                <MintIndustryPassModalWallet
                    nftPurchase={props.nftPurchase}
                />
            </div>

            <div className="mint-industry-credit-card_body_success_summary">
                <h4>Summary</h4>
                <p className="mint-industry-credit-card_body_success_summary_subtitle">
                    Paid Price
                </p>
                {props.nftPurchase.stripeTotal > 0 &&
                <h4>
                        ${props.nftPurchase.stripeTotal.toFixed(2)} (USD)
                </h4>
                }
                {props.nftPurchase.dpay > 0 &&
                <h4>
                    {formatNumberWithCommas(props.nftPurchase.dpay)} {import.meta.env.VITE_TOKEN_NAME}
                </h4>
                }
                <hr/>
                <div className="mint-industry-credit-card_body_totals_row">
                    <p className="mint-industry-credit-card_body_totals_text">
                        Date
                    </p>
                    <p className="mint-industry-credit-card_body_totals_text">
                        {formatDate(new Date().toDateString())}
                    </p>
                </div>
                <div className="mint-industry-credit-card_body_totals_row">
                    <p className="mint-industry-credit-card_body_totals_text">
                        Payment Method
                    </p>
                    <p className="mint-industry-credit-card_body_totals_text">
                        {paymentEnumToString()}
                    </p>
                </div>

                <div className="mint-industry-credit-card_body_success_confirm-button">
                    <FrameButton
                        <ButtonHTMLAttributes<HTMLButtonElement>>
                        color="purple"
                        size={isDesktop || isTablet
                            ? "large"
                            : "normal"}
                        onClick={props.resetStates}
                    >
                        Confirm
                    </FrameButton>
                </div>

            </div>
        </div>
    );
}

export default MintIndustryPassModalSuccess;
