import {IAction} from "../IAction";
import {cloneDeep} from "lodash";
import InitialAffiliateStore, {IAffiliateStore} from "./InitialAffiliateStore";

export enum AffiliateAction {
    NEW_REFERRAL_CODE = "NEW_REFERRAL_CODE",
    REMOVE_REFERRAL_CODE = "REMOVE_REFERRAL_CODE",
}

export default function affiliateReducer(store: IAffiliateStore = InitialAffiliateStore, a: IAction<AffiliateAction, any>): IAffiliateStore {

    const n: IAffiliateStore = cloneDeep(store);
    const oneMonth = Date.now() + 30 * 24 * 60 * 60 * 1000;

    switch (a.type) {
        case AffiliateAction.NEW_REFERRAL_CODE:
            n.referralCode = a.payload;
            n.referralExpiry = oneMonth;
            break;
        case AffiliateAction.REMOVE_REFERRAL_CODE:
            n.referralCode = undefined;
            n.referralExpiry = undefined;
            break;
        default:
            break;
    }

    return n;
}
