import { GeoPoint } from "@devour/client";

const toRadians = (deg: number) => deg * (Math.PI / 180);
const toDegrees = (rad: number) => rad * (180 / Math.PI);

export default function getCoordinatesData(location1: GeoPoint, location2: GeoPoint) {
    if (!location2) {
        return {
            center: {
                type: "Point",
                coordinates: location1.coordinates,
            },
            zoomLevel: 13,
        };
    }
    const [lon1, lat1] = location1.coordinates;
    const [lon2, lat2] = location2.coordinates;
    // Extract coordinates
    const { centerLat, centerLon} = calculateMidpoint(location1, location2);

    // Calculate the distance between the two points using Haversine formula
    const earthRadiusInMiles = 3958.8; // Earth's radius in miles
    const dLat = toRadians(lat2 - lat1);
    const dLon = toRadians(lon2 - lon1);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distanceInMiles = earthRadiusInMiles * c;

    // Determine the zoom level based on the distance
    let zoomLevel;
    if (distanceInMiles < 1) {
        zoomLevel = 13;
    } else if (distanceInMiles < 5) {
        zoomLevel = 12;
    } else if (distanceInMiles < 10) {
        zoomLevel = 11;
    } else if (distanceInMiles < 20) {
        zoomLevel = 10;
    } else if (distanceInMiles < 50) {
        zoomLevel = 9;
    } else if (distanceInMiles < 100) {
        zoomLevel = 8;
    } else if (distanceInMiles < 200) {
        zoomLevel = 7;
    } else if (distanceInMiles < 500) {
        zoomLevel = 6;
    } else if (distanceInMiles < 1000) {
        zoomLevel = 5;
    } else if (distanceInMiles < 2000) {
        zoomLevel = 4;
    } else {
        zoomLevel = 3;
    }

    // Return the center point and the zoom level
    return {
        distanceInMiles,
        center: {
            type: "Point",
            coordinates: [centerLon, centerLat],
        },
        zoomLevel: zoomLevel,
    };
}


const calculateMidpoint = (location1: GeoPoint, location2: GeoPoint) => {
    const [lon1, lat1] = location1.coordinates;
    const [lon2, lat2] = location2.coordinates;

    const lat1Rad = toRadians(lat1);
    const lon1Rad = toRadians(lon1);
    const lat2Rad = toRadians(lat2);
    const lon2Rad = toRadians(lon2);

    // Convert lat/lon to Cartesian coordinates
    const x1 = Math.cos(lat1Rad) * Math.cos(lon1Rad);
    const y1 = Math.cos(lat1Rad) * Math.sin(lon1Rad);
    const z1 = Math.sin(lat1Rad);

    const x2 = Math.cos(lat2Rad) * Math.cos(lon2Rad);
    const y2 = Math.cos(lat2Rad) * Math.sin(lon2Rad);
    const z2 = Math.sin(lat2Rad);

    // Compute average Cartesian coordinates
    const x = (x1 + x2) / 2;
    const y = (y1 + y2) / 2;
    const z = (z1 + z2) / 2;

    // Convert average Cartesian coordinates back to latitude/longitude
    const centerLon = Math.atan2(y, x);
    const hyp = Math.sqrt(x * x + y * y);
    const centerLat = Math.atan2(z, hyp);

    const adjustedLat = toDegrees(centerLat) + 0.003;

    return {
        centerLat: adjustedLat,
        centerLon: toDegrees(centerLon),
    };
};
