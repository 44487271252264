import {
    ButtonHTMLAttributes,
    ChangeEventHandler,
    FormEvent,
    ReactElement, useContext,
    useEffect,
    useState,
} from "react";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {
    Address,
    AddressBook,
    AddressBooksApi,
    CreateAddressBookBody,
    DeliveryHandoffInstructions,
    UsersApi,
} from "@devour/client";
import FrameOneAutoPanel from "./autoPanelComponents/FrameOneAutoPanel";
import FrameAutoPanelHeader from "./autoPanelComponents/FrameAutoPanelHeader";
import FrameAutoPanelBody from "./autoPanelComponents/FrameAutoPanelBody";
import FrameButton from "../buttons/FrameButton";
import FrameOneAddressInputGroup from "../inputs/FrameOneAddressInputGroup";
import {
    addError,
    decrementLoading,
    incrementLoading,
    updateCurrentUser,
} from "@/redux/meta/metaActions";
import getConfig from "../../utils/getConfig";
import {omit} from "lodash";
import classNames from "classnames";
import FrameOneCheckbox from "@/components/inputs/FrameOneCheckbox";
import {isMobile, isTablet} from "react-device-detect";
import FrameAutoPanelFooter from "@/components/modals/autoPanelComponents/FrameAutoPanelFooter";
import {IoClose} from "react-icons/io5";
import {RestaurantContext} from "@/pages/restaurants/context/RestaurantContext";

const defaultAddressForm: CreateAddressBookBody = {
    line1: "",
    line2: "",
    locality: "",
    administrativeArea: "",
    postalCode: "",
    country: "US",
    deliveryInstructions: "",
    isDefault: false,
    handoffInstructions: undefined,
};

interface Props {
    isOpen: boolean;
    addressBook: AddressBook;
    onSave: (addressBook: AddressBook) => void;
    onClose: () => void;
}

function ManageAddressBookUpdateAddressModal(props: Props): ReactElement {
    const dispatch = useDispatch();
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const isMobileDevice = isMobile && !isTablet;
    const [
        addressForm,
        setAddressForm,
    ] = useState<CreateAddressBookBody>(defaultAddressForm);
    const {embeddedMenu} = useContext(RestaurantContext);

    useEffect(() => {
        if (props.isOpen) {
            setAddressForm(omit(props.addressBook, "user") as Address);
        }
    }, [props.isOpen]);

    /**
     * Handle all text input onChange events.
     *
     * @param key
     */
    function inputOnChange(key: keyof CreateAddressBookBody): ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> {
        return (e) => {
            setAddressForm((_addressForm) => {
                return {
                    ..._addressForm,
                    [key]: e.target.value,
                };
            });
        };
    }

    /**
     * Handle delivery handoff onChange events.
     *
     * @param handoffInstructions
     */
    function deliveryHandoffOnChange(handoffInstructions: DeliveryHandoffInstructions): void {
        if (addressForm.handoffInstructions === handoffInstructions) {
            // "New" value is the same as previous value. Reset the value back to null or "OTHER"
            setAddressForm((_addressForm) => {
                return {
                    ..._addressForm,
                    handoffInstructions: DeliveryHandoffInstructions.OTHER,
                };
            });
        } else {
            setAddressForm((_addressForm) => {
                return {
                    ..._addressForm,
                    handoffInstructions: handoffInstructions,
                };
            });
        }
    }

    async function submitUpdatedAddress(): Promise<void> {
        if (!fullToken) {
            return;
        }
        dispatch(incrementLoading());

        try {
            let newAddressBook: AddressBook;
            if (props.addressBook?.id) {
                newAddressBook = await new AddressBooksApi(getConfig(fullToken)).updateAddressBook({
                    id: props.addressBook?.id,
                    createAddressBookBody: addressForm,
                });
            } else {
                newAddressBook = await new AddressBooksApi(getConfig(fullToken)).createAddressBook({
                    createAddressBookBody: addressForm,
                });
            }

            const userRes = await new UsersApi(getConfig(fullToken)).getProfile();
            dispatch(updateCurrentUser(userRes));

            props.onSave(newAddressBook);
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            dispatch(decrementLoading());
        }
    }

    function onDefaultToggle() {
        setAddressForm((_addressForm) => {
            return {
                ..._addressForm,
                isDefault: !addressForm.isDefault,
            };
        });
    }

    async function onSubmit(e: FormEvent<HTMLFormElement>) {
        e?.preventDefault();
        await submitUpdatedAddress();
    }

    return (
        <form onSubmit={onSubmit}>
            <FrameOneAutoPanel
                isOpen={props.isOpen}
                toggle={props.onClose}
                size="sm"
                maxHeight={true}
                fullScreenHeight={!embeddedMenu}
                contentClassName="manage-address-book-add-modal"
                modalOnTablet={true}
            >
                {!isMobileDevice &&
                    <FrameAutoPanelHeader
                        title={props.addressBook?.id
                            ? "Saved Address"
                            : "Add New Address"}
                        toggle={props.onClose}
                        modalOnTablet={true}
                    />
                }

                <FrameAutoPanelBody
                    className="manage-address-book-add-modal_body"
                    modalOnTablet={true}
                >
                    {isMobileDevice &&
                        <div className="manage-address-book-add-modal_body_mobile-header">
                            <h4>
                                {props.addressBook?.id
                                    ? "Edit Address"
                                    : "Add Address"}
                            </h4>
                            <button
                                className="reset-button"
                                type="button"
                                onClick={props.onClose}
                            >
                                <IoClose />
                            </button>
                        </div>
                    }

                    <FrameOneAddressInputGroup
                        value={omit(addressForm, "deliveryInstructions") as Address}
                        onChange={setAddressForm}
                        defaultMode="manual"
                        showModeChangeButton={false}
                    />

                    {fullToken &&
                        <>
                            <div className="manage-address-book-add-modal_body_instructions">
                                <label>Delivery Instructions</label>
                                <div
                                    className="manage-address-book-add-modal_body_instructions_options"
                                >
                                    <button
                                        className={classNames("manage-address-book-add-modal_body_instructions_options_item", {
                                            "is-active": addressForm.handoffInstructions === DeliveryHandoffInstructions.HANDITTOME,
                                        })}
                                        type="button"
                                        onClick={() => deliveryHandoffOnChange(DeliveryHandoffInstructions.HANDITTOME)}
                                    >
                                        Hand it to me
                                    </button>
                                    <button
                                        className={classNames("manage-address-book-add-modal_body_instructions_options_item", {
                                            "is-active": addressForm.handoffInstructions === DeliveryHandoffInstructions.LEAVEITATMYDOOR,
                                        })}
                                        type="button"
                                        onClick={() => deliveryHandoffOnChange(DeliveryHandoffInstructions.LEAVEITATMYDOOR)}
                                    >
                                        Leave it at my door
                                    </button>
                                </div>

                                <textarea
                                    placeholder="Delivery instructions (Optional)"
                                    value={addressForm.deliveryInstructions}
                                    onChange={inputOnChange("deliveryInstructions")}
                                    maxLength={130}
                                />
                            </div>

                            <div className="manage-address-book-add-modal_body_default">
                                <FrameOneCheckbox
                                    onToggle={onDefaultToggle}
                                    checked={addressForm.isDefault}
                                >
                                    Set as default address
                                </FrameOneCheckbox>
                            </div>
                        </>
                    }

                    {!isMobileDevice &&
                        <div className="manage-address-book-add-modal_body_actions">
                            <FrameButton
                                <ButtonHTMLAttributes<HTMLButtonElement>>
                                color="purple"
                                size="normal"
                                forwardProps={{
                                    type: "submit",
                                }}
                            >
                                {props.addressBook?.id
                                    ? "Save"
                                    : "Add"}
                            </FrameButton>
                        </div>
                    }
                </FrameAutoPanelBody>
                {isMobileDevice &&
                    <FrameAutoPanelFooter>
                        <FrameButton
                            <ButtonHTMLAttributes<HTMLButtonElement>>
                            color="purple"
                            size="normal"
                            forwardProps={{
                                type: "submit",
                            }}
                        >
                            {props.addressBook?.id
                                ? "Save"
                                : "Add"}
                        </FrameButton>
                    </FrameAutoPanelFooter>
                }
            </FrameOneAutoPanel>
        </form>
    );
}

export default ManageAddressBookUpdateAddressModal;
