/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Restaurant,
    RestaurantFromJSON,
    RestaurantFromJSONTyped,
    RestaurantToJSON,
} from './';

/**
 * 
 * @export
 * @interface FavoriteRestaurantsResponse
 */
export interface FavoriteRestaurantsResponse {
    /**
     * 
     * @type {Array<Restaurant>}
     * @memberof FavoriteRestaurantsResponse
     */
    restaurants: Array<Restaurant>;
}

export function FavoriteRestaurantsResponseFromJSON(json: any): FavoriteRestaurantsResponse {
    return FavoriteRestaurantsResponseFromJSONTyped(json, false);
}

export function FavoriteRestaurantsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FavoriteRestaurantsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'restaurants': ((json['restaurants'] as Array<any>).map(RestaurantFromJSON)),
    };
}

export function FavoriteRestaurantsResponseToJSON(value?: FavoriteRestaurantsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'restaurants': ((value.restaurants as Array<any>).map(RestaurantToJSON)),
    };
}


