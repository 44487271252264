/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MenuOrderItemBase,
    MenuOrderItemBaseFromJSON,
    MenuOrderItemBaseFromJSONTyped,
    MenuOrderItemBaseToJSON,
} from './';

/**
 * Represents a single customization item in the menu order.
 * @export
 * @interface MenuOrderSubItem
 */
export interface MenuOrderSubItem extends MenuOrderItemBase {
    /**
     * 
     * @type {string}
     * @memberof MenuOrderSubItem
     */
    menuCustomizationItemId?: string;
    /**
     * 
     * @type {Array<MenuOrderSubItem>}
     * @memberof MenuOrderSubItem
     */
    customizations?: Array<MenuOrderSubItem>;
}

export function MenuOrderSubItemFromJSON(json: any): MenuOrderSubItem {
    return MenuOrderSubItemFromJSONTyped(json, false);
}

export function MenuOrderSubItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): MenuOrderSubItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...MenuOrderItemBaseFromJSONTyped(json, ignoreDiscriminator),
        'menuCustomizationItemId': !exists(json, 'menuCustomizationItemId') ? undefined : json['menuCustomizationItemId'],
        'customizations': !exists(json, 'customizations') ? undefined : ((json['customizations'] as Array<any>).map(MenuOrderSubItemFromJSON)),
    };
}

export function MenuOrderSubItemToJSON(value?: MenuOrderSubItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...MenuOrderItemBaseToJSON(value),
        'menuCustomizationItemId': value.menuCustomizationItemId,
        'customizations': value.customizations === undefined ? undefined : ((value.customizations as Array<any>).map(MenuOrderSubItemToJSON)),
    };
}


