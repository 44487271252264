import {ReactElement} from "react";
import {BsCreditCardFill} from "react-icons/bs";
import {FaChevronLeft} from "react-icons/fa6";

interface Props {
    isDefaultCard: boolean; // If a default card is selected render a different header
    onClose: () => void;
}

function CheckoutPaymentCreditModalHeader(props: Props): ReactElement {

    if (props.isDefaultCard) {
        return (
            <>
                <div className="checkout-payments-credit_header">

                    <div className="checkout-payments-credit_header_left">
                        <FaChevronLeft
                            onClick={props.onClose}
                            className="checkout-payments-credit_header_exit"/>
                        <p>Pay with Credit Card</p>
                    </div>

                </div>

                <hr/>
            </>
        );
    } else {
        return (
            <>
                <div className="checkout-payments-credit_header">

                    <div className="checkout-payments-credit_header_left">
                        <BsCreditCardFill/>
                        <p>Add Credit Card</p>
                    </div>

                    <img
                        src={import.meta.env.VITE_CDN_URL + "/images/cancel-gray.svg"}
                        onClick={props.onClose}
                        alt="exit"
                        className="checkout-payments-credit_header_exit"
                    />

                </div>
                <hr/>
            </>
        );
    }
}

export default CheckoutPaymentCreditModalHeader;
