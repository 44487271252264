import {ReactElement, ReactNode} from "react";
import classNames from "classnames";

export interface FrameOneSwitchInputOption<DataType> {
    data?: DataType;
    render: ReactNode;
    value: string | number;
    disabled?: boolean;
}

interface Props<DataType> {
    name: string;
    value: string | number;
    options: Array<FrameOneSwitchInputOption<DataType>>;
    onToggle: (value: DataType) => void;
    disabled?: boolean;
    className?: string;
    isLoading?: boolean;
}

function FrameOneSwitchInput<DataType = string | number>(props: Props<DataType>): ReactElement {

    function renderOption(option: FrameOneSwitchInputOption<DataType>, index: number) {
        return (
            <label
                key={`${props.name}-option-${index}`}
                className={classNames("frame-one-switch-input_option", {
                    "is-active": props.value === option.value,
                    "is-disabled": props.disabled || option.disabled || props.isLoading,
                })}
            >
                <input
                    type="radio"
                    name={props.name}
                    onChange={() => props.onToggle(option.data || option.value as DataType)}
                    checked={props.value === option.value}
                    disabled={props.disabled || option.disabled}
                />
                {option.render}
            </label>
        );
    }

    return (
        <div
            // Some SCSS mapping stuff to make this work. Check _frame-one-switch-input.scss to debug.
            className={classNames(
                "frame-one-switch-input", props.className,
                `input-options--${props.options.length}-${props.options.findIndex(option => option.value === props.value)}`,
                {
                    "is-disabled": props.disabled,
                },
            )}
        >
            {props.options.map(renderOption)}
            <div className="frame-one-switch-input_slider">
                <div className="frame-one-switch-input_slider_inner"/>
            </div>
        </div>
    );
}

export default FrameOneSwitchInput;
