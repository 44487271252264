/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AddressBook,
    AddressBookFromJSON,
    AddressBookFromJSONTyped,
    AddressBookToJSON,
    Asset,
    AssetFromJSON,
    AssetFromJSONTyped,
    AssetToJSON,
    PhoneNumber,
    PhoneNumberFromJSON,
    PhoneNumberFromJSONTyped,
    PhoneNumberToJSON,
    StandardUser,
    StandardUserFromJSON,
    StandardUserFromJSONTyped,
    StandardUserToJSON,
    UserType,
    UserTypeFromJSON,
    UserTypeFromJSONTyped,
    UserTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface Merchant
 */
export interface Merchant extends StandardUser {
    /**
     * 
     * @type {Array<string>}
     * @memberof Merchant
     */
    stripeAccountIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Merchant
     */
    latestMerchantAgreementId?: string;
}

export function MerchantFromJSON(json: any): Merchant {
    return MerchantFromJSONTyped(json, false);
}

export function MerchantFromJSONTyped(json: any, ignoreDiscriminator: boolean): Merchant {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...StandardUserFromJSONTyped(json, ignoreDiscriminator),
        'stripeAccountIds': !exists(json, 'stripeAccountIds') ? undefined : json['stripeAccountIds'],
        'latestMerchantAgreementId': !exists(json, 'latestMerchantAgreementId') ? undefined : json['latestMerchantAgreementId'],
    };
}

export function MerchantToJSON(value?: Merchant | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...StandardUserToJSON(value),
        'stripeAccountIds': value.stripeAccountIds,
        'latestMerchantAgreementId': value.latestMerchantAgreementId,
    };
}


