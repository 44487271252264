/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserRankDataAllOf,
    UserRankDataAllOfFromJSON,
    UserRankDataAllOfFromJSONTyped,
    UserRankDataAllOfToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserRankData
 */
export interface UserRankData {
    /**
     * 
     * @type {string}
     * @memberof UserRankData
     */
    nickname: string;
    /**
     * 
     * @type {string}
     * @memberof UserRankData
     */
    pfpUrl?: string;
    /**
     * 
     * @type {number}
     * @memberof UserRankData
     */
    level: number;
    /**
     * 
     * @type {number}
     * @memberof UserRankData
     */
    rank: number;
    /**
     * 
     * @type {number}
     * @memberof UserRankData
     */
    experiencePoints: number;
}

export function UserRankDataFromJSON(json: any): UserRankData {
    return UserRankDataFromJSONTyped(json, false);
}

export function UserRankDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserRankData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nickname': json['nickname'],
        'pfpUrl': !exists(json, 'pfpUrl') ? undefined : json['pfpUrl'],
        'level': json['level'],
        'rank': json['rank'],
        'experiencePoints': json['experiencePoints'],
    };
}

export function UserRankDataToJSON(value?: UserRankData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nickname': value.nickname,
        'pfpUrl': value.pfpUrl,
        'level': value.level,
        'rank': value.rank,
        'experiencePoints': value.experiencePoints,
    };
}


