import FrameOneModal from "@/components/modals/modalComponents/FrameOneModal";
import FrameModalBody from "@/components/modals/modalComponents/FrameModalBody";
import {AiOutlineExclamationCircle} from "react-icons/ai";
import FrameButton from "@/components/buttons/FrameButton";
import {ButtonHTMLAttributes} from "react";
import FrameModalHeader from "@/components/modals/modalComponents/FrameModalHeader";


interface Props {
    isOpen: boolean;
    errorMessage: string;
    toggle: () => void;
}
function CheckoutPaymentErrorModal(props: Props) {
    return (
        <FrameOneModal
            isOpen={props.isOpen}
            toggle={props.toggle}
            contentClassName="checkout-split-pay-fail-modal"
        >
            <FrameModalHeader
                toggle={props.toggle}
                showLeftChevron={false}
            />
            <FrameModalBody className="checkout-split-pay-fail-modal_body">
                <AiOutlineExclamationCircle />
                <h3>Order Error</h3>
                <div className="checkout-split-pay-fail-modal_body_message">
					There was a problem with your order:{" "}
                    <strong>{props.errorMessage}</strong>
                </div>
                <FrameButton
                    <ButtonHTMLAttributes<HTMLButtonElement>>
                    color="danger"
                    size="narrow"
                    className="checkout-split-pay-fail-modal_body_btn"
                    onClick={props.toggle}
                >
                    Try Again
                </FrameButton>
            </FrameModalBody>
        </FrameOneModal>
    );
}

export default CheckoutPaymentErrorModal;
