import moment from "moment-timezone";
import {DateInfo} from "../../components/checkout/checkoutDetails/CheckoutDetailsScheduleTimes";
import {BusinessServiceAvailability} from "@devour/client";

// Finds the store hours of the selected date day
export function findStoreHours(date: DateInfo, timezone: string, storeHours: Array<BusinessServiceAvailability>) {

    let dayOfWeek = date.day.toUpperCase();

    if (dayOfWeek === "TODAY") {
        dayOfWeek = moment().tz(timezone)
            .format("dddd")
            .toUpperCase();
    } else if (dayOfWeek === "TOMORROW") {
        dayOfWeek = moment().tz(timezone)
            .add(1, "days")
            .format("dddd")
            .toUpperCase();
    }

    return storeHours.find(day => day.dayOfWeek === dayOfWeek);
}