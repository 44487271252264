/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetBrandMapRestaurantsResponseAllOf,
    GetBrandMapRestaurantsResponseAllOfFromJSON,
    GetBrandMapRestaurantsResponseAllOfFromJSONTyped,
    GetBrandMapRestaurantsResponseAllOfToJSON,
    PaginatedResponse,
    PaginatedResponseFromJSON,
    PaginatedResponseFromJSONTyped,
    PaginatedResponseToJSON,
    PaginationInfo,
    PaginationInfoFromJSON,
    PaginationInfoFromJSONTyped,
    PaginationInfoToJSON,
    Restaurant,
    RestaurantFromJSON,
    RestaurantFromJSONTyped,
    RestaurantToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetBrandMapRestaurantsResponse
 */
export interface GetBrandMapRestaurantsResponse extends PaginatedResponse {
    /**
     * 
     * @type {Array<Restaurant>}
     * @memberof GetBrandMapRestaurantsResponse
     */
    paginatedRestaurants: Array<Restaurant>;
    /**
     * 
     * @type {Array<Restaurant>}
     * @memberof GetBrandMapRestaurantsResponse
     */
    allRestaurants: Array<Restaurant>;
}

export function GetBrandMapRestaurantsResponseFromJSON(json: any): GetBrandMapRestaurantsResponse {
    return GetBrandMapRestaurantsResponseFromJSONTyped(json, false);
}

export function GetBrandMapRestaurantsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetBrandMapRestaurantsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...PaginatedResponseFromJSONTyped(json, ignoreDiscriminator),
        'paginatedRestaurants': ((json['paginatedRestaurants'] as Array<any>).map(RestaurantFromJSON)),
        'allRestaurants': ((json['allRestaurants'] as Array<any>).map(RestaurantFromJSON)),
    };
}

export function GetBrandMapRestaurantsResponseToJSON(value?: GetBrandMapRestaurantsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...PaginatedResponseToJSON(value),
        'paginatedRestaurants': ((value.paginatedRestaurants as Array<any>).map(RestaurantToJSON)),
        'allRestaurants': ((value.allRestaurants as Array<any>).map(RestaurantToJSON)),
    };
}


