/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UpdatedOwnerships,
    UpdatedOwnershipsFromJSON,
    UpdatedOwnershipsFromJSONTyped,
    UpdatedOwnershipsToJSON,
} from './';

/**
 * 
 * @export
 * @interface ForceUpdateNftOwnershipResponse
 */
export interface ForceUpdateNftOwnershipResponse {
    /**
     * 
     * @type {Array<UpdatedOwnerships>}
     * @memberof ForceUpdateNftOwnershipResponse
     */
    updatedOwnerships: Array<UpdatedOwnerships>;
}

export function ForceUpdateNftOwnershipResponseFromJSON(json: any): ForceUpdateNftOwnershipResponse {
    return ForceUpdateNftOwnershipResponseFromJSONTyped(json, false);
}

export function ForceUpdateNftOwnershipResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ForceUpdateNftOwnershipResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'updatedOwnerships': ((json['updatedOwnerships'] as Array<any>).map(UpdatedOwnershipsFromJSON)),
    };
}

export function ForceUpdateNftOwnershipResponseToJSON(value?: ForceUpdateNftOwnershipResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'updatedOwnerships': ((value.updatedOwnerships as Array<any>).map(UpdatedOwnershipsToJSON)),
    };
}


