/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrderDiscount,
    OrderDiscountFromJSON,
    OrderDiscountFromJSONTyped,
    OrderDiscountToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetGlobalOrderDiscountsResponse
 */
export interface GetGlobalOrderDiscountsResponse {
    /**
     * 
     * @type {Array<OrderDiscount>}
     * @memberof GetGlobalOrderDiscountsResponse
     */
    orderDiscounts: Array<OrderDiscount>;
}

export function GetGlobalOrderDiscountsResponseFromJSON(json: any): GetGlobalOrderDiscountsResponse {
    return GetGlobalOrderDiscountsResponseFromJSONTyped(json, false);
}

export function GetGlobalOrderDiscountsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetGlobalOrderDiscountsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'orderDiscounts': ((json['orderDiscounts'] as Array<any>).map(OrderDiscountFromJSON)),
    };
}

export function GetGlobalOrderDiscountsResponseToJSON(value?: GetGlobalOrderDiscountsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'orderDiscounts': ((value.orderDiscounts as Array<any>).map(OrderDiscountToJSON)),
    };
}


