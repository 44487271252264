import {InputHTMLAttributes, ReactElement} from "react";
import {omit} from "lodash";
import classNames from "classnames";
import {IconType} from "react-icons";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    icon?: IconType;
    iconOnClick?: () => void;
}

function IconInput(props: Props): ReactElement {

    const Icon = props.icon;

    return (
        <div
            className={classNames("frame-one-icon-input", {
                "frame-one-icon-input_disabled": props.disabled,
            })}
        >
            <input
                {...omit(props, "icon")}
            />
            {
                props.icon &&
                <div className="frame-one-icon-input_icon-container">
				    <Icon
				        onClick={props.iconOnClick}
				        className={classNames("frame-one-icon-input_icon-container_icon", {
				            "frame-one-icon-input_icon-container_icon_hover-enabled": props.iconOnClick !== undefined,
				        })}
				    />
                </div>
            }
        </div>
    );
}

export default IconInput;

