import moment from "moment-timezone";

// Combines the selected date and the selected slot time to a unix timestamp that we can save to the database.
export function combineTimesToUnix(selectedDateUnix: number, slotScheduleTimeUnix: number, timeZone: string): number {
    const dateMoment = moment.unix(selectedDateUnix).tz(timeZone);
    const timeMoment = moment.unix(slotScheduleTimeUnix).tz(timeZone);

    // Extract year, month, and day from the selected date
    const year = dateMoment.year();
    const month = dateMoment.month();
    const day = dateMoment.date();

    // Extract hours and minutes from the selected time slot
    const hour = timeMoment.hours();
    const minute = timeMoment.minutes();

    // Combine date and time into a new moment object
    const combinedDateTime = moment.tz({
        year,
        month,
        day,
        hour,
        minute,
    }, timeZone);

    // Convert the combined moment object to Unix timestamp and update the menu order
    return combinedDateTime.unix();

}