import {ButtonHTMLAttributes, ReactElement, useState} from "react";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameOneModal from "./modalComponents/FrameOneModal";
import {TiStarFullOutline} from "react-icons/ti";
import {GoFrensApi, NftGrouping, Token} from "@devour/client";
import FrameButton from "@/components/buttons/FrameButton";
import {addError, decrementLoading, incrementLoading} from "@/redux/meta/metaActions";
import {useDispatch, useSelector} from "react-redux";
import getConfig from "@/utils/getConfig";
import Toast from "@/components/Toast";
import {IStore} from "@/redux/defaultStore";
import {useGetUserProfile} from "@/hooks/useGetUserProfile";
import {useGetNftOwnerships} from "@/hooks/useGetNftOwnerships";
import {useGetNftOwnershipsForUser} from "@/hooks/useGetNftOwnershipsForUser";
import useThemePreference from "@/hooks/useThemePreference";

interface Props {
    isOpen: boolean;
    toggle: () => void;
    grouping: NftGrouping;
}

function GoFrensRepCommunityModal(props: Props): ReactElement {
    const dispatch = useDispatch();
    const [
        showToast,
        setShowToast,
    ] = useState<boolean>(false);
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const {refetch: refetchUserProfileData} = useGetUserProfile(fullToken);
    const {refetch: refetchNftOwnerships} = useGetNftOwnerships(fullToken as Token);
    const {refetch: refetchNftOwnershipsForUser} = useGetNftOwnershipsForUser(fullToken as Token);
    const { isOnDarkMode } = useThemePreference();

    async function handleUserJoiningCommunity(): Promise<void> {
        dispatch(incrementLoading());

        try {
            await new GoFrensApi(getConfig(fullToken)).addUserToCommunity({
                id: props.grouping.id,
            });
            setShowToast(true);
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            dispatch(decrementLoading());
            props.toggle();
        }
    }

    async function handleToastDismissal() {
        setShowToast(false);
        await Promise.all([
            refetchUserProfileData(),
            refetchNftOwnershipsForUser(),
            refetchNftOwnerships(),
        ]);
    }

    return (
        <>
            <Toast
                message={`Awesome! you're now repping ${props.grouping.name}!`}
                isOpen={showToast}
                onDismiss={handleToastDismissal}
            />
            <FrameOneModal
                isOpen={props.isOpen}
                toggle={props.toggle}
                contentClassName="gofrens-rep-community-modal"
            >
                <FrameModalBody className="gofrens-rep-community-modal_body">
                    {isOnDarkMode
                        ? <img
                            src={`${import.meta.env.VITE_CDN_URL}/images/DM-gofrens-rep-community-star.svg`}
                            alt="star-icon"/>
                        : <TiStarFullOutline className="gofrens-rep-community-modal_body_star"/>
                    }

                    <p className="gofrens-rep-community-modal_body_title">
                        Rep This Community?
                    </p>
                    <p className="gofrens-rep-community-modal_body_message">
                        {`By joining ${props.grouping.name}, you cannot rep any other community. If you want to leave, 
                    you may only join another community during the first 7 days of the following month.`}
                    </p>
                    <p className="gofrens-rep-community-modal_body_disclaimer">
                        You must always hold at least 1 NFT associated with this community to retain your community
                        membership.
                    </p>
                    <FrameButton
                        <ButtonHTMLAttributes<HTMLButtonElement>>
                        color="purple-blue-gradient"
                        size="normal"
                        className="gofrens-rep-community-modal_body_button"
                        onClick={async () => await handleUserJoiningCommunity()}
                    >
                        Rep Now!
                    </FrameButton>
                    <button
                        className="gofrens-rep-community-modal_body_cancel"
                        onClick={props.toggle}
                    >
                        Cancel
                    </button>
                </FrameModalBody>
            </FrameOneModal>
        </>
    );
}

export default GoFrensRepCommunityModal;
