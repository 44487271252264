import {RestaurantSearchResult} from "@devour/client";

export function getExclusiveItemNfts(restaurant: RestaurantSearchResult) {
    const exclusiveItemNfts: Set<string> = new Set<string>();

    const allGroupings = restaurant.secretMenuItems?.map((item) => item.nftGroupings).flat();
    allGroupings.forEach((id) => exclusiveItemNfts.add(id));

    const discountsNfts: Array<string> = restaurant.promos?.map(d => d.nftGroupings).flat();
    discountsNfts.forEach((id) => exclusiveItemNfts.add(id));

    // we should not have duplicate NFT ids
    return Array.from(exclusiveItemNfts.values());
}
