import {
    GetUserResponse,
    RestaurantSearchResult,
    Token,
    UsersApi,
} from "@devour/client";
import getConfig from "@/utils/getConfig";
import {useQueryClient, useMutation} from "@tanstack/react-query";
import _ from "lodash";
import {USE_GET_USER_PROFILE_KEY} from "@/hooks/useGetUserProfile";
import {USE_GET_FAVOURITE_RESTAURANTS_KEY} from "@/hooks/useGetFavouriteRestaurants";

interface FavouriteBusinessParams {
    fullToken: Token;
    business: RestaurantSearchResult
}

async function favouriteBusiness({fullToken, business}: FavouriteBusinessParams): Promise<void> {
    return await new UsersApi(getConfig(fullToken)).favoriteBusiness({
        businessId: business._id,
    });
}

function updateFavouriteBusinessQuery(fullToken: Token, business: RestaurantSearchResult) {
    const queryClient = useQueryClient();

    const getUserProfileKey = [
        USE_GET_USER_PROFILE_KEY,
        fullToken?.id,
    ];
    const getFavouriteRestaurantsKey = [
        USE_GET_FAVOURITE_RESTAURANTS_KEY,
        fullToken?.id,
    ];

    return {
        mutationKey: [
            "favorite-business",
            fullToken,
        ],
        mutationFn: () => favouriteBusiness({fullToken,
            business}),
        // When mutate is called:
        onMutate: async (newFavouriteBusiness) => {
            // Cancel any outgoing refetches
            await queryClient.cancelQueries(getUserProfileKey);

            // Snapshot the previous value
            const previousUserProfile = queryClient.getQueryData<GetUserResponse>(getUserProfileKey);

            // Optimistically update to the new value
            const {isFavourite} = newFavouriteBusiness;
            const userProfileData = _.cloneDeep(previousUserProfile);
            // if Favouriting, add the business id on user's favorites. If unfavouriting, remove the id
            userProfileData.user.favorites = isFavourite
                ? [
                    ...userProfileData?.user?.favorites,
                    business._id,
                ]
                : userProfileData?.user?.favorites.filter(favouriteId => favouriteId !== business._id);
            queryClient.setQueryData(getUserProfileKey, userProfileData);

            // Return a context with the previous and new user profile data
            return {previousUserProfile};
        },
        onError: (_err, _, context) => {
            // If the mutation fails, rollback the data to the previous state
            queryClient.setQueryData(getUserProfileKey, context?.previousUserProfile);
        },
        onSettled: () => {
            // Always refetch after error or success to get the latest data from the server
            queryClient.invalidateQueries(getUserProfileKey);
            // We also want the latest data from /restaurants/favorites api since users might then navigate to the favourite page
            queryClient.invalidateQueries(getFavouriteRestaurantsKey);
        },
    } as const;
}


export function useUpdateFavoriteBusiness(fullToken: Token, business: RestaurantSearchResult) {
    return useMutation(updateFavouriteBusinessQuery(fullToken, business));
}