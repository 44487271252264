/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdatedOwnerships
 */
export interface UpdatedOwnerships {
    /**
     * 
     * @type {boolean}
     * @memberof UpdatedOwnerships
     */
    isRecorded: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdatedOwnerships
     */
    trackerId: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatedOwnerships
     */
    trackerName: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdatedOwnerships
     */
    createdOwnershipIds: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdatedOwnerships
     */
    deletedOwnershipIds: Array<string>;
}

export function UpdatedOwnershipsFromJSON(json: any): UpdatedOwnerships {
    return UpdatedOwnershipsFromJSONTyped(json, false);
}

export function UpdatedOwnershipsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdatedOwnerships {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isRecorded': json['isRecorded'],
        'trackerId': json['trackerId'],
        'trackerName': json['trackerName'],
        'createdOwnershipIds': json['createdOwnershipIds'],
        'deletedOwnershipIds': json['deletedOwnershipIds'],
    };
}

export function UpdatedOwnershipsToJSON(value?: UpdatedOwnerships | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isRecorded': value.isRecorded,
        'trackerId': value.trackerId,
        'trackerName': value.trackerName,
        'createdOwnershipIds': value.createdOwnershipIds,
        'deletedOwnershipIds': value.deletedOwnershipIds,
    };
}


