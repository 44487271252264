import {ButtonHTMLAttributes, ReactElement, useState} from "react";
import FrameButton from "../buttons/FrameButton";
import {magic, magicPolygon} from "@/utils/magic";
import {logout} from "@/redux/auth/authActions";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import Toast from "@/components/Toast";
import { FaEthereum } from "react-icons/fa";
import FrameOneModal from "@/components/modals/modalComponents/FrameOneModal";
import FrameModalHeader from "@/components/modals/modalComponents/FrameModalHeader";
import FrameModalBody from "@/components/modals/modalComponents/FrameModalBody";
import {IStore} from "@/redux/defaultStore";
import {toggleLoginOpen, toggleMagicWalletsModal} from "@/redux/meta/metaActions";
import useThemePreference from "@/hooks/useThemePreference";

function SelectMagicWalletModal(): ReactElement {
    const {getThemedImageUrl} = useThemePreference();
    const [
        showMagicErrorToast,
        setShowMagicErrorToast,
    ] = useState<boolean>(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const isModalShown = useSelector((store: IStore) => store.metaStore.isMagicWalletsModalActive);

    function handleMagicErrorToastDismissal() {
        setShowMagicErrorToast(false);
        dispatch(logout());
        dispatch(toggleLoginOpen(true));
    }

    async function useMagicWallet(): Promise<void> {
        const isMagicActive = await magic.user.isLoggedIn();
        if (isMagicActive === false) {
            setShowMagicErrorToast(true);
        } else {
            await magic.wallet.showUI();
        }
    }

    async function usePolygonMagicWallet(): Promise<void> {
        const isMagicActive = await magicPolygon.user.isLoggedIn();
        if (isMagicActive === false) {
            setShowMagicErrorToast(true);
        } else {
            await magicPolygon.wallet.showUI();
        }
    }

    function renderPolygonIcon(): ReactElement {
        return (
            <img
                src={getThemedImageUrl(import.meta.env.VITE_CDN_URL + "/images/polygon-logo.png")}
                alt="Polygon Logo"
            />
        );
    }

    function toggleModal(): void {
        dispatch(toggleMagicWalletsModal(!isModalShown));
    }

    return (
        <>
            <Toast
                forceWidth="40rem"
                message="Uh oh! There was an issue with your active session. Please log in again. If this problem persists, contact support."
                isOpen={showMagicErrorToast}
                showButton={true}
                buttonMessage="Dismiss"
                buttonFunction={handleMagicErrorToastDismissal}
                onDismiss={handleMagicErrorToastDismissal}
            />
            <FrameOneModal
                isOpen={isModalShown}
                toggle={toggleModal}
                contentClassName="select-magic-wallet-modal"
            >
                <FrameModalHeader
                    title="Select Wallet"
                    toggle={toggleModal}
                />

                <FrameModalBody className="select-magic-wallet-modal_body">

                    <FrameButton
                        <ButtonHTMLAttributes<HTMLButtonElement>>
                        color="purple-outline"
                        size="large"
                        onClick={useMagicWallet}
                        leftIcon={FaEthereum}
                    >
                        Ethereum Wallet
                    </FrameButton>

                    <FrameButton
                        <ButtonHTMLAttributes<HTMLButtonElement>>
                        color="purple-outline"
                        size="large"
                        onClick={usePolygonMagicWallet}
                        leftIcon={renderPolygonIcon}
                    >
                        Polygon Wallet
                    </FrameButton>

                </FrameModalBody>

            </FrameOneModal>
        </>
    );
}

export default SelectMagicWalletModal;
