/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiError,
    ApiErrorFromJSON,
    ApiErrorToJSON,
    BulkReimportMenuBody,
    BulkReimportMenuBodyFromJSON,
    BulkReimportMenuBodyToJSON,
    CreateMenuCategoryBody,
    CreateMenuCategoryBodyFromJSON,
    CreateMenuCategoryBodyToJSON,
    CreateMenuItemBody,
    CreateMenuItemBodyFromJSON,
    CreateMenuItemBodyToJSON,
    GetMenuResponse,
    GetMenuResponseFromJSON,
    GetMenuResponseToJSON,
    GetOrderItemsResponse,
    GetOrderItemsResponseFromJSON,
    GetOrderItemsResponseToJSON,
} from '../models';

export interface BulkReimportMenuRequest {
    bulkReimportMenuBody?: BulkReimportMenuBody;
}

export interface GetMenuRequest {
    id: string;
    asap?: boolean;
}

export interface GetPreviousOrderItemsRequest {
    id: string;
}

export interface ReimportMenuRequest {
    id: string;
}

export interface UpdateMenuCategoryRequest {
    id: string;
    createMenuCategoryBody?: CreateMenuCategoryBody;
}

export interface UpdateMenuItemRequest {
    id: string;
    createMenuItemBody?: CreateMenuItemBody;
}

/**
 * MenusApi - interface
 * @export
 * @interface MenusApiInterface
 */
export interface MenusApiInterface {
    /**
     * Used for Admins to run bulk re-imports on menus.
     * @param {BulkReimportMenuBody} [bulkReimportMenuBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenusApiInterface
     */
    bulkReimportMenuRaw(requestParameters: BulkReimportMenuRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Used for Admins to run bulk re-imports on menus.
     */
    bulkReimportMenu(requestParameters: BulkReimportMenuRequest): Promise<void>;

    /**
     * Should get the menu parsed in a standardized format for a single restaurant.
     * @param {string} id 
     * @param {boolean} [asap] Returns menu data as soon as possible. Potentially cached data that has expired.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenusApiInterface
     */
    getMenuRaw(requestParameters: GetMenuRequest): Promise<runtime.ApiResponse<GetMenuResponse>>;

    /**
     * Should get the menu parsed in a standardized format for a single restaurant.
     */
    getMenu(requestParameters: GetMenuRequest): Promise<GetMenuResponse>;

    /**
     * Should get the customer\'s previously ordered menu items for a particular restaurant.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenusApiInterface
     */
    getPreviousOrderItemsRaw(requestParameters: GetPreviousOrderItemsRequest): Promise<runtime.ApiResponse<GetOrderItemsResponse>>;

    /**
     * Should get the customer\'s previously ordered menu items for a particular restaurant.
     */
    getPreviousOrderItems(requestParameters: GetPreviousOrderItemsRequest): Promise<GetOrderItemsResponse>;

    /**
     * Should reimport the menu for the restaurant using their POS APIs.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenusApiInterface
     */
    reimportMenuRaw(requestParameters: ReimportMenuRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Should reimport the menu for the restaurant using their POS APIs.
     */
    reimportMenu(requestParameters: ReimportMenuRequest): Promise<void>;

    /**
     * Used to update menu category
     * @param {string} id 
     * @param {CreateMenuCategoryBody} [createMenuCategoryBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenusApiInterface
     */
    updateMenuCategoryRaw(requestParameters: UpdateMenuCategoryRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Used to update menu category
     */
    updateMenuCategory(requestParameters: UpdateMenuCategoryRequest): Promise<void>;

    /**
     * Used to update menu item
     * @param {string} id 
     * @param {CreateMenuItemBody} [createMenuItemBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenusApiInterface
     */
    updateMenuItemRaw(requestParameters: UpdateMenuItemRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Used to update menu item
     */
    updateMenuItem(requestParameters: UpdateMenuItemRequest): Promise<void>;

}

/**
 * no description
 */
export class MenusApi extends runtime.BaseAPI implements MenusApiInterface {

    /**
     * Used for Admins to run bulk re-imports on menus.
     */
    async bulkReimportMenuRaw(requestParameters: BulkReimportMenuRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/bulk-reimport-menu`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BulkReimportMenuBodyToJSON(requestParameters.bulkReimportMenuBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Used for Admins to run bulk re-imports on menus.
     */
    async bulkReimportMenu(requestParameters: BulkReimportMenuRequest): Promise<void> {
        await this.bulkReimportMenuRaw(requestParameters);
    }

    /**
     * Should get the menu parsed in a standardized format for a single restaurant.
     */
    async getMenuRaw(requestParameters: GetMenuRequest): Promise<runtime.ApiResponse<GetMenuResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getMenu.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.asap !== undefined) {
            queryParameters['asap'] = requestParameters.asap;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/menus/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetMenuResponseFromJSON(jsonValue));
    }

    /**
     * Should get the menu parsed in a standardized format for a single restaurant.
     */
    async getMenu(requestParameters: GetMenuRequest): Promise<GetMenuResponse> {
        const response = await this.getMenuRaw(requestParameters);
        return await response.value();
    }

    /**
     * Should get the customer\'s previously ordered menu items for a particular restaurant.
     */
    async getPreviousOrderItemsRaw(requestParameters: GetPreviousOrderItemsRequest): Promise<runtime.ApiResponse<GetOrderItemsResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getPreviousOrderItems.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/menus/{id}/order-items`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetOrderItemsResponseFromJSON(jsonValue));
    }

    /**
     * Should get the customer\'s previously ordered menu items for a particular restaurant.
     */
    async getPreviousOrderItems(requestParameters: GetPreviousOrderItemsRequest): Promise<GetOrderItemsResponse> {
        const response = await this.getPreviousOrderItemsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Should reimport the menu for the restaurant using their POS APIs.
     */
    async reimportMenuRaw(requestParameters: ReimportMenuRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling reimportMenu.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/menus/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Should reimport the menu for the restaurant using their POS APIs.
     */
    async reimportMenu(requestParameters: ReimportMenuRequest): Promise<void> {
        await this.reimportMenuRaw(requestParameters);
    }

    /**
     * Used to update menu category
     */
    async updateMenuCategoryRaw(requestParameters: UpdateMenuCategoryRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateMenuCategory.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Discriminated", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/menu-categories/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateMenuCategoryBodyToJSON(requestParameters.createMenuCategoryBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Used to update menu category
     */
    async updateMenuCategory(requestParameters: UpdateMenuCategoryRequest): Promise<void> {
        await this.updateMenuCategoryRaw(requestParameters);
    }

    /**
     * Used to update menu item
     */
    async updateMenuItemRaw(requestParameters: UpdateMenuItemRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateMenuItem.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Discriminated", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/menu-items/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateMenuItemBodyToJSON(requestParameters.createMenuItemBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Used to update menu item
     */
    async updateMenuItem(requestParameters: UpdateMenuItemRequest): Promise<void> {
        await this.updateMenuItemRaw(requestParameters);
    }

}
