/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DisableBusinessBody
 */
export interface DisableBusinessBody {
    /**
     * 
     * @type {string}
     * @memberof DisableBusinessBody
     */
    disableReason: string;
}

export function DisableBusinessBodyFromJSON(json: any): DisableBusinessBody {
    return DisableBusinessBodyFromJSONTyped(json, false);
}

export function DisableBusinessBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): DisableBusinessBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'disableReason': json['disableReason'],
    };
}

export function DisableBusinessBodyToJSON(value?: DisableBusinessBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'disableReason': value.disableReason,
    };
}


