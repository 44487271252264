/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BusinessTaxonomyBody
 */
export interface BusinessTaxonomyBody {
    /**
     * 
     * @type {string}
     * @memberof BusinessTaxonomyBody
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessTaxonomyBody
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessTaxonomyBody
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof BusinessTaxonomyBody
     */
    priority?: number;
}

export function BusinessTaxonomyBodyFromJSON(json: any): BusinessTaxonomyBody {
    return BusinessTaxonomyBodyFromJSONTyped(json, false);
}

export function BusinessTaxonomyBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): BusinessTaxonomyBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'image': !exists(json, 'image') ? undefined : json['image'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'priority': !exists(json, 'priority') ? undefined : json['priority'],
    };
}

export function BusinessTaxonomyBodyToJSON(value?: BusinessTaxonomyBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'image': value.image,
        'description': value.description,
        'priority': value.priority,
    };
}


