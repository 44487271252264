import type { EventEmitter, SendTransactionOptions, WalletName } from "@solana/wallet-adapter-base";
import {
    BaseMessageSignerWalletAdapter, isIosAndRedirectable,
    isVersionedTransaction,
    scopePollingDetectionStrategy,
    WalletAccountError,
    WalletConnectionError,
    WalletDisconnectedError,
    WalletDisconnectionError,
    WalletError,
    WalletNotConnectedError,
    WalletNotReadyError,
    WalletPublicKeyError,
    WalletReadyState,
    WalletSendTransactionError,
    WalletSignMessageError,
    WalletSignTransactionError,
} from "@solana/wallet-adapter-base";
import type {
    Connection,
    SendOptions,
    Transaction,
    TransactionSignature,
    TransactionVersion,
    VersionedTransaction,
} from "@solana/web3.js";
import { PublicKey } from "@solana/web3.js";
import {isGoNative} from "@/utils/isGoNative";

interface PhantomWalletEvents {
    connect(...args: unknown[]): unknown;
    disconnect(...args: unknown[]): unknown;
    accountChanged(newPublicKey: PublicKey): unknown;
}

interface PhantomWallet extends EventEmitter<PhantomWalletEvents> {
    isPhantom?: boolean;
    publicKey?: { toBytes(): Uint8Array };
    isConnected: boolean;
    signTransaction<T extends Transaction | VersionedTransaction>(transaction: T): Promise<T>;
    signAllTransactions<T extends Transaction | VersionedTransaction>(transactions: T[]): Promise<T[]>;
    signAndSendTransaction<T extends Transaction | VersionedTransaction>(
        transaction: T,
        options?: SendOptions
    ): Promise<{ signature: TransactionSignature }>;
    signMessage(message: Uint8Array): Promise<{ signature: Uint8Array }>;
    connect(): Promise<void>;
    disconnect(): Promise<void>;
}

interface PhantomWindow extends Window {
    phantom?: {
        solana?: PhantomWallet;
    };
    solana?: PhantomWallet;
}

declare const window: PhantomWindow;

export interface PhantomWalletAdapterConfig {}

export const PhantomWalletName = "Phantom" as WalletName<"Phantom">;

export class CustomPhantomWalletAdapter extends BaseMessageSignerWalletAdapter {
    name = PhantomWalletName;
    url = "https://phantom.app";
    icon =
        "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDgiIGhlaWdodD0iMTA4IiB2aWV3Qm94PSIwIDAgMTA4IDEwOCIgZmlsbD0ibm9uZSI+CjxyZWN0IHdpZHRoPSIxMDgiIGhlaWdodD0iMTA4IiByeD0iMjYiIGZpbGw9IiNBQjlGRjIiLz4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik00Ni41MjY3IDY5LjkyMjlDNDIuMDA1NCA3Ni44NTA5IDM0LjQyOTIgODUuNjE4MiAyNC4zNDggODUuNjE4MkMxOS41ODI0IDg1LjYxODIgMTUgODMuNjU2MyAxNSA3NS4xMzQyQzE1IDUzLjQzMDUgNDQuNjMyNiAxOS44MzI3IDcyLjEyNjggMTkuODMyN0M4Ny43NjggMTkuODMyNyA5NCAzMC42ODQ2IDk0IDQzLjAwNzlDOTQgNTguODI1OCA4My43MzU1IDc2LjkxMjIgNzMuNTMyMSA3Ni45MTIyQzcwLjI5MzkgNzYuOTEyMiA2OC43MDUzIDc1LjEzNDIgNjguNzA1MyA3Mi4zMTRDNjguNzA1MyA3MS41NzgzIDY4LjgyNzUgNzAuNzgxMiA2OS4wNzE5IDY5LjkyMjlDNjUuNTg5MyA3NS44Njk5IDU4Ljg2ODUgODEuMzg3OCA1Mi41NzU0IDgxLjM4NzhDNDcuOTkzIDgxLjM4NzggNDUuNjcxMyA3OC41MDYzIDQ1LjY3MTMgNzQuNDU5OEM0NS42NzEzIDcyLjk4ODQgNDUuOTc2OCA3MS40NTU2IDQ2LjUyNjcgNjkuOTIyOVpNODMuNjc2MSA0Mi41Nzk0QzgzLjY3NjEgNDYuMTcwNCA4MS41NTc1IDQ3Ljk2NTggNzkuMTg3NSA0Ny45NjU4Qzc2Ljc4MTYgNDcuOTY1OCA3NC42OTg5IDQ2LjE3MDQgNzQuNjk4OSA0Mi41Nzk0Qzc0LjY5ODkgMzguOTg4NSA3Ni43ODE2IDM3LjE5MzEgNzkuMTg3NSAzNy4xOTMxQzgxLjU1NzUgMzcuMTkzMSA4My42NzYxIDM4Ljk4ODUgODMuNjc2MSA0Mi41Nzk0Wk03MC4yMTAzIDQyLjU3OTVDNzAuMjEwMyA0Ni4xNzA0IDY4LjA5MTYgNDcuOTY1OCA2NS43MjE2IDQ3Ljk2NThDNjMuMzE1NyA0Ny45NjU4IDYxLjIzMyA0Ni4xNzA0IDYxLjIzMyA0Mi41Nzk1QzYxLjIzMyAzOC45ODg1IDYzLjMxNTcgMzcuMTkzMSA2NS43MjE2IDM3LjE5MzFDNjguMDkxNiAzNy4xOTMxIDcwLjIxMDMgMzguOTg4NSA3MC4yMTAzIDQyLjU3OTVaIiBmaWxsPSIjRkZGREY4Ii8+Cjwvc3ZnPg==";
    supportedTransactionVersions: ReadonlySet<TransactionVersion> = new Set([
        "legacy",
        0,
    ]);

    private _connecting: boolean;
    private _wallet: PhantomWallet | null;
    private _publicKey: PublicKey | null;
    private _readyState: WalletReadyState =
        typeof window === "undefined" || typeof document === "undefined"
            ? WalletReadyState.Unsupported
            : WalletReadyState.NotDetected;

    constructor(config: PhantomWalletAdapterConfig = {}) {
        super();
        this._connecting = false;
        this._wallet = null;
        this._publicKey = null;

        if (this._readyState !== WalletReadyState.Unsupported) {
            if (isGoNative() || isIosAndRedirectable()) {
                // make Phantom loadable when in iOS safari, or our GoNative app, instead of checking for install
                this._readyState = WalletReadyState.Loadable;
                this.emit("readyStateChange", this._readyState);
            } else {
                // for desktop browser
                // it won't likely run into here as Wallet Adapter could already detect the phantom object injected into the window.
                scopePollingDetectionStrategy(() => {
                    if (window.phantom?.solana?.isPhantom || window.solana?.isPhantom) {
                        this._readyState = WalletReadyState.Installed;
                        this.emit("readyStateChange", this._readyState);
                        return true;
                    }
                    return false;
                });
            }
        }
    }

    get publicKey() {
        return this._publicKey;
    }

    get connecting() {
        return this._connecting;
    }

    get readyState() {
        return this._readyState;
    }

    async autoConnect(): Promise<void> {
        // Skip auto-connect if in the Loadable state
        // We can't redirect to a universal link without user input
        if (this.readyState === WalletReadyState.Installed) {
            await this.connect();
        }
    }

    /**
     * Connect to the wallet. Establish the connection if in Phantom's browser already as the phantom object is available.
     * Otherwise, open the Phantom universal link.
     */
    async connect(): Promise<void> {
        try {
            if (this.connected || this.connecting) return;

            if (this.readyState === WalletReadyState.Loadable) {
                // redirect to the Phantom /browse universal link
                // this will open our log-in page in the Phantom in-wallet browser
                const url = encodeURIComponent(`${window.location.origin}/log-in`);
                const ref = encodeURIComponent(window.location.origin);
                window.location.href = `https://phantom.app/ul/browse/${url}?ref=${ref}`;
                return;
            }

            if (this.readyState !== WalletReadyState.Installed) throw new WalletNotReadyError();

            this._connecting = true;

            // establish connection within browser where phantom is available
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const wallet = window.phantom?.solana || window.solana!;

            // connect to Phantom if not already connected
            if (!wallet.isConnected) {
                try {
                    await wallet.connect();
                } catch (error: any) {
                    throw new WalletConnectionError(error?.message, error);
                }
            }

            if (!wallet.publicKey) throw new WalletAccountError();

            let publicKey: PublicKey;
            try {
                publicKey = new PublicKey(wallet.publicKey.toBytes());
            } catch (error: any) {
                throw new WalletPublicKeyError(error?.message, error);
            }

            // attach listeners for disconnection and account change events
            wallet.on("disconnect", this._disconnected);
            wallet.on("accountChanged", this._accountChanged);


            // update wallet reference and public key
            this._wallet = wallet;
            this._publicKey = publicKey;

            this.emit("connect", publicKey);
        } catch (error: any) {
            this.emit("error", error);
            throw error;
        } finally {
            this._connecting = false;
        }
    }

    /**
     * Disconnect from the wallet
     */
    async disconnect(): Promise<void> {
        const wallet = this._wallet;
        if (wallet) {

            // remove event listeners
            wallet.off("disconnect", this._disconnected);
            wallet.off("accountChanged", this._accountChanged);

            // clear wallet reference and public key
            this._wallet = null;
            this._publicKey = null;

            try {
                await wallet.disconnect();
            } catch (error: any) {
                this.emit("error", new WalletDisconnectionError(error?.message, error));
            }
        }

        this.emit("disconnect");
    }

    /**
     * Sends a transaction using the connected Phantom wallet.
     * @param transaction The unsigned trnasaction to be signed and submitted to the network by the wallet.
     * @param connection The Solana JSON RPC connection.
     * @param options
     */
    async sendTransaction<T extends Transaction | VersionedTransaction>(
        transaction: T,
        connection: Connection,
        options: SendTransactionOptions = {},
    ): Promise<TransactionSignature> {
        try {
            const wallet = this._wallet;
            if (!wallet) throw new WalletNotConnectedError();

            try {
                const { signers, ...sendOptions } = options;

                if (isVersionedTransaction(transaction)) {
                    signers?.length && transaction.sign(signers);
                } else {
                    // version by setting recentBlockhash and feePayer fields which then be checked by Solana to see if the
                    // transaction is outdated then partially sign
                    transaction = (await this.prepareTransaction(transaction, connection, sendOptions)) as T;
                    signers?.length && (transaction as Transaction).partialSign(...signers);
                }

                sendOptions.preflightCommitment = sendOptions.preflightCommitment || connection.commitment;

                const { signature } = await wallet.signAndSendTransaction(transaction, sendOptions);
                return signature;
            } catch (error: any) {
                if (error instanceof WalletError) throw error;
                throw new WalletSendTransactionError(error?.message, error);
            }
        } catch (error: any) {
            this.emit("error", error);
            throw error;
        }
    }

    /**
     * Signs a transaction and return the transaction back to the application
     * @param transaction
     */
    async signTransaction<T extends Transaction | VersionedTransaction>(transaction: T): Promise<T> {
        try {
            const wallet = this._wallet;
            if (!wallet) throw new WalletNotConnectedError();

            try {
                // if versioned ( checked to see if transaction is outdated) then sign
                return await wallet.signTransaction(transaction) || transaction;
            } catch (error: any) {
                throw new WalletSignTransactionError(error?.message, error);
            }
        } catch (error: any) {
            this.emit("error", error);
            throw error;
        }
    }

    /**
     * Signs multiple transactions once app is connected
     * @param transactions
     */
    async signAllTransactions<T extends Transaction | VersionedTransaction>(transactions: T[]): Promise<T[]> {
        try {
            const wallet = this._wallet;
            if (!wallet) throw new WalletNotConnectedError();

            try {
                return await wallet.signAllTransactions(transactions) || transactions;
            } catch (error: any) {
                throw new WalletSignTransactionError(error?.message, error);
            }
        } catch (error: any) {
            this.emit("error", error);
            throw error;
        }
    }

    /**
     * Signs arbitrary messages using the user's Phantom wallet
     * @param message
     */
    async signMessage(message: Uint8Array): Promise<Uint8Array> {
        try {
            const wallet = this._wallet;
            if (!wallet) throw new WalletNotConnectedError();

            try {
                const { signature } = await wallet.signMessage(message);
                return signature;
            } catch (error: any) {
                throw new WalletSignMessageError(error?.message, error);
            }
        } catch (error: any) {
            this.emit("error", error);
            throw error;
        }
    }


    /**
     * Event handler for handling disconnection.
     * Emits disconnect event and an error if applicable.
     */
    private _disconnected = () => {
        const wallet = this._wallet;
        if (wallet) {
            wallet.off("disconnect", this._disconnected);
            wallet.off("accountChanged", this._accountChanged);

            this._wallet = null;
            this._publicKey = null;

            this.emit("error", new WalletDisconnectedError());
            this.emit("disconnect");
        }
    };

    /**
     * Event handler for handling account changes
     * Emits connect event with the new public key if applicable, or an error if there's an issue with the public key.
     */
    private _accountChanged = (newPublicKey: PublicKey) => {
        const publicKey = this._publicKey;
        if (!publicKey) return;

        try {
            newPublicKey = new PublicKey(newPublicKey.toBytes());
        } catch (error: any) {
            this.emit("error", new WalletPublicKeyError(error?.message, error));
            return;
        }

        if (publicKey.equals(newPublicKey)) return;

        this._publicKey = newPublicKey;
        this.emit("connect", newPublicKey);
    };
}

