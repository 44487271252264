import {
    ButtonHTMLAttributes,
    ChangeEvent,
    Fragment,
    PropsWithChildren,
    ReactElement,
    ReactNode,
    useEffect,
    useState,
} from "react";
import FrameButton from "@/components/buttons/FrameButton";
import {FaMapMarkerAlt} from "react-icons/fa";
import classNames from "classnames";

interface Props<D = string> {
    placeholder?: string;
    resetOnSelect?: boolean;
    emptyText?: string;
    minLength?: number;
    onSelect: (d: D) => void;
    onInputChange: (q: string) => void;
    results: Array<{
        render: ReactNode;
        data: D;
        address?: string;
    }>;
    showButton?: boolean;
    showMapMarkerIcon?: boolean;
}

function FrameOneAutocompleteInput<D>(props: PropsWithChildren<Props<D>>): ReactElement {

    const [
        inputValue,
        setInputValue,
    ] = useState<string>("");
    const [
        dismissResults,
        setDismissResults,
    ] = useState<boolean>(false);
    const [
        resultData,
        setResultData,
    ] = useState<D>();

    useEffect(() => {
        if (inputValue.length >= props.minLength) {
            props.onInputChange(inputValue);
        }
    }, [inputValue]);

    function onClickHelper(data: D): void {
        props.onSelect(data);

        if (props.resetOnSelect) {
            setInputValue("");
        }
    }

    const renderResults: JSX.Element =
        <ul className="frame-one-autocomplete-input_results_list">
            {props.results.map((result, index) => <li
                key={`autocomplete-result-${index}`}
                className="frame-one-autocomplete-input_results_list_item"
            >
                <button
                    type="button"
                    onClick={() => {
                        if (props.showButton) {
                            setDismissResults(true);
                            setInputValue(result.address);
                            setResultData(result.data);
                        } else {
                            onClickHelper(result.data);
                        }
                    }}
                >
                    {result.render}
                </button>
            </li>)}
        </ul>;

    /**
     * No results from autocomplete api, but check if the input component actually has text before showing the empty response.
     */
    const renderNoResults: JSX.Element = props.emptyText
        ? <div className="frame-one-autocomplete-input_results_empty">
            {props.emptyText}
        </div>
        : <Fragment/>;

    function onChangeHelper(e: ChangeEvent<HTMLInputElement>) {
        setDismissResults(false);
        setResultData(undefined);
        setInputValue(e.target.value);
    }

    return (
        <>
            <div className={classNames("frame-one-autocomplete-input", {
                "show-icon": props.showMapMarkerIcon,
            })}>
                {props.showMapMarkerIcon && <FaMapMarkerAlt className="frame-one-autocomplete-input_icon"/> }
                <div className="frame-one-autocomplete-input_value">
                    <input
                        type="text"
                        placeholder={props.placeholder}
                        value={inputValue}
                        onChange={onChangeHelper}
                    />
                </div>
                {inputValue.length >= props.minLength && !dismissResults &&
                <div className="frame-one-autocomplete-input_results">
                    {props.results.length > 0
                        ? renderResults
                        : renderNoResults}
                </div>
                }
            </div>
            {props.showButton &&
            <FrameButton
                <ButtonHTMLAttributes<HTMLButtonElement>>
                color="purple"
                size="normal"
                onClick={() => resultData && onClickHelper(resultData)}
                forwardProps={{
                    disabled: !resultData,
                }}
                className="frame-one-autocomplete-input_button"
            >
                    Find food near me
            </FrameButton>}
        </>
    );

}

FrameOneAutocompleteInput.defaultProps = {
    minLength: 1,
    emptyText: "No results found",
};

export default FrameOneAutocompleteInput;
