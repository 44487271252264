/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Address,
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
    BrandMapAdvertisementBlocksBody,
    BrandMapAdvertisementBlocksBodyFromJSON,
    BrandMapAdvertisementBlocksBodyFromJSONTyped,
    BrandMapAdvertisementBlocksBodyToJSON,
    BrandMapBannerImageBody,
    BrandMapBannerImageBodyFromJSON,
    BrandMapBannerImageBodyFromJSONTyped,
    BrandMapBannerImageBodyToJSON,
    BrandMapColorTheme,
    BrandMapColorThemeFromJSON,
    BrandMapColorThemeFromJSONTyped,
    BrandMapColorThemeToJSON,
    BrandMapHtmlSection,
    BrandMapHtmlSectionFromJSON,
    BrandMapHtmlSectionFromJSONTyped,
    BrandMapHtmlSectionToJSON,
    BrandMapPromoHeaderBody,
    BrandMapPromoHeaderBodyFromJSON,
    BrandMapPromoHeaderBodyFromJSONTyped,
    BrandMapPromoHeaderBodyToJSON,
    BrandMapStatus,
    BrandMapStatusFromJSON,
    BrandMapStatusFromJSONTyped,
    BrandMapStatusToJSON,
    BrandPageType,
    BrandPageTypeFromJSON,
    BrandPageTypeFromJSONTyped,
    BrandPageTypeToJSON,
    EVMChain,
    EVMChainFromJSON,
    EVMChainFromJSONTyped,
    EVMChainToJSON,
    LoginAccessPoint,
    LoginAccessPointFromJSON,
    LoginAccessPointFromJSONTyped,
    LoginAccessPointToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreateBrandMapBody
 */
export interface CreateBrandMapBody {
    /**
     * 
     * @type {string}
     * @memberof CreateBrandMapBody
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandMapBody
     */
    brand: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandMapBody
     */
    slug?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandMapBody
     */
    titleImage?: string;
    /**
     * 
     * @type {BrandMapBannerImageBody}
     * @memberof CreateBrandMapBody
     */
    bannerImage?: BrandMapBannerImageBody;
    /**
     * 
     * @type {Address}
     * @memberof CreateBrandMapBody
     */
    defaultLocation?: Address;
    /**
     * 
     * @type {BrandMapStatus}
     * @memberof CreateBrandMapBody
     */
    status: BrandMapStatus;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateBrandMapBody
     */
    businesses?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandMapBody
     */
    mainPromo?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateBrandMapBody
     */
    promos?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandMapBody
     */
    promoHero?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandMapBody
     */
    promoBackground?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandMapBody
     */
    promoCartHero?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandMapBody
     */
    promoCartBackground?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandMapBody
     */
    promoTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandMapBody
     */
    promoDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandMapBody
     */
    promoTagline?: string;
    /**
     * 
     * @type {BrandMapColorTheme}
     * @memberof CreateBrandMapBody
     */
    colorTheme: BrandMapColorTheme;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandMapBody
     */
    colorBackground?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandMapBody
     */
    defaultBusiness?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandMapBody
     */
    autoMintContract?: string;
    /**
     * 
     * @type {EVMChain}
     * @memberof CreateBrandMapBody
     */
    autoMintChain?: EVMChain;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandMapBody
     */
    advertisementTitle?: string;
    /**
     * 
     * @type {Array<BrandMapAdvertisementBlocksBody>}
     * @memberof CreateBrandMapBody
     */
    advertisementBlocks: Array<BrandMapAdvertisementBlocksBody>;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandMapBody
     */
    autoMintToastMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandMapBody
     */
    autoMintToastBackground?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandMapBody
     */
    description?: string;
    /**
     * 
     * @type {BrandMapColorTheme}
     * @memberof CreateBrandMapBody
     */
    autoMintToastTextColor?: BrandMapColorTheme;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandMapBody
     */
    preAutoMintToastMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandMapBody
     */
    preAutoMintToastBackground?: string;
    /**
     * 
     * @type {LoginAccessPoint}
     * @memberof CreateBrandMapBody
     */
    loginAccessPoint?: LoginAccessPoint;
    /**
     * 
     * @type {BrandPageType}
     * @memberof CreateBrandMapBody
     */
    brandPageType?: BrandPageType;
    /**
     * 
     * @type {boolean}
     * @memberof CreateBrandMapBody
     */
    allowMarketplaceAccess?: boolean;
    /**
     * 
     * @type {BrandMapPromoHeaderBody}
     * @memberof CreateBrandMapBody
     */
    promoHeader?: BrandMapPromoHeaderBody;
    /**
     * 
     * @type {Array<BrandMapHtmlSection>}
     * @memberof CreateBrandMapBody
     */
    htmlSectionFirstRow?: Array<BrandMapHtmlSection>;
    /**
     * 
     * @type {Array<BrandMapHtmlSection>}
     * @memberof CreateBrandMapBody
     */
    htmlSectionSecondRow?: Array<BrandMapHtmlSection>;
}

export function CreateBrandMapBodyFromJSON(json: any): CreateBrandMapBody {
    return CreateBrandMapBodyFromJSONTyped(json, false);
}

export function CreateBrandMapBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateBrandMapBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'brand': json['brand'],
        'slug': !exists(json, 'slug') ? undefined : json['slug'],
        'titleImage': !exists(json, 'titleImage') ? undefined : json['titleImage'],
        'bannerImage': !exists(json, 'bannerImage') ? undefined : BrandMapBannerImageBodyFromJSON(json['bannerImage']),
        'defaultLocation': !exists(json, 'defaultLocation') ? undefined : AddressFromJSON(json['defaultLocation']),
        'status': BrandMapStatusFromJSON(json['status']),
        'businesses': !exists(json, 'businesses') ? undefined : json['businesses'],
        'mainPromo': !exists(json, 'mainPromo') ? undefined : json['mainPromo'],
        'promos': !exists(json, 'promos') ? undefined : json['promos'],
        'promoHero': !exists(json, 'promoHero') ? undefined : json['promoHero'],
        'promoBackground': !exists(json, 'promoBackground') ? undefined : json['promoBackground'],
        'promoCartHero': !exists(json, 'promoCartHero') ? undefined : json['promoCartHero'],
        'promoCartBackground': !exists(json, 'promoCartBackground') ? undefined : json['promoCartBackground'],
        'promoTitle': !exists(json, 'promoTitle') ? undefined : json['promoTitle'],
        'promoDescription': !exists(json, 'promoDescription') ? undefined : json['promoDescription'],
        'promoTagline': !exists(json, 'promoTagline') ? undefined : json['promoTagline'],
        'colorTheme': BrandMapColorThemeFromJSON(json['colorTheme']),
        'colorBackground': !exists(json, 'colorBackground') ? undefined : json['colorBackground'],
        'defaultBusiness': !exists(json, 'defaultBusiness') ? undefined : json['defaultBusiness'],
        'autoMintContract': !exists(json, 'autoMintContract') ? undefined : json['autoMintContract'],
        'autoMintChain': !exists(json, 'autoMintChain') ? undefined : EVMChainFromJSON(json['autoMintChain']),
        'advertisementTitle': !exists(json, 'advertisementTitle') ? undefined : json['advertisementTitle'],
        'advertisementBlocks': ((json['advertisementBlocks'] as Array<any>).map(BrandMapAdvertisementBlocksBodyFromJSON)),
        'autoMintToastMessage': !exists(json, 'autoMintToastMessage') ? undefined : json['autoMintToastMessage'],
        'autoMintToastBackground': !exists(json, 'autoMintToastBackground') ? undefined : json['autoMintToastBackground'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'autoMintToastTextColor': !exists(json, 'autoMintToastTextColor') ? undefined : BrandMapColorThemeFromJSON(json['autoMintToastTextColor']),
        'preAutoMintToastMessage': !exists(json, 'preAutoMintToastMessage') ? undefined : json['preAutoMintToastMessage'],
        'preAutoMintToastBackground': !exists(json, 'preAutoMintToastBackground') ? undefined : json['preAutoMintToastBackground'],
        'loginAccessPoint': !exists(json, 'loginAccessPoint') ? undefined : LoginAccessPointFromJSON(json['loginAccessPoint']),
        'brandPageType': !exists(json, 'brandPageType') ? undefined : BrandPageTypeFromJSON(json['brandPageType']),
        'allowMarketplaceAccess': !exists(json, 'allowMarketplaceAccess') ? undefined : json['allowMarketplaceAccess'],
        'promoHeader': !exists(json, 'promoHeader') ? undefined : BrandMapPromoHeaderBodyFromJSON(json['promoHeader']),
        'htmlSectionFirstRow': !exists(json, 'htmlSectionFirstRow') ? undefined : ((json['htmlSectionFirstRow'] as Array<any>).map(BrandMapHtmlSectionFromJSON)),
        'htmlSectionSecondRow': !exists(json, 'htmlSectionSecondRow') ? undefined : ((json['htmlSectionSecondRow'] as Array<any>).map(BrandMapHtmlSectionFromJSON)),
    };
}

export function CreateBrandMapBodyToJSON(value?: CreateBrandMapBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'brand': value.brand,
        'slug': value.slug,
        'titleImage': value.titleImage,
        'bannerImage': BrandMapBannerImageBodyToJSON(value.bannerImage),
        'defaultLocation': AddressToJSON(value.defaultLocation),
        'status': BrandMapStatusToJSON(value.status),
        'businesses': value.businesses,
        'mainPromo': value.mainPromo,
        'promos': value.promos,
        'promoHero': value.promoHero,
        'promoBackground': value.promoBackground,
        'promoCartHero': value.promoCartHero,
        'promoCartBackground': value.promoCartBackground,
        'promoTitle': value.promoTitle,
        'promoDescription': value.promoDescription,
        'promoTagline': value.promoTagline,
        'colorTheme': BrandMapColorThemeToJSON(value.colorTheme),
        'colorBackground': value.colorBackground,
        'defaultBusiness': value.defaultBusiness,
        'autoMintContract': value.autoMintContract,
        'autoMintChain': EVMChainToJSON(value.autoMintChain),
        'advertisementTitle': value.advertisementTitle,
        'advertisementBlocks': ((value.advertisementBlocks as Array<any>).map(BrandMapAdvertisementBlocksBodyToJSON)),
        'autoMintToastMessage': value.autoMintToastMessage,
        'autoMintToastBackground': value.autoMintToastBackground,
        'description': value.description,
        'autoMintToastTextColor': BrandMapColorThemeToJSON(value.autoMintToastTextColor),
        'preAutoMintToastMessage': value.preAutoMintToastMessage,
        'preAutoMintToastBackground': value.preAutoMintToastBackground,
        'loginAccessPoint': LoginAccessPointToJSON(value.loginAccessPoint),
        'brandPageType': BrandPageTypeToJSON(value.brandPageType),
        'allowMarketplaceAccess': value.allowMarketplaceAccess,
        'promoHeader': BrandMapPromoHeaderBodyToJSON(value.promoHeader),
        'htmlSectionFirstRow': value.htmlSectionFirstRow === undefined ? undefined : ((value.htmlSectionFirstRow as Array<any>).map(BrandMapHtmlSectionToJSON)),
        'htmlSectionSecondRow': value.htmlSectionSecondRow === undefined ? undefined : ((value.htmlSectionSecondRow as Array<any>).map(BrandMapHtmlSectionToJSON)),
    };
}


