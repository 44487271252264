import {ReactElement} from "react";
import {HandoffOptions} from "@devour/client";
import FrameOneSwitchInput from "./inputs/FrameOneSwitchInput";
import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";

interface Props {
    onHandoffToggle: (option: HandoffOptions) => void;
    disabledDelivery?: boolean;
    disabledPickup?: boolean;
    handoff?: HandoffOptions;
}

function HandoffToggle(props: Props): ReactElement {
    const handoff = props.handoff || useSelector((store: IStore) => store.metaStore.handoff);

    return (
        <FrameOneSwitchInput
            <HandoffOptions>
            name="handoff-options"
            value={handoff}
            onToggle={props.onHandoffToggle}
            options={[
                {
                    render: "Delivery",
                    value: HandoffOptions.DELIVERY,
                    disabled: props.disabledDelivery,
                },
                {
                    render: "Pickup",
                    value: HandoffOptions.PICKUP,
                    disabled: props.disabledPickup,
                },
            ]}
        />
    );
}

export default HandoffToggle;
