import {IAction} from "../IAction";
import {cloneDeep} from "lodash";
import InitialAuthStore, {IAuthStore} from "./InitialAuthStore";

export enum AuthAction {
    LOGIN = "LOGIN",
    LOGOUT = "LOGOUT",
}

export default function authReducer(store: IAuthStore = InitialAuthStore, a: IAction<AuthAction, any>): IAuthStore {

    const n: IAuthStore = cloneDeep(store);

    switch (a.type) {
        case AuthAction.LOGIN:
            n.fullToken = a.payload;
            break;
        case AuthAction.LOGOUT:
            delete n.fullToken;
            break;
        default:
            break;
    }

    return n;
}
