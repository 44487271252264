import {ChangeEvent, ReactElement, useState} from "react";
import FrameModalHeader from "../modalComponents/FrameModalHeader";
import FrameModalBody from "../modalComponents/FrameModalBody";
import FrameModalFooter from "../modalComponents/FrameModalFooter";
import FrameButton from "../../buttons/FrameButton";
import FrameOneModal from "../modalComponents/FrameOneModal";
import {ApiError} from "@devour/client";
import * as Sentry from "@sentry/react";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    apiError: ApiError;
}

function ReportErrorModal(props: Props): ReactElement {
    const [
        reportMessage,
        setReportMessage,
    ] = useState<string>("");

    function onSubmit(): void {
        Sentry.withScope((scope) => {
            scope.setTag("type", "UserReported");
            scope.setLevel("error");
            scope.setContext("apiError", {
                apiError: props.apiError,
            });
            Sentry.captureMessage(`User Report: ${props.apiError.message} ${reportMessage}`);
        });
        setReportMessage("");
        props.onClose();
    }

    function onReportMessageChange(e: ChangeEvent<HTMLTextAreaElement>): void {
        setReportMessage(e.target.value);
    }

    return (
        <FrameOneModal
            isOpen={props.isOpen}
            toggle={props.onClose}
            contentClassName="report-error-modal"
        >
            <FrameModalHeader
                title="Report"
                toggle={props.onClose}
            />

            <FrameModalBody className="report-error-modal_body">
                <div className="report-error-modal_body_info">
                    <label>Please give us additional info to speed up the report process.</label>
                    <textarea
                        placeholder="Enter a description of this report..."
                        value={reportMessage}
                        onChange={onReportMessageChange}
                        maxLength={300}
                    />
                </div>
            </FrameModalBody>

            <FrameModalFooter>
                <FrameButton
                    color="purple"
                    size="normal"
                    onClick={onSubmit}
                >
                    Submit
                </FrameButton>
            </FrameModalFooter>
        </FrameOneModal>
    );
}

export default ReportErrorModal;
