/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GameEventRewardResponse
 */
export interface GameEventRewardResponse {
    /**
     * 
     * @type {number}
     * @memberof GameEventRewardResponse
     */
    result: number;
    /**
     * 
     * @type {number}
     * @memberof GameEventRewardResponse
     */
    rotationAmount: number;
    /**
     * 
     * @type {number}
     * @memberof GameEventRewardResponse
     */
    pointsEarned?: number;
}

export function GameEventRewardResponseFromJSON(json: any): GameEventRewardResponse {
    return GameEventRewardResponseFromJSONTyped(json, false);
}

export function GameEventRewardResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GameEventRewardResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': json['result'],
        'rotationAmount': json['rotationAmount'],
        'pointsEarned': !exists(json, 'pointsEarned') ? undefined : json['pointsEarned'],
    };
}

export function GameEventRewardResponseToJSON(value?: GameEventRewardResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': value.result,
        'rotationAmount': value.rotationAmount,
        'pointsEarned': value.pointsEarned,
    };
}


