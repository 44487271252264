import {ReactElement} from "react";
import Skeleton from "react-loading-skeleton";

function RestaurantSearchDataSkeleton(): ReactElement {
    return (
        <div className='restaurant_search_skeleton  restaurants-search-results-page_content'>
            <div className='restaurant_search_title'>
                <Skeleton height={34}/>
            </div>
            <div className='restaurants-search-results_list'>
                {Array.from({length: 12}, (_, i) => <div className="restaurants-search-results_list_item" key={i}>
                    <div className="restaurant_search_item_card ">
                        <Skeleton height={163}/>
                    </div>
                    <div className="restaurant-search_item_description">
                        <Skeleton/>
                    </div>
                </div>)}
            </div>
        </div>

    );
}

export default RestaurantSearchDataSkeleton;