/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Address,
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
    HandoffOptions,
    HandoffOptionsFromJSON,
    HandoffOptionsFromJSONTyped,
    HandoffOptionsToJSON,
    MenuOrderDiscount,
    MenuOrderDiscountFromJSON,
    MenuOrderDiscountFromJSONTyped,
    MenuOrderDiscountToJSON,
    MenuOrderItem,
    MenuOrderItemFromJSON,
    MenuOrderItemFromJSONTyped,
    MenuOrderItemToJSON,
    MenuOrderServiceFee,
    MenuOrderServiceFeeFromJSON,
    MenuOrderServiceFeeFromJSONTyped,
    MenuOrderServiceFeeToJSON,
    MenuOrderStatus,
    MenuOrderStatusFromJSON,
    MenuOrderStatusFromJSONTyped,
    MenuOrderStatusToJSON,
    OrderRefund,
    OrderRefundFromJSON,
    OrderRefundFromJSONTyped,
    OrderRefundToJSON,
    TimeStamps,
    TimeStampsFromJSON,
    TimeStampsFromJSONTyped,
    TimeStampsToJSON,
} from './';

/**
 * Represents a single menu order owned by users in the system.
 * @export
 * @interface MenuOrderOwn
 */
export interface MenuOrderOwn {
    /**
     * 
     * @type {string}
     * @memberof MenuOrderOwn
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof MenuOrderOwn
     */
    createdAt: number;
    /**
     * 
     * @type {number}
     * @memberof MenuOrderOwn
     */
    updatedAt: number;
    /**
     * 
     * @type {string}
     * @memberof MenuOrderOwn
     */
    business: string;
    /**
     * 
     * @type {number}
     * @memberof MenuOrderOwn
     */
    subtotal: number;
    /**
     * 
     * @type {number}
     * @memberof MenuOrderOwn
     */
    stripeTotal: number;
    /**
     * 
     * @type {Array<MenuOrderServiceFee>}
     * @memberof MenuOrderOwn
     */
    serviceFees?: Array<MenuOrderServiceFee>;
    /**
     * 
     * @type {number}
     * @memberof MenuOrderOwn
     */
    tax: number;
    /**
     * 
     * @type {number}
     * @memberof MenuOrderOwn
     */
    deliveryCharge: number;
    /**
     * 
     * @type {number}
     * @memberof MenuOrderOwn
     */
    tipDelivery?: number;
    /**
     * 
     * @type {Array<MenuOrderDiscount>}
     * @memberof MenuOrderOwn
     */
    discounts?: Array<MenuOrderDiscount>;
    /**
     * 
     * @type {string}
     * @memberof MenuOrderOwn
     */
    transactionHash?: string;
    /**
     * 
     * @type {MenuOrderStatus}
     * @memberof MenuOrderOwn
     */
    status?: MenuOrderStatus;
    /**
     * 
     * @type {Address}
     * @memberof MenuOrderOwn
     */
    address: Address;
    /**
     * 
     * @type {HandoffOptions}
     * @memberof MenuOrderOwn
     */
    handoff?: HandoffOptions;
    /**
     * 
     * @type {string}
     * @memberof MenuOrderOwn
     */
    pickupCode?: string;
    /**
     * 
     * @type {Array<MenuOrderItem>}
     * @memberof MenuOrderOwn
     */
    orderItems: Array<MenuOrderItem>;
    /**
     * 
     * @type {boolean}
     * @memberof MenuOrderOwn
     */
    callbackComplete: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MenuOrderOwn
     */
    exceededSlippage?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MenuOrderOwn
     */
    uberEatsUuid?: string;
    /**
     * 
     * @type {string}
     * @memberof MenuOrderOwn
     */
    orderUuid?: string;
    /**
     * 
     * @type {Array<OrderRefund>}
     * @memberof MenuOrderOwn
     */
    refunds?: Array<OrderRefund>;
}

export function MenuOrderOwnFromJSON(json: any): MenuOrderOwn {
    return MenuOrderOwnFromJSONTyped(json, false);
}

export function MenuOrderOwnFromJSONTyped(json: any, ignoreDiscriminator: boolean): MenuOrderOwn {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'business': json['business'],
        'subtotal': json['subtotal'],
        'stripeTotal': json['stripeTotal'],
        'serviceFees': !exists(json, 'serviceFees') ? undefined : ((json['serviceFees'] as Array<any>).map(MenuOrderServiceFeeFromJSON)),
        'tax': json['tax'],
        'deliveryCharge': json['deliveryCharge'],
        'tipDelivery': !exists(json, 'tipDelivery') ? undefined : json['tipDelivery'],
        'discounts': !exists(json, 'discounts') ? undefined : ((json['discounts'] as Array<any>).map(MenuOrderDiscountFromJSON)),
        'transactionHash': !exists(json, 'transactionHash') ? undefined : json['transactionHash'],
        'status': !exists(json, 'status') ? undefined : MenuOrderStatusFromJSON(json['status']),
        'address': AddressFromJSON(json['address']),
        'handoff': !exists(json, 'handoff') ? undefined : HandoffOptionsFromJSON(json['handoff']),
        'pickupCode': !exists(json, 'pickupCode') ? undefined : json['pickupCode'],
        'orderItems': ((json['orderItems'] as Array<any>).map(MenuOrderItemFromJSON)),
        'callbackComplete': json['callbackComplete'],
        'exceededSlippage': !exists(json, 'exceededSlippage') ? undefined : json['exceededSlippage'],
        'uberEatsUuid': !exists(json, 'uberEatsUuid') ? undefined : json['uberEatsUuid'],
        'orderUuid': !exists(json, 'orderUuid') ? undefined : json['orderUuid'],
        'refunds': !exists(json, 'refunds') ? undefined : ((json['refunds'] as Array<any>).map(OrderRefundFromJSON)),
    };
}

export function MenuOrderOwnToJSON(value?: MenuOrderOwn | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'business': value.business,
        'subtotal': value.subtotal,
        'stripeTotal': value.stripeTotal,
        'serviceFees': value.serviceFees === undefined ? undefined : ((value.serviceFees as Array<any>).map(MenuOrderServiceFeeToJSON)),
        'tax': value.tax,
        'deliveryCharge': value.deliveryCharge,
        'tipDelivery': value.tipDelivery,
        'discounts': value.discounts === undefined ? undefined : ((value.discounts as Array<any>).map(MenuOrderDiscountToJSON)),
        'transactionHash': value.transactionHash,
        'status': MenuOrderStatusToJSON(value.status),
        'address': AddressToJSON(value.address),
        'handoff': HandoffOptionsToJSON(value.handoff),
        'pickupCode': value.pickupCode,
        'orderItems': ((value.orderItems as Array<any>).map(MenuOrderItemToJSON)),
        'callbackComplete': value.callbackComplete,
        'exceededSlippage': value.exceededSlippage,
        'uberEatsUuid': value.uberEatsUuid,
        'orderUuid': value.orderUuid,
        'refunds': value.refunds === undefined ? undefined : ((value.refunds as Array<any>).map(OrderRefundToJSON)),
    };
}


