/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AutocompleteAddressResponseAddresses
 */
export interface AutocompleteAddressResponseAddresses {
    /**
     * 
     * @type {string}
     * @memberof AutocompleteAddressResponseAddresses
     */
    formattedAddress: string;
    /**
     * 
     * @type {string}
     * @memberof AutocompleteAddressResponseAddresses
     */
    placeId: string;
}

export function AutocompleteAddressResponseAddressesFromJSON(json: any): AutocompleteAddressResponseAddresses {
    return AutocompleteAddressResponseAddressesFromJSONTyped(json, false);
}

export function AutocompleteAddressResponseAddressesFromJSONTyped(json: any, ignoreDiscriminator: boolean): AutocompleteAddressResponseAddresses {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'formattedAddress': json['formattedAddress'],
        'placeId': json['placeId'],
    };
}

export function AutocompleteAddressResponseAddressesToJSON(value?: AutocompleteAddressResponseAddresses | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'formattedAddress': value.formattedAddress,
        'placeId': value.placeId,
    };
}


