/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ImportBusinessBodyItem
 */
export interface ImportBusinessBodyItem {
    /**
     * 
     * @type {string}
     * @memberof ImportBusinessBodyItem
     */
    devour_merchant_id?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportBusinessBodyItem
     */
    olo_vendor_id?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportBusinessBodyItem
     */
    chowly_api_key?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportBusinessBodyItem
     */
    merchant_account_email?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportBusinessBodyItem
     */
    merchant_parent_id?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportBusinessBodyItem
     */
    merchant_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportBusinessBodyItem
     */
    merchant_internal_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportBusinessBodyItem
     */
    merchant_description?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportBusinessBodyItem
     */
    merchant_email?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportBusinessBodyItem
     */
    merchant_phone?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportBusinessBodyItem
     */
    merchant_address_line1?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportBusinessBodyItem
     */
    merchant_address_line2?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportBusinessBodyItem
     */
    merchant_address_city?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportBusinessBodyItem
     */
    merchant_address_state?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportBusinessBodyItem
     */
    merchant_address_zipcode?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportBusinessBodyItem
     */
    merchant_address_country?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportBusinessBodyItem
     */
    merchant_address_longitude?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportBusinessBodyItem
     */
    merchant_address_latitude?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportBusinessBodyItem
     */
    merchant_address_place_id?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportBusinessBodyItem
     */
    merchant_timezone?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportBusinessBodyItem
     */
    merchant_brands?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportBusinessBodyItem
     */
    merchant_categories?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportBusinessBodyItem
     */
    merchant_prep_time?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportBusinessBodyItem
     */
    merchant_handoff?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportBusinessBodyItem
     */
    merchant_delivery_distance?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportBusinessBodyItem
     */
    merchant_brand_size?: string;
}

export function ImportBusinessBodyItemFromJSON(json: any): ImportBusinessBodyItem {
    return ImportBusinessBodyItemFromJSONTyped(json, false);
}

export function ImportBusinessBodyItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImportBusinessBodyItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'devour_merchant_id': !exists(json, 'devour_merchant_id') ? undefined : json['devour_merchant_id'],
        'olo_vendor_id': !exists(json, 'olo_vendor_id') ? undefined : json['olo_vendor_id'],
        'chowly_api_key': !exists(json, 'chowly_api_key') ? undefined : json['chowly_api_key'],
        'merchant_account_email': !exists(json, 'merchant_account_email') ? undefined : json['merchant_account_email'],
        'merchant_parent_id': !exists(json, 'merchant_parent_id') ? undefined : json['merchant_parent_id'],
        'merchant_name': !exists(json, 'merchant_name') ? undefined : json['merchant_name'],
        'merchant_internal_name': !exists(json, 'merchant_internal_name') ? undefined : json['merchant_internal_name'],
        'merchant_description': !exists(json, 'merchant_description') ? undefined : json['merchant_description'],
        'merchant_email': !exists(json, 'merchant_email') ? undefined : json['merchant_email'],
        'merchant_phone': !exists(json, 'merchant_phone') ? undefined : json['merchant_phone'],
        'merchant_address_line1': !exists(json, 'merchant_address_line1') ? undefined : json['merchant_address_line1'],
        'merchant_address_line2': !exists(json, 'merchant_address_line2') ? undefined : json['merchant_address_line2'],
        'merchant_address_city': !exists(json, 'merchant_address_city') ? undefined : json['merchant_address_city'],
        'merchant_address_state': !exists(json, 'merchant_address_state') ? undefined : json['merchant_address_state'],
        'merchant_address_zipcode': !exists(json, 'merchant_address_zipcode') ? undefined : json['merchant_address_zipcode'],
        'merchant_address_country': !exists(json, 'merchant_address_country') ? undefined : json['merchant_address_country'],
        'merchant_address_longitude': !exists(json, 'merchant_address_longitude') ? undefined : json['merchant_address_longitude'],
        'merchant_address_latitude': !exists(json, 'merchant_address_latitude') ? undefined : json['merchant_address_latitude'],
        'merchant_address_place_id': !exists(json, 'merchant_address_place_id') ? undefined : json['merchant_address_place_id'],
        'merchant_timezone': !exists(json, 'merchant_timezone') ? undefined : json['merchant_timezone'],
        'merchant_brands': !exists(json, 'merchant_brands') ? undefined : json['merchant_brands'],
        'merchant_categories': !exists(json, 'merchant_categories') ? undefined : json['merchant_categories'],
        'merchant_prep_time': !exists(json, 'merchant_prep_time') ? undefined : json['merchant_prep_time'],
        'merchant_handoff': !exists(json, 'merchant_handoff') ? undefined : json['merchant_handoff'],
        'merchant_delivery_distance': !exists(json, 'merchant_delivery_distance') ? undefined : json['merchant_delivery_distance'],
        'merchant_brand_size': !exists(json, 'merchant_brand_size') ? undefined : json['merchant_brand_size'],
    };
}

export function ImportBusinessBodyItemToJSON(value?: ImportBusinessBodyItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'devour_merchant_id': value.devour_merchant_id,
        'olo_vendor_id': value.olo_vendor_id,
        'chowly_api_key': value.chowly_api_key,
        'merchant_account_email': value.merchant_account_email,
        'merchant_parent_id': value.merchant_parent_id,
        'merchant_name': value.merchant_name,
        'merchant_internal_name': value.merchant_internal_name,
        'merchant_description': value.merchant_description,
        'merchant_email': value.merchant_email,
        'merchant_phone': value.merchant_phone,
        'merchant_address_line1': value.merchant_address_line1,
        'merchant_address_line2': value.merchant_address_line2,
        'merchant_address_city': value.merchant_address_city,
        'merchant_address_state': value.merchant_address_state,
        'merchant_address_zipcode': value.merchant_address_zipcode,
        'merchant_address_country': value.merchant_address_country,
        'merchant_address_longitude': value.merchant_address_longitude,
        'merchant_address_latitude': value.merchant_address_latitude,
        'merchant_address_place_id': value.merchant_address_place_id,
        'merchant_timezone': value.merchant_timezone,
        'merchant_brands': value.merchant_brands,
        'merchant_categories': value.merchant_categories,
        'merchant_prep_time': value.merchant_prep_time,
        'merchant_handoff': value.merchant_handoff,
        'merchant_delivery_distance': value.merchant_delivery_distance,
        'merchant_brand_size': value.merchant_brand_size,
    };
}


