/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
    TimeStamps,
    TimeStampsFromJSON,
    TimeStampsFromJSONTyped,
    TimeStampsToJSON,
} from './';

/**
 * An admin can create one of these models to set an amount in USD a referrer will get when a user who has referred spends a certain amount of money.
 * @export
 * @interface ReferralRewardThreshold
 */
export interface ReferralRewardThreshold {
    /**
     * 
     * @type {boolean}
     * @memberof ReferralRewardThreshold
     */
    disabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReferralRewardThreshold
     */
    hidden: boolean;
    /**
     * 
     * @type {number}
     * @memberof ReferralRewardThreshold
     */
    expiration?: number;
    /**
     * The amount in USD given to the referrer when the minimum requirement is met.
     * @type {number}
     * @memberof ReferralRewardThreshold
     */
    amount: number;
    /**
     * The threshold marking the minimum amount that a user must spend to give the referrer the reward.
     * @type {number}
     * @memberof ReferralRewardThreshold
     */
    minPaymentRequired: number;
    /**
     * 
     * @type {string}
     * @memberof ReferralRewardThreshold
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof ReferralRewardThreshold
     */
    createdAt: number;
    /**
     * 
     * @type {number}
     * @memberof ReferralRewardThreshold
     */
    updatedAt: number;
}

export function ReferralRewardThresholdFromJSON(json: any): ReferralRewardThreshold {
    return ReferralRewardThresholdFromJSONTyped(json, false);
}

export function ReferralRewardThresholdFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReferralRewardThreshold {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'disabled': json['disabled'],
        'hidden': json['hidden'],
        'expiration': !exists(json, 'expiration') ? undefined : json['expiration'],
        'amount': json['amount'],
        'minPaymentRequired': json['minPaymentRequired'],
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
    };
}

export function ReferralRewardThresholdToJSON(value?: ReferralRewardThreshold | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'disabled': value.disabled,
        'hidden': value.hidden,
        'expiration': value.expiration,
        'amount': value.amount,
        'minPaymentRequired': value.minPaymentRequired,
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
    };
}


