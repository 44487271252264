import {ReactElement} from "react";
import Skeleton from "react-loading-skeleton";

interface Props {
    noOfCards: number;
}
function GoFrensNftGroupingsSkeleton(props: Props): ReactElement {
    return (
        <>
            {Array.from({length: props.noOfCards}, (_, i) => <div key={i} className="nft-grouping-skeleton-card">
                <div className="nft-grouping-card nft-grouping-card_skeleton-adjust">
                    <div className="nft-grouping-card_assets">
                        <div
                            className="nft-grouping-card_assets_hero react-loading-skeleton"
                            style={{backgroundColor: "#ebebeb"}}
                        >
                        </div>

                        <div className="nft-grouping-card_assets_icon-container">
                            <div
                                className="nft-grouping-card_assets_icon-container_icon
                                    nft-grouping-card_assets_icon-container_skeleton-adjust
                                    react-loading-skeleton"
                                style={{backgroundColor: "#ebebeb"}}
                            />
                        </div>
                    </div>

                    <div className="nft-grouping-card_details">
                        <h6 className="nft-grouping-card_details_title">
                            <Skeleton/>
                        </h6>

                        <p className="nft-grouping-card_details_count">
                            <Skeleton/>
                        </p>
                    </div>
                </div>
            </div>)}
        </>
    );
}

export default GoFrensNftGroupingsSkeleton;