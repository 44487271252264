/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BusinessTaxonomy,
    BusinessTaxonomyFromJSON,
    BusinessTaxonomyFromJSONTyped,
    BusinessTaxonomyToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetBusinessTaxonomiesResponse
 */
export interface GetBusinessTaxonomiesResponse {
    /**
     * 
     * @type {Array<BusinessTaxonomy>}
     * @memberof GetBusinessTaxonomiesResponse
     */
    businessTaxonomies: Array<BusinessTaxonomy>;
}

export function GetBusinessTaxonomiesResponseFromJSON(json: any): GetBusinessTaxonomiesResponse {
    return GetBusinessTaxonomiesResponseFromJSONTyped(json, false);
}

export function GetBusinessTaxonomiesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetBusinessTaxonomiesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'businessTaxonomies': ((json['businessTaxonomies'] as Array<any>).map(BusinessTaxonomyFromJSON)),
    };
}

export function GetBusinessTaxonomiesResponseToJSON(value?: GetBusinessTaxonomiesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'businessTaxonomies': ((value.businessTaxonomies as Array<any>).map(BusinessTaxonomyToJSON)),
    };
}


