import {PropsWithChildren, ReactElement, useContext} from "react";
import {Restaurant} from "@devour/client";
import Skeleton from "react-loading-skeleton";
import {BsDot} from "react-icons/bs";
import RestaurantHoursSummary from "@/pages/restaurants/components/RestaurantHoursSummary";
import RestaurantPageTaxonomies from "@/pages/restaurants/components/RestaurantPageTaxonomies";
import {RestaurantContext} from "@/pages/restaurants/context/RestaurantContext";
import RestaurantHandoffOptions from "@/pages/restaurants/components/RestaurantHandoffOptions";
import RestaurantHandoffOptionsDetails from "@/pages/restaurants/components/RestaurantHandoffOptionsDetails";
import CheckoutDetailsHandoff from "@/components/checkout/checkoutDetails/CheckoutDetailsHandoff";
import {isDesktop} from "react-device-detect";

interface Props {
    data: Restaurant;
}

function RestaurantHeaderWithHandoffOptions(props: PropsWithChildren<Props>): ReactElement {
    const {menuOrder} = useContext(RestaurantContext);

    return (
        <>
            <div className="restaurant-header-handoff-opts">

                <div className="restaurant-header-handoff-opts_info">

                    <div className="restaurant-page_title">
                        {props.data
                            ? <h2>{props.data?.name}</h2>
						 : <h2 className="restaurant-menu-page-content-skeleton_title"><Skeleton/></h2>
                        }
                    </div>

                    <div className="restaurant-page_hours">
                        {!props.data
                            ? <div className="restaurant-menu-page-hour-skeleton react-loading-skeleton"/>
                            : <>
                                {!props?.data?.isOpen &&
                                <>
									    <div className="restaurant-page_hours_now-closed-text">
											Now Closed
									    </div>
									    <BsDot/>
                                </>
                                }
                                <RestaurantHoursSummary restaurant={props.data}/>
                            </>
                        }
                    </div>

                    <div className="restaurant-page_details">
                        <RestaurantPageTaxonomies/>
                    </div>
                </div>

                {!isDesktop && props?.data?.isOpen && props.children}
                <div className="restaurant-header-handoff-opts_handoff">
                    <div className="restaurant-handoff-options">
                        {menuOrder
                            ? <CheckoutDetailsHandoff
                                backToRestaurantPageOnAddressUpdate={false}
                                menuOrderId={menuOrder.id}
                            />
						 : <RestaurantHandoffOptions/>
                        }
                    </div>
                    <RestaurantHandoffOptionsDetails/>
                </div>
            </div>

            {isDesktop && props?.data?.isOpen && props.children}

        </>
    );
}

export default RestaurantHeaderWithHandoffOptions;
