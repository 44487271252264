import {ReactElement, useEffect, useState} from "react";
import {UtilsApi, Address} from "@devour/client";
import {useParams} from "react-router";
import FrameButton from "../../../components/buttons/FrameButton";
import {FaTwitter} from "react-icons/fa";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {toggleLoginOpen} from "@/redux/meta/metaActions";

function RestaurantSearchNoResults(): ReactElement {

    const dispatch = useDispatch();
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const {placeId} = useParams<{ placeId: string; }>();
    const [
        address,
        setAddress,
    ] = useState<Address>();

    useEffect(() => {
        getAddress();
    }, [placeId]);

    async function getAddress(): Promise<void> {
        if (placeId) {
            const tempAddress = await new UtilsApi().getAddressFromPlaceId({
                placeId: placeId,
            });
            setAddress(tempAddress);
        }
    }

    return (
        <div className="restaurants-no-search-results">
            <img
                src={`${import.meta.env.VITE_CDN_URL}/images/DevourGO-NoRestaurants_AdobeExpress.gif`}
                alt="No restaurants in your area"
            />
            <h2>Sorry, there are no restaurants available in your area!</h2>
            <div className="restaurants-no-search-results_tweetbox">
                <h5>Want DevourGO in your city?</h5>
                <p>Tweet us a request to add your city now!</p>
                <FrameButton
                    color="twitter"
                    size="normal"
                    leftIcon={FaTwitter}
                    href={`https://twitter.com/intent/tweet?text=Hey @godevour, please open delivery to ${address?.locality}, ${address?.administrativeArea} asap - we're hungry over here! devourgo.io`}
                >
                    <span> Tweet at us!</span>
                </FrameButton>
            </div>

            <div className="restaurants-no-search-results_joinbox">
                <h4>Join GoVIP</h4>
                {fullToken
                    ? <p>
                        Spin, Share & Play in GoVIP
                        <br/>
                        Jump over to GoVIP to level up your account to win {import.meta.env.VITE_TOKEN_NAME},
                        rewards, prizes and bragging rights!
                    </p>
                    : <p>
                        Until DevourGO lands in your city, join GoVIP! Start leveling up your account to win {import.meta.env.VITE_TOKEN_NAME},
                        prizes and bragging rights!
                    </p>
                }

                {fullToken
                    ? <FrameButton
                        color="purple"
                        size="large"
                        to="/go-vip-dashboard"
                    >
                        GoVIP
                    </FrameButton>
                    : <FrameButton
                        color="purple"
                        size="large"
                        onClick={() => dispatch(toggleLoginOpen(true))}
                    >
                        Join GoVIP
                    </FrameButton>
                }
            </div>
        </div>
    );
}

export default RestaurantSearchNoResults;
