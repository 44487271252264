import {
    ChangeEvent,
    ReactElement,
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import {
    Address,
    AddressBook,
    BrandMapColorTheme,
    HandoffOptions,
    PaginationInfo,
    RestaurantSearchResult,
} from "@devour/client";
import SearchInput from "@/components/inputs/SearchInput";
import BrandMapLandingPageGoogleMapsWrapper from "@/components/brands/BrandMapLandingPageGoogleMapsWrapper";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {toggleOrderHandoff} from "@/redux/meta/metaActions";
import MapLandingBusinessListCard from "@/components/MapLandingBusinessListCard";
import HandoffToggle from "@/components/HandoffToggle";
import {useSearchRestaurants} from "@/hooks/useSearchRestaurants";
import FramePaginator, {FrontendPagination} from "@/components/paginator/FramePaginator";
import _, {omit} from "lodash";
import {framePaginationLimiterOptions} from "@/components/paginator/FramePaginatorLimiter";
import {useParams} from "react-router";
import {useGetBrandMap} from "@/hooks/useGetBrandMap";
import {BrandMapContext, BrandMapStates} from "@/pages/brandMap/context/BrandMapContext";
import {getDefaultMapZoom, getMapCenter} from "@/components/brands/BrandMapLocationsSearch";

const defaultFrontendPagination: FrontendPagination = {
    offset: 0,
    limit: framePaginationLimiterOptions?.[0]?.value as number,
};

interface Props {
    toggleEmailModal?: (showModal: boolean) => void;
    isInitialState?: boolean;
    userAddress?: Address | AddressBook;
}

function BrandMapMarketplaceSearch(props: Props): ReactElement {
    const {slug} = useParams<{ slug: string }>();
    const {userAddress} = props;
    const dispatch = useDispatch();
    const handoff = useSelector((store: IStore) => store.metaStore.handoff);
    const placeId = useSelector((store: IStore) => store.metaStore.lastSearchedPlaceId);
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);

    const {selectedRestaurant, setSelectedRestaurant, setBrandMapState} = useContext(BrandMapContext);
    const [searchValue, setSearchValue] = useState<string>("");
    const [querySearchFilter, setQuerySearchFilter] = useState<string>("");
    const [frontendPagination, setFrontendPagination] = useState<FrontendPagination>(defaultFrontendPagination);

    const {data: brandMap} = useGetBrandMap(slug);
    const {data: restaurantResults, isFetching: isRestaurantDataLoading} = useSearchRestaurants(
        placeId,
        handoff,
        frontendPagination.offset,
        frontendPagination.limit,
        querySearchFilter,
    );

    const businessesToRender = restaurantResults?.restaurantsPaginated || [];
    const mapRef = useRef<google.maps.Map>(null);
    const debounceLoadData = useCallback(_.debounce(updateSearchFilter, 1000), []);

    // Refs for scrolling the active business list card to the top of the list
    const businessRefs = useRef<(HTMLDivElement | null)[]>([]);
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const activeRestaurant = businessesToRender?.find(b => b._id === selectedRestaurant);

    useEffect(() => {
        const activeIndex = businessesToRender?.findIndex(b => b._id === selectedRestaurant);

        // Selected business exists in the list of businesses
        if (activeIndex !== -1 && businessRefs.current[activeIndex]) {
            const activeElement = businessRefs.current[activeIndex];
            const scrollContainer = scrollContainerRef.current;

            // Scroll the business card to the top of the scrollable container
            scrollContainer.scrollTo({
                top: activeElement.offsetTop - scrollContainer.offsetTop,
                behavior: "smooth",
            });
        }
    }, [selectedRestaurant, businessesToRender]);

    useEffect(() => {
        void debounceLoadData(searchValue);
    }, [searchValue]);

    useEffect(() => {
        if (placeId) {
            setFrontendPagination(defaultFrontendPagination);
            setSearchValue("");
            setQuerySearchFilter("");
        }
    }, [placeId, handoff]);

    useEffect(() => {
        setFrontendPagination({
            ...frontendPagination,
            offset: 0,
        });
    }, [querySearchFilter]);

    useEffect(() => {
        if (!mapRef.current) {
            return;
        }
        if (activeRestaurant) {
            mapRef.current.setZoom(15);
            mapRef.current.panTo(getMapCenter(activeRestaurant.address));
        } else if (businessesToRender[0]) {
            mapRef.current.setZoom(getDefaultMapZoom(businessesToRender[0].address));
        }
    }, [activeRestaurant?._id]);

    function toggleHandoff(option: HandoffOptions): void {
        dispatch(toggleOrderHandoff(option));
    }

    function updateSearchFilter(searchValue: string): void {
        setQuerySearchFilter(searchValue);
    }

    function handleSearchChange(e: ChangeEvent<HTMLInputElement>): void {
        setSearchValue(e.target.value);
    }

    function clearSearch(): void {
        setSearchValue("");
    }

    function renderBusiness(business: RestaurantSearchResult, i: number): ReactNode {
        return (
            <div
                key={`business_${i}`}
                className="brand-map-locations_map_list_item"
                ref={el => businessRefs.current[i] = el}
            >
                <MapLandingBusinessListCard
                    business={business}
                    onSelectClick={onRestaurantSelect}
                    isActive={selectedRestaurant === business._id}
                    brandMap={brandMap}
                    onOpenRestaurantMenu={onOpenRestaurantMenu}
                    isInitialState={props.isInitialState}
                />
            </div>
        );
    }

    function onOpenRestaurantMenu(): void {
        if (!fullToken) {
            props.toggleEmailModal?.(true);
        } else {
            setBrandMapState(BrandMapStates.ORDER_IN_PROGRESS);
        }
    }

    function onRestaurantSelect(business: RestaurantSearchResult) {
        setSelectedRestaurant(business._id === selectedRestaurant ? null : business._id);
    }

    function saveMapRef(_mapRef: google.maps.Map): void {
        mapRef.current = _mapRef;
    }

    function onPaginationChange(newPagination: PaginationInfo): void {
        setFrontendPagination(newPagination);
    }

    function renderMarketplaceSearchSkeleton(): ReactElement {
        return (
            <>
                <div className="brand-map-locations_map_render react-loading-skeleton"/>

                <div className="brand-map-locations_map_list">
                    {Array.from({length: 3}, (_, i) => <div
                        key={`business_${i}`}
                        className="brand-map-locations_map_list_item react-loading-skeleton"
                    />)}
                </div>
            </>
        );
    }

    return (
        <>
            <div className="brand-map-locations_map brand-map-locations_map_marketplace">
                <div className="brand-map-locations_map_handoff">
                    <HandoffToggle onHandoffToggle={toggleHandoff}/>
                </div>

                <div className="brand-map-locations_map_search">
                    <SearchInput
                        placeholder="Search a restaurant or dishes"
                        value={searchValue}
                        onChange={handleSearchChange}
                        maxLength={15}
                        onClear={clearSearch}
                    />
                </div>

                {isRestaurantDataLoading
                    ? renderMarketplaceSearchSkeleton()
                    : <>
                        <div className="brand-map-locations_map_render">
                            <BrandMapLandingPageGoogleMapsWrapper
                                activeBusinessId={selectedRestaurant}
                                businessesToRender={businessesToRender}
                                brandMap={brandMap}
                                saveMapRef={saveMapRef}
                                onMapPinClick={onRestaurantSelect}
                                center={getMapCenter(businessesToRender[0]?.address || userAddress)}
                                defaultZoom={getDefaultMapZoom(businessesToRender[0]?.address || userAddress)}
                            />
                        </div>

                        <div className="brand-map-locations_map_list" ref={scrollContainerRef}>
                            {businessesToRender?.length > 0
                                ? <>
                                    {businessesToRender.map(renderBusiness)}
                                    <FramePaginator
                                        {...restaurantResults?.paginationInfo}
                                        {...omit(frontendPagination, "frontendRenderKey")}
                                        onPaginationChange={onPaginationChange}
                                        showPaginatorLimit={false}
                                        theme={brandMap.colorTheme === BrandMapColorTheme.DARK ? "light" : "dark"}
                                    />
                                </> : <div className="brand-map-locations_map_list_empty">
                                    <p>No Businesses that match your filter</p>
                                </div>
                            }
                        </div>
                    </>

                }
            </div>
        </>
    );
}

export default BrandMapMarketplaceSearch;
