/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FrontendErrorBody
 */
export interface FrontendErrorBody {
    /**
     * 
     * @type {string}
     * @memberof FrontendErrorBody
     */
    route?: string;
    /**
     * 
     * @type {string}
     * @memberof FrontendErrorBody
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof FrontendErrorBody
     */
    stack?: string;
    /**
     * 
     * @type {string}
     * @memberof FrontendErrorBody
     */
    search?: string;
}

export function FrontendErrorBodyFromJSON(json: any): FrontendErrorBody {
    return FrontendErrorBodyFromJSONTyped(json, false);
}

export function FrontendErrorBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): FrontendErrorBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'route': !exists(json, 'route') ? undefined : json['route'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'stack': !exists(json, 'stack') ? undefined : json['stack'],
        'search': !exists(json, 'search') ? undefined : json['search'],
    };
}

export function FrontendErrorBodyToJSON(value?: FrontendErrorBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'route': value.route,
        'message': value.message,
        'stack': value.stack,
        'search': value.search,
    };
}


