import {PropsWithChildren, ReactElement} from "react";
import classNames from "classnames";

interface Props {
    className?: string;
    bodyRef?: (node: HTMLDivElement) => void;
}

function FrameModalBody(props: PropsWithChildren<Props>): ReactElement {
    return (
        <div
            ref={props.bodyRef}
            className={classNames("frame-one-modal_body", props.className)}>
            {props.children}
        </div>
    );
}

export default FrameModalBody;
