import {FC, ReactElement, ReactNode, useEffect, useState} from "react";
import FrameOneAutoPanel from "./autoPanelComponents/FrameOneAutoPanel";
import FrameAutoPanelBody from "./autoPanelComponents/FrameAutoPanelBody";
import {GoVipGameData} from "../goVip/goVipText";
import GoVipModalHeader from "../goVip/GoVipModalHeader";
import GoVipPlay2EarnRewardsCard from "../goVip/GoVipPlay2EarnRewardsCard";
import FrameAutoPanelFooter from "./autoPanelComponents/FrameAutoPanelFooter";
import FrameButton from "../buttons/FrameButton";
import {isDesktop, isTablet} from "react-device-detect";
import {FiX} from "react-icons/fi";
import FrameOneModal from "./modalComponents/FrameOneModal";
import {GamesApi} from "@devour/client";
import {useDispatch, useSelector} from "react-redux";
import getConfig from "../../utils/getConfig";
import {addError, toggleLoginOpen} from "@/redux/meta/metaActions";
import {IStore} from "@/redux/defaultStore";
import Toast from "../Toast";
import {AiTwotoneLock} from "react-icons/ai";
import {useNavigate} from "react-router-dom";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    gameData: GoVipGameData;
    gameContainer: FC;
    requiredLevel: number;
}

function GoVipGameRewardsModal(props: Props): ReactElement {
    const GameContainerComponent = props.gameContainer;
    const [
        isGameOpen,
        setIsGameOpen,
    ] = useState<boolean>(false);

    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const accountLevel = useSelector((store: IStore) => store.metaStore.accountLevel);
    const dispatch = useDispatch();

    const disabled = accountLevel == null || accountLevel.level < props.requiredLevel;

    const [
        showToast,
        setShowToast,
    ] = useState<boolean>(false);
    const [
        completedLevels,
        setCompletedLevels,
    ] = useState<Array<number>>(undefined);
    const history = useNavigate();

    async function handleToastDismissal() {
        setShowToast(false);
    }

    async function getCompletedLevels() {
        try {
            const levels = await new GamesApi(getConfig(fullToken)).getCompletedLevels({
                id: props.gameData.game,
            });

            setCompletedLevels(levels.completedLevels);
        } catch (e) {
            dispatch(await addError(e));
        }
    }

    useEffect(() => {
        if (props.isOpen && fullToken) {
            void getCompletedLevels();

        } else {
            if (fullToken) {
                // to avoid the users seeing the spinner on modal close
                setTimeout(() => setCompletedLevels(undefined), 500);
            } else {
                setCompletedLevels([]);
            }
        }

    }, [props.isOpen]);

    function renderLevel(stage: string, index: number): ReactNode {
        return (
            <GoVipPlay2EarnRewardsCard
                reward={props.gameData.reward}
                rewardSubtitle={props.gameData.rewardSubtitle}
                stage={stage}
                isDisabled={disabled}
                isCompleted={props.gameData.game === "Rally Champion"
                    ? completedLevels.length === 10 // there's only 1 reward row for this game
                    : completedLevels.includes(index)
                }
                toggle={() => {
                    if (disabled) {
                        setShowToast(true);
                    } else {
                        setIsGameOpen(!isGameOpen);
                    }
                }}
                key={`go-vip-game-rewards-card-${props.gameData.game}-${index}`}
            />
        );
    }

    return (
        <>
            <Toast
                duration={3000}
                message={fullToken
                    ? "Level up more to access this game!"
                    : "Join DevourGO and start your GoVIP Journey!"}
                isOpen={showToast}
                showButton={true}
                buttonMessage={fullToken
                    ? "Dismiss"
                    : "Sign Up"}
                buttonFunction={() => {
                    if (!fullToken) {
                        props.onClose();
                        dispatch(toggleLoginOpen(true));
                    }
                }}
                onDismiss={handleToastDismissal}
            />
            <FrameOneModal
                isOpen={isGameOpen}
                contentClassName="game-thumbnail_game-modal"
                toggle={() => {
                    setIsGameOpen(!isGameOpen);
                }}
            >
                <GameContainerComponent/>

                {!isDesktop &&
                <FiX
                    className="game-thumbnail_game-modal_exit-button"
                    onClick={() => setIsGameOpen(!isGameOpen)}
                />
                }
            </FrameOneModal>

            <FrameOneAutoPanel
                isOpen={props.isOpen}
                toggle={props.onClose}
                contentClassName="pizza-cafe-level-details-modal"
                size="sm"
                maxHeight={props.gameData.game !== "Rally Champion"}
            >
                <GoVipModalHeader
                    title={props.gameData.game}
                    subtitle={props.gameData.description}
                    toggle={props.onClose}
                    thumbnailUrl={props.gameData.thumbnailUrl}
                    isDisabled={disabled}
                />

                <FrameAutoPanelBody className="pizza-cafe-level-details-modal_body">
                    {completedLevels
                        ? <div className="pizza-cafe-level-details-modal_body_levels">
                            <div className="pizza-cafe-level-details-modal_body_levels_container">
                                {props.gameData.levelsData.map((stage, index) => {
                                    return renderLevel(stage, index);
                                })}
                            </div>

                        </div>
                        : <div className="go-vip-share2earn_spinner-container">
                            <div className="spinner"/>
                        </div>
                    }
                </FrameAutoPanelBody>

                <FrameAutoPanelFooter>
                    <FrameButton
                        color="purple"
                        size="large"
                        className="pizza-cafe-level-details-modal_body_button"
                        onClick={disabled
                            ? undefined
                            : () => setIsGameOpen(!isGameOpen)}
                        leftIcon={disabled
                            ? AiTwotoneLock
                            : undefined}
                        forwardProps={{disabled}}
                    >
                        {disabled
                            ? fullToken
                                ? `${isDesktop || isTablet
                                    ? "Requires"
                                    : ""} Level ${props.requiredLevel}`
                                : "Join DevourGO"
                            : "Play Now"}
                    </FrameButton>
                </FrameAutoPanelFooter>

            </FrameOneAutoPanel>
        </>
    );
}

export default GoVipGameRewardsModal;
