import {ReactElement, MouseEvent, useContext} from "react";
import {MenuItemSearchEssential} from "@devour/client";
import classNames from "classnames";
import {htmlDecode} from "@/utils/htmlDecode";
import {RestaurantContext} from "@/pages/restaurants/context/RestaurantContext";

interface Props {
    menuItem: MenuItemSearchEssential;
}

function MenuItemCardPreload(props: Props): ReactElement {

    const {setActiveMenuItemId} = useContext(RestaurantContext);

    function cardOnClick(event: MouseEvent<HTMLDivElement>): void {
        event.stopPropagation();
        setActiveMenuItemId?.(props.menuItem.id || "");
    }

    if (!props.menuItem) {
        return null;
    }

    return (
        <div
            className="menu-item-card"
            onClick={cardOnClick}
        >

            <div className="menu-item-card_left">
                <div className="menu-item-card_header">
                    <div className="menu-item-card_name">
                        {htmlDecode(props.menuItem?.name)}
                    </div>
                </div>

                <div className="menu-item-card_description">
                    {htmlDecode(props.menuItem.description)}
                </div>

                <div className={classNames("menu-item-card_price", {
                    "is-free": props.menuItem.price <= 0,
                })}
                >
                    {props.menuItem.price > 0
                        ? `$${props.menuItem.price.toFixed(2)}`
                        : "$0.00"}
                </div>

            </div>

            <div className="menu-item-card_right">
                {props.menuItem.image &&
                <div className="menu-item-card_image">
					    <div className="menu-item-card_image_inner">
					        <img
					            src={props.menuItem.image}
					            alt={props.menuItem?.name}
					            onError={({currentTarget}) => {
					                currentTarget.onerror = null; // prevents looping
					                currentTarget.src = import.meta.env.VITE_CDN_URL + "/images/placeholderitem.webp";
					            }}
					        />
					    </div>
                </div>
                }

            </div>

        </div>
    );
}

export default MenuItemCardPreload;
