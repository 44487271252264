/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MenuOrderDiscount,
    MenuOrderDiscountFromJSON,
    MenuOrderDiscountFromJSONTyped,
    MenuOrderDiscountToJSON,
} from './';

/**
 * 
 * @export
 * @interface InlineResponse20014
 */
export interface InlineResponse20014 {
    /**
     * 
     * @type {Array<MenuOrderDiscount>}
     * @memberof InlineResponse20014
     */
    discounts: Array<MenuOrderDiscount>;
}

export function InlineResponse20014FromJSON(json: any): InlineResponse20014 {
    return InlineResponse20014FromJSONTyped(json, false);
}

export function InlineResponse20014FromJSONTyped(json: any, ignoreDiscriminator: boolean): InlineResponse20014 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'discounts': ((json['discounts'] as Array<any>).map(MenuOrderDiscountFromJSON)),
    };
}

export function InlineResponse20014ToJSON(value?: InlineResponse20014 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'discounts': ((value.discounts as Array<any>).map(MenuOrderDiscountToJSON)),
    };
}


