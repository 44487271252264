/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetWithdrawsResponseAllOf,
    GetWithdrawsResponseAllOfFromJSON,
    GetWithdrawsResponseAllOfFromJSONTyped,
    GetWithdrawsResponseAllOfToJSON,
    PaginatedResponse,
    PaginatedResponseFromJSON,
    PaginatedResponseFromJSONTyped,
    PaginatedResponseToJSON,
    PaginationInfo,
    PaginationInfoFromJSON,
    PaginationInfoFromJSONTyped,
    PaginationInfoToJSON,
    Withdraw,
    WithdrawFromJSON,
    WithdrawFromJSONTyped,
    WithdrawToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetWithdrawsResponse
 */
export interface GetWithdrawsResponse extends PaginatedResponse {
    /**
     * 
     * @type {Array<Withdraw>}
     * @memberof GetWithdrawsResponse
     */
    withdraws: Array<Withdraw>;
}

export function GetWithdrawsResponseFromJSON(json: any): GetWithdrawsResponse {
    return GetWithdrawsResponseFromJSONTyped(json, false);
}

export function GetWithdrawsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetWithdrawsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...PaginatedResponseFromJSONTyped(json, ignoreDiscriminator),
        'withdraws': ((json['withdraws'] as Array<any>).map(WithdrawFromJSON)),
    };
}

export function GetWithdrawsResponseToJSON(value?: GetWithdrawsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...PaginatedResponseToJSON(value),
        'withdraws': ((value.withdraws as Array<any>).map(WithdrawToJSON)),
    };
}


