/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Asset,
    AssetFromJSON,
    AssetFromJSONTyped,
    AssetToJSON,
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
    OrderDiscountCalculation,
    OrderDiscountCalculationFromJSON,
    OrderDiscountCalculationFromJSONTyped,
    OrderDiscountCalculationToJSON,
    OrderDiscountFrequencyQualifier,
    OrderDiscountFrequencyQualifierFromJSON,
    OrderDiscountFrequencyQualifierFromJSONTyped,
    OrderDiscountFrequencyQualifierToJSON,
    OrderDiscountOperator,
    OrderDiscountOperatorFromJSON,
    OrderDiscountOperatorFromJSONTyped,
    OrderDiscountOperatorToJSON,
    OrderDiscountOrigin,
    OrderDiscountOriginFromJSON,
    OrderDiscountOriginFromJSONTyped,
    OrderDiscountOriginToJSON,
    OrderDiscountType,
    OrderDiscountTypeFromJSON,
    OrderDiscountTypeFromJSONTyped,
    OrderDiscountTypeToJSON,
    TimeStamps,
    TimeStampsFromJSON,
    TimeStampsFromJSONTyped,
    TimeStampsToJSON,
} from './';

/**
 * Represents a single discount for orders in the system.
 * @export
 * @interface OrderDiscount
 */
export interface OrderDiscount {
    /**
     * 
     * @type {string}
     * @memberof OrderDiscount
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof OrderDiscount
     */
    createdAt: number;
    /**
     * 
     * @type {number}
     * @memberof OrderDiscount
     */
    updatedAt: number;
    /**
     * 
     * @type {string}
     * @memberof OrderDiscount
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDiscount
     */
    internalName?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDiscount
     */
    description?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderDiscount
     */
    businesses?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof OrderDiscount
     */
    priority: number;
    /**
     * 
     * @type {number}
     * @memberof OrderDiscount
     */
    amount: number;
    /**
     * 
     * @type {number}
     * @memberof OrderDiscount
     */
    amountMax: number;
    /**
     * 
     * @type {number}
     * @memberof OrderDiscount
     */
    subtotalMin: number;
    /**
     * 
     * @type {number}
     * @memberof OrderDiscount
     */
    expiresAt?: number;
    /**
     * 
     * @type {OrderDiscountType}
     * @memberof OrderDiscount
     */
    type: OrderDiscountType;
    /**
     * 
     * @type {OrderDiscountCalculation}
     * @memberof OrderDiscount
     */
    calculation: OrderDiscountCalculation;
    /**
     * 
     * @type {OrderDiscountOrigin}
     * @memberof OrderDiscount
     */
    origin: OrderDiscountOrigin;
    /**
     * 
     * @type {boolean}
     * @memberof OrderDiscount
     */
    isEnabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrderDiscount
     */
    isSingleUseUser: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrderDiscount
     */
    isDisplayedGoFrens: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrderDiscount
     */
    isSingleUseSystem: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrderDiscount
     */
    isStackablePromo: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrderDiscount
     */
    isFirstOrder: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrderDiscount
     */
    isReferralOnly: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderDiscount
     */
    nftGroupings?: Array<string>;
    /**
     * 
     * @type {OrderDiscountOperator}
     * @memberof OrderDiscount
     */
    nftOperator?: OrderDiscountOperator;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderDiscount
     */
    codes?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof OrderDiscount
     */
    maxRedemptions?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderDiscount
     */
    timesRedeemed?: number;
    /**
     * 
     * @type {OrderDiscountFrequencyQualifier}
     * @memberof OrderDiscount
     */
    useFrequencyQualifier?: OrderDiscountFrequencyQualifier;
    /**
     * 
     * @type {number}
     * @memberof OrderDiscount
     */
    useFrequency?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderDiscount
     */
    menuCategories?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderDiscount
     */
    menuItems?: Array<string>;
    /**
     * 
     * @type {Asset}
     * @memberof OrderDiscount
     */
    image?: Asset;
    /**
     * 
     * @type {boolean}
     * @memberof OrderDiscount
     */
    isBrandMapValid?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrderDiscount
     */
    isBrandMapAutoApplied?: boolean;
}

export function OrderDiscountFromJSON(json: any): OrderDiscount {
    return OrderDiscountFromJSONTyped(json, false);
}

export function OrderDiscountFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderDiscount {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'label': json['label'],
        'internalName': !exists(json, 'internalName') ? undefined : json['internalName'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'businesses': !exists(json, 'businesses') ? undefined : json['businesses'],
        'priority': json['priority'],
        'amount': json['amount'],
        'amountMax': json['amountMax'],
        'subtotalMin': json['subtotalMin'],
        'expiresAt': !exists(json, 'expiresAt') ? undefined : json['expiresAt'],
        'type': OrderDiscountTypeFromJSON(json['type']),
        'calculation': OrderDiscountCalculationFromJSON(json['calculation']),
        'origin': OrderDiscountOriginFromJSON(json['origin']),
        'isEnabled': json['isEnabled'],
        'isSingleUseUser': json['isSingleUseUser'],
        'isDisplayedGoFrens': json['isDisplayedGoFrens'],
        'isSingleUseSystem': json['isSingleUseSystem'],
        'isStackablePromo': json['isStackablePromo'],
        'isFirstOrder': json['isFirstOrder'],
        'isReferralOnly': json['isReferralOnly'],
        'nftGroupings': !exists(json, 'nftGroupings') ? undefined : json['nftGroupings'],
        'nftOperator': !exists(json, 'nftOperator') ? undefined : OrderDiscountOperatorFromJSON(json['nftOperator']),
        'codes': !exists(json, 'codes') ? undefined : json['codes'],
        'maxRedemptions': !exists(json, 'maxRedemptions') ? undefined : json['maxRedemptions'],
        'timesRedeemed': !exists(json, 'timesRedeemed') ? undefined : json['timesRedeemed'],
        'useFrequencyQualifier': !exists(json, 'useFrequencyQualifier') ? undefined : OrderDiscountFrequencyQualifierFromJSON(json['useFrequencyQualifier']),
        'useFrequency': !exists(json, 'useFrequency') ? undefined : json['useFrequency'],
        'menuCategories': !exists(json, 'menuCategories') ? undefined : json['menuCategories'],
        'menuItems': !exists(json, 'menuItems') ? undefined : json['menuItems'],
        'image': !exists(json, 'image') ? undefined : AssetFromJSON(json['image']),
        'isBrandMapValid': !exists(json, 'isBrandMapValid') ? undefined : json['isBrandMapValid'],
        'isBrandMapAutoApplied': !exists(json, 'isBrandMapAutoApplied') ? undefined : json['isBrandMapAutoApplied'],
    };
}

export function OrderDiscountToJSON(value?: OrderDiscount | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'label': value.label,
        'internalName': value.internalName,
        'description': value.description,
        'businesses': value.businesses,
        'priority': value.priority,
        'amount': value.amount,
        'amountMax': value.amountMax,
        'subtotalMin': value.subtotalMin,
        'expiresAt': value.expiresAt,
        'type': OrderDiscountTypeToJSON(value.type),
        'calculation': OrderDiscountCalculationToJSON(value.calculation),
        'origin': OrderDiscountOriginToJSON(value.origin),
        'isEnabled': value.isEnabled,
        'isSingleUseUser': value.isSingleUseUser,
        'isDisplayedGoFrens': value.isDisplayedGoFrens,
        'isSingleUseSystem': value.isSingleUseSystem,
        'isStackablePromo': value.isStackablePromo,
        'isFirstOrder': value.isFirstOrder,
        'isReferralOnly': value.isReferralOnly,
        'nftGroupings': value.nftGroupings,
        'nftOperator': OrderDiscountOperatorToJSON(value.nftOperator),
        'codes': value.codes,
        'maxRedemptions': value.maxRedemptions,
        'timesRedeemed': value.timesRedeemed,
        'useFrequencyQualifier': OrderDiscountFrequencyQualifierToJSON(value.useFrequencyQualifier),
        'useFrequency': value.useFrequency,
        'menuCategories': value.menuCategories,
        'menuItems': value.menuItems,
        'image': AssetToJSON(value.image),
        'isBrandMapValid': value.isBrandMapValid,
        'isBrandMapAutoApplied': value.isBrandMapAutoApplied,
    };
}


