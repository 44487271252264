/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateMenuItemBody
 */
export interface CreateMenuItemBody {
    /**
     * 
     * @type {string}
     * @memberof CreateMenuItemBody
     */
    category: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateMenuItemBody
     */
    images?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateMenuItemBody
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateMenuItemBody
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateMenuItemBody
     */
    sortOrder?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateMenuItemBody
     */
    price: number;
    /**
     * 
     * @type {number}
     * @memberof CreateMenuItemBody
     */
    taxRate: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateMenuItemBody
     */
    isAlcohol?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateMenuItemBody
     */
    isActive?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateMenuItemBody
     */
    nftGroupings?: Array<string>;
}

export function CreateMenuItemBodyFromJSON(json: any): CreateMenuItemBody {
    return CreateMenuItemBodyFromJSONTyped(json, false);
}

export function CreateMenuItemBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateMenuItemBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'category': json['category'],
        'images': !exists(json, 'images') ? undefined : json['images'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'sortOrder': !exists(json, 'sortOrder') ? undefined : json['sortOrder'],
        'price': json['price'],
        'taxRate': json['taxRate'],
        'isAlcohol': !exists(json, 'isAlcohol') ? undefined : json['isAlcohol'],
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'nftGroupings': !exists(json, 'nftGroupings') ? undefined : json['nftGroupings'],
    };
}

export function CreateMenuItemBodyToJSON(value?: CreateMenuItemBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'category': value.category,
        'images': value.images,
        'name': value.name,
        'description': value.description,
        'sortOrder': value.sortOrder,
        'price': value.price,
        'taxRate': value.taxRate,
        'isAlcohol': value.isAlcohol,
        'isActive': value.isActive,
        'nftGroupings': value.nftGroupings,
    };
}


