/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NftGroupingCategory,
    NftGroupingCategoryFromJSON,
    NftGroupingCategoryFromJSONTyped,
    NftGroupingCategoryToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetNftGroupingCategoryResponse
 */
export interface GetNftGroupingCategoryResponse {
    /**
     * 
     * @type {Array<NftGroupingCategory>}
     * @memberof GetNftGroupingCategoryResponse
     */
    nftGroupingCategories: Array<NftGroupingCategory>;
}

export function GetNftGroupingCategoryResponseFromJSON(json: any): GetNftGroupingCategoryResponse {
    return GetNftGroupingCategoryResponseFromJSONTyped(json, false);
}

export function GetNftGroupingCategoryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetNftGroupingCategoryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nftGroupingCategories': ((json['nftGroupingCategories'] as Array<any>).map(NftGroupingCategoryFromJSON)),
    };
}

export function GetNftGroupingCategoryResponseToJSON(value?: GetNftGroupingCategoryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nftGroupingCategories': ((value.nftGroupingCategories as Array<any>).map(NftGroupingCategoryToJSON)),
    };
}


