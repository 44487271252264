/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NftTracker,
    NftTrackerFromJSON,
    NftTrackerFromJSONTyped,
    NftTrackerToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetNftTrackersFromGroupingResponse
 */
export interface GetNftTrackersFromGroupingResponse {
    /**
     * 
     * @type {Array<NftTracker>}
     * @memberof GetNftTrackersFromGroupingResponse
     */
    nftTrackers?: Array<NftTracker>;
}

export function GetNftTrackersFromGroupingResponseFromJSON(json: any): GetNftTrackersFromGroupingResponse {
    return GetNftTrackersFromGroupingResponseFromJSONTyped(json, false);
}

export function GetNftTrackersFromGroupingResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetNftTrackersFromGroupingResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nftTrackers': !exists(json, 'nftTrackers') ? undefined : ((json['nftTrackers'] as Array<any>).map(NftTrackerFromJSON)),
    };
}

export function GetNftTrackersFromGroupingResponseToJSON(value?: GetNftTrackersFromGroupingResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nftTrackers': value.nftTrackers === undefined ? undefined : ((value.nftTrackers as Array<any>).map(NftTrackerToJSON)),
    };
}


