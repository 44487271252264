export const solanaInstructionsCopy = [
    {
        step: 1,
        text: "After clicking on the Connect button, you’ll be redirected to a new window.",
        imageUrl: `${import.meta.env.VITE_CDN_URL}/images/solana-wc-step1.webp`,
    },
    {
        step: 2,
        text: "If prompted, log in to the same account within the new window.",
        imageUrl: `${import.meta.env.VITE_CDN_URL}/images/solana-wc-step2.webp`,
    },
    {
        step: 3,
        text: "You’ll find yourself on the GoFriends page. Select the wallet you want to connect again.",
        imageUrl: `${import.meta.env.VITE_CDN_URL}/images/solana-wc-step3.webp`,
    },
    {
        step: 4,
        text: "You’re all set - follow the instructions on the screen to complete the connection!",
        imageUrl: `${import.meta.env.VITE_CDN_URL}/images/solana-wc-step4.webp`,
    },
];