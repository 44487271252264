import {useEffect, useState} from "react";
import {getNftInformationFromTokenUri, NftInformationFromTokenUriOutput} from "../utils/getNftInformationFromTokenUri";

export function useNftInformationFromUri(uri: string): [boolean, NftInformationFromTokenUriOutput] {

    const [
        loading,
        setLoading,
    ] = useState(false);
    const [
        information,
        setInformation,
    ] = useState<NftInformationFromTokenUriOutput>();

    useEffect(() => {
        async function getInfo(): Promise<void> {
            setLoading(true);
            try {
                const res = await getNftInformationFromTokenUri(uri);
                setInformation(res);
            } catch (e) {
            } finally {
                setLoading(false);
            }
        }

        if (uri != null) {
            getInfo().then()
                .catch();
        }
    }, [uri]);

    return [
        loading,
        information,
    ];
}
