/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiError,
    ApiErrorFromJSON,
    ApiErrorToJSON,
    CancelWithdrawBody,
    CancelWithdrawBodyFromJSON,
    CancelWithdrawBodyToJSON,
    CompleteWithdrawBody,
    CompleteWithdrawBodyFromJSON,
    CompleteWithdrawBodyToJSON,
    CreateWithdrawBody,
    CreateWithdrawBodyFromJSON,
    CreateWithdrawBodyToJSON,
    GetWithdrawFeeAmountResponse,
    GetWithdrawFeeAmountResponseFromJSON,
    GetWithdrawFeeAmountResponseToJSON,
    GetWithdrawsOwnSort,
    GetWithdrawsOwnSortFromJSON,
    GetWithdrawsOwnSortToJSON,
    GetWithdrawsResponse,
    GetWithdrawsResponseFromJSON,
    GetWithdrawsResponseToJSON,
    GetWithdrawsSort,
    GetWithdrawsSortFromJSON,
    GetWithdrawsSortToJSON,
    RejectWithdrawBody,
    RejectWithdrawBodyFromJSON,
    RejectWithdrawBodyToJSON,
    SortOrder,
    SortOrderFromJSON,
    SortOrderToJSON,
    WithdrawStatus,
    WithdrawStatusFromJSON,
    WithdrawStatusToJSON,
} from '../models';

export interface CancelWithdrawRequestRequest {
    id: string;
    cancelWithdrawBody?: CancelWithdrawBody;
}

export interface CompleteWithdrawRequestRequest {
    id: string;
    completeWithdrawBody?: CompleteWithdrawBody;
}

export interface CreateWithdrawRequestRequest {
    createWithdrawBody?: CreateWithdrawBody;
}

export interface GetWithdrawRequestsRequest {
    status?: Array<WithdrawStatus>;
    search?: string;
    sort?: GetWithdrawsSort;
    order?: SortOrder;
    limit?: number;
    offset?: number;
    numberOfAvailableOffsets?: number;
}

export interface GetWithdrawRequestsOwnRequest {
    status?: Array<WithdrawStatus>;
    search?: string;
    sort?: GetWithdrawsOwnSort;
    order?: SortOrder;
    limit?: number;
    offset?: number;
    numberOfAvailableOffsets?: number;
}

export interface RejectWithdrawRequestRequest {
    id: string;
    rejectWithdrawBody?: RejectWithdrawBody;
}

/**
 * WithdrawsApi - interface
 * @export
 * @interface WithdrawsApiInterface
 */
export interface WithdrawsApiInterface {
    /**
     * Used by users to cancel in progress withdrawals.
     * @param {string} id 
     * @param {CancelWithdrawBody} [cancelWithdrawBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WithdrawsApiInterface
     */
    cancelWithdrawRequestRaw(requestParameters: CancelWithdrawRequestRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Used by users to cancel in progress withdrawals.
     */
    cancelWithdrawRequest(requestParameters: CancelWithdrawRequestRequest): Promise<void>;

    /**
     * Used by admins to complete a withdrawal requests
     * @param {string} id 
     * @param {CompleteWithdrawBody} [completeWithdrawBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WithdrawsApiInterface
     */
    completeWithdrawRequestRaw(requestParameters: CompleteWithdrawRequestRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Used by admins to complete a withdrawal requests
     */
    completeWithdrawRequest(requestParameters: CompleteWithdrawRequestRequest): Promise<void>;

    /**
     * Used to create new withdrawal request
     * @param {CreateWithdrawBody} [createWithdrawBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WithdrawsApiInterface
     */
    createWithdrawRequestRaw(requestParameters: CreateWithdrawRequestRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Used to create new withdrawal request
     */
    createWithdrawRequest(requestParameters: CreateWithdrawRequestRequest): Promise<void>;

    /**
     * Gets the current fee for calculating withdraw fees.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WithdrawsApiInterface
     */
    getWithdrawFeeAmountRaw(): Promise<runtime.ApiResponse<GetWithdrawFeeAmountResponse>>;

    /**
     * Gets the current fee for calculating withdraw fees.
     */
    getWithdrawFeeAmount(): Promise<GetWithdrawFeeAmountResponse>;

    /**
     * Used by admins to get a list of all withdrawal requests
     * @param {Array<WithdrawStatus>} [status] 
     * @param {string} [search] 
     * @param {GetWithdrawsSort} [sort] 
     * @param {SortOrder} [order] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {number} [numberOfAvailableOffsets] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WithdrawsApiInterface
     */
    getWithdrawRequestsRaw(requestParameters: GetWithdrawRequestsRequest): Promise<runtime.ApiResponse<GetWithdrawsResponse>>;

    /**
     * Used by admins to get a list of all withdrawal requests
     */
    getWithdrawRequests(requestParameters: GetWithdrawRequestsRequest): Promise<GetWithdrawsResponse>;

    /**
     * Used by user to get a list of all their withdrawal requests
     * @param {Array<WithdrawStatus>} [status] 
     * @param {string} [search] 
     * @param {GetWithdrawsOwnSort} [sort] 
     * @param {SortOrder} [order] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {number} [numberOfAvailableOffsets] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WithdrawsApiInterface
     */
    getWithdrawRequestsOwnRaw(requestParameters: GetWithdrawRequestsOwnRequest): Promise<runtime.ApiResponse<GetWithdrawsResponse>>;

    /**
     * Used by user to get a list of all their withdrawal requests
     */
    getWithdrawRequestsOwn(requestParameters: GetWithdrawRequestsOwnRequest): Promise<GetWithdrawsResponse>;

    /**
     * Used by admins to reject a withdrawal requests
     * @param {string} id 
     * @param {RejectWithdrawBody} [rejectWithdrawBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WithdrawsApiInterface
     */
    rejectWithdrawRequestRaw(requestParameters: RejectWithdrawRequestRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Used by admins to reject a withdrawal requests
     */
    rejectWithdrawRequest(requestParameters: RejectWithdrawRequestRequest): Promise<void>;

}

/**
 * no description
 */
export class WithdrawsApi extends runtime.BaseAPI implements WithdrawsApiInterface {

    /**
     * Used by users to cancel in progress withdrawals.
     */
    async cancelWithdrawRequestRaw(requestParameters: CancelWithdrawRequestRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling cancelWithdrawRequest.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/withdraws/{id}/cancel`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CancelWithdrawBodyToJSON(requestParameters.cancelWithdrawBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Used by users to cancel in progress withdrawals.
     */
    async cancelWithdrawRequest(requestParameters: CancelWithdrawRequestRequest): Promise<void> {
        await this.cancelWithdrawRequestRaw(requestParameters);
    }

    /**
     * Used by admins to complete a withdrawal requests
     */
    async completeWithdrawRequestRaw(requestParameters: CompleteWithdrawRequestRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling completeWithdrawRequest.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/withdraws/{id}/complete`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompleteWithdrawBodyToJSON(requestParameters.completeWithdrawBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Used by admins to complete a withdrawal requests
     */
    async completeWithdrawRequest(requestParameters: CompleteWithdrawRequestRequest): Promise<void> {
        await this.completeWithdrawRequestRaw(requestParameters);
    }

    /**
     * Used to create new withdrawal request
     */
    async createWithdrawRequestRaw(requestParameters: CreateWithdrawRequestRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/withdraws`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateWithdrawBodyToJSON(requestParameters.createWithdrawBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Used to create new withdrawal request
     */
    async createWithdrawRequest(requestParameters: CreateWithdrawRequestRequest): Promise<void> {
        await this.createWithdrawRequestRaw(requestParameters);
    }

    /**
     * Gets the current fee for calculating withdraw fees.
     */
    async getWithdrawFeeAmountRaw(): Promise<runtime.ApiResponse<GetWithdrawFeeAmountResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/withdraws/get-withdraw-fee-amount`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetWithdrawFeeAmountResponseFromJSON(jsonValue));
    }

    /**
     * Gets the current fee for calculating withdraw fees.
     */
    async getWithdrawFeeAmount(): Promise<GetWithdrawFeeAmountResponse> {
        const response = await this.getWithdrawFeeAmountRaw();
        return await response.value();
    }

    /**
     * Used by admins to get a list of all withdrawal requests
     */
    async getWithdrawRequestsRaw(requestParameters: GetWithdrawRequestsRequest): Promise<runtime.ApiResponse<GetWithdrawsResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.status) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.numberOfAvailableOffsets !== undefined) {
            queryParameters['numberOfAvailableOffsets'] = requestParameters.numberOfAvailableOffsets;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/withdraws`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetWithdrawsResponseFromJSON(jsonValue));
    }

    /**
     * Used by admins to get a list of all withdrawal requests
     */
    async getWithdrawRequests(requestParameters: GetWithdrawRequestsRequest): Promise<GetWithdrawsResponse> {
        const response = await this.getWithdrawRequestsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used by user to get a list of all their withdrawal requests
     */
    async getWithdrawRequestsOwnRaw(requestParameters: GetWithdrawRequestsOwnRequest): Promise<runtime.ApiResponse<GetWithdrawsResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.status) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.numberOfAvailableOffsets !== undefined) {
            queryParameters['numberOfAvailableOffsets'] = requestParameters.numberOfAvailableOffsets;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/withdraws/own`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetWithdrawsResponseFromJSON(jsonValue));
    }

    /**
     * Used by user to get a list of all their withdrawal requests
     */
    async getWithdrawRequestsOwn(requestParameters: GetWithdrawRequestsOwnRequest): Promise<GetWithdrawsResponse> {
        const response = await this.getWithdrawRequestsOwnRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used by admins to reject a withdrawal requests
     */
    async rejectWithdrawRequestRaw(requestParameters: RejectWithdrawRequestRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling rejectWithdrawRequest.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/withdraws/{id}/reject`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RejectWithdrawBodyToJSON(requestParameters.rejectWithdrawBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Used by admins to reject a withdrawal requests
     */
    async rejectWithdrawRequest(requestParameters: RejectWithdrawRequestRequest): Promise<void> {
        await this.rejectWithdrawRequestRaw(requestParameters);
    }

}
