/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateBrandBody
 */
export interface UpdateBrandBody {
    /**
     * 
     * @type {string}
     * @memberof UpdateBrandBody
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBrandBody
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBrandBody
     */
    businessIcon?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBrandBody
     */
    businessHeader?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBrandBody
     */
    image?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateBrandBody
     */
    isEnabled?: boolean;
}

export function UpdateBrandBodyFromJSON(json: any): UpdateBrandBody {
    return UpdateBrandBodyFromJSONTyped(json, false);
}

export function UpdateBrandBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateBrandBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'businessIcon': !exists(json, 'businessIcon') ? undefined : json['businessIcon'],
        'businessHeader': !exists(json, 'businessHeader') ? undefined : json['businessHeader'],
        'image': !exists(json, 'image') ? undefined : json['image'],
        'isEnabled': !exists(json, 'isEnabled') ? undefined : json['isEnabled'],
    };
}

export function UpdateBrandBodyToJSON(value?: UpdateBrandBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'businessIcon': value.businessIcon,
        'businessHeader': value.businessHeader,
        'image': value.image,
        'isEnabled': value.isEnabled,
    };
}


