import {ReactElement} from "react";
import FrameOneAutoPanel from "./autoPanelComponents/FrameOneAutoPanel";
import FrameAutoPanelBody from "./autoPanelComponents/FrameAutoPanelBody";
import GoVipModalHeader from "../goVip/GoVipModalHeader";
import GoVipGames from "../goVip/GoVipGames";

interface Props {
    isOpen: boolean;
    toggle: () => void;
}

function GoVipAllPlay2EarnGamesModal(props: Props): ReactElement {

    return (
        <FrameOneAutoPanel
            isOpen={props.isOpen}
            toggle={props.toggle}
            contentClassName="go-vip-all-play2earn-games"
            size="lg"
            maxHeight={true}
        >

            <GoVipModalHeader
                title="Play2Earn"
                subtitle="Progress in each game to earn points."
                toggle={props.toggle}
            />

            <FrameAutoPanelBody className="go-vip-all-play2earn-games_body">
                <GoVipGames/>
            </FrameAutoPanelBody>
        </FrameOneAutoPanel>
    );
}

export default GoVipAllPlay2EarnGamesModal;
