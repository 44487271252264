/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BusinessPeriod,
    BusinessPeriodFromJSON,
    BusinessPeriodFromJSONTyped,
    BusinessPeriodToJSON,
} from './';

/**
 * 
 * @export
 * @interface BusinessServiceAvailabilityOverride
 */
export interface BusinessServiceAvailabilityOverride {
    /**
     * 
     * @type {string}
     * @memberof BusinessServiceAvailabilityOverride
     */
    date: string;
    /**
     * 
     * @type {Array<BusinessPeriod>}
     * @memberof BusinessServiceAvailabilityOverride
     */
    timePeriods: Array<BusinessPeriod>;
}

export function BusinessServiceAvailabilityOverrideFromJSON(json: any): BusinessServiceAvailabilityOverride {
    return BusinessServiceAvailabilityOverrideFromJSONTyped(json, false);
}

export function BusinessServiceAvailabilityOverrideFromJSONTyped(json: any, ignoreDiscriminator: boolean): BusinessServiceAvailabilityOverride {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'date': json['date'],
        'timePeriods': ((json['timePeriods'] as Array<any>).map(BusinessPeriodFromJSON)),
    };
}

export function BusinessServiceAvailabilityOverrideToJSON(value?: BusinessServiceAvailabilityOverride | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date': value.date,
        'timePeriods': ((value.timePeriods as Array<any>).map(BusinessPeriodToJSON)),
    };
}


