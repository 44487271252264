import {ButtonHTMLAttributes, ChangeEventHandler, FormEvent, ReactElement, useState} from "react";
import FrameOneAutoPanel from "@/components/modals/autoPanelComponents/FrameOneAutoPanel";
import FrameAutoPanelBody from "@/components/modals/autoPanelComponents/FrameAutoPanelBody";
import FrameOnePhoneNumberInput from "@/components/inputs/FrameOnePhoneNumberInput";
import {
    BrandMap,
    BrandMapColorTheme,
    CreateUserBody,
    GetUserResponse,
    LoginResponse,
    PhoneNumberBody,
    UsersApi,
} from "@devour/client";
import IconInput from "@/components/inputs/IconInput";
import FrameButton from "@/components/buttons/FrameButton";
import {addError, decrementLoading, incrementLoading} from "@/redux/meta/metaActions";
import getConfig, {getMagicConfig} from "@/utils/getConfig";
import {magic} from "@/utils/magic";
import {useDispatch} from "react-redux";
import classNames from "classnames";
import {isMobile, isTablet} from "react-device-detect";
import {useGetBrandMap} from "@/hooks/useGetBrandMap";
import {useParams} from "react-router";

interface Props {
    isOpen: boolean;
    toggle: (resetMagicCred: boolean) => void;
    magicCredential: string;
    updateUserAfterLogin: (loginRes: LoginResponse, userRes: GetUserResponse) => Promise<void>;
    brandMap: BrandMap;
    renderLegalLink: (to: string, text: string) => ReactElement;
    renderDevourLogo: (colorTheme: BrandMapColorTheme) => ReactElement;
}

const defaultCreateUserBody: CreateUserBody = {
    password: "",
    confirmPassword: "",
    email: "",
    phoneNumber: {
        countryCode: "US",
        nationalNumber: "",

    },
    firstName: "",
    lastName: "",
    referralCode: "",
};

const defaultFormValidationResults = {
    firstName: true,
    lastName: true,
    phoneNumber: true,
};

const MAX_CHARACTER_LENGTH = 22;

function BrandSignUpModal(props: Props): ReactElement {
    const {renderLegalLink, renderDevourLogo} = props;
    const dispatch = useDispatch();
    const {slug} = useParams<{ slug: string }>();
    const {refetch: refetchBrandMap} = useGetBrandMap(slug);
    const [createUserBody, setCreateUserBody] = useState<CreateUserBody>(defaultCreateUserBody);

    const [showReferralSection, setShowReferralSection] = useState<boolean>(false);
    const [referralCodeValid, setReferralCodeValid] = useState<boolean | undefined>(undefined);
    const [isSignUpInProgress, setIsSignUpInProgress] = useState<boolean>(false);
    const [validationResults, setValidationResults] = useState(defaultFormValidationResults);
    const [isFormValid, setIsFormValid] = useState<boolean>(true);

    const isTextThemeDark = props.brandMap.colorTheme === BrandMapColorTheme.DARK;
    const isTextThemeLight = props.brandMap.colorTheme === BrandMapColorTheme.LIGHT;
    const cancelImageUrl = isTextThemeLight
        ? `${import.meta.env.VITE_CDN_URL}/images/cancel-light-gray.svg`
        : `${import.meta.env.VITE_CDN_URL}/images/cancel-medium-gray.svg`;

    const plusImageUrl = isTextThemeLight
        ? `${import.meta.env.VITE_CDN_URL}/images/plus-gentle-lavender.svg`
        : `${import.meta.env.VITE_CDN_URL}/images/plus-secondary-purple.svg`;

    function prepareRegisterBody(skip: boolean) {
        const body = {};

        if (skip || createUserBody === defaultCreateUserBody) {
            return body;
        }

        Object.keys(createUserBody).forEach((index) => {
            if (index === "phoneNumber") {
                if (createUserBody[index].nationalNumber !== "") {
                    body["phoneNumber"] = createUserBody[index];
                }
            } else if (createUserBody[index] !== "") {
                body[index] = createUserBody[index];
            }
        });
        return body;
    }

    /**
     * Validates form fields and returns an object with validation results
     * @param userBody
     */
    function validateForm(userBody: CreateUserBody) {
        const {firstName, lastName, phoneNumber} = userBody;
        return {
            firstName: !!firstName,
            lastName: !!lastName,
            phoneNumber: phoneNumber.nationalNumber.length === 10,
        };
    }

    /**
     * Submits the signup form
     * Validates the form,
     * calls the register API with the magic credential, and logs the user in
     * @param skip
     */
    function submitSignUp(skip: boolean): (e?: FormEvent<HTMLFormElement>) => Promise<void> {
        return async (e?) => {
            e?.preventDefault();

            try {
                setIsSignUpInProgress(true);

                // Form Validation
                if (!skip) {
                    const validationResults = validateForm(createUserBody);
                    setValidationResults(validationResults);

                    // Check if all values in validation results are truthy
                    const isFormValid = Object.values(validationResults).every(Boolean);
                    setIsFormValid(isFormValid);

                    if (!isFormValid) {
                        return;
                    }
                }

                // call the login api with the magic_credential, if the API returns 200 with a token, then log the user in as normal
                const loginRes = await new UsersApi(getMagicConfig(props.magicCredential)).register({
                    registerBody: prepareRegisterBody(skip),
                });

                // grab the profile to save into redux
                const userRes = await new UsersApi(getConfig(loginRes.token)).getProfile();

                // login to magic locally
                if (await magic.user.isLoggedIn() === false) {
                    await magic.auth.loginWithCredential(props.magicCredential);
                }

                await props.updateUserAfterLogin(loginRes, userRes);

                // reset form after successful submission
                setCreateUserBody(defaultCreateUserBody);
            } catch (e) {
                dispatch(await addError(e));

            } finally {
                dispatch(incrementLoading());
                await refetchBrandMap();
                dispatch(decrementLoading());
                props.toggle(true);
                setIsSignUpInProgress(false);
            }
        };
    }

    function referralCodeOnChange(referralCode: string): void {
        const newUserBody = {
            ...createUserBody,
            referralCode: referralCode?.length
                ? referralCode
                : "",
        };
        setCreateUserBody(newUserBody);
        setIsFormValid(true);
    }

    function phoneNumberOnChange(phoneNumber: PhoneNumberBody): void {
        setCreateUserBody({
            ...createUserBody,
            phoneNumber,
        });
        setIsFormValid(true);
        setValidationResults({
            ...validationResults,
            phoneNumber: true,
        });
    }

    function inputOnChange(key: keyof Omit<CreateUserBody, "phoneNumber">): ChangeEventHandler<HTMLInputElement> {
        return (e) => {
            setCreateUserBody({
                ...createUserBody,
                [key]: e.target.value,
            });
            setIsFormValid(true);
            setValidationResults({
                ...validationResults,
                [key]: true,
            });
        };
    }

    function handleReferralCodeInputValidation(newReferralCode: string): void {
        referralCodeOnChange(newReferralCode);
        if (!newReferralCode.length) {
            setReferralCodeValid(undefined); // reset color on 0
        } else if (newReferralCode.length < MAX_CHARACTER_LENGTH || (/[^a-zA-Z0-9]/).test(newReferralCode)) {
            setReferralCodeValid(false); // too short or invalid chars
        } else {
            setReferralCodeValid(true);
        }
    }

    function handleShowReferral(): void {
        setShowReferralSection(!showReferralSection);
    }

    return (
        <FrameOneAutoPanel
            isOpen={props.isOpen}
            fullScreenHeight={true}
            toggle={() => props.toggle(true)}
            contentClassName={classNames("brand-signup-modal", {"mobile": isMobile && !isTablet})}
            size="sm2"
            modalOnTablet={true}
        >
            <div
                className="brand-signup-modal_container"
                style={{backgroundImage: `url(${`${import.meta.env.VITE_CDN_URL}/images/brand-signup-modal-graphic.webp`})`}}
            >
                <img
                    src={cancelImageUrl}
                    onClick={() => props.toggle(true)}
                    alt="close"
                    className="brand-email-modal_close"
                />

                {renderDevourLogo(props.brandMap.colorTheme)}

                <h1 className="brand-signup-modal_container_title">
                    Sign Up
                </h1>

                <p className="brand-signup-modal_container_subtitle">
                    Welcome to DevourGO to eat, play, and earn.
                </p>

                <FrameAutoPanelBody className="brand-signup-modal_body">
                    <form
                        onSubmit={submitSignUp(false)}>
                        <div className="brand-signup-modal_body_row">
                            <div className="brand-signup-modal_body_row_input">
                                <label>First Name</label>
                                <input
                                    className={classNames({
                                        "error": !validationResults.firstName,
                                    })}
                                    placeholder="First name"
                                    value={createUserBody.firstName}
                                    onChange={inputOnChange("firstName")}
                                />
                            </div>

                            <div className="brand-signup-modal_body_row_input">
                                <label>Last Name</label>
                                <input
                                    className={classNames({
                                        "error": !validationResults.lastName,
                                    })}
                                    placeholder="Last name"
                                    value={createUserBody.lastName}
                                    onChange={inputOnChange("lastName")}
                                />
                            </div>
                        </div>

                        {/* COUNTRY CODE SELECT AND PHONE INPUT*/}
                        <div className="brand-signup-modal_body_phone">
                            <FrameOnePhoneNumberInput
                                value={createUserBody.phoneNumber}
                                onChange={phoneNumberOnChange}
                                chevronClassname={isTextThemeLight ? "chevron-dark" : "chevron-light"}
                                inputClassname={!validationResults.phoneNumber ? "error" : ""}
                            />
                        </div>

                        {/* REFERRALS */}
                        <div className="brand-signup-modal_referral">
                            {!showReferralSection
                                ? <div
                                    className="brand-signup-modal_referral_container"
                                    onClick={handleShowReferral}
                                >
                                    <img
                                        src={plusImageUrl}
                                        alt="plus"
                                    />
                                    <label>Add a Referral Code</label>
                                </div>
                                : <label>Referral Code (Optional)</label>
                            }

                            <div
                                className={classNames({
                                    "sign-up_content_referral-code-container_referral-code-content-visible": showReferralSection,
                                    "sign-up_content_referral-code-container_referral-code-content-hidden": !showReferralSection,
                                })}
                            >
                                <IconInput
                                    className={classNames({"error": referralCodeValid === false})}
                                    placeholder={"Enter the code shared by your friend"}
                                    maxLength={MAX_CHARACTER_LENGTH}
                                    value={createUserBody?.referralCode?.length
                                        ? createUserBody.referralCode
                                        : ""}
                                    onChange={(e) => {
                                        handleReferralCodeInputValidation(e.target.value);
                                    }}
                                />
                            </div>
                        </div>

                        {/* DISPLAY ERROR MESSAGES */}
                        <div className="brand-signup-modal_error-div">
                            {!isFormValid &&
                                <p className="brand-signup-modal_error-msg">Please fill out all required fields.</p>
                            }

                            {referralCodeValid === false &&
                                <p className="brand-signup-modal_error-msg">Referral code is invalid.</p>
                            }
                        </div>

                        <div className={classNames({
                            "brand-signup-modal_mobile-footer": isMobile && !isTablet,
                        })}>
                            <FrameButton
                                <ButtonHTMLAttributes<HTMLButtonElement>>
                                color="purple"
                                size="normal"
                                className="brand-email-modal_button button-span"
                                showSpinner={true}
                                forwardProps={{
                                    type: "submit",
                                    disabled: referralCodeValid === false || isSignUpInProgress,
                                }}
                            >
                                Sign Up
                            </FrameButton>

                            <div className="sign-up_content_sign-up-container">
                                <div
                                    className="sign-up_content_sign-up-container_link"
                                    onClick={() => submitSignUp(true)()}
                                >
                                    <p className={classNames("brand-signup-modal_skip", {
                                        "medium-gray": isTextThemeDark,
                                    })}
                                    >
                                        Skip
                                    </p>
                                </div>
                            </div>

                            <div className="brand-email-modal_legal">
                                <p className={classNames({
                                    "subtitle-gray": isTextThemeLight,
                                })}>
                                    By clicking “Sign up” above, you acknowledge that you have read and understood, and
                                    agree to
                                    DevourGO’s{" "}
                                    {renderLegalLink("/terms-of-use", "Terms of Use")},{" "}
                                    {renderLegalLink("/privacy-policy", "Privacy Policy")}, and{" "}
                                    {renderLegalLink("/cookie-policy", "Cookie Policy")}
                                </p>
                            </div>
                        </div>
                    </form>
                </FrameAutoPanelBody>
            </div>
        </FrameOneAutoPanel>
    );
}

export default BrandSignUpModal;
