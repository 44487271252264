import {ReactElement, useEffect, useState} from "react";
import {FiAlertCircle, FiChevronRight} from "react-icons/fi";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import GoVipLevelsModal from "@/components/modals/GoVipLevelsModal";
import GoVipPointsText from "@/components/goVip/GoVipPointsText";
import GoVipExperienceBar from "@/components/goVip/GoVipExperienceBar";
import {RiVipDiamondLine} from "react-icons/ri";
import {useGetUserLeaderboardRank} from "@/hooks/useGetUserLeaderboardRank";
import {formatNumberWithCommas} from "@/utils/formatNumberWithCommas";
import {useGetTransactions} from "@/hooks/useGetTransactions";
import FrameButton from "@/components/buttons/FrameButton";
import {useNavigate} from "react-router-dom";
import {isDesktop, isMobile} from "react-device-detect";
import {useGetUserLevel} from "@/hooks/useGetUserLevel";
import classNames from "classnames";
import Toast from "@/components/Toast";
import {getRankSuffix} from "@/utils/getRankSuffix";
import {addError, toggleLoginOpen, updateAccountLevel} from "@/redux/meta/metaActions";
import Skeleton from "react-loading-skeleton";
import {useGate} from "statsig-react";

function GoVipRestaurantSearchBanner(): ReactElement {

    const currentUser = useSelector((store: IStore) => store.metaStore.currentUser);
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const accountLevel = useSelector((store: IStore) => store.metaStore.accountLevel);
    const { value: dpayStatus } = useGate(import.meta.env.VITE_TOKEN_STATSIG_STATUS);

    const history = useNavigate();

    const [
        showGoVipLevelsModal,
        setShowGoVipLevelsModal,
    ] = useState<boolean>(false);

    const {data: userLeaderboardRankData} = useGetUserLeaderboardRank(fullToken);
    const leaderboardRank: number = userLeaderboardRankData?.rank;
    const {
        data: transactionsData,
        isError: isTransactionsDataError,
    } = useGetTransactions(fullToken, currentUser?.user?.id);
    const {data: userLevelData} = useGetUserLevel(fullToken, currentUser?.user?.id);
    const shouldShowUserRank: boolean = userLevelData?.level > 1;

    const [
        showDpayErrorToast,
        setShowDpayErrorToast,
    ] = useState<boolean>(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (userLevelData) {
            void updateLevelData();
        }
    }, [userLevelData]);

    async function updateLevelData() {
        try {
            dispatch(updateAccountLevel(userLevelData));
        } catch (err) {
            dispatch(await addError(err));
        }
    }

    function toggleGoVipPrizesModal(): void {
        setShowGoVipLevelsModal(!showGoVipLevelsModal);
    }

    function renderDpayCard(): ReactElement {
        return (
            <div
                className={classNames(
                    "go-vip-restaurant-search_container_right_card",
                    isDesktop && "go-vip-restaurant-search_container_right_card_adjust-desktop",
                )}
            >
                <img
                    className="go-vip-restaurant-search_container_right_card_icon"
                    src={import.meta.env.VITE_CDN_URL + "/images/FUEL.webp"}
                    alt="DevourGO Logo"
                />
                <div className="go-vip-restaurant-search_container_right_card_info">
                    {fullToken && !transactionsData && isTransactionsDataError
                        ? <div className="go-vip-restaurant-search_container_right_card_info_error">
                            {import.meta.env.VITE_TOKEN_NAME} prices are unavailable at the moment.
                        </div>
                        : <div>
                            <p className="go-vip-restaurant-search_container_right_card_dpay">
                                {!fullToken
                                    ? `0 ${import.meta.env.VITE_TOKEN_NAME}`
                                    : !transactionsData
                                        ? <Skeleton width={130}/>
                                        : `${formatNumberWithCommas(transactionsData.balance)} ${import.meta.env.VITE_TOKEN_NAME}`}
                            </p>
                            <p className="go-vip-top_header_dpay-card_content_balances_fiat">
                                {!fullToken
                                    ? "0 USD"
                                    : !transactionsData
                                        ? <Skeleton width={130}/>
                                        : `$${Intl.NumberFormat("en-us", {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        }).format(transactionsData.balanceInUsd)} USD`
                                }
                            </p>
                        </div>
                    }
                    <FrameButton
                        color={transactionsData
                            ? "white-drop-shadow-devour"
                            : "gray"}
                        size="pill"
                        rightIcon={FiChevronRight}
                        className={`go-vip-restaurant-search_container_right_card_info_load-dpay${transactionsData
                            ? ""
                            : "_disabled"}`}
                        onClick={() => !transactionsData && isTransactionsDataError
                            ? setShowDpayErrorToast(true)
                            : fullToken
                                ? history("/load-fuel")
                                : dispatch(toggleLoginOpen(true))}
                    >
                        Load {import.meta.env.VITE_TOKEN_NAME}
                    </FrameButton>

                </div>
            </div>
        );
    }

    function renderRank(): ReactElement {
        return (
            <FrameButton
                color="white-drop-shadow"
                size="pill"
                leftIcon={RiVipDiamondLine}
                rightIcon={FiChevronRight}
                className="go-vip-restaurant-search_container_left_rank"
                onClick={() => history("/go-vip")}
            >
                {!fullToken
                    ? "Explore GoVIP"
                    : shouldShowUserRank
                        ? `Rank ${leaderboardRank}${getRankSuffix(leaderboardRank)}`
                        : "NEW"}
            </FrameButton>
        );
    }

    function handleToastDismissal() {
        setShowDpayErrorToast(false);
    }

    return (
        <>
            <Toast
                duration={3000}
                message={`Uh oh... it looks like ${import.meta.env.VITE_TOKEN_NAME} prices are unavailable right now. Please check back in a bit!`}
                isOpen={showDpayErrorToast}
                variant="error"
                onDismiss={handleToastDismissal}
            />
            <GoVipLevelsModal
                isOpen={showGoVipLevelsModal}
                toggle={toggleGoVipPrizesModal}
            />
            <div className="go-vip-restaurant-search">
                {fullToken && !leaderboardRank
                    ? <div className="react-loading-skeleton go-vip-restaurant-search_skeleton"/>
                    : <>
                        <div className="go-vip-restaurant-search_container">
                            <div className="go-vip-restaurant-search_container_left">

                                <div className="go-vip-restaurant-search_container_left_level-info">
                                    <div className="go-vip-restaurant-search_container_left_level-info_current-level">
                                        <p>
                                            {`LEVEL ${accountLevel?.level
                                                ? accountLevel.level
                                                : 1}`}
                                        </p>
                                        <FiAlertCircle
                                            onClick={toggleGoVipPrizesModal}
                                            className="go-vip-top_header_exp_current-level_info-icon"
                                        />
                                    </div>
                                    {renderRank()}
                                </div>

                                <div className="go-vip-restaurant-search_container_left_exp-container">
                                    <h1 className="go-vip-restaurant-search_container_left_counter">
                                        {fullToken
                                            ? accountLevel?.experienceGainedAtThisLevel
                                            : 0}
                                    </h1>
                                    <p className="go-vip-restaurant-search_container_left_points">
                                        / {fullToken && accountLevel
                                            ? accountLevel?.experienceGainedAtThisLevel + accountLevel?.experienceRequiredForNextLevel
                                            : 1000} Points
                                    </p>
                                    {isDesktop &&
                                    <>
                                        <div className="go-vip-top_header_top-row-container_spacer"/>
                                        <GoVipPointsText/>
                                    </>
                                    }
                                </div>
                                <GoVipExperienceBar/>
                            </div>

                            <div className="go-vip-restaurant-search_container_right">
                                {isDesktop && dpayStatus && renderDpayCard()}
                            </div>
                        </div>
                        {isMobile && dpayStatus && renderDpayCard()}
                    </>
                }
            </div>
        </>
    );
}

export default GoVipRestaurantSearchBanner;
