/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateReferralRewardThresholdBody
 */
export interface CreateReferralRewardThresholdBody {
    /**
     * 
     * @type {boolean}
     * @memberof CreateReferralRewardThresholdBody
     */
    disabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateReferralRewardThresholdBody
     */
    hidden: boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateReferralRewardThresholdBody
     */
    expiration?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateReferralRewardThresholdBody
     */
    amount: number;
    /**
     * 
     * @type {number}
     * @memberof CreateReferralRewardThresholdBody
     */
    minPaymentRequired: number;
}

export function CreateReferralRewardThresholdBodyFromJSON(json: any): CreateReferralRewardThresholdBody {
    return CreateReferralRewardThresholdBodyFromJSONTyped(json, false);
}

export function CreateReferralRewardThresholdBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateReferralRewardThresholdBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'disabled': json['disabled'],
        'hidden': json['hidden'],
        'expiration': !exists(json, 'expiration') ? undefined : json['expiration'],
        'amount': json['amount'],
        'minPaymentRequired': json['minPaymentRequired'],
    };
}

export function CreateReferralRewardThresholdBodyToJSON(value?: CreateReferralRewardThresholdBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'disabled': value.disabled,
        'hidden': value.hidden,
        'expiration': value.expiration,
        'amount': value.amount,
        'minPaymentRequired': value.minPaymentRequired,
    };
}


