import {useQuery} from "@tanstack/react-query";
import {
    GetMenuOrdersOwnSort,
    GetMenuOrdersResponse,
    MenuOrdersApi,
    MenuOrderStatus,
    SortOrder,
    Token,
} from "@devour/client";
import getConfig from "@/utils/getConfig";

interface MenuOrdersOwnConfigs {
    sort?: GetMenuOrdersOwnSort;
    order?: SortOrder;
    limit?: number;
    offset?: number,
    status?: MenuOrderStatus[]
}

async function fetchMenuOrdersOwn(config: MenuOrdersOwnConfigs): Promise<GetMenuOrdersResponse> {
    const {sort, order, limit, offset, status} = config;
    return await new MenuOrdersApi(getConfig()).getMenuOrdersOwn({
        sort,
        order,
        limit,
        offset,
        status,
    });
}

function menuOrdersOwnQuery(fullToken: Token, config: MenuOrdersOwnConfigs) {
    return {
        queryKey: [
            "user-menu-orders-own",
            fullToken?.id ?? "unauthenticated",
            config,
        ],
        queryFn: () => fetchMenuOrdersOwn(config),
        staleTime: 60000,
        enabled: !!fullToken,
    } as const;
}

export function useGetMenuOrdersOwn(fullToken: Token, config: MenuOrdersOwnConfigs) {
    return useQuery(menuOrdersOwnQuery(fullToken, config));
}