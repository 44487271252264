import {useState, ReactElement, useEffect} from "react";
import {Token, SiteConfiguration, SiteConfigurationKey, SiteConfigurationsApi} from "@devour/client";
import {decrementLoading, incrementLoading} from "../redux/meta/metaActions";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../redux/defaultStore";
import FrameButton from "./buttons/FrameButton";
import {LinkProps} from "react-router-dom";

interface Props {
    configKey: SiteConfigurationKey;
}

interface StateProps {
    fullToken: Token;
}

function SiteHeroBanner(props: SiteHeroBannerProps): ReactElement {
    const [
        siteConfiguration,
        setSiteConfiguration,
    ] = useState<SiteConfiguration>(undefined);

    useEffect(() => {
        getSiteConfigurationApi().then();
    }, []);

    async function getSiteConfigurationApi(): Promise<void> {

        props.dispatch(incrementLoading());

        try {
            const res = await new SiteConfigurationsApi().getSiteConfiguration({
                configKey: props.configKey,
            });

            setSiteConfiguration(res);
        } catch (e) {
            // Configuration might not exist yet.
            // props.dispatch(await addError(e));
        } finally {
            props.dispatch(decrementLoading());
        }
    }

    if (!siteConfiguration?.asset) {
        return null;
    }

    return (
        <div className="site-hero-banner">
            <div
                className="site-hero-banner_inner"
                style={{backgroundImage: `url(${siteConfiguration?.asset?.pipeUrl})`}}
            >
                <div className="site-hero-banner_text">
                    <div className="site-hero-banner_title">
                        {siteConfiguration.title}
                    </div>
                    <div className="site-hero-banner_description">
                        {siteConfiguration.description}
                    </div>
                </div>
                <div className="site-hero-banner_cta">
                    {siteConfiguration.url && siteConfiguration.buttonText &&
                    <FrameButton
						    <LinkProps>
						    color="purple"
						    size="normal"
						    href={siteConfiguration.url}
                    >
						    {siteConfiguration.buttonText}
                    </FrameButton>
                    }
                </div>
            </div>
        </div>
    );
}

function connector() {
    return connect((store: IStore, props: Props): StateProps & Props => {
        return {
            fullToken: store.authStore.fullToken,
            ...props,
        };
    });
}

type SiteHeroBannerProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(SiteHeroBanner);
