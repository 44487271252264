import {FaArrowLeft} from "react-icons/fa";
import classNames from "classnames";
import {isDesktop} from "react-device-detect";
import {AiOutlineCloudUpload} from "react-icons/ai";
import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import React, {useEffect} from "react";
import {DragEvents} from "@/components/modals/editProfileModal/dragEvents";
import NftProfilePictureBadge from "@/components/NftProfilePictureBadge";
import {useGetUserProfile} from "@/hooks/useGetUserProfile";
import {NftsApi, Token} from "@devour/client";
import getConfig from "@/utils/getConfig";
import {useLocation} from "react-router-dom";
import {useGetNftOwnershipsForUser} from "@/hooks/useGetNftOwnershipsForUser";

interface Props {
    onClose: () => void;
    handleDragEvents: (event: DragEvents) => React.DragEventHandler<HTMLDivElement>;
    count: number;
    dragState: DragEvents;
    inputName: string;
    setInputName: (val: string) => void;
    removeAvatar: () => Promise<void>;
    setShowPfpOptions: (val: boolean) => void;
}

export default function EditProfile(props: Props) {
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const location = useLocation();

    const {data: userProfileData} = useGetUserProfile(fullToken);
    const {refetch: refetchNftOwnerships} = useGetNftOwnershipsForUser(fullToken as Token);

    async function refreshNftTokenUri() {
        try {
            const res = await new NftsApi(getConfig(fullToken)).refreshNftTokenUriRaw();

            // Only refresh if tokenUris got updated
            if (res.raw.status === 201) {
                await refetchNftOwnerships();
            }

        } catch {
            // fail silently
        }
    }

    useEffect(() => {
        // Excluding gofrens because it handles refresh already on its page
        if (fullToken && !location.pathname.includes("gofriends")) {
            void refreshNftTokenUri();
        }
    }, []);

    return (
        <>
            <div className="edit-profile-modal_header">
                <button
                    className="edit-profile-modal_header_icon"
                    onClick={props.onClose}
                >
                    <FaArrowLeft
                        className="frame-one-bottom-panel_header_title-row_close-icon"
                    />
                </button>
            </div>
            <div className="edit-profile-modal_body">
                <div
                    className={classNames(
                        "edit-profile-modal_body_profile",
                        isDesktop
                            ? "edit-profile-modal_body_profile_desktop"
                            : "edit-profile-modal_body_profile_mobile",
                    )}
                >
                    <div
                        className="edit-profile-modal_body_profile_picture"
                        onDragOver={props.handleDragEvents(DragEvents.Hover)}
                        onDragLeave={props.handleDragEvents(DragEvents.Enter)}
                        onDrop={props.handleDragEvents(DragEvents.Drop)}
                    >
                        <div className={classNames(
                            "edit-profile-modal_drop-zone",
                            (props.dragState === DragEvents.Enter || props.dragState === DragEvents.Hover) && "edit-profile-modal_drop-zone_hovering",
                            props.dragState === DragEvents.Hover && "edit-profile-modal_drop-zone_hovering_over-element",
                        )}>
                            <div className="edit-profile-modal_drop-zone_content">
                                <AiOutlineCloudUpload
                                    className="edit-profile-modal_drop-zone_icon"
                                />
                                <p>
                                    Drop to upload photo
                                </p>
                            </div>
                        </div>
                        <img
                            src={userProfileData?.user.gravatar + (userProfileData?.user.profilePicture
                                ? `?v=${props.count}`
                                : "")}
                            alt="User Profile"
                        />
                        {userProfileData?.user.nftProfilePicture &&
                        <NftProfilePictureBadge size="xl"/>
                        }
                    </div>
                </div>
                <div className="edit-profile-modal_body_content">
                    <div className="edit-profile-modal_body_content_text_button">
                        <button
                            className={classNames(
                                "edit-profile-modal_body_content_text_button",
                                "edit-profile-modal_body_content_text_button_normal",
                            )}
                            onClick={() => props.setShowPfpOptions(true)}
                        >
                            <p className="edit-profile-modal_body_content_text">
                                Change Picture
                            </p>
                        </button>
                        {(userProfileData?.user.profilePicture || userProfileData?.user.nftProfilePicture) && <button
                            className={classNames(
                                "edit-profile-modal_body_content_text_button",
                                "edit-profile-modal_body_content_text_button_warning",
                            )}
                            onClick={props.removeAvatar}
                        >
                            <p className="edit-profile-modal_body_content_text">
                                Remove picture
                            </p>
                        </button>}
                    </div>
                    <input
                        value={props.inputName}
                        placeholder="Your nickname..."
                        className="edit-profile-modal_body_content_input"
                        onChange={(e) => props.setInputName(e.target.value)}
                    />
                    <div className="edit-profile-modal_body_default">
                        <p>*Unless set, the default nickname is GoVIP Player.</p>
                    </div>
                </div>
            </div>
        </>
    );
}