/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BusinessPeriod,
    BusinessPeriodFromJSON,
    BusinessPeriodFromJSONTyped,
    BusinessPeriodToJSON,
} from './';

/**
 * 
 * @export
 * @interface BusinessServiceAvailability
 */
export interface BusinessServiceAvailability {
    /**
     * 
     * @type {string}
     * @memberof BusinessServiceAvailability
     */
    dayOfWeek: string;
    /**
     * 
     * @type {Array<BusinessPeriod>}
     * @memberof BusinessServiceAvailability
     */
    timePeriods: Array<BusinessPeriod>;
}

export function BusinessServiceAvailabilityFromJSON(json: any): BusinessServiceAvailability {
    return BusinessServiceAvailabilityFromJSONTyped(json, false);
}

export function BusinessServiceAvailabilityFromJSONTyped(json: any, ignoreDiscriminator: boolean): BusinessServiceAvailability {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dayOfWeek': json['dayOfWeek'],
        'timePeriods': ((json['timePeriods'] as Array<any>).map(BusinessPeriodFromJSON)),
    };
}

export function BusinessServiceAvailabilityToJSON(value?: BusinessServiceAvailability | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dayOfWeek': value.dayOfWeek,
        'timePeriods': ((value.timePeriods as Array<any>).map(BusinessPeriodToJSON)),
    };
}


