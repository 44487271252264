import {HandoffOptions, OrderDiscountType} from "@devour/client";
import {ReactElement, useContext} from "react";
import Skeleton from "react-loading-skeleton";
import {roundNumber} from "@/utils/roundNumber";
import classNames from "classnames";
import Tooltip from "@/components/Tooltip";
import {BsFillQuestionCircleFill} from "react-icons/bs";
import CheckoutDeliveryTips from "@/components/checkout/checkoutOrderSummary/CheckoutDeliveryTips";
import {useParams} from "react-router";
import {useMenuOrder} from "@/hooks/menuOrder/useMenuOrder";
import {RestaurantContext} from "@/pages/restaurants/context/RestaurantContext";


function CheckoutTotals(): ReactElement {

    const { menuOrderId: contextMenuOrderId } = useContext(RestaurantContext);
    const { menuOrderId: paramMenuOrderId } = useParams<{ menuOrderId: string }>();
    const menuOrderId = paramMenuOrderId || contextMenuOrderId;

    const {data: menuOrder} = useMenuOrder(menuOrderId);

    // Get service fees
    const serviceFees: Array<number> = menuOrder?.serviceFees?.map((item) => item.amount);
    const serviceFeesTotal: number = serviceFees?.length
        ? serviceFees.reduce((a, b) => a + b)
        : 0;
    const freeDelivery = menuOrder.handoff === HandoffOptions.PICKUP ||
        menuOrder?.discounts?.find(discount => discount.type === OrderDiscountType.DELIVERY && discount.amount === menuOrder.deliveryCharge);

    function renderPayments(): ReactElement {
        const displayedDiscounts = menuOrder.discounts.filter(discount => {
            if (discount.type === OrderDiscountType.DELIVERY && freeDelivery) {
                return false;
            }

            return discount.type === OrderDiscountType.ORDER ||
                discount.type === OrderDiscountType.DELIVERY ||
                discount.type === OrderDiscountType.CATEGORY ||
                discount.type === OrderDiscountType.MENUITEM ||
                discount.type === OrderDiscountType.PAYMENT;
        });

        if (!displayedDiscounts?.length) {
            return;
        }

        return (
            <>
                {displayedDiscounts.map((discount, index) => <div
                    className={classNames(
                        "checkout-summary_totals_row",
                        "menu-order-discount",
                    )}
                    key={`menu-order-discount-${discount.label}-${index}`}
                >
                    <span>{discount.label}</span>
                    <span>{`-$${discount.amount.toFixed(2)}`}</span>
                </div>)}
            </>
        );
    }

    function getOrderFeesTaxes(): string {
        const taxDiscounts = menuOrder.discounts?.filter(discount => discount.type === OrderDiscountType.SALESTAX);
        const taxDiscountsAmount = taxDiscounts?.length
            ? taxDiscounts.reduce((acc, item) => item.amount + acc, 0)
            : 0;
        return roundNumber(menuOrder.tax - taxDiscountsAmount + serviceFeesTotal).toFixed(2);
    }

    function renderTax(): string {
        if (!menuOrder) {
            return;
        }
        // Don't display tax discounts to customer
        const taxDiscounts = menuOrder.discounts?.filter((discount) => discount.type === OrderDiscountType.SALESTAX);
        const discountTypeTaxCount: Array<number> = taxDiscounts?.map((item) => item.amount);
        const discountTypeTax: number = discountTypeTaxCount.length
            ? discountTypeTaxCount.reduce((a, b) => a + b)
            : 0;

        return roundNumber(menuOrder.tax - discountTypeTax).toFixed(2);
    }

    function renderDeliveryFee(): ReactElement {
        if (freeDelivery) {
            return (
                <>
                    <span>Free Delivery</span>
                    <span>
                        <span className="checkout-summary_totals_free-delivery_before">
                            <s>${menuOrder?.deliveryCharge.toFixed(2)}</s>
                        </span>
                        <span>$0.00</span>

                    </span>
                </>
            );
        }

        return (
            <>
                <span>Delivery Fee</span>
                <span>
                    {menuOrder?.callbackComplete
                        ? menuOrder?.deliveryCharge.toFixed(2)
                        : <Skeleton height={17.5} width={40}/>}
                </span>
            </>
        );
    }

    return (
        <>
            <div className="checkout-summary_totals">
                <div className="checkout-summary_totals_row">
                    <span>Subtotal</span>
                    <span>
                        {menuOrder?.callbackComplete
                            ? `$${menuOrder.subtotal.toFixed(2)}`
                            : <Skeleton height={17.5} width={40}/>
                        }
                    </span>
                </div>
                <div className="checkout-summary_totals_row">
                    <span className="checkout-summary_totals_fees">
                    Estimated Fees & Taxes
                        <Tooltip
                            outsideIcon={BsFillQuestionCircleFill}
                            containerElementClassname="checkout-summary"
                        >
                            <p>
                                <strong>Tax: ${renderTax()}</strong>
                            </p>

                            <p>
                                <strong>Service Fee: ${roundNumber(serviceFeesTotal).toFixed(2)}</strong>
                            </p>

                            <hr />

                            {menuOrder?.serviceFees?.length === 1 && menuOrder?.serviceFees[0].description &&
                            <p>
                                {menuOrder.serviceFees[0].description}
                            </p>
                            }

                        </Tooltip>
                    </span>
                    <span>
                        {menuOrder?.callbackComplete
                            ? `$${getOrderFeesTaxes()}`
                            : <Skeleton height={17.5} width={40}/>
                        }
                    </span>
                </div>
                {menuOrder?.handoff === HandoffOptions.DELIVERY &&
                    <div className={classNames("checkout-summary_totals_row", {
                        "menu-order-discount": freeDelivery,
                    })}>
                        {renderDeliveryFee()}
                    </div>
                }
                {menuOrder?.discounts && renderPayments()}
            </div>
            <hr />

            {menuOrder?.handoff === HandoffOptions.DELIVERY &&
            <>
                <CheckoutDeliveryTips />
                <hr />
            </>
            }

            <div className="checkout-summary_totals_row checkout-summary_totals_total">
                <span>
                    <strong>Total</strong>
                </span>
                <span>
                    {menuOrder?.callbackComplete
                        ? <strong>${menuOrder?.stripeTotal.toFixed(2)}</strong>
                        : <Skeleton height={17.5} width={40}/>
                    }
                </span>
            </div>

        </>

    );
}

export default CheckoutTotals;
