import {ReactElement} from "react";
import Spacer from "@/components/Spacer";
import {isDesktop, isTablet} from "react-device-detect";
import classNames from "classnames";
import {FaCircleCheck} from "react-icons/fa6";
import {ActiveDpay} from "@/utils/getMenuOrderPaymentMethod";
import {useGetTransactions} from "@/hooks/useGetTransactions";
import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";

interface Props {
    insufficientVdpay: boolean;
    activeDpayMethod: ActiveDpay;
    handleMethodOnClick: () => Promise<void>;
    dpayFiat: number;
}

function CheckoutPaymentVdpayCard(props: Props): ReactElement {
    const currentUser = useSelector((store: IStore) => store.metaStore?.currentUser);
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const {data: transactionData} = useGetTransactions(fullToken, currentUser?.user?.id);

    return (
        <>
            <div
                className={classNames("checkout-payments-dpay-modal_body_dpay-account-card", {
                    "checkout-payments-dpay-modal_body_dpay-container_vdpay-insufficient": props.insufficientVdpay && props.activeDpayMethod !== ActiveDpay.VDPAY,
                    "checkout-payments-dpay-modal_body_dpay-container_card-inactive": !props.insufficientVdpay && props.activeDpayMethod !== ActiveDpay.VDPAY,
                    "checkout-payments-dpay-modal_body_dpay-container_card-active": !props.insufficientVdpay && props.activeDpayMethod === ActiveDpay.VDPAY,
                })}
                onClick={async () => await props.handleMethodOnClick()}
            >

                <div className="checkout-payments-dpay-modal_body_dpay-account-card_title">
                    <img
                        className="checkout-payment-option_card_icon"
                        src={import.meta.env.VITE_CDN_URL + "/images/FUEL.webp"}
                        alt="DPAY icon"
                    />
                    <p className="checkout-payments-dpay-modal_body_dpay-account-card_title_text">
                        My Account {import.meta.env.VITE_TOKEN_NAME}
                    </p>
                    {props.activeDpayMethod === ActiveDpay.VDPAY &&
                    <>
                        <Spacer/>
                        <FaCircleCheck
                            className="checkout-payments-dpay-modal_body_dpay-container_card-active_icon"/>
                    </>
                    }
                </div>

                <div
                    className="checkout-payments-dpay-modal_body_dpay-account-card_balance"
                >
                    <p className="checkout-payments-dpay-modal_body_dpay-account-card_balance_text">
                        My Balance
                    </p>

                    {(isDesktop || isTablet) && <Spacer/>}

                    <div className={classNames({
                        "checkout-payments-dpay-modal_body_dpay-account-card_balance_tag": !props.insufficientVdpay,
                        "checkout-payments-dpay-modal_body_dpay-account-card_balance_insufficient-tag": props.insufficientVdpay,
                    })}>
                        {transactionData?.balance &&
                        <p className="checkout-payments-dpay-modal_body_dpay-account-card_balance_amount">
                            <span>{Math.round(transactionData.balance)}</span> {import.meta.env.VITE_TOKEN_NAME}
                                | <span>${props.dpayFiat}</span> USD
                        </p>
                        }
                    </div>
                </div>

            </div>
        </>
    );
}

export default CheckoutPaymentVdpayCard;
