/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NftTracker,
    NftTrackerFromJSON,
    NftTrackerFromJSONTyped,
    NftTrackerToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetNftTrackerResponse
 */
export interface GetNftTrackerResponse {
    /**
     * 
     * @type {NftTracker}
     * @memberof GetNftTrackerResponse
     */
    nftTracker?: NftTracker;
    /**
     * 
     * @type {Array<NftTracker>}
     * @memberof GetNftTrackerResponse
     */
    nftTrackers?: Array<NftTracker>;
}

export function GetNftTrackerResponseFromJSON(json: any): GetNftTrackerResponse {
    return GetNftTrackerResponseFromJSONTyped(json, false);
}

export function GetNftTrackerResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetNftTrackerResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nftTracker': !exists(json, 'nftTracker') ? undefined : NftTrackerFromJSON(json['nftTracker']),
        'nftTrackers': !exists(json, 'nftTrackers') ? undefined : ((json['nftTrackers'] as Array<any>).map(NftTrackerFromJSON)),
    };
}

export function GetNftTrackerResponseToJSON(value?: GetNftTrackerResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nftTracker': NftTrackerToJSON(value.nftTracker),
        'nftTrackers': value.nftTrackers === undefined ? undefined : ((value.nftTrackers as Array<any>).map(NftTrackerToJSON)),
    };
}


