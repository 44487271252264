/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BusinessServiceAvailability,
    BusinessServiceAvailabilityFromJSON,
    BusinessServiceAvailabilityFromJSONTyped,
    BusinessServiceAvailabilityToJSON,
} from './';

/**
 * 
 * @export
 * @interface BusinessServiceAvailabilityBody
 */
export interface BusinessServiceAvailabilityBody {
    /**
     * 
     * @type {string}
     * @memberof BusinessServiceAvailabilityBody
     */
    timeZone: string;
    /**
     * 
     * @type {number}
     * @memberof BusinessServiceAvailabilityBody
     */
    suspendUntil?: number;
    /**
     * 
     * @type {Array<BusinessServiceAvailability>}
     * @memberof BusinessServiceAvailabilityBody
     */
    serviceAvailabilities: Array<BusinessServiceAvailability>;
}

export function BusinessServiceAvailabilityBodyFromJSON(json: any): BusinessServiceAvailabilityBody {
    return BusinessServiceAvailabilityBodyFromJSONTyped(json, false);
}

export function BusinessServiceAvailabilityBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): BusinessServiceAvailabilityBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'timeZone': json['timeZone'],
        'suspendUntil': !exists(json, 'suspendUntil') ? undefined : json['suspendUntil'],
        'serviceAvailabilities': ((json['serviceAvailabilities'] as Array<any>).map(BusinessServiceAvailabilityFromJSON)),
    };
}

export function BusinessServiceAvailabilityBodyToJSON(value?: BusinessServiceAvailabilityBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'timeZone': value.timeZone,
        'suspendUntil': value.suspendUntil,
        'serviceAvailabilities': ((value.serviceAvailabilities as Array<any>).map(BusinessServiceAvailabilityToJSON)),
    };
}


