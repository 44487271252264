/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PizzaCafeRequestBody
 */
export interface PizzaCafeRequestBody {
    /**
     * 
     * @type {number}
     * @memberof PizzaCafeRequestBody
     */
    level: number;
}

export function PizzaCafeRequestBodyFromJSON(json: any): PizzaCafeRequestBody {
    return PizzaCafeRequestBodyFromJSONTyped(json, false);
}

export function PizzaCafeRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PizzaCafeRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'level': json['level'],
    };
}

export function PizzaCafeRequestBodyToJSON(value?: PizzaCafeRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'level': value.level,
    };
}


