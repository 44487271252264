/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PhoneNumberBody,
    PhoneNumberBodyFromJSON,
    PhoneNumberBodyFromJSONTyped,
    PhoneNumberBodyToJSON,
} from './';

/**
 * 
 * @export
 * @interface UpdateProfileBody
 */
export interface UpdateProfileBody {
    /**
     * 
     * @type {string}
     * @memberof UpdateProfileBody
     */
    email: string;
    /**
     * 
     * @type {PhoneNumberBody}
     * @memberof UpdateProfileBody
     */
    phoneNumber?: PhoneNumberBody;
    /**
     * 
     * @type {string}
     * @memberof UpdateProfileBody
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProfileBody
     */
    lastName?: string;
}

export function UpdateProfileBodyFromJSON(json: any): UpdateProfileBody {
    return UpdateProfileBodyFromJSONTyped(json, false);
}

export function UpdateProfileBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateProfileBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : PhoneNumberBodyFromJSON(json['phoneNumber']),
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
    };
}

export function UpdateProfileBodyToJSON(value?: UpdateProfileBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'phoneNumber': PhoneNumberBodyToJSON(value.phoneNumber),
        'firstName': value.firstName,
        'lastName': value.lastName,
    };
}


