import {ReactElement, useContext} from "react";
import {useNavigate} from "react-router-dom";
import {isMobile, isTablet} from "react-device-detect";
import {useParams} from "react-router";
import {useMenuOrder} from "@/hooks/menuOrder/useMenuOrder";
import {RestaurantContext} from "@/pages/restaurants/context/RestaurantContext";
import {BrandMapStates} from "@/pages/brandMap/context/BrandMapContext";
import {useRestaurant} from "@/hooks/useRestaurant";

function CheckoutDetailsHeader(): ReactElement {
    const { menuOrderId: contextMenuOrderId, embeddedMenu, setCheckoutState } = useContext(RestaurantContext);
    const { menuOrderId: paramMenuOrderId } = useParams<{ menuOrderId: string }>();
    const menuOrderId = paramMenuOrderId || contextMenuOrderId;
    const navigate = useNavigate();

    const {data: menuOrder} = useMenuOrder(menuOrderId);
    const {data: restaurant} = useRestaurant(menuOrder?.business);

    return (

        <div className="checkout-details_header">

            {(isTablet || isMobile) &&
            <div
                className="checkout-details_back-link"
                onClick={() => {
                    if (paramMenuOrderId) {
                        navigate(`${restaurant?.url}/${menuOrder.address.placeId}`);
                    } else if (embeddedMenu) {
                        setCheckoutState(BrandMapStates.ORDER_IN_PROGRESS);
                    }
                }}
            >
                <div className="checkout-details_back-link">
                    <img
                        className="checkout-details_back-link_cancel"
                        src={import.meta.env.VITE_CDN_URL + "/images/cancel.svg"}
                        alt="cancel"
                    />
                </div>
            </div>
            }

            <div className="checkout-details_header_title">
                Checkout
            </div>

            <p className="checkout-details_header_restaurant">{restaurant?.name}</p>
        </div>
    );
}

export default CheckoutDetailsHeader;

