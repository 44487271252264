import {
    NftOwnershipInformation,
    NftGrouping,
    Restaurant,
    RestaurantSearchResult,
    OrderDiscountSearchEssential,
} from "@devour/client";
import {RestaurantSearchFiltersQuery} from "../../../utils/restaurantsSearchFilter";
import {ReactElement} from "react";
import {HiSparkles} from "react-icons/hi";

interface Props {
    restaurant: Restaurant | RestaurantSearchResult;
    restaurantFilters?: RestaurantSearchFiltersQuery;
    nftOwnershipResponse?: NftOwnershipInformation;
    showPromos: "on" | "off" | "auto";
}

function sortDiscounts(a: OrderDiscountSearchEssential, b: OrderDiscountSearchEssential): number {
    if (a.priority !== b.priority) {
        // Sort by priority descending
        return b.priority - a.priority;
    } else {
        // Sort by amount descending
        return b.amount - a.amount;
    }
}

function RestaurantCardGoFrens(props: Props): ReactElement {
    function getGrouping(groupingId: string): NftGrouping {
        const allNftGroupings = props.nftOwnershipResponse?.nftGroupings.concat(props.nftOwnershipResponse?.myGroupings);
        return allNftGroupings.find((grouping) => grouping.id === groupingId);
    }

    /**	List of discounts for this restaurant that the current user has access to */
    const nftActiveDiscounts: Array<OrderDiscountSearchEssential> = props.restaurant.promos?.filter((discount) => {
        return discount.nftGroupings?.find((nftGroupingId) => {
            return props.nftOwnershipResponse?.nftOwnerships?.[0]?.find((ownership) => ownership.nftGroupingId === nftGroupingId);
        });
    }) || [];

    /**	Find exclusive menu items for this restaurant that the current user has access to.
	 * Filter all hidden menu items by cross-referencing their NFT groupings to the NFTs that the user owns.
	 * */
    const nftExclusiveItems = props.restaurant.secretMenuItems?.filter((menuItem) => {
        return props.nftOwnershipResponse?.nftOwnerships?.[0]?.find((ownership) => menuItem.nftGroupings.includes(ownership.nftGroupingId));
    });

    /** List of discounts that should be displayed. As per Github #330 rules **/
    const nftActiveDiscountsRender: Array<OrderDiscountSearchEssential> = nftActiveDiscounts?.filter((discount) => {
        if (!discount.isDisplayedGoFrens) {
            return false;
        }
        if (props.showPromos === "on") {
            return true;
        }
        if (props.showPromos === "off") {
            return false;
        }
        // Check to make sure that we only show the discount if the user has a gofrens filter selected that is a grouping that is associated with this discount. Github #330
        return discount.nftGroupings.findIndex(grouping => props.restaurantFilters?.nftGroupings?.includes(grouping)) > -1;
    });
    const discountToRender: OrderDiscountSearchEssential = nftActiveDiscountsRender.sort(sortDiscounts)?.[0];

    if (!discountToRender && !nftExclusiveItems) {
        return null;
    }

    return (
        <div className="restaurant-card-go-frens">
            <div className="restaurant-card-go-frens_promo">
                {discountToRender &&
                <>
					    <div className="restaurant-card-go-frens_promo_image">
					        <img
					            src={getGrouping(discountToRender.nftGroupings?.[0])?.icon.url || import.meta.env.VITE_CDN_URL + "/images/token-white.webp"}
					            alt={getGrouping(discountToRender.nftGroupings?.[0])?.name}
					        />
					    </div>
					    <div
					        className="restaurant-card-go-frens_promo_label"
					        title={discountToRender.label}
					    >
					        {discountToRender.label}
					    </div>
                </>
                }
                {nftExclusiveItems.length > 0 &&
                <div className="restaurant-card-go-frens_promo_counter">
					    <HiSparkles/>
					    <span>{nftExclusiveItems.length}</span>
                </div>
                }
            </div>
        </div>
    );
}

export default RestaurantCardGoFrens;
