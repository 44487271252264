/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AccountLevel,
    AccountLevelFromJSON,
    AccountLevelToJSON,
    AddWalletRequestBody,
    AddWalletRequestBodyFromJSON,
    AddWalletRequestBodyToJSON,
    AdminDebitOrCreditLevelPointRequestBody,
    AdminDebitOrCreditLevelPointRequestBodyFromJSON,
    AdminDebitOrCreditLevelPointRequestBodyToJSON,
    ApiError,
    ApiErrorFromJSON,
    ApiErrorToJSON,
    AtlasHashResponse,
    AtlasHashResponseFromJSON,
    AtlasHashResponseToJSON,
    BanOrUnbanUserFromAllCommunitiesBody,
    BanOrUnbanUserFromAllCommunitiesBodyFromJSON,
    BanOrUnbanUserFromAllCommunitiesBodyToJSON,
    BanUserFromCommunityBody,
    BanUserFromCommunityBodyFromJSON,
    BanUserFromCommunityBodyToJSON,
    ChangePasswordBody,
    ChangePasswordBodyFromJSON,
    ChangePasswordBodyToJSON,
    ChangeUserPasswordBody,
    ChangeUserPasswordBodyFromJSON,
    ChangeUserPasswordBodyToJSON,
    CreateUserBody,
    CreateUserBodyFromJSON,
    CreateUserBodyToJSON,
    GetBannedCommunitiesResponse,
    GetBannedCommunitiesResponseFromJSON,
    GetBannedCommunitiesResponseToJSON,
    GetUserCommunityMembershipResponse,
    GetUserCommunityMembershipResponseFromJSON,
    GetUserCommunityMembershipResponseToJSON,
    GetUserIdResponse,
    GetUserIdResponseFromJSON,
    GetUserIdResponseToJSON,
    GetUserResponse,
    GetUserResponseFromJSON,
    GetUserResponseToJSON,
    GetUsersCsvResponse,
    GetUsersCsvResponseFromJSON,
    GetUsersCsvResponseToJSON,
    GetUsersResponse,
    GetUsersResponseFromJSON,
    GetUsersResponseToJSON,
    GetUsersSort,
    GetUsersSortFromJSON,
    GetUsersSortToJSON,
    ImportUserBody,
    ImportUserBodyFromJSON,
    ImportUserBodyToJSON,
    ImportUserResponse,
    ImportUserResponseFromJSON,
    ImportUserResponseToJSON,
    InlineResponse20017,
    InlineResponse20017FromJSON,
    InlineResponse20017ToJSON,
    InlineResponse20018,
    InlineResponse20018FromJSON,
    InlineResponse20018ToJSON,
    InlineResponse20019,
    InlineResponse20019FromJSON,
    InlineResponse20019ToJSON,
    LoginBody,
    LoginBodyFromJSON,
    LoginBodyToJSON,
    LoginResponse,
    LoginResponseFromJSON,
    LoginResponseToJSON,
    LoginType,
    LoginTypeFromJSON,
    LoginTypeToJSON,
    LootboxMintRecord,
    LootboxMintRecordFromJSON,
    LootboxMintRecordToJSON,
    NftOwnershipInformation,
    NftOwnershipInformationFromJSON,
    NftOwnershipInformationToJSON,
    RegisterBody,
    RegisterBodyFromJSON,
    RegisterBodyToJSON,
    RemoveWalletRequestBody,
    RemoveWalletRequestBodyFromJSON,
    RemoveWalletRequestBodyToJSON,
    SortOrder,
    SortOrderFromJSON,
    SortOrderToJSON,
    Token,
    TokenFromJSON,
    TokenToJSON,
    UpdateNicknameBody,
    UpdateNicknameBodyFromJSON,
    UpdateNicknameBodyToJSON,
    UpdateNotificationsBody,
    UpdateNotificationsBodyFromJSON,
    UpdateNotificationsBodyToJSON,
    UpdateProfileBody,
    UpdateProfileBodyFromJSON,
    UpdateProfileBodyToJSON,
    UpdateProfilePictureBody,
    UpdateProfilePictureBodyFromJSON,
    UpdateProfilePictureBodyToJSON,
    UpdateUserTypeBody,
    UpdateUserTypeBodyFromJSON,
    UpdateUserTypeBodyToJSON,
    UserType,
    UserTypeFromJSON,
    UserTypeToJSON,
} from '../models';

export interface AddSolanaWalletRequest {
    addWalletRequestBody?: AddWalletRequestBody;
}

export interface AddWalletRequest {
    addWalletRequestBody?: AddWalletRequestBody;
}

export interface AdminDebitOrCreditLevelPointsRequest {
    adminDebitOrCreditLevelPointRequestBody?: AdminDebitOrCreditLevelPointRequestBody;
}

export interface BanOrUnbanUserFromAllCommunitiesRequest {
    banOrUnbanUserFromAllCommunitiesBody?: BanOrUnbanUserFromAllCommunitiesBody;
}

export interface BanUserFromCommunityRequest {
    banUserFromCommunityBody?: BanUserFromCommunityBody;
}

export interface ChangePasswordRequest {
    changePasswordBody?: ChangePasswordBody;
}

export interface ChangeUserPasswordRequest {
    id: string;
    changeUserPasswordBody?: ChangeUserPasswordBody;
}

export interface DisableUserRequest {
    id: string;
}

export interface EnableUserRequest {
    id: string;
}

export interface FavoriteBusinessRequest {
    businessId?: string;
}

export interface GetBannedCommunitiesRequest {
    id: string;
}

export interface GetLevelRequest {
    id: string;
}

export interface GetUserRequest {
    id: string;
}

export interface GetUserCommunityMembershipRequest {
    id: string;
}

export interface GetUserIdRequest {
    email: string;
}

export interface GetUsersRequest {
    type?: Array<UserType>;
    search?: string;
    sort?: GetUsersSort;
    order?: SortOrder;
    limit?: number;
    offset?: number;
    numberOfAvailableOffsets?: number;
}

export interface GetUsersLevelCsvRequest {
    type?: Array<UserType>;
}

export interface ImportUsersRequest {
    importUserBody?: ImportUserBody;
}

export interface LoginRequest {
    type: LoginType;
    loginBody?: LoginBody;
}

export interface RegisterRequest {
    registerBody?: RegisterBody;
}

export interface RegisterMerchantRequest {
    createUserBody?: CreateUserBody;
}

export interface RemoveUserCommunityMembershipRequest {
    id: string;
}

export interface RemoveWalletRequest {
    removeWalletRequestBody?: RemoveWalletRequestBody;
}

export interface UnbanUserFromCommunityRequest {
    banUserFromCommunityBody?: BanUserFromCommunityBody;
}

export interface UpdateDefaultPaymentRequest {
    defaultPaymentMethodId?: string;
}

export interface UpdateLeaderboardNicknameRequest {
    updateNicknameBody?: UpdateNicknameBody;
}

export interface UpdateNftOwnershipForUserRequest {
    force?: string;
}

export interface UpdateNotificationsRequest {
    updateNotificationsBody?: UpdateNotificationsBody;
}

export interface UpdateProfileRequest {
    updateProfileBody?: UpdateProfileBody;
}

export interface UpdateProfilePictureRequest {
    updateProfilePictureBody?: UpdateProfilePictureBody;
}

export interface UpdateUserTypeRequest {
    id: string;
    updateUserTypeBody?: UpdateUserTypeBody;
}

/**
 * UsersApi - interface
 * @export
 * @interface UsersApiInterface
 */
export interface UsersApiInterface {
    /**
     * takes in the signature from a wallet to verify the public key.
     * @param {AddWalletRequestBody} [addWalletRequestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    addSolanaWalletRaw(requestParameters: AddSolanaWalletRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * takes in the signature from a wallet to verify the public key.
     */
    addSolanaWallet(requestParameters: AddSolanaWalletRequest): Promise<void>;

    /**
     * takes in the signature from a wallet to verify the public key.
     * @param {AddWalletRequestBody} [addWalletRequestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    addWalletRaw(requestParameters: AddWalletRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * takes in the signature from a wallet to verify the public key.
     */
    addWallet(requestParameters: AddWalletRequest): Promise<void>;

    /**
     * Allows an admin to debit or credit an arbitrary amount of dpay.
     * @param {AdminDebitOrCreditLevelPointRequestBody} [adminDebitOrCreditLevelPointRequestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    adminDebitOrCreditLevelPointsRaw(requestParameters: AdminDebitOrCreditLevelPointsRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Allows an admin to debit or credit an arbitrary amount of dpay.
     */
    adminDebitOrCreditLevelPoints(requestParameters: AdminDebitOrCreditLevelPointsRequest): Promise<void>;

    /**
     * Bans or unbans a user from all communities
     * @param {BanOrUnbanUserFromAllCommunitiesBody} [banOrUnbanUserFromAllCommunitiesBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    banOrUnbanUserFromAllCommunitiesRaw(requestParameters: BanOrUnbanUserFromAllCommunitiesRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Bans or unbans a user from all communities
     */
    banOrUnbanUserFromAllCommunities(requestParameters: BanOrUnbanUserFromAllCommunitiesRequest): Promise<void>;

    /**
     * Bans a user from a community, they will be unable to join until unbanned.
     * @param {BanUserFromCommunityBody} [banUserFromCommunityBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    banUserFromCommunityRaw(requestParameters: BanUserFromCommunityRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Bans a user from a community, they will be unable to join until unbanned.
     */
    banUserFromCommunity(requestParameters: BanUserFromCommunityRequest): Promise<void>;

    /**
     * Used by users to change their passwords.
     * @param {ChangePasswordBody} [changePasswordBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    changePasswordRaw(requestParameters: ChangePasswordRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Used by users to change their passwords.
     */
    changePassword(requestParameters: ChangePasswordRequest): Promise<void>;

    /**
     * Used by GOD admins to change user\'s passwords.
     * @param {string} id 
     * @param {ChangeUserPasswordBody} [changeUserPasswordBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    changeUserPasswordRaw(requestParameters: ChangeUserPasswordRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Used by GOD admins to change user\'s passwords.
     */
    changeUserPassword(requestParameters: ChangeUserPasswordRequest): Promise<void>;

    /**
     * Used by admins to disable a user
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    disableUserRaw(requestParameters: DisableUserRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Used by admins to disable a user
     */
    disableUser(requestParameters: DisableUserRequest): Promise<void>;

    /**
     * Used by GOD admins to enable a user
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    enableUserRaw(requestParameters: EnableUserRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Used by GOD admins to enable a user
     */
    enableUser(requestParameters: EnableUserRequest): Promise<void>;

    /**
     * Used by users to add or remove a business from their favorites list.
     * @param {string} [businessId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    favoriteBusinessRaw(requestParameters: FavoriteBusinessRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Used by users to add or remove a business from their favorites list.
     */
    favoriteBusiness(requestParameters: FavoriteBusinessRequest): Promise<void>;

    /**
     * Get user authentication hash for Atlas chat session.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    getAtlasHashRaw(): Promise<runtime.ApiResponse<AtlasHashResponse>>;

    /**
     * Get user authentication hash for Atlas chat session.
     */
    getAtlasHash(): Promise<AtlasHashResponse>;

    /**
     * Get all communities a user is banned from
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    getBannedCommunitiesRaw(requestParameters: GetBannedCommunitiesRequest): Promise<runtime.ApiResponse<GetBannedCommunitiesResponse>>;

    /**
     * Get all communities a user is banned from
     */
    getBannedCommunities(requestParameters: GetBannedCommunitiesRequest): Promise<GetBannedCommunitiesResponse>;

    /**
     * Get the leaderboard nickname of the current user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    getLeaderboardNicknameRaw(): Promise<runtime.ApiResponse<InlineResponse20019>>;

    /**
     * Get the leaderboard nickname of the current user.
     */
    getLeaderboardNickname(): Promise<InlineResponse20019>;

    /**
     * Get information regarding the user\'s level and experience.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    getLevelRaw(requestParameters: GetLevelRequest): Promise<runtime.ApiResponse<AccountLevel>>;

    /**
     * Get information regarding the user\'s level and experience.
     */
    getLevel(requestParameters: GetLevelRequest): Promise<AccountLevel>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    getNftOwnershipsForUserRaw(): Promise<runtime.ApiResponse<NftOwnershipInformation>>;

    /**
     */
    getNftOwnershipsForUser(): Promise<NftOwnershipInformation>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    getProfileRaw(): Promise<runtime.ApiResponse<GetUserResponse>>;

    /**
     */
    getProfile(): Promise<GetUserResponse>;

    /**
     * Get the top 15 users, ranked by total experience.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    getTopUsersByLeaderboardRankRaw(): Promise<runtime.ApiResponse<InlineResponse20017>>;

    /**
     * Get the top 15 users, ranked by total experience.
     */
    getTopUsersByLeaderboardRank(): Promise<InlineResponse20017>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    getUserRaw(requestParameters: GetUserRequest): Promise<runtime.ApiResponse<GetUserResponse>>;

    /**
     */
    getUser(requestParameters: GetUserRequest): Promise<GetUserResponse>;

    /**
     * Get information regarding the user\'s community, their rank and points earned for the community.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    getUserCommunityMembershipRaw(requestParameters: GetUserCommunityMembershipRequest): Promise<runtime.ApiResponse<GetUserCommunityMembershipResponse>>;

    /**
     * Get information regarding the user\'s community, their rank and points earned for the community.
     */
    getUserCommunityMembership(requestParameters: GetUserCommunityMembershipRequest): Promise<GetUserCommunityMembershipResponse>;

    /**
     * Given the user\'s email, will return the user\'s id
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    getUserIdRaw(requestParameters: GetUserIdRequest): Promise<runtime.ApiResponse<GetUserIdResponse>>;

    /**
     * Given the user\'s email, will return the user\'s id
     */
    getUserId(requestParameters: GetUserIdRequest): Promise<GetUserIdResponse>;

    /**
     * Get the leaderboard rank of the current user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    getUserLeaderboardRankRaw(): Promise<runtime.ApiResponse<InlineResponse20018>>;

    /**
     * Get the leaderboard rank of the current user.
     */
    getUserLeaderboardRank(): Promise<InlineResponse20018>;

    /**
     * get user\'s current level lootbox mint record
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    getUserLevelLootboxMintRecordRaw(): Promise<runtime.ApiResponse<LootboxMintRecord>>;

    /**
     * get user\'s current level lootbox mint record
     */
    getUserLevelLootboxMintRecord(): Promise<LootboxMintRecord>;

    /**
     * 
     * @param {Array<UserType>} [type] 
     * @param {string} [search] 
     * @param {GetUsersSort} [sort] 
     * @param {SortOrder} [order] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {number} [numberOfAvailableOffsets] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    getUsersRaw(requestParameters: GetUsersRequest): Promise<runtime.ApiResponse<GetUsersResponse>>;

    /**
     */
    getUsers(requestParameters: GetUsersRequest): Promise<GetUsersResponse>;

    /**
     * 
     * @param {Array<UserType>} [type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    getUsersLevelCsvRaw(requestParameters: GetUsersLevelCsvRequest): Promise<runtime.ApiResponse<GetUsersCsvResponse>>;

    /**
     */
    getUsersLevelCsv(requestParameters: GetUsersLevelCsvRequest): Promise<GetUsersCsvResponse>;

    /**
     * Used to import new users
     * @param {ImportUserBody} [importUserBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    importUsersRaw(requestParameters: ImportUsersRequest): Promise<runtime.ApiResponse<ImportUserResponse>>;

    /**
     * Used to import new users
     */
    importUsers(requestParameters: ImportUsersRequest): Promise<ImportUserResponse>;

    /**
     * Used to log in admins and merchants.
     * @param {LoginType} type 
     * @param {LoginBody} [loginBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    loginRaw(requestParameters: LoginRequest): Promise<runtime.ApiResponse<Token>>;

    /**
     * Used to log in admins and merchants.
     */
    login(requestParameters: LoginRequest): Promise<Token>;

    /**
     * Used to log in standard users.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    magicLoginRaw(): Promise<runtime.ApiResponse<LoginResponse>>;

    /**
     * Used to log in standard users.
     */
    magicLogin(): Promise<LoginResponse>;

    /**
     * Used to register a standard user.
     * @param {RegisterBody} [registerBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    registerRaw(requestParameters: RegisterRequest): Promise<runtime.ApiResponse<LoginResponse>>;

    /**
     * Used to register a standard user.
     */
    register(requestParameters: RegisterRequest): Promise<LoginResponse>;

    /**
     * Used to register a merchant user
     * @param {CreateUserBody} [createUserBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    registerMerchantRaw(requestParameters: RegisterMerchantRequest): Promise<runtime.ApiResponse<GetUserResponse>>;

    /**
     * Used to register a merchant user
     */
    registerMerchant(requestParameters: RegisterMerchantRequest): Promise<GetUserResponse>;

    /**
     * Used by users to remove their profile picture.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    removeProfilePictureRaw(): Promise<runtime.ApiResponse<void>>;

    /**
     * Used by users to remove their profile picture.
     */
    removeProfilePicture(): Promise<void>;

    /**
     * Removes a user from their current community
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    removeUserCommunityMembershipRaw(requestParameters: RemoveUserCommunityMembershipRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Removes a user from their current community
     */
    removeUserCommunityMembership(requestParameters: RemoveUserCommunityMembershipRequest): Promise<void>;

    /**
     * removes a wallet from the given account.
     * @param {RemoveWalletRequestBody} [removeWalletRequestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    removeWalletRaw(requestParameters: RemoveWalletRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * removes a wallet from the given account.
     */
    removeWallet(requestParameters: RemoveWalletRequest): Promise<void>;

    /**
     * Reset isEjectedFromCommunity for a user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    resetIsEjectedRaw(): Promise<runtime.ApiResponse<void>>;

    /**
     * Reset isEjectedFromCommunity for a user.
     */
    resetIsEjected(): Promise<void>;

    /**
     * Unbans a user from a community.
     * @param {BanUserFromCommunityBody} [banUserFromCommunityBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    unbanUserFromCommunityRaw(requestParameters: UnbanUserFromCommunityRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Unbans a user from a community.
     */
    unbanUserFromCommunity(requestParameters: UnbanUserFromCommunityRequest): Promise<void>;

    /**
     * Used by users to add, remove or update their default payment method.
     * @param {string} [defaultPaymentMethodId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    updateDefaultPaymentRaw(requestParameters: UpdateDefaultPaymentRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Used by users to add, remove or update their default payment method.
     */
    updateDefaultPayment(requestParameters: UpdateDefaultPaymentRequest): Promise<void>;

    /**
     * Update the leaderboard nickname of the current user.
     * @param {UpdateNicknameBody} [updateNicknameBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    updateLeaderboardNicknameRaw(requestParameters: UpdateLeaderboardNicknameRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Update the leaderboard nickname of the current user.
     */
    updateLeaderboardNickname(requestParameters: UpdateLeaderboardNicknameRequest): Promise<void>;

    /**
     * 
     * @param {string} [force] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    updateNftOwnershipForUserRaw(requestParameters: UpdateNftOwnershipForUserRequest): Promise<runtime.ApiResponse<void>>;

    /**
     */
    updateNftOwnershipForUser(requestParameters: UpdateNftOwnershipForUserRequest): Promise<void>;

    /**
     * Used by users to update their profile.
     * @param {UpdateNotificationsBody} [updateNotificationsBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    updateNotificationsRaw(requestParameters: UpdateNotificationsRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Used by users to update their profile.
     */
    updateNotifications(requestParameters: UpdateNotificationsRequest): Promise<void>;

    /**
     * Used by users to update their profile.
     * @param {UpdateProfileBody} [updateProfileBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    updateProfileRaw(requestParameters: UpdateProfileRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Used by users to update their profile.
     */
    updateProfile(requestParameters: UpdateProfileRequest): Promise<void>;

    /**
     * Used by users to update their profile picture.
     * @param {UpdateProfilePictureBody} [updateProfilePictureBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    updateProfilePictureRaw(requestParameters: UpdateProfilePictureRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Used by users to update their profile picture.
     */
    updateProfilePicture(requestParameters: UpdateProfilePictureRequest): Promise<void>;

    /**
     * Used by admins to change a user\'s type.
     * @param {string} id 
     * @param {UpdateUserTypeBody} [updateUserTypeBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    updateUserTypeRaw(requestParameters: UpdateUserTypeRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Used by admins to change a user\'s type.
     */
    updateUserType(requestParameters: UpdateUserTypeRequest): Promise<void>;

}

/**
 * no description
 */
export class UsersApi extends runtime.BaseAPI implements UsersApiInterface {

    /**
     * takes in the signature from a wallet to verify the public key.
     */
    async addSolanaWalletRaw(requestParameters: AddSolanaWalletRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("StandardUser", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/add-solana-wallet`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddWalletRequestBodyToJSON(requestParameters.addWalletRequestBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * takes in the signature from a wallet to verify the public key.
     */
    async addSolanaWallet(requestParameters: AddSolanaWalletRequest): Promise<void> {
        await this.addSolanaWalletRaw(requestParameters);
    }

    /**
     * takes in the signature from a wallet to verify the public key.
     */
    async addWalletRaw(requestParameters: AddWalletRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("StandardUser", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/add-wallet`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddWalletRequestBodyToJSON(requestParameters.addWalletRequestBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * takes in the signature from a wallet to verify the public key.
     */
    async addWallet(requestParameters: AddWalletRequest): Promise<void> {
        await this.addWalletRaw(requestParameters);
    }

    /**
     * Allows an admin to debit or credit an arbitrary amount of dpay.
     */
    async adminDebitOrCreditLevelPointsRaw(requestParameters: AdminDebitOrCreditLevelPointsRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("GodAdmin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/admin-debit-or-credit-level-points`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AdminDebitOrCreditLevelPointRequestBodyToJSON(requestParameters.adminDebitOrCreditLevelPointRequestBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Allows an admin to debit or credit an arbitrary amount of dpay.
     */
    async adminDebitOrCreditLevelPoints(requestParameters: AdminDebitOrCreditLevelPointsRequest): Promise<void> {
        await this.adminDebitOrCreditLevelPointsRaw(requestParameters);
    }

    /**
     * Bans or unbans a user from all communities
     */
    async banOrUnbanUserFromAllCommunitiesRaw(requestParameters: BanOrUnbanUserFromAllCommunitiesRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/ban-or-unban-user-from-all-communities`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BanOrUnbanUserFromAllCommunitiesBodyToJSON(requestParameters.banOrUnbanUserFromAllCommunitiesBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Bans or unbans a user from all communities
     */
    async banOrUnbanUserFromAllCommunities(requestParameters: BanOrUnbanUserFromAllCommunitiesRequest): Promise<void> {
        await this.banOrUnbanUserFromAllCommunitiesRaw(requestParameters);
    }

    /**
     * Bans a user from a community, they will be unable to join until unbanned.
     */
    async banUserFromCommunityRaw(requestParameters: BanUserFromCommunityRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/ban-user-from-community`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BanUserFromCommunityBodyToJSON(requestParameters.banUserFromCommunityBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Bans a user from a community, they will be unable to join until unbanned.
     */
    async banUserFromCommunity(requestParameters: BanUserFromCommunityRequest): Promise<void> {
        await this.banUserFromCommunityRaw(requestParameters);
    }

    /**
     * Used by users to change their passwords.
     */
    async changePasswordRaw(requestParameters: ChangePasswordRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/change-password`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ChangePasswordBodyToJSON(requestParameters.changePasswordBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Used by users to change their passwords.
     */
    async changePassword(requestParameters: ChangePasswordRequest): Promise<void> {
        await this.changePasswordRaw(requestParameters);
    }

    /**
     * Used by GOD admins to change user\'s passwords.
     */
    async changeUserPasswordRaw(requestParameters: ChangeUserPasswordRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling changeUserPassword.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("GodAdmin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/{id}/change-password`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ChangeUserPasswordBodyToJSON(requestParameters.changeUserPasswordBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Used by GOD admins to change user\'s passwords.
     */
    async changeUserPassword(requestParameters: ChangeUserPasswordRequest): Promise<void> {
        await this.changeUserPasswordRaw(requestParameters);
    }

    /**
     * Used by admins to disable a user
     */
    async disableUserRaw(requestParameters: DisableUserRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling disableUser.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/{id}/disable`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Used by admins to disable a user
     */
    async disableUser(requestParameters: DisableUserRequest): Promise<void> {
        await this.disableUserRaw(requestParameters);
    }

    /**
     * Used by GOD admins to enable a user
     */
    async enableUserRaw(requestParameters: EnableUserRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling enableUser.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/{id}/enable`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Used by GOD admins to enable a user
     */
    async enableUser(requestParameters: EnableUserRequest): Promise<void> {
        await this.enableUserRaw(requestParameters);
    }

    /**
     * Used by users to add or remove a business from their favorites list.
     */
    async favoriteBusinessRaw(requestParameters: FavoriteBusinessRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.businessId !== undefined) {
            queryParameters['businessId'] = requestParameters.businessId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/favorite-business`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Used by users to add or remove a business from their favorites list.
     */
    async favoriteBusiness(requestParameters: FavoriteBusinessRequest): Promise<void> {
        await this.favoriteBusinessRaw(requestParameters);
    }

    /**
     * Get user authentication hash for Atlas chat session.
     */
    async getAtlasHashRaw(): Promise<runtime.ApiResponse<AtlasHashResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/atlas-hash`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AtlasHashResponseFromJSON(jsonValue));
    }

    /**
     * Get user authentication hash for Atlas chat session.
     */
    async getAtlasHash(): Promise<AtlasHashResponse> {
        const response = await this.getAtlasHashRaw();
        return await response.value();
    }

    /**
     * Get all communities a user is banned from
     */
    async getBannedCommunitiesRaw(requestParameters: GetBannedCommunitiesRequest): Promise<runtime.ApiResponse<GetBannedCommunitiesResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getBannedCommunities.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/get-banned-communities/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetBannedCommunitiesResponseFromJSON(jsonValue));
    }

    /**
     * Get all communities a user is banned from
     */
    async getBannedCommunities(requestParameters: GetBannedCommunitiesRequest): Promise<GetBannedCommunitiesResponse> {
        const response = await this.getBannedCommunitiesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get the leaderboard nickname of the current user.
     */
    async getLeaderboardNicknameRaw(): Promise<runtime.ApiResponse<InlineResponse20019>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/leaderboard-nickname`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20019FromJSON(jsonValue));
    }

    /**
     * Get the leaderboard nickname of the current user.
     */
    async getLeaderboardNickname(): Promise<InlineResponse20019> {
        const response = await this.getLeaderboardNicknameRaw();
        return await response.value();
    }

    /**
     * Get information regarding the user\'s level and experience.
     */
    async getLevelRaw(requestParameters: GetLevelRequest): Promise<runtime.ApiResponse<AccountLevel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getLevel.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/level/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AccountLevelFromJSON(jsonValue));
    }

    /**
     * Get information regarding the user\'s level and experience.
     */
    async getLevel(requestParameters: GetLevelRequest): Promise<AccountLevel> {
        const response = await this.getLevelRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getNftOwnershipsForUserRaw(): Promise<runtime.ApiResponse<NftOwnershipInformation>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/users/nft-ownerships`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => NftOwnershipInformationFromJSON(jsonValue));
    }

    /**
     */
    async getNftOwnershipsForUser(): Promise<NftOwnershipInformation> {
        const response = await this.getNftOwnershipsForUserRaw();
        return await response.value();
    }

    /**
     */
    async getProfileRaw(): Promise<runtime.ApiResponse<GetUserResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/profile`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUserResponseFromJSON(jsonValue));
    }

    /**
     */
    async getProfile(): Promise<GetUserResponse> {
        const response = await this.getProfileRaw();
        return await response.value();
    }

    /**
     * Get the top 15 users, ranked by total experience.
     */
    async getTopUsersByLeaderboardRankRaw(): Promise<runtime.ApiResponse<InlineResponse20017>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/users/top-leaderboard-ranks`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20017FromJSON(jsonValue));
    }

    /**
     * Get the top 15 users, ranked by total experience.
     */
    async getTopUsersByLeaderboardRank(): Promise<InlineResponse20017> {
        const response = await this.getTopUsersByLeaderboardRankRaw();
        return await response.value();
    }

    /**
     */
    async getUserRaw(requestParameters: GetUserRequest): Promise<runtime.ApiResponse<GetUserResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getUser.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUserResponseFromJSON(jsonValue));
    }

    /**
     */
    async getUser(requestParameters: GetUserRequest): Promise<GetUserResponse> {
        const response = await this.getUserRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get information regarding the user\'s community, their rank and points earned for the community.
     */
    async getUserCommunityMembershipRaw(requestParameters: GetUserCommunityMembershipRequest): Promise<runtime.ApiResponse<GetUserCommunityMembershipResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getUserCommunityMembership.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/{id}/membership`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUserCommunityMembershipResponseFromJSON(jsonValue));
    }

    /**
     * Get information regarding the user\'s community, their rank and points earned for the community.
     */
    async getUserCommunityMembership(requestParameters: GetUserCommunityMembershipRequest): Promise<GetUserCommunityMembershipResponse> {
        const response = await this.getUserCommunityMembershipRaw(requestParameters);
        return await response.value();
    }

    /**
     * Given the user\'s email, will return the user\'s id
     */
    async getUserIdRaw(requestParameters: GetUserIdRequest): Promise<runtime.ApiResponse<GetUserIdResponse>> {
        if (requestParameters.email === null || requestParameters.email === undefined) {
            throw new runtime.RequiredError('email','Required parameter requestParameters.email was null or undefined when calling getUserId.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/users/id/{email}`.replace(`{${"email"}}`, encodeURIComponent(String(requestParameters.email))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUserIdResponseFromJSON(jsonValue));
    }

    /**
     * Given the user\'s email, will return the user\'s id
     */
    async getUserId(requestParameters: GetUserIdRequest): Promise<GetUserIdResponse> {
        const response = await this.getUserIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get the leaderboard rank of the current user.
     */
    async getUserLeaderboardRankRaw(): Promise<runtime.ApiResponse<InlineResponse20018>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/leaderboard-rank`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20018FromJSON(jsonValue));
    }

    /**
     * Get the leaderboard rank of the current user.
     */
    async getUserLeaderboardRank(): Promise<InlineResponse20018> {
        const response = await this.getUserLeaderboardRankRaw();
        return await response.value();
    }

    /**
     * get user\'s current level lootbox mint record
     */
    async getUserLevelLootboxMintRecordRaw(): Promise<runtime.ApiResponse<LootboxMintRecord>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user-lootbox-mint-record`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LootboxMintRecordFromJSON(jsonValue));
    }

    /**
     * get user\'s current level lootbox mint record
     */
    async getUserLevelLootboxMintRecord(): Promise<LootboxMintRecord> {
        const response = await this.getUserLevelLootboxMintRecordRaw();
        return await response.value();
    }

    /**
     */
    async getUsersRaw(requestParameters: GetUsersRequest): Promise<runtime.ApiResponse<GetUsersResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.type) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.numberOfAvailableOffsets !== undefined) {
            queryParameters['numberOfAvailableOffsets'] = requestParameters.numberOfAvailableOffsets;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUsersResponseFromJSON(jsonValue));
    }

    /**
     */
    async getUsers(requestParameters: GetUsersRequest): Promise<GetUsersResponse> {
        const response = await this.getUsersRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getUsersLevelCsvRaw(requestParameters: GetUsersLevelCsvRequest): Promise<runtime.ApiResponse<GetUsersCsvResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.type) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/userslvl`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUsersCsvResponseFromJSON(jsonValue));
    }

    /**
     */
    async getUsersLevelCsv(requestParameters: GetUsersLevelCsvRequest): Promise<GetUsersCsvResponse> {
        const response = await this.getUsersLevelCsvRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used to import new users
     */
    async importUsersRaw(requestParameters: ImportUsersRequest): Promise<runtime.ApiResponse<ImportUserResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/import`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ImportUserBodyToJSON(requestParameters.importUserBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ImportUserResponseFromJSON(jsonValue));
    }

    /**
     * Used to import new users
     */
    async importUsers(requestParameters: ImportUsersRequest): Promise<ImportUserResponse> {
        const response = await this.importUsersRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used to log in admins and merchants.
     */
    async loginRaw(requestParameters: LoginRequest): Promise<runtime.ApiResponse<Token>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling login.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/login/{type}`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginBodyToJSON(requestParameters.loginBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenFromJSON(jsonValue));
    }

    /**
     * Used to log in admins and merchants.
     */
    async login(requestParameters: LoginRequest): Promise<Token> {
        const response = await this.loginRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used to log in standard users.
     */
    async magicLoginRaw(): Promise<runtime.ApiResponse<LoginResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Magic", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/login/magic`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginResponseFromJSON(jsonValue));
    }

    /**
     * Used to log in standard users.
     */
    async magicLogin(): Promise<LoginResponse> {
        const response = await this.magicLoginRaw();
        return await response.value();
    }

    /**
     * Used to register a standard user.
     */
    async registerRaw(requestParameters: RegisterRequest): Promise<runtime.ApiResponse<LoginResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Magic", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/register`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RegisterBodyToJSON(requestParameters.registerBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginResponseFromJSON(jsonValue));
    }

    /**
     * Used to register a standard user.
     */
    async register(requestParameters: RegisterRequest): Promise<LoginResponse> {
        const response = await this.registerRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used to register a merchant user
     */
    async registerMerchantRaw(requestParameters: RegisterMerchantRequest): Promise<runtime.ApiResponse<GetUserResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Discriminated", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/register/merchant`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUserBodyToJSON(requestParameters.createUserBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUserResponseFromJSON(jsonValue));
    }

    /**
     * Used to register a merchant user
     */
    async registerMerchant(requestParameters: RegisterMerchantRequest): Promise<GetUserResponse> {
        const response = await this.registerMerchantRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used by users to remove their profile picture.
     */
    async removeProfilePictureRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/remove-profile-picture`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Used by users to remove their profile picture.
     */
    async removeProfilePicture(): Promise<void> {
        await this.removeProfilePictureRaw();
    }

    /**
     * Removes a user from their current community
     */
    async removeUserCommunityMembershipRaw(requestParameters: RemoveUserCommunityMembershipRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling removeUserCommunityMembership.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/{id}/remove-user-from-community`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Removes a user from their current community
     */
    async removeUserCommunityMembership(requestParameters: RemoveUserCommunityMembershipRequest): Promise<void> {
        await this.removeUserCommunityMembershipRaw(requestParameters);
    }

    /**
     * removes a wallet from the given account.
     */
    async removeWalletRaw(requestParameters: RemoveWalletRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("StandardUser", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/remove-wallet`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: RemoveWalletRequestBodyToJSON(requestParameters.removeWalletRequestBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * removes a wallet from the given account.
     */
    async removeWallet(requestParameters: RemoveWalletRequest): Promise<void> {
        await this.removeWalletRaw(requestParameters);
    }

    /**
     * Reset isEjectedFromCommunity for a user.
     */
    async resetIsEjectedRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/reset-community-ejection`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Reset isEjectedFromCommunity for a user.
     */
    async resetIsEjected(): Promise<void> {
        await this.resetIsEjectedRaw();
    }

    /**
     * Unbans a user from a community.
     */
    async unbanUserFromCommunityRaw(requestParameters: UnbanUserFromCommunityRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/unban-user-from-community`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BanUserFromCommunityBodyToJSON(requestParameters.banUserFromCommunityBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Unbans a user from a community.
     */
    async unbanUserFromCommunity(requestParameters: UnbanUserFromCommunityRequest): Promise<void> {
        await this.unbanUserFromCommunityRaw(requestParameters);
    }

    /**
     * Used by users to add, remove or update their default payment method.
     */
    async updateDefaultPaymentRaw(requestParameters: UpdateDefaultPaymentRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.defaultPaymentMethodId !== undefined) {
            queryParameters['defaultPaymentMethodId'] = requestParameters.defaultPaymentMethodId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/update-default-payment`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Used by users to add, remove or update their default payment method.
     */
    async updateDefaultPayment(requestParameters: UpdateDefaultPaymentRequest): Promise<void> {
        await this.updateDefaultPaymentRaw(requestParameters);
    }

    /**
     * Update the leaderboard nickname of the current user.
     */
    async updateLeaderboardNicknameRaw(requestParameters: UpdateLeaderboardNicknameRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/leaderboard-nickname`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateNicknameBodyToJSON(requestParameters.updateNicknameBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update the leaderboard nickname of the current user.
     */
    async updateLeaderboardNickname(requestParameters: UpdateLeaderboardNicknameRequest): Promise<void> {
        await this.updateLeaderboardNicknameRaw(requestParameters);
    }

    /**
     */
    async updateNftOwnershipForUserRaw(requestParameters: UpdateNftOwnershipForUserRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.force !== undefined) {
            queryParameters['force'] = requestParameters.force;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/update-nft-ownerships-for-user`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateNftOwnershipForUser(requestParameters: UpdateNftOwnershipForUserRequest): Promise<void> {
        await this.updateNftOwnershipForUserRaw(requestParameters);
    }

    /**
     * Used by users to update their profile.
     */
    async updateNotificationsRaw(requestParameters: UpdateNotificationsRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/update-notifications`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateNotificationsBodyToJSON(requestParameters.updateNotificationsBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Used by users to update their profile.
     */
    async updateNotifications(requestParameters: UpdateNotificationsRequest): Promise<void> {
        await this.updateNotificationsRaw(requestParameters);
    }

    /**
     * Used by users to update their profile.
     */
    async updateProfileRaw(requestParameters: UpdateProfileRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/update-profile`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateProfileBodyToJSON(requestParameters.updateProfileBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Used by users to update their profile.
     */
    async updateProfile(requestParameters: UpdateProfileRequest): Promise<void> {
        await this.updateProfileRaw(requestParameters);
    }

    /**
     * Used by users to update their profile picture.
     */
    async updateProfilePictureRaw(requestParameters: UpdateProfilePictureRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/update-profile-picture`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateProfilePictureBodyToJSON(requestParameters.updateProfilePictureBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Used by users to update their profile picture.
     */
    async updateProfilePicture(requestParameters: UpdateProfilePictureRequest): Promise<void> {
        await this.updateProfilePictureRaw(requestParameters);
    }

    /**
     * Used by admins to change a user\'s type.
     */
    async updateUserTypeRaw(requestParameters: UpdateUserTypeRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateUserType.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Discriminated", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/{id}/type`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserTypeBodyToJSON(requestParameters.updateUserTypeBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Used by admins to change a user\'s type.
     */
    async updateUserType(requestParameters: UpdateUserTypeRequest): Promise<void> {
        await this.updateUserTypeRaw(requestParameters);
    }

}
