import {PropsWithChildren, ReactElement} from "react";
import classNames from "classnames";

interface Props {
    className?: string;
}

function FrameModalFooter(props: PropsWithChildren<Props>): ReactElement {

    return (
        <div className={classNames("frame-one-modal_footer", props.className)}>
            {props.children}
        </div>
    );
}

export default FrameModalFooter;
