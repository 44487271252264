import {useQuery} from "@tanstack/react-query";
import {GetWithdrawsResponse, SortOrder, Token, WithdrawsApi} from "@devour/client";
import getConfig from "@/utils/getConfig";

interface WithdrawRequestsOwnQueryParams {
    order?: SortOrder;
    limit?: number;
    offset?: number;
}

async function fetchWithdrawRequestsOwn(fullToken: Token, params?: WithdrawRequestsOwnQueryParams): Promise<GetWithdrawsResponse> {
    return await new WithdrawsApi(getConfig(fullToken)).getWithdrawRequestsOwn(params);
}

function withdrawRequestsOwnQuery(fullToken: Token, params?: WithdrawRequestsOwnQueryParams) {
    return {
        queryKey: [
            "withdraw-requests",
            fullToken?.id ?? "unauthenticated",
            params,
        ],
        queryFn: () => fetchWithdrawRequestsOwn(fullToken, params),
        enabled: !!fullToken,
        keepPreviousData: true,
    } as const;
}

export function useGetWithdrawRequestsOwn(fullToken: Token, params?: WithdrawRequestsOwnQueryParams) {
    return useQuery(withdrawRequestsOwnQuery(fullToken, params));
}