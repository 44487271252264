import {ReactElement} from "react";
import Spacer from "@/components/Spacer";
import {isDesktop, isTablet} from "react-device-detect";
import classNames from "classnames";
import {FaCircleCheck} from "react-icons/fa6";
import {ActiveDpay} from "@/utils/getMenuOrderPaymentMethod";
import {useAccount} from "wagmi";

interface Props {
    insufficientExternal: boolean;
    activeDpayMethod: ActiveDpay;
    handleMethodOnClick: () => Promise<void>;
    wcTag: string;
}
function CheckoutPaymentExternalCard(props: Props): ReactElement {

    const account = useAccount();

    return (
        <div
            className={classNames(
                "checkout-payment-option_card",
                {
                    "checkout-payments-dpay-modal_body_dpay-container_card-insufficient": props.insufficientExternal && props.activeDpayMethod !== ActiveDpay.EXTERNAL,
                    "checkout-payments-dpay-modal_body_dpay-container_card-inactive": !props.insufficientExternal && props.activeDpayMethod !== ActiveDpay.EXTERNAL,
                    "checkout-payments-dpay-modal_body_dpay-container_card-active": !props.insufficientExternal && props.activeDpayMethod === ActiveDpay.EXTERNAL,
                },
            )}
            onClick={async () => await props.handleMethodOnClick()}
        >
            <img
                className={classNames("checkout-payments-dpay-modal_body_wc-icon", {
                    "checkout-payments-dpay-modal_body_wc-icon_enabled": !props.insufficientExternal,
                    "checkout-payments-dpay-modal_body_wc-icon_disabled": props.insufficientExternal,
                })}
                src={import.meta.env.VITE_CDN_URL + `/images/wallet-connect-icon${props.insufficientExternal
                    ? "-disabled"
                    : ""}.webp`}
                alt="Wallet connect icon"
            />
            <p className="checkout-payment-option_card_method">
                {account.isConnected
                    ? props.insufficientExternal && !(isTablet || isDesktop)
                        ? "External"
                        : "External Wallet"
                    : "Connect To External Wallet"
                }
            </p>
            {account.isConnected && (isDesktop || isTablet) &&
            <div className="checkout-payments-dpay-modal_body_wc-tag">
                <p>
                    {props.wcTag}
                </p>
            </div>
            }
            {props.activeDpayMethod === ActiveDpay.EXTERNAL &&
            <>
                <Spacer/>
                <FaCircleCheck
                    className="checkout-payments-dpay-modal_body_dpay-container_card-active_icon"/>
            </>
            }
            {props.insufficientExternal &&
            <>
                <Spacer/>
                <div className="checkout-payments-dpay-modal_body_dpay-container_card-insufficient_tag">
                    <p className="checkout-payments-dpay-modal_body_title-row_subheader">
                        {`${isDesktop || isTablet
                            ? "Insufficient"
                            : "No"} Balance`}
                    </p>
                </div>
            </>
            }
        </div>
    );
}

export default CheckoutPaymentExternalCard;
