/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ImportBusinessResponseItem,
    ImportBusinessResponseItemFromJSON,
    ImportBusinessResponseItemFromJSONTyped,
    ImportBusinessResponseItemToJSON,
} from './';

/**
 * 
 * @export
 * @interface ImportBusinessResponse
 */
export interface ImportBusinessResponse {
    /**
     * 
     * @type {Array<ImportBusinessResponseItem>}
     * @memberof ImportBusinessResponse
     */
    results?: Array<ImportBusinessResponseItem>;
}

export function ImportBusinessResponseFromJSON(json: any): ImportBusinessResponse {
    return ImportBusinessResponseFromJSONTyped(json, false);
}

export function ImportBusinessResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImportBusinessResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'results': !exists(json, 'results') ? undefined : ((json['results'] as Array<any>).map(ImportBusinessResponseItemFromJSON)),
    };
}

export function ImportBusinessResponseToJSON(value?: ImportBusinessResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'results': value.results === undefined ? undefined : ((value.results as Array<any>).map(ImportBusinessResponseItemToJSON)),
    };
}


