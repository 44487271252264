/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateBusinessSpecialInstructionsBody
 */
export interface UpdateBusinessSpecialInstructionsBody {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateBusinessSpecialInstructionsBody
     */
    specialInstructions: boolean;
}

export function UpdateBusinessSpecialInstructionsBodyFromJSON(json: any): UpdateBusinessSpecialInstructionsBody {
    return UpdateBusinessSpecialInstructionsBodyFromJSONTyped(json, false);
}

export function UpdateBusinessSpecialInstructionsBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateBusinessSpecialInstructionsBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'specialInstructions': json['specialInstructions'],
    };
}

export function UpdateBusinessSpecialInstructionsBodyToJSON(value?: UpdateBusinessSpecialInstructionsBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'specialInstructions': value.specialInstructions,
    };
}


