import {NftGrouping, NftOwnershipInformation} from "@devour/client";
import {FormEvent, ReactElement, ReactNode, useState} from "react";
import {sortNftGroupingsByPriority} from "@/utils/sortNftGroupingsByPriority";
import GoFrensNftGroupingsSkeleton from "@/components/skeletons/GoFrensPage/GoFrensNftGroupingsSkeleton";
import GoFrensCommunityCard from "@/components/goFrens/GoFrensCommunityCard";
import {ReactComponent as SearchIcon} from "../../svgs/search.svg";
import {isDesktop, isMobile, isTablet} from "react-device-detect";
import {useNavigate} from "react-router-dom";
import useThemePreference from "@/hooks/useThemePreference";

interface Props {
    nftOwnershipResponse: NftOwnershipInformation;
    isNftOwnershipResponseLoading: boolean;
}
const NUM_OF_OTHER_COMMUNITIES_TO_EXPLORE = 4;

export default function GoFrensHeaderNoRepCommunity(props: Props): ReactElement {
    const { isOnDarkMode } = useThemePreference();
    const [
        searchValue,
        setSearchValue,
    ] = useState<string>("");
    const history = useNavigate();

    function filterNftCommunitiesAndNfts() {
        // filter trackers with matching name
        const filteredGroupingsFromCollection = props.nftOwnershipResponse?.nftTrackers?.filter(tracker => tracker.name.toLowerCase().includes(searchValue.toLowerCase())).map(tracker => tracker.groupingId);

        // filter groupings with matching name or has the filtered tracker
        return props.nftOwnershipResponse?.myGroupings?.filter(grouping => grouping.name.toLowerCase().includes(searchValue.toLowerCase()) ||
            filteredGroupingsFromCollection.includes(grouping.id)).sort(sortNftGroupingsByPriority);

    }
    function handleSubmitSearch(e: FormEvent) {
        e.preventDefault();

        if (!searchValue) return null;

        const trimmedSearchValue = searchValue.trim();

        const filteredCommunities = filterNftCommunitiesAndNfts();
        const data = {
            keyword: trimmedSearchValue,
            communities: filteredCommunities,
            otherCommunities: [],
        };

        // get 4 other communities
        if (filteredCommunities.length === 0) {
            data.otherCommunities = props.nftOwnershipResponse?.nftGroupings
                .sort(() => Math.random() - 0.5)
                .slice(0, NUM_OF_OTHER_COMMUNITIES_TO_EXPLORE);
        }

        setSearchValue("");
        history("/gofriends/search-results", { state: data});
    }
    function renderNftGrouping(grouping: NftGrouping): ReactNode {
        let ownedCount = 0;

        if (props.nftOwnershipResponse?.nftOwnerships?.[0]) {
            for (const nftOwnership of props.nftOwnershipResponse?.nftOwnerships?.[0]) {
                if (props.nftOwnershipResponse?.nftTrackers?.find(t => t.id === nftOwnership.nftTrackerId)?.groupingId === grouping.id) {
                    ownedCount++;
                }
            }
        }

        return (
            <GoFrensCommunityCard
                key={grouping.id}
                grouping={grouping}
                trackers={props.nftOwnershipResponse?.nftTrackers?.filter(t => t.groupingId === grouping.id)}
                owned={ownedCount}
                onlyShowOwned={true}
                className="gofrens-rep-community-card"
                forceWidth={isMobile && !isTablet
                    ? "20rem"
                    : ""}
            />
        );
    }


    return (
        <div
            className="gofrens-header-no-rep"
            style={{backgroundImage: `url(${import.meta.env.VITE_CDN_URL + `/images/${isOnDarkMode ? "DM-" : ""}gofrens-rep-community-hero${isMobile && !isTablet
                ? "-3"
                : ""}.webp`})`}}
        >
            <div className="gofrens-header-no-rep_container">
                <div className="gofrens-header-no-rep_container_header">
                    <div className="gofrens-header-no-rep_container_header_top">
                        <h1 className="gofrens-header-no-rep_container_header_top_headline">
                            REP YOUR <span
                                className="gofrens-header-no-rep_container_header_top_highlight">COMMUNITY</span>
                        </h1>
                        <p className="gofrens-header-no-rep_container_header_top_subheader">
                            Join a community to get benefits, rewards, and access to exclusive menu items!
                        </p>
                    </div>
                    <form onSubmit={handleSubmitSearch} className="gofrens-header-no-rep_container_header_search">
                        <input
                            type="text"
                            placeholder="Search a community, collection, or NFT"
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                        />
                        <button className="gofrens-header-no-rep_container_header_search_button">
                            <span className="gofrens-header-no-rep_container_header_search_button_text">Let's go</span>
                            <SearchIcon />
                        </button>
                    </form>

                </div>
                <div className="gofrens-header-no-rep_container_communities">
                    <h3 className="gofrens-header-no-rep_container_communities_header">Rep a Community Now!</h3>
                    <div className="gofrens-header-no-rep_container_communities_body">
                        <div className="gofrens-header-no-rep_container_communities_body_cards">
                            {props.nftOwnershipResponse
                                ? props.nftOwnershipResponse?.myGroupings?.sort(sortNftGroupingsByPriority).map(renderNftGrouping)
                                : <GoFrensNftGroupingsSkeleton noOfCards={isDesktop
                                    ? 5
                                    : !isTablet && isMobile
                                        ? 2
                                        : 3}/>
                            }

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}