/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BannedUser
 */
export interface BannedUser {
    /**
     * 
     * @type {string}
     * @memberof BannedUser
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof BannedUser
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof BannedUser
     */
    nickname: string;
}

export function BannedUserFromJSON(json: any): BannedUser {
    return BannedUserFromJSONTyped(json, false);
}

export function BannedUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): BannedUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': json['userId'],
        'email': json['email'],
        'nickname': json['nickname'],
    };
}

export function BannedUserToJSON(value?: BannedUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'email': value.email,
        'nickname': value.nickname,
    };
}


