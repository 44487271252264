import {ButtonHTMLAttributes, ReactElement, useContext, useState} from "react";
import RestaurantPageAddressButton from "./RestaurantPageAddressButton";
import UserAccountButton from "../../../components/UserAccountButton";
import FrameButton from "../../../components/buttons/FrameButton";
import {FaShoppingCart} from "react-icons/fa";
import {RestaurantContext} from "../context/RestaurantContext";
import {refreshMenuOrderCart} from "@/redux/meta/metaActions";
import {useDispatch} from "react-redux";
import DevourTopNavUserData from "@/components/DevourTopNavUserData";
import Toast from "@/components/Toast";
import { useNavigate } from "react-router";
import SignUpToast from "@/components/SignUpToast";

interface Props {
    toggleShowCartPanel: () => void;
}

function RestaurantPageTopBar(props: Props): ReactElement {
    const dispatch = useDispatch();
    const {menuOrder} = useContext(RestaurantContext);
    const [
        showToast,
        setShowToast,
    ] = useState<boolean>(false);

    const sumQuantity: number = menuOrder?.orderItems.reduce((accumulator, object) => {
        return accumulator + object.quantity;
    }, 0);
    const history = useNavigate();
    function handleToastDismissal() {
        setShowToast(false);
    }
    function handleToastShow() {
        setShowToast(true);
    }

    function onCartClick(): void {
        dispatch(refreshMenuOrderCart());
        props.toggleShowCartPanel();
    }

    return (
        <>
            <SignUpToast
                message='Join DevourGO and start your GoFriends journey!'
                isOpen={showToast}
                onDismiss={handleToastDismissal}
            />
            <div className="restaurant-page-top-bar">
                <div className="restaurant-page-top-bar_logo">
                    <img
                        src={`${import.meta.env.VITE_CDN_URL}/images/DevourGO_Final-01.webp`}
                        alt="DevourGO Logo"
                    />
                </div>
                <div className="restaurant-page-top-bar_cart">
                    <div className="restaurant-page-top-bar_cart_button">
                        <FrameButton
                            <ButtonHTMLAttributes<HTMLButtonElement>>
                            onClick={onCartClick}
                            color="purple"
                            size="narrow"
                            leftIcon={FaShoppingCart}
                            forwardProps={{type: "button"}}
                        >
                            {sumQuantity || 0}
                        </FrameButton>
                    </div>
                </div>
                <div className="restaurant-page-top-bar_account">
                    <DevourTopNavUserData onShowSignInToast={handleToastShow} dropdownAnchorElement=".restaurant-page-top-bar" avatarOnly hideWalletOnMobile={true}/>
                </div>
                <div className="restaurant-page-top-bar_address">
                    <RestaurantPageAddressButton/>
                </div>
            </div>
        </>
    );
}

export default RestaurantPageTopBar;
