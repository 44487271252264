import {useQuery} from "@tanstack/react-query";
import {InlineResponse20017, Token, UsersApi} from "@devour/client";
import getConfig from "@/utils/getConfig";

async function fetchUserLeaderboardNickname(fullToken: Token): Promise<InlineResponse20017> {
    return await new UsersApi(getConfig(fullToken)).getLeaderboardNickname();
}

function useUserLeaderboardNicknameQuery(fullToken: Token) {
    return {
        queryKey: [
            "user-leaderboard-nickname",
            fullToken.id,
        ],
        queryFn: () => fullToken ? fetchUserLeaderboardNickname(fullToken) : null,
        staleTime: 24 * 60 * 60000,
        enabled: !!fullToken,
    } as const;
}

export function useGetUserLeaderboardNickname(fullToken: Token) {
    return useQuery(useUserLeaderboardNicknameQuery(fullToken));
}