/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PhoneNumberBody,
    PhoneNumberBodyFromJSON,
    PhoneNumberBodyFromJSONTyped,
    PhoneNumberBodyToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreateOnboardingSignedUrlBody
 */
export interface CreateOnboardingSignedUrlBody {
    /**
     * 
     * @type {string}
     * @memberof CreateOnboardingSignedUrlBody
     */
    email?: string;
    /**
     * 
     * @type {PhoneNumberBody}
     * @memberof CreateOnboardingSignedUrlBody
     */
    phoneNumber?: PhoneNumberBody;
    /**
     * 
     * @type {string}
     * @memberof CreateOnboardingSignedUrlBody
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOnboardingSignedUrlBody
     */
    lastName?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateOnboardingSignedUrlBody
     */
    wallets?: Array<string>;
    /**
     * The url to redirect the user to after they have completed the onboarding process. This should be passed in as a path with no domain (e.g. /restaurants/64248455c0fbdce66f9a5962/ChIJzZ-ZyFd7K4cRPbfzaT_Q124 would redirect to a restaurant page with the given path).
     * @type {string}
     * @memberof CreateOnboardingSignedUrlBody
     */
    redirectUrl?: string;
}

export function CreateOnboardingSignedUrlBodyFromJSON(json: any): CreateOnboardingSignedUrlBody {
    return CreateOnboardingSignedUrlBodyFromJSONTyped(json, false);
}

export function CreateOnboardingSignedUrlBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateOnboardingSignedUrlBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : PhoneNumberBodyFromJSON(json['phoneNumber']),
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'wallets': !exists(json, 'wallets') ? undefined : json['wallets'],
        'redirectUrl': !exists(json, 'redirectUrl') ? undefined : json['redirectUrl'],
    };
}

export function CreateOnboardingSignedUrlBodyToJSON(value?: CreateOnboardingSignedUrlBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'phoneNumber': PhoneNumberBodyToJSON(value.phoneNumber),
        'firstName': value.firstName,
        'lastName': value.lastName,
        'wallets': value.wallets,
        'redirectUrl': value.redirectUrl,
    };
}


