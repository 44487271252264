import {useQuery} from "@tanstack/react-query";
import {UsersApi, Token, NftOwnershipInformation} from "@devour/client";
import getConfig from "@/utils/getConfig";

async function fetchNftOwnershipsForUser(): Promise<NftOwnershipInformation> {
    return await new UsersApi(getConfig()).getNftOwnershipsForUser();
}

function nftOwnershipsQuery(fullToken: Token) {
    return {
        queryKey: [
            "nft-ownerships",
            fullToken?.id ?? "unauthenticated",
        ],
        queryFn: () => fetchNftOwnershipsForUser(),
        keepPreviousData: true,
        staleTime: 30 * 60000,
        enabled: !!fullToken,
    } as const;
}

export function useGetNftOwnershipsForUser(fullToken: Token) {
    return useQuery(nftOwnershipsQuery(fullToken));
}
