import {Restaurant} from "@devour/client";

enum RestaurantSearchPromo {
    DISCOUNT = "DISCOUNT",
    EXCLUSIVE = "EXCLUSIVE",
}

export interface RestaurantTextSearchResult {
    searchScore: number;
    _id: string;
    name: string;
}

export interface RestaurantSearchFiltersQuery {
    nftGroupings: Array<string>;
    promoTypes: Array<RestaurantSearchPromo>;
    taxonomyIds: Array<string>;
    radius: number;
    textSearch: string;
}

const kmInMile = 1.609;

/**
 * Distance filter temporarily disabled.
 * Max distance being handled by backend for now.
 * Set this default radius high for frontend distance check to to always return true.
 */
const defaultRadiusInMiles = 10000;

export const defaultRestaurantSearchFilters: RestaurantSearchFiltersQuery = {
    nftGroupings: [],
    promoTypes: [
        RestaurantSearchPromo.DISCOUNT,
        RestaurantSearchPromo.EXCLUSIVE,
    ],
    taxonomyIds: [],
    radius: Math.round(defaultRadiusInMiles * kmInMile * 1000),
    textSearch: "",
};

/**
 * https://github.com/Frame-One-Software/devour/issues/198
 * https://github.com/Frame-One-Software/devour/issues/254
 * Need to figure out which NFTs and categories are "active" for these groups of businesses.
 * Thus, need to query ALL the businesses (within the radius).
 * No point in backend filtering / pagination anymore. Return everything and let the frontend handle it.
 * This is the filter function to return a boolean for the array `filter()` check.
 * @param restaurant
 * @param restaurantFilters
 */
export function restaurantsSearchFilter(restaurant: Restaurant, restaurantFilters: RestaurantSearchFiltersQuery): boolean {
    // if (restaurantFilters.textSearchResults) {
    // 	const findRestaurantInResults = restaurantFilters.textSearchResults.find((result) => result._id === restaurant.id);
    // 	if (!findRestaurantInResults) {
    // 		return false;
    // 	}
    // }

    if (restaurantFilters.nftGroupings.length) {
        let hasPromo: boolean = false;
        if (restaurantFilters.promoTypes?.includes(RestaurantSearchPromo.DISCOUNT)) {
            const checkDiscount = (nftGroupingId: string) => restaurant.promos?.find((discount) => discount.isDisplayedGoFrens && discount.nftGroupings?.includes(nftGroupingId));
            if (restaurantFilters.nftGroupings.some(checkDiscount)) {
                hasPromo = true;
            }
        }

        if (restaurantFilters.promoTypes?.includes(RestaurantSearchPromo.EXCLUSIVE)) {

            const checkExclusive = restaurant.secretMenuItems?.filter((menuItem) => {
                return restaurantFilters.nftGroupings?.find((nftGroupingId) => menuItem.nftGroupings?.includes(nftGroupingId));
            });

            if (checkExclusive.length > 0) {
                hasPromo = true;
            }
        }

        if (!hasPromo) {
            return false;
        }
    }

    if (restaurant.distance * 1000 > restaurantFilters.radius) {
        return false;
    }

    if (restaurantFilters.taxonomyIds.length) {
        const checkTaxonomy = (filterTaxonomyId: string) => restaurant.taxonomies.find((businessTaxonomyId: string) => filterTaxonomyId === businessTaxonomyId);
        if (!restaurantFilters.taxonomyIds.some(checkTaxonomy)) {
            return false;
        }
    }

    return true;
}
