import {ReactElement, Dispatch, SetStateAction, useState, useCallback, useEffect, ChangeEvent} from "react";
import {RestaurantSearchFiltersQuery} from "@/utils/restaurantsSearchFilter";
import {AiOutlineSearch} from "react-icons/ai";
import _ from "lodash";

interface Props {
    restaurantFilters: RestaurantSearchFiltersQuery;
    updateRestaurantFilters: Dispatch<SetStateAction<RestaurantSearchFiltersQuery>>;
}

function RestaurantSearchTextInput(props: Props): ReactElement {
    const [
        searchValue,
        setSearchValue,
    ] = useState<string>("");
    const debounceLoadData = useCallback(_.debounce(textSearchRestaurants, 1000), []);

    useEffect(() => {
        void debounceLoadData(searchValue);
    }, [searchValue]);

    /**
	 * On debounce, update filter to requery api.
	 */
    function textSearchRestaurants(searchValue: string): void {
        props.updateRestaurantFilters({
            ...props.restaurantFilters,
            textSearch: searchValue,
        });
    }

    function onSearchChange(e: ChangeEvent<HTMLInputElement>): void {
        setSearchValue(e.target.value);
    }

    return (
        <div className="restaurant-search-text-input">
            <AiOutlineSearch/>
            <input
                type="search"
                value={searchValue}
                onChange={onSearchChange}
                placeholder="Search a restaurant or dishes"
            />
        </div>
    );
}

export default RestaurantSearchTextInput;
