import useThemePreference from "@/hooks/useThemePreference";
import {ReactElement} from "react";

interface Props {
    height?: string;
}

function Divider(props: Props): ReactElement {

    const {isOnDarkMode} = useThemePreference();
    const borderColor = isOnDarkMode
        ? "#212123"
        : "#F7F7F8";
    const border = `${props?.height
        ? props.height
        : "0.5rem"} solid ${borderColor}`;

    return (
        <div className="section-divider" style={{borderBottom: border }} />
    );
}

export default Divider;
