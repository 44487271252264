/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MenuCategory,
    MenuCategoryFromJSON,
    MenuCategoryFromJSONTyped,
    MenuCategoryToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetMenuResponse
 */
export interface GetMenuResponse {
    /**
     * 
     * @type {string}
     * @memberof GetMenuResponse
     */
    currency: string;
    /**
     * 
     * @type {Array<MenuCategory>}
     * @memberof GetMenuResponse
     */
    menus: Array<MenuCategory>;
}

export function GetMenuResponseFromJSON(json: any): GetMenuResponse {
    return GetMenuResponseFromJSONTyped(json, false);
}

export function GetMenuResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetMenuResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'currency': json['currency'],
        'menus': ((json['menus'] as Array<any>).map(MenuCategoryFromJSON)),
    };
}

export function GetMenuResponseToJSON(value?: GetMenuResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'currency': value.currency,
        'menus': ((value.menus as Array<any>).map(MenuCategoryToJSON)),
    };
}


