/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GameDataBody
 */
export interface GameDataBody {
    /**
     * 
     * @type {string}
     * @memberof GameDataBody
     */
    gameId: string;
    /**
     * 
     * @type {string}
     * @memberof GameDataBody
     */
    gameData: string;
}

export function GameDataBodyFromJSON(json: any): GameDataBody {
    return GameDataBodyFromJSONTyped(json, false);
}

export function GameDataBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): GameDataBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'gameId': json['gameId'],
        'gameData': json['gameData'],
    };
}

export function GameDataBodyToJSON(value?: GameDataBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'gameId': value.gameId,
        'gameData': value.gameData,
    };
}


