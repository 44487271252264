import { FC } from "react";
interface props {
    title: string | JSX.Element;
    subtitle: string;
    image: string;
}

const OnboardingModalCommonStep: FC<props> = ({image, subtitle, title}) => {

    return (
        <>
            <div className="onboarding-modal_common-step">
                <div className="onboarding-modal_common-step_image">
                    <img src={`${import.meta.env.VITE_CDN_URL}${image}`} alt={subtitle} />
                </div>
                <div className="onboarding-modal_common-step_title">
                    <span>{title}</span>
                    <p>{subtitle}</p>
                </div>

            </div>
        </>
    );

};

export default OnboardingModalCommonStep;
