import {ButtonHTMLAttributes, ReactElement} from "react";
import FrameOneModal from "./modalComponents/FrameOneModal";
import {FiX} from "react-icons/fi";
import FrameButton from "@/components/buttons/FrameButton";
import {useDispatch, useSelector} from "react-redux";
import {
    toggleIndustryPassModal,
    toggleLoginOpen,
    updateIsIndustryDismissed,
    updateIsIndustryDismissedForSession,
} from "@/redux/meta/metaActions";
import {IStore} from "@/redux/defaultStore";
import {isDesktop, isTablet} from "react-device-detect";

interface Props {
    isOpen: boolean;
    toggle: () => void;
}

function IndustryPassPromoModal(props: Props): ReactElement {

    const dispatch = useDispatch();

    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);

    function toggleMintIndustryCollectionNftDpayModal(): void {
        dispatch(updateIsIndustryDismissedForSession(true));
        props.toggle();
        if (fullToken) {
            dispatch(toggleIndustryPassModal(true));
        } else {
            dispatch(toggleLoginOpen(true));
        }
    }

    return (
        <FrameOneModal
            isOpen={props.isOpen}
            toggle={() => {
                dispatch(updateIsIndustryDismissedForSession(true));
                props.toggle();
            }}
            contentClassName="industry-pass-promo-modal"
            size={isDesktop || isTablet
                ? "lg"
                : "sm"}
        >
            <div
                className="industry-pass-promo-modal_container"
                style={{
                    backgroundImage: isDesktop || isTablet
                        ? `url(${import.meta.env.VITE_CDN_URL}/images/industry-promo-fuel-desktop.png)`
                        : `url(${import.meta.env.VITE_CDN_URL}/images/industry-promo-fuel-mobile.png)`,
                }}
            >
                <div className="industry-pass-promo-modal_container_close-icon-wrapper">
                    <FiX
                        className="industry-pass-promo-modal_container_close-icon-wrapper_close"
                        onClick={() => {
                            dispatch(updateIsIndustryDismissedForSession(true));
                            props.toggle();
                        }}
                    />
                </div>

                <div
                    className="industry-pass-promo-modal_container_info">
                    <p className="industry-pass-promo-modal_container_info_text">
                        Get $0 Delivery Fee and more exclusive benefits!
                    </p>
                    <FrameButton
                        <ButtonHTMLAttributes<HTMLButtonElement>>
                        color="white-drop-shadow"
                        size="narrow"
                        className="industry-pass-promo-modal_container_info_button"
                        onClick={toggleMintIndustryCollectionNftDpayModal}
                    >
                        Save with the Industry Pass
                    </FrameButton>
                    <button
                        className="industry-pass-promo-modal_container_info_dismiss"
                        onClick={() => {
                            dispatch(updateIsIndustryDismissed(true));
                            dispatch(updateIsIndustryDismissedForSession(true));
                            props.toggle();
                        }}
                    >
                        Don't show again
                    </button>
                </div>
            </div>
        </FrameOneModal>
    );
}

export default IndustryPassPromoModal;
