/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CommunityMember
 */
export interface CommunityMember {
    /**
     * 
     * @type {string}
     * @memberof CommunityMember
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CommunityMember
     */
    nickname: string;
    /**
     * 
     * @type {string}
     * @memberof CommunityMember
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CommunityMember
     */
    userRank: string;
    /**
     * 
     * @type {number}
     * @memberof CommunityMember
     */
    pointsEarnedForCommunity: number;
}

export function CommunityMemberFromJSON(json: any): CommunityMember {
    return CommunityMemberFromJSONTyped(json, false);
}

export function CommunityMemberFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommunityMember {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'nickname': json['nickname'],
        'email': json['email'],
        'userRank': json['userRank'],
        'pointsEarnedForCommunity': json['pointsEarnedForCommunity'],
    };
}

export function CommunityMemberToJSON(value?: CommunityMember | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'nickname': value.nickname,
        'email': value.email,
        'userRank': value.userRank,
        'pointsEarnedForCommunity': value.pointsEarnedForCommunity,
    };
}


