import {FC, useState} from "react";
import {MdOutlineContentCopy} from "react-icons/md";
import {useSelector} from "react-redux";
import {IStore} from "../redux/defaultStore";
import FrameButton from "./buttons/FrameButton";
import CopiedInput from "./inputs/ReferralCopiedInput";
import {isGoNative} from "../utils/isGoNative";
import {useGetOwnReferralCode} from "@/hooks/useGetOwnReferralCode";

const ReferralCodeGeneration: FC = () => {

    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const [
        isCodeCopied,
        setIsCodeCopied,
    ] = useState<boolean>(false);
    const [
        isLinkCopied,
        setIsLinkCopied,
    ] = useState<boolean>(false);

    const {data: ownReferralCodeData} = useGetOwnReferralCode(fullToken);
    const referralCode = ownReferralCodeData?.referralCodes?.[0]?.code;

    return (
        <div className="referral-code-generation">

            <div className="referral-code-generation_container">

                <div className="referral-code-generation_row">

                    <label> My referral code </label>

                    <div className="referral-code-generation_input-container_input-row">
                        <div className="referral-code-generation_input-container">
                            <CopiedInput
                                className="referral-code-generation_input-container_input"
                                disabled={true}
                                value={referralCode}
                                isCopiedVisible={isCodeCopied}
                            />
                        </div>
                        <FrameButton
                            color="gray"
                            size="normal"
                            leftIcon={MdOutlineContentCopy}
                            onClick={() => {
                                setIsCodeCopied(true);
                                setIsLinkCopied(false);
                                if (isGoNative()) {
                                    // @ts-ignore
                                    gonative.clipboard.set({"data": referralCode}); // for app to copy to clipboard
                                } else {
                                    navigator.clipboard.writeText(referralCode);
                                }
                            }}
                        >
							Copy
                        </FrameButton>

                    </div>

                </div>

                <div className="referral-code-generation_row">

                    <label> My referral link </label>

                    <div className="referral-code-generation_input-container_input-row">
                        <div className="referral-code-generation_input-container">
                            <CopiedInput
                                className="referral-code-generation_input-container_input"
                                disabled={true}
                                value={`${import.meta.env.VITE_WEB_URL}/?ref=${encodeURI(referralCode)}`}
                                isCopiedVisible={isLinkCopied}
                            />
                        </div>
                        <FrameButton
                            color="gray"
                            size="normal"
                            leftIcon={MdOutlineContentCopy}
                            onClick={() => {
                                setIsCodeCopied(false);
                                setIsLinkCopied(true);
                                if (isGoNative()) {
                                    // @ts-ignore
                                    gonative.clipboard.set({
                                        "data": `${import.meta.env.VITE_WEB_URL}/?ref=${encodeURI(referralCode)}`,
                                    }); // for app to copy to clipboard
                                } else {
                                    navigator.clipboard.writeText(`${import.meta.env.VITE_WEB_URL}/?ref=${encodeURI(referralCode)}`);
                                }
                            }}
                        >
							Copy
                        </FrameButton>

                    </div>

                </div>

            </div>

        </div>
    );
};

export default ReferralCodeGeneration;
