import React, {ReactElement, useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";

interface Props {
    dropdownBody: ReactElement;
    dropdownBodyClassName: string;
    dropdownToggleElement: ReactElement;
}

function Dropdown(props: Props): ReactElement {

    const isModalActive = useSelector((store: IStore) => store.metaStore.activeModals);
    const [
        showDropdown,
        setShowDropdown,
    ] = useState<boolean>(false);

    const dropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // Handle both touch and mouse events
        document.addEventListener("mousedown", closeDropdown);
        document.addEventListener("touchstart", closeDropdown);

        return () => {
            document.removeEventListener("mousedown", closeDropdown);
            document.removeEventListener("touchstart", closeDropdown);
        };
    }, [isModalActive]);

    function closeDropdown(event: MouseEvent) {
        if (!isModalActive) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setShowDropdown(false);
            }
        }
    }

    function handleToggleDropdown(): void {
        setShowDropdown(!showDropdown);
    }

    function handleStopPropagation(e: React.MouseEvent | React.TouchEvent): void {
        e.stopPropagation();
    }

    function renderDropdown(): ReactElement {
        return (
            <div
                className={props.dropdownBodyClassName}
                ref={dropdownRef}
                style={{
                    opacity: !showDropdown
                        ? "0"
                        : "1",
                    transition: "all .2s",
                    visibility: !showDropdown
                        ? "hidden"
                        : "visible",
                }}
            >
                {props.dropdownBody}
            </div>
        );
    }

    return (
        <>
            <div
                ref={dropdownRef}
                onClick={handleToggleDropdown}
                onMouseDown={handleStopPropagation}
                onTouchStart={handleStopPropagation}
            >
                {props.dropdownToggleElement}
            </div>
            {renderDropdown()}
        </>
    );
}

export default Dropdown;
