import {ReactElement} from "react";

function TermsOfUsePage(): ReactElement {

    return (
        <div className="legal-page">
            <iframe
                title="Terms of Use"
                src="https://app.termly.io/document/terms-of-use-for-online-marketplace/d9b19993-aa96-4ded-a162-88e077ec7cb2"
                className="legal-page_content"
            />
        </div>
    );
}

export default TermsOfUsePage;

