/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiError,
    ApiErrorFromJSON,
    ApiErrorToJSON,
    DeleteAccountRequestBody,
    DeleteAccountRequestBodyFromJSON,
    DeleteAccountRequestBodyToJSON,
    DeletionVerificationCode,
    DeletionVerificationCodeFromJSON,
    DeletionVerificationCodeToJSON,
} from '../models';

export interface DeleteAccountRequest {
    deleteAccountRequestBody?: DeleteAccountRequestBody;
}

/**
 * AccountDeletionApi - interface
 * @export
 * @interface AccountDeletionApiInterface
 */
export interface AccountDeletionApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountDeletionApiInterface
     */
    createDeletionVerificationCodeRaw(): Promise<runtime.ApiResponse<DeletionVerificationCode>>;

    /**
     */
    createDeletionVerificationCode(): Promise<DeletionVerificationCode>;

    /**
     * 
     * @param {DeleteAccountRequestBody} [deleteAccountRequestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountDeletionApiInterface
     */
    deleteAccountRaw(requestParameters: DeleteAccountRequest): Promise<runtime.ApiResponse<void>>;

    /**
     */
    deleteAccount(requestParameters: DeleteAccountRequest): Promise<void>;

}

/**
 * no description
 */
export class AccountDeletionApi extends runtime.BaseAPI implements AccountDeletionApiInterface {

    /**
     */
    async createDeletionVerificationCodeRaw(): Promise<runtime.ApiResponse<DeletionVerificationCode>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("StandardUser", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/account-deletion-verification`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DeletionVerificationCodeFromJSON(jsonValue));
    }

    /**
     */
    async createDeletionVerificationCode(): Promise<DeletionVerificationCode> {
        const response = await this.createDeletionVerificationCodeRaw();
        return await response.value();
    }

    /**
     */
    async deleteAccountRaw(requestParameters: DeleteAccountRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("StandardUser", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/account-deletion`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: DeleteAccountRequestBodyToJSON(requestParameters.deleteAccountRequestBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteAccount(requestParameters: DeleteAccountRequest): Promise<void> {
        await this.deleteAccountRaw(requestParameters);
    }

}
