import {ReactElement} from "react";
interface Props {
    rows?: number;
    width?: string;
}
function GoFrensMyCommunityLeaderboardSkeleton(props: Props): ReactElement {
    return (
        <div
            className="my-community-leaderboard_skeleton-adjust"
            style={{width: props?.width
                ? props.width
                : "100%" }}
        >
            {Array.from({length: props.rows}, (_, i) => <div
                key={i}
                style={{
                    height: 80,
                }}
                className="react-loading-skeleton"
            />)}
        </div>
    );
}

export default GoFrensMyCommunityLeaderboardSkeleton;