import {Token} from "@devour/client";
import {IAction} from "../IAction";
import {AuthAction} from "./authReducer";

export function login(token: Token): IAction<AuthAction.LOGIN, Token> {
    return {
        type: AuthAction.LOGIN,
        payload: token,
    };
}

export function logout(): IAction<AuthAction.LOGOUT> {
    return {
        type: AuthAction.LOGOUT,
    };
}
