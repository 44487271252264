import {useLocation} from "react-router";
import {FC, useEffect} from "react";
import {useGA4React} from "ga-4-react";

const NavigationTracking: FC = () => {
    const location = useLocation();
    const ga = useGA4React();

    useEffect(() => {
        if (ga) {
            ga.pageview(location.pathname);
        }

    }, [location.pathname]);

    return null;
};

export default NavigationTracking;