import {Link} from "react-router-dom";

function BrandLandingFooter() {
    return (
        <footer className="restaurant-map-landing_footer">
            <div className="restaurant-map-landing_footer_logo">
                <img
                    src={import.meta.env.VITE_CDN_URL + "/images/DevourGO_Final-01.webp"}
                    alt="DevourGO Logo"
                />
            </div>
            <div className="restaurant-map-landing_footer_disclaimer">
                <div className="restaurant-map-landing_footer_disclaimer_options">
                    <Link
                        to="/privacy-policy"
                        rel="noopener noreferrer"
                    >
                        Privacy Policy
                    </Link>
                    <span> | </span>
                    <Link
                        to="/terms-of-use"
                        rel="noopener noreferrer"
                    >
                        Terms of Use
                    </Link>
                    <span> | </span>
                    <Link
                        to="/cookie-policy"
                        rel="noopener noreferrer"
                    >
                        Cookie Policy
                    </Link>
                </div>
                <div className="restaurant-map-landing_footer_disclaimer_text">
                    © 2024 DevourGo
                </div>
            </div>
        </footer>
    );
}

export default BrandLandingFooter;