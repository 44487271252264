import {ReactElement, useState} from "react";
import ManageAddressBookModal from "./modals/ManageAddressBookModal";
import FrameButton from "./buttons/FrameButton";


function AccountPageManageAddressBook(): ReactElement {
    const [
        showAddressModal,
        setShowAddressModal,
    ] = useState(false);

    function toggleShowAddressModal(): void {
        setShowAddressModal(s => !s);
    }

    return (
        <>
            <ManageAddressBookModal
                isOpen={showAddressModal}
                onClose={toggleShowAddressModal}
            />

            <div className="account-page-manage-address-book">
                <h3>Address Book</h3>

                <FrameButton
                    color="gray"
                    size="normal"
                    onClick={toggleShowAddressModal}
                >
					Manage Addresses
                </FrameButton>
            </div>
        </>
    );
}

export default AccountPageManageAddressBook;
