import {ReactElement, SelectHTMLAttributes} from "react";
import {FiChevronDown} from "react-icons/fi";
import classNames from "classnames";

function FrameOneSelect(props: SelectHTMLAttributes<HTMLSelectElement>): ReactElement {
    return (
        <div className="f1-select-wrapper">
            <select {...props}/>

            <div
                className={classNames("f1-select-wrapper_icon-container", {
                    "f1-select-wrapper_icon-container_disabled": props.disabled,
                })}
            >
                <FiChevronDown/>
            </div>
        </div>
    );
}

export default FrameOneSelect;
