/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserLvlAllOf,
    UserLvlAllOfFromJSON,
    UserLvlAllOfFromJSONTyped,
    UserLvlAllOfToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserLvl
 */
export interface UserLvl {
    /**
     * 
     * @type {string}
     * @memberof UserLvl
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof UserLvl
     */
    level: number;
    /**
     * 
     * @type {string}
     * @memberof UserLvl
     */
    email: string;
    /**
     * 
     * @type {number}
     * @memberof UserLvl
     */
    id?: number;
}

export function UserLvlFromJSON(json: any): UserLvl {
    return UserLvlFromJSONTyped(json, false);
}

export function UserLvlFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserLvl {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'level': json['level'],
        'email': json['email'],
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function UserLvlToJSON(value?: UserLvl | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'level': value.level,
        'email': value.email,
        'id': value.id,
    };
}


