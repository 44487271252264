import {ReactElement, useContext, useEffect, useState} from "react";
import FrameOneAutoPanel from "@/components/modals/autoPanelComponents/FrameOneAutoPanel";
import FrameAutoPanelBody from "@/components/modals/autoPanelComponents/FrameAutoPanelBody";
import CheckoutPaymentCreditModalHeader
    from "@/components/checkout/checkoutPayments/credit/CheckoutPaymentCreditModalHeader";
import {useDispatch, useSelector} from "react-redux";
import {StripePaymentMethodObject} from "@/types/Stripe";
import {ActivePaymentMethod, getMenuOrderPaymentMethod} from "@/utils/getMenuOrderPaymentMethod";
import CheckoutPaymentCreditButton from "@/components/checkout/checkoutPayments/credit/CheckoutPaymentCreditButton";
import CheckoutPaymentAddCreditButton
    from "@/components/checkout/checkoutPayments/credit/CheckoutPaymentAddCreditButton";
import CheckoutPaymentAddCreditModal from "@/components/checkout/checkoutPayments/credit/CheckoutPaymentAddCreditModal";
import {useGetStripePaymentMethodList} from "@/hooks/useGetStripePaymentMethodList";
import {IStore} from "@/redux/defaultStore";
import {useGetUserProfile} from "@/hooks/useGetUserProfile";
import {isMobile, isTablet} from "react-device-detect";
import Skeleton from "@/components/skeletons/Skeleton";
import classNames from "classnames";
import renderCardInfo from "@/utils/renderCardInfo";
import {useAddCreditPaymentMethod} from "@/hooks/creditPaymentMethod/useAddCreditPaymentMethod";
import {useParams} from "react-router";
import {useSelectCreditPaymentMethod} from "@/hooks/creditPaymentMethod/useSelectCreditPaymentMethod";
import {addError, updateCurrentUser} from "@/redux/meta/metaActions";
import {UsersApi} from "@devour/client";
import getConfig from "@/utils/getConfig";
import {useMenuOrder} from "@/hooks/menuOrder/useMenuOrder";
import {RestaurantContext} from "@/pages/restaurants/context/RestaurantContext";

interface Props {
    isOpen: boolean;
    toggle: () => void;
}

function CheckoutPaymentCreditModal(props: Props): ReactElement {
    const { menuOrderId: contextMenuOrderId } = useContext(RestaurantContext);
    const { menuOrderId: paramMenuOrderId } = useParams<{ menuOrderId: string }>();
    const menuOrderId = paramMenuOrderId || contextMenuOrderId;

    const [
        showAddCreditModal,
        setShowAddCreditModal,
    ] = useState<boolean>(false);

    const dispatch = useDispatch();
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);

    // Fetch list of the user's Stripe payment methods
    const {data: paymentMethodData} = useGetStripePaymentMethodList(fullToken);
    const paymentMethods = (paymentMethodData?.paymentMethods) as Array<StripePaymentMethodObject>;

    // Fetch user profile
    const {data: userProfileData} = useGetUserProfile(fullToken);

    // Fetch menu order
    const {data: menuOrder} = useMenuOrder(menuOrderId);
    const menuOrderPaymentMethod = getMenuOrderPaymentMethod(menuOrder, paymentMethods);

    /**
     * Use React Query to add a credit card to payment methods
     * isLoading is used to display a skeleton while the mutation is in progress
     */
    const {
        mutate: addCreditCard,
        isPending: isAddCreditCardLoading,
    } = useAddCreditPaymentMethod(menuOrderId);

    /**
     * Uses React Query to select a credit card as the payment method
     */
    const {
        mutate: selectCreditCard,
        isPending: isSelectCreditCardLoading,
    } = useSelectCreditPaymentMethod(menuOrderId);

    // If user has a default credit card selected, set it as the active payment method
    useEffect(() => {
        if (props.isOpen) {
            setShowAddCreditModal(paymentMethods?.length === 0);
        }
    }, [
        paymentMethods,
        paymentMethodData,
        userProfileData,
        props.isOpen,
    ]);

    /**
     * Handles the credit card selection event.
     * @param selected
     * @param payment
     */
    async function handleCreditCardSelection(selected: boolean, payment: StripePaymentMethodObject): Promise<void> {
        if (!selected) {
            try {
                selectCreditCard(payment);
                const userRes = await new UsersApi(getConfig(fullToken)).getProfile();
                dispatch(updateCurrentUser(userRes));
            } catch (e) {
                dispatch(await addError(e));
            }
        }
    }

    /**
     * Renders the credit card payment method buttons
     * If there is only one card left, and you delete this card, the modal will close
     *
     * @param payment - A Stripe payment method object
     */
    function renderPaymentMethods(payment: StripePaymentMethodObject[]): JSX.Element {

        const isLastAndOnlyCard = payment.length === 1;

        return (
            <>
                {payment.map((payment: StripePaymentMethodObject) => {
                    const selected = payment.id === menuOrder.paymentMethodId &&
                        menuOrderPaymentMethod?.method === ActivePaymentMethod.CREDIT;

                    return isAddCreditCardLoading || isSelectCreditCardLoading
                        ? <Skeleton
                            key={payment.id}
                            rows={1}
                            height={50}
                        />
                        : <CheckoutPaymentCreditButton
                            exitOnDelete={isLastAndOnlyCard} // If last card, close modal on delete
                            key={payment.id}
                            info={renderCardInfo(payment)}
                            payment={payment}
                            onClose={props.toggle}
                            selected={selected}
                            selectCreditCard={async () => {
                                await handleCreditCardSelection(selected, payment);
                            }}
                        />;
                })}
            </>
        );
    }

    /**
     * Close all modals (Add a credit card modal and the credit card modal)
     */
    function onCloseAllModals() {
        setShowAddCreditModal(false);
        props.toggle();
    }

    function onCloseAddCreditModal(): void {
        setShowAddCreditModal(!showAddCreditModal);
    }

    return (
        <>
            <CheckoutPaymentAddCreditModal
                isOpen={showAddCreditModal}
                noCards={paymentMethods && paymentMethods.length === 0}
                onCloseAll={onCloseAllModals}
                onClose={onCloseAddCreditModal}
                addCreditCard={addCreditCard}
            />

            {(paymentMethods?.length > 0 || isAddCreditCardLoading) &&
            <FrameOneAutoPanel
                isOpen={props.isOpen}
                toggle={props.toggle}
                contentClassName={classNames("checkout-payments-credit", {
                    "checkout-payments-credit_mobile": isMobile && !isTablet,
                })}
                modalOnTablet={true}
                size="sm2"
            >
                <CheckoutPaymentCreditModalHeader
                    isDefaultCard={true}
                    onClose={props.toggle}
                />

                <FrameAutoPanelBody className="checkout-payments-credit_buttons">

                    {isAddCreditCardLoading && paymentMethods.length === 0
                        ? <Skeleton rows={1} height={50}/>
                        : <>
                            {renderPaymentMethods(paymentMethods)}
                            <CheckoutPaymentAddCreditButton
                                onClick={() => setShowAddCreditModal(true)}
                            />
                        </>

                    }

                </FrameAutoPanelBody>

            </FrameOneAutoPanel>
            }
        </>
    );
}

export default CheckoutPaymentCreditModal;
