import {ButtonHTMLAttributes, ReactElement, useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import GoFrensMyCommunityLeaderboardBoard from "@/components/goFrens/GoFrensMyCommunityLeaderboardBoard";
import FrameAutoPanelHeader from "@/components/modals/autoPanelComponents/FrameAutoPanelHeader";
import FrameOneSelect from "@/components/inputs/FrameOneSelect";
import FrameOneAutoPanel from "@/components/modals/autoPanelComponents/FrameOneAutoPanel";
import {LeaderboardsDateRange, GoFrensApi, RankedMember} from "@devour/client";
import getConfig from "@/utils/getConfig";
import {addError} from "@/redux/meta/metaActions";
import FrameAutoPanelBody from "@/components/modals/autoPanelComponents/FrameAutoPanelBody";
import GoFrensMyCommunityLeaderboardSkeleton
    from "@/components/skeletons/GoFrensPage/GoFrensMyCommunityLeaderboardSkeleton";
import {getDateInEst} from "@/utils/getDateInEst";
import FrameButton from "@/components/buttons/FrameButton";
import {FaRankingStar} from "react-icons/fa6";
import {ViewMode} from "@/components/goFrens/GoFrensTopLeaderboard";
import GoFrensTopLeaderboardCommunityTableHeader
    from "@/components/goFrens/topLeaderboard/GoFrensTopLeaderboardCommunityTableHeader";

interface Props {
    show: boolean;
    toggle: () => void;
    renderedData: Array<RankedMember>;
    setQuery: React.Dispatch<React.SetStateAction<string>>;
}

function GoFrensMonthlyTopMembersModal(props: Props): ReactElement {
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const dispatch = useDispatch();

    const now = getDateInEst();
    const currentMonth = `${now.getMonth() + 1},${now.getFullYear()}`;
    const [
        queryParam,
        setQueryParam,
    ] = useState<string>(currentMonth);

    const [
        localData,
        setLocalData,
    ] = useState<Array<RankedMember>>(props.renderedData);

    const [
        viewModeTopMembers,
        setViewModeTopMembers,
    ] = useState<ViewMode>(ViewMode.MONTHLY);

    const [
        dateRange,
        setDateRange,
    ] = useState<Array<LeaderboardsDateRange>>(undefined);

    useEffect(() => {
        if (props.show) {
            void getDateRange();
        }
    }, [props.show]);

    useEffect(() => {
        setLocalData(undefined);
        void getMonthlyLeaderboardData(`${queryParam},${viewModeTopMembers}`);
    }, [
        viewModeTopMembers,
        queryParam,
    ]);

    async function getMonthlyLeaderboardData(leaderboardTimeParam: string): Promise<void> {

        try {
            const res = await new GoFrensApi(getConfig(fullToken)).getMonthlyTopMembersLeaderboard({
                leaderboardTimeParam: leaderboardTimeParam,
            });
            setLocalData(res.topMembers);
        } catch (e) {
            dispatch(await addError(e));
        }
    }

    function toggleViewMode() {
        if (viewModeTopMembers === ViewMode.MONTHLY) {
            setViewModeTopMembers(ViewMode.CUMULATIVE);
        } else {
            setViewModeTopMembers(ViewMode.MONTHLY);
        }
    }

    // Function generates the view mode button content
    function viewModeButtonContent(): string {
        return viewModeTopMembers === ViewMode.MONTHLY
            ? "Monthly"
            : "All-Time";
    }

    async function getDateRange(): Promise<void> {
        try {
            const res = await new GoFrensApi(getConfig(fullToken)).getLeaderboardsDateRange();
            setDateRange(res.dates);

        } catch (e) {
            dispatch(await addError(e));
        }
    }

    function onCloseModal() {
        props.setQuery(currentMonth);
        props.toggle();
    }

    return (
        <FrameOneAutoPanel
            size="md"
            isOpen={props.show}
            toggle={onCloseModal}
            contentClassName="gofrens-members-leaderboard-modal-view"
        >
            <FrameAutoPanelHeader
                title="Top 50 Members"
                toggle={onCloseModal}
                showXBottomPanel={true}
            />

            <FrameAutoPanelBody className="members-leaderboard-modal-view_body">

                <div className="gofrens-top-leaderboard_button-container">

                    <div className="gofrens-top-leaderboard_button-container-item1">
                        <FrameOneSelect
                            className="gofrens-members-leaderboard-modal-view_select"
                            onChange={(e) => {
                                setQueryParam(e.target.value);
                            }}
                        >
                            {dateRange &&
                                <>
                                    <option value={currentMonth}>Current Month</option>
                                    {dateRange.map((date) => {
                                        return date.dateParam !== currentMonth &&
                                            <option key={date.dateParam} value={date.dateParam}>
                                                {date.dateValue}
                                            </option>;

                                    })}
                                </>
                            }
                        </FrameOneSelect>
                    </div>

                    <FrameButton
                        <ButtonHTMLAttributes<HTMLButtonElement>>
                        className="gofrens-top-leaderboard_view-mode-button
                        gofrens-top-leaderboard_button-container-item2"
                        color="gray"
                        size="pill"
                        onClick={toggleViewMode}
                        rightIcon={FaRankingStar}
                    >
                        <div className="gofrens-top-leaderboard_view-mode">
                            {viewModeButtonContent()}
                        </div>
                    </FrameButton>
                </div>

                <div className="my-community-leaderboard">
                    <GoFrensTopLeaderboardCommunityTableHeader isModal={true}/>

                    {localData
                        ? <GoFrensMyCommunityLeaderboardBoard
                            rankData={localData}
                            className="members-leaderboard-modal-view_body_board"
                            isModal={true}
                        />
                        : <GoFrensMyCommunityLeaderboardSkeleton rows={5}/>
                    }
                </div>
            </FrameAutoPanelBody>
        </FrameOneAutoPanel>

    );
}

export default GoFrensMonthlyTopMembersModal;