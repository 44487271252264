import {StripePaymentMethodObject} from "@/types/Stripe";
import {capitalize} from "lodash";

/**
 * Function takes a stripe payment method object,
 * and renders the card's info into a string like "Visa ****4242"
 *
 * @param payment - A Stripe payment method object
 */
function renderCardInfo(payment: StripePaymentMethodObject): string {
    return (
        capitalize(payment.card.brand) +
        " " +
        "****" +
        payment.card.last4
    );
}

export default renderCardInfo;