/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ImportBusinessBodyItem,
    ImportBusinessBodyItemFromJSON,
    ImportBusinessBodyItemFromJSONTyped,
    ImportBusinessBodyItemToJSON,
} from './';

/**
 * 
 * @export
 * @interface ImportBusinessBody
 */
export interface ImportBusinessBody {
    /**
     * 
     * @type {Array<ImportBusinessBodyItem>}
     * @memberof ImportBusinessBody
     */
    data?: Array<ImportBusinessBodyItem>;
}

export function ImportBusinessBodyFromJSON(json: any): ImportBusinessBody {
    return ImportBusinessBodyFromJSONTyped(json, false);
}

export function ImportBusinessBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImportBusinessBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(ImportBusinessBodyItemFromJSON)),
    };
}

export function ImportBusinessBodyToJSON(value?: ImportBusinessBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(ImportBusinessBodyItemToJSON)),
    };
}


