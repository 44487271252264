import {ReactElement} from "react";

interface Props {
    level: number;
}

function GoVipLevelsSquare(props: Props): ReactElement {

    return (
        <div className="go-vip-levels_body_cards-container_card_square
            go-vip-levels_body_cards-container_card_square_enabled"
        >
            <p> Level </p>
            <h4> {props.level} </h4>
        </div>
    );
}

export default GoVipLevelsSquare;
