
interface Props {
    checked: boolean,
    onChange: (checked: boolean) => void,
}

const ToggleSwitch: React.FC<Props> = ({checked, onChange}) => {
    const handleOnChange = () => {
        onChange(!checked);
    };

    return <div className="toggle-switch">
        <div className="switch-input pretty p-switch p-fill">
            <input type="checkbox" checked={checked} onChange={handleOnChange}/>
            <div className="state">
                <label/>
            </div>
        </div>
    </div>;
};

export default ToggleSwitch;