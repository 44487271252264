import {ReactElement, Dispatch, SetStateAction, useState, ChangeEvent, KeyboardEvent} from "react";
import {defaultRestaurantSearchFilters, RestaurantSearchFiltersQuery} from "@/utils/restaurantsSearchFilter";
import {AiFillCloseCircle, AiOutlineSearch} from "react-icons/ai";
import {IoIosArrowBack} from "react-icons/io";

interface Props {
    restaurantFilters: RestaurantSearchFiltersQuery;
    updateRestaurantFilters: Dispatch<SetStateAction<RestaurantSearchFiltersQuery>>;
}

function RestaurantSearchTextInputMobile(props: Props): ReactElement {
    const [
        searchValue,
        setSearchValue,
    ] = useState<string>("");
    const [
        focus,
        setFocus,
    ] = useState<boolean>(false);

    function onFocus(): void {
        setFocus(true);
    }

    function onEnter(e: KeyboardEvent<HTMLInputElement>) {
        if (e.key === "Enter") {
            e.currentTarget.blur();
        }
    }

    function onBlur(): void {
        textSearchRestaurants(searchValue);
        // Need to set timeout because the cancel button disappears off focus, so it needs to stay long enough for the user to click on it
        setTimeout(() => {
            setFocus(false);
        }, 100);
    }

    function onCancel() {
        setSearchValue("");
        props.updateRestaurantFilters(defaultRestaurantSearchFilters);
    }

    /**
     * On debounce, update filter to requery api.
     */
    function textSearchRestaurants(searchValue: string): void {
        props.updateRestaurantFilters({
            ...props.restaurantFilters,
            textSearch: searchValue,
        });
    }

    function onSearchChange(e: ChangeEvent<HTMLInputElement>): void {
        setSearchValue(e.target.value);
    }

    return (
        <div className="restaurant-search-text-input-mobile">
            {props.restaurantFilters.textSearch && !focus &&
            <div className="restaurant-search-text-input-mobile_back">
                <button
                    className="reset-button"
                    type="button"
                    onClick={onCancel}
                >
                    <IoIosArrowBack/>
                </button>
            </div>
            }
            <div className="restaurant-search-text-input-mobile_field">
                <AiOutlineSearch className="restaurant-search-text-input-mobile_field_icon-search"/>
                <input
                    type="search"
                    value={searchValue}
                    onChange={onSearchChange}
                    placeholder="Search a restaurant or dishes"
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onKeyUp={onEnter}
                />
                {searchValue && focus &&
                <div className="restaurant-search-text-input-mobile_field_icon-close">
                    <button
                        className="reset-button"
                        type="button"
                        onClick={onCancel}
                    >
                        <AiFillCloseCircle/>
                    </button>
                </div>
                }
            </div>
            {focus &&
            <div className="restaurant-search-text-input-mobile_cancel">
                <button
                    className="reset-button"
                    type="button"
                    onClick={onCancel}
                >
                        Cancel
                </button>
            </div>
            }
        </div>
    );
}

export default RestaurantSearchTextInputMobile;
