import {ButtonHTMLAttributes, ReactElement} from "react";
import {useDispatch} from "react-redux";
import {addError} from "@/redux/meta/metaActions";
import FrameOneAutoPanel from "@/components/modals/autoPanelComponents/FrameOneAutoPanel";
import FrameAutoPanelHeader from "@/components/modals/autoPanelComponents/FrameAutoPanelHeader";
import FrameAutoPanelBody from "@/components/modals/autoPanelComponents/FrameAutoPanelBody";
import FrameButton from "@/components/buttons/FrameButton";
import {PiWarningCircle} from "react-icons/pi";
import {useDeleteCreditPaymentMethod} from "@/hooks/creditPaymentMethod/useDeleteCreditPaymentMethod";

interface Props {
    isOpen: boolean;
    onClose: () => void; // Closes remove card confirmation modal
    paymentId: string;
    exitOnDelete: boolean; // Is it the last and only card?
    exit: () => void; // Exit modal if it is the last and only card
    deleteCreditCard: ReturnType<typeof useDeleteCreditPaymentMethod>["mutate"];
}

function CheckoutPaymentRemoveCreditModal(props: Props): ReactElement {
    const dispatch = useDispatch();

    /**
     * When user confirms the removal of a card,
     * Close the remove card confirmation modal and start skeletoning that card's button.
     * Delete the card from Stripe payment methods.
     * Clear the user's default payment method if the deleted card was the default.
     */
    async function onRemoveConfirm(): Promise<void> {
        props.onClose();

        try {
            props.deleteCreditCard(props.paymentId);
        } catch (e) {
            dispatch(await addError(e));
        }
    }

    return (
        <FrameOneAutoPanel
            isOpen={props.isOpen}
            toggle={props.onClose}
            size="sm"
            contentClassName="card-payment-remove-modal"
            modalOnTablet={true}>

            <FrameAutoPanelHeader
                title=""
                toggle={props.onClose}
                modalOnMobile={true}
                modalOnTablet={true}
            />

            <FrameAutoPanelBody
                modalOnTablet={true}
                modalOnMobile={true}
                className="checkout-payments-credit_remove-modal-body">

                <PiWarningCircle className="checkout-payments-credit_red-icon"/>

                <p className="checkout-payments-credit_remove-modal-body_title">
                    Remove Credit Card?
                </p>

                <p className="checkout-payments-credit_remove-modal-body_message">
                    Are you sure you want to remove this credit card?
                    This action cannot be undone after you click confirm.
                </p>

                <FrameButton
                    <ButtonHTMLAttributes<HTMLButtonElement>>
                    color="danger"
                    size="normal"
                    onClick={onRemoveConfirm}
                    className="checkout-payments-credit_remove-modal-body_remove-button"
                    forwardProps={{
                        type: "button",
                    }}
                >
                    Confirm and Remove
                </FrameButton>

                <FrameButton
                    <ButtonHTMLAttributes<HTMLButtonElement>>
                    color="gray"
                    size="normal"
                    onClick={props.onClose}
                    className="checkout-payments-credit_remove-modal-body_cancel-button"
                    forwardProps={{
                        type: "button",
                    }}
                >
                    Cancel
                </FrameButton>

            </FrameAutoPanelBody>
        </FrameOneAutoPanel>
    );
}

export default CheckoutPaymentRemoveCreditModal;
