/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BrandMapStatus,
    BrandMapStatusFromJSON,
    BrandMapStatusFromJSONTyped,
    BrandMapStatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface UpdateBrandMapStatusBody
 */
export interface UpdateBrandMapStatusBody {
    /**
     * 
     * @type {BrandMapStatus}
     * @memberof UpdateBrandMapStatusBody
     */
    status: BrandMapStatus;
}

export function UpdateBrandMapStatusBodyFromJSON(json: any): UpdateBrandMapStatusBody {
    return UpdateBrandMapStatusBodyFromJSONTyped(json, false);
}

export function UpdateBrandMapStatusBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateBrandMapStatusBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': BrandMapStatusFromJSON(json['status']),
    };
}

export function UpdateBrandMapStatusBodyToJSON(value?: UpdateBrandMapStatusBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': BrandMapStatusToJSON(value.status),
    };
}


