import {isAPIError} from "@/utils/isAPIError";
import {ApiError} from "@devour/client";


export async function getApiErrorMessage(error?: { json: () => Promise<ApiError> } | ApiError): Promise<string> {
    let errorMessage: string;

    try {
        if (isAPIError(error)) {
            errorMessage = (error as ApiError).message;
        } else {
            const jsonResponse = await (error as { json: () => Promise<ApiError> }).json();
            if (isAPIError(jsonResponse)) {
                errorMessage = jsonResponse.message;
            } else { // default error message
                errorMessage = "An unknown error has occurred. Please contact support or try your request again.";
            }
        }
    } catch (e) {
        errorMessage = "An unknown error has occurred. Please contact support or try your request again.";
    }

    if (errorMessage === "Failed to fetch") {
        errorMessage = "There was an error with your connection to the server. Please confirm you are connected to the internet, your adblocker is off, and your network is not blocking any network request. If the issue continues, please try refreshing the page.";
    }

    return errorMessage;
}