import {ReactElement} from "react";
import GameThumbnail from "../GameThumbnail";
import GameModalHandler from "../GameModalHandler";
import {
    GameThumbnailData,
} from "./goVipText";

function GoVipGames(): ReactElement {

    function renderThumbnails() {
        return GameThumbnailData.map((game, index) => {
            return (
                <GameThumbnail
                    requiredLevel={game.requiredLevel}
                    gameContainer={game.gameContainer}
                    title={game.title}
                    thumbnailUrl={game.thumbnailUrl}
                    levelDetailsModal={
                        <GameModalHandler
                            requiredLevel={game.requiredLevel}
                            gameData={game.gameData}
                            gameContainer={game.gameContainer}
                        />
                    }
                    description={game.description}
                    key={`go-vip-game-thumbnail-${index}`}
                />
            );
        });
    }

    return (
        <>
            {renderThumbnails()}
        </>
    );
}

export default GoVipGames;
