import {GetUserCommunityInfoResponse, Token, GoFrensApi} from "@devour/client";
import getConfig from "@/utils/getConfig";
import {useQuery} from "@tanstack/react-query";

async function fetchCommunityInfoForUser(fullToken): Promise<GetUserCommunityInfoResponse> {
    return new GoFrensApi(getConfig(fullToken)).getUserCommunityInfo();
}

function communityInfoQuery(fullToken: Token) {
    return {
        queryKey: [
            "get-user-community-info",
            fullToken?.id,
        ],
        queryFn: () => fetchCommunityInfoForUser(fullToken),
        enabled: !!fullToken,
    } as const;
}

export function useGetUserCommunityInfo(fullToken) {
    return useQuery(communityInfoQuery(fullToken));
}
