/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BusinessPermission,
    BusinessPermissionFromJSON,
    BusinessPermissionFromJSONTyped,
    BusinessPermissionToJSON,
} from './';

/**
 * 
 * @export
 * @interface BusinessUpdateUserBody
 */
export interface BusinessUpdateUserBody {
    /**
     * 
     * @type {string}
     * @memberof BusinessUpdateUserBody
     */
    userId: string;
    /**
     * 
     * @type {Array<BusinessPermission>}
     * @memberof BusinessUpdateUserBody
     */
    permissions: Array<BusinessPermission>;
}

export function BusinessUpdateUserBodyFromJSON(json: any): BusinessUpdateUserBody {
    return BusinessUpdateUserBodyFromJSONTyped(json, false);
}

export function BusinessUpdateUserBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): BusinessUpdateUserBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': json['userId'],
        'permissions': ((json['permissions'] as Array<any>).map(BusinessPermissionFromJSON)),
    };
}

export function BusinessUpdateUserBodyToJSON(value?: BusinessUpdateUserBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'permissions': ((value.permissions as Array<any>).map(BusinessPermissionToJSON)),
    };
}


