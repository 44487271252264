/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrderDiscountSearchEssential
 */
export interface OrderDiscountSearchEssential {
    /**
     * 
     * @type {string}
     * @memberof OrderDiscountSearchEssential
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDiscountSearchEssential
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDiscountSearchEssential
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderDiscountSearchEssential
     */
    amount: number;
    /**
     * 
     * @type {number}
     * @memberof OrderDiscountSearchEssential
     */
    priority: number;
    /**
     * 
     * @type {boolean}
     * @memberof OrderDiscountSearchEssential
     */
    isDisplayedGoFrens?: boolean;
    /**
     * 
     * @type {number}
     * @memberof OrderDiscountSearchEssential
     */
    expiresAt?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderDiscountSearchEssential
     */
    nftGroupings?: Array<string>;
}

export function OrderDiscountSearchEssentialFromJSON(json: any): OrderDiscountSearchEssential {
    return OrderDiscountSearchEssentialFromJSONTyped(json, false);
}

export function OrderDiscountSearchEssentialFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderDiscountSearchEssential {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'label': json['label'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'amount': json['amount'],
        'priority': json['priority'],
        'isDisplayedGoFrens': !exists(json, 'isDisplayedGoFrens') ? undefined : json['isDisplayedGoFrens'],
        'expiresAt': !exists(json, 'expiresAt') ? undefined : json['expiresAt'],
        'nftGroupings': !exists(json, 'nftGroupings') ? undefined : json['nftGroupings'],
    };
}

export function OrderDiscountSearchEssentialToJSON(value?: OrderDiscountSearchEssential | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'label': value.label,
        'description': value.description,
        'amount': value.amount,
        'priority': value.priority,
        'isDisplayedGoFrens': value.isDisplayedGoFrens,
        'expiresAt': value.expiresAt,
        'nftGroupings': value.nftGroupings,
    };
}


