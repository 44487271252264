import {ReactElement} from "react";
import FrameButton from "../../../components/buttons/FrameButton";
import {useParams} from "react-router";
import FrameOneAutoPanel from "@/components/modals/autoPanelComponents/FrameOneAutoPanel";
import FrameAutoPanelBody from "@/components/modals/autoPanelComponents/FrameAutoPanelBody";
import {LinkProps} from "react-router-dom";
import {BsFillArrowRightCircleFill} from "react-icons/bs";

interface Props {
    onToggle: () => void;
    display: boolean;
}

function MenuUnavailableModal(props: Props): ReactElement {
    const {placeId} = useParams<{ placeId?: string; }>();

    return (
        <FrameOneAutoPanel
            contentClassName="menu-unavailable-modal"
            isOpen={props.display}
            toggle={props.onToggle}
            size="xs"
        >
            <FrameAutoPanelBody>
                <h3>
					This location is currently unavailable for ordering.
                </h3>
                <p>
					To find an alternative location to order from, click the button below:
                </p>
                <div className="menu-unavailable-modal_cta">
                    <FrameButton
                        <LinkProps>
                        color="purple"
                        size="normal"
                        to={placeId
                            ? `/restaurants/search/${placeId}`
                            : "/restaurants"}
                        rightIcon={BsFillArrowRightCircleFill}
                    >
						Search for Restaurant
                    </FrameButton>
                </div>
            </FrameAutoPanelBody>
        </FrameOneAutoPanel>
    );
}

export default MenuUnavailableModal;
