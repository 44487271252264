import * as ethers from "ethers";
import {magic} from "@/utils/magic";
import {dpayAbi} from "@/abis/dpayAbi";

export async function sendDpayTransaction(realAmountOfDpay: bigint) {
    try {
        const provider = new ethers.BrowserProvider(magic.rpcProvider);
        const signer = await provider.getSigner();
        const contract = new ethers.Contract(import.meta.env.VITE_DPAY_TOKEN_ADDRESS_ETHEREUM, dpayAbi, signer);
        const tx = await contract.transfer(import.meta.env.VITE_DPAY_CHECKOUT_RECIPIENT_ADDRESS, realAmountOfDpay);
        return await tx.wait();

    } catch (e) {
        throw new Error("On-chain transaction failed. Reason: " + e.reason + ".");
    }
}
