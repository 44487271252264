import {AnchorHTMLAttributes, ReactElement, useState} from "react";
import FrameButton from "./buttons/FrameButton";
import ConfirmDeleteModal from "./modals/ConfirmDeleteModal";
import {useGate} from "statsig-react";

function AccountDeletionPanel(): ReactElement {
    const {value: dpayStatus} = useGate(import.meta.env.VITE_TOKEN_STATSIG_STATUS);

    const [
        showModal,
        setShowModal,
    ] = useState(false);

    function toggleModal(): void {
        setShowModal(!showModal);
    }

    return (
        <div className="acc-deactivate-panel">
            <ConfirmDeleteModal
                isOpen={showModal}
                toggle={toggleModal}
            />
            <div className="acc-deactivate-panel_container">
                <div className="acc-deactivate-panel_container_row">
                    <div>
                        <p>
                            <strong>
																Delete Account
                            </strong>
                        </p>
                        <p>
														Your account and customer data will be permanently removed from
														DevourGO. Any
                            {dpayStatus ? ` ${import.meta.env.VITE_TOKEN_NAME} balance, ` : " "}
														gift card balances and access to your DevourGO Wallet will also be
														forfeited. We encourage
														you to export your DevourGO Wallet private keys, so that you can
														transport that wallet to another provider.
                        </p>
                        <p className="acc-deactivate-panel_container_row_more-info">
														For more information on how we collect and use customer data, please
														visit our <a
                                href={`${import.meta.env.VITE_WEB_URL}/privacy-policy`}>Privacy
														Policy.</a>
                        </p>
                    </div>
                    <FrameButton
                        <AnchorHTMLAttributes<HTMLAnchorElement>>
                        color="gray"
                        size="narrow"
                        className="acc-deactivate-panel_container_row_button"
                        onClick={toggleModal}
                    >
												Delete Account
                    </FrameButton>
                </div>
            </div>
        </div>
    );
}

export default AccountDeletionPanel;
