function formatDateToHumanReadable(unixDate: string | Date | number): { time: string, date: string } {
    const date = new Date(unixDate);

    // Formatting time
    const timeFormatter = new Intl.DateTimeFormat("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
    });
    const formattedTime = timeFormatter.format(date);

    // Formatting date
    const dateFormatter = new Intl.DateTimeFormat("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
    });
    const formattedDate = dateFormatter.format(date);

    return {
        time: formattedTime,
        date: formattedDate,
    };
}

export default formatDateToHumanReadable;