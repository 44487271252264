/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ReferralCode,
    ReferralCodeFromJSON,
    ReferralCodeFromJSONTyped,
    ReferralCodeToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetReferralCodesResponse
 */
export interface GetReferralCodesResponse {
    /**
     * 
     * @type {Array<ReferralCode>}
     * @memberof GetReferralCodesResponse
     */
    referralCodes: Array<ReferralCode>;
}

export function GetReferralCodesResponseFromJSON(json: any): GetReferralCodesResponse {
    return GetReferralCodesResponseFromJSONTyped(json, false);
}

export function GetReferralCodesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetReferralCodesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'referralCodes': ((json['referralCodes'] as Array<any>).map(ReferralCodeFromJSON)),
    };
}

export function GetReferralCodesResponseToJSON(value?: GetReferralCodesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'referralCodes': ((value.referralCodes as Array<any>).map(ReferralCodeToJSON)),
    };
}


