import {ButtonHTMLAttributes, ReactElement, useState} from "react";
import FrameButton from "./buttons/FrameButton";
import FrameOneModal from "./modals/modalComponents/FrameOneModal";
import {updateActiveGameModal} from "../redux/meta/metaActions";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "../redux/defaultStore";
import classNames from "classnames";
import {AiTwotoneLock} from "react-icons/ai";
import {isDesktop, isTablet} from "react-device-detect";
import {FiX} from "react-icons/fi";
import {GoVipGameThumbnailData} from "./goVip/goVipText";

function GameThumbnail(props: GoVipGameThumbnailData): ReactElement {

    const GameContainerComponent = props.gameContainer;
    const [
        isOpen,
        setIsOpen,
    ] = useState(false);
    const dispatch = useDispatch();

    const accountLevel = useSelector((store: IStore) => store.metaStore.accountLevel);
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);

    function toggle(): void {
        setIsOpen(!isOpen);
    }

    const disabled = accountLevel == null || accountLevel.level < props.requiredLevel;

    return (
        <>
            {props.levelDetailsModal &&
            props.levelDetailsModal
            }
            <FrameOneModal
                isOpen={isOpen}
                contentClassName="game-thumbnail_game-modal"
                toggle={toggle}
            >
                <GameContainerComponent/>

                {!isDesktop &&
                <FiX
					    className="game-thumbnail_game-modal_exit-button"
					    onClick={toggle}
                />
                }
            </FrameOneModal>

            <div className="game-card">
                <div
                    className="game-card_image-container"
                    onClick={disabled
                        ? undefined
                        : toggle}
                >
                    {props.thumbnailUrl &&
                    <img
						    className={
						        classNames(
						            "game-thumbnail_alert-and-img-con_image-wrapper_image",
						            disabled
                                    ? "game-thumbnail_alert-and-img-con_image-wrapper_black-and-white-filter"
                                    : undefined,
						            disabled
                                    ? "game-thumbnail_no-pointer"
                                    : "game-thumbnail_pointer",
						        )}
						    alt="Game Thumbnail"
						    src={props.thumbnailUrl}
                    />
                    }
                </div>
                <div className="game-card_content">
                    <h4>{props.title}</h4>

                    <p className="game-card_content_description">
                        {props.description}
                    </p>

                    <div className="game-card_actions">
                        <FrameButton
                            <ButtonHTMLAttributes<HTMLButtonElement>>
                            color="ghost"
                            size="narrow"
                            className="game-card_actions_learn-more"
                            onClick={() => dispatch(updateActiveGameModal(props.title))}
                        >
							Learn More
                        </FrameButton>
                        <FrameButton
                            <ButtonHTMLAttributes<HTMLButtonElement>>
                            color="purple"
                            size="narrow"
                            onClick={disabled
                                ? undefined
                                : toggle}
                            leftIcon={disabled
                                ? AiTwotoneLock
                                : undefined}
                            forwardProps={{disabled}}
                            className="game-card_actions_play"
                        >
                            {disabled
                                ? fullToken
                                    ? `${isDesktop || isTablet
                                        ? "Requires"
                                        : ""} Level ${props.requiredLevel}`
                                    : "Join DevourGO"
                                : "Play Now"}
                        </FrameButton>
                    </div>
                </div>
            </div>

        </>
    );

}


export default GameThumbnail;
