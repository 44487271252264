import {ButtonHTMLAttributes, ReactElement} from "react";
import {MenuOrderItem} from "@devour/client";
import FrameAutoPanelHeader from "../modals/autoPanelComponents/FrameAutoPanelHeader";
import FrameAutoPanelBody from "../modals/autoPanelComponents/FrameAutoPanelBody";
import FrameButton from "../buttons/FrameButton";
import FrameOneAutoPanel from "../modals/autoPanelComponents/FrameOneAutoPanel";

interface Props {
    menuOrderItem: MenuOrderItem;
    index: number;
    onRemoveItem: (index: number) => void;
    isOpen: boolean;
    onClose: () => void;
}

function DevourCartItemRemovePanel(props: Props): ReactElement {
    function onRemove(): void {
        props.onRemoveItem(props.index);
        props.onClose();
    }

    if (!props.menuOrderItem) {
        return null;
    }

    return (
        <FrameOneAutoPanel
            isOpen={props.isOpen}
            toggle={props.onClose}
            size="xs"
            contentClassName="devour-cart-item-remove-panel"
        >
            <FrameAutoPanelHeader
                title="Remove item"
                toggle={props.onClose}
            />

            <FrameAutoPanelBody className="devour-cart-item-remove-panel_body">

                <p>
					Really remove {props.menuOrderItem?.name} from your cart? This action can't be undone.
                </p>

                <FrameButton
                    <ButtonHTMLAttributes<HTMLButtonElement>>
                    color="danger"
                    size="narrow"
                    className="devour-cart-item-remove-panel_body_confirm"
                    forwardProps={{type: "button"}}
                    onClick={onRemove}
                >
					Remove item
                </FrameButton>

                <button
                    className="devour-cart-item-remove-panel_body_cancel"
                    type="button"
                    onClick={props.onClose}
                >
					Go back
                </button>


            </FrameAutoPanelBody>
        </FrameOneAutoPanel>
    );
}


export default DevourCartItemRemovePanel;
