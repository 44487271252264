import {useQuery} from "@tanstack/react-query";
import {
    GetTransactionsPaginatedResponse,
    GetTransactionsPaginatedSort,
    SortOrder,
    Token,
    TransactionsApi,
} from "@devour/client";
import getConfig from "@/utils/getConfig";
import { useGate } from "statsig-react";

interface TransactionsPaginatedQueryParams {
    sort?: GetTransactionsPaginatedSort;
    order?: SortOrder;
    limit?: number;
    offset?: number
}

async function fetchTransactionsPaginated(fullToken: Token, userId: string, params: TransactionsPaginatedQueryParams): Promise<GetTransactionsPaginatedResponse> {
    return await new TransactionsApi(getConfig(fullToken)).getTransactionsPaginated({
        ...params,
        id: userId,
    });
}

function transactionsPaginatedQuery(fullToken: Token, userId: string, params: TransactionsPaginatedQueryParams) {
    const { value: dpayStatus } = useGate(import.meta.env.VITE_TOKEN_STATSIG_STATUS);

    return {
        queryKey: [
            "transactions-paginated",
            fullToken?.id ?? "unauthenticated",
            userId ?? "no-users-id",
            params,
        ],
        queryFn: () => fetchTransactionsPaginated(fullToken, userId, params),
        enabled: Boolean(fullToken && userId && dpayStatus),
        keepPreviousData: true,
    } as const;
}

export function useGetTransactionsPaginated(fullToken: Token, userId: string, params?: TransactionsPaginatedQueryParams) {
    return useQuery(transactionsPaginatedQuery(fullToken, userId, params));
}