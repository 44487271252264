import {useMutation, useQueryClient} from "@tanstack/react-query";
import {ApiError, MenuOrdersApi, UsersApi} from "@devour/client";
import getConfig from "@/utils/getConfig";
import {addError} from "@/redux/meta/metaActions";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {useGetStripePaymentMethodList} from "@/hooks/useGetStripePaymentMethodList";
import {useGetUserProfile} from "@/hooks/useGetUserProfile";
import {StripePaymentMethodObject} from "@/types/Stripe";

/**
 * When a credit card is selected,
 * Update the menu order payment method and the user's default payment method.
 * Refetch the updated user and stripe payment methods data
 * @param menuOrderId
 */
export function useSelectCreditPaymentMethod(menuOrderId: string) {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);

    // Fetch list of the user's Stripe payment methods
    const {refetch: refetchPaymentMethods} = useGetStripePaymentMethodList(fullToken);

    // Fetch user profile
    const {refetch: refetchUser} = useGetUserProfile(fullToken);

    return useMutation({
        mutationFn: async (payment: StripePaymentMethodObject) => {

            const newMenuOrder = await new MenuOrdersApi(getConfig()).updateMenuOrder({
                id: menuOrderId,
                createMenuOrderBody: {
                    paymentMethodId: payment.id,
                    dpay: 0,
                    vdpay: 0,
                    onChainDpay: 0,
                    isMagicWallet: false,
                    isCoinbase: false,
                },
            });

            await new UsersApi(getConfig(fullToken)).updateDefaultPayment({
                defaultPaymentMethodId: payment.id,
            });

            // Update the menuOrder data cache with the new updated menu order
            // No need to refetchMenuOrder. This eliminates the need to
            // refetch the menu order from the database, increasing speed
            queryClient.setQueryData([
                "menuOrder",
                menuOrderId,
            ], newMenuOrder);
            return newMenuOrder;
        },
        onError: async (e) => dispatch(await addError(e as ApiError)),
        onSuccess: async () => {
            await refetchPaymentMethods();
            await refetchUser();
        },
    });
}
