/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GeoPoint,
    GeoPointFromJSON,
    GeoPointFromJSONTyped,
    GeoPointToJSON,
} from './';

/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {GeoPoint}
     * @memberof Address
     */
    location?: GeoPoint;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    line1?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    line2?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    locality: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    administrativeArea: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    postalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    placeId?: string;
}

export function AddressFromJSON(json: any): Address {
    return AddressFromJSONTyped(json, false);
}

export function AddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): Address {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'location': !exists(json, 'location') ? undefined : GeoPointFromJSON(json['location']),
        'line1': !exists(json, 'line1') ? undefined : json['line1'],
        'line2': !exists(json, 'line2') ? undefined : json['line2'],
        'locality': json['locality'],
        'administrativeArea': json['administrativeArea'],
        'postalCode': !exists(json, 'postalCode') ? undefined : json['postalCode'],
        'country': json['country'],
        'placeId': !exists(json, 'placeId') ? undefined : json['placeId'],
    };
}

export function AddressToJSON(value?: Address | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'location': GeoPointToJSON(value.location),
        'line1': value.line1,
        'line2': value.line2,
        'locality': value.locality,
        'administrativeArea': value.administrativeArea,
        'postalCode': value.postalCode,
        'country': value.country,
        'placeId': value.placeId,
    };
}


