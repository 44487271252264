/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Address,
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
    BusinessType,
    BusinessTypeFromJSON,
    BusinessTypeFromJSONTyped,
    BusinessTypeToJSON,
    HandoffOptions,
    HandoffOptionsFromJSON,
    HandoffOptionsFromJSONTyped,
    HandoffOptionsToJSON,
    PhoneNumberBody,
    PhoneNumberBodyFromJSON,
    PhoneNumberBodyFromJSONTyped,
    PhoneNumberBodyToJSON,
    RestaurantBrandSize,
    RestaurantBrandSizeFromJSON,
    RestaurantBrandSizeFromJSONTyped,
    RestaurantBrandSizeToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreateBusinessBodyBase
 */
export interface CreateBusinessBodyBase {
    /**
     * 
     * @type {BusinessType}
     * @memberof CreateBusinessBodyBase
     */
    type?: BusinessType;
    /**
     * 
     * @type {RestaurantBrandSize}
     * @memberof CreateBusinessBodyBase
     */
    brandSize?: RestaurantBrandSize;
    /**
     * 
     * @type {string}
     * @memberof CreateBusinessBodyBase
     */
    parent?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBusinessBodyBase
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBusinessBodyBase
     */
    internalName?: string;
    /**
     * See moment.tz.names()
     * @type {string}
     * @memberof CreateBusinessBodyBase
     */
    timeZone?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBusinessBodyBase
     */
    currency?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBusinessBodyBase
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBusinessBodyBase
     */
    email?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateBusinessBodyBase
     */
    specialInstructions?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateBusinessBodyBase
     */
    prepTime?: number;
    /**
     * 
     * @type {PhoneNumberBody}
     * @memberof CreateBusinessBodyBase
     */
    phoneNumber?: PhoneNumberBody;
    /**
     * 
     * @type {Address}
     * @memberof CreateBusinessBodyBase
     */
    address: Address;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateBusinessBodyBase
     */
    taxonomies?: Array<string>;
    /**
     * 
     * @type {Array<HandoffOptions>}
     * @memberof CreateBusinessBodyBase
     */
    handoffOptions?: Array<HandoffOptions>;
    /**
     * 
     * @type {string}
     * @memberof CreateBusinessBodyBase
     */
    firstDeliveryId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBusinessBodyBase
     */
    merchantId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBusinessBodyBase
     */
    website?: string;
}

export function CreateBusinessBodyBaseFromJSON(json: any): CreateBusinessBodyBase {
    return CreateBusinessBodyBaseFromJSONTyped(json, false);
}

export function CreateBusinessBodyBaseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateBusinessBodyBase {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : BusinessTypeFromJSON(json['type']),
        'brandSize': !exists(json, 'brandSize') ? undefined : RestaurantBrandSizeFromJSON(json['brandSize']),
        'parent': !exists(json, 'parent') ? undefined : json['parent'],
        'name': json['name'],
        'internalName': !exists(json, 'internalName') ? undefined : json['internalName'],
        'timeZone': !exists(json, 'timeZone') ? undefined : json['timeZone'],
        'currency': !exists(json, 'currency') ? undefined : json['currency'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'specialInstructions': !exists(json, 'specialInstructions') ? undefined : json['specialInstructions'],
        'prepTime': !exists(json, 'prepTime') ? undefined : json['prepTime'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : PhoneNumberBodyFromJSON(json['phoneNumber']),
        'address': AddressFromJSON(json['address']),
        'taxonomies': !exists(json, 'taxonomies') ? undefined : json['taxonomies'],
        'handoffOptions': !exists(json, 'handoffOptions') ? undefined : ((json['handoffOptions'] as Array<any>).map(HandoffOptionsFromJSON)),
        'firstDeliveryId': !exists(json, 'firstDeliveryId') ? undefined : json['firstDeliveryId'],
        'merchantId': !exists(json, 'merchantId') ? undefined : json['merchantId'],
        'website': !exists(json, 'website') ? undefined : json['website'],
    };
}

export function CreateBusinessBodyBaseToJSON(value?: CreateBusinessBodyBase | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': BusinessTypeToJSON(value.type),
        'brandSize': RestaurantBrandSizeToJSON(value.brandSize),
        'parent': value.parent,
        'name': value.name,
        'internalName': value.internalName,
        'timeZone': value.timeZone,
        'currency': value.currency,
        'description': value.description,
        'email': value.email,
        'specialInstructions': value.specialInstructions,
        'prepTime': value.prepTime,
        'phoneNumber': PhoneNumberBodyToJSON(value.phoneNumber),
        'address': AddressToJSON(value.address),
        'taxonomies': value.taxonomies,
        'handoffOptions': value.handoffOptions === undefined ? undefined : ((value.handoffOptions as Array<any>).map(HandoffOptionsToJSON)),
        'firstDeliveryId': value.firstDeliveryId,
        'merchantId': value.merchantId,
        'website': value.website,
    };
}


