import {PropsWithChildren, ReactElement, useEffect} from "react";
import {connect, ConnectedProps} from "react-redux";
import {useNavigate} from "react-router-dom";
import {Token} from "@devour/client";
import {IStore} from "../../redux/defaultStore";
import {useLocation} from "react-router";

interface StateProps {
    fullToken: Token;
}

interface Props {
}

function AuthenticatedRoute(props: AuthenticatedRouteProps): ReactElement {

    const {fullToken} = props;
    const history = useNavigate();
    const location = useLocation();

    /**
	 * Async function added, so we can leverage "await" on addError passed to the Redux dispatch action.
	 *
	 */
    // const handleErrorDispatch = useCallback(async () => {
    // 	props.dispatch(await addError({
    // 		type: ErrorType.APP,
    // 		message: "You must be logged in to access that page. If you were previously logged in, your session may have expired, and you must log in again.",
    // 	}));
    // }, [props]);

    useEffect(() => {
        if (!fullToken?.token) {
            // handleErrorDispatch().then().catch();
            history(`/log-in?redirect=${location.pathname.slice(1)}`, {replace: true});
        }
    }, [
        fullToken,
        history,
    ]);

    if (!fullToken?.token) {
        return null;
    }

    return (
        <>
            {props.children}
        </>
    );
}

function connector() {
    return connect((store: IStore, props: PropsWithChildren<{}> & Props): StateProps & PropsWithChildren<{}> & Props => {
        return {
            fullToken: store.authStore.fullToken,
            ...props,
        };
    });
}

type AuthenticatedRouteProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(AuthenticatedRoute);
