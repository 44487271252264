import {ReactElement} from "react";
import LoadDpayPanel from "@/components/loadDpay/LoadDpayPanel";
import FrameOneAutoPanel from "@/components/modals/autoPanelComponents/FrameOneAutoPanel";
import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {useGetTransactions} from "@/hooks/useGetTransactions";

interface Props {
    isOpen: boolean;
    toggle: () => void;
}

function CheckoutPaymentLoadDpayModal(props: Props): ReactElement {

    const currentUser = useSelector((store: IStore) => store.metaStore?.currentUser);
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const {refetch: refetchTransactionData} = useGetTransactions(fullToken, currentUser?.user?.id);

    return (
        <FrameOneAutoPanel
            isOpen={props.isOpen}
            toggle={props.toggle}
            contentClassName="contact-modal"
            size="sm2"
            modalOnTablet={true}
        >
            <LoadDpayPanel
                toggleAsModal={async () => {
                    props.toggle();
                    await refetchTransactionData();
                }}
            />

        </FrameOneAutoPanel>
    );
}

export default CheckoutPaymentLoadDpayModal;
