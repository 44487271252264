/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Referral,
    ReferralFromJSON,
    ReferralFromJSONTyped,
    ReferralToJSON,
} from './';

/**
 * 
 * @export
 * @interface GenerateAllReferralsResponse
 */
export interface GenerateAllReferralsResponse {
    /**
     * 
     * @type {Array<Referral>}
     * @memberof GenerateAllReferralsResponse
     */
    referrals: Array<Referral>;
}

export function GenerateAllReferralsResponseFromJSON(json: any): GenerateAllReferralsResponse {
    return GenerateAllReferralsResponseFromJSONTyped(json, false);
}

export function GenerateAllReferralsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GenerateAllReferralsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'referrals': ((json['referrals'] as Array<any>).map(ReferralFromJSON)),
    };
}

export function GenerateAllReferralsResponseToJSON(value?: GenerateAllReferralsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'referrals': ((value.referrals as Array<any>).map(ReferralToJSON)),
    };
}


