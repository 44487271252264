import {ReactElement} from "react";
import {MenuOrder, OrderDiscountType} from "@devour/client";

interface Props {
    menuOrder: MenuOrder;
}

function MenuOrderDiscounts(props: Props): ReactElement {

    // Don't display tax discounts to customer
    const displayedDiscounts = props.menuOrder.discounts?.filter((discount) => {
        return discount.type === OrderDiscountType.ORDER ||
            discount.type === OrderDiscountType.DELIVERY ||
            discount.type === OrderDiscountType.CATEGORY ||
            discount.type === OrderDiscountType.MENUITEM;
    });

    return (
        <div className="menu-order-checkout-discounts">
            {displayedDiscounts.map((discount, index) => <div
                key={`discount-${index}`}
                className="menu-order-checkout-totals_row"
            >
                <div className="menu-order-checkout-totals_row_label">
                    <p className="devour-cart-card_fees-font-adjust">
                        {discount.label}
                    </p>
                </div>
                <div className="menu-order-checkout-totals_row_value">
                    <p className="devour-cart-card_fees-font-adjust">
							- ${discount.amount.toFixed(2)}
                    </p>
                </div>
            </div>)}
        </div>
    );
}

export default MenuOrderDiscounts;
