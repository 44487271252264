import GameContainer from "./GameContainer";
import {FC, useEffect, useState} from "react";
import {GamesApi, Token} from "@devour/client";
import getConfig from "../../utils/getConfig";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {addError} from "@/redux/meta/metaActions";

interface StateProps {
    fullToken: Token;
}

const StackTheBurger: FC<BurgerStackProps> = (props) => {

    const [
        message,
        setMessage,
    ] = useState<string>();

    useEffect(() => {
        async function listener(event: any) {

            const data = event.data;
            if (data.level_status === "WIN" && !isNaN(data.lifetime_money)) {
                try {
                    const {message} = await new GamesApi(getConfig(props.fullToken)).burgerStack({
                        burgerStackRequestBody: {
                            money: data?.lifetime_money,
                        },
                    });

                    setMessage(message);
                } catch (err) {
                    props.dispatch(await addError(err));
                }
            }
        }

        window.addEventListener("message", listener, false);

        return () => window.removeEventListener("message", listener);
    }, [props.fullToken.token]);


    return (
        <GameContainer
            baseUrl={`${import.meta.env.VITE_CDN_URL}/games/stack-the-burger-v2/index.html`}
            gameId="burger-stack"
            width="606"
            height="1080"
            message={message}
        />
    );
};

function connector() {
    return connect((store: IStore): StateProps => {
        return {
            fullToken: store.authStore.fullToken,
        };
    });
}

type BurgerStackProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(StackTheBurger);