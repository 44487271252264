import {ReactElement, useState} from "react";
import {Address, UtilsApi} from "@devour/client";
import FrameOneAutocompleteInput from "./FrameOneAutocompleteInput";
import {FaMapMarkerAlt} from "react-icons/fa";

interface Props {
    placeholder?: string;
    callback: (newAddress: Address) => void;
    onError?: () => void;
    showButton?: boolean;
    showMapMarkerIcon?: boolean;
}

function FrameOneAddressAutocomplete(props: Props): ReactElement {

    const [
        autocompleteResults,
        setAutocompleteResults,
    ] = useState<Array<{ formattedAddress: string; placeId: string; }>>([]);
    const [
        lastUnusableQuery,
        setLastUnusableQuery,
    ] = useState<string>("");

    async function fetchAutocompleteResults(query: string): Promise<void> {
        setAutocompleteResults([]);
        try {
            if (query.length >= 5 && (query.indexOf(lastUnusableQuery) !== 0 || lastUnusableQuery === "")) {
                // Query by formatted address text
                const res = await new UtilsApi().getAddressAutocomplete({
                    formattedAddress: query,
                });
                setAutocompleteResults(res.addresses);
                if (!res.addresses.length) {
                    setLastUnusableQuery(query);
                }
            }
        } catch {
            props?.onError();
        }
    }

    async function onAddressSelect(placeId: string): Promise<void> {
        const res = await new UtilsApi().getAddressFromPlaceId({
            placeId: placeId,
        });

        props.callback(res);
    }

    const autocompleteArray: Array<{ data: string; render: JSX.Element }> = autocompleteResults.map((result) => {
        const render =
			<div className="frame-one-address-autocomplete_result-item">
			    <FaMapMarkerAlt/>
			    <div>
			        {result.formattedAddress}
			    </div>
			</div>;
        return {
            data: result.placeId,
            render: render,
            address: result.formattedAddress,
        };
    });

    return (
        <div className="frame-one-address-autocomplete">
            <FrameOneAutocompleteInput
                <string>
                emptyText="Address not found"
                placeholder={props.placeholder ?? "Search for an address"}
                results={autocompleteArray}
                onSelect={onAddressSelect}
                onInputChange={fetchAutocompleteResults}
                resetOnSelect={true}
                minLength={5}
                showButton={props.showButton}
                showMapMarkerIcon={props.showMapMarkerIcon}
            />
        </div>
    );
}

export default FrameOneAddressAutocomplete;
