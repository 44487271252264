/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Asset,
    AssetFromJSON,
    AssetFromJSONTyped,
    AssetToJSON,
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
    OrderDiscount,
    OrderDiscountFromJSON,
    OrderDiscountFromJSONTyped,
    OrderDiscountToJSON,
    TimeStamps,
    TimeStampsFromJSON,
    TimeStampsFromJSONTyped,
    TimeStampsToJSON,
} from './';

/**
 * Represents a brand entity which consists of associated merchant accounts & arbitrary businesses as designated by admins.
 * @export
 * @interface Brand
 */
export interface Brand {
    /**
     * 
     * @type {string}
     * @memberof Brand
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof Brand
     */
    createdAt: number;
    /**
     * 
     * @type {number}
     * @memberof Brand
     */
    updatedAt: number;
    /**
     * 
     * @type {string}
     * @memberof Brand
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Brand
     */
    description?: string;
    /**
     * 
     * @type {Asset}
     * @memberof Brand
     */
    image?: Asset;
    /**
     * 
     * @type {Asset}
     * @memberof Brand
     */
    businessIcon?: Asset;
    /**
     * 
     * @type {Asset}
     * @memberof Brand
     */
    businessHeader?: Asset;
    /**
     * 
     * @type {boolean}
     * @memberof Brand
     */
    isEnabled: boolean;
    /**
     * 
     * @type {Array<OrderDiscount>}
     * @memberof Brand
     */
    promos?: Array<OrderDiscount>;
}

export function BrandFromJSON(json: any): Brand {
    return BrandFromJSONTyped(json, false);
}

export function BrandFromJSONTyped(json: any, ignoreDiscriminator: boolean): Brand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'image': !exists(json, 'image') ? undefined : AssetFromJSON(json['image']),
        'businessIcon': !exists(json, 'businessIcon') ? undefined : AssetFromJSON(json['businessIcon']),
        'businessHeader': !exists(json, 'businessHeader') ? undefined : AssetFromJSON(json['businessHeader']),
        'isEnabled': json['isEnabled'],
        'promos': !exists(json, 'promos') ? undefined : ((json['promos'] as Array<any>).map(OrderDiscountFromJSON)),
    };
}

export function BrandToJSON(value?: Brand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'name': value.name,
        'description': value.description,
        'image': AssetToJSON(value.image),
        'businessIcon': AssetToJSON(value.businessIcon),
        'businessHeader': AssetToJSON(value.businessHeader),
        'isEnabled': value.isEnabled,
        'promos': value.promos === undefined ? undefined : ((value.promos as Array<any>).map(OrderDiscountToJSON)),
    };
}


