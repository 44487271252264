/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CommunityMember,
    CommunityMemberFromJSON,
    CommunityMemberFromJSONTyped,
    CommunityMemberToJSON,
} from './';

/**
 * 
 * @export
 * @interface ManageCommunityInfo
 */
export interface ManageCommunityInfo {
    /**
     * 
     * @type {string}
     * @memberof ManageCommunityInfo
     */
    communityRank: string;
    /**
     * 
     * @type {Array<CommunityMember>}
     * @memberof ManageCommunityInfo
     */
    communityMembers: Array<CommunityMember>;
    /**
     * 
     * @type {number}
     * @memberof ManageCommunityInfo
     */
    totalPoints: number;
}

export function ManageCommunityInfoFromJSON(json: any): ManageCommunityInfo {
    return ManageCommunityInfoFromJSONTyped(json, false);
}

export function ManageCommunityInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ManageCommunityInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'communityRank': json['communityRank'],
        'communityMembers': ((json['communityMembers'] as Array<any>).map(CommunityMemberFromJSON)),
        'totalPoints': json['totalPoints'],
    };
}

export function ManageCommunityInfoToJSON(value?: ManageCommunityInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'communityRank': value.communityRank,
        'communityMembers': ((value.communityMembers as Array<any>).map(CommunityMemberToJSON)),
        'totalPoints': value.totalPoints,
    };
}


