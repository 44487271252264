/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum OrderDiscountOperator {
    ANY = 'ANY',
    ALL = 'ALL'
}

export function OrderDiscountOperatorFromJSON(json: any): OrderDiscountOperator {
    return OrderDiscountOperatorFromJSONTyped(json, false);
}

export function OrderDiscountOperatorFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderDiscountOperator {
    return json as OrderDiscountOperator;
}

export function OrderDiscountOperatorToJSON(value?: OrderDiscountOperator | null): any {
    return value as any;
}

