import { useEffect, useRef } from "react";

const useInactive = (trigger: () => void) => {
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    const initializeTimeout = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
            trigger();
        }, 20 * 1000);
    };
    useEffect(() => {
        initializeTimeout();
        document.addEventListener("click", initializeTimeout);
        document.addEventListener("mousemove", initializeTimeout);
        document.addEventListener("keydown", initializeTimeout);
        document.addEventListener("scroll", initializeTimeout);
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, []);
};


export default useInactive;
