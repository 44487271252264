/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetReferralRewardThresholdsResponseAllOf,
    GetReferralRewardThresholdsResponseAllOfFromJSON,
    GetReferralRewardThresholdsResponseAllOfFromJSONTyped,
    GetReferralRewardThresholdsResponseAllOfToJSON,
    PaginatedResponse,
    PaginatedResponseFromJSON,
    PaginatedResponseFromJSONTyped,
    PaginatedResponseToJSON,
    PaginationInfo,
    PaginationInfoFromJSON,
    PaginationInfoFromJSONTyped,
    PaginationInfoToJSON,
    ReferralRewardThreshold,
    ReferralRewardThresholdFromJSON,
    ReferralRewardThresholdFromJSONTyped,
    ReferralRewardThresholdToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetReferralRewardThresholdsResponse
 */
export interface GetReferralRewardThresholdsResponse extends PaginatedResponse {
    /**
     * 
     * @type {Array<ReferralRewardThreshold>}
     * @memberof GetReferralRewardThresholdsResponse
     */
    referralRewardThresholds: Array<ReferralRewardThreshold>;
}

export function GetReferralRewardThresholdsResponseFromJSON(json: any): GetReferralRewardThresholdsResponse {
    return GetReferralRewardThresholdsResponseFromJSONTyped(json, false);
}

export function GetReferralRewardThresholdsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetReferralRewardThresholdsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...PaginatedResponseFromJSONTyped(json, ignoreDiscriminator),
        'referralRewardThresholds': ((json['referralRewardThresholds'] as Array<any>).map(ReferralRewardThresholdFromJSON)),
    };
}

export function GetReferralRewardThresholdsResponseToJSON(value?: GetReferralRewardThresholdsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...PaginatedResponseToJSON(value),
        'referralRewardThresholds': ((value.referralRewardThresholds as Array<any>).map(ReferralRewardThresholdToJSON)),
    };
}


