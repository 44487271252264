/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
    Brand,
    BrandFromJSON,
    BrandFromJSONTyped,
    BrandToJSON,
    Merchant,
    MerchantFromJSON,
    MerchantFromJSONTyped,
    MerchantToJSON,
    TimeStamps,
    TimeStampsFromJSON,
    TimeStampsFromJSONTyped,
    TimeStampsToJSON,
} from './';

/**
 * Represents a connection between a Brand & a Merchant account & the permission of the Merchant; the existence of this model with matching Brand & Merchant implies the 2 are connected, regardless of granted permissions.
 * @export
 * @interface BrandToMerchantPermissions
 */
export interface BrandToMerchantPermissions {
    /**
     * 
     * @type {string}
     * @memberof BrandToMerchantPermissions
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof BrandToMerchantPermissions
     */
    createdAt: number;
    /**
     * 
     * @type {number}
     * @memberof BrandToMerchantPermissions
     */
    updatedAt: number;
    /**
     * 
     * @type {Brand}
     * @memberof BrandToMerchantPermissions
     */
    brand: Brand;
    /**
     * 
     * @type {Merchant}
     * @memberof BrandToMerchantPermissions
     */
    merchant: Merchant;
    /**
     * 
     * @type {boolean}
     * @memberof BrandToMerchantPermissions
     */
    createMaps: boolean;
}

export function BrandToMerchantPermissionsFromJSON(json: any): BrandToMerchantPermissions {
    return BrandToMerchantPermissionsFromJSONTyped(json, false);
}

export function BrandToMerchantPermissionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): BrandToMerchantPermissions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'brand': BrandFromJSON(json['brand']),
        'merchant': MerchantFromJSON(json['merchant']),
        'createMaps': json['createMaps'],
    };
}

export function BrandToMerchantPermissionsToJSON(value?: BrandToMerchantPermissions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'brand': BrandToJSON(value.brand),
        'merchant': MerchantToJSON(value.merchant),
        'createMaps': value.createMaps,
    };
}


