/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum OrderRefundResponsibility {
    DEVOUR = 'DEVOUR',
    MERCHANT = 'MERCHANT',
    DELIVERYPROVIDER = 'DELIVERY_PROVIDER',
    SYSTEMCANCELLED = 'SYSTEM_CANCELLED',
    CUSTOMER = 'CUSTOMER'
}

export function OrderRefundResponsibilityFromJSON(json: any): OrderRefundResponsibility {
    return OrderRefundResponsibilityFromJSONTyped(json, false);
}

export function OrderRefundResponsibilityFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderRefundResponsibility {
    return json as OrderRefundResponsibility;
}

export function OrderRefundResponsibilityToJSON(value?: OrderRefundResponsibility | null): any {
    return value as any;
}

