import {AddressBook, BusinessesApi} from "@devour/client";
import {restaurantSearchRedirect} from "./restaurantSearchRedirect";
import getConfig from "./getConfig";

/**
 * There are a lot of spots where we need to handle the ?redirect= query param. This will take in the value and return
 * the correct path to redirect to.
 */
export async function getRedirectRoute(redirect: string, lastSearchedPlaceId?: string, restaurantId?: string, menuOrderId?: string, addressBooks?: Array<AddressBook>, brandMapSlug?: string): Promise<string> {
    if (decodeURIComponent(redirect).startsWith("/")) {
        return decodeURIComponent(redirect);
    } else if (redirect === "go-vip") {
        return "/go-vip-dashboard";

    } else if (redirect === "brand-map" && brandMapSlug) {
        return `/brands/${brandMapSlug}`;

    } else if (redirect === "checkout" && menuOrderId) { // hitting this branch implies ordering is launched
        return `/checkout/${menuOrderId}`;

    } else if (redirect === "restaurant" && restaurantId) {
        try {
            const res = await new BusinessesApi(getConfig()).getRestaurant({
                id: restaurantId,
                placeId: lastSearchedPlaceId,
            });
            return `${res.url}/${lastSearchedPlaceId || ""}`;
        } catch (e) {
            console.error(e);
            return "/restaurants";
        }
    } else if (redirect === "restaurants-search") {
        const redirectRoute = await restaurantSearchRedirect(addressBooks, lastSearchedPlaceId);
        return redirectRoute || "/restaurants";
    } else if (redirect === "mint-industry-pass") {
        return "/gofriends?mint=industry-pass";
    } else if (redirect === "dpay-load") {
        return "/dpay-load";
    } else if (redirect === "refer-a-friend") {
        return "/refer-a-friend";

    } else if (redirect === "gofriends") {
        return "/gofriends";

    } else if (redirect === "favorites") {
        return "/favorites";

    } else if (redirect === "menu-orders") {
        return "/menu-orders";

    } else if (redirect === "account") {
        return "/account";
    } else if (redirect) {
        return `/${redirect}`;
    } else {
        return "/go-vip-dashboard";
    }
}
