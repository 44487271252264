import {ReactElement} from "react";
import Spacer from "@/components/Spacer";

function CheckoutPaymentDpaySkeleton(): ReactElement {

    return (
        <div>

            <div className="checkout-payments-dpay-modal_body_title-row">
                <div className="checkout-payments-dpay-modal_skeleton_title react-loading-skeleton" />
                <div className="checkout-payments-dpay-modal_skeleton_tag react-loading-skeleton" />
                <Spacer/>
                <div className="checkout-payments-dpay-modal_skeleton_button react-loading-skeleton" />
            </div>

            <div className="checkout-payments-dpay-modal_skeleton_acct-balance react-loading-skeleton" />

            <div className="checkout-payments-dpay-modal_skeleton_title react-loading-skeleton" />

            <div className="checkout-payments-dpay-modal_skeleton_methods">
                <div className="checkout-payments-dpay-modal_skeleton_methods_cards react-loading-skeleton" />
                <div className="checkout-payments-dpay-modal_skeleton_methods_cards react-loading-skeleton" />
            </div>

        </div>
    );

}

export default CheckoutPaymentDpaySkeleton;

