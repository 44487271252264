/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    WithdrawWalletChain,
    WithdrawWalletChainFromJSON,
    WithdrawWalletChainFromJSONTyped,
    WithdrawWalletChainToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreateWithdrawBody
 */
export interface CreateWithdrawBody {
    /**
     * 
     * @type {number}
     * @memberof CreateWithdrawBody
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof CreateWithdrawBody
     */
    wallet: string;
    /**
     * 
     * @type {WithdrawWalletChain}
     * @memberof CreateWithdrawBody
     */
    walletChain?: WithdrawWalletChain;
}

export function CreateWithdrawBodyFromJSON(json: any): CreateWithdrawBody {
    return CreateWithdrawBodyFromJSONTyped(json, false);
}

export function CreateWithdrawBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateWithdrawBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amount': json['amount'],
        'wallet': json['wallet'],
        'walletChain': !exists(json, 'walletChain') ? undefined : WithdrawWalletChainFromJSON(json['walletChain']),
    };
}

export function CreateWithdrawBodyToJSON(value?: CreateWithdrawBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount': value.amount,
        'wallet': value.wallet,
        'walletChain': WithdrawWalletChainToJSON(value.walletChain),
    };
}


