/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LoginBody
 */
export interface LoginBody {
    /**
     * 
     * @type {string}
     * @memberof LoginBody
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof LoginBody
     */
    password: string;
    /**
     * If a user is directed from another platform, they will have a signed url key. Logging in will automatically pull any missing information from the signed url and assign it to the user.
     * @type {string}
     * @memberof LoginBody
     */
    onboardingSignedUrlKey?: string;
}

export function LoginBodyFromJSON(json: any): LoginBody {
    return LoginBodyFromJSONTyped(json, false);
}

export function LoginBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoginBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
        'password': json['password'],
        'onboardingSignedUrlKey': !exists(json, 'onboardingSignedUrlKey') ? undefined : json['onboardingSignedUrlKey'],
    };
}

export function LoginBodyToJSON(value?: LoginBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'password': value.password,
        'onboardingSignedUrlKey': value.onboardingSignedUrlKey,
    };
}


