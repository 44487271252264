/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrderRefundResponsibility,
    OrderRefundResponsibilityFromJSON,
    OrderRefundResponsibilityFromJSONTyped,
    OrderRefundResponsibilityToJSON,
} from './';

/**
 * 
 * @export
 * @interface RefundAutoMenuOrderBody
 */
export interface RefundAutoMenuOrderBody {
    /**
     * 
     * @type {OrderRefundResponsibility}
     * @memberof RefundAutoMenuOrderBody
     */
    responsibility: OrderRefundResponsibility;
    /**
     * 
     * @type {string}
     * @memberof RefundAutoMenuOrderBody
     */
    errorMessage: string;
}

export function RefundAutoMenuOrderBodyFromJSON(json: any): RefundAutoMenuOrderBody {
    return RefundAutoMenuOrderBodyFromJSONTyped(json, false);
}

export function RefundAutoMenuOrderBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): RefundAutoMenuOrderBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'responsibility': OrderRefundResponsibilityFromJSON(json['responsibility']),
        'errorMessage': json['errorMessage'],
    };
}

export function RefundAutoMenuOrderBodyToJSON(value?: RefundAutoMenuOrderBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'responsibility': OrderRefundResponsibilityToJSON(value.responsibility),
        'errorMessage': value.errorMessage,
    };
}


