/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetOnboardingThirdPartiesResponseAllOf,
    GetOnboardingThirdPartiesResponseAllOfFromJSON,
    GetOnboardingThirdPartiesResponseAllOfFromJSONTyped,
    GetOnboardingThirdPartiesResponseAllOfToJSON,
    OnboardingThirdParty,
    OnboardingThirdPartyFromJSON,
    OnboardingThirdPartyFromJSONTyped,
    OnboardingThirdPartyToJSON,
    PaginatedResponse,
    PaginatedResponseFromJSON,
    PaginatedResponseFromJSONTyped,
    PaginatedResponseToJSON,
    PaginationInfo,
    PaginationInfoFromJSON,
    PaginationInfoFromJSONTyped,
    PaginationInfoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetOnboardingThirdPartiesResponse
 */
export interface GetOnboardingThirdPartiesResponse extends PaginatedResponse {
    /**
     * 
     * @type {Array<OnboardingThirdParty>}
     * @memberof GetOnboardingThirdPartiesResponse
     */
    onboardingThirdParties: Array<OnboardingThirdParty>;
}

export function GetOnboardingThirdPartiesResponseFromJSON(json: any): GetOnboardingThirdPartiesResponse {
    return GetOnboardingThirdPartiesResponseFromJSONTyped(json, false);
}

export function GetOnboardingThirdPartiesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetOnboardingThirdPartiesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...PaginatedResponseFromJSONTyped(json, ignoreDiscriminator),
        'onboardingThirdParties': ((json['onboardingThirdParties'] as Array<any>).map(OnboardingThirdPartyFromJSON)),
    };
}

export function GetOnboardingThirdPartiesResponseToJSON(value?: GetOnboardingThirdPartiesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...PaginatedResponseToJSON(value),
        'onboardingThirdParties': ((value.onboardingThirdParties as Array<any>).map(OnboardingThirdPartyToJSON)),
    };
}


