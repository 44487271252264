import {ButtonHTMLAttributes, ReactElement, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {useGetUserProfile} from "@/hooks/useGetUserProfile";
import FrameButton from "@/components/buttons/FrameButton";
import {isDesktop} from "react-device-detect";
import GoFrensLeaderboardMyRankInfoSkeleton
    from "@/components/skeletons/GoFrensPage/GoFrensLeaderboardMyRankInfoSkeleton";
import {useGetUserCommunityInfo} from "@/hooks/useGetCommunityInfo";
import {useNavigate} from "react-router-dom";
import GoFrensMyCommunityLeaderboardBoard from "@/components/goFrens/GoFrensMyCommunityLeaderboardBoard";
import GoFrensLeaderboardMyRankInfo from "@/components/goFrens/GoFrensLeaderboardMyRankInfo";
import {GoFrensApi, RankedMember} from "@devour/client";
import GoFrensMyCommunityLeaderboardSkeleton
    from "@/components/skeletons/GoFrensPage/GoFrensMyCommunityLeaderboardSkeleton";
import GoFrensMonthlyTopMembersModal from "@/components/modals/GoFrensMonthlyTopMembersModal";
import NftProfilePictureBadge from "@/components/NftProfilePictureBadge";
import getConfig from "@/utils/getConfig";
import {addError} from "@/redux/meta/metaActions";
import {ViewMode} from "@/components/goFrens/GoFrensTopLeaderboard";
import {getDateInEst} from "@/utils/getDateInEst";
import {BsArrowRight} from "react-icons/bs";
import GoFrensTopLeaderboardCommunityTableHeader
    from "@/components/goFrens/topLeaderboard/GoFrensTopLeaderboardCommunityTableHeader";

function GoFrensCommunityLeaderBoard(): ReactElement {
    const now = getDateInEst();
    const currentMonth = `${now.getMonth() + 1},${now.getFullYear()}`;
    const history = useNavigate();
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);

    const {data: userProfileData} = useGetUserProfile(fullToken);
    const {data: userCommunityInfo, refetch: refetchCommunityInfo} = useGetUserCommunityInfo(fullToken);
    const [queryParam, setQueryParam] = useState<string>(currentMonth);
    const [leaderboardData, setLeaderboardData] = useState<Array<RankedMember>>(undefined);
    const [userRankInfo, setUserRankInfo] = useState<RankedMember>(undefined);
    const [showTopMembersModal, setShowTopMembersModal] = useState<boolean>(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (userProfileData && userCommunityInfo) {
            const user = userCommunityInfo.members?.find(user => user.id === userProfileData.user?.id);
            setUserRankInfo(user);
        }
    }, [
        userProfileData,
        userCommunityInfo,
    ]);

    useEffect(() => {
        if (fullToken) {
            void refetchCommunityInfo();
        }
    }, [userProfileData?.user]);

    // Get Top Members leaderboard data
    useEffect(() => {
        void getMonthlyLeaderboardData(`${queryParam},${ViewMode.MONTHLY}`);

    }, [queryParam]);

    async function getMonthlyLeaderboardData(leaderboardTimeParam: string): Promise<void> {
        setLeaderboardData(undefined);
        try {
            const res = await new GoFrensApi(getConfig(fullToken)).getMonthlyTopMembersLeaderboard({
                leaderboardTimeParam: leaderboardTimeParam,
            });
            setLeaderboardData(res.topMembers);
        } catch (e) {
            dispatch(await addError(e));
        }
    }

    function toggleModal() {
        setShowTopMembersModal(prevState => !prevState);
    }

    function renderBoostYourRankButton() {
        return (
            <FrameButton
                <ButtonHTMLAttributes<HTMLButtonElement>>
                color="purple"
                size="normal"
                rightIcon={BsArrowRight}
                className={`boost-your-rank ${isDesktop ? "leaderboard-small-btn" : ""}`}
                onClick={() => {
                    history("/go-vip-dashboard");
                }}
            >
                Boost Your Rank
            </FrameButton>
        );
    }

    function getMembersAroundUser() {
        const noOfMembersToShow = 3;
        const rankedMembers = userCommunityInfo.members.filter((member: RankedMember) => member.rankWithinCommunity != null);
        const totalRankedMembers = rankedMembers.length;

        // if the user is the only member, and/or user is not ranked, show user
        if (totalRankedMembers <= 1 || userRankInfo.rankWithinCommunity == null) {
            return [userRankInfo];
        }

        const currUserIdx = rankedMembers.findIndex(u => u.id === userProfileData.user?.id);
        // if the user is ranked first, show user, and at most 2 other members ranked below
        if (currUserIdx === 0) {
            return rankedMembers.slice(0, Math.min(noOfMembersToShow, totalRankedMembers));
        }

        // if the user in ranked last, show the users 1 and 2 ranks above them
        if (currUserIdx === totalRankedMembers - 1) {
            return rankedMembers.slice(Math.max(0, totalRankedMembers - noOfMembersToShow), currUserIdx + 1);
        }
        return rankedMembers.slice(Math.max(0, currUserIdx - 1), Math.min(totalRankedMembers, currUserIdx + 2));
    }

    // Slice the ranked members array
    function getTopMembers() {
        const noOfMembersToShow = 5;
        return leaderboardData
            .filter((member: RankedMember) => member.rankWithinCommunity != null)
            .slice(0, noOfMembersToShow);
    }

    function renderProfilePicture() {
        return (
            <>
                {userProfileData?.user?.gravatar &&
                <img
                    src={userProfileData?.user?.gravatar}
                    alt="User Profile"
                />
                }
                {userProfileData?.user.nftProfilePicture &&
                <NftProfilePictureBadge size="lg"/>
                }
            </>
        );
    }

    return (
        <div className="gofrens-my-community">
            {userRankInfo && leaderboardData &&
            <GoFrensMonthlyTopMembersModal
                show={showTopMembersModal}
                toggle={toggleModal}
                setQuery={setQueryParam}
                renderedData={leaderboardData.filter((member: RankedMember) => member.rankWithinCommunity != null)}
            />
            }
            <div className="gofrens-my-community-leaderboard">
                <div className="gofrens-my-community-leaderboard_top">
                    <div className="gofrens-my-community-leaderboard_top_header">
                        <div className="gofrens-my-community-leaderboard_top_header_user">
                            <div className="gofrens-my-community-leaderboard_top_header_user_image-container">
                                {renderProfilePicture()}
                            </div>
                            <h4>
                                {userRankInfo?.nickname || "GoVIP Player"}
                            </h4>
                        </div>
                        {isDesktop && renderBoostYourRankButton()}
                    </div>

                    {userRankInfo
                        ? <>
                            <GoFrensLeaderboardMyRankInfo
                                rank={userRankInfo.rankWithinCommunity}
                                pointsEarnedForCommunity={userRankInfo.pointsEarnedForCommunity}
                            />
                            <div className="my-community-leaderboard">
                                <GoFrensTopLeaderboardCommunityTableHeader />

                                <GoFrensMyCommunityLeaderboardBoard
                                    rankData={getMembersAroundUser()}
                                    className="around-user-ranks"
                                />
                            </div>
                        </>
                        : <>
                            <GoFrensLeaderboardMyRankInfoSkeleton/>
                            <GoFrensMyCommunityLeaderboardSkeleton rows={3}/>
                        </>
                    }

                    {!isDesktop && renderBoostYourRankButton()}
                </div>

                <div className="gofrens_divider"/>

                <div className="gofrens-my-community-leaderboard_bottom">
                    <div className="gofrens-my-community-leaderboard_bottom_header">
                        <h3>Top Members</h3>
                        <FrameButton
                            <ButtonHTMLAttributes<HTMLButtonElement>>
                            color="white-drop-shadow"
                            size="normal"
                            className="gofrens-top-leaderboard_top-inputs_button leaderboard-small-btn"
                            onClick={toggleModal}
                        >
                            View Top 50
                        </FrameButton>
                    </div>

                    <div className="gofrens-my-community-leaderboard_bottom_body">
                        {userRankInfo && leaderboardData
                            ? <div className="my-community-leaderboard">
                                <GoFrensTopLeaderboardCommunityTableHeader />
                                <GoFrensMyCommunityLeaderboardBoard
                                    rankData={getTopMembers()}
                                    className="members-top-ranks"
                                />
                            </div>
                            : <GoFrensMyCommunityLeaderboardSkeleton rows={5}/>}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GoFrensCommunityLeaderBoard;