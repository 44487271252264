import {
    MenuItem,
    MenuOrderItem,
    MenuCategory,
    MenuCustomization,
    MenuCustomizationItem,
    MenuOrderSubItem,
} from "@devour/client";


/**
 * Loop through (nested) menu item customizations and find the finalized menu item response
 * @param menuOrderSubItem
 * @param customizations
 */
export function findMenuCustomizationItemResponse(menuOrderSubItem: MenuOrderSubItem, customizations: Array<MenuCustomization>): MenuCustomizationItem {
    if (!customizations || !customizations.length) {
        return;
    }
    const allOptions = customizations.map((customization) => customization.options).flat();
    const findOption = allOptions.find((option) => option.id === menuOrderSubItem.externalId || option.id === menuOrderSubItem.menuCustomizationItemId);

    if (findOption) {
        return findOption;
    }

    return findMenuCustomizationItemResponse(
        menuOrderSubItem,
        allOptions.map((o) => o.customizations).flat(),
    );
}

/**
 * Loop through (nested) menu categories and find the finalized menu item response
 * @param menuOrderItem
 * @param menuCategories
 */
export function findMenuItemResponse(menuOrderItem: MenuOrderItem, menuCategories: Array<MenuCategory>): MenuItem {
    if (!menuCategories || !menuCategories.length) {
        return;
    }
    const menuItems = menuCategories.map(c => c.menuItems).flat();
    const findItem = menuItems.find(i => i.id === menuOrderItem.menuItemId || i.id === menuOrderItem.externalId);

    if (findItem) {
        return findItem;
    }

    return findMenuItemResponse(menuOrderItem, menuCategories.map(c => c.menuSubcategories).flat());
}

/**
 * Loop through all menu categories, menu items, & menu item customizations to ensure this menu order item is still valid.
 * @param menuOrderItem
 * @param menuCategories
 */
export function validateMenuOrderItem(menuOrderItem: MenuOrderItem, menuCategories: Array<MenuCategory>): boolean {
    const menuItem = findMenuItemResponse(menuOrderItem, menuCategories);

    // Check the main menu item
    if (!menuItem || !menuItem.isEnabled) {
        return false;
    }

    // If menu order item has no customizations, then no need for further checks.
    if (!menuOrderItem.customizations) {
        return true;
    }

    // Check each individual customization item
    return menuOrderItem.customizations.every((customizationItem) => {
        const menuCustomizationItem = findMenuCustomizationItemResponse(customizationItem, menuItem.customizations);
        return menuCustomizationItem && menuCustomizationItem.isEnabled;
    });
}
