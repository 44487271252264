/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetDpayPriceResponse
 */
export interface GetDpayPriceResponse {
    /**
     * 
     * @type {number}
     * @memberof GetDpayPriceResponse
     */
    dPayPricePerUsd: number;
}

export function GetDpayPriceResponseFromJSON(json: any): GetDpayPriceResponse {
    return GetDpayPriceResponseFromJSONTyped(json, false);
}

export function GetDpayPriceResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetDpayPriceResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dPayPricePerUsd': json['dPayPricePerUsd'],
    };
}

export function GetDpayPriceResponseToJSON(value?: GetDpayPriceResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dPayPricePerUsd': value.dPayPricePerUsd,
    };
}


