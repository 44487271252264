import {useQuery} from "@tanstack/react-query";
import {GetReferralCodesResponse, ReferralsApi, Token} from "@devour/client";
import getConfig from "@/utils/getConfig";

async function fetchOwnReferralCode(fullToken: Token): Promise<GetReferralCodesResponse> {
    return await new ReferralsApi(getConfig(fullToken)).getOwnReferralCode({});
}

function ownReferralCodeQuery(fullToken: Token) {
    return {
        queryKey: [
            "own-referral-code",
            fullToken?.id,
        ],
        queryFn: () => fetchOwnReferralCode(fullToken),
        staleTime: 30 * 24 * 60 * 60000,
        enabled: Boolean(fullToken?.id),
    } as const;
}

export function useGetOwnReferralCode(fullToken: Token) {
    return useQuery(ownReferralCodeQuery(fullToken));
}