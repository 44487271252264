import {getRankSuffix} from "@/utils/getRankSuffix";

interface Props {
    rank: number | undefined;
    pointsEarnedForCommunity: number;
}
function GoFrensLeaderboardMyRankInfo(props: Props) {
    return (
        <div className="gofrens-my-community-leaderboard_top_my-info">
            <div className="gofrens-my-community-leaderboard_top_my-info_container">
                <h5>My Rank</h5>
                <div className="gofrens-my-community-leaderboard_top_my-info_container_main">
                    {props.rank || <span className="my-rank-unranked">Unranked</span>}<span className="my-rank-suffix">{getRankSuffix(props.rank)}</span>
                </div>
                <p className="gofrens-my-community-leaderboard_top_my-info_container_sub-section">
                    in this community
                </p>
            </div>
            <div className="gofrens-my-community-leaderboard_top_my-info_container">
                <h5>My Points</h5>
                <div className="gofrens-my-community-leaderboard_top_my-info_container_main">
                    {props.pointsEarnedForCommunity.toLocaleString("en-US")}<span className="my-rank-suffix">pts</span>
                </div>
                <p className="gofrens-my-community-leaderboard_top_my-info_container_sub-section">
                    contributed
                </p>
            </div>
        </div>
    );
}

export default GoFrensLeaderboardMyRankInfo;