import {ReactElement} from "react";
import {BsCheckLg} from "react-icons/bs";

export interface Props {
    message: string;
}

function LandingIndustryBenefitsRow(props: Props): ReactElement {

    return (
        <div className="landing_industry-pass_contents_ad_card_promo-row">
            <BsCheckLg className="landing_industry-pass_contents_ad_card_promo-row_check"/>
            <p>{props.message}</p>
        </div>
    );
}

export default LandingIndustryBenefitsRow;
