/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OloError
 */
export interface OloError {
    /**
     * 
     * @type {string}
     * @memberof OloError
     */
    message: string;
    /**
     * 
     * @type {number}
     * @memberof OloError
     */
    num: number;
    /**
     * 
     * @type {number}
     * @memberof OloError
     */
    optionid?: number;
    /**
     * 
     * @type {number}
     * @memberof OloError
     */
    productid?: number;
}

export function OloErrorFromJSON(json: any): OloError {
    return OloErrorFromJSONTyped(json, false);
}

export function OloErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): OloError {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'message': json['message'],
        'num': json['num'],
        'optionid': !exists(json, 'optionid') ? undefined : json['optionid'],
        'productid': !exists(json, 'productid') ? undefined : json['productid'],
    };
}

export function OloErrorToJSON(value?: OloError | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'message': value.message,
        'num': value.num,
        'optionid': value.optionid,
        'productid': value.productid,
    };
}


