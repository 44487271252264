/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MenuItemSearchEssential
 */
export interface MenuItemSearchEssential {
    /**
     * 
     * @type {string}
     * @memberof MenuItemSearchEssential
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof MenuItemSearchEssential
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof MenuItemSearchEssential
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof MenuItemSearchEssential
     */
    price: number;
}

export function MenuItemSearchEssentialFromJSON(json: any): MenuItemSearchEssential {
    return MenuItemSearchEssentialFromJSONTyped(json, false);
}

export function MenuItemSearchEssentialFromJSONTyped(json: any, ignoreDiscriminator: boolean): MenuItemSearchEssential {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'price': json['price'],
    };
}

export function MenuItemSearchEssentialToJSON(value?: MenuItemSearchEssential | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'price': value.price,
    };
}


