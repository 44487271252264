import {ReactElement} from "react";
import {MenuOrder, OrderDiscountType} from "@devour/client";
import Tooltip from "../Tooltip";
import {AiOutlineInfoCircle} from "react-icons/ai";
import {roundNumber} from "@/utils/roundNumber";
import Skeleton from "react-loading-skeleton";

interface Props {
    menuOrder: MenuOrder;
}

function MenuOrderFeesTaxes(props: Props): ReactElement {

    // Get service fees
    const serviceFees: Array<number> = props.menuOrder?.serviceFees?.map((item) => item.amount);
    const serviceFeesTotal: number = serviceFees?.length
        ? serviceFees.reduce((a, b) => a + b)
        : 0;

    function renderFees(): string {
        // Don't display tax discounts to customer
        const taxDiscounts = props.menuOrder.discounts?.filter((discount) => discount.type === OrderDiscountType.SALESTAX);
        const discountTypeTaxCount: Array<number> = taxDiscounts?.map((item) => item.amount);
        const discountTypeTax: number = discountTypeTaxCount.length
            ? discountTypeTaxCount.reduce((a, b) => a + b)
            : 0;

        return roundNumber(props.menuOrder.tax - discountTypeTax + serviceFeesTotal).toFixed(2);
    }

    function renderTax(): string {
        // Don't display tax discounts to customer
        const taxDiscounts = props.menuOrder.discounts?.filter((discount) => discount.type === OrderDiscountType.SALESTAX);
        const discountTypeTaxCount: Array<number> = taxDiscounts?.map((item) => item.amount);
        const discountTypeTax: number = discountTypeTaxCount.length
            ? discountTypeTaxCount.reduce((a, b) => a + b)
            : 0;

        return roundNumber(props.menuOrder.tax - discountTypeTax).toFixed(2);
    }

    return (
        <div className="menu-order-checkout-totals_row">
            <div className="menu-order-checkout-totals_row_label">
                <div className="order-receipt-modal_body_fees-container">
                    <p className="order-receipt-modal_body_fees-container_fees devour-cart-card_fees-font-adjust">
						Estimated Fees & Taxes
                    </p>
                    <Tooltip
                        outsideIcon={AiOutlineInfoCircle}
                    >
                        <p className="menu-order-checkout-totals_text">
                            <strong>Tax: ${renderTax()}</strong>
                        </p>

                        <p className="menu-order-checkout-totals_text">
                            <strong>Service Fee: ${roundNumber(serviceFeesTotal).toFixed(2)}</strong>
                        </p>

                        {props.menuOrder.serviceFees?.length === 1 && props.menuOrder.serviceFees[0].description &&
                        <p>
							    {props.menuOrder.serviceFees[0].description}
                        </p>
                        }

                    </Tooltip>
                </div>

            </div>
            <div className="menu-order-checkout-totals_row_value">
                <p className="devour-cart-card_fees-font-adjust">
                    {props.menuOrder.callbackComplete
                        ? `$${renderFees()}`
                        : <Skeleton height={10}/>}
                </p>
            </div>
        </div>
    );
}


export default MenuOrderFeesTaxes;
