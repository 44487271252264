import {ReactElement, ReactNode, useMemo, useState} from "react";
import {
    MenuOrder,
    SortOrder,
    GetMenuOrdersOwnSort,
    MenuOrderStatus, Token,
} from "@devour/client";
import FramePaginator, {defaultFrontendPagination, FrontendPagination} from "@/components/paginator/FramePaginator";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import MenuOrderListCard from "../../components/menuOrder/MenuOrderListCard";
import FrameButton, { FrameOneButtonColor } from "../../components/buttons/FrameButton";
import MenuOrderListPageSkeleton
    from "../../components/skeletons/MenuOrderListPage/MenuOrderListPageSkeleton";
import {useGetMenuOrdersOwn} from "@/hooks/useGetMenuOrdersOwn";
import DevourTopNav from "@/components/DevourTopNav";
import Tabs from "@/components/Tabs";
import MenuOrderPlaceholder from "./MenuOrderPlaceholder";
import { toggleChatOpen } from "@/redux/meta/metaActions";

export const processingList: Array<MenuOrderStatus> = [
    MenuOrderStatus.PAID,
    MenuOrderStatus.PROCESSING,
    MenuOrderStatus.RECEIVED,
    MenuOrderStatus.DELIVERYSERVICE,
    MenuOrderStatus.DRIVERPICKUP,
    MenuOrderStatus.OUTFORDELIVERY,
    MenuOrderStatus.DRIVERATDESTINATION,
];

export const completedList: Array<MenuOrderStatus> = [
    MenuOrderStatus.DELIVERED,
    MenuOrderStatus.CUSTOMERPICKUP,
    MenuOrderStatus.CUSTOMERPICKEDUP,
    MenuOrderStatus.REFUNDED,
    MenuOrderStatus.AUTOREFUNDED,
    MenuOrderStatus.RESOLVED,
];

export const cancelledList: Array<MenuOrderStatus> = [
    MenuOrderStatus.ERRORDEVOUR,
    MenuOrderStatus.ERRORRESTAURANT,
    MenuOrderStatus.ERRORDELIVERY,
    MenuOrderStatus.ERRORDEVOURIQ,
    MenuOrderStatus.SYSTEMCANCELLED,
];


export const menuOrderFinalizedStatuses: Array<MenuOrderStatus> = [
    ...completedList,
    ...cancelledList,
];

export function getOrderStatusTag(order: MenuOrder): { name: string, color: FrameOneButtonColor } {
    if (completedList.includes(order.status)) {
        return {
            name: "Completed",
            color: "success",
        };
    }

    if (cancelledList.includes(order.status)) {
        return {
            name: "Cancelled",
            color: "gray-light",
        };
    }

    return {
        name: "Processing",
        color: "warning",
    };
}

const tabs = ["In progress", "History"];
enum tabsEnum {
    inProgress = "In progress", history = "History",
}

function MenuOrderListPage(): ReactElement {
    const [
        frontendPagination,
        setFrontendPagination,
    ] = useState<FrontendPagination>(defaultFrontendPagination);
    const dispatch = useDispatch();
    const isChatOpen = useSelector((store: IStore) => store.metaStore.isChatOpen);
    const fullToken = useSelector<IStore>(state => state.authStore.fullToken);
    const [activeTab, setActiveTab] = useState<string>(tabs[0]);

    const {data: menuOrdersOwn} = useGetMenuOrdersOwn(fullToken as Token, {
        sort: GetMenuOrdersOwnSort.CreatedAt,
        order: SortOrder.Desc,
        limit: frontendPagination.limit,
        offset: frontendPagination.offset,
        status: activeTab === tabsEnum.inProgress ? processingList : completedList,
    });


    const {processing: processingOrders, completed: completedOrders} = useMemo(() => {
        if (!menuOrdersOwn) {
            return {processing: [],
                completed: []};
        }
        return filterOrderHelper(menuOrdersOwn?.menuOrders);
    }, [menuOrdersOwn]);

    function filterOrderHelper(listItems): { processing: any, completed: any } {
        const processing = [];
        const completed = [];

        for (let i = 0; i < listItems.length; i++) {
            const item = listItems[i];
            if (processingList?.includes(item.status.toUpperCase())) {
                processing.push(item);
            } else {
                completed.push(item);
            }
        }

        return {processing,
            completed};
    }
    const filteredOrders = useMemo(() => {
        let orders = [];
        if (activeTab === tabsEnum.inProgress) {
            orders = processingOrders;
        } else if (activeTab === tabsEnum.history) {
            orders = completedOrders;
        }
        return orders;

    }, [activeTab, processingOrders, completedOrders]);

    function handleChangeTab(tab: string) {
        setFrontendPagination(defaultFrontendPagination);
        setActiveTab(tab);
    }

    function renderOrderCard(menuOrder: MenuOrder, i: number): ReactNode {
        return (
            <MenuOrderListCard
                key={`menu-order_${i}`}
                order={menuOrder}
            />
        );
    }

    function toggleHelpIsOpen(): void {
        dispatch(toggleChatOpen(!isChatOpen));
    }


    return (
        <div className="menu-order-list-page">
            <DevourTopNav text="My Orders"/>
            <div className="menu-order-list-page_container">
                <div className="menu-order-list-page_content">
                    <div className="menu-order-list-page_content_top">
                        <Tabs activeTab={activeTab} tabs={tabs} onTabClicked={handleChangeTab} />
                        <FrameButton
                            color="gray"
                            size="narrow"
                            onClick={toggleHelpIsOpen}
                        >
                                        Order Help
                        </FrameButton>
                    </div>

                    {menuOrdersOwn ? <section className="menu-order-list-page_content_list">
                        {filteredOrders.length > 0
                            ? <>
                                {filteredOrders?.map(renderOrderCard) }
                                {menuOrdersOwn.paginationInfo && <FramePaginator
                                    {...frontendPagination}
                                    {...menuOrdersOwn.paginationInfo}
                                    onPaginationChange={setFrontendPagination }
                                    showPaginatorLimit={true}/>}
                            </>
                            : <MenuOrderPlaceholder/>}
                    </section> : <MenuOrderListPageSkeleton/>}
                </div>
            </div>
        </div>
    );
}

export default MenuOrderListPage;
