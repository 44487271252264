import {ChangeEvent, Dispatch, ReactElement, SetStateAction, useContext, useEffect, useState} from "react";
import {ErrorType, MenuOrderDiscount, MenuOrdersApi} from "@devour/client";
import FrameButton from "../../buttons/FrameButton";
import {addError, decrementLoading, incrementLoading} from "@/redux/meta/metaActions";
import getConfig from "../../../utils/getConfig";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import FrameOneModal from "@/components/modals/modalComponents/FrameOneModal";
import FrameModalBody from "@/components/modals/modalComponents/FrameModalBody";
import {FaCheckCircle, FaChevronLeft} from "react-icons/fa";
import classNames from "classnames";
import Skeleton from "react-loading-skeleton";
import {useParams} from "react-router";
import {useMenuOrder} from "@/hooks/menuOrder/useMenuOrder";
import {RestaurantContext} from "@/pages/restaurants/context/RestaurantContext";
import {getApiErrorMessage} from "@/utils/getApiErrorMessage";

interface FormValues {
    discountCodeInput: string;
}

const defaultValues: FormValues = {
    discountCodeInput: "",
};

interface Props {
    isOpen: boolean;
    onClose: () => void;
    setIsOrderSummaryLoading?: Dispatch<SetStateAction<boolean>>;
}

function sortDiscounts(a: MenuOrderDiscount, b: MenuOrderDiscount): number {
    // Sort by priority descending
    if (a.priority > b.priority) return -1;
    if (a.priority < b.priority) return 1;
    // Sort by amount descending
    if (a.amount > b.amount) return -1;
    if (a.amount < b.amount) return 1;
    return 0;
}

/**
 * Please redo this component after we get rid of the restaurant context,
 * unfortunately it's being used in the cart and the
 * checkout, which forces the less than ideal menuId situation.
 */
function CheckoutDiscountSelectionModal(props: Props): ReactElement {
    const {menuOrderId: contextMenuOrderId} = useContext(RestaurantContext);
    const {menuOrderId: paramMenuOrderId} = useParams<{ menuOrderId: string; }>();
    const menuOrderId = paramMenuOrderId || contextMenuOrderId;

    const {data: menuOrder, refetch: refetchMenuOrder} = useMenuOrder(menuOrderId);

    const dispatch = useDispatch();
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const [
        formValues,
        setFormValues,
    ] = useState<FormValues>(defaultValues);
    const [
        menuOrderDiscounts,
        setMenuOrderDiscounts,
    ] = useState<Array<MenuOrderDiscount>>([]);
    const appliedDiscountCodes = menuOrder?.discounts?.filter(discount => discount?.code);
    const [
        discountCodeError,
        setDiscountCodeError,
    ] = useState<string>("");

    useEffect(() => {
        if (props.isOpen) {
            setDiscountCodeError("");
            setFormValues(defaultValues);
        }
    }, [props.isOpen]);

    useEffect(() => {
        if (props.isOpen && menuOrder) {
            void fetchMenuOrderDiscounts();
        }
    }, [props.isOpen, menuOrder]);

    /**
     * Get the details for this menu order from our api.
     */
    async function fetchMenuOrderDiscounts(): Promise<void> {
        if (!menuOrder || !fullToken) {
            return;
        }

        try {
            const orderDiscounts = await new MenuOrdersApi(getConfig()).getMenuOrderDiscounts({
                id: menuOrder.id,
            });
            setMenuOrderDiscounts(orderDiscounts.discounts);
        } catch (e) {
            dispatch(await addError(e));
        }

    }


    async function onUpdateOrderDiscounts(updatedDiscountSelected?: string, updatedDiscountCodes?: Array<string>): Promise<void> {
        try {
            // TODO: refactor
            if (props?.setIsOrderSummaryLoading) {
                props.setIsOrderSummaryLoading(true);
            }

            // TODO: refactor - bandaid fix :/
            dispatch(incrementLoading());
            await new MenuOrdersApi(getConfig()).updateMenuOrder({
                id: menuOrder.id,
                createMenuOrderBody: {
                    discountSelected: updatedDiscountSelected !== null ? updatedDiscountSelected : menuOrder.discountSelected,
                    discountCodes: updatedDiscountCodes ? updatedDiscountCodes : undefined,
                },
            });

            await refetchMenuOrder();
            setDiscountCodeError("");
            setFormValues(defaultValues);
        } catch (e) {
            if (e.status === 400) {
                const errorMessage = await getApiErrorMessage(e);
                setDiscountCodeError(errorMessage);
            } else {
                dispatch(await addError(e));
            }
        } finally {
            // TODO: refactor
            if (props?.setIsOrderSummaryLoading) {
                props.setIsOrderSummaryLoading(false);
            }
            dispatch(decrementLoading());
        }
    }

    /**
     * Handle discount selected onChange events.
     *
     * @param value
     */
    async function discountSelectedOnChange(value: string): Promise<void> {
        const discountSelected = menuOrder.discountSelected !== value ? value : "";
        await onUpdateOrderDiscounts(discountSelected);
    }

    /**
     * Handle discount code onChange events.
     *
     * @param e
     */
    function discountCodeOnChange(e: ChangeEvent<HTMLInputElement>): void {
        // remove error as input was invalid then changes
        if (discountCodeError) {
            setDiscountCodeError("");
        }
        setFormValues({
            ...formValues,
            discountCodeInput: e.target.value,
        });
    }

    async function handleDiscountCodeRemoval(): Promise<void> {
        await onUpdateOrderDiscounts(null, []);
    }

    async function handleDiscountCodeApply(): Promise<void> {
        await onUpdateOrderDiscounts(
            undefined,
            // Only 1 discount code can be applied at a time
            [formValues.discountCodeInput],
        );
    }

    function renderDiscountCard(discount: MenuOrderDiscount): ReactElement {
        return (
            <div
                className={classNames(
                    "checkout-discount-selection-modal_body_discounts_option",
                    {"is-selected": menuOrder.discountSelected === discount.reference || discount.code},
                )}
                key={discount.reference as string}
                onClick={() => discount.code
                    ? handleDiscountCodeRemoval()
                    : discountSelectedOnChange(discount.reference)}
            >
                <div className="checkout-discount-selection-modal_body_discounts_option_info">
                    <div className="checkout-discount-selection-modal_body_discounts_option_info_label">
                        <strong>{discount.label}</strong>
                    </div>
                    <div className="checkout-discount-selection-modal_body_discounts_option_info_description">
                        {discount.description
                            ? discount.description
                            : `Get ${discount.amount.toFixed(2)} off`}
                    </div>
                </div>
                <div className="checkout-discount-selection-modal_body_discounts_option_selected-icon">
                    {(menuOrder.discountSelected === discount.reference || discount.code) && <FaCheckCircle />}
                </div>
            </div>
        );
    }

    function renderDiscountsSkeleton(): ReactElement {
        return (
            <>
                <div className="checkout-discount-selection-modal_body_discounts-heading">
                    <Skeleton height={17.5} width={150} />
                </div>
                <div className="checkout-discount-selection-modal_body_discounts">
                    {Array.from({length: 3}, (_, i) => <div className="checkout-discount-selection-modal_body_discounts_option" key={i}>
                        <div className="checkout-discount-selection-modal_body_discounts_option_info">
                            <div><Skeleton width={150} height={40}/></div>
                            <div><Skeleton width={200} height={20}/></div>
                        </div>
                    </div>)}
                </div>
            </>
        );
    }

    if (!menuOrder) {
        return null;
    }

    return (
        <FrameOneModal
            isOpen={props.isOpen}
            toggle={props.onClose}
            size="sm"
            contentClassName="checkout-discount-selection-modal"
        >

            <FrameModalBody className="checkout-discount-selection-modal_body">
                <div className="checkout-discount-selection-modal_header">
                    <FaChevronLeft
                        className="checkout-discount-selection-modal_header_icon"
                        onClick={props.onClose}
                    />
                    <h4>Add Promotion</h4>
                </div>

                <hr />

                <div className="checkout-discount-selection-modal_body_code">
                    <div>
                        <input
                            className={classNames(
                                "checkout-discount-selection-modal_body_code_input",
                                {"is-error": discountCodeError},
                            )}
                            placeholder="Enter promo code"
                            value={formValues.discountCodeInput}
                            onChange={discountCodeOnChange}
                        />
                        {discountCodeError &&
                        <div className="checkout-discount-selection-modal_body_code_input-error">
                                Error: {discountCodeError}
                        </div>
                        }
                    </div>

                    <FrameButton
                        color={formValues.discountCodeInput && !discountCodeError ? "purple" : "gray"}
                        size="narrow"
                        onClick={handleDiscountCodeApply}
                        forwardProps={{
                            disabled: !formValues.discountCodeInput || discountCodeError,
                        }}
                        className="checkout-discount-selection-modal_body_code_save"
                    >
                        Apply
                    </FrameButton>
                </div>

                {!menuOrderDiscounts?.length && !appliedDiscountCodes?.length && renderDiscountsSkeleton()}
                {(menuOrderDiscounts?.length > 0 || appliedDiscountCodes?.length > 0) &&
                <>
                    <div className="checkout-discount-selection-modal_body_discounts-heading">Available Promotions</div>
                    <div className="checkout-discount-selection-modal_body_discounts">
                        {appliedDiscountCodes?.map(discount => renderDiscountCard(discount))}
                        {menuOrderDiscounts?.sort(sortDiscounts)
                            .map(discount => renderDiscountCard(discount))
                        }
                    </div>
                </>
                }
            </FrameModalBody>
        </FrameOneModal>
    );
}

export default CheckoutDiscountSelectionModal;
