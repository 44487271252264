import { NftGrouping, NftOwnershipMatrix, NftTracker } from "@devour/client";

const getOwnedNftData = (grouping: NftGrouping, trackers?: NftTracker[], nftOwnerShip?: NftOwnershipMatrix[]) => {
    let ownedCount = 0;
    const groupingTrackers = trackers?.filter((t) => t.groupingId === grouping.id);
    nftOwnerShip?.[0]?.forEach((ownership) => {
        if (ownership.nftTrackerId && trackers?.find((t) => t.id === ownership.nftTrackerId)?.groupingId === grouping.id) {
            ownedCount++;
        }
    });
    return {ownedCount,
        groupingTrackers};
};

export default getOwnedNftData;