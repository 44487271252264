import {ReactElement} from "react";
import classNames from "classnames";

interface Props {
    isModal?: boolean;
}

function GoFrensTopLeaderboardCommunityTableHeader(props: Props): ReactElement {

    return (
        <div className={classNames(
            "my-community-leaderboard_header",
            {"is-modal": props.isModal},
        )}>
            <div className="my-community-leaderboard_header_rank"><p>Rank</p></div>
            <div className="my-community-leaderboard_header_member"><p>Community</p></div>
            <div className="my-community-leaderboard_header_exp"><p>EXP</p></div>
        </div>
    );
}

export default GoFrensTopLeaderboardCommunityTableHeader;
