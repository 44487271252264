import {
    ChangeEvent,
    ReactElement,
    ReactNode, useCallback, useContext, useEffect, useMemo,
    useRef,
    useState,
} from "react";
import {
    Address, AddressBook,
    BrandMapColorTheme,
    HandoffOptions,
    PaginationInfo,
    Restaurant,
} from "@devour/client";
import SearchInput from "@/components/inputs/SearchInput";
import BrandMapLandingPageGoogleMapsWrapper from "@/components/brands/BrandMapLandingPageGoogleMapsWrapper";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {toggleOrderHandoff, updateLastSearchedPlaceId} from "@/redux/meta/metaActions";
import MapLandingBusinessListCard from "@/components/MapLandingBusinessListCard";
import HandoffToggle from "@/components/HandoffToggle";
import {useParams} from "react-router";
import {useGetBrandMap} from "@/hooks/useGetBrandMap";
import {BrandMapContext, BrandMapStates} from "@/pages/brandMap/context/BrandMapContext";
import FramePaginator, {FrontendPagination} from "@/components/paginator/FramePaginator";
import useGetBrandMapRestaurants from "@/hooks/useGetBrandMapRestaurants";
import _, {omit} from "lodash";
import {framePaginationLimiterOptions} from "@/components/paginator/FramePaginatorLimiter";

interface Props {
    toggleEmailModal?: (showModal: boolean) => void;
    isInitialState: boolean;
    userAddress?: Address | AddressBook;
}

const defaultFrontendPagination: FrontendPagination = {
    offset: 0,
    limit: framePaginationLimiterOptions?.[0]?.value as number,
};

export function getDefaultMapZoom(address: Address): number {
    return address?.locality ? 11
        : address?.administrativeArea ? 8
            : 5;
}

export function getMapCenter(address: Address): google.maps.LatLngLiteral {
    if (address) {
        return {
            lat: address.location.coordinates?.[1],
            lng: address.location.coordinates?.[0],
        };
    }
}

function BrandMapLocationsSearch(props: Props): ReactElement {
    const {slug} = useParams<{ slug: string }>();
    const {userAddress} = props;
    const dispatch = useDispatch();
    const handoff = useSelector((store: IStore) => store.metaStore.handoff);
    const placeId = useSelector((store: IStore) => store.metaStore.lastSearchedPlaceId);
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);

    const {selectedRestaurant, setSelectedRestaurant, setBrandMapState} = useContext(BrandMapContext);
    const [searchValue, setSearchValue] = useState<string>("");
    const [querySearchFilter, setQuerySearchFilter] = useState<string>("");
    const [frontendPagination, setFrontendPagination] = useState<FrontendPagination>(defaultFrontendPagination);

    const {data: brandMap} = useGetBrandMap(slug);
    const {data: restaurantResults, isFetching: isRestaurantsDataLoading} = useGetBrandMapRestaurants(
        slug,
        placeId ?? undefined,
        handoff,
        frontendPagination.offset,
        frontendPagination.limit,
        querySearchFilter,
    );

    const mapRef = useRef<google.maps.Map>(null);
    const debounceLoadData = useCallback(_.debounce(updateSearchFilter, 1000), []);

    const allRestaurants = restaurantResults?.allRestaurants;
    const restaurantsToRender = restaurantResults?.paginatedRestaurants;

    // Refs for scrolling the active business list card to the top of the list
    const businessRefs = useRef<(HTMLDivElement | null)[]>([]);
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const memoizedDefaultCenter = useMemo(() => getMapCenter(userAddress), [userAddress?.placeId]);
    const memoizedDefaultZoom = useMemo(() => getDefaultMapZoom(userAddress), [userAddress?.placeId]);
    const prevSearchedPlaceIdRef = useRef<string | null>(null);

    /**
     * Handles map centering and zooming when a restaurant is selected or de-selected.
     */
    useEffect(() => {
        if (!restaurantsToRender || !allRestaurants || !mapRef.current) {
            return;
        }
        const activeRestaurant = allRestaurants.find(b => b.id === selectedRestaurant);
        if (activeRestaurant) {
            mapRef.current.setZoom(15);
            mapRef.current.panTo(getMapCenter(activeRestaurant.address));
        } else if (restaurantsToRender[0]) {
            mapRef.current.setZoom(getDefaultMapZoom(restaurantsToRender[0].address));
        }
    }, [selectedRestaurant]);

    /**
     * Handles map centering and zooming when the list of businesses changes.
     */
    useEffect(() => {
        if (restaurantsToRender?.[0]) {
            if (restaurantsToRender.find(r => r.id === selectedRestaurant)) {
                // do not pan the map if the selected restaurant is within the current paginated list as it is already handled by the selectedRestaurant effect.
                return;
            }
            if (prevSearchedPlaceIdRef.current === placeId) {
                mapRef.current.setZoom(getDefaultMapZoom(restaurantsToRender[0].address));
                mapRef.current.panTo(getMapCenter(restaurantsToRender[0].address));
            } else {
                // do not pan the map if the list changes because user just changed the address
                prevSearchedPlaceIdRef.current = placeId;
            }
        }
    }, [restaurantsToRender]);

    useEffect(() => {
        void debounceLoadData(searchValue);
    }, [searchValue]);

    /**
     * Reset the search filter when user enters a new address or changes handoff.
     */
    useEffect(() => {
        if (placeId) {
            setFrontendPagination(defaultFrontendPagination);
            setSearchValue("");
            setQuerySearchFilter("");
        }
    }, [placeId, handoff]);

    useEffect(() => {
        const activeIndex = restaurantsToRender?.findIndex(b => b.id === selectedRestaurant);

        // Selected business exists in the list of businesses
        if (activeIndex !== -1 && businessRefs.current[activeIndex]) {
            const activeElement = businessRefs.current[activeIndex];
            const scrollContainer = scrollContainerRef.current;

            // Scroll the business card to the top of the scrollable container
            scrollContainer.scrollTo({
                top: activeElement.offsetTop - scrollContainer.offsetTop,
                behavior: "smooth",
            });
        }
    }, [selectedRestaurant, restaurantsToRender]);

    function updateSearchFilter(searchValue: string): void {
        setFrontendPagination({
            ...frontendPagination,
            offset: 0,
        });
        setQuerySearchFilter(searchValue);
    }

    function toggleHandoff(option: HandoffOptions): void {
        dispatch(toggleOrderHandoff(option));
    }

    /**
     * Update state with new search value.
     *
     * @param e
     */
    function handleSearchChange(e: ChangeEvent<HTMLInputElement>): void {
        setSearchValue(e.target.value);
    }

    /**
     * Clear the search value.
     *
     */
    function clearSearch(): void {
        setSearchValue("");
    }

    /**
     * Render each "card" for each business location.
     *
     * @param business
     * @param i
     */
    function renderBusiness(business: Restaurant, i: number): ReactNode {
        return (
            <div
                key={`business_${i}`}
                className="brand-map-locations_map_list_item"
                ref={el => businessRefs.current[i] = el}
            >
                <MapLandingBusinessListCard
                    business={business}
                    onSelectClick={onRestaurantSelect}
                    isActive={selectedRestaurant === business.id}
                    brandMap={brandMap}
                    onOpenRestaurantMenu={onOpenRestaurantMenu}
                    isInitialState={props.isInitialState}
                />
            </div>
        );
    }

    /**
     * Save ref to the map.
     *
     * @param _mapRef
     */
    function saveMapRef(_mapRef: google.maps.Map): void {
        mapRef.current = _mapRef;
    }

    function onOpenRestaurantMenu(): void {
        if (!fullToken) {
            props.toggleEmailModal?.(true);
        } else {
            setBrandMapState(BrandMapStates.ORDER_IN_PROGRESS);
        }
    }

    function onRestaurantSelect(business: Restaurant) {
        const selectedRestaurantId = business.id === selectedRestaurant ? null : business.id;
        const restaurant = restaurantsToRender?.find(r => r.id === selectedRestaurantId);
        setSelectedRestaurant(selectedRestaurantId);
        if (!restaurant) {
            // restaurant is not within current paginated list, update offset to show selected restaurant
            const restaurantIndex = restaurantResults.allRestaurants.findIndex(r => r.id === selectedRestaurantId);
            if (restaurantIndex >= 0) {
                setFrontendPagination({
                    ...frontendPagination,
                    offset: Math.floor(restaurantIndex / frontendPagination.limit),
                });
            }
        }
    }

    function onPaginationChange(newPagination: PaginationInfo): void {
        setFrontendPagination(newPagination);
    }

    return (
        <div className="brand-map-locations_map">
            <div className="brand-map-locations_map_header">
                {brandMap?.brand?.image &&
                    <div className="brand-map-locations_map_header_image">
                        <img
                            src={brandMap.brand.image.url}
                            alt={brandMap.brand.name}
                        />
                    </div>
                }

                <div className="brand-map-locations_map_header_text">
                    <h3 className="brand-map-locations_map_header_text_name">{brandMap?.brand?.name}</h3>

                    <p>{brandMap?.businesses?.length} Locations</p>
                </div>
            </div>

            <div className="brand-map-locations_map_handoff">
                <HandoffToggle onHandoffToggle={toggleHandoff}/>
            </div>

            <div className="brand-map-locations_map_search">
                <SearchInput
                    placeholder="Search for a location"
                    value={searchValue}
                    onChange={handleSearchChange}
                    maxLength={15}
                    onClear={clearSearch}
                />
            </div>
            <div className="brand-map-locations_map_render">
                <BrandMapLandingPageGoogleMapsWrapper
                    activeBusinessId={selectedRestaurant}
                    businessesToRender={restaurantResults?.allRestaurants || []}
                    brandMap={brandMap}
                    saveMapRef={saveMapRef}
                    onMapPinClick={onRestaurantSelect}
                    center={memoizedDefaultCenter}
                    defaultZoom={memoizedDefaultZoom}
                />
            </div>

            {isRestaurantsDataLoading
                ? <div className="brand-map-locations_map_list">
                    {Array.from({length: 2}, (_, i) => <div
                        key={`business_${i}`}
                        className="brand-map-locations_map_list_item react-loading-skeleton"
                    />)}
                </div>
                : <div className="brand-map-locations_map_list" ref={scrollContainerRef}>
                    {restaurantsToRender?.length > 0
                        ? <>
                            {restaurantsToRender.map(renderBusiness)}
                            <FramePaginator
                                {...restaurantResults?.paginationInfo}
                                {...omit(frontendPagination, "frontendRenderKey")}
                                onPaginationChange={onPaginationChange}
                                showPaginatorLimit={false}
                                theme={brandMap.colorTheme === BrandMapColorTheme.DARK ? "light" : "dark"}
                            />
                        </> : <div className="brand-map-locations_map_list_empty">
                            <p>No Businesses that match your filter</p>
                        </div>
                    }
                </div>
            }
        </div>
    );
}

export default BrandMapLocationsSearch;
