/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PhoneNumber
 */
export interface PhoneNumber {
    /**
     * 
     * @type {string}
     * @memberof PhoneNumber
     */
    normalized: string;
    /**
     * 
     * @type {string}
     * @memberof PhoneNumber
     */
    countryCode: string;
    /**
     * 
     * @type {string}
     * @memberof PhoneNumber
     */
    nationalNumber: string;
}

export function PhoneNumberFromJSON(json: any): PhoneNumber {
    return PhoneNumberFromJSONTyped(json, false);
}

export function PhoneNumberFromJSONTyped(json: any, ignoreDiscriminator: boolean): PhoneNumber {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'normalized': json['normalized'],
        'countryCode': json['countryCode'],
        'nationalNumber': json['nationalNumber'],
    };
}

export function PhoneNumberToJSON(value?: PhoneNumber | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'normalized': value.normalized,
        'countryCode': value.countryCode,
        'nationalNumber': value.nationalNumber,
    };
}


