import {PropsWithChildren, ReactElement} from "react";
import classNames from "classnames";
import {FrameOneButtonColor} from "./buttons/FrameButton";

interface Props {
    name: string;
    color?: FrameOneButtonColor;
    rounded?: boolean;
    className?: string;
}

function Tag(props: PropsWithChildren<Props>): ReactElement {

    const fullClassNames: string = classNames(
        "tag",
        `frame-one-button_color_${props.color}`,
        props.className,
        props.rounded ? "rounded" : undefined,
    );

    return (
        <div className={fullClassNames}>
            <p>
                {props.name}
            </p>
        </div>
    );
}

export default Tag;
