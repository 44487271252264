/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Address,
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
    Asset,
    AssetFromJSON,
    AssetFromJSONTyped,
    AssetToJSON,
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
    Brand,
    BrandFromJSON,
    BrandFromJSONTyped,
    BrandToJSON,
    BrandMapAdvertisementBlock,
    BrandMapAdvertisementBlockFromJSON,
    BrandMapAdvertisementBlockFromJSONTyped,
    BrandMapAdvertisementBlockToJSON,
    BrandMapBannerImage,
    BrandMapBannerImageFromJSON,
    BrandMapBannerImageFromJSONTyped,
    BrandMapBannerImageToJSON,
    BrandMapColorTheme,
    BrandMapColorThemeFromJSON,
    BrandMapColorThemeFromJSONTyped,
    BrandMapColorThemeToJSON,
    BrandMapHtmlSection,
    BrandMapHtmlSectionFromJSON,
    BrandMapHtmlSectionFromJSONTyped,
    BrandMapHtmlSectionToJSON,
    BrandMapPromoHeader,
    BrandMapPromoHeaderFromJSON,
    BrandMapPromoHeaderFromJSONTyped,
    BrandMapPromoHeaderToJSON,
    BrandMapStatus,
    BrandMapStatusFromJSON,
    BrandMapStatusFromJSONTyped,
    BrandMapStatusToJSON,
    BrandPageType,
    BrandPageTypeFromJSON,
    BrandPageTypeFromJSONTyped,
    BrandPageTypeToJSON,
    EVMChain,
    EVMChainFromJSON,
    EVMChainFromJSONTyped,
    EVMChainToJSON,
    LoginAccessPoint,
    LoginAccessPointFromJSON,
    LoginAccessPointFromJSONTyped,
    LoginAccessPointToJSON,
    Merchant,
    MerchantFromJSON,
    MerchantFromJSONTyped,
    MerchantToJSON,
    OrderDiscount,
    OrderDiscountFromJSON,
    OrderDiscountFromJSONTyped,
    OrderDiscountToJSON,
    TimeStamps,
    TimeStampsFromJSON,
    TimeStampsFromJSONTyped,
    TimeStampsToJSON,
} from './';

/**
 * Represents a map for a brand.
 * @export
 * @interface BrandMap
 */
export interface BrandMap {
    /**
     * 
     * @type {string}
     * @memberof BrandMap
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof BrandMap
     */
    createdAt: number;
    /**
     * 
     * @type {number}
     * @memberof BrandMap
     */
    updatedAt: number;
    /**
     * 
     * @type {string}
     * @memberof BrandMap
     */
    name?: string;
    /**
     * 
     * @type {Brand}
     * @memberof BrandMap
     */
    brand: Brand;
    /**
     * 
     * @type {Merchant}
     * @memberof BrandMap
     */
    merchant?: Merchant;
    /**
     * 
     * @type {string}
     * @memberof BrandMap
     */
    slug?: string;
    /**
     * 
     * @type {Asset}
     * @memberof BrandMap
     */
    titleImage?: Asset;
    /**
     * 
     * @type {Address}
     * @memberof BrandMap
     */
    defaultLocation?: Address;
    /**
     * 
     * @type {BrandMapStatus}
     * @memberof BrandMap
     */
    status: BrandMapStatus;
    /**
     * 
     * @type {Array<string>}
     * @memberof BrandMap
     */
    businesses?: Array<string>;
    /**
     * 
     * @type {OrderDiscount}
     * @memberof BrandMap
     */
    mainPromo?: OrderDiscount;
    /**
     * 
     * @type {Array<OrderDiscount>}
     * @memberof BrandMap
     */
    promos?: Array<OrderDiscount>;
    /**
     * 
     * @type {Asset}
     * @memberof BrandMap
     */
    promoHero?: Asset;
    /**
     * 
     * @type {Asset}
     * @memberof BrandMap
     */
    promoBackground?: Asset;
    /**
     * 
     * @type {Asset}
     * @memberof BrandMap
     */
    promoCartHero?: Asset;
    /**
     * 
     * @type {Asset}
     * @memberof BrandMap
     */
    promoCartBackground?: Asset;
    /**
     * 
     * @type {BrandMapColorTheme}
     * @memberof BrandMap
     */
    colorTheme: BrandMapColorTheme;
    /**
     * 
     * @type {string}
     * @memberof BrandMap
     */
    colorBackground?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandMap
     */
    defaultBusiness?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandMap
     */
    promoTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandMap
     */
    promoDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandMap
     */
    promoTagline?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandMap
     */
    autoMintContract?: string;
    /**
     * 
     * @type {EVMChain}
     * @memberof BrandMap
     */
    autoMintChain?: EVMChain;
    /**
     * 
     * @type {string}
     * @memberof BrandMap
     */
    advertisementTitle?: string;
    /**
     * 
     * @type {Array<BrandMapAdvertisementBlock>}
     * @memberof BrandMap
     */
    advertisementBlocks?: Array<BrandMapAdvertisementBlock>;
    /**
     * 
     * @type {BrandMapBannerImage}
     * @memberof BrandMap
     */
    bannerImage?: BrandMapBannerImage;
    /**
     * 
     * @type {string}
     * @memberof BrandMap
     */
    autoMintToastMessage?: string;
    /**
     * 
     * @type {Asset}
     * @memberof BrandMap
     */
    autoMintToastBackground?: Asset;
    /**
     * 
     * @type {string}
     * @memberof BrandMap
     */
    description?: string;
    /**
     * 
     * @type {BrandMapColorTheme}
     * @memberof BrandMap
     */
    autoMintToastTextColor?: BrandMapColorTheme;
    /**
     * 
     * @type {string}
     * @memberof BrandMap
     */
    preAutoMintToastMessage?: string;
    /**
     * 
     * @type {Asset}
     * @memberof BrandMap
     */
    preAutoMintToastBackground?: Asset;
    /**
     * 
     * @type {LoginAccessPoint}
     * @memberof BrandMap
     */
    loginAccessPoint?: LoginAccessPoint;
    /**
     * 
     * @type {BrandPageType}
     * @memberof BrandMap
     */
    brandPageType?: BrandPageType;
    /**
     * 
     * @type {boolean}
     * @memberof BrandMap
     */
    allowMarketplaceAccess?: boolean;
    /**
     * 
     * @type {BrandMapPromoHeader}
     * @memberof BrandMap
     */
    promoHeader?: BrandMapPromoHeader;
    /**
     * 
     * @type {Array<BrandMapHtmlSection>}
     * @memberof BrandMap
     */
    htmlSectionFirstRow?: Array<BrandMapHtmlSection>;
    /**
     * 
     * @type {Array<BrandMapHtmlSection>}
     * @memberof BrandMap
     */
    htmlSectionSecondRow?: Array<BrandMapHtmlSection>;
}

export function BrandMapFromJSON(json: any): BrandMap {
    return BrandMapFromJSONTyped(json, false);
}

export function BrandMapFromJSONTyped(json: any, ignoreDiscriminator: boolean): BrandMap {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'brand': BrandFromJSON(json['brand']),
        'merchant': !exists(json, 'merchant') ? undefined : MerchantFromJSON(json['merchant']),
        'slug': !exists(json, 'slug') ? undefined : json['slug'],
        'titleImage': !exists(json, 'titleImage') ? undefined : AssetFromJSON(json['titleImage']),
        'defaultLocation': !exists(json, 'defaultLocation') ? undefined : AddressFromJSON(json['defaultLocation']),
        'status': BrandMapStatusFromJSON(json['status']),
        'businesses': !exists(json, 'businesses') ? undefined : json['businesses'],
        'mainPromo': !exists(json, 'mainPromo') ? undefined : OrderDiscountFromJSON(json['mainPromo']),
        'promos': !exists(json, 'promos') ? undefined : ((json['promos'] as Array<any>).map(OrderDiscountFromJSON)),
        'promoHero': !exists(json, 'promoHero') ? undefined : AssetFromJSON(json['promoHero']),
        'promoBackground': !exists(json, 'promoBackground') ? undefined : AssetFromJSON(json['promoBackground']),
        'promoCartHero': !exists(json, 'promoCartHero') ? undefined : AssetFromJSON(json['promoCartHero']),
        'promoCartBackground': !exists(json, 'promoCartBackground') ? undefined : AssetFromJSON(json['promoCartBackground']),
        'colorTheme': BrandMapColorThemeFromJSON(json['colorTheme']),
        'colorBackground': !exists(json, 'colorBackground') ? undefined : json['colorBackground'],
        'defaultBusiness': !exists(json, 'defaultBusiness') ? undefined : json['defaultBusiness'],
        'promoTitle': !exists(json, 'promoTitle') ? undefined : json['promoTitle'],
        'promoDescription': !exists(json, 'promoDescription') ? undefined : json['promoDescription'],
        'promoTagline': !exists(json, 'promoTagline') ? undefined : json['promoTagline'],
        'autoMintContract': !exists(json, 'autoMintContract') ? undefined : json['autoMintContract'],
        'autoMintChain': !exists(json, 'autoMintChain') ? undefined : EVMChainFromJSON(json['autoMintChain']),
        'advertisementTitle': !exists(json, 'advertisementTitle') ? undefined : json['advertisementTitle'],
        'advertisementBlocks': !exists(json, 'advertisementBlocks') ? undefined : ((json['advertisementBlocks'] as Array<any>).map(BrandMapAdvertisementBlockFromJSON)),
        'bannerImage': !exists(json, 'bannerImage') ? undefined : BrandMapBannerImageFromJSON(json['bannerImage']),
        'autoMintToastMessage': !exists(json, 'autoMintToastMessage') ? undefined : json['autoMintToastMessage'],
        'autoMintToastBackground': !exists(json, 'autoMintToastBackground') ? undefined : AssetFromJSON(json['autoMintToastBackground']),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'autoMintToastTextColor': !exists(json, 'autoMintToastTextColor') ? undefined : BrandMapColorThemeFromJSON(json['autoMintToastTextColor']),
        'preAutoMintToastMessage': !exists(json, 'preAutoMintToastMessage') ? undefined : json['preAutoMintToastMessage'],
        'preAutoMintToastBackground': !exists(json, 'preAutoMintToastBackground') ? undefined : AssetFromJSON(json['preAutoMintToastBackground']),
        'loginAccessPoint': !exists(json, 'loginAccessPoint') ? undefined : LoginAccessPointFromJSON(json['loginAccessPoint']),
        'brandPageType': !exists(json, 'brandPageType') ? undefined : BrandPageTypeFromJSON(json['brandPageType']),
        'allowMarketplaceAccess': !exists(json, 'allowMarketplaceAccess') ? undefined : json['allowMarketplaceAccess'],
        'promoHeader': !exists(json, 'promoHeader') ? undefined : BrandMapPromoHeaderFromJSON(json['promoHeader']),
        'htmlSectionFirstRow': !exists(json, 'htmlSectionFirstRow') ? undefined : ((json['htmlSectionFirstRow'] as Array<any>).map(BrandMapHtmlSectionFromJSON)),
        'htmlSectionSecondRow': !exists(json, 'htmlSectionSecondRow') ? undefined : ((json['htmlSectionSecondRow'] as Array<any>).map(BrandMapHtmlSectionFromJSON)),
    };
}

export function BrandMapToJSON(value?: BrandMap | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'name': value.name,
        'brand': BrandToJSON(value.brand),
        'merchant': MerchantToJSON(value.merchant),
        'slug': value.slug,
        'titleImage': AssetToJSON(value.titleImage),
        'defaultLocation': AddressToJSON(value.defaultLocation),
        'status': BrandMapStatusToJSON(value.status),
        'businesses': value.businesses,
        'mainPromo': OrderDiscountToJSON(value.mainPromo),
        'promos': value.promos === undefined ? undefined : ((value.promos as Array<any>).map(OrderDiscountToJSON)),
        'promoHero': AssetToJSON(value.promoHero),
        'promoBackground': AssetToJSON(value.promoBackground),
        'promoCartHero': AssetToJSON(value.promoCartHero),
        'promoCartBackground': AssetToJSON(value.promoCartBackground),
        'colorTheme': BrandMapColorThemeToJSON(value.colorTheme),
        'colorBackground': value.colorBackground,
        'defaultBusiness': value.defaultBusiness,
        'promoTitle': value.promoTitle,
        'promoDescription': value.promoDescription,
        'promoTagline': value.promoTagline,
        'autoMintContract': value.autoMintContract,
        'autoMintChain': EVMChainToJSON(value.autoMintChain),
        'advertisementTitle': value.advertisementTitle,
        'advertisementBlocks': value.advertisementBlocks === undefined ? undefined : ((value.advertisementBlocks as Array<any>).map(BrandMapAdvertisementBlockToJSON)),
        'bannerImage': BrandMapBannerImageToJSON(value.bannerImage),
        'autoMintToastMessage': value.autoMintToastMessage,
        'autoMintToastBackground': AssetToJSON(value.autoMintToastBackground),
        'description': value.description,
        'autoMintToastTextColor': BrandMapColorThemeToJSON(value.autoMintToastTextColor),
        'preAutoMintToastMessage': value.preAutoMintToastMessage,
        'preAutoMintToastBackground': AssetToJSON(value.preAutoMintToastBackground),
        'loginAccessPoint': LoginAccessPointToJSON(value.loginAccessPoint),
        'brandPageType': BrandPageTypeToJSON(value.brandPageType),
        'allowMarketplaceAccess': value.allowMarketplaceAccess,
        'promoHeader': BrandMapPromoHeaderToJSON(value.promoHeader),
        'htmlSectionFirstRow': value.htmlSectionFirstRow === undefined ? undefined : ((value.htmlSectionFirstRow as Array<any>).map(BrandMapHtmlSectionToJSON)),
        'htmlSectionSecondRow': value.htmlSectionSecondRow === undefined ? undefined : ((value.htmlSectionSecondRow as Array<any>).map(BrandMapHtmlSectionToJSON)),
    };
}


