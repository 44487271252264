import GameContainer from "./GameContainer";
import {FC, useEffect, useState} from "react";
import {GamesApi, Token} from "@devour/client";
import getConfig from "../../utils/getConfig";
import {addError} from "@/redux/meta/metaActions";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "@/redux/defaultStore";

interface StateProps {
    fullToken: Token;
}
const RallyChamp: FC<RallyChampProps> = (props) => {

    const [
        message,
        setMessage,
    ] = useState<string>();

    useEffect(() => {
        async function listener(event: any) {

            const data = event.data;
            if (!isNaN(data.rank) && data.rank === 1) {
                try {
                    const {message} = await new GamesApi(getConfig(props.fullToken)).rallyChampion({
                        rallyChampionRequestBody: {
                            position: data.rank,
                            track_played: data.track_played,
                        },
                    });
                    setMessage(message);
                } catch (err) {
                    props.dispatch(await addError(err));
                }
            }
        }

        window.addEventListener("message", listener, false);

        return () => window.removeEventListener("message", listener);
    }, [props.fullToken.token]);

    return (
        <GameContainer
            baseUrl={`${import.meta.env.VITE_CDN_URL}/games/rally-champion/index.html`}
            gameId="rally-champion"
            width="960"
            height="540"
            message={message}
        />
    );
};

function connector() {
    return connect((store: IStore): StateProps => {
        return {
            fullToken: store.authStore.fullToken,
        };
    });
}

type RallyChampProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(RallyChamp);