import {useMutation, useQueryClient} from "@tanstack/react-query";
import {ApiError, DeliveryHandoffInstructions, MenuOrdersApi} from "@devour/client";
import getConfig from "@/utils/getConfig";
import {addError} from "@/redux/meta/metaActions";
import {useDispatch} from "react-redux";

export interface UpdateMenuOrderDeliveryNotesFormValues {
    deliveryNotes: string;
    deliveryHandoffInstructions: DeliveryHandoffInstructions,
}

/**
 * Update the menu order's delivery notes
 *
 * @param menuOrderId
 */
export function useUpdateMenuOrderDeliveryNotes(menuOrderId: string) {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (formValues: UpdateMenuOrderDeliveryNotesFormValues) => {

            const newMenuOrder = await new MenuOrdersApi(getConfig()).updateMenuOrder({
                id: menuOrderId,
                createMenuOrderBody: formValues,
            });

            // Update the menuOrder data cache with the new updated menu order
            // No need refetchMenuOrder. This eliminates the need to
            // refetch the menu order from the database, increasing speed
            queryClient.setQueryData([
                "menuOrder",
                menuOrderId,
            ], newMenuOrder);
            return newMenuOrder;
        },
        onError: async (e) => dispatch(await addError(e as ApiError)),
    });
}