import {AffiliateAction} from "./affiliateReducer";
import {IAction} from "../IAction";

export function newReferralCode(referralCode: string): IAction<AffiliateAction.NEW_REFERRAL_CODE, string> {
    return {
        type: AffiliateAction.NEW_REFERRAL_CODE,
        payload: referralCode,
    };
}

export function removeReferralCode(): IAction<AffiliateAction.REMOVE_REFERRAL_CODE, null> {
    return {
        type: AffiliateAction.REMOVE_REFERRAL_CODE,
    };
}

