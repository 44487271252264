/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiError,
    ApiErrorFromJSON,
    ApiErrorToJSON,
    SiteConfiguration,
    SiteConfigurationFromJSON,
    SiteConfigurationToJSON,
    SiteConfigurationBody,
    SiteConfigurationBodyFromJSON,
    SiteConfigurationBodyToJSON,
    SiteConfigurationKey,
    SiteConfigurationKeyFromJSON,
    SiteConfigurationKeyToJSON,
} from '../models';

export interface GetSiteConfigurationRequest {
    configKey?: SiteConfigurationKey;
}

export interface UpdateSiteConfigurationRequest {
    siteConfigurationBody?: SiteConfigurationBody;
}

/**
 * SiteConfigurationsApi - interface
 * @export
 * @interface SiteConfigurationsApiInterface
 */
export interface SiteConfigurationsApiInterface {
    /**
     * Used to get site configurations.
     * @param {SiteConfigurationKey} [configKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteConfigurationsApiInterface
     */
    getSiteConfigurationRaw(requestParameters: GetSiteConfigurationRequest): Promise<runtime.ApiResponse<SiteConfiguration>>;

    /**
     * Used to get site configurations.
     */
    getSiteConfiguration(requestParameters: GetSiteConfigurationRequest): Promise<SiteConfiguration>;

    /**
     * Used by admins to create / update site configuration.
     * @param {SiteConfigurationBody} [siteConfigurationBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteConfigurationsApiInterface
     */
    updateSiteConfigurationRaw(requestParameters: UpdateSiteConfigurationRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Used by admins to create / update site configuration.
     */
    updateSiteConfiguration(requestParameters: UpdateSiteConfigurationRequest): Promise<void>;

}

/**
 * no description
 */
export class SiteConfigurationsApi extends runtime.BaseAPI implements SiteConfigurationsApiInterface {

    /**
     * Used to get site configurations.
     */
    async getSiteConfigurationRaw(requestParameters: GetSiteConfigurationRequest): Promise<runtime.ApiResponse<SiteConfiguration>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.configKey !== undefined) {
            queryParameters['configKey'] = requestParameters.configKey;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/site-configurations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SiteConfigurationFromJSON(jsonValue));
    }

    /**
     * Used to get site configurations.
     */
    async getSiteConfiguration(requestParameters: GetSiteConfigurationRequest): Promise<SiteConfiguration> {
        const response = await this.getSiteConfigurationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used by admins to create / update site configuration.
     */
    async updateSiteConfigurationRaw(requestParameters: UpdateSiteConfigurationRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/site-configurations`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SiteConfigurationBodyToJSON(requestParameters.siteConfigurationBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Used by admins to create / update site configuration.
     */
    async updateSiteConfiguration(requestParameters: UpdateSiteConfigurationRequest): Promise<void> {
        await this.updateSiteConfigurationRaw(requestParameters);
    }

}
