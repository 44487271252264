import {ReactElement, ReactNode} from "react";
import {OrderDiscountSearchEssential, Restaurant, NftOwnershipInformation} from "@devour/client";
import GoFrensCard from "@/components/goFrens/GoFrensCard";
import {getPromoInfo} from "@/utils/getPromoInfo";
import {useGetUnauthenticatedNftGroupings} from "@/hooks/useGetUnauthenticatedNftGroupings";

interface Props {
    children?: ReactNode;
    width?: number | string;
    restaurant: Restaurant;
    nftOwnershipResponse: NftOwnershipInformation;
}

function sortDiscounts(a: OrderDiscountSearchEssential, b: OrderDiscountSearchEssential): number {
    if (a.priority !== b.priority) {
        // Sort by priority descending
        return b.priority - a.priority;
    } else {
        // Sort by amount descending
        return b.amount - a.amount;
    }
}

function GoFrensRestaurantPromoCard(props: Props): ReactElement {
    const {data: allNftGroupings} = useGetUnauthenticatedNftGroupings(false);

    /**	List of discounts for this restaurant that the current user has access to */
    const nftActiveDiscounts: Array<OrderDiscountSearchEssential> = props.restaurant?.promos?.filter((discount) => {
        return discount.nftGroupings?.find((nftGroupingId) => {
            return props.nftOwnershipResponse?.nftOwnerships?.[0]?.find((ownership) => ownership.nftGroupingId === nftGroupingId);
        });
    }) || [];
    const discountToRender: OrderDiscountSearchEssential = nftActiveDiscounts.sort(sortDiscounts)?.[0];
    const [
        promoType,
        promoTitle,
    ] = getPromoInfo(discountToRender);


    if (!props.restaurant || !discountToRender) {
        return null;
    }

    const discountGroupingIdsSet = new Set(discountToRender.nftGroupings);
    const userOwnerships = props.nftOwnershipResponse?.nftOwnerships[0];
    const nftGroupings = allNftGroupings?.nftGroupings.filter(grouping => discountGroupingIdsSet.has(grouping.id) &&
        userOwnerships?.some(ownership => ownership.nftGroupingId === grouping.id));

    if (!nftGroupings?.length) {
        return null;
    }

    function getThumbnail() {
        if (nftGroupings.length > 1) {
            return import.meta.env.VITE_CDN_URL + "/images/landing-background.webp";
        } else {
            return props.restaurant?.headerImage?.url || import.meta.env.VITE_CDN_URL + "/images/landing-background.webp";
        }
    }

    return (
        <GoFrensCard
            thumbnailUrl={getThumbnail()}
            title={`${discountToRender.label} from ${props.restaurant.name}`}
            subtitles={[
                `Provided by ${nftGroupings.length > 1
                    ? `${nftGroupings.length} communities`
                    : `${nftGroupings[0].name}`}`,
            ]}
            description={discountToRender.description}
            tag={promoTitle}
            tagColour={promoType}
            children={props.children}
            width={props.width}
        />
    );
}

export default GoFrensRestaurantPromoCard;
