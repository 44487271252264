import {Dispatch, ReactElement, SetStateAction, useState} from "react";
import {NftOwnershipInformation, RestaurantSearchResult} from "@devour/client";
import {RestaurantSearchFiltersQuery} from "../../../utils/restaurantsSearchFilter";
import RestaurantSearchNftGroupingBanner from "./RestaurantSearchNftGroupingBanner";
import classNames from "classnames";
import RestaurantSearchNftGroupingBottomPanel from "./RestaurantSearchNftGroupingBottomPanel";
import {getExclusiveItemNfts} from "../../../utils/getExclusiveItemNfts";

interface Props {
    restaurants: Array<RestaurantSearchResult>;
    restaurantFilters: RestaurantSearchFiltersQuery;
    updateRestaurantFilters: Dispatch<SetStateAction<RestaurantSearchFiltersQuery>>;
    nftOwnershipResponse: NftOwnershipInformation;
}

function RestaurantSearchNftGrouping(props: Props): ReactElement {

    const [
        nftGroupingActive,
        setNftGroupingActive,
    ] = useState<boolean>(false);
    const hasOwnedNfts = props.nftOwnershipResponse?.nftOwnerships?.[0]?.length > 0;
    const nftGroupingIdsActive: Array<string> = props.restaurants
        .map((restaurant) => {
            return getExclusiveItemNfts(restaurant);
        }) // Map to `Array<Array<string>>`
        .flat(); // Flatten to `Array<string>`


    if (!hasOwnedNfts || !nftGroupingIdsActive.length) {
        return (
            <div className="restaurants-search-nft-grouping-no-results"/>
        );
    }

    return (
        <div
            className={classNames("restaurants-search-nft-grouping", {
                "is-active": nftGroupingActive,
            })}
        >
            <RestaurantSearchNftGroupingBanner
                restaurants={props.restaurants}
                nftOwnershipResponse={props.nftOwnershipResponse}
                isOpen={nftGroupingActive}
                updateOpenStatus={setNftGroupingActive}
            />
            <RestaurantSearchNftGroupingBottomPanel
                restaurants={props.restaurants}
                nftOwnershipResponse={props.nftOwnershipResponse}
                isOpen={nftGroupingActive}
                updateOpenStatus={setNftGroupingActive}
                restaurantFilters={props.restaurantFilters}
                updateRestaurantFilters={props.updateRestaurantFilters}
            />

        </div>
    );
}

export default RestaurantSearchNftGrouping;
