/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrderDiscountOrigin,
    OrderDiscountOriginFromJSON,
    OrderDiscountOriginFromJSONTyped,
    OrderDiscountOriginToJSON,
    OrderDiscountType,
    OrderDiscountTypeFromJSON,
    OrderDiscountTypeFromJSONTyped,
    OrderDiscountTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface MenuOrderDiscount
 */
export interface MenuOrderDiscount {
    /**
     * 
     * @type {string}
     * @memberof MenuOrderDiscount
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof MenuOrderDiscount
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof MenuOrderDiscount
     */
    code?: string;
    /**
     * 
     * @type {number}
     * @memberof MenuOrderDiscount
     */
    amount: number;
    /**
     * 
     * @type {number}
     * @memberof MenuOrderDiscount
     */
    priority?: number;
    /**
     * 
     * @type {number}
     * @memberof MenuOrderDiscount
     */
    expiresAt?: number;
    /**
     * 
     * @type {OrderDiscountOrigin}
     * @memberof MenuOrderDiscount
     */
    origin: OrderDiscountOrigin;
    /**
     * 
     * @type {OrderDiscountType}
     * @memberof MenuOrderDiscount
     */
    type: OrderDiscountType;
    /**
     * 
     * @type {string}
     * @memberof MenuOrderDiscount
     */
    reference?: string;
}

export function MenuOrderDiscountFromJSON(json: any): MenuOrderDiscount {
    return MenuOrderDiscountFromJSONTyped(json, false);
}

export function MenuOrderDiscountFromJSONTyped(json: any, ignoreDiscriminator: boolean): MenuOrderDiscount {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'label': json['label'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'amount': json['amount'],
        'priority': !exists(json, 'priority') ? undefined : json['priority'],
        'expiresAt': !exists(json, 'expiresAt') ? undefined : json['expiresAt'],
        'origin': OrderDiscountOriginFromJSON(json['origin']),
        'type': OrderDiscountTypeFromJSON(json['type']),
        'reference': !exists(json, 'reference') ? undefined : json['reference'],
    };
}

export function MenuOrderDiscountToJSON(value?: MenuOrderDiscount | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'label': value.label,
        'description': value.description,
        'code': value.code,
        'amount': value.amount,
        'priority': value.priority,
        'expiresAt': value.expiresAt,
        'origin': OrderDiscountOriginToJSON(value.origin),
        'type': OrderDiscountTypeToJSON(value.type),
        'reference': value.reference,
    };
}


