import {ReactElement, ReactNode} from "react";
import {NftOwnershipInformation, OrderDiscount} from "@devour/client";
import GoFrensCard from "@/components/goFrens/GoFrensCard";
import {getPromoInfo} from "@/utils/getPromoInfo";
import {useGetUnauthenticatedNftGroupings} from "@/hooks/useGetUnauthenticatedNftGroupings";

interface Props {
    children?: ReactNode;
    width?: number | string;
    promo: OrderDiscount;
    nftOwnershipResponse: NftOwnershipInformation;
}

function GoFrensGlobalPromoCard(props: Props): ReactElement {
    const {data: allNftGroupings} = useGetUnauthenticatedNftGroupings(false);
    const discountGroupingIdsSet = new Set(props.promo.nftGroupings);
    const userOwnerships = props.nftOwnershipResponse?.nftOwnerships[0];
    const nftGroupings = allNftGroupings?.nftGroupings.filter(grouping => discountGroupingIdsSet.has(grouping.id) &&
        userOwnerships?.some(ownership => ownership.nftGroupingId === grouping.id));

    if (!nftGroupings?.length || !props.promo) {
        return null;
    }

    const [
        promoType,
        promoTitle,
    ] = getPromoInfo(props.promo);

    function getThumbnail() {
        if (nftGroupings.length > 1) {
            return import.meta.env.VITE_CDN_URL + "/images/landing-background.webp";
        } else {
            return nftGroupings[0].hero?.url || import.meta.env.VITE_CDN_URL + "/images/landing-background.webp";
        }
    }

    return (
        <GoFrensCard
            thumbnailUrl={getThumbnail()}
            title={props.promo.label}
            subtitles={[
                `Provided by ${nftGroupings.length > 1
                    ? `${nftGroupings.length} communities`
                    : `${nftGroupings[0].name}`}`,
            ]}
            description={props.promo.description}
            tag={promoTitle}
            tagColour={promoType}
            children={props.children}
            width={props.width}
        />
    );
}

export default GoFrensGlobalPromoCard;
