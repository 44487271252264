/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
    TimeStamps,
    TimeStampsFromJSON,
    TimeStampsFromJSONTyped,
    TimeStampsToJSON,
} from './';

/**
 * Represents a NFT Community to keep track of total GoVIP points for a community on GoFrens leaderboard.
 * @export
 * @interface NftCommunity
 */
export interface NftCommunity {
    /**
     * 
     * @type {string}
     * @memberof NftCommunity
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof NftCommunity
     */
    createdAt: number;
    /**
     * 
     * @type {number}
     * @memberof NftCommunity
     */
    updatedAt: number;
    /**
     * 
     * @type {string}
     * @memberof NftCommunity
     */
    nftGrouping: string;
    /**
     * 
     * @type {number}
     * @memberof NftCommunity
     */
    totalPoints: number;
    /**
     * 
     * @type {number}
     * @memberof NftCommunity
     */
    monthlyPoints: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof NftCommunity
     */
    bannedUsers: Array<string>;
}

export function NftCommunityFromJSON(json: any): NftCommunity {
    return NftCommunityFromJSONTyped(json, false);
}

export function NftCommunityFromJSONTyped(json: any, ignoreDiscriminator: boolean): NftCommunity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'nftGrouping': json['nftGrouping'],
        'totalPoints': json['totalPoints'],
        'monthlyPoints': json['monthlyPoints'],
        'bannedUsers': json['bannedUsers'],
    };
}

export function NftCommunityToJSON(value?: NftCommunity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'nftGrouping': value.nftGrouping,
        'totalPoints': value.totalPoints,
        'monthlyPoints': value.monthlyPoints,
        'bannedUsers': value.bannedUsers,
    };
}


