/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
    RelationshipType,
    RelationshipTypeFromJSON,
    RelationshipTypeFromJSONTyped,
    RelationshipTypeToJSON,
    TimeStamps,
    TimeStampsFromJSON,
    TimeStampsFromJSONTyped,
    TimeStampsToJSON,
} from './';

/**
 * Each user can be referred to Devour with a referral code; this models that relationship .
 * @export
 * @interface ReferralRelationship
 */
export interface ReferralRelationship {
    /**
     * 
     * @type {string}
     * @memberof ReferralRelationship
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof ReferralRelationship
     */
    createdAt: number;
    /**
     * 
     * @type {number}
     * @memberof ReferralRelationship
     */
    updatedAt: number;
    /**
     * 
     * @type {string}
     * @memberof ReferralRelationship
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof ReferralRelationship
     */
    referredUser: string;
    /**
     * 
     * @type {RelationshipType}
     * @memberof ReferralRelationship
     */
    relationshipType: RelationshipType;
}

export function ReferralRelationshipFromJSON(json: any): ReferralRelationship {
    return ReferralRelationshipFromJSONTyped(json, false);
}

export function ReferralRelationshipFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReferralRelationship {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'code': json['code'],
        'referredUser': json['referredUser'],
        'relationshipType': RelationshipTypeFromJSON(json['relationshipType']),
    };
}

export function ReferralRelationshipToJSON(value?: ReferralRelationship | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'code': value.code,
        'referredUser': value.referredUser,
        'relationshipType': RelationshipTypeToJSON(value.relationshipType),
    };
}


