/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiError,
    ApiErrorFromJSON,
    ApiErrorToJSON,
    CreateSEOBody,
    CreateSEOBodyFromJSON,
    CreateSEOBodyToJSON,
    GetSEOResponse,
    GetSEOResponseFromJSON,
    GetSEOResponseToJSON,
    UpdateSEOBody,
    UpdateSEOBodyFromJSON,
    UpdateSEOBodyToJSON,
} from '../models';

export interface CreateSeoTagsRequest {
    createSEOBody?: CreateSEOBody;
}

export interface GetSeoTagsRequest {
    url: string;
}

export interface UpdateSeoTagsRequest {
    updateSEOBody?: UpdateSEOBody;
}

/**
 * SEOApi - interface
 * @export
 * @interface SEOApiInterface
 */
export interface SEOApiInterface {
    /**
     * Used to create a new entry for a SEO tags for the given url
     * @param {CreateSEOBody} [createSEOBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SEOApiInterface
     */
    createSeoTagsRaw(requestParameters: CreateSeoTagsRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Used to create a new entry for a SEO tags for the given url
     */
    createSeoTags(requestParameters: CreateSeoTagsRequest): Promise<void>;

    /**
     * Used to get a list of all SEO tags for the given url
     * @param {string} url 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SEOApiInterface
     */
    getSeoTagsRaw(requestParameters: GetSeoTagsRequest): Promise<runtime.ApiResponse<GetSEOResponse>>;

    /**
     * Used to get a list of all SEO tags for the given url
     */
    getSeoTags(requestParameters: GetSeoTagsRequest): Promise<GetSEOResponse>;

    /**
     * Used to update the list of SEO tags for the given url.
     * @param {UpdateSEOBody} [updateSEOBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SEOApiInterface
     */
    updateSeoTagsRaw(requestParameters: UpdateSeoTagsRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Used to update the list of SEO tags for the given url.
     */
    updateSeoTags(requestParameters: UpdateSeoTagsRequest): Promise<void>;

}

/**
 * no description
 */
export class SEOApi extends runtime.BaseAPI implements SEOApiInterface {

    /**
     * Used to create a new entry for a SEO tags for the given url
     */
    async createSeoTagsRaw(requestParameters: CreateSeoTagsRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/seo/seo-tags`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateSEOBodyToJSON(requestParameters.createSEOBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Used to create a new entry for a SEO tags for the given url
     */
    async createSeoTags(requestParameters: CreateSeoTagsRequest): Promise<void> {
        await this.createSeoTagsRaw(requestParameters);
    }

    /**
     * Used to get a list of all SEO tags for the given url
     */
    async getSeoTagsRaw(requestParameters: GetSeoTagsRequest): Promise<runtime.ApiResponse<GetSEOResponse>> {
        if (requestParameters.url === null || requestParameters.url === undefined) {
            throw new runtime.RequiredError('url','Required parameter requestParameters.url was null or undefined when calling getSeoTags.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.url !== undefined) {
            queryParameters['url'] = requestParameters.url;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/seo/seo-tags`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSEOResponseFromJSON(jsonValue));
    }

    /**
     * Used to get a list of all SEO tags for the given url
     */
    async getSeoTags(requestParameters: GetSeoTagsRequest): Promise<GetSEOResponse> {
        const response = await this.getSeoTagsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used to update the list of SEO tags for the given url.
     */
    async updateSeoTagsRaw(requestParameters: UpdateSeoTagsRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/seo/seo-tags`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateSEOBodyToJSON(requestParameters.updateSEOBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Used to update the list of SEO tags for the given url.
     */
    async updateSeoTags(requestParameters: UpdateSeoTagsRequest): Promise<void> {
        await this.updateSeoTagsRaw(requestParameters);
    }

}
