/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TopCommunityLeader,
    TopCommunityLeaderFromJSON,
    TopCommunityLeaderFromJSONTyped,
    TopCommunityLeaderToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetCommunityLeadersResponse
 */
export interface GetCommunityLeadersResponse {
    /**
     * 
     * @type {Array<TopCommunityLeader>}
     * @memberof GetCommunityLeadersResponse
     */
    communityLeaders: Array<TopCommunityLeader>;
}

export function GetCommunityLeadersResponseFromJSON(json: any): GetCommunityLeadersResponse {
    return GetCommunityLeadersResponseFromJSONTyped(json, false);
}

export function GetCommunityLeadersResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCommunityLeadersResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'communityLeaders': ((json['communityLeaders'] as Array<any>).map(TopCommunityLeaderFromJSON)),
    };
}

export function GetCommunityLeadersResponseToJSON(value?: GetCommunityLeadersResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'communityLeaders': ((value.communityLeaders as Array<any>).map(TopCommunityLeaderToJSON)),
    };
}


