import {ReactElement} from "react";
import {Link} from "react-router-dom";
import {useGate} from "statsig-react";

interface FooterItem {
    title: string;
    href: string;
}

const socialMediaItems: Array<FooterItem> = [
    {
        title: "Twitter",
        href: "https://twitter.com/GoDevour",
    },
    {
        title: "Telegram",
        href: "https://t.me/godevour",
    },
    {
        title: "Instagram",
        href: "https://www.instagram.com/go_devour/",
    },
    {
        title: "Facebook",
        href: "https://www.facebook.com/GoDevour",
    },
    {
        title: "LinkedIn",
        href: "https://www.linkedin.com/company/godevour/",
    },
    {
        title: "Discord",
        href: "https://discord.com/invite/rfvvuDnPG5",
    },
];

const googlePlayImage = `${import.meta.env.VITE_CDN_URL}/images/devour-footer-googleplay.webp`;
const appleStoreImage = `${import.meta.env.VITE_CDN_URL}/images/devour-footer-applestore.webp`;

function DevourFooter(): ReactElement {
    const {value: dpayStatus} = useGate(import.meta.env.VITE_TOKEN_STATSIG_STATUS);

    const moreDevourItems: Array<FooterItem> = [
        {
            title: "Partner with Us",
            href: "mailto:hello@devourgo.io?subject=Partner With Us",
        },
        ...false ? [
            {
                title: `Buy ${import.meta.env.VITE_TOKEN_NAME} on Flooz`,
                href: `https://flooz.trade/trade/${import.meta.env.VITE_DPAY_TOKEN_ADDRESS_ETHEREUM}?network=eth`,
            },
            {
                title: `Buy ${import.meta.env.VITE_TOKEN_NAME} on Uniswap`,
                href: `https://app.uniswap.org/swap?inputCurrency=ETH&outputCurrency=${import.meta.env.VITE_DPAY_TOKEN_ADDRESS_ETHEREUM}`,
            },
        ] : [],
        {
            title: "Corporate Website",
            href: "https://devour.io/",
        },
        {
            title: "The Industry Pass",
            href: "https://opensea.io/collection/industry-pass",
        },
        ...false ? [
            {
                title: "CoinMarketCap",
                href: "https://coinmarketcap.com/currencies/devour-token-v2/",
            },
        ] : [],
    ];

    function renderFooterItem({title, href}: FooterItem): ReactElement {
        return (
            <div
                className="devour-footer_container_main_items_item"
                key={title}
            >
                <a
                    href={href}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {title}
                </a>
            </div>
        );
    }

    return (
        <footer className="devour-footer">
            <div className="devour-footer_container">
                <div className="devour-footer_container_main">
                    <div className="devour-footer_container_main_more-devour">
                        <div className="devour-footer_container_main_title">More Devour</div>
                        <div className="devour-footer_container_main_items">
                            {moreDevourItems.map(renderFooterItem)}
                        </div>
                    </div>
                    <div className="devour-footer_container_main_social-media">
                        <div className="devour-footer_container_main_title">Social Media</div>
                        <div className="devour-footer_container_main_items">
                            {socialMediaItems.map(renderFooterItem)}
                        </div>
                    </div>
                </div>
                <div className="devour-footer_container_logo-with-downloads">
                    <div className="devour-footer_container_logo-with-downloads_logo">
                        <img
                            src={`${import.meta.env.VITE_CDN_URL}/images/DevourGO_Final-White.webp`}
                            alt="devour-logo"/>
                    </div>
                    <div className="devour-footer_container_logo-with-downloads_downloads">
                        <a className="devour-footer_container_logo-with-downloads_downloads_google-play"
													 href="https://play.google.com/store/apps/details?id=io.devourgo.app">
                            <img src={googlePlayImage} alt="google play"/>
                        </a>
                        <a className="devour-footer_container_logo-with-downloads_downloads_apple-store"
													 href="https://apps.apple.com/us/app/devourgo-food-delivery/id6448696874">
                            <img src={appleStoreImage} alt="google play"/>
                        </a>
                    </div>
                </div>
            </div>

            <div className="devour-footer_disclaimer">
                <div className="devour-footer_disclaimer_text">
										© 2024 DevourGo
                </div>
                <div className="devour-footer_disclaimer_options">
                    <Link
                        to="/privacy-policy"
                        rel="noopener noreferrer"
                    >
												Privacy Policy
                    </Link>
                    <span> | </span>
                    <Link
                        to="/terms-of-use"
                        rel="noopener noreferrer"
                    >
												Terms of Use
                    </Link>
                    <span> | </span>
                    <Link
                        to="/cookie-policy"
                        rel="noopener noreferrer"
                    >
												Cookie Policy
                    </Link>
                </div>
            </div>
        </footer>
    );
}

export default DevourFooter;