/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetNftGroupingsResponseAllOf,
    GetNftGroupingsResponseAllOfFromJSON,
    GetNftGroupingsResponseAllOfFromJSONTyped,
    GetNftGroupingsResponseAllOfToJSON,
    NftGrouping,
    NftGroupingFromJSON,
    NftGroupingFromJSONTyped,
    NftGroupingToJSON,
    PaginatedResponse,
    PaginatedResponseFromJSON,
    PaginatedResponseFromJSONTyped,
    PaginatedResponseToJSON,
    PaginationInfo,
    PaginationInfoFromJSON,
    PaginationInfoFromJSONTyped,
    PaginationInfoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetNftGroupingsResponse
 */
export interface GetNftGroupingsResponse extends PaginatedResponse {
    /**
     * 
     * @type {Array<NftGrouping>}
     * @memberof GetNftGroupingsResponse
     */
    nftGroupings: Array<NftGrouping>;
}

export function GetNftGroupingsResponseFromJSON(json: any): GetNftGroupingsResponse {
    return GetNftGroupingsResponseFromJSONTyped(json, false);
}

export function GetNftGroupingsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetNftGroupingsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...PaginatedResponseFromJSONTyped(json, ignoreDiscriminator),
        'nftGroupings': ((json['nftGroupings'] as Array<any>).map(NftGroupingFromJSON)),
    };
}

export function GetNftGroupingsResponseToJSON(value?: GetNftGroupingsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...PaginatedResponseToJSON(value),
        'nftGroupings': ((value.nftGroupings as Array<any>).map(NftGroupingToJSON)),
    };
}


