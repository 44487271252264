/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum BrandMapColorTheme {
    LIGHT = 'LIGHT',
    DARK = 'DARK'
}

export function BrandMapColorThemeFromJSON(json: any): BrandMapColorTheme {
    return BrandMapColorThemeFromJSONTyped(json, false);
}

export function BrandMapColorThemeFromJSONTyped(json: any, ignoreDiscriminator: boolean): BrandMapColorTheme {
    return json as BrandMapColorTheme;
}

export function BrandMapColorThemeToJSON(value?: BrandMapColorTheme | null): any {
    return value as any;
}

