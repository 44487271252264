/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiError,
    ApiErrorFromJSON,
    ApiErrorToJSON,
    GetAdminCommunityInfoResponse,
    GetAdminCommunityInfoResponseFromJSON,
    GetAdminCommunityInfoResponseToJSON,
    GetCommunityLeadersResponse,
    GetCommunityLeadersResponseFromJSON,
    GetCommunityLeadersResponseToJSON,
    GetCommunityRankingDataResponse,
    GetCommunityRankingDataResponseFromJSON,
    GetCommunityRankingDataResponseToJSON,
    GetCurrentTopLeaderboardRankingsResponse,
    GetCurrentTopLeaderboardRankingsResponseFromJSON,
    GetCurrentTopLeaderboardRankingsResponseToJSON,
    GetLeaderboardsDateRangeResponse,
    GetLeaderboardsDateRangeResponseFromJSON,
    GetLeaderboardsDateRangeResponseToJSON,
    GetMonthlyTopMembersLeaderboardResponse,
    GetMonthlyTopMembersLeaderboardResponseFromJSON,
    GetMonthlyTopMembersLeaderboardResponseToJSON,
    GetNftCommunityFromGroupingResponse,
    GetNftCommunityFromGroupingResponseFromJSON,
    GetNftCommunityFromGroupingResponseToJSON,
    GetUserCommunityInfoResponse,
    GetUserCommunityInfoResponseFromJSON,
    GetUserCommunityInfoResponseToJSON,
    GetUsersBannedFromCommunityResponse,
    GetUsersBannedFromCommunityResponseFromJSON,
    GetUsersBannedFromCommunityResponseToJSON,
} from '../models';

export interface AddUserToCommunityRequest {
    id: string;
}

export interface GetAdminCommunityInfoRequest {
    id: string;
}

export interface GetAdminCommunityRankingDataRequest {
    id: string;
}

export interface GetCommunityLeadersRankingsRequest {
    leaderboardTimeParam: string;
}

export interface GetCurrentTopLeaderboardRankingsRequest {
    leaderboardTimeParam: string;
}

export interface GetMonthlyTopMembersLeaderboardRequest {
    leaderboardTimeParam: string;
}

export interface GetNftCommunityFromGroupingRequest {
    id: string;
}

export interface GetUsersBannedFromCommunityRequest {
    id: string;
}

/**
 * GoFrensApi - interface
 * @export
 * @interface GoFrensApiInterface
 */
export interface GoFrensApiInterface {
    /**
     * Adds a user to an NFT community
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoFrensApiInterface
     */
    addUserToCommunityRaw(requestParameters: AddUserToCommunityRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Adds a user to an NFT community
     */
    addUserToCommunity(requestParameters: AddUserToCommunityRequest): Promise<void>;

    /**
     * Gets community info for the grouping ID provided in the request.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoFrensApiInterface
     */
    getAdminCommunityInfoRaw(requestParameters: GetAdminCommunityInfoRequest): Promise<runtime.ApiResponse<GetAdminCommunityInfoResponse>>;

    /**
     * Gets community info for the grouping ID provided in the request.
     */
    getAdminCommunityInfo(requestParameters: GetAdminCommunityInfoRequest): Promise<GetAdminCommunityInfoResponse>;

    /**
     * Gets community ranking data for the grouping ID provided in the request.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoFrensApiInterface
     */
    getAdminCommunityRankingDataRaw(requestParameters: GetAdminCommunityRankingDataRequest): Promise<runtime.ApiResponse<GetCommunityRankingDataResponse>>;

    /**
     * Gets community ranking data for the grouping ID provided in the request.
     */
    getAdminCommunityRankingData(requestParameters: GetAdminCommunityRankingDataRequest): Promise<GetCommunityRankingDataResponse>;

    /**
     * Gets all the NFT community leaders, sorted by total exp
     * @param {string} leaderboardTimeParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoFrensApiInterface
     */
    getCommunityLeadersRankingsRaw(requestParameters: GetCommunityLeadersRankingsRequest): Promise<runtime.ApiResponse<GetCommunityLeadersResponse>>;

    /**
     * Gets all the NFT community leaders, sorted by total exp
     */
    getCommunityLeadersRankings(requestParameters: GetCommunityLeadersRankingsRequest): Promise<GetCommunityLeadersResponse>;

    /**
     * Gets all the NFT community rankings, sorted by total exp
     * @param {string} leaderboardTimeParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoFrensApiInterface
     */
    getCurrentTopLeaderboardRankingsRaw(requestParameters: GetCurrentTopLeaderboardRankingsRequest): Promise<runtime.ApiResponse<GetCurrentTopLeaderboardRankingsResponse>>;

    /**
     * Gets all the NFT community rankings, sorted by total exp
     */
    getCurrentTopLeaderboardRankings(requestParameters: GetCurrentTopLeaderboardRankingsRequest): Promise<GetCurrentTopLeaderboardRankingsResponse>;

    /**
     * Gets a single Nft Grouping with all of its associated Nft trackers.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoFrensApiInterface
     */
    getLeaderboardsDateRangeRaw(): Promise<runtime.ApiResponse<GetLeaderboardsDateRangeResponse>>;

    /**
     * Gets a single Nft Grouping with all of its associated Nft trackers.
     */
    getLeaderboardsDateRange(): Promise<GetLeaderboardsDateRangeResponse>;

    /**
     * Gets top 50 contributors and their points contributed to the community up to end of the month requested.
     * @param {string} leaderboardTimeParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoFrensApiInterface
     */
    getMonthlyTopMembersLeaderboardRaw(requestParameters: GetMonthlyTopMembersLeaderboardRequest): Promise<runtime.ApiResponse<GetMonthlyTopMembersLeaderboardResponse>>;

    /**
     * Gets top 50 contributors and their points contributed to the community up to end of the month requested.
     */
    getMonthlyTopMembersLeaderboard(requestParameters: GetMonthlyTopMembersLeaderboardRequest): Promise<GetMonthlyTopMembersLeaderboardResponse>;

    /**
     * Gets NFT community given an NFT grouping id.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoFrensApiInterface
     */
    getNftCommunityFromGroupingRaw(requestParameters: GetNftCommunityFromGroupingRequest): Promise<runtime.ApiResponse<GetNftCommunityFromGroupingResponse>>;

    /**
     * Gets NFT community given an NFT grouping id.
     */
    getNftCommunityFromGrouping(requestParameters: GetNftCommunityFromGroupingRequest): Promise<GetNftCommunityFromGroupingResponse>;

    /**
     * Gets a single Nft Grouping with all of its associated Nft trackers.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoFrensApiInterface
     */
    getUserCommunityInfoRaw(): Promise<runtime.ApiResponse<GetUserCommunityInfoResponse>>;

    /**
     * Gets a single Nft Grouping with all of its associated Nft trackers.
     */
    getUserCommunityInfo(): Promise<GetUserCommunityInfoResponse>;

    /**
     * Gets all the users banned from the NFT community tied to the NFT grouping ID passed in the param.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoFrensApiInterface
     */
    getUsersBannedFromCommunityRaw(requestParameters: GetUsersBannedFromCommunityRequest): Promise<runtime.ApiResponse<GetUsersBannedFromCommunityResponse>>;

    /**
     * Gets all the users banned from the NFT community tied to the NFT grouping ID passed in the param.
     */
    getUsersBannedFromCommunity(requestParameters: GetUsersBannedFromCommunityRequest): Promise<GetUsersBannedFromCommunityResponse>;

    /**
     * Removes a user from their current community
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoFrensApiInterface
     */
    removeUserFromCommunityRaw(): Promise<runtime.ApiResponse<void>>;

    /**
     * Removes a user from their current community
     */
    removeUserFromCommunity(): Promise<void>;

}

/**
 * no description
 */
export class GoFrensApi extends runtime.BaseAPI implements GoFrensApiInterface {

    /**
     * Adds a user to an NFT community
     */
    async addUserToCommunityRaw(requestParameters: AddUserToCommunityRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling addUserToCommunity.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/add-user-to-community/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Adds a user to an NFT community
     */
    async addUserToCommunity(requestParameters: AddUserToCommunityRequest): Promise<void> {
        await this.addUserToCommunityRaw(requestParameters);
    }

    /**
     * Gets community info for the grouping ID provided in the request.
     */
    async getAdminCommunityInfoRaw(requestParameters: GetAdminCommunityInfoRequest): Promise<runtime.ApiResponse<GetAdminCommunityInfoResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getAdminCommunityInfo.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/get-admin-community-info/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAdminCommunityInfoResponseFromJSON(jsonValue));
    }

    /**
     * Gets community info for the grouping ID provided in the request.
     */
    async getAdminCommunityInfo(requestParameters: GetAdminCommunityInfoRequest): Promise<GetAdminCommunityInfoResponse> {
        const response = await this.getAdminCommunityInfoRaw(requestParameters);
        return await response.value();
    }

    /**
     * Gets community ranking data for the grouping ID provided in the request.
     */
    async getAdminCommunityRankingDataRaw(requestParameters: GetAdminCommunityRankingDataRequest): Promise<runtime.ApiResponse<GetCommunityRankingDataResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getAdminCommunityRankingData.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/get-admin-community-ranking-data/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCommunityRankingDataResponseFromJSON(jsonValue));
    }

    /**
     * Gets community ranking data for the grouping ID provided in the request.
     */
    async getAdminCommunityRankingData(requestParameters: GetAdminCommunityRankingDataRequest): Promise<GetCommunityRankingDataResponse> {
        const response = await this.getAdminCommunityRankingDataRaw(requestParameters);
        return await response.value();
    }

    /**
     * Gets all the NFT community leaders, sorted by total exp
     */
    async getCommunityLeadersRankingsRaw(requestParameters: GetCommunityLeadersRankingsRequest): Promise<runtime.ApiResponse<GetCommunityLeadersResponse>> {
        if (requestParameters.leaderboardTimeParam === null || requestParameters.leaderboardTimeParam === undefined) {
            throw new runtime.RequiredError('leaderboardTimeParam','Required parameter requestParameters.leaderboardTimeParam was null or undefined when calling getCommunityLeadersRankings.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/get-community-leaders/{leaderboardTimeParam}`.replace(`{${"leaderboardTimeParam"}}`, encodeURIComponent(String(requestParameters.leaderboardTimeParam))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCommunityLeadersResponseFromJSON(jsonValue));
    }

    /**
     * Gets all the NFT community leaders, sorted by total exp
     */
    async getCommunityLeadersRankings(requestParameters: GetCommunityLeadersRankingsRequest): Promise<GetCommunityLeadersResponse> {
        const response = await this.getCommunityLeadersRankingsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Gets all the NFT community rankings, sorted by total exp
     */
    async getCurrentTopLeaderboardRankingsRaw(requestParameters: GetCurrentTopLeaderboardRankingsRequest): Promise<runtime.ApiResponse<GetCurrentTopLeaderboardRankingsResponse>> {
        if (requestParameters.leaderboardTimeParam === null || requestParameters.leaderboardTimeParam === undefined) {
            throw new runtime.RequiredError('leaderboardTimeParam','Required parameter requestParameters.leaderboardTimeParam was null or undefined when calling getCurrentTopLeaderboardRankings.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/get-current-top-leaderboard-rankings/{leaderboardTimeParam}`.replace(`{${"leaderboardTimeParam"}}`, encodeURIComponent(String(requestParameters.leaderboardTimeParam))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCurrentTopLeaderboardRankingsResponseFromJSON(jsonValue));
    }

    /**
     * Gets all the NFT community rankings, sorted by total exp
     */
    async getCurrentTopLeaderboardRankings(requestParameters: GetCurrentTopLeaderboardRankingsRequest): Promise<GetCurrentTopLeaderboardRankingsResponse> {
        const response = await this.getCurrentTopLeaderboardRankingsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Gets a single Nft Grouping with all of its associated Nft trackers.
     */
    async getLeaderboardsDateRangeRaw(): Promise<runtime.ApiResponse<GetLeaderboardsDateRangeResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/get-leaderboards-date-range`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetLeaderboardsDateRangeResponseFromJSON(jsonValue));
    }

    /**
     * Gets a single Nft Grouping with all of its associated Nft trackers.
     */
    async getLeaderboardsDateRange(): Promise<GetLeaderboardsDateRangeResponse> {
        const response = await this.getLeaderboardsDateRangeRaw();
        return await response.value();
    }

    /**
     * Gets top 50 contributors and their points contributed to the community up to end of the month requested.
     */
    async getMonthlyTopMembersLeaderboardRaw(requestParameters: GetMonthlyTopMembersLeaderboardRequest): Promise<runtime.ApiResponse<GetMonthlyTopMembersLeaderboardResponse>> {
        if (requestParameters.leaderboardTimeParam === null || requestParameters.leaderboardTimeParam === undefined) {
            throw new runtime.RequiredError('leaderboardTimeParam','Required parameter requestParameters.leaderboardTimeParam was null or undefined when calling getMonthlyTopMembersLeaderboard.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/get-monthly-top-members-leaderboard/{leaderboardTimeParam}`.replace(`{${"leaderboardTimeParam"}}`, encodeURIComponent(String(requestParameters.leaderboardTimeParam))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetMonthlyTopMembersLeaderboardResponseFromJSON(jsonValue));
    }

    /**
     * Gets top 50 contributors and their points contributed to the community up to end of the month requested.
     */
    async getMonthlyTopMembersLeaderboard(requestParameters: GetMonthlyTopMembersLeaderboardRequest): Promise<GetMonthlyTopMembersLeaderboardResponse> {
        const response = await this.getMonthlyTopMembersLeaderboardRaw(requestParameters);
        return await response.value();
    }

    /**
     * Gets NFT community given an NFT grouping id.
     */
    async getNftCommunityFromGroupingRaw(requestParameters: GetNftCommunityFromGroupingRequest): Promise<runtime.ApiResponse<GetNftCommunityFromGroupingResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getNftCommunityFromGrouping.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/get-nft-community-from-grouping/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetNftCommunityFromGroupingResponseFromJSON(jsonValue));
    }

    /**
     * Gets NFT community given an NFT grouping id.
     */
    async getNftCommunityFromGrouping(requestParameters: GetNftCommunityFromGroupingRequest): Promise<GetNftCommunityFromGroupingResponse> {
        const response = await this.getNftCommunityFromGroupingRaw(requestParameters);
        return await response.value();
    }

    /**
     * Gets a single Nft Grouping with all of its associated Nft trackers.
     */
    async getUserCommunityInfoRaw(): Promise<runtime.ApiResponse<GetUserCommunityInfoResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/get-user-community-info`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUserCommunityInfoResponseFromJSON(jsonValue));
    }

    /**
     * Gets a single Nft Grouping with all of its associated Nft trackers.
     */
    async getUserCommunityInfo(): Promise<GetUserCommunityInfoResponse> {
        const response = await this.getUserCommunityInfoRaw();
        return await response.value();
    }

    /**
     * Gets all the users banned from the NFT community tied to the NFT grouping ID passed in the param.
     */
    async getUsersBannedFromCommunityRaw(requestParameters: GetUsersBannedFromCommunityRequest): Promise<runtime.ApiResponse<GetUsersBannedFromCommunityResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getUsersBannedFromCommunity.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/get-users-banned-from-community/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUsersBannedFromCommunityResponseFromJSON(jsonValue));
    }

    /**
     * Gets all the users banned from the NFT community tied to the NFT grouping ID passed in the param.
     */
    async getUsersBannedFromCommunity(requestParameters: GetUsersBannedFromCommunityRequest): Promise<GetUsersBannedFromCommunityResponse> {
        const response = await this.getUsersBannedFromCommunityRaw(requestParameters);
        return await response.value();
    }

    /**
     * Removes a user from their current community
     */
    async removeUserFromCommunityRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/remove-user-from-community`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Removes a user from their current community
     */
    async removeUserFromCommunity(): Promise<void> {
        await this.removeUserFromCommunityRaw();
    }

}
