import {ReactElement, useEffect, useState} from "react";
import {
    SiteConfigurationsApi,
    NftOwnershipInformation,
    SiteConfiguration,
    SiteConfigurationKey,
} from "@devour/client";
import {MdCheckCircle} from "react-icons/md";

interface Props {
    nftOwnershipResponse?: NftOwnershipInformation;
}

function IndustryCollectionBanner(props: Props): ReactElement {
    const [
        siteConfiguration,
        setSiteConfiguration,
    ] = useState<SiteConfiguration>(undefined);

    useEffect(() => {
        void getSiteConfigurationApi();
    }, [props.nftOwnershipResponse]);

    async function getSiteConfigurationApi(): Promise<void> {
        if (!props.nftOwnershipResponse?.nftOwnerships?.[0]) {
            return;
        }

        try {
            const res = await new SiteConfigurationsApi().getSiteConfiguration({
                configKey: SiteConfigurationKey.INDUSTRYPROMOBANNER,
            });

            setSiteConfiguration(res);
        } catch (e) {
            // Configuration might not exist yet.
            // props.dispatch(await addError(e));
        }
    }

    if (!props.nftOwnershipResponse?.nftOwnerships?.[0] || !siteConfiguration?.nftGroupings) {
        return null;
    }
    const userOwnedGroupingIds: Array<string> = props.nftOwnershipResponse?.nftOwnerships?.[0]?.map(g => g.nftGroupingId);
    const ownedNftMatches = siteConfiguration?.nftGroupings?.filter(groupingId => userOwnedGroupingIds?.includes(groupingId));

    if (!ownedNftMatches.length) {
        return null;
    }

    return (
        <div className="industry-collection-banner">
            <div className="industry-collection-banner_icon">
                <MdCheckCircle/>
            </div>
            <div className="industry-collection-banner_content">
                <div className="industry-collection-banner_title">
                    {siteConfiguration.title}
                </div>
                <div className="industry-collection-banner_description">
                    {siteConfiguration.description}
                </div>
            </div>
        </div>
    );
}

export default IndustryCollectionBanner;
