import {ReactElement} from "react";
import FrameOneAutoPanel from "@/components/modals/autoPanelComponents/FrameOneAutoPanel";
import FrameAutoPanelHeader from "@/components/modals/autoPanelComponents/FrameAutoPanelHeader";
import FrameAutoPanelBody from "@/components/modals/autoPanelComponents/FrameAutoPanelBody";
import FrameAutoPanelFooter from "@/components/modals/autoPanelComponents/FrameAutoPanelFooter";
import {removeMenuOrderError} from "@/redux/meta/metaActions";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import FrameButton from "@/components/buttons/FrameButton";
import {useUpdateMenuOrderItems} from "@/hooks/menuOrder/useUpdateMenuOrderItems";
import {useMenuOrder} from "@/hooks/menuOrder/useMenuOrder";

function MenuOrderErrorModal(): ReactElement {
    const dispatch = useDispatch();
    const menuOrders = useSelector((store: IStore) => store.metaStore.menuOrders);
    const menuOrderError = useSelector((store: IStore) => store.metaStore.menuOrderError);
    const existingMenuOrderId = menuOrders
        ? menuOrders[menuOrderError.restaurantId]
        : undefined;
    const {data: menuOrder} = useMenuOrder(existingMenuOrderId);
    const {
        mutateAsync: updateMenuOrderItems,
    } = useUpdateMenuOrderItems({
        menuOrder: menuOrder,
        menuOrderErrorModal: true,
    });

    async function onClearCart() {
        await updateMenuOrderItems({
            orderItems: [],
        });

        onCloseModal();
    }

    function onCloseModal() {
        dispatch(removeMenuOrderError());
    }

    return (
        <FrameOneAutoPanel
            contentClassName="menu-order-error-modal"
            isOpen={Boolean(menuOrderError?.errorMessage)}
            toggle={onCloseModal}
            size="xs"
        >
            <FrameAutoPanelHeader
                title="Cart Error"
                toggle={onCloseModal}
            />
            <FrameAutoPanelBody>
                <p>
                    {menuOrderError?.errorMessage}
                </p>
            </FrameAutoPanelBody>
            <FrameAutoPanelFooter>
                <FrameButton
                    color="danger"
                    size="normal"
                    onClick={onClearCart}
                >
										Clear Cart
                </FrameButton>

                <FrameButton
                    color="purple"
                    size="normal"
                    onClick={onCloseModal}
                >
										Dismiss
                </FrameButton>
            </FrameAutoPanelFooter>

        </FrameOneAutoPanel>
    );
}

export default MenuOrderErrorModal;
