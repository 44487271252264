import {ButtonHTMLAttributes, Dispatch, ReactElement, SetStateAction} from "react";
import FrameButton from "@/components/buttons/FrameButton";
import {GoFrensTutorialSteps} from "@/components/modals/GoFrensTutorialModal/GoFrensTutorialSteps";

interface Props {
    setTutorialStep: Dispatch<SetStateAction<GoFrensTutorialSteps>>;
}

function GoFrensTutorialModalInitial(props: Props): ReactElement {

    return (
        <div className="gofrens-tutorial-modal-initial">
            <div className="gofrens-tutorial-modal-initial_subtitle">
				Welcome To
            </div>
            <div className="gofrens-tutorial-modal-initial_title">
				GoFriends
            </div>
            <p className="gofrens-tutorial-modal-initial_description">
				Show your community spirit and unleash epic rewards on DevourGO.
				Let the games begin!
            </p>

            <FrameButton
                <ButtonHTMLAttributes<HTMLButtonElement>>
                color="purple-outline"
                size="large"
                onClick={() => props.setTutorialStep(GoFrensTutorialSteps.NAB_NFT)}
            >
				Explore Now!
            </FrameButton>

        </div>
    );
}

export default GoFrensTutorialModalInitial;
