/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ImportUserBodyItem
 */
export interface ImportUserBodyItem {
    /**
     * 
     * @type {string}
     * @memberof ImportUserBodyItem
     */
    devour_user_id?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportUserBodyItem
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportUserBodyItem
     */
    phone_number?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportUserBodyItem
     */
    first_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportUserBodyItem
     */
    last_name?: string;
}

export function ImportUserBodyItemFromJSON(json: any): ImportUserBodyItem {
    return ImportUserBodyItemFromJSONTyped(json, false);
}

export function ImportUserBodyItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImportUserBodyItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'devour_user_id': !exists(json, 'devour_user_id') ? undefined : json['devour_user_id'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phone_number': !exists(json, 'phone_number') ? undefined : json['phone_number'],
        'first_name': !exists(json, 'first_name') ? undefined : json['first_name'],
        'last_name': !exists(json, 'last_name') ? undefined : json['last_name'],
    };
}

export function ImportUserBodyItemToJSON(value?: ImportUserBodyItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'devour_user_id': value.devour_user_id,
        'email': value.email,
        'phone_number': value.phone_number,
        'first_name': value.first_name,
        'last_name': value.last_name,
    };
}


