/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetNftPurchasesResponseAllOf,
    GetNftPurchasesResponseAllOfFromJSON,
    GetNftPurchasesResponseAllOfFromJSONTyped,
    GetNftPurchasesResponseAllOfToJSON,
    NftPurchase,
    NftPurchaseFromJSON,
    NftPurchaseFromJSONTyped,
    NftPurchaseToJSON,
    PaginatedResponse,
    PaginatedResponseFromJSON,
    PaginatedResponseFromJSONTyped,
    PaginatedResponseToJSON,
    PaginationInfo,
    PaginationInfoFromJSON,
    PaginationInfoFromJSONTyped,
    PaginationInfoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetNftPurchasesResponse
 */
export interface GetNftPurchasesResponse extends PaginatedResponse {
    /**
     * 
     * @type {Array<NftPurchase>}
     * @memberof GetNftPurchasesResponse
     */
    nftPurchases: Array<NftPurchase>;
}

export function GetNftPurchasesResponseFromJSON(json: any): GetNftPurchasesResponse {
    return GetNftPurchasesResponseFromJSONTyped(json, false);
}

export function GetNftPurchasesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetNftPurchasesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...PaginatedResponseFromJSONTyped(json, ignoreDiscriminator),
        'nftPurchases': ((json['nftPurchases'] as Array<any>).map(NftPurchaseFromJSON)),
    };
}

export function GetNftPurchasesResponseToJSON(value?: GetNftPurchasesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...PaginatedResponseToJSON(value),
        'nftPurchases': ((value.nftPurchases as Array<any>).map(NftPurchaseToJSON)),
    };
}


