/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ImportUserBodyItem,
    ImportUserBodyItemFromJSON,
    ImportUserBodyItemFromJSONTyped,
    ImportUserBodyItemToJSON,
} from './';

/**
 * 
 * @export
 * @interface ImportUserBody
 */
export interface ImportUserBody {
    /**
     * 
     * @type {Array<ImportUserBodyItem>}
     * @memberof ImportUserBody
     */
    data?: Array<ImportUserBodyItem>;
}

export function ImportUserBodyFromJSON(json: any): ImportUserBody {
    return ImportUserBodyFromJSONTyped(json, false);
}

export function ImportUserBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImportUserBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(ImportUserBodyItemFromJSON)),
    };
}

export function ImportUserBodyToJSON(value?: ImportUserBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(ImportUserBodyItemToJSON)),
    };
}


