import {useQuery} from "@tanstack/react-query";
import {
    GetNftGroupingsResponse,
    NftsApi,
} from "@devour/client";
import getConfig from "@/utils/getConfig";

async function fetchUnauthenticatedNftGroupings(onlyShowEligible: boolean): Promise<GetNftGroupingsResponse> {
    return await new NftsApi(getConfig()).getNftGroupings({
        ...onlyShowEligible
            ? { onlyShowEligible: "true" }
            : {},
        limit: Number.MAX_SAFE_INTEGER,
    });
}

function unauthenticatedNftGroupingsQuery(onlyShowEligible: boolean) {
    return {
        queryKey: [
            "unauthenticated-nft-groupings",
            onlyShowEligible,
        ],
        queryFn: () => fetchUnauthenticatedNftGroupings(onlyShowEligible),
        keepPreviousData: true,
        staleTime: 60 * 60000,
    } as const;
}

export function useGetUnauthenticatedNftGroupings(onlyShowEligible: boolean) {
    return useQuery(unauthenticatedNftGroupingsQuery(onlyShowEligible));
}
