import { FC } from "react";

interface props {
    currentStep?: number;
    stepsCount?: number;
    progress?: number;
}

const MenuSuccessTrackerProgressBar: FC<props> = ({ currentStep = 0, stepsCount, progress }) => {


    const renderContent = () => {

        if (Number.isInteger(progress)) {
            return <div className="step progress">
                <div className="progress-bar" style={{ width: `${progress}%` }}></div>
            </div>;
        }

        if (stepsCount > 0) {
            return Array.from({ length: stepsCount }).map((_, index) => {
                return <div key={index} className={`step ${currentStep >= index + 1 ? "active" : ""}`}></div>;
            });
        }
        return <div className="step loading">
            <div className="loading-progress"></div>
        </div>;

    };


    return (
        <div className="menu-order-success-tracker_progress-bar">
            {renderContent()}
        </div>
    );
};

export default MenuSuccessTrackerProgressBar;
