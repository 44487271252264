import {ReactElement, useEffect} from "react";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameButton from "../buttons/FrameButton";
import FrameOneModal from "./modalComponents/FrameOneModal";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../../redux/defaultStore";

interface StateProps {
    email: string;
    fullName?: string;
}

interface Props {
    isOpen: boolean;
    toggle: () => void;
}

function Share2EarnModal(props: Share2EarnModalProps): ReactElement {

    useEffect(() => {
        if (props.isOpen) {
            const script = document.createElement("script");
            script.src = "https://widget.gleamjs.io/e.js";
            script.async = true;

            document.body.appendChild(script);

            return () => {
                document.body.removeChild(script);
            };
        }
    }, [props.isOpen]);

    useEffect(() => {
        (window as any).Gleam = (window as any).Gleam || [];

        if (props.email) {
            (window as any).Gleam.push([
                "email",
                props.email,
            ]);
        }

        if (props.fullName) {
            (window as any).Gleam.push([
                "name",
                props.fullName,
            ]);
        }
    }, [
        props.email,
        props.fullName,
    ]);

    return (
        <FrameOneModal
            isOpen={props.isOpen}
            toggle={props.toggle}
            size="sm"
            contentClassName="share2earn-modal"
        >

            <a
                className="e-widget no-button generic-loader"
                href="https://gleam.io/14YEH/govip-level1-test"
                rel="nofollow"
            >
				GoVIP
            </a>

            <FrameModalBody className="share2earn-modal_body">
                <div className="share2earn-modal_body_btn-container">
                    <FrameButton
                        color="purple"
                        size="normal"
                        onClick={props.toggle}
                    >
						Dismiss
                    </FrameButton>
                </div>
            </FrameModalBody>
        </FrameOneModal>
    );
}

function connector() {
    return connect((store: IStore, props: Props): StateProps & Props => {
        let fullName = "";
        if (store.metaStore.currentUser?.user?.firstName) {
            fullName += store.metaStore.currentUser.user.firstName;
        }

        if (store.metaStore.currentUser?.user?.lastName) {
            if (fullName.length > 0) {
                fullName += " ";
            }
            fullName += store.metaStore.currentUser?.user?.lastName;
        }
        return {
            ...props,
            email: store.metaStore.currentUser?.user?.email,
            fullName: fullName.length > 0
                ? fullName
                : undefined,
        };
    });
}

type Share2EarnModalProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(Share2EarnModal);
