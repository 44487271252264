/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
    BusinessServiceAvailability,
    BusinessServiceAvailabilityFromJSON,
    BusinessServiceAvailabilityFromJSONTyped,
    BusinessServiceAvailabilityToJSON,
    MenuCustomizationItem,
    MenuCustomizationItemFromJSON,
    MenuCustomizationItemFromJSONTyped,
    MenuCustomizationItemToJSON,
    TimeStamps,
    TimeStampsFromJSON,
    TimeStampsFromJSONTyped,
    TimeStampsToJSON,
} from './';

/**
 * A wrapper for customization items available to a single menu item.
 * @export
 * @interface MenuCustomization
 */
export interface MenuCustomization {
    /**
     * 
     * @type {string}
     * @memberof MenuCustomization
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof MenuCustomization
     */
    createdAt: number;
    /**
     * 
     * @type {number}
     * @memberof MenuCustomization
     */
    updatedAt: number;
    /**
     * 
     * @type {string}
     * @memberof MenuCustomization
     */
    menuItem?: string;
    /**
     * 
     * @type {string}
     * @memberof MenuCustomization
     */
    customizationItem?: string;
    /**
     * 
     * @type {string}
     * @memberof MenuCustomization
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof MenuCustomization
     */
    description?: string;
    /**
     * The minimum quantity for sum total from the option group.
     * @type {number}
     * @memberof MenuCustomization
     */
    minAggregate?: number;
    /**
     * The maximum quantity for sum total from the option group.
     * @type {number}
     * @memberof MenuCustomization
     */
    maxAggregate?: number;
    /**
     * The minimum number of different options that must be selected from the option group.
     * @type {number}
     * @memberof MenuCustomization
     */
    minSelects?: number;
    /**
     * The maximum number of different options that can be selected from the option group.
     * @type {number}
     * @memberof MenuCustomization
     */
    maxSelects?: number;
    /**
     * The minimum quantity for a single option selected from the option group.
     * @type {number}
     * @memberof MenuCustomization
     */
    minChoice?: number;
    /**
     * The maximum quantity for a single option selected from the option group.
     * @type {number}
     * @memberof MenuCustomization
     */
    maxChoice?: number;
    /**
     * 
     * @type {number}
     * @memberof MenuCustomization
     */
    quantityIncrement?: number;
    /**
     * 
     * @type {boolean}
     * @memberof MenuCustomization
     */
    multipleQuantities?: boolean;
    /**
     * Sort order for this on the frontend
     * @type {number}
     * @memberof MenuCustomization
     */
    sortOrder?: number;
    /**
     * 
     * @type {Array<BusinessServiceAvailability>}
     * @memberof MenuCustomization
     */
    serviceAvailabilities?: Array<BusinessServiceAvailability>;
    /**
     * 
     * @type {Array<MenuCustomizationItem>}
     * @memberof MenuCustomization
     */
    options: Array<MenuCustomizationItem>;
    /**
     * 
     * @type {number}
     * @memberof MenuCustomization
     */
    oloId?: number;
    /**
     * 
     * @type {number}
     * @memberof MenuCustomization
     */
    oloChainId?: number;
    /**
     * 
     * @type {string}
     * @memberof MenuCustomization
     */
    chowlyId?: string;
    /**
     * 
     * @type {string}
     * @memberof MenuCustomization
     */
    itsaCheckmateId?: string;
}

export function MenuCustomizationFromJSON(json: any): MenuCustomization {
    return MenuCustomizationFromJSONTyped(json, false);
}

export function MenuCustomizationFromJSONTyped(json: any, ignoreDiscriminator: boolean): MenuCustomization {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'menuItem': !exists(json, 'menuItem') ? undefined : json['menuItem'],
        'customizationItem': !exists(json, 'customizationItem') ? undefined : json['customizationItem'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'minAggregate': !exists(json, 'minAggregate') ? undefined : json['minAggregate'],
        'maxAggregate': !exists(json, 'maxAggregate') ? undefined : json['maxAggregate'],
        'minSelects': !exists(json, 'minSelects') ? undefined : json['minSelects'],
        'maxSelects': !exists(json, 'maxSelects') ? undefined : json['maxSelects'],
        'minChoice': !exists(json, 'minChoice') ? undefined : json['minChoice'],
        'maxChoice': !exists(json, 'maxChoice') ? undefined : json['maxChoice'],
        'quantityIncrement': !exists(json, 'quantityIncrement') ? undefined : json['quantityIncrement'],
        'multipleQuantities': !exists(json, 'multipleQuantities') ? undefined : json['multipleQuantities'],
        'sortOrder': !exists(json, 'sortOrder') ? undefined : json['sortOrder'],
        'serviceAvailabilities': !exists(json, 'serviceAvailabilities') ? undefined : ((json['serviceAvailabilities'] as Array<any>).map(BusinessServiceAvailabilityFromJSON)),
        'options': ((json['options'] as Array<any>).map(MenuCustomizationItemFromJSON)),
        'oloId': !exists(json, 'oloId') ? undefined : json['oloId'],
        'oloChainId': !exists(json, 'oloChainId') ? undefined : json['oloChainId'],
        'chowlyId': !exists(json, 'chowlyId') ? undefined : json['chowlyId'],
        'itsaCheckmateId': !exists(json, 'itsaCheckmateId') ? undefined : json['itsaCheckmateId'],
    };
}

export function MenuCustomizationToJSON(value?: MenuCustomization | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'menuItem': value.menuItem,
        'customizationItem': value.customizationItem,
        'name': value.name,
        'description': value.description,
        'minAggregate': value.minAggregate,
        'maxAggregate': value.maxAggregate,
        'minSelects': value.minSelects,
        'maxSelects': value.maxSelects,
        'minChoice': value.minChoice,
        'maxChoice': value.maxChoice,
        'quantityIncrement': value.quantityIncrement,
        'multipleQuantities': value.multipleQuantities,
        'sortOrder': value.sortOrder,
        'serviceAvailabilities': value.serviceAvailabilities === undefined ? undefined : ((value.serviceAvailabilities as Array<any>).map(BusinessServiceAvailabilityToJSON)),
        'options': ((value.options as Array<any>).map(MenuCustomizationItemToJSON)),
        'oloId': value.oloId,
        'oloChainId': value.oloChainId,
        'chowlyId': value.chowlyId,
        'itsaCheckmateId': value.itsaCheckmateId,
    };
}


