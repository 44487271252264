import {ReactElement, ReactNode, useContext, useEffect, useRef, useState} from "react";
import classNames from "classnames";
import {isDesktop, isMobile, isTablet} from "react-device-detect";
import FrameButton from "./buttons/FrameButton";
import {FiArrowLeft, FiArrowRight} from "react-icons/fi";
import {useRestaurantPreviousOrderedItems} from "@/hooks/useRestaurantPrevouslyOrderedItems";
import {validateMenuOrderItem} from "@/utils/validateMenuOrderItem";
import {RestaurantContext} from "@/pages/restaurants/context/RestaurantContext";
import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {MenuCategory} from "@devour/client";
import useWindowSize from "@/hooks/useWindowSize";

interface Props {
    categories: Array<MenuCategory>;
    onSticky: boolean;
}

const orderAgainCategory: MenuCategory = {
    id: "order-again",
    name: "Order Again",
    createdAt: 0,
    updatedAt: 0,
    business: null,
};

function RestaurantMenuCategoriesListJumper(props: Props): ReactElement {
    const {restaurant, embeddedMenu} = useContext(RestaurantContext);
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const queryRestaurantPreviousOrderedItems = useRestaurantPreviousOrderedItems(restaurant.id, fullToken);
    const menuCategoriesRoot = getCategories(props.categories);
    const previousOrderedItemValidated = queryRestaurantPreviousOrderedItems?.data?.orderItems.find((item) => validateMenuOrderItem(item, menuCategoriesRoot));
    const [
        leftOffset,
        setLeftOffset,
    ] = useState({
        left: 0,
        max: true,
        renderKey: 1,
    });
    const [
        currentCategory,
        setCurrentCategory,
    ] = useState(props.categories.sort((a, b) => a.sortOrder - b.sortOrder)[0]);
    const categoryListRef = useRef<HTMLDivElement>(null);
    const containerDiv = document.getElementById("restaurant-map-landing_menu_rah") || window;

    const windowSize = useWindowSize()[0];
    const isWindowMobileView = windowSize < 576;
    const isMobileView = isMobile && !isTablet || isWindowMobileView;

    let stickyCategoriesHeightOffsetMagic: number;

    if (isMobileView) {
        stickyCategoriesHeightOffsetMagic = embeddedMenu ? 230 : 155;
    } else {
        stickyCategoriesHeightOffsetMagic = embeddedMenu ? 1365 : 75;
    }

    /**
     * We run the scroll function once on mount & each time the categories list in props changes just to check the total
     * width of the container, so we know if we need to show the "right" arrow.
     */
    useEffect(() => {
        if (categoryListRef.current) {
            slideCategories(true)();
        }
    }, [JSON.stringify(props.categories)]);

    useEffect(() => {
        categoryListRef.current.scroll({
            left: leftOffset.left,
            behavior: "smooth",
        });
    }, [leftOffset]);

    /**
     * Listen onScroll event on the window so that we know which of the categories to visually "turn on".
     *
     */
    useEffect(() => {
        function checkCurrentCategory(): void {
            let activeCategory: MenuCategory;
            let scrollDistance;
            let visibleContainerHeight;

            const containerDiv = document.getElementById("restaurant-map-landing_menu_rah");

            if (containerDiv) {
                scrollDistance = containerDiv.scrollTop;
                visibleContainerHeight = window.innerHeight - containerDiv.getBoundingClientRect().top;
            } else {
                scrollDistance = window.scrollY;
                visibleContainerHeight = window.innerHeight;
            }

            // Check for the "Order Again" category
            const categoryDiv = document.getElementById("menu-category-order-again");

            // Set iteratee category as the "active" one if window it is ~halfway up the screen or more.
            if (categoryDiv && scrollDistance >= categoryDiv.offsetTop - visibleContainerHeight * 0.6) {
                activeCategory = orderAgainCategory;
            }

            // Iterate over each category to find the one that matches the current scroll position
            props.categories.forEach((category) => {
                const categoryDiv = document.getElementById(`menu-category-${category.id}`);
                let screenFactor: number;

                if (isMobileView) {
                    screenFactor = 0.32;
                } else {
                    // Desktop view on brandmap page is within a container div, requires different screenFactor.
                    screenFactor = embeddedMenu ? 1.65 : 0.15;
                }

                // Set iteratee category as the "active" one if window it is ~halfway up the screen or more.
                if (categoryDiv && scrollDistance >= categoryDiv.offsetTop - visibleContainerHeight * screenFactor) {
                    activeCategory = category;
                }
            });

            // If no category found matching, then we must be on the first one (special logic because it should be the default selected one if user isn't scrolled down the menu at all).
            if (!activeCategory) {
                if (previousOrderedItemValidated) {
                    activeCategory = orderAgainCategory;
                } else {
                    activeCategory = props.categories[0];
                }
            }

            setCurrentCategory(activeCategory);
        }

        containerDiv.addEventListener("scroll", checkCurrentCategory);

        return () => {
            containerDiv.removeEventListener("scroll", checkCurrentCategory);
        };
    }, [previousOrderedItemValidated]);

    /**
     * Scroll the categories section by a certain amount when clicking the arrows.
     * On smaller screens, just scroll by 100px, and larger screens scroll 50% of the visible width of the section.
     * This implementation isn't perfect as it doesn't account for screen resizing to know if we need to show
     * the "right" button; as we try to determine that on initial render.
     *
     * @param left
     */
    function slideCategories(left: boolean): () => void {
        return () => {
            const scrollAmountPercentage = categoryListRef.current?.clientWidth * 0.5;
            let scrollAmountCalculated = scrollAmountPercentage > 100
                ? scrollAmountPercentage
                : 100;

            if (!left && leftOffset.left + scrollAmountCalculated >= categoryListRef?.current?.scrollWidth - categoryListRef?.current?.clientWidth) {
                scrollAmountCalculated = categoryListRef?.current?.scrollWidth - categoryListRef?.current?.clientWidth;
                setLeftOffset({
                    left: scrollAmountCalculated,
                    max: true,
                    renderKey: leftOffset.renderKey + 1,
                });
            } else {
                setLeftOffset({
                    left: categoryListRef.current.scrollLeft + (left
                        ? scrollAmountCalculated * -1
                        : scrollAmountCalculated),
                    max: categoryListRef.current.scrollWidth <= categoryListRef.current.clientWidth,
                    renderKey: leftOffset.renderKey + 1,
                });
            }
        };
    }

    /**
     * Scroll to specified category on click / tap
     *
     * @param category
     */
    function scrollToCategory(category: MenuCategory): () => void {
        return () => {
            setCurrentCategory(category);
            const divToScrollTo = document.getElementById(`menu-category-${category.id}`);
            if (divToScrollTo && containerDiv) {
                containerDiv.scrollTo({
                    behavior: "smooth",
                    top: divToScrollTo.offsetTop - stickyCategoriesHeightOffsetMagic, // Because if we scroll directly to the div, the title will be covered by the sticky category selection; so scroll to just a little before it.
                });
            }
        };
    }

    function renderCategory(category: MenuCategory, i: number): ReactNode {
        return (
            <div
                key={`jumper-button_${i}`}
                className={classNames("category-jumper_list_cat", {
                    "category-jumper_list_cat-active": category.id === currentCategory?.id,
                })}
                onClick={scrollToCategory(category)}
            >
                <span>
                    {category?.name}
                </span>
            </div>
        );
    }

    if (!props.categories) {
        return null;
    }

    return (
        <div className={classNames(
            "category-jumper",
            {
                "category-jumper_not-sticky": !props.onSticky,
            },
        )}>
            <div
                ref={categoryListRef}
                className={classNames("category-jumper_list", {
                    "category-jumper_list_desktop": isDesktop,
                })}
            >
                {previousOrderedItemValidated && renderCategory(orderAgainCategory, -1)}
                {props.categories.sort((a, b) => a.sortOrder - b.sortOrder).map(renderCategory)}
            </div>

            {isDesktop &&
            <div className="category-jumper_arrows">
				    <div
				        className={classNames("category-jumper_arrows_left", {
				            "category-jumper_arrows_left_hidden": !(leftOffset.left > 0),
				        })}
				    >
				        <FrameButton
				            color="dark"
				            size="icon"
				            leftIcon={FiArrowLeft}
				            onClick={slideCategories(true)}
				        />
				    </div>

				    <div
				        className={classNames("category-jumper_arrows_right", {
				            "category-jumper_arrows_right_hidden": leftOffset.max,
				        })}
				    >
				        <FrameButton
				            color="dark"
				            size="icon"
				            leftIcon={FiArrowRight}
				            onClick={slideCategories(false)}
				        />
				    </div>
            </div>
            }
        </div>
    );
}

/**
 * Get list of categories & sub-categories we want to show in the jumper component.
 *
 * @param menuCategories
 */
function getCategories(menuCategories: Array<MenuCategory>): Array<MenuCategory> {
    return menuCategories.reduce((acc: Array<MenuCategory>, category) => {
        const menuItems = category.menuItems?.filter((menuItem) => menuItem.isEnabled);
        if (category.isEnabled && menuItems?.length > 0) {
            acc.push(category);
        }

        const subCats = category.menuSubcategories?.filter((subcategory) => subcategory.isEnabled);
        if (subCats?.length > 0) {
            acc = acc.concat(getCategories(subCats));
        }

        return acc;
    }, []);
}

export default RestaurantMenuCategoriesListJumper;
