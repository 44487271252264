import FrameButton from "@/components/buttons/FrameButton";
import {ButtonHTMLAttributes, FormEvent, ReactElement, useState} from "react";
import {BiTargetLock} from "react-icons/bi";
import {Address, BrandMap, BrandMapColorTheme, ErrorType, UtilsApi} from "@devour/client";
import {useDispatch} from "react-redux";
import {addError, decrementLoading, incrementLoading, updateLastSearchedPlaceId} from "@/redux/meta/metaActions";
import FrameOneAddressAutocomplete from "@/components/inputs/FrameOneAddressAutocomplete";
import FrameOneAddressInputGroup from "@/components/inputs/FrameOneAddressInputGroup";

const defaultValues: Address = {
    line1: "",
    line2: "",
    locality: "",
    administrativeArea: "",
    postalCode: "",
    country: "US",
};

interface Props {
    brandMap: BrandMap;
}

function BrandLandingAddressSearch(props: Props): ReactElement {
    const dispatch = useDispatch();
    const [formValues, setFormValues] = useState<Address>(defaultValues);
    const [autocompleteOperational, setAutocompleteOperational] = useState<boolean>(true);
    const isTextDark = props.brandMap?.colorTheme === BrandMapColorTheme.DARK;

    function autocompleteCallback(newAddress: Address) {
        dispatch(updateLastSearchedPlaceId(newAddress.placeId));
    }

    async function onManualSubmit(e: FormEvent<HTMLFormElement>): Promise<void> {
        e.preventDefault();
        dispatch(incrementLoading());
        try {
            const res = await new UtilsApi().getPlaceIdFromAddress({
                address: formValues,
            });
            dispatch(updateLastSearchedPlaceId(res.placeId));
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            dispatch(decrementLoading());
        }
    }

    function addressOnChange(address: Address): void {
        setFormValues(address);
    }

    async function onGeolocation(position: GeolocationPosition) {
        try {
            const res = await new UtilsApi().getAddressFromLatLng({
                latitude: position.coords.latitude.toString(),
                longitude: position.coords.longitude.toString(),
            });
            dispatch(updateLastSearchedPlaceId(res.placeId));
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            dispatch(decrementLoading());
        }
    }

    async function onQueryCurrentPosition() {
        if (!navigator.geolocation) {
            dispatch(await addError({
                type: ErrorType.APP,
                message: "Geolocation is not supported by this browser.",
            }));
            return;
        }

        dispatch(incrementLoading());

        try {

            /*
             * `navigator.geolocation.getCurrentPosition` isn't an actual promise.
             * Need to wrap with a real promise and await to show loading screen.
             */
            const position: GeolocationPosition = await new Promise((resolve, reject) => {
                navigator.geolocation.getCurrentPosition(resolve, reject);
            });
            await onGeolocation(position);
        } catch (e) {
            dispatch(await addError({
                type: ErrorType.APP,
                message: e.message,
            }));
            dispatch(decrementLoading());
        }
    }
    return (
        <div className="restaurant-map-landing_address">
            <div className="restaurant-map-landing_address_header">
                <img
                    src={`${import.meta.env.VITE_CDN_URL}/images/brand-map-location-icon${isTextDark ? "-dm" : ""}.svg`}
                    alt="Location Pin"
                />
                <div>Enter Your Address</div>
                <p className="subheader">
                    To proceed your order for delivery or pickup, please enter your current address.
                </p>
            </div>
            <div className="restaurant-map-landing_address_body">
                <div className="restaurant-map-landing_address_body_search">
                    {autocompleteOperational
                        ? <FrameOneAddressAutocomplete
                            placeholder="Your current address"
                            callback={autocompleteCallback}
                            onError={() => setAutocompleteOperational(false)}
                            showButton={false}
                            showMapMarkerIcon={true}
                        />
                        : <form onSubmit={onManualSubmit}>
                            <div className="restaurant-initial-address-search_address_field-group">
                                <FrameOneAddressInputGroup
                                    value={formValues}
                                    onChange={addressOnChange}
                                    showModeChangeButton={false}
                                    defaultMode="manual"
                                />
                            </div>

                            <FrameButton
                                <ButtonHTMLAttributes<HTMLButtonElement>>
                                color="purple"
                                size="normal"
                                className="restaurant-map-landing_address_inner_body_geolocation_button"
                                forwardProps={{
                                    type: "submit",
                                }}
                            >
                                Confirm Address
                            </FrameButton>
                        </form>
                    }
                </div>
                <div className="checkout-split-pay-modal_body_divider">or</div>
                <FrameButton
                    <ButtonHTMLAttributes<HTMLButtonElement>>
                    color={isTextDark ? "gray-light" : "ghost"}
                    size="normal"
                    onClick={onQueryCurrentPosition}
                    showSpinner={true}
                    className="restaurant-map-landing_address_body_geolocation_button"
                >
                    <BiTargetLock className="svg svg-icon"/>
                    Use my current location
                </FrameButton>
            </div>
        </div>
    );
}

export default BrandLandingAddressSearch;