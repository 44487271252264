import {WindowBreakpointSizes} from "@/components/BackgroundTriangleSVG";

export function getWindowBreakpoint(windowWidth: number): WindowBreakpointSizes {
    if (windowWidth < 768) {
        return WindowBreakpointSizes.SMALL;
    } else if (windowWidth >= 768 && windowWidth < 992) {
        return WindowBreakpointSizes.MEDIUM;
    } else {
        return WindowBreakpointSizes.LARGE;
    }
}