import {ReactElement} from "react";
import {MenuOrderSubItem} from "@devour/client";

interface Props {
    quantity: number;
    name: string;
    price: number;
    customization: MenuOrderSubItem[]
}

function CheckoutOrderSummaryItem(props: Props): ReactElement {

    /**
     * Display the price of an item in the appropriate string format
     *
     * @param price
     * @param customization
     */
    function displayPrice(price: number, customization: boolean): string {
        if (price === 0) {
            return "";
        }

        const customizationsTotalPrice = props.customization.reduce((total, customizationItem) => total + customizationItem.price, 0);

        const totalPrice = customization
            ? price
            : (price + customizationsTotalPrice) * props.quantity;

        const formattedPrice = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
        }).format(totalPrice);

        return customization
            ? `+${formattedPrice}`
            : formattedPrice;
    }

    return (
        <div className="checkout-summary_item">

            <div className="checkout-summary_item_quantity-name">

                <div className="checkout-summary_item_quantity">
                    {`x ${props.quantity}`}
                </div>

                <div className="checkout-summary_item_info">

                    <div className="checkout-summary_item_name">
                        {props.name}
                    </div>

                    <div className="checkout-summary_item_customizations">
                        {props.customization.map((customization, index) => <div
                            key={index}
                            className="checkout-summary_item_customization">
                            {customization.name} {displayPrice(customization.price, true)}
                        </div>)}
                    </div>

                </div>

            </div>

            <div className="checkout-summary_item_price">
                {displayPrice(props.price, false)}
            </div>

        </div>
    );
}

export default CheckoutOrderSummaryItem;

