/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ValidateDeliveryDistanceReason,
    ValidateDeliveryDistanceReasonFromJSON,
    ValidateDeliveryDistanceReasonFromJSONTyped,
    ValidateDeliveryDistanceReasonToJSON,
} from './';

/**
 * Represents the response when validating if an address is close enough to a restaurant to order delivery.
 * @export
 * @interface ValidateDeliveryDistanceResponse
 */
export interface ValidateDeliveryDistanceResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ValidateDeliveryDistanceResponse
     */
    canDeliver: boolean;
    /**
     * 
     * @type {ValidateDeliveryDistanceReason}
     * @memberof ValidateDeliveryDistanceResponse
     */
    reason?: ValidateDeliveryDistanceReason;
}

export function ValidateDeliveryDistanceResponseFromJSON(json: any): ValidateDeliveryDistanceResponse {
    return ValidateDeliveryDistanceResponseFromJSONTyped(json, false);
}

export function ValidateDeliveryDistanceResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidateDeliveryDistanceResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'canDeliver': json['canDeliver'],
        'reason': !exists(json, 'reason') ? undefined : ValidateDeliveryDistanceReasonFromJSON(json['reason']),
    };
}

export function ValidateDeliveryDistanceResponseToJSON(value?: ValidateDeliveryDistanceResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'canDeliver': value.canDeliver,
        'reason': ValidateDeliveryDistanceReasonToJSON(value.reason),
    };
}


