export type ToastPosition =
    "top-left"
    | "top-center"
    | "top-right"
    | "bottom-left"
    | "bottom-center"
    | "bottom-right";

export type ToastVariant =
    "success"
    | "warning"
    | "error"
    | "info"
    | "background";

export interface VariantConfig {
    icon?: string;
    styles?: {
        color?: string;
        backgroundColor?: string;
        border?: string;
    };
}

type VariantsConfig = {
    [key in ToastVariant]: (isOnDarkMode?: boolean, imgPath?: string, textColor?: string) => VariantConfig;
};

export const variantsConfig: VariantsConfig = {
    success: (isOnDarkMode?: boolean) => ({
        icon: import.meta.env.VITE_CDN_URL + "/images/toast_success_icon.svg",
        styles: {
            color: isOnDarkMode ? "#5D4C9A" : "#7859E6",
            border: `0.0625rem solid ${isOnDarkMode ? "#5D4C9A" : "#7859E6"}`,
            backgroundColor: isOnDarkMode ? "#DDD5F9" : "#F9F8FE",
        },
    }),
    warning: (isOnDarkMode?: boolean) => ({
        icon: import.meta.env.VITE_CDN_URL + "/images/toast_warning_icon.svg",
        styles: {
            color: isOnDarkMode ? "#6B641D" : "#CE9500",
            border: `0.0625rem solid ${isOnDarkMode ? "#6B641D" : "#CE9500"}`,
            backgroundColor: isOnDarkMode ? "#FFFCD8" : "#FFFEE4",
        },
    }),
    error: (isOnDarkMode?: boolean) => ({
        icon: import.meta.env.VITE_CDN_URL + "/images/toast_error_icon.svg",
        styles: {
            color: isOnDarkMode ? "#6B1F1C" : "#FF4238",
            border: `0.0625rem solid ${isOnDarkMode ? "#6B1F1C" : "#FF4238"}`,
            backgroundColor: isOnDarkMode ? "#FFD9D7" : "#FFF6F2",
        },
    }),
    info: (isOnDarkMode?: boolean) => ({
        icon: import.meta.env.VITE_CDN_URL + "/images/toast_info_icon.svg",
        styles: {
            color: isOnDarkMode ? "#2D2D2F" : "#FFF",
            backgroundColor: isOnDarkMode ? "#E6E6E6" : "#403E48",
            border: isOnDarkMode ? "0.0625rem solid #9C9C9D" : null,
        },
    }),
    background: (_isOnDarkMode: boolean, imgPath: string, textColor: string) => ({
        styles: {
            color: textColor,
            border: "0.0625rem solid #FFF",
            backgroundColor: "#403E48",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundImage: `linear-gradient(88deg, rgba(0, 0, 0, 0.81) -0.23%, rgba(0, 0, 0, 0.35) 96.45%, rgba(0, 0, 0, 0.00) 106.42%), url(${imgPath})`,
        },
    }),
};
