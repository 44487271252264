/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BonusDpayOption,
    BonusDpayOptionFromJSON,
    BonusDpayOptionFromJSONTyped,
    BonusDpayOptionToJSON,
    PhoneNumberBody,
    PhoneNumberBodyFromJSON,
    PhoneNumberBodyFromJSONTyped,
    PhoneNumberBodyToJSON,
    SiteConfigurationKey,
    SiteConfigurationKeyFromJSON,
    SiteConfigurationKeyFromJSONTyped,
    SiteConfigurationKeyToJSON,
} from './';

/**
 * 
 * @export
 * @interface SiteConfigurationBody
 */
export interface SiteConfigurationBody {
    /**
     * 
     * @type {SiteConfigurationKey}
     * @memberof SiteConfigurationBody
     */
    configKey: SiteConfigurationKey;
    /**
     * 
     * @type {string}
     * @memberof SiteConfigurationBody
     */
    asset?: string;
    /**
     * 
     * @type {string}
     * @memberof SiteConfigurationBody
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof SiteConfigurationBody
     */
    buttonText?: string;
    /**
     * 
     * @type {string}
     * @memberof SiteConfigurationBody
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof SiteConfigurationBody
     */
    description?: string;
    /**
     * 
     * @type {object}
     * @memberof SiteConfigurationBody
     */
    data?: object;
    /**
     * 
     * @type {number}
     * @memberof SiteConfigurationBody
     */
    percentage?: number;
    /**
     * 
     * @type {number}
     * @memberof SiteConfigurationBody
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof SiteConfigurationBody
     */
    user?: string;
    /**
     * 
     * @type {string}
     * @memberof SiteConfigurationBody
     */
    business?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SiteConfigurationBody
     */
    nftGroupings?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof SiteConfigurationBody
     */
    startDate?: number;
    /**
     * 
     * @type {number}
     * @memberof SiteConfigurationBody
     */
    endDate?: number;
    /**
     * 
     * @type {BonusDpayOption}
     * @memberof SiteConfigurationBody
     */
    bonusDpayType?: BonusDpayOption;
    /**
     * 
     * @type {PhoneNumberBody}
     * @memberof SiteConfigurationBody
     */
    phoneNumber?: PhoneNumberBody;
}

export function SiteConfigurationBodyFromJSON(json: any): SiteConfigurationBody {
    return SiteConfigurationBodyFromJSONTyped(json, false);
}

export function SiteConfigurationBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SiteConfigurationBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'configKey': SiteConfigurationKeyFromJSON(json['configKey']),
        'asset': !exists(json, 'asset') ? undefined : json['asset'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'buttonText': !exists(json, 'buttonText') ? undefined : json['buttonText'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'data': !exists(json, 'data') ? undefined : json['data'],
        'percentage': !exists(json, 'percentage') ? undefined : json['percentage'],
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'user': !exists(json, 'user') ? undefined : json['user'],
        'business': !exists(json, 'business') ? undefined : json['business'],
        'nftGroupings': !exists(json, 'nftGroupings') ? undefined : json['nftGroupings'],
        'startDate': !exists(json, 'startDate') ? undefined : json['startDate'],
        'endDate': !exists(json, 'endDate') ? undefined : json['endDate'],
        'bonusDpayType': !exists(json, 'bonusDpayType') ? undefined : BonusDpayOptionFromJSON(json['bonusDpayType']),
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : PhoneNumberBodyFromJSON(json['phoneNumber']),
    };
}

export function SiteConfigurationBodyToJSON(value?: SiteConfigurationBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'configKey': SiteConfigurationKeyToJSON(value.configKey),
        'asset': value.asset,
        'url': value.url,
        'buttonText': value.buttonText,
        'title': value.title,
        'description': value.description,
        'data': value.data,
        'percentage': value.percentage,
        'amount': value.amount,
        'user': value.user,
        'business': value.business,
        'nftGroupings': value.nftGroupings,
        'startDate': value.startDate,
        'endDate': value.endDate,
        'bonusDpayType': BonusDpayOptionToJSON(value.bonusDpayType),
        'phoneNumber': PhoneNumberBodyToJSON(value.phoneNumber),
    };
}


