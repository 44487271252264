import React, {ReactElement, ReactNode} from "react";
import FrameOneModal from "../modalComponents/FrameOneModal";
import FrameOneBottomPanel from "../bottomPanelComponents/FrameOneBottomPanel";
import {isModal} from "@/utils/frameOneIsModal";

interface Props {
    isOpen: boolean;
    children: ReactNode;
    toggle?: () => void;
    contentClassName?: string;
    containerClassName?: string;
    maxHeight?: boolean;
    size?: "xs" | "xs2" | "sm" | "sm2" | "md" | "lg" | "xl" | "max";
    floatingHeader?: ReactNode;
    disableOverlayDismiss?: boolean;
    modalOnTablet?: boolean;
    modalOnMobile?: boolean;
    disableFullHeight?: boolean;
    setCloseModal?: React.Dispatch<React.SetStateAction<() => void>>;
    fullScreenHeight?: boolean;
}

/**
 * Opens a modal on desktop, and a panel from the bottom on mobile / tablet.
 * @param props
 * @constructor
 */
function FrameOneAutoPanel(props: Props): ReactElement {

    if (isModal(props.modalOnTablet, props.modalOnMobile)) {
        return (
            <FrameOneModal
                isOpen={props.isOpen}
                toggle={props.toggle}
                size={props.size}
                contentClassName={props.contentClassName}
                containerClassName={props.containerClassName}
                disableOverlayDismiss={props.disableOverlayDismiss}
                setCloseModal={props.setCloseModal}
            >
                {props.children}
            </FrameOneModal>
        );
    } else {
        return (
            <FrameOneBottomPanel
                isOpen={props.isOpen}
                toggle={props.toggle}
                maxHeight={props.maxHeight}
                contentClassName={props.contentClassName}
                containerClassName={props.containerClassName}
                floatingHeader={props.floatingHeader}
                disableOverlayDismiss={props.disableOverlayDismiss}
                disableFullHeight={props.disableFullHeight}
                setCloseModal={props.setCloseModal}
                fullScreenHeight={props.fullScreenHeight}
            >
                {props.children}
            </FrameOneBottomPanel>
        );
    }
}

export default FrameOneAutoPanel;
