import {
    Dispatch,
    ReactElement,
    SetStateAction,
    useContext,
    useState,
    MouseEvent,
    ButtonHTMLAttributes,
} from "react";
import {
    MenuItem,
    Address,
    UtilsApi,
    MenuOrderItem,
    ErrorType,
} from "@devour/client";
import FrameButton from "../../../components/buttons/FrameButton";
import {RestaurantContext} from "../context/RestaurantContext";
import {
    addError,
    decrementLoading,
    incrementLoading,
} from "@/redux/meta/metaActions";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {IoTrashBin} from "react-icons/io5";
import {FaPlus, FaMinus, FaLock} from "react-icons/fa";
import {isRestaurantAvailable, useRestaurantAvailability} from "@/hooks/useRestaurantAvailability";
import useThemePreference from "@/hooks/useThemePreference";
import {useUpdateMenuOrderItems} from "@/hooks/menuOrder/useUpdateMenuOrderItems";
import {useCreateMenuOrderItems} from "@/hooks/menuOrder/useCreateMenuOrderItems";

interface Props {
    defaultQuickAddItem?: MenuOrderItem;
    placeId: string;
    menuItem: MenuItem;
    setShowCustomizationModal: Dispatch<SetStateAction<boolean>>;
}

function MenuItemCardAddButton(props: Props): ReactElement {
    const {isOnDarkMode } = useThemePreference();
    const dispatch = useDispatch();
    const currentUser = useSelector((store: IStore) => store.metaStore.currentUser);
    const {menuOrder, restaurant} = useContext(RestaurantContext);
    const [
        quickAdd,
        setQuickAdd,
    ] = useState<boolean>(false);
    const {data: restaurantAvailability} = useRestaurantAvailability(restaurant);
    const availabilityCheckFailed = !isRestaurantAvailable(restaurantAvailability);
    const orderItem = menuOrder?.orderItems.find((item) => {
        return (item.menuItemId === props.menuItem.id || item.externalId === props.menuItem.id) && !item.notes;
    });
    const {
        mutateAsync: createMenuOrderItems,
    } = useCreateMenuOrderItems({
        restaurantId: restaurant.id,
    });
    const {
        mutateAsync: updateMenuOrderItems,
    } = useUpdateMenuOrderItems({
        menuOrder: menuOrder,
    });

    async function createNewShoppingCart(quantity: number = 1) {
        dispatch(incrementLoading());
        try {
            const findAddressInBook = currentUser?.user?.addresses.find(a => a.placeId === props.placeId);
            let address: Address;
            if (props.placeId) {
                address = await new UtilsApi().getAddressFromPlaceId({
                    placeId: props.placeId,
                });
            } else {
                address = restaurant.address;
            }

            const newQuickAddItem: MenuOrderItem = props.defaultQuickAddItem || {
                menuItemId: props.menuItem.id,
                quantity: quantity,
                subtotal: 0, // Subtotal property is required but will be overwritten by backend
                price: props.menuItem.price, // Price property is required but will be overwritten by backend
                taxRate: 0, // Tax rate property is required but will be overwritten by backend
                tax: 0, // Tax property is required but will be overwritten by backend
                name: "", // Name property is required but will be overwritten by backend
            };

            await createMenuOrderItems({
                address: address,
                orderItems: [newQuickAddItem],
                deliveryNotes: findAddressInBook?.deliveryInstructions,
                deliveryHandoffInstructions: findAddressInBook?.handoffInstructions,
            });
        } catch (e) {
            props.setShowCustomizationModal(true);
            // Set timeout on the error modal to ensure it starts on top of the customization modal
            setTimeout(async() => {
                dispatch(await addError({
                    type: ErrorType.APP,
                    message: "Some of the modifiers you previously selected are no longer available, please review your modifier selections.",
                }));
            }, 500);
        } finally {
            dispatch(decrementLoading());
        }
    }

    async function addToExistingShoppingCart(quantity: number) {
        dispatch(incrementLoading());
        try {

            // Check if item is already in cart
            const orderItemIndex = menuOrder?.orderItems.findIndex((item) => {
                return (item.menuItemId === props.menuItem.id || item.externalId === props.menuItem.id) && !item.notes;
            });
            const newOrderItemsBody: Array<MenuOrderItem> = menuOrder?.orderItems.concat();

            if (orderItemIndex > -1) { // Item is already in cart
                if (quantity > 0) {
                    // Update quantity of item
                    newOrderItemsBody[orderItemIndex].quantity = quantity;
                } else {
                    // Remove this item if new quantity is 0
                    newOrderItemsBody.splice(orderItemIndex, 1);
                }
            } else {
                const newQuickAddItem: MenuOrderItem = props.defaultQuickAddItem || {
                    menuItemId: props.menuItem.id,
                    quantity: 1,
                    subtotal: 0, // Subtotal property is required but will be overwritten by backend
                    price: props.menuItem.price, // Price property is required but will be overwritten by backend
                    taxRate: 0, // Tax rate property is required but will be overwritten by backend
                    tax: 0, // Tax property is required but will be overwritten by backend
                    name: "", // Name property is required but will be overwritten by backend
                };

                // Add new item to array
                newOrderItemsBody.push(newQuickAddItem);
            }
            await updateMenuOrderItems({
                orderItems: newOrderItemsBody,
            });
        } catch (e) {
            props.setShowCustomizationModal(true);
            // Set timeout on the error modal to ensure it starts on top of the customization modal
            setTimeout(async() => {
                dispatch(await addError({
                    type: ErrorType.APP,
                    message: "Some of the modifiers you previously selected are no longer available, please review your modifier selections.",
                }));
            }, 500);
        } finally {
            dispatch(decrementLoading());
        }
    }

    function updateQuantity(quantity: number) {
        if (menuOrder) {
            void addToExistingShoppingCart(quantity);
        } else {
            void createNewShoppingCart(quantity);
        }
        if (quantity <= 0) {
            setQuickAdd(false);
        }
    }

    async function initialOnClick(event: MouseEvent<HTMLButtonElement>): Promise<void> {
        event.stopPropagation();
        if (availabilityCheckFailed) {
            return;
        }

        if (props.defaultQuickAddItem || !props.menuItem.customizations?.length) { // Check if item does not have customization options
            // If item does not exist in cart, automatically add one
            if (!orderItem) {
                if (menuOrder) {
                    void addToExistingShoppingCart(1);
                } else {
                    void createNewShoppingCart(1);
                }
            }
            setQuickAdd(true);
        } else {
            // Open customization modal
            props.setShowCustomizationModal(true);
        }
    }


    return (
        <div className="menu-item-card-add-button">
            {quickAdd
                ? <div className="menu-item-card-add-button_quick-add">
                    {orderItem?.quantity > 0 &&
                    <FrameButton
						    <ButtonHTMLAttributes<HTMLButtonElement>>
						    onClick={() => updateQuantity((orderItem?.quantity || 0) - 1)}
						    color={isOnDarkMode
                            ? "purple"
                            : "white-drop-shadow"}
						    size="icon"
						    leftIcon={orderItem?.quantity === 1
                            ? IoTrashBin
                            : FaMinus}
						    showSpinner={false}
                    />
                    }
                    <div className="menu-item-card-add-button_quick-add_quantity">
                        {orderItem?.quantity || 0} x
                    </div>
                    <FrameButton
                        <ButtonHTMLAttributes<HTMLButtonElement>>
                        onClick={() => updateQuantity((orderItem?.quantity || 0) + 1)}
                        color={isOnDarkMode
                            ? "purple"
                            : "white-drop-shadow"}
                        size="icon"
                        leftIcon={FaPlus}
                        showSpinner={false}
                    />
                </div>
			 : orderItem
                    ? <div className="menu-item-card-add-button_initial">
                        <FrameButton
                            <ButtonHTMLAttributes<HTMLButtonElement>>
                            onClick={initialOnClick}
                            color={isOnDarkMode
                                ? "purple"
                                : "white-drop-shadow"}
                            size="icon"
                        >
                            {orderItem.quantity} x
                        </FrameButton>
                    </div>
			 : <div className="menu-item-card-add-button_initial">
                        <FrameButton
                            <ButtonHTMLAttributes<HTMLButtonElement>>
                            onClick={initialOnClick}
                            color={isOnDarkMode
                                ? "purple"
                                : "white-drop-shadow"}
                            size="icon"
                            leftIcon={availabilityCheckFailed
                                ? FaLock
                                : FaPlus}
                            showSpinner={false}
                        />
                    </div>
            }
        </div>
    );
}

export default MenuItemCardAddButton;
