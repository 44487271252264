/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DevourIQUpdateUberEatsUrl
 */
export interface DevourIQUpdateUberEatsUrl {
    /**
     * 
     * @type {string}
     * @memberof DevourIQUpdateUberEatsUrl
     */
    uberEatsUrl: string;
    /**
     * 
     * @type {boolean}
     * @memberof DevourIQUpdateUberEatsUrl
     */
    reimportMenu: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DevourIQUpdateUberEatsUrl
     */
    verifyMatch: boolean;
}

export function DevourIQUpdateUberEatsUrlFromJSON(json: any): DevourIQUpdateUberEatsUrl {
    return DevourIQUpdateUberEatsUrlFromJSONTyped(json, false);
}

export function DevourIQUpdateUberEatsUrlFromJSONTyped(json: any, ignoreDiscriminator: boolean): DevourIQUpdateUberEatsUrl {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'uberEatsUrl': json['uberEatsUrl'],
        'reimportMenu': json['reimportMenu'],
        'verifyMatch': json['verifyMatch'],
    };
}

export function DevourIQUpdateUberEatsUrlToJSON(value?: DevourIQUpdateUberEatsUrl | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'uberEatsUrl': value.uberEatsUrl,
        'reimportMenu': value.reimportMenu,
        'verifyMatch': value.verifyMatch,
    };
}


