import {AnchorHTMLAttributes, ButtonHTMLAttributes, ReactElement, useState} from "react";
import {BsArrowRight} from "react-icons/bs";
import {IoRefreshOutline} from "react-icons/io5";
import FrameButton from "@/components/buttons/FrameButton";
import {NftOwnershipInformation, Token, UsersApi} from "@devour/client";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {isDesktop, isTablet} from "react-device-detect";
import getConfig from "@/utils/getConfig";
import {addError, toggleLoginOpen} from "@/redux/meta/metaActions";
import Toast from "@/components/Toast";
import {useNavigate} from "react-router-dom";
import {useLocation} from "react-router";
import {useGetNftOwnershipsForUser} from "@/hooks/useGetNftOwnershipsForUser";
import SignUpToast from "../SignUpToast";

interface Props {
    nftOwnershipResponse: NftOwnershipInformation;
    isNftOwnershipResponseLoading: boolean;
}

function GoFrensHeader(props: Props): ReactElement {

    const [
        refreshingNftOwnerships,
        setRefreshingNftOwnerships,
    ] = useState(false);
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const [
        showToast,
        setShowToast,
    ] = useState<boolean>(false);
    const {isLoading: isNftOwnershipsForUserDataLoading} = useGetNftOwnershipsForUser(fullToken as Token);

    const dispatch = useDispatch();
    const history = useNavigate();
    const location = useLocation();

    function isNftOwned(): boolean {
        if (!fullToken) {
            return false;
        }
        if (props.isNftOwnershipResponseLoading) {
            return true;
        }
        if (!props.nftOwnershipResponse?.nftOwnerships?.length) {
            return false;
        }
        return !(props.nftOwnershipResponse?.nftOwnerships?.length === 1 && !props.nftOwnershipResponse?.nftOwnerships[0].length);

    }

    function createRefreshNftOwnerships(force: boolean): () => Promise<void> {
        return async () => {
            setRefreshingNftOwnerships(true);
            try {
                const res = await new UsersApi(getConfig()).updateNftOwnershipForUserRaw({force: force
                    ? "true"
                    : "false"});
                if (res.raw.status === 200) {
                    window.location.reload();
                }
            } catch (e) {
                dispatch(await addError(e));
            } finally {
                setRefreshingNftOwnerships(false);
            }
        };
    }
    function handleToastDismissal(): void {
        setShowToast(false);
    }

    return (
        <>
            <SignUpToast
                message={location.pathname.includes("/gofriends")
                    ? "Join DevourGO to unlock your GoFriends promos!"
                    : "Join DevourGO and start your GoVIP Journey!"}
                isOpen={showToast}
                onDismiss={handleToastDismissal}
            />
            <div className="gofrens-header">
                <div className="gofrens-header_content">
                    <div className="gofrens-header_content_container">
                        <div className="gofrens-header_content_container_left">
                            <h1 className="gofrens-header_content_container_left_headline">
                                REP YOUR <span
                                    className="gofrens-header_content_container_left_highlight">COMMUNITY</span>
                            </h1>
                            <p className="gofrens-header_content_container_left_subheader">
                                Join a community to get benefits, rewards, and access to exclusive menu items!
                            </p>
                            {!isNftOwned() &&
                            <div className="gofrens-header_content_container_left_button-container">
                                <FrameButton
                                    <AnchorHTMLAttributes<HTMLAnchorElement>>
                                    to="/the-industry-pass"
                                    color="ghost"
                                    size="normal"
                                    rightIcon={BsArrowRight}
                                    className="gofrens-header_content_container_left_button-container_explore"
                                    forwardProps={{
                                        target: "_blank",
                                    }}
                                >
                                        Explore The Industry Collection
                                </FrameButton>
                                <FrameButton
                                    <ButtonHTMLAttributes<HTMLButtonElement>>
                                    color="white-drop-shadow-devour"
                                    size={isDesktop || isTablet
                                        ? "normal"
                                        : "narrow"}
                                    rightIcon={IoRefreshOutline}
                                    className="gofrens-header_content_container_left_button-container_refresh"
                                    onClick={async () => {
                                        if (fullToken) {
                                            await createRefreshNftOwnerships(true)();
                                        } else {
                                            setShowToast(true);
                                        }
                                    }}
                                    forwardProps={{
                                        disabled: fullToken && (isNftOwnershipsForUserDataLoading || refreshingNftOwnerships),
                                    }}
                                >
                                    {refreshingNftOwnerships
                                        ? "Refreshing..."
                                        : isNftOwnershipsForUserDataLoading && fullToken
                                            ? "Loading GoFrens..."
                                            : "Refresh NFTs"}
                                </FrameButton>
                            </div>
                            }
                        </div>
                        <img
                            className="gofrens-header_content_container_graphic"
                            src={import.meta.env.VITE_CDN_URL + "/images/gofrens-header-nft-graphic.webp"}
                            alt="GoFriends Nfts"
                        />
                    </div>
                </div>
                <div className="gofrens-header_white-rounded"/>
            </div>
        </>
    );
}

export default GoFrensHeader;
