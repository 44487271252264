import { store } from "@/redux";
import { AnonymousSpin } from "@/types/AnonymousSpin";
import { getDateInEst } from "./getDateInEst";

const getAnonymousSpin = (today = true): AnonymousSpin | undefined => {
    const storeSpin = store.getState().metaStore.anonymousSpin;
    if (today && storeSpin) {
        const lastSpinDate = storeSpin.spinDate
            ? getDateInEst(new Date(storeSpin.spinDate))
            : null;
        const currentDate = new Date().toLocaleString("en-US", {timeZone: "America/New_York"});
        const startOfToday = new Date(currentDate);
        startOfToday.setHours(0, 0, 0, 0);
        const spinEnabled: boolean = !lastSpinDate || lastSpinDate < startOfToday;
        if (!spinEnabled) {
            return storeSpin;
        }
    }
    return storeSpin;
};
export default getAnonymousSpin;
