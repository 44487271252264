/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Address,
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
    BusinessType,
    BusinessTypeFromJSON,
    BusinessTypeFromJSONTyped,
    BusinessTypeToJSON,
    CreateBusinessBodyBase,
    CreateBusinessBodyBaseFromJSON,
    CreateBusinessBodyBaseFromJSONTyped,
    CreateBusinessBodyBaseToJSON,
    HandoffOptions,
    HandoffOptionsFromJSON,
    HandoffOptionsFromJSONTyped,
    HandoffOptionsToJSON,
    PhoneNumberBody,
    PhoneNumberBodyFromJSON,
    PhoneNumberBodyFromJSONTyped,
    PhoneNumberBodyToJSON,
    RestaurantBrandSize,
    RestaurantBrandSizeFromJSON,
    RestaurantBrandSizeFromJSONTyped,
    RestaurantBrandSizeToJSON,
} from './';

/**
 * 
 * @export
 * @interface UpdateBusinessAdmin
 */
export interface UpdateBusinessAdmin extends CreateBusinessBodyBase {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateBusinessAdmin
     */
    isEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateBusinessAdmin
     */
    isActive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateBusinessAdmin
     */
    isOperating?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateBusinessAdmin
     */
    distanceDeliveryMax?: number;
}

export function UpdateBusinessAdminFromJSON(json: any): UpdateBusinessAdmin {
    return UpdateBusinessAdminFromJSONTyped(json, false);
}

export function UpdateBusinessAdminFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateBusinessAdmin {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...CreateBusinessBodyBaseFromJSONTyped(json, ignoreDiscriminator),
        'isEnabled': !exists(json, 'isEnabled') ? undefined : json['isEnabled'],
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'isOperating': !exists(json, 'isOperating') ? undefined : json['isOperating'],
        'distanceDeliveryMax': !exists(json, 'distanceDeliveryMax') ? undefined : json['distanceDeliveryMax'],
    };
}

export function UpdateBusinessAdminToJSON(value?: UpdateBusinessAdmin | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...CreateBusinessBodyBaseToJSON(value),
        'isEnabled': value.isEnabled,
        'isActive': value.isActive,
        'isOperating': value.isOperating,
        'distanceDeliveryMax': value.distanceDeliveryMax,
    };
}


