import {PropsWithChildren, ReactElement} from "react";
import FrameModalBody from "../modalComponents/FrameModalBody";
import FrameBottomPanelBody from "../bottomPanelComponents/FrameBottomPanelBody";
import {isModal} from "@/utils/frameOneIsModal.ts";

interface Props {
    className?: string;
    modalOnTablet?: boolean;
    modalBodyRef?: (node: HTMLDivElement) => void;
    modalOnMobile?: boolean;
}

function FrameAutoPanelBody(props: PropsWithChildren<Props>): ReactElement {
    if (isModal(props.modalOnTablet, props.modalOnMobile)) {
        return (
            <FrameModalBody
                bodyRef={props.modalBodyRef}
                className={props.className}
            >
                {props.children}
            </FrameModalBody>
        );
    } else {
        return (
            <FrameBottomPanelBody
                bodyRef={props.modalBodyRef}
                className={props.className}
            >
                {props.children}
            </FrameBottomPanelBody>
        );
    }
}

export default FrameAutoPanelBody;
