import {ReactElement} from "react";
import {
    Restaurant,
} from "@devour/client";
import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import FrameButton from "../../components/buttons/FrameButton";
import RestaurantCardFavorites from "./components/RestaurantCardFavorites";
import RestaurantSearchDataSkeleton from "../../components/skeletons/RestaurantSearchDataSkeleton";
import {useGetFavouriteRestaurants} from "@/hooks/useGetFavouriteRestaurants";
import RestaurantFavoritesTopBar from "@/pages/restaurants/components/RestaurantFavoritesTopBar";
import {restaurantSearchRedirect} from "@/utils/restaurantSearchRedirect";
import {useNavigate} from "react-router-dom";
import DevourTopNav from "@/components/DevourTopNav";

function RestaurantFavoritesPage(): ReactElement {
    const navigate = useNavigate();
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const currentUser = useSelector((store: IStore) => store.metaStore.currentUser);
    const lastSearchedPlaceId = useSelector((store: IStore) => store.metaStore.lastSearchedPlaceId);

    const {data: favouriteRestaurantsData, isLoading: isFavouriteRestaurantsLoading} = useGetFavouriteRestaurants(fullToken);

    const restaurants = favouriteRestaurantsData?.restaurants;

    async function handleOrderFoodRouteForAuthedUser(): Promise<void> {
        const redirectRoute = await restaurantSearchRedirect(currentUser?.user?.addresses, lastSearchedPlaceId);
        if (redirectRoute) {
            navigate(redirectRoute);
        } else {
            navigate("/restaurants");
        }
    }

    function renderRestaurant(restaurant: Restaurant): JSX.Element {
        return (
            <li
                key={restaurant.id}
                className="restaurants-search-results_list_item"
            >
                <RestaurantCardFavorites
                    restaurant={restaurant}
                />
            </li>
        );
    }


    return (
        <div className="restaurants-favorites-page">
            <DevourTopNav text="Favorites"/>
            {isFavouriteRestaurantsLoading
                ? <RestaurantSearchDataSkeleton/>
                : <div className="restaurants-favorites-page_content">
                    <h2>
                        Favorite Restaurants
                    </h2>

                    {restaurants.length > 0
                        ? <ul className="restaurants-search-results_list">
                            {restaurants.map(renderRestaurant)}
                        </ul>
                        : <div className="restaurants-favorites-page_no-results">
                            <p>
                                You haven't saved any stores yet.
                            </p>
                            <FrameButton
                                color="purple"
                                size="normal"
                                onClick={handleOrderFoodRouteForAuthedUser}
                            >
                                Browse restaurants
                            </FrameButton>
                        </div>
                    }
                </div>
            }
        </div>
    );
}

export default RestaurantFavoritesPage;
