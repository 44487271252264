import {ReactElement, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {PaginationInfo, Token} from "@devour/client";
import {FiChevronLeft} from "react-icons/fi";
import {useNavigate} from "react-router-dom";
import GoFrensNftCard from "@/components/goFrens/GoFrensNftCard";
import GoFrensMyNftsSkeleton from "@/components/skeletons/GoFrensPage/GoFrensMyNftsSkeleton";
import {useGetNftOwnerships} from "@/hooks/useGetNftOwnerships";
import {isDesktop, isMobile, isTablet} from "react-device-detect";
import {getPaginationInfo} from "@/utils/paginationUtils";
import FramePaginator, {FrontendPagination} from "@/components/paginator/FramePaginator";

const defaultPaginationInfo: PaginationInfo = {
    enablePrevious: true,
    enableNext: true,
    lowerRange: 0,
    upperRange: 0,
    total: 0,
    availableOffsets: [],
    limit: 12,
    offset: 0,
};

function GoFrensMyNfts(): ReactElement {

    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const {data: nftOwnershipsData} = useGetNftOwnerships(fullToken as Token);
    const navigate = useNavigate();
    const count = nftOwnershipsData?.nftOwnerships?.length || 0;
    const [paginationInfo, setPaginationInfo] = useState<PaginationInfo>(defaultPaginationInfo);
    const start = paginationInfo.offset * paginationInfo.limit;
    let end = start + paginationInfo.limit;
    if (end > count) {
        end = count;
    }

    useEffect(() => {
        // Recalculate pagination & reset the results back to page 1
        onPaginationChange({
            ...paginationInfo,
            offset: 0,
        });
    }, [nftOwnershipsData?.nftOwnerships]);

    function onPaginationChange(newPagination: FrontendPagination) {
        const newPaginationInfo: PaginationInfo = getPaginationInfo({
            limit: newPagination.limit,
            offset: newPagination.offset,
            numberOfAvailableOffsets: 5,
        }, count);

        setPaginationInfo({
            ...newPagination,
            ...newPaginationInfo,
        });
    }

    return (
        <div className="view-all">
            <div
                className="view-all_back"
                onClick={() => {
                    navigate("/gofriends");
                }}
            >
                <FiChevronLeft className="view-all_back_icon"/>
                <p>Back to GoFriends</p>
            </div>

            <div className="gofrens-my-communities_description_title">
                <h3 className="gofrens-my-communities_description_title_header">My NFTs</h3>
                <p className="gofrens-my-communities_description_title_subheader">
                    NFTs I own from my communities
                </p>
            </div>
            <div className="view-all_card-container">
                {!nftOwnershipsData && <GoFrensMyNftsSkeleton noOfCards={isDesktop
                    ? 6
                    : !isTablet && isMobile
                        ? 2
                        : 4}/>}
                {nftOwnershipsData?.nftOwnerships
                    .slice(start, end)
                    .map((ownership) => {
                        return (
                            <GoFrensNftCard
                                key={ownership?.id}
                                ownership={ownership}
                            />
                        );
                    })}
            </div>
            <FramePaginator
                {...paginationInfo}
                onPaginationChange={onPaginationChange}
                showPaginatorLimit={false}
            />

        </div>
    );
}

export default GoFrensMyNfts;
