/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiError,
    ApiErrorFromJSON,
    ApiErrorToJSON,
    CreateOnboardingSignedUrlBody,
    CreateOnboardingSignedUrlBodyFromJSON,
    CreateOnboardingSignedUrlBodyToJSON,
    CreateOnboardingThirdPartyBody,
    CreateOnboardingThirdPartyBodyFromJSON,
    CreateOnboardingThirdPartyBodyToJSON,
    GetOnboardingInformationResponse,
    GetOnboardingInformationResponseFromJSON,
    GetOnboardingInformationResponseToJSON,
    GetOnboardingThirdPartiesResponse,
    GetOnboardingThirdPartiesResponseFromJSON,
    GetOnboardingThirdPartiesResponseToJSON,
    GetOnboardingThirdPartiesSort,
    GetOnboardingThirdPartiesSortFromJSON,
    GetOnboardingThirdPartiesSortToJSON,
    OnboardingSignedUrl,
    OnboardingSignedUrlFromJSON,
    OnboardingSignedUrlToJSON,
    OnboardingThirdParty,
    OnboardingThirdPartyFromJSON,
    OnboardingThirdPartyToJSON,
    SortOrder,
    SortOrderFromJSON,
    SortOrderToJSON,
} from '../models';

export interface CreateOnboardingSignedUrlRequest {
    createOnboardingSignedUrlBody?: CreateOnboardingSignedUrlBody;
}

export interface CreateOnboardingThirdPartyRequest {
    createOnboardingThirdPartyBody?: CreateOnboardingThirdPartyBody;
}

export interface DeleteOnboardingThirdPartyRequest {
    id: string;
}

export interface GetOnboardingInformationRequest {
    signedUrlKey: string;
}

export interface GetOnboardingThirdPartiesRequest {
    search?: string;
    sort?: GetOnboardingThirdPartiesSort;
    order?: SortOrder;
    limit?: number;
    offset?: number;
    numberOfAvailableOffsets?: number;
}

export interface MergeUserWithSignedUrlKeyRequest {
    signedUrlKey: string;
}

/**
 * OnboardingApi - interface
 * @export
 * @interface OnboardingApiInterface
 */
export interface OnboardingApiInterface {
    /**
     * Creates a new signed-url for onboarding a user from one platform to DevourGo.
     * @param {CreateOnboardingSignedUrlBody} [createOnboardingSignedUrlBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingApiInterface
     */
    createOnboardingSignedUrlRaw(requestParameters: CreateOnboardingSignedUrlRequest): Promise<runtime.ApiResponse<OnboardingSignedUrl>>;

    /**
     * Creates a new signed-url for onboarding a user from one platform to DevourGo.
     */
    createOnboardingSignedUrl(requestParameters: CreateOnboardingSignedUrlRequest): Promise<OnboardingSignedUrl>;

    /**
     * Creates a new Onboarding Third Party and generates the public and secret keys. This will return the secret key, which needs to be saved, as it is the only time it will be shown.
     * @param {CreateOnboardingThirdPartyBody} [createOnboardingThirdPartyBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingApiInterface
     */
    createOnboardingThirdPartyRaw(requestParameters: CreateOnboardingThirdPartyRequest): Promise<runtime.ApiResponse<OnboardingThirdParty>>;

    /**
     * Creates a new Onboarding Third Party and generates the public and secret keys. This will return the secret key, which needs to be saved, as it is the only time it will be shown.
     */
    createOnboardingThirdParty(requestParameters: CreateOnboardingThirdPartyRequest): Promise<OnboardingThirdParty>;

    /**
     * Deletes an Onboarding Third Party by ID.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingApiInterface
     */
    deleteOnboardingThirdPartyRaw(requestParameters: DeleteOnboardingThirdPartyRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Deletes an Onboarding Third Party by ID.
     */
    deleteOnboardingThirdParty(requestParameters: DeleteOnboardingThirdPartyRequest): Promise<void>;

    /**
     * gets the information associated with a signedUrlKey.
     * @param {string} signedUrlKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingApiInterface
     */
    getOnboardingInformationRaw(requestParameters: GetOnboardingInformationRequest): Promise<runtime.ApiResponse<GetOnboardingInformationResponse>>;

    /**
     * gets the information associated with a signedUrlKey.
     */
    getOnboardingInformation(requestParameters: GetOnboardingInformationRequest): Promise<GetOnboardingInformationResponse>;

    /**
     * 
     * @param {string} [search] will search over name, description, public key, and the secret key. You can type the secret key in here to confirm who it was given to.
     * @param {GetOnboardingThirdPartiesSort} [sort] 
     * @param {SortOrder} [order] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {number} [numberOfAvailableOffsets] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingApiInterface
     */
    getOnboardingThirdPartiesRaw(requestParameters: GetOnboardingThirdPartiesRequest): Promise<runtime.ApiResponse<GetOnboardingThirdPartiesResponse>>;

    /**
     */
    getOnboardingThirdParties(requestParameters: GetOnboardingThirdPartiesRequest): Promise<GetOnboardingThirdPartiesResponse>;

    /**
     * gets the information associated with a signedUrlKey.
     * @param {string} signedUrlKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingApiInterface
     */
    mergeUserWithSignedUrlKeyRaw(requestParameters: MergeUserWithSignedUrlKeyRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * gets the information associated with a signedUrlKey.
     */
    mergeUserWithSignedUrlKey(requestParameters: MergeUserWithSignedUrlKeyRequest): Promise<void>;

}

/**
 * no description
 */
export class OnboardingApi extends runtime.BaseAPI implements OnboardingApiInterface {

    /**
     * Creates a new signed-url for onboarding a user from one platform to DevourGo.
     */
    async createOnboardingSignedUrlRaw(requestParameters: CreateOnboardingSignedUrlRequest): Promise<runtime.ApiResponse<OnboardingSignedUrl>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/onboarding/signed-url`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateOnboardingSignedUrlBodyToJSON(requestParameters.createOnboardingSignedUrlBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OnboardingSignedUrlFromJSON(jsonValue));
    }

    /**
     * Creates a new signed-url for onboarding a user from one platform to DevourGo.
     */
    async createOnboardingSignedUrl(requestParameters: CreateOnboardingSignedUrlRequest): Promise<OnboardingSignedUrl> {
        const response = await this.createOnboardingSignedUrlRaw(requestParameters);
        return await response.value();
    }

    /**
     * Creates a new Onboarding Third Party and generates the public and secret keys. This will return the secret key, which needs to be saved, as it is the only time it will be shown.
     */
    async createOnboardingThirdPartyRaw(requestParameters: CreateOnboardingThirdPartyRequest): Promise<runtime.ApiResponse<OnboardingThirdParty>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/onboarding/third-party`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateOnboardingThirdPartyBodyToJSON(requestParameters.createOnboardingThirdPartyBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OnboardingThirdPartyFromJSON(jsonValue));
    }

    /**
     * Creates a new Onboarding Third Party and generates the public and secret keys. This will return the secret key, which needs to be saved, as it is the only time it will be shown.
     */
    async createOnboardingThirdParty(requestParameters: CreateOnboardingThirdPartyRequest): Promise<OnboardingThirdParty> {
        const response = await this.createOnboardingThirdPartyRaw(requestParameters);
        return await response.value();
    }

    /**
     * Deletes an Onboarding Third Party by ID.
     */
    async deleteOnboardingThirdPartyRaw(requestParameters: DeleteOnboardingThirdPartyRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteOnboardingThirdParty.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/onboarding/third-party/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes an Onboarding Third Party by ID.
     */
    async deleteOnboardingThirdParty(requestParameters: DeleteOnboardingThirdPartyRequest): Promise<void> {
        await this.deleteOnboardingThirdPartyRaw(requestParameters);
    }

    /**
     * gets the information associated with a signedUrlKey.
     */
    async getOnboardingInformationRaw(requestParameters: GetOnboardingInformationRequest): Promise<runtime.ApiResponse<GetOnboardingInformationResponse>> {
        if (requestParameters.signedUrlKey === null || requestParameters.signedUrlKey === undefined) {
            throw new runtime.RequiredError('signedUrlKey','Required parameter requestParameters.signedUrlKey was null or undefined when calling getOnboardingInformation.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/onboarding/signed-url/information/{signedUrlKey}`.replace(`{${"signedUrlKey"}}`, encodeURIComponent(String(requestParameters.signedUrlKey))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetOnboardingInformationResponseFromJSON(jsonValue));
    }

    /**
     * gets the information associated with a signedUrlKey.
     */
    async getOnboardingInformation(requestParameters: GetOnboardingInformationRequest): Promise<GetOnboardingInformationResponse> {
        const response = await this.getOnboardingInformationRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getOnboardingThirdPartiesRaw(requestParameters: GetOnboardingThirdPartiesRequest): Promise<runtime.ApiResponse<GetOnboardingThirdPartiesResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.numberOfAvailableOffsets !== undefined) {
            queryParameters['numberOfAvailableOffsets'] = requestParameters.numberOfAvailableOffsets;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/onboarding/third-parties`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetOnboardingThirdPartiesResponseFromJSON(jsonValue));
    }

    /**
     */
    async getOnboardingThirdParties(requestParameters: GetOnboardingThirdPartiesRequest): Promise<GetOnboardingThirdPartiesResponse> {
        const response = await this.getOnboardingThirdPartiesRaw(requestParameters);
        return await response.value();
    }

    /**
     * gets the information associated with a signedUrlKey.
     */
    async mergeUserWithSignedUrlKeyRaw(requestParameters: MergeUserWithSignedUrlKeyRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.signedUrlKey === null || requestParameters.signedUrlKey === undefined) {
            throw new runtime.RequiredError('signedUrlKey','Required parameter requestParameters.signedUrlKey was null or undefined when calling mergeUserWithSignedUrlKey.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/onboarding/signed-url/merge-user/{signedUrlKey}`.replace(`{${"signedUrlKey"}}`, encodeURIComponent(String(requestParameters.signedUrlKey))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * gets the information associated with a signedUrlKey.
     */
    async mergeUserWithSignedUrlKey(requestParameters: MergeUserWithSignedUrlKeyRequest): Promise<void> {
        await this.mergeUserWithSignedUrlKeyRaw(requestParameters);
    }

}
