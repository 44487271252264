import {useQuery} from "@tanstack/react-query";
import {
    BusinessesApi,
    HandoffOptions,
    GetOrderAgainRestaurantsResponse, Token,
} from "@devour/client";
import getConfig from "@/utils/getConfig";

async function fetchOrderAgainRestaurants(placeId: string, handoff: HandoffOptions): Promise<GetOrderAgainRestaurantsResponse> {
    return await new BusinessesApi(getConfig()).getOrderAgainRestaurants({
        placeId: placeId,
        handoff: handoff,
    });
}

function getOrderAgainRestaurants(placeId: string, handoff: HandoffOptions, fullToken: Token) {
    return {
        queryKey: [
            "user-order-again-restaurants",
            fullToken?.id ?? "unauthenticated",
            placeId,
            handoff,
        ],
        queryFn: () => fetchOrderAgainRestaurants(placeId, handoff),
        staleTime: 60000,
        enabled: !!placeId && !!fullToken,
    } as const;
}

export function useGetOrderAgainRestaurants(placeId: string, handoff: HandoffOptions, fullToken: Token) {
    return useQuery(getOrderAgainRestaurants(placeId, handoff, fullToken));
}