import {ReactElement} from "react";
import {IoMdExit} from "react-icons/io";
import {BiCommentError} from "react-icons/bi";

interface Props {
    toggleLeaveCommunityModal: () => void;
    toggleCommunityInfoDrawer: () => void
}
function GoFrensCommunityHeaderDropdownBody(props: Props): ReactElement {

    return (
        <>
            <div
                className="devour-top-nav_dropdown_account_options_row"
                onClick={props.toggleCommunityInfoDrawer}
            >
                <BiCommentError className="community-about"/>
                <p>About</p>
            </div>
            <hr />
            <div
                className="devour-top-nav_dropdown_account_options_row devour-top-nav_dropdown_account_options_logout"
                onClick={props.toggleLeaveCommunityModal}
            >
                <IoMdExit strokeWidth="0.675rem"/>
                <p>Leave This Community</p>
            </div>
        </>
    );
}

export default GoFrensCommunityHeaderDropdownBody;
