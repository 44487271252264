/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum GetMenuOrdersOwnSort {
    CreatedAt = 'createdAt',
    UpdatedAt = 'updatedAt',
    Status = 'status'
}

export function GetMenuOrdersOwnSortFromJSON(json: any): GetMenuOrdersOwnSort {
    return GetMenuOrdersOwnSortFromJSONTyped(json, false);
}

export function GetMenuOrdersOwnSortFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetMenuOrdersOwnSort {
    return json as GetMenuOrdersOwnSort;
}

export function GetMenuOrdersOwnSortToJSON(value?: GetMenuOrdersOwnSort | null): any {
    return value as any;
}

