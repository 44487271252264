/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SimpleHashWebhookPayloadData,
    SimpleHashWebhookPayloadDataFromJSON,
    SimpleHashWebhookPayloadDataFromJSONTyped,
    SimpleHashWebhookPayloadDataToJSON,
} from './';

/**
 * 
 * @export
 * @interface SimpleHashWebhookPayload
 */
export interface SimpleHashWebhookPayload {
    /**
     * 
     * @type {SimpleHashWebhookPayloadData}
     * @memberof SimpleHashWebhookPayload
     */
    data: SimpleHashWebhookPayloadData;
    /**
     * 
     * @type {string}
     * @memberof SimpleHashWebhookPayload
     */
    type: string;
}

export function SimpleHashWebhookPayloadFromJSON(json: any): SimpleHashWebhookPayload {
    return SimpleHashWebhookPayloadFromJSONTyped(json, false);
}

export function SimpleHashWebhookPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimpleHashWebhookPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': SimpleHashWebhookPayloadDataFromJSON(json['data']),
        'type': json['type'],
    };
}

export function SimpleHashWebhookPayloadToJSON(value?: SimpleHashWebhookPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': SimpleHashWebhookPayloadDataToJSON(value.data),
        'type': value.type,
    };
}


