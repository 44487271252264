/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
    TimeStamps,
    TimeStampsFromJSON,
    TimeStampsFromJSONTyped,
    TimeStampsToJSON,
} from './';

/**
 * Represents a third party and its associated public and secret keys for onboarding users.
 * @export
 * @interface OnboardingThirdParty
 */
export interface OnboardingThirdParty {
    /**
     * 
     * @type {string}
     * @memberof OnboardingThirdParty
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof OnboardingThirdParty
     */
    createdAt: number;
    /**
     * 
     * @type {number}
     * @memberof OnboardingThirdParty
     */
    updatedAt: number;
    /**
     * 
     * @type {string}
     * @memberof OnboardingThirdParty
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof OnboardingThirdParty
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof OnboardingThirdParty
     */
    publicKey?: string;
    /**
     * 
     * @type {string}
     * @memberof OnboardingThirdParty
     */
    secretKey?: string;
    /**
     * An example of what the header should look like for this third party to submit requests to the backend.
     * @type {string}
     * @memberof OnboardingThirdParty
     */
    exampleHeader: string;
}

export function OnboardingThirdPartyFromJSON(json: any): OnboardingThirdParty {
    return OnboardingThirdPartyFromJSONTyped(json, false);
}

export function OnboardingThirdPartyFromJSONTyped(json: any, ignoreDiscriminator: boolean): OnboardingThirdParty {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'title': json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'publicKey': !exists(json, 'publicKey') ? undefined : json['publicKey'],
        'secretKey': !exists(json, 'secretKey') ? undefined : json['secretKey'],
        'exampleHeader': json['exampleHeader'],
    };
}

export function OnboardingThirdPartyToJSON(value?: OnboardingThirdParty | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'title': value.title,
        'description': value.description,
        'publicKey': value.publicKey,
        'secretKey': value.secretKey,
        'exampleHeader': value.exampleHeader,
    };
}


