/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NftRewardPrize,
    NftRewardPrizeFromJSON,
    NftRewardPrizeFromJSONTyped,
    NftRewardPrizeToJSON,
    PaginatedResponse,
    PaginatedResponseFromJSON,
    PaginatedResponseFromJSONTyped,
    PaginatedResponseToJSON,
    PaginationInfo,
    PaginationInfoFromJSON,
    PaginationInfoFromJSONTyped,
    PaginationInfoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetNftRewardPrizesForAdminResponse
 */
export interface GetNftRewardPrizesForAdminResponse extends PaginatedResponse {
    /**
     * 
     * @type {Array<NftRewardPrize>}
     * @memberof GetNftRewardPrizesForAdminResponse
     */
    prizes?: Array<NftRewardPrize>;
}

export function GetNftRewardPrizesForAdminResponseFromJSON(json: any): GetNftRewardPrizesForAdminResponse {
    return GetNftRewardPrizesForAdminResponseFromJSONTyped(json, false);
}

export function GetNftRewardPrizesForAdminResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetNftRewardPrizesForAdminResponse {
    return json;
}

export function GetNftRewardPrizesForAdminResponseToJSON(value?: GetNftRewardPrizesForAdminResponse | null): any {
    return value;
}


