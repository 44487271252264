import {createContext, Dispatch, SetStateAction} from "react";

export enum BrandMapStates {
    NOT_INITIATED,
    SELECT_PROMOTION,
    SELECT_LOCATION,
    ORDER_IN_PROGRESS,
    CHECKOUT_IN_PROGRESS,
    SUCCESS,
    FAILURE,
}

interface IBrandMapContext {
    brandMapState?: BrandMapStates;
    setBrandMapState?: Dispatch<SetStateAction<BrandMapStates>>;
    selectedPromo?: string;
    setSelectedPromo?: Dispatch<SetStateAction<string>>;
    selectedRestaurant?: string;
    setSelectedRestaurant?: Dispatch<SetStateAction<string>>;
}

const BRAND_MAP_DEFAULT_VALUE: IBrandMapContext = {
    brandMapState: BrandMapStates.NOT_INITIATED,
    setBrandMapState: undefined,
    selectedPromo: undefined,
    setSelectedPromo: undefined,
    selectedRestaurant: undefined,
    setSelectedRestaurant: undefined,
};

export const BrandMapContext = createContext<IBrandMapContext>(BRAND_MAP_DEFAULT_VALUE);
