import {ReactElement} from "react";

function BrandSkeleton(): ReactElement {
    return (
        <div className="brand-skeleton">
            <div className="brand-skeleton_top-nav react-loading-skeleton"></div>
            <div className="brand-skeleton_header react-loading-skeleton"></div>
            <div className="brand-skeleton_description react-loading-skeleton"></div>
            <div className="brand-skeleton_progress react-loading-skeleton"></div>
            <div className="brand-skeleton_order react-loading-skeleton"></div>
        </div>
    );
}

export default BrandSkeleton;