import React, {ReactElement, ReactNode, useEffect, useState} from "react";
import FrameButton from "../../buttons/FrameButton";
import {WalletMultiButton} from "@solana/wallet-adapter-react-ui";
import {useWallet} from "@solana/wallet-adapter-react";
import {Token, UsersApi} from "@devour/client";
import getConfig from "@/utils/getConfig";
import bs58 from "bs58";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {useGetUserProfile} from "@/hooks/useGetUserProfile";
import {addError, decrementLoading, incrementLoading} from "@/redux/meta/metaActions";
import {IoIosCheckmarkCircle} from "react-icons/io";
import {isGoNative, isPhantomApp} from "@/utils/isGoNative";
import {WalletNotConnectedError} from "@solana/wallet-adapter-base";
import {useGetNftOwnerships} from "@/hooks/useGetNftOwnerships";
import FrameOneAutoPanel from "@/components/modals/autoPanelComponents/FrameOneAutoPanel";
import FrameAutoPanelHeader from "@/components/modals/autoPanelComponents/FrameAutoPanelHeader";
import FrameAutoPanelBody from "@/components/modals/autoPanelComponents/FrameAutoPanelBody";
import {isMobile, isTablet} from "react-device-detect";
import {solanaInstructionsCopy} from "@/components/modals/wallets/solanaInstructionsCopy";
import SolanaInstructionRow from "@/components/modals/wallets/SolanaInstructionRow";
import FrameAutoPanelFooter from "@/components/modals/autoPanelComponents/FrameAutoPanelFooter";
import {useGetNftOwnershipsForUser} from "@/hooks/useGetNftOwnershipsForUser";

interface Props {
    isOpen: boolean;
    toggle: () => void;
}

function ConnectSolanaWalletModal(props: Props): ReactElement {

    const wallet = useWallet();

    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);

    const {data: currentUser, refetch: refetchUserProfileData} = useGetUserProfile(fullToken);
    const {refetch: refetchNftOwnerships} = useGetNftOwnerships(fullToken as Token);
    const {refetch: refetchNftOwnershipsForUser} = useGetNftOwnershipsForUser(fullToken as Token);

    const dispatch = useDispatch();

    const [
        isConnected,
        setIsConnected,
    ] = useState<boolean>(false);

    useEffect(() => {
        if (!props.isOpen) {
            setIsConnected(false);
            if (wallet.connected) {
                void wallet.disconnect();
            }
        }
    }, [props.isOpen]);

    async function connectSolanaWallet(): Promise<void> {
        try {
            if (!wallet.publicKey) {
                throw new WalletNotConnectedError();
            }
            const data = new TextEncoder().encode(currentUser?.user?.id);
            const signature = await wallet.signMessage(data);

            dispatch(incrementLoading());

            await new UsersApi(getConfig(fullToken)).addSolanaWallet({
                addWalletRequestBody: {
                    signature: bs58.encode(signature),
                    publicKey: wallet.publicKey.toBase58(),
                },
            });
            void refetchUserProfileData();
            void refetchNftOwnerships();
            void refetchNftOwnershipsForUser();
            setIsConnected(true);
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            dispatch(decrementLoading());
        }
    }

    function renderTitle(): string {
        if (!wallet.publicKey) {
            return "Connect Solana Wallet";
        }
        if (!isConnected) {
            return "Verify Wallet";
        }
        return "Success!";
    }

    function renderDefaultBody(): ReactNode {
        return (
            <div className="solana-wallet-modal_body">
                <img
                    src={import.meta.env.VITE_CDN_URL + "/images/wallet-connect-img.webp"}
                    alt="wallet connect"
                    className="solana-wallet-modal_body_graphic"
                />

                <p className="solana-wallet-modal_body_description">
                    Connect your wallet to DevourGO to unlock exclusive rewards and compete for your NFT community!
                </p>

                {isGoNative() &&
                <div className="solana-wallet-modal_body_app">
                    <p className="solana-wallet-modal_body_app_header">
                        <strong>How to Connect</strong>
                    </p>
                    <div className="solana-wallet-modal_body_app_steps">
                        <div className="solana-wallet-modal_body_app_steps_container">
                            {solanaInstructionsCopy.map((row, index) => {
                                return (
                                    <SolanaInstructionRow
                                        key={index}
                                        step={row.step}
                                        text={row.text}
                                        imageUrl={row.imageUrl}
                                    />
                                );
                            })}
                        </div>
                    </div>

                    <p className="solana-wallet-modal_body_create">
                            If you encounter difficulties, we suggest using {" "}
                        <a
                            href={"https://devourgo.io"}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="solana-wallet-modal_body_link"
                        >
                                our mobile web browser
                        </a>
                        {" "}or the desktop option instead.
                    </p>
                </div>
                }

                {!isGoNative() &&
                <>
                    <WalletMultiButton/>

                    {wallet.publicKey &&
                    <>
                        <br/>
                        <FrameButton
                            <React.ButtonHTMLAttributes<HTMLButtonElement>>
                            color="purple"
                            size="normal"
                            onClick={async () => await connectSolanaWallet()}
                            className="solana-wallet-modal_verify-btn"
                        >
                                    Verify
                        </FrameButton>
                    </>
                    }

                    <p className="solana-wallet-modal_body_create">
                            Don't have a Solana wallet?{" "}
                        <a
                            href="https://phantom.app/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="solana-wallet-modal_body_link"
                        >
                            {isMobile && !isTablet
                                ? "Create one"
                                : "Click here to create one"}
                        </a>
                    </p>
                </>
                }

            </div>
        );
    }

    function renderWalletFinishedBody(): ReactNode {
        return (
            <div className="wallet-connect-registry-modal_body_finished">
                <div className="wallet-connect-registry-modal_body_finished_check-con">
                    <IoIosCheckmarkCircle
                        className="wallet-connect-registry-modal_body_finished_check-con_check"/>
                </div>

                <p className="wallet-connect-registry-modal_body_finished_status">
                    Wallet connected!
                </p>
                {isPhantomApp() &&
                <p>
                        To return to your previous screen, please close the Phantom application and reopen the DevourGo
                        application or mobile browser you were using before. You can then resume your experience from
                        there.
                </p>
                }
            </div>
        );
    }

    return (
        <FrameOneAutoPanel
            isOpen={props.isOpen}
            toggle={props.toggle}
            contentClassName="solana-wallet-modal"
            size="xs"
            modalOnTablet={true}
            fullScreenHeight={!isConnected && isGoNative()}
        >
            <FrameAutoPanelHeader
                title={renderTitle()}
                isTitleBold={true}
                toggle={props.toggle}
                centerTitle={true}
                modalOnTablet={true}
                showXBottomPanel={true}
            />

            <FrameAutoPanelBody>
                {!isConnected
                    ? renderDefaultBody()
                    : renderWalletFinishedBody()}
            </FrameAutoPanelBody>

            {!isConnected && isGoNative() &&
            <FrameAutoPanelFooter>
                <div className="solana-wallet-modal_body">
                    <WalletMultiButton/>

                    {wallet.publicKey &&
                    <>
                        <br/>
                        <FrameButton
                            <React.ButtonHTMLAttributes<HTMLButtonElement>>
                            color="purple"
                            size="normal"
                            onClick={async () => await connectSolanaWallet()}
                            className="solana-wallet-modal_verify-btn"
                        >
                                    Verify
                        </FrameButton>
                    </>
                    }

                    <p className="solana-wallet-modal_body_create">
                            Don't have a Solana wallet?{" "}
                        <a
                            href="https://phantom.app/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="solana-wallet-modal_body_link"
                        >
                            {isMobile && !isTablet
                                ? "Create one"
                                : "Click here to create one"}
                        </a>
                    </p>
                </div>
            </FrameAutoPanelFooter>
            }

        </FrameOneAutoPanel>
    );
}

export default ConnectSolanaWalletModal;
