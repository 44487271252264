/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserType,
    UserTypeFromJSON,
    UserTypeFromJSONTyped,
    UserTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface UpdateUserTypeBody
 */
export interface UpdateUserTypeBody {
    /**
     * 
     * @type {UserType}
     * @memberof UpdateUserTypeBody
     */
    type: UserType;
}

export function UpdateUserTypeBodyFromJSON(json: any): UpdateUserTypeBody {
    return UpdateUserTypeBodyFromJSONTyped(json, false);
}

export function UpdateUserTypeBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateUserTypeBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': UserTypeFromJSON(json['type']),
    };
}

export function UpdateUserTypeBodyToJSON(value?: UpdateUserTypeBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': UserTypeToJSON(value.type),
    };
}


