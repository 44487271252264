import {ButtonHTMLAttributes, ReactElement} from "react";
import FrameOneAutoPanel from "@/components/modals/autoPanelComponents/FrameOneAutoPanel";
import FrameAutoPanelHeader from "@/components/modals/autoPanelComponents/FrameAutoPanelHeader";
import FrameAutoPanelBody from "@/components/modals/autoPanelComponents/FrameAutoPanelBody";
import {PiWarningCircleBold} from "react-icons/pi";
import FrameButton from "@/components/buttons/FrameButton";

interface Props {
    isOpen: boolean;
    toggle: () => void;
}

function LoadDpayFailedModal(props: Props): ReactElement {
    return (
        <FrameOneAutoPanel
            isOpen={props.isOpen}
            toggle={props.toggle}
            contentClassName="payment-failed-modal"
            size="xs"
        >
            <FrameAutoPanelHeader
                title=""
                toggle={props.toggle}
            />
            <FrameAutoPanelBody className="payment-failed-modal_body">
                <div className="payment-failed-modal_body_icon">
                    <PiWarningCircleBold/>
                </div>

                <h4>
                    Load FUEL Failed
                </h4>

                <p className="payment-failed-modal_body_message">
                    There has been a problem loading FUEL into your account. Please try using a different card or try again.
                </p>

                <FrameButton
                    <ButtonHTMLAttributes<HTMLButtonElement>>
                    color="purple"
                    size="normal"
                    onClick={props.toggle}
                >
                    Try Again
                </FrameButton>

                <p
                    className="payment-failed-modal_body_close-btn"
                    onClick={props.toggle}
                >
                    Close
                </p>

            </FrameAutoPanelBody>
        </FrameOneAutoPanel>
    );
}

export default LoadDpayFailedModal;