/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetMenuOrdersOwnResponseAllOf,
    GetMenuOrdersOwnResponseAllOfFromJSON,
    GetMenuOrdersOwnResponseAllOfFromJSONTyped,
    GetMenuOrdersOwnResponseAllOfToJSON,
    MenuOrderOwn,
    MenuOrderOwnFromJSON,
    MenuOrderOwnFromJSONTyped,
    MenuOrderOwnToJSON,
    PaginatedResponse,
    PaginatedResponseFromJSON,
    PaginatedResponseFromJSONTyped,
    PaginatedResponseToJSON,
    PaginationInfo,
    PaginationInfoFromJSON,
    PaginationInfoFromJSONTyped,
    PaginationInfoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetMenuOrdersOwnResponse
 */
export interface GetMenuOrdersOwnResponse extends PaginatedResponse {
    /**
     * 
     * @type {Array<MenuOrderOwn>}
     * @memberof GetMenuOrdersOwnResponse
     */
    menuOrders: Array<MenuOrderOwn>;
}

export function GetMenuOrdersOwnResponseFromJSON(json: any): GetMenuOrdersOwnResponse {
    return GetMenuOrdersOwnResponseFromJSONTyped(json, false);
}

export function GetMenuOrdersOwnResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetMenuOrdersOwnResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...PaginatedResponseFromJSONTyped(json, ignoreDiscriminator),
        'menuOrders': ((json['menuOrders'] as Array<any>).map(MenuOrderOwnFromJSON)),
    };
}

export function GetMenuOrdersOwnResponseToJSON(value?: GetMenuOrdersOwnResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...PaginatedResponseToJSON(value),
        'menuOrders': ((value.menuOrders as Array<any>).map(MenuOrderOwnToJSON)),
    };
}


