import {ReactElement, useContext, useEffect, useState} from "react";
import {FaChevronRight, FaTag} from "react-icons/fa";
import {FaPlus} from "react-icons/fa6";
import CheckoutDiscountSelectionModal from "@/components/checkout/checkoutOrderSummary/CheckoutDiscountSelectionModal";
import CheckoutTotals from "@/components/checkout/checkoutOrderSummary/CheckoutTotals";
import {MenuOrder, MenuOrdersApi, OrderDiscountType} from "@devour/client";
import {useNavigate} from "react-router-dom";
import CheckoutOrderSummaryItems from "@/components/checkout/checkoutOrderSummary/CheckoutOrderSummaryItemsContainer";
import CheckoutOrderSummarySkeleton from "@/components/checkout/checkoutOrderSummary/CheckoutOrderSummarySkeleton";
import Skeleton from "react-loading-skeleton";
import {useParams} from "react-router";
import {useMenuOrder} from "@/hooks/menuOrder/useMenuOrder";
import {useDispatch} from "react-redux";
import {addError} from "@/redux/meta/metaActions";
import getConfig from "@/utils/getConfig";
import {RestaurantContext} from "@/pages/restaurants/context/RestaurantContext";
import {BrandMapStates} from "@/pages/brandMap/context/BrandMapContext";
import {useRestaurant} from "@/hooks/useRestaurant";

function CheckoutOrderSummary(): ReactElement {

    const { menuOrderId: contextMenuOrderId, embeddedMenu, setCheckoutState } = useContext(RestaurantContext);
    const { menuOrderId: paramMenuOrderId } = useParams<{ menuOrderId: string }>();
    const menuOrderId = paramMenuOrderId || contextMenuOrderId;

    const {data: menuOrder, refetch: refetchMenuOrder} = useMenuOrder(menuOrderId);
    const {data: restaurant} = useRestaurant(menuOrder?.business);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [
        isCallbackFunctionsRunning,
        setIsCallbackFunctionsRunning,
    ] = useState<boolean>(false);
    const [
        showEditCart,
        setShowEditCart,
    ] = useState(false);
    const [
        showDiscountSelectionModal,
        setShowDiscountSelectionModal,
    ] = useState<boolean>(false);

    // TODO: refactor
    const [
        isOrderSummaryLoading,
        setIsOrderSummaryLoading,
    ] = useState<boolean>(false);

    const selectedDiscountsApplied = menuOrder?.discounts
        .filter(discount => {
            return discount.type !== OrderDiscountType.SALESTAX &&
                (discount.code && discount.code === menuOrder.discountCodes[0] ||
                discount.reference && discount.reference === menuOrder.discountSelected);
        });

    useEffect(() => {
        if (menuOrder && !menuOrder.callbackComplete) {
            void checkCallbackComplete();
        }
    }, [menuOrder]);

    /**
     * This is to get around forcing a refresh of the cart until menuOrder.callbackComplete
     * is complete, which was causing the restaurant context to trigger re-renders of the cart.
     */
    async function checkCallbackComplete(): Promise<void> {

        // Set a state to prevent this from being called via the useEffect if
        // function is already running.
        if (isCallbackFunctionsRunning) {
            return;
        }

        setIsCallbackFunctionsRunning(true);

        try {
            let complete: boolean = false;

            // We essentially run a while loop that checks the status of the menuOrder
            // until the callback function is finished.
            while (!complete) {
                complete = await timeoutGettingMenuOrder();
            }

            // After the while loop ends, we refresh the cart ONCE.
            await refetchMenuOrder();

        } catch (e) {
            dispatch(await addError(e));

        } finally {
            // Reset the callback function running state.
            setIsCallbackFunctionsRunning(false);
        }
    }

    async function getMenuOrder(): Promise<boolean> {
        if (!menuOrder?.id) {
            return true;
        }
        const fetchedMenuOrder: MenuOrder = await new MenuOrdersApi(getConfig()).getMenuOrder({
            id: menuOrder?.id,
        });
        return fetchedMenuOrder.callbackComplete;
    }

    async function timeoutGettingMenuOrder(): Promise<boolean> {
        const timeoutInterval = 2000;

        // Set a lock which causes a 2-second wait between fetch times.
        await new Promise(resolve => setTimeout(resolve, timeoutInterval));

        return await getMenuOrder();
    }

    function getButtonContent(): ReactElement {
        if (!selectedDiscountsApplied?.length) {
            return <>Add Promotions</>;
        } else if (selectedDiscountsApplied.length === 1) {
            return (
                <>
                    <span
                        className="checkout-summary_discounts-btn_left_content_single-promo-label">Promotion Added</span>
                    <span>
                        <strong>{selectedDiscountsApplied[0].code || selectedDiscountsApplied[0].label}</strong>
                    </span>
                </>
            );
        } else {
            return <span className="checkout-summary_discounts-btn_left_content_multiple-promos">Multiple promotions applied</span>;
        }
    }

    function toggleEditCart(): void {
        setShowEditCart(!showEditCart);
    }

    function toggleDiscountSelectionModal(): void {
        setShowDiscountSelectionModal(!showDiscountSelectionModal);
    }

    return (
        <>
            <CheckoutDiscountSelectionModal
                isOpen={showDiscountSelectionModal}
                onClose={toggleDiscountSelectionModal}
                setIsOrderSummaryLoading={setIsOrderSummaryLoading}
            />
            <div className="checkout-summary">
                <div className="checkout-summary_header">
                    <h4>Order Summary</h4>
                    {showEditCart &&
                    <div
                        className="checkout-summary_edit-cart"
                        onClick={() => {
                            if (paramMenuOrderId) {
                                navigate(`${restaurant?.url}/${menuOrder.address.placeId}`);
                            } else if (embeddedMenu) {
                                setCheckoutState(BrandMapStates.ORDER_IN_PROGRESS);
                            }
                        }}
                    >
                        <p>Edit Cart</p>
                    </div>
                    }
                </div>

                {!menuOrder
                    ? <CheckoutOrderSummarySkeleton rows={1}/>
                    : <CheckoutOrderSummaryItems onClick={toggleEditCart}/>
                }

                <div
                    className="checkout-summary_discounts-btn"
                    onClick={toggleDiscountSelectionModal}
                >
                    <div className="checkout-summary_discounts-btn_left">
                        <FaTag/>
                        <div className="checkout-summary_discounts-btn_left_content">
                            {!menuOrder || isCallbackFunctionsRunning || isOrderSummaryLoading
                                ? <Skeleton width={150} height={25}/>
                                : getButtonContent()
                            }
                        </div>
                    </div>
                    {!selectedDiscountsApplied?.length
                        ? <FaPlus className="checkout-summary_discounts-btn_add-icon"/>
                        : <FaChevronRight className="checkout-summary_discounts-btn_add-icon"/>
                    }
                </div>

                <hr/>

                {isCallbackFunctionsRunning || isOrderSummaryLoading
                    ? <CheckoutOrderSummarySkeleton rows={3} />
                    : <CheckoutTotals/>
                }

            </div>
        </>
    );
}


export default CheckoutOrderSummary;

