/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
    TimeStamps,
    TimeStampsFromJSON,
    TimeStampsFromJSONTyped,
    TimeStampsToJSON,
} from './';

/**
 * Used to save a fiat => DPAY purchase attempt.
 * @export
 * @interface DpayPurchase
 */
export interface DpayPurchase {
    /**
     * 
     * @type {string}
     * @memberof DpayPurchase
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof DpayPurchase
     */
    createdAt: number;
    /**
     * 
     * @type {number}
     * @memberof DpayPurchase
     */
    updatedAt: number;
    /**
     * 
     * @type {string}
     * @memberof DpayPurchase
     */
    user: string;
    /**
     * 
     * @type {number}
     * @memberof DpayPurchase
     */
    price: number;
    /**
     * 
     * @type {number}
     * @memberof DpayPurchase
     */
    fiat: number;
    /**
     * 
     * @type {number}
     * @memberof DpayPurchase
     */
    dpay: number;
    /**
     * 
     * @type {number}
     * @memberof DpayPurchase
     */
    expiresAt: number;
    /**
     * 
     * @type {boolean}
     * @memberof DpayPurchase
     */
    isComplete: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DpayPurchase
     */
    isPending: boolean;
    /**
     * 
     * @type {string}
     * @memberof DpayPurchase
     */
    dpayTransaction?: string;
    /**
     * 
     * @type {string}
     * @memberof DpayPurchase
     */
    paymentMethodId?: string;
    /**
     * 
     * @type {string}
     * @memberof DpayPurchase
     */
    paymentIntentId?: string;
    /**
     * 
     * @type {string}
     * @memberof DpayPurchase
     */
    experienceRewardsTransaction?: string;
    /**
     * 
     * @type {string}
     * @memberof DpayPurchase
     */
    errorMessage?: string;
}

export function DpayPurchaseFromJSON(json: any): DpayPurchase {
    return DpayPurchaseFromJSONTyped(json, false);
}

export function DpayPurchaseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DpayPurchase {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'user': json['user'],
        'price': json['price'],
        'fiat': json['fiat'],
        'dpay': json['dpay'],
        'expiresAt': json['expiresAt'],
        'isComplete': json['isComplete'],
        'isPending': json['isPending'],
        'dpayTransaction': !exists(json, 'dpayTransaction') ? undefined : json['dpayTransaction'],
        'paymentMethodId': !exists(json, 'paymentMethodId') ? undefined : json['paymentMethodId'],
        'paymentIntentId': !exists(json, 'paymentIntentId') ? undefined : json['paymentIntentId'],
        'experienceRewardsTransaction': !exists(json, 'experienceRewardsTransaction') ? undefined : json['experienceRewardsTransaction'],
        'errorMessage': !exists(json, 'errorMessage') ? undefined : json['errorMessage'],
    };
}

export function DpayPurchaseToJSON(value?: DpayPurchase | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'user': value.user,
        'price': value.price,
        'fiat': value.fiat,
        'dpay': value.dpay,
        'expiresAt': value.expiresAt,
        'isComplete': value.isComplete,
        'isPending': value.isPending,
        'dpayTransaction': value.dpayTransaction,
        'paymentMethodId': value.paymentMethodId,
        'paymentIntentId': value.paymentIntentId,
        'experienceRewardsTransaction': value.experienceRewardsTransaction,
        'errorMessage': value.errorMessage,
    };
}


