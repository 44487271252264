import {useMutation, useQueryClient} from "@tanstack/react-query";
import {ApiError, MenuOrdersApi} from "@devour/client";
import getConfig from "@/utils/getConfig";
import {addError} from "@/redux/meta/metaActions";
import {useDispatch} from "react-redux";

/**
 * Update a menu order's scheduled time, or delivery notes
 *
 * @param menuOrderId
 */
export function useUpdateMenuOrderScheduleTime(menuOrderId: string) {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (unix: number) => {

            const newMenuOrder = await new MenuOrdersApi(getConfig()).updateMenuOrder({
                id: menuOrderId,
                createMenuOrderBody: {
                    scheduledTime: unix,
                },
            });

            // Update the menuOrder data cache with the new updated menu order
            // No need refetchMenuOrder. This eliminates the need to
            // refetch the menu order from the database, increasing speed
            queryClient.setQueryData([
                "menuOrder",
                menuOrderId,
            ], newMenuOrder);
            return newMenuOrder;
        },
        onError: async (e) => dispatch(await addError(e as ApiError)),
    });
}