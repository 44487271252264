/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Asset,
    AssetFromJSON,
    AssetFromJSONTyped,
    AssetToJSON,
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
    TimeStamps,
    TimeStampsFromJSON,
    TimeStampsFromJSONTyped,
    TimeStampsToJSON,
} from './';

/**
 * Represents a category / tag for the business.
 * @export
 * @interface BusinessTaxonomy
 */
export interface BusinessTaxonomy {
    /**
     * 
     * @type {string}
     * @memberof BusinessTaxonomy
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof BusinessTaxonomy
     */
    createdAt: number;
    /**
     * 
     * @type {number}
     * @memberof BusinessTaxonomy
     */
    updatedAt: number;
    /**
     * 
     * @type {Asset}
     * @memberof BusinessTaxonomy
     */
    image?: Asset;
    /**
     * 
     * @type {string}
     * @memberof BusinessTaxonomy
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessTaxonomy
     */
    description?: string;
    /**
     * Sort order field descending
     * @type {number}
     * @memberof BusinessTaxonomy
     */
    priority: number;
}

export function BusinessTaxonomyFromJSON(json: any): BusinessTaxonomy {
    return BusinessTaxonomyFromJSONTyped(json, false);
}

export function BusinessTaxonomyFromJSONTyped(json: any, ignoreDiscriminator: boolean): BusinessTaxonomy {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'image': !exists(json, 'image') ? undefined : AssetFromJSON(json['image']),
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'priority': json['priority'],
    };
}

export function BusinessTaxonomyToJSON(value?: BusinessTaxonomy | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'image': AssetToJSON(value.image),
        'name': value.name,
        'description': value.description,
        'priority': value.priority,
    };
}


