// Generates an array of the next 30 dates to display
// on the schedule a pickup/delivery time modal (CheckoutDetailsScheduleTimes)
import {DateInfo} from "@/components/checkout/checkoutDetails/CheckoutDetailsScheduleTimes";
import moment from "moment-timezone";

export function generateDates(userTimeZone): DateInfo[] {
    const dates: Array<DateInfo> = [];
    for (let i = 0; i < 30; i++) {
        const date = moment().tz(userTimeZone)
            .add(i, "days");

        let formattedDate;

        if (i === 0) {
            formattedDate = date.format("MMM D,") + "Today";
        } else if (i === 1) {
            formattedDate = date.format("MMM D,") + "Tomorrow";
        } else {
            formattedDate = date.format("MMM D,dddd"); // e.g., "Jan,5,Sunday"
        }

        const [
            month,
            day,
        ] = formattedDate.split(","); // Split string into an array: ["Feb 5", "Monday"]

        dates.push({
            month,
            day,
            unix: date.unix(),
        });
    }
    return dates;
}
