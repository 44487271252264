const formatter = new Intl.NumberFormat("en-us", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    useGrouping: true,
});

export function formatCurrency(value: any): string {
    return value !== undefined
        ? formatter.format(value)
        : "-";
}

/**
 * Format the price string.
 * Need to add comma for thousands separator.
 * Need to maintain the same number of decimal places.
 * @param price
 * @param precision
 */
export function formatPriceAddCommaPreserveDecimals(price: string | number, precision: number = 2): string {
    if (!price && price !== 0) {
        return "";
    }
    // Round 1: Convert to number and render locale format, which adds comma separator for thousands but strips trailing zeros.
    const priceNumber: number = Number(price);
    const priceComma: string = priceNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // Round 2: Split it at the decimal and grab the first half for the whole number with the comma.
    const priceWholeNumber: string = priceComma.split(".")[0];
    // Round 3: Check if original price string includes a period.
    // If so, split it at the decimal and grab the second half for the decimals.
    let priceDecimal: string | null = null;
    if (precision) {
        priceDecimal = priceNumber.toFixed(precision).split(".")[1];
    }
    // Final round: Super-glue it back together with a period (if one existed in the original price string).
    return priceDecimal
        ? `${priceWholeNumber}.${priceDecimal}`
        : priceComma;
}
