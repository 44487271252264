import FrameButton from "@/components/buttons/FrameButton";
import {ButtonHTMLAttributes, Dispatch, SetStateAction} from "react";
import {isDesktop, isMobile, isTablet} from "react-device-detect";
import {FaChevronRight} from "react-icons/fa";
import RestaurantCard from "@/pages/restaurants/components/RestaurantCard";
import {ViewStates} from "@/pages/restaurants/RestaurantSearchResultsPage";
import {useParams} from "react-router";
import {NftOwnershipInformation, RestaurantSearchResult} from "@devour/client";
import {RestaurantSearchFiltersQuery} from "@/utils/restaurantsSearchFilter";


interface Props {
    restaurants: Array<RestaurantSearchResult>;
    setViewState: Dispatch<SetStateAction<ViewStates>>;
    restaurantFilters: RestaurantSearchFiltersQuery;
    nftOwnershipResponse: NftOwnershipInformation;
}
function RestaurantSearchOrderAgain(props: Props) {
    const {placeId} = useParams<{ placeId: string; }>();

    if (!props.restaurants?.length) {
        return null;
    }

    return (
        <div className="restaurants-search-results-page_order-again">
            <div className="restaurants-search-results-page_order-again_header">
                <h3>Order Again</h3>
                <FrameButton
                    <ButtonHTMLAttributes<HTMLButtonElement>>
                    color="white-drop-shadow"
                    size={isMobile && !isTablet
                        ? "icon"
                        : "narrow"}
                    leftIcon={isMobile && !isTablet
                        ? FaChevronRight
                        : undefined}
                    className="restaurants-search-results-page_order-again_header_button"
                    onClick={() => props.setViewState(ViewStates.ORDER_AGAIN)}
                >
                    {isDesktop || isTablet
                        ? "View All"
                        : ""}
                </FrameButton>
            </div>
            <div className="restaurants-search-results-page_list">
                {props.restaurants.map(restaurant => <div key={restaurant._id} className="restaurants-search-results-page_list_item">
                    <RestaurantCard
                        placeId={placeId}
                        restaurant={restaurant}
                        restaurantFilters={props.restaurantFilters}
                        nftOwnershipResponse={props.nftOwnershipResponse}
                        showPromos="auto"
                    />
                </div>)}
            </div>
        </div>
    );
}

export default RestaurantSearchOrderAgain;