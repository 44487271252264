/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GleamEntryUpload,
    GleamEntryUploadFromJSON,
    GleamEntryUploadFromJSONTyped,
    GleamEntryUploadToJSON,
} from './';

/**
 * 
 * @export
 * @interface GleamEntry
 */
export interface GleamEntry {
    /**
     * 
     * @type {number}
     * @memberof GleamEntry
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof GleamEntry
     */
    entry_method_id: number;
    /**
     * 
     * @type {string}
     * @memberof GleamEntry
     */
    action: string;
    /**
     * 
     * @type {string}
     * @memberof GleamEntry
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof GleamEntry
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof GleamEntry
     */
    value: string;
    /**
     * 
     * @type {number}
     * @memberof GleamEntry
     */
    worth: number;
    /**
     * 
     * @type {string}
     * @memberof GleamEntry
     */
    landing_url: string;
    /**
     * 
     * @type {string}
     * @memberof GleamEntry
     */
    referring_url: string;
    /**
     * 
     * @type {GleamEntryUpload}
     * @memberof GleamEntry
     */
    upload: GleamEntryUpload;
}

export function GleamEntryFromJSON(json: any): GleamEntry {
    return GleamEntryFromJSONTyped(json, false);
}

export function GleamEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): GleamEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'entry_method_id': json['entry_method_id'],
        'action': json['action'],
        'created_at': json['created_at'],
        'type': json['type'],
        'value': json['value'],
        'worth': json['worth'],
        'landing_url': json['landing_url'],
        'referring_url': json['referring_url'],
        'upload': GleamEntryUploadFromJSON(json['upload']),
    };
}

export function GleamEntryToJSON(value?: GleamEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'entry_method_id': value.entry_method_id,
        'action': value.action,
        'created_at': value.created_at,
        'type': value.type,
        'value': value.value,
        'worth': value.worth,
        'landing_url': value.landing_url,
        'referring_url': value.referring_url,
        'upload': GleamEntryUploadToJSON(value.upload),
    };
}


