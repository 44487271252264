/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrderRefundReason,
    OrderRefundReasonFromJSON,
    OrderRefundReasonFromJSONTyped,
    OrderRefundReasonToJSON,
    OrderRefundResponsibility,
    OrderRefundResponsibilityFromJSON,
    OrderRefundResponsibilityFromJSONTyped,
    OrderRefundResponsibilityToJSON,
} from './';

/**
 * 
 * @export
 * @interface OrderRefundBody
 */
export interface OrderRefundBody {
    /**
     * 
     * @type {OrderRefundReason}
     * @memberof OrderRefundBody
     */
    reason?: OrderRefundReason;
    /**
     * 
     * @type {OrderRefundResponsibility}
     * @memberof OrderRefundBody
     */
    responsibility?: OrderRefundResponsibility;
    /**
     * 
     * @type {string}
     * @memberof OrderRefundBody
     */
    notes?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderRefundBody
     */
    fiat?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderRefundBody
     */
    dpay?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderRefundBody
     */
    rewardsClawback?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderRefundBody
     */
    responsiblePartyAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderRefundBody
     */
    transferReverseAmount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof OrderRefundBody
     */
    discountNullify?: boolean;
}

export function OrderRefundBodyFromJSON(json: any): OrderRefundBody {
    return OrderRefundBodyFromJSONTyped(json, false);
}

export function OrderRefundBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderRefundBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reason': !exists(json, 'reason') ? undefined : OrderRefundReasonFromJSON(json['reason']),
        'responsibility': !exists(json, 'responsibility') ? undefined : OrderRefundResponsibilityFromJSON(json['responsibility']),
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'fiat': !exists(json, 'fiat') ? undefined : json['fiat'],
        'dpay': !exists(json, 'dpay') ? undefined : json['dpay'],
        'rewardsClawback': !exists(json, 'rewardsClawback') ? undefined : json['rewardsClawback'],
        'responsiblePartyAmount': !exists(json, 'responsiblePartyAmount') ? undefined : json['responsiblePartyAmount'],
        'transferReverseAmount': !exists(json, 'transferReverseAmount') ? undefined : json['transferReverseAmount'],
        'discountNullify': !exists(json, 'discountNullify') ? undefined : json['discountNullify'],
    };
}

export function OrderRefundBodyToJSON(value?: OrderRefundBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reason': OrderRefundReasonToJSON(value.reason),
        'responsibility': OrderRefundResponsibilityToJSON(value.responsibility),
        'notes': value.notes,
        'fiat': value.fiat,
        'dpay': value.dpay,
        'rewardsClawback': value.rewardsClawback,
        'responsiblePartyAmount': value.responsiblePartyAmount,
        'transferReverseAmount': value.transferReverseAmount,
        'discountNullify': value.discountNullify,
    };
}


