/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ChangePasswordBody
 */
export interface ChangePasswordBody {
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordBody
     */
    oldPassword: string;
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordBody
     */
    newPassword: string;
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordBody
     */
    confirmPassword: string;
}

export function ChangePasswordBodyFromJSON(json: any): ChangePasswordBody {
    return ChangePasswordBodyFromJSONTyped(json, false);
}

export function ChangePasswordBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangePasswordBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'oldPassword': json['oldPassword'],
        'newPassword': json['newPassword'],
        'confirmPassword': json['confirmPassword'],
    };
}

export function ChangePasswordBodyToJSON(value?: ChangePasswordBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'oldPassword': value.oldPassword,
        'newPassword': value.newPassword,
        'confirmPassword': value.confirmPassword,
    };
}


