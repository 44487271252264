import { useEffect } from "react";

const eventBus = new EventTarget();
const logInEvent = "logged-in-from-dialog";
const registerEvent = "signed-up-from-dialog";
export const useOnLogin = (callback: (newUser: boolean) => void, deps: React.DependencyList = []) => {
    useEffect(() => {
        eventBus.addEventListener(logInEvent, () => callback(false));
        eventBus.addEventListener(registerEvent, () => callback(true));
        return () => {
            eventBus.removeEventListener(logInEvent, () => callback(false));
            eventBus.addEventListener(registerEvent, () => callback(true));
        };
    }, deps);

};


export const emitLoginEvent = () => {
    eventBus.dispatchEvent(new Event(logInEvent));
};

export const emitRegisterEvent = () => {
    eventBus.dispatchEvent(new Event(registerEvent));
};
