import {FC, PropsWithChildren, ReactElement, useState, useRef, useEffect, MouseEvent as ReactMouseEvent} from "react";
import classNames from "classnames";
import {isDesktop} from "react-device-detect";

interface Props {
    className?: string;
    outsideContent?: ReactElement;
    outsideIcon?: FC;
    insideIcon?: FC;
    containerElementClassname?: string;
}

interface Position {
    left?: number | string;
    top?: number | string;
    bottom?: number | string;
    right?: number | string;
}

function Tooltip(props: PropsWithChildren<Props>): ReactElement {

    const OutsideIcon: FC = props.outsideIcon;
    const InsideIcon: FC = props.insideIcon;
    const [lockTooltip, setLockTooltip] = useState<boolean>(false);
    const [hoverTooltip, setHoverTooltip] = useState<boolean>(false);
    const [position, setPosition] = useState<Position>({});
    const tooltipRef = useRef<HTMLDivElement>(null);
    const tooltipContentRef = useRef<HTMLDivElement>(null);
    const [containerElement, setContainerElement] = useState<HTMLElement>(undefined);

    // get container's width, right edge and padding if it exists
    const containerWidth = containerElement?.offsetWidth;
    const containerRight = containerElement ? containerElement.getBoundingClientRect().right : document.body.clientWidth;

    // const containerHeight = containerElement?.offsetHeight;
    const containerBottom = containerElement ? containerElement.getBoundingClientRect().bottom : document.body.clientHeight;

    let containerRightPadding = 0;
    let containerBottomPadding = 0;

    if (containerElement) {
        const containerComputedStyle = window.getComputedStyle(containerElement);
        containerRightPadding = parseInt(containerComputedStyle.getPropertyValue("padding-right"), 10);
        containerBottomPadding = parseInt(containerComputedStyle.getPropertyValue("padding-bottom"), 10);
    }

    useEffect(() => {
        if (tooltipRef.current && props.containerElementClassname) {
            const element: HTMLElement = tooltipRef.current.closest(`.${props.containerElementClassname}`);
            setContainerElement(element);
        }
    }, [props.containerElementClassname]);

    useEffect(() => {
        function unlockTooltip(e: MouseEvent): void {
            if (!tooltipRef.current?.contains(e.target as Node) && lockTooltip) {
                e.stopPropagation();
                setLockTooltip(false);
            }
        }

        document.addEventListener("touchstart", unlockTooltip);
        document.addEventListener("mousedown", unlockTooltip);

        return () => {
            document.removeEventListener("touchstart", unlockTooltip);
            document.removeEventListener("mousedown", unlockTooltip);
        };
    }, [lockTooltip]);

    function calculatePosition(): void {
        if (!tooltipContentRef.current || !tooltipRef.current) {
            return;
        }

        const tooltipContentWidth = tooltipContentRef.current.offsetWidth;
        const tooltipContentHeight = tooltipContentRef.current.offsetHeight;

        const tooltipLeft = tooltipRef.current.getBoundingClientRect().left;
        const tooltipTop = tooltipRef.current.getBoundingClientRect().top;

        const newPosition: Position = {};

        // if the content goes off the container, shift left
        if (tooltipLeft + tooltipContentWidth >= containerRight - containerRightPadding) {
            newPosition.left = -(tooltipLeft + tooltipContentWidth - containerRight) - containerRightPadding;
        }

        if (tooltipTop + tooltipContentHeight > containerBottom - containerBottomPadding) {
            newPosition.bottom = "calc(100% + 0.47rem)";
            newPosition.top = "unset";
        }
        setPosition(newPosition);
    }

    function toggleLockTooltip(e: ReactMouseEvent): void {
        e.stopPropagation();
        calculatePosition();
        setLockTooltip(s => !s);
    }

    function toggleHoverTooltip(): void {
        if (isDesktop) {
            calculatePosition();
            setHoverTooltip(s => !s);
        }
    }

    return (
        <div
            className={classNames("tooltip", props.className, {
                "is-lock": lockTooltip,
                "is-hover": hoverTooltip,
            })}
            onClick={toggleLockTooltip}
            onMouseEnter={toggleHoverTooltip}
            onMouseLeave={toggleHoverTooltip}
            ref={tooltipRef}
        >
            {props.outsideContent ?? <div className="tooltip_icon"><OutsideIcon/></div>}
            <div
                className="tooltip_content"
                ref={tooltipContentRef}
                // reset maxWidth if needed
                style={{
                    ...position,
                    ...containerWidth && {maxWidth: containerWidth - 2 * containerRightPadding},
                }}
            >
                {props.insideIcon && <InsideIcon />}
                <div>
                    {props.children}
                </div>
            </div>
        </div>
    );
}

export default Tooltip;
