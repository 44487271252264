/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RelationshipType,
    RelationshipTypeFromJSON,
    RelationshipTypeFromJSONTyped,
    RelationshipTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface ReferralLinkTextBody
 */
export interface ReferralLinkTextBody {
    /**
     * 
     * @type {string}
     * @memberof ReferralLinkTextBody
     */
    header?: string;
    /**
     * 
     * @type {string}
     * @memberof ReferralLinkTextBody
     */
    description?: string;
    /**
     * 
     * @type {RelationshipType}
     * @memberof ReferralLinkTextBody
     */
    relationshipType: RelationshipType;
}

export function ReferralLinkTextBodyFromJSON(json: any): ReferralLinkTextBody {
    return ReferralLinkTextBodyFromJSONTyped(json, false);
}

export function ReferralLinkTextBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReferralLinkTextBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'header': !exists(json, 'header') ? undefined : json['header'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'relationshipType': RelationshipTypeFromJSON(json['relationshipType']),
    };
}

export function ReferralLinkTextBodyToJSON(value?: ReferralLinkTextBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'header': value.header,
        'description': value.description,
        'relationshipType': RelationshipTypeToJSON(value.relationshipType),
    };
}


