/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Referral
 */
export interface Referral {
    /**
     * 
     * @type {string}
     * @memberof Referral
     */
    referrerId: string;
    /**
     * 
     * @type {string}
     * @memberof Referral
     */
    referrerEmail: string;
    /**
     * 
     * @type {number}
     * @memberof Referral
     */
    count?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof Referral
     */
    referredUsers: Array<string>;
}

export function ReferralFromJSON(json: any): Referral {
    return ReferralFromJSONTyped(json, false);
}

export function ReferralFromJSONTyped(json: any, ignoreDiscriminator: boolean): Referral {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'referrerId': json['referrerId'],
        'referrerEmail': json['referrerEmail'],
        'count': !exists(json, 'count') ? undefined : json['count'],
        'referredUsers': json['referredUsers'],
    };
}

export function ReferralToJSON(value?: Referral | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'referrerId': value.referrerId,
        'referrerEmail': value.referrerEmail,
        'count': value.count,
        'referredUsers': value.referredUsers,
    };
}


