/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LeaderboardsDateRange,
    LeaderboardsDateRangeFromJSON,
    LeaderboardsDateRangeFromJSONTyped,
    LeaderboardsDateRangeToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetLeaderboardsDateRangeResponse
 */
export interface GetLeaderboardsDateRangeResponse {
    /**
     * 
     * @type {Array<LeaderboardsDateRange>}
     * @memberof GetLeaderboardsDateRangeResponse
     */
    dates: Array<LeaderboardsDateRange>;
}

export function GetLeaderboardsDateRangeResponseFromJSON(json: any): GetLeaderboardsDateRangeResponse {
    return GetLeaderboardsDateRangeResponseFromJSONTyped(json, false);
}

export function GetLeaderboardsDateRangeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetLeaderboardsDateRangeResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dates': ((json['dates'] as Array<any>).map(LeaderboardsDateRangeFromJSON)),
    };
}

export function GetLeaderboardsDateRangeResponseToJSON(value?: GetLeaderboardsDateRangeResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dates': ((value.dates as Array<any>).map(LeaderboardsDateRangeToJSON)),
    };
}


