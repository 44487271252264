import React, {ReactElement} from "react";

interface Props {
    message: React.JSX.Element | string;
}
function GoFrensNoResultsGraphic(props: Props): ReactElement {
    return (
        <div className="gofrens-no-results">
            <img
                src={`${import.meta.env.VITE_CDN_URL}/images/no-results-question-mark.webp`}
                alt="The Industry Collection"
            />
            <p className="gofrens-no-results_title">Oops, Nothing Found!</p>
            <p className="gofrens-no-results_subtitle">{props.message}</p>
        </div>
    );
}

export default GoFrensNoResultsGraphic;