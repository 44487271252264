import {ReactElement, useContext, useEffect, useState} from "react";
import {
    Address,
    HandoffOptions,
    Restaurant,
    ValidateDeliveryDistanceReason,
    ValidateDeliveryDistanceResponse,
} from "@devour/client";
import Skeleton from "react-loading-skeleton";
import {BsDot, BsExclamationCircle} from "react-icons/bs";
import RestaurantHoursSummary from "@/pages/restaurants/components/RestaurantHoursSummary";
import RestaurantPageTaxonomies from "@/pages/restaurants/components/RestaurantPageTaxonomies";
import RestaurantAddressEntryPrompt from "@/components/restaurants/RestaurantAddressEntryPrompt";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {updateLastSearchedPlaceId} from "@/redux/meta/metaActions";
import {useNavigate} from "react-router-dom";
import {RestaurantContext} from "@/pages/restaurants/context/RestaurantContext";

interface Props {
    data: Restaurant;
    placeId: string;
    validateDeliveryDistanceResponse?: ValidateDeliveryDistanceResponse;
}

function RestaurantHeaderDefault(props: Props): ReactElement {

    const dispatch = useDispatch();
    const history = useNavigate();
    const [
        forceShowAddressUiAfterGettingPlaceIdFromRedux,
        setForceShowAddressUiAfterGettingPlaceIdFromRedux,
    ] = useState(false);
    const handoff = useSelector((store: IStore) => store.metaStore.handoff);
    const {embeddedMenu} = useContext(RestaurantContext);

    useEffect(() => {
        if (!props.placeId) {
            setForceShowAddressUiAfterGettingPlaceIdFromRedux(true);
        }
    }, [
        props.placeId,
        props.data,
    ]);

    function handleAddressPromptComplete(_address: Address, _placeId: string): void {
        dispatch(updateLastSearchedPlaceId(_placeId));

        if (props.data && !embeddedMenu) {
            history(`${props.data?.url}/${_placeId}`, {replace: true});
        }
    }

    return (
        <div className="restaurant-page_header_default-margin-adjust restaurant-page_section-margin">
            <div className="restaurant-page_title">
                {props.data
                    ? <h2>{props.data?.name}</h2>
                    : <h2 className="restaurant-menu-page-content-skeleton_title"><Skeleton /></h2>
                }
            </div>

            <div className="restaurant-page_hours">
                {!props.data
                    ? <div className="restaurant-menu-page-hour-skeleton react-loading-skeleton"/>
                    : <>
                        {!props?.data?.isOpen &&
                        <>
                            <div className="restaurant-page_hours_now-closed-text">
                                    Now Closed
                            </div>
                            <BsDot/>
                        </>
                        }
                        <RestaurantHoursSummary restaurant={props.data}/>
                    </>
                }
            </div>

            <div className="restaurant-page_details">
                <RestaurantPageTaxonomies/>
            </div>

            {handoff === HandoffOptions.DELIVERY &&
            props.validateDeliveryDistanceResponse &&
            !props.validateDeliveryDistanceResponse.canDeliver &&
            props.validateDeliveryDistanceResponse.reason === ValidateDeliveryDistanceReason.TooFar &&
            <div className="restaurant-page_too-far">
                <p>
                    <BsExclamationCircle/>
                            You are too far away to order delivery from this restaurant.
                </p>
            </div>

            }
            {(!props.placeId || forceShowAddressUiAfterGettingPlaceIdFromRedux) && handoff === HandoffOptions.DELIVERY &&
            <div className="restaurant-header-handoff-opts_handoff_prompt-address">
                <RestaurantAddressEntryPrompt
                    onSetAddress={handleAddressPromptComplete}
                />
            </div>
            }
        </div>
    );
}

export default RestaurantHeaderDefault;
