/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum WithdrawStatus {
    PENDING = 'PENDING',
    COMPLETED = 'COMPLETED',
    REJECTED = 'REJECTED',
    CANCELLED = 'CANCELLED'
}

export function WithdrawStatusFromJSON(json: any): WithdrawStatus {
    return WithdrawStatusFromJSONTyped(json, false);
}

export function WithdrawStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): WithdrawStatus {
    return json as WithdrawStatus;
}

export function WithdrawStatusToJSON(value?: WithdrawStatus | null): any {
    return value as any;
}

