import {ReactElement} from "react";
import Spacer from "../Spacer";
import {FiChevronRight} from "react-icons/fi";
import {truncateText} from "../../utils/truncateText";
import {GleamCompetitionAction} from "@devour/client";

interface Props {
    action: GleamCompetitionAction;
    toggle: () => void;
}

function GoVipShare2EarnActionCard(props: Props): ReactElement {

    return (
        <div
            className="go-vip-share2earn_action-card go-vip-share2earn_action-card_hover"
            onClick={props.toggle}
        >
            <div className="go-vip-share2earn_action-card_content">

                <div
                    className="go-vip-levels_body_cards-container_card_square go-vip-levels_body_cards-container_card_square_enabled">
                    <div className="go-vip-levels_body_cards-container_card_square_gleam">
                        <h4>{`+${10 * props.action.entries}`}</h4>
                        <p>pts</p>
                    </div>
                </div>

                <div className="go-vip-share2earn_action-card_content_description">
                    <p className="go-vip-share2earn_action-card_content_description_header">
                        <strong>{truncateText(25, props.action.name)}</strong>
                    </p>
                    <p className="go-vip-share2earn_action-card_content_description_entries">
                        {`Earn ${10 * props.action.entries} points!`}
                    </p>
                </div>

                <Spacer/>

                <FiChevronRight className="go-vip-top_header_dpay-card_content_chevron"/>

            </div>
        </div>
    );
}

export default GoVipShare2EarnActionCard;
