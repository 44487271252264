import React, {ReactElement} from "react";
import classNames from "classnames";
import FrameButton from "@/components/buttons/FrameButton";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {FaArrowLeft} from "react-icons/fa";
import {useNavigate} from "react-router";
import {toggleSidebar} from "@/redux/meta/metaActions";

interface Props {
    isMobileCard?: boolean;
}

function BrandGoBackCard(props: Props): ReactElement {
    const {isMobileCard} = props;
    const lastBrandMap = useSelector((state: IStore) => state.metaStore.lastBrandMap);
    const hero = lastBrandMap?.bannerImage?.image.url;
    const icon = lastBrandMap?.titleImage?.url || lastBrandMap?.promoCartHero?.url;
    const navigate = useNavigate();
    const dispatch = useDispatch();

    function handleGoBack() {
        dispatch(toggleSidebar(false));
        navigate(`/brands/${lastBrandMap?.slug}`);
    }

    if (!lastBrandMap) {
        return null;
    }

    if (isMobileCard) {
        return (
            <div
                className="brand-go-back-card_mobile"
                style={{
                    "--hero-background-url": `url(${hero})`,
                } as React.CSSProperties}
            >
                <span className="brand-go-back-card_mobile_title">{lastBrandMap.name}</span>
                <FrameButton
                    color={"gray-light"}
                    size="normal"
                    className="brand-go-back-card_button brand-go-back-card_mobile_button"
                    onClick={handleGoBack}
                >
                    <FaArrowLeft className="back-arrow"/> Go Back
                </FrameButton>
            </div>
        );
    }

    return (
        <>
            <div
                className={classNames("brand-go-back-card")}
                onClick={handleGoBack}
            >
                <div className="brand-go-back-card_graphics">
                    <div className={classNames(
                        "brand-go-back-card_graphics_hero",
                        {"no-hero": !hero},
                    )}>
                        {hero &&
                            <img
                                src={hero}
                                alt={`${lastBrandMap.name} hero`}
                                className="brand-go-back-card_graphics_hero_img"
                            />
                        }
                    </div>
                    <div className="brand-go-back-card_graphics_icon">
                        {icon &&
                            <img
                                src={icon}
                                alt={`${lastBrandMap.name} icon`}
                            />
                        }
                    </div>
                </div>

                <div className="brand-go-back-card_body">
                    <div className="brand-go-back-card_body_description">
                        <p className="brand-go-back-card_body_description_name">{lastBrandMap.name}</p>
                        <FrameButton
                            color={"gray-light"}
                            size="normal"
                            className="brand-go-back-card_button"
                            onClick={handleGoBack}
                        >
                            <FaArrowLeft className="back-arrow"/> Go Back
                        </FrameButton>
                    </div>

                </div>
            </div>
        </>
    );
}

export default BrandGoBackCard;
