import {ReactElement, useEffect, useState} from "react";
import FrameOneModal from "@/components/modals/modalComponents/FrameOneModal";
import {FaChevronLeft} from "react-icons/fa6";
import {HandoffOptions} from "@devour/client";
import moment from "moment-timezone";
import {useParams} from "react-router";
import {useMenuOrder} from "@/hooks/menuOrder/useMenuOrder";
import {generateTimeSlots, Slot} from "@/utils/CheckoutDetailsScheduleTime/generateTimeSlots";
import {generateDates} from "@/utils/CheckoutDetailsScheduleTime/generateDates";
import classNames from "classnames";
import {useUpdateMenuOrderScheduleTime} from "@/hooks/menuOrder/useUpdateMenuOrderScheduleTime";
import {combineTimesToUnix} from "@/utils/CheckoutDetailsScheduleTime/combineTimesToUnix";
import {useRestaurant} from "@/hooks/useRestaurant";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    mode: string;
}

export interface DateInfo {
    day: string;
    month: string;
    unix: number;
}

function CheckoutDetailsScheduleTimes(props: Props): ReactElement {
    const {menuOrderId} = useParams<{ menuOrderId: string; }>();
    const {data: menuOrder} = useMenuOrder(menuOrderId);
    const {data: restaurant} = useRestaurant(menuOrder?.business);
    const {
        mutate: updateMenuOrderScheduleTime,
    } = useUpdateMenuOrderScheduleTime(menuOrderId);

    const restaurantTimeZone = restaurant?.timeZone || moment.tz.guess();

    const [
        timeSlots,
        setTimeSlots,
    ] = useState([]);
    const [
        selectedDate,
        setSelectedDate,
    ] = useState<DateInfo>({
        day: "Today",
        month: moment().tz(restaurantTimeZone)
            .format("MMM D"),
        unix: moment().tz(restaurantTimeZone)
            .unix(),
    });

    // Generate 30 days of dates to map/display
    const dates = generateDates(restaurantTimeZone);

    // Regenerate timeslots whenever the selected date changes
    useEffect(() => {
        if (restaurant) {
            setTimeSlots(generateTimeSlots(restaurant, selectedDate));
        }
    }, [selectedDate]);

    /**
     * Upon time slot click, close the modal, skeleton the content, and update the scheduled time
     */
    function handleSlotClick(slot: Slot) {
        const unix = combineTimesToUnix(selectedDate.unix, slot.scheduleTime, restaurantTimeZone);
        props.onClose();
        updateMenuOrderScheduleTime(unix);
    }

    /**
     * Renders 30 days of dates from today
     */
    function renderDates() {
        return dates.map((date, index) => <div
            key={index + date.unix}
            className={classNames("checkout-details_dates_dateDiv", {
                "checkout-details_dates_dateDiv_selected": date.day === selectedDate.day,
            })}
            onClick={() => setSelectedDate(date)}>

            <div className="checkout-details_dates_date">
                <p> {date.day} </p>
                <p className="checkout-details_dates_month">
                    {date.month}
                </p>
            </div>
        </div>);
    }

    /**
     * Renders the time slots for a given date
     */
    function renderTimeSlots() {
        if (!timeSlots.length) {
            return [
                <p>
                    No times are available for this day.
                </p>,
            ];
        }
        return timeSlots.map((slot, index) => <label
            key={index + slot.formattedSlot}
            className="checkout-details_slots-label">
            <input
                type="radio"
                name="slots"
                value={slot}
                className="checkout-details_slots-label_slots-input"
                onClick={() => handleSlotClick(slot)}
            />
            <p>{slot.formattedSlot}</p>
        </label>);
    }

    return (
        <FrameOneModal
            isOpen={props.isOpen}
            toggle={props.onClose}
            size="xs"
            contentClassName="checkout-details_schedule-modal"
        >

            <div className="checkout-details_schedule-modal_header">
                <button
                    onClick={props.onClose}
                    className="checkout-details_schedule-modal_times-back"
                >
                    <FaChevronLeft className="checkout-details_schedule-modal_back"/>
                </button>

                <p>Schedule {menuOrder?.handoff === HandoffOptions.DELIVERY
                    ? "delivery"
                    : "pickup"}</p>
            </div>

            <hr className="checkout-details_schedule-modal_line checkout-details_schedule-modal_line_schedule-time"/>

            <div className="checkout-details_dates">
                {renderDates()}
            </div>

            {renderTimeSlots()}
        </FrameOneModal>
    );
}

export default CheckoutDetailsScheduleTimes;
