import {useQuery} from "@tanstack/react-query";
import {DevourIQApi, DevourIQGetStoreAvailabilityResponse, Restaurant} from "@devour/client";

async function fetchRestaurantAvailability(restaurantId: string): Promise<DevourIQGetStoreAvailabilityResponse> {
    return await new DevourIQApi().devourIQRestaurantStatus({
        id: restaurantId,
    });
}

function restaurantAvailabilityQuery(restaurant: Restaurant) {
    return {
        queryKey: [
            "restaurant-availability",
            restaurant?.id,
        ],
        queryFn: () => fetchRestaurantAvailability(restaurant?.id),
        keepPreviousData: true,
        staleTime: 15 * 60 * 1000, // 15 minute cache
        refetchOnWindowFocus: false,
        enabled: !!(restaurant?.isDevourIQ && restaurant?.id),
    } as const;
}

export function useRestaurantAvailability(restaurant: Restaurant) {
    // const queryClient = useQueryClient();

    return useQuery(restaurantAvailabilityQuery(restaurant));

}

export function isRestaurantAvailable(response: DevourIQGetStoreAvailabilityResponse): boolean {
    // This is only a supplementary check for locations that closed unexpectedly.
    // Default to open if check is not complete to prevent "blockers" from triggering.
    // Could be it is not a DevourIQ location or the API is loading.
    if (!response) {
        return true;
    }
    return response.isOpen;
}
