import {GetMenuResponse, MenuOrderItem} from "@devour/client";
import {findMenuItemResponse} from "@/utils/validateMenuOrderItem";

const getRestaurantMenuItemImage = (restaurantMenu: GetMenuResponse, menuOrderItem: MenuOrderItem): string => {
    if (!restaurantMenu) {
        return import.meta.env.VITE_CDN_URL + "/images/empty.png";
    }
    const menuItem = findMenuItemResponse(menuOrderItem, restaurantMenu.menus);
    return menuItem?.images?.[0]?.url || import.meta.env.VITE_CDN_URL + "/images/empty.png";
};
export default getRestaurantMenuItemImage;