import { IStore } from "@/redux/defaultStore";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const useClosePopups = (closeFunction: (event: MouseEvent, isModalActive: number) => void) => {

    const isModalActive = useSelector((store: IStore) => store.metaStore.activeModals);
    const handleClose = (event: MouseEvent) => {
        closeFunction(event, isModalActive);
    };
    useEffect(() => {
        // Handle both touch and mouse events
        document.addEventListener("mousedown", handleClose);
        document.addEventListener("touchstart", handleClose);

        return () => {
            document.removeEventListener("mousedown", handleClose);
            document.removeEventListener("touchstart", handleClose);
        };
    }, [isModalActive]);

};

export default useClosePopups;