import {PropsWithChildren, ReactElement} from "react";
import classNames from "classnames";

interface Props<T> {
    value: T; // value used to help parent form control values, ie "Checkbox 1", "Checkbox 2", etc. For configuring if this radio button is "active", use the checked props.
    onToggle: (val: T) => void; // handler for when user clicks on this radio button.
    checked: boolean; // determines whether the radio button should be checked. Intended implementation should be checking your form field value against this radio's "value" prop to determine if checked.
    disabled?: boolean;
    name: string; // html attribute for uniquely identifying this radio button.
}

function FrameOneRadio<T>(props: PropsWithChildren<Props<T>>): ReactElement {

    /**
	 * onClick helper for when clicking on a part of the component
	 * that is not directly the input itself.
	 *
	 * Don't call the toggle function if this input is disabled.
	 *
	 */
    function onClickHelper(): void {
        if (!props.disabled) {
            props.onToggle(props.value);
        }
    }

    return (
        <div
            onClick={onClickHelper}
            className={classNames("frame-one-radio", {
                "frame-one-radio_disabled": props.disabled,
            })}
        >
            <div>
                <div className="pretty p-default p-round p-smooth">
                    <input
                        type="radio"
                        checked={props.checked}
                        onChange={onClickHelper}
                        disabled={props.disabled}
                        name={props.name}
                    />
                    <div className="state">
                        <label/>
                    </div>
                </div>
            </div>

            {props.children &&
            <label className="frame-one-radio_real-label">
				    {props.children}
            </label>
            }
        </div>
    );
}

export default FrameOneRadio;
