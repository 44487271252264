/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BusinessPermission,
    BusinessPermissionFromJSON,
    BusinessPermissionFromJSONTyped,
    BusinessPermissionToJSON,
    BusinessUserFull,
    BusinessUserFullFromJSON,
    BusinessUserFullFromJSONTyped,
    BusinessUserFullToJSON,
    GetBusinessPermissionsResponseAllOf,
    GetBusinessPermissionsResponseAllOfFromJSON,
    GetBusinessPermissionsResponseAllOfFromJSONTyped,
    GetBusinessPermissionsResponseAllOfToJSON,
    PaginatedResponse,
    PaginatedResponseFromJSON,
    PaginatedResponseFromJSONTyped,
    PaginatedResponseToJSON,
    PaginationInfo,
    PaginationInfoFromJSON,
    PaginationInfoFromJSONTyped,
    PaginationInfoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetBusinessPermissionsResponse
 */
export interface GetBusinessPermissionsResponse extends PaginatedResponse {
    /**
     * 
     * @type {Array<BusinessUserFull>}
     * @memberof GetBusinessPermissionsResponse
     */
    currentUsers: Array<BusinessUserFull>;
    /**
     * 
     * @type {Array<BusinessPermission>}
     * @memberof GetBusinessPermissionsResponse
     */
    authUser: Array<BusinessPermission>;
}

export function GetBusinessPermissionsResponseFromJSON(json: any): GetBusinessPermissionsResponse {
    return GetBusinessPermissionsResponseFromJSONTyped(json, false);
}

export function GetBusinessPermissionsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetBusinessPermissionsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...PaginatedResponseFromJSONTyped(json, ignoreDiscriminator),
        'currentUsers': ((json['currentUsers'] as Array<any>).map(BusinessUserFullFromJSON)),
        'authUser': ((json['authUser'] as Array<any>).map(BusinessPermissionFromJSON)),
    };
}

export function GetBusinessPermissionsResponseToJSON(value?: GetBusinessPermissionsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...PaginatedResponseToJSON(value),
        'currentUsers': ((value.currentUsers as Array<any>).map(BusinessUserFullToJSON)),
        'authUser': ((value.authUser as Array<any>).map(BusinessPermissionToJSON)),
    };
}


