/**
 * Compute distance between two points.
 * https://stackoverflow.com/a/27943
 * https://www.movable-type.co.uk/scripts/latlong.html
 * https://en.wikipedia.org/wiki/Haversine_formula
 * @param lat1
 * @param lon1
 * @param lat2
 * @param lon2
 * @return Distance in km
 */
export function getDistanceFromLatLonInKm(lat1: number, lon1: number, lat2: number, lon2: number): number {
    const R = 6371; // Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1); // deg2rad below
    const dLon = deg2rad(lon2 - lon1);
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in km
}

function deg2rad(deg): number {
    return deg * (Math.PI / 180);
}
