/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Business,
    BusinessFromJSON,
    BusinessFromJSONTyped,
    BusinessToJSON,
    GetBusinessesOwnResponseAllOf,
    GetBusinessesOwnResponseAllOfFromJSON,
    GetBusinessesOwnResponseAllOfFromJSONTyped,
    GetBusinessesOwnResponseAllOfToJSON,
    PaginatedResponse,
    PaginatedResponseFromJSON,
    PaginatedResponseFromJSONTyped,
    PaginatedResponseToJSON,
    PaginationInfo,
    PaginationInfoFromJSON,
    PaginationInfoFromJSONTyped,
    PaginationInfoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetBusinessesOwnResponse
 */
export interface GetBusinessesOwnResponse extends PaginatedResponse {
    /**
     * 
     * @type {Array<Business>}
     * @memberof GetBusinessesOwnResponse
     */
    businesses: Array<Business>;
}

export function GetBusinessesOwnResponseFromJSON(json: any): GetBusinessesOwnResponse {
    return GetBusinessesOwnResponseFromJSONTyped(json, false);
}

export function GetBusinessesOwnResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetBusinessesOwnResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...PaginatedResponseFromJSONTyped(json, ignoreDiscriminator),
        'businesses': ((json['businesses'] as Array<any>).map(BusinessFromJSON)),
    };
}

export function GetBusinessesOwnResponseToJSON(value?: GetBusinessesOwnResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...PaginatedResponseToJSON(value),
        'businesses': ((value.businesses as Array<any>).map(BusinessToJSON)),
    };
}


