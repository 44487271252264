import {ReactElement, useState} from "react";
import {FiAlertCircle} from "react-icons/fi";
import classNames from "classnames";
import DpayCardInfoModal from "./modals/DpayCardInfoModal";

interface Props {
    className?: string;
}

function DpayCardInfoModalAlertIcon(props: Props): ReactElement {

    const [
        showModal,
        setShowModal,
    ] = useState(false);

    function toggleModal(): void {
        setShowModal(!showModal);
    }

    return (
        <>
            <DpayCardInfoModal
                isOpen={showModal}
                toggle={toggleModal}
            />

            <FiAlertCircle
                onClick={toggleModal}
                className={classNames("levels-info-alert-icon", props.className)}
            />
        </>
    );
}

export default DpayCardInfoModalAlertIcon;
