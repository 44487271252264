import {ButtonHTMLAttributes, FormEvent, ReactElement, useState} from "react";
import FrameOneAutoPanel from "@/components/modals/autoPanelComponents/FrameOneAutoPanel";
import FrameAutoPanelBody from "@/components/modals/autoPanelComponents/FrameAutoPanelBody";
import CheckoutPaymentCreditModalHeader
    from "@/components/checkout/checkoutPayments/credit/CheckoutPaymentCreditModalHeader";
import {useDispatch, useSelector} from "react-redux";
import {addError, updateCurrentUser} from "@/redux/meta/metaActions";
import getConfig from "../../../../utils/getConfig";
import {UsersApi} from "@devour/client";
import {NumberFormatValues} from "react-number-format/types/types";
import {PatternFormat} from "react-number-format";
import FrameButton from "@/components/buttons/FrameButton";
import {IStore} from "@/redux/defaultStore";
import {isDesktop, isMobile, isTablet} from "react-device-detect";
import classNames from "classnames";
import {useAddCreditPaymentMethod} from "@/hooks/creditPaymentMethod/useAddCreditPaymentMethod";
import {CreditCardFormValues, isCreditCardValid} from "@/utils/isCreditCardValid";

const defaultValues: CreditCardFormValues = {
    cardNumber: "",
    expDate: {
        floatValue: undefined,
        formattedValue: "",
        value: "",
    },
    securityCode: "",
    postalCode: "",
    country: "US",
};

interface Props {
    isOpen: boolean;
    onCloseAll?: () => void; // Close all modals (this and the main credit card modal)
    onClose: () => void; // Close this modal only
    noCards?: boolean;
    addCreditCard: ReturnType<typeof useAddCreditPaymentMethod>["mutate"];
}

function CheckoutPaymentAddCreditModal(props: Props): ReactElement {
    const [
        formValues,
        setFormValues,
    ] = useState<CreditCardFormValues>(defaultValues);
    const dispatch = useDispatch();
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);

    /**
     * Upon form submission to add a new credit card:
     * - Close all modals,
     * - Add a new payment method to Stripe
     * - Update the menu order with the new payment method
     * - Update the user's default payment method
     *
     * @param e - FormEvent
     */
    async function onFormSubmit(e: FormEvent<HTMLFormElement>): Promise<void> {
        e.preventDefault();
        try {
            setFormValues(defaultValues);

            props.addCreditCard(formValues);

            // Update redux store just in case other components still depend on this
            const userRes = await new UsersApi(getConfig(fullToken)).getProfile();
            dispatch(updateCurrentUser(userRes));

        } catch (e) {
            dispatch(await addError(e));

        } finally {
            props.onClose();
        }
    }

    /**
     * Handle the card number input onChange.
     *
     * @param cardNumber
     */
    function cardNumberOnChange(cardNumber: NumberFormatValues): void {
        setFormValues({
            ...formValues,
            cardNumber: cardNumber.value,
        });
    }

    /**
     * Handle the card expiry input onChange.
     *
     * @param expDate
     */
    function expiryOnChange(expDate: NumberFormatValues): void {
        setFormValues({
            ...formValues,
            expDate,
        });
    }

    /**
     * Handle the card security input onChange.
     *
     * @param security
     */
    function securityOnChange(security: NumberFormatValues): void {
        setFormValues({
            ...formValues,
            securityCode: security.value,
        });
    }

    /**
     * Handle the postal code input onChange.
     *
     * @param postalCode
     */
    function postalCodeOnChange(postalCode: NumberFormatValues): void {
        setFormValues({
            ...formValues,
            postalCode: postalCode.value,
        });
    }

    return (
        <FrameOneAutoPanel
            isOpen={props.isOpen}
            toggle={props.noCards
                ? props.onCloseAll
                : props.onClose}
            contentClassName={classNames("checkout-payments-credit", {
                "checkout-payments-credit_mobile-padding": isMobile,
            })}
            modalOnTablet={true}
            size="sm2"
        >
            <CheckoutPaymentCreditModalHeader
                isDefaultCard={false}
                onClose={props.noCards
                    ? props.onCloseAll
                    : props.onClose}
            />

            <FrameAutoPanelBody className="checkout-payments-credit_body">
                <form onSubmit={onFormSubmit}>
                    <div className="form-group">
                        <label htmlFor="cardNumber">Card Number</label>
                        <PatternFormat
                            value={formValues.cardNumber}
                            valueIsNumericString={true}
                            type="tel"
                            format="#### #### #### ####"
                            placeholder="0000 0000 0000 0000"
                            className="card-payment-add-modal_body_card-number_text"
                            onValueChange={cardNumberOnChange}
                        />
                    </div>

                    <div className="form-row">
                        <div className="form-group">
                            <label htmlFor="expiryDate">Expiry Date</label>
                            <PatternFormat
                                value={formValues.expDate.value}
                                valueIsNumericString={true}
                                type="tel"
                                format="## / ##"
                                placeholder="MM / YY"
                                onValueChange={expiryOnChange}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="securityCode">Security Code</label>
                            <PatternFormat
                                value={formValues.securityCode}
                                valueIsNumericString={true}
                                type="tel"
                                format="###"
                                placeholder="000"
                                onValueChange={securityOnChange}
                            />
                        </div>

                        {(isDesktop || isTablet) &&
                            <div className="form-group">
                                <label htmlFor="zipCode">ZIP Code</label>
                                <PatternFormat
                                    value={formValues.postalCode}
                                    valueIsNumericString={true}
                                    type="tel"
                                    format="#####"
                                    placeholder="00000"
                                    onValueChange={postalCodeOnChange}
                                />
                            </div>
                        }
                    </div>

                    {isMobile && !isTablet &&
                        <div className="form-row">
                            <div className="form-group">
                                <label htmlFor="zipCode">ZIP Code</label>
                                <PatternFormat
                                    value={formValues.postalCode}
                                    valueIsNumericString={true}
                                    type="tel"
                                    format="#####"
                                    placeholder="00000"
                                    onValueChange={postalCodeOnChange}
                                />
                            </div>
                        </div>
                    }

                    <div className={classNames("button-group", {
                        "button-group-mobile": isMobile && !isTablet,
                    })}>

                        <button
                            type="reset"
                            className={classNames("checkout-payments-credit_button_reset", {
                                "checkout-payments-credit_button_reset-purple": isCreditCardValid(formValues),
                            })}
                            onClick={(e) => {
                                e.preventDefault();
                                setFormValues(defaultValues);
                            }}>
                            Reset
                        </button>

                        <FrameButton
                            <ButtonHTMLAttributes<HTMLButtonElement>>
                            color="purple"
                            size="narrow"
                            className="checkout-payments-credit_button_submit"
                            forwardProps={{
                                type: "submit",
                                disabled: !isCreditCardValid(formValues),
                            }}
                        >
                            Add Card
                        </FrameButton>
                    </div>
                </form>

            </FrameAutoPanelBody>
        </FrameOneAutoPanel>
    );
}

export default CheckoutPaymentAddCreditModal;
