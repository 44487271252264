import {ReactElement} from "react";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameOneModal from "./modalComponents/FrameOneModal";
import {IoIosCheckmarkCircle} from "react-icons/io";
import FrameButton from "../buttons/FrameButton";

interface Props {
    isOpen: boolean;
    onClose: () => void;
}

function WalletRemovalSuccessModal(props: Props): ReactElement {

    return (
        <FrameOneModal
            isOpen={props.isOpen}
            toggle={props.onClose}
            contentClassName="wallet-removal-success-modal"
        >
            <FrameModalBody className="wallet-removal-success-modal_body">
                <div className="wallet-removal-success-modal_body_check-con">
                    <IoIosCheckmarkCircle className="wallet-removal-success-modal_body_check-con_check"/>
                </div>

                <p className="wallet-removal-success-modal_body_message">
					Your wallet has been removed successfully.
                </p>

                <div className="wallet-removal-success-modal_body_btn-con">
                    <FrameButton
                        color="purple"
                        size="normal"
                        onClick={props.onClose}
                    >
						Dismiss
                    </FrameButton>
                </div>
            </FrameModalBody>
        </FrameOneModal>
    );
}

export default WalletRemovalSuccessModal;
