/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateDpayCsvDataBody
 */
export interface CreateDpayCsvDataBody {
    /**
     * 
     * @type {number}
     * @memberof CreateDpayCsvDataBody
     */
    dateStart: number;
    /**
     * 
     * @type {number}
     * @memberof CreateDpayCsvDataBody
     */
    dateEnd: number;
}

export function CreateDpayCsvDataBodyFromJSON(json: any): CreateDpayCsvDataBody {
    return CreateDpayCsvDataBodyFromJSONTyped(json, false);
}

export function CreateDpayCsvDataBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateDpayCsvDataBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dateStart': json['dateStart'],
        'dateEnd': json['dateEnd'],
    };
}

export function CreateDpayCsvDataBodyToJSON(value?: CreateDpayCsvDataBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dateStart': value.dateStart,
        'dateEnd': value.dateEnd,
    };
}


