import {ButtonHTMLAttributes, ReactElement} from "react";
import FrameOneModal from "@/components/modals/modalComponents/FrameOneModal";
import FrameModalBody from "@/components/modals/modalComponents/FrameModalBody";
import FrameModalNoHeaderToggle from "@/components/modals/modalComponents/FrameModalNoHeaderToggle";
import {FiAlertCircle} from "react-icons/fi";
import FrameButton from "@/components/buttons/FrameButton";

interface Props {
    isOpen: boolean;
    toggle: () => void;
    toggleLoadDpayModal: () => void;
}

function CheckoutPaymentInsufficientDpayModal(props: Props): ReactElement {

    return (
        <FrameOneModal
            isOpen={props.isOpen}
            toggle={props.toggle}
            contentClassName="checkout-payments-insufficient-modal"
        >
            <FrameModalBody className="checkout-payments-insufficient-modal_body">

                <FrameModalNoHeaderToggle toggle={props.toggle} />

                <FiAlertCircle className="checkout-payments-insufficient-modal_body_icon" />

                <h3>Insufficient {import.meta.env.VITE_TOKEN_NAME} Balance</h3>

                <p className="checkout-payments-insufficient-modal_body_description">
                    Unfortunately, you don't have enough {import.meta.env.VITE_TOKEN_NAME} in your account to cover the order.
                    Please load {import.meta.env.VITE_TOKEN_NAME} to continue with checkout, or select another payment method.
                </p>

                <FrameButton
                    <ButtonHTMLAttributes<HTMLButtonElement>>
                    color="purple"
                    size="normal"
                    onClick={() => {
                        props.toggle();
                        props.toggleLoadDpayModal();
                    }}
                >
                    Load {import.meta.env.VITE_TOKEN_NAME} Now
                </FrameButton>

                <div
                    className="checkout-payments-insufficient-modal_body_secondary-description"
                    onClick={props.toggle}
                >
                    <p>
                        Use Other Payment Method
                    </p>
                </div>

            </FrameModalBody>

        </FrameOneModal>
    );
}

export default CheckoutPaymentInsufficientDpayModal;
