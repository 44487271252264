import {
    AnchorHTMLAttributes,
    ButtonHTMLAttributes,
    FC,
    MutableRefObject,
    PropsWithChildren,
    ReactElement,
    ReactNode,
    MouseEvent,
} from "react";
import {Link, LinkProps} from "react-router-dom";
import classNames from "classnames";
import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";

export type FrameOneButtonType =
    LinkProps
    | AnchorHTMLAttributes<HTMLAnchorElement>
    | ButtonHTMLAttributes<HTMLButtonElement>;

export type FrameOneButtonRefType =
    HTMLAnchorElement
    | HTMLButtonElement;

export type FrameOneButtonColor =
    "purple"
    | "purple-outline"
    | "purple-text-no-border"
    | "purple-gradient"
    | "purple-gradient-dark"
    | "purple-blue-gradient"
    | "purple-outline-transparent"
    | "gray"
    | "gray-light"
    | "ghost"
    | "white-drop-shadow"
    | "white-drop-shadow-devour"
    | "warning"
    | "danger"
    | "danger-outline"
    | "white-outline"
    | "dark-outline"
    | "dark"
    | "brand-dark"
    | "brand-light"
    | "success"
    | "twitter";

export type FrameOneButtonSize =
    "normal"
    | "large"
    | "narrow"
    | "pill"
    | "viewMode"
    | "icon" // Intended to be used with a single icon & no children.
    | "icon-square"; // Intended to be used with a single icon & no children.

interface Props<T extends FrameOneButtonType, U extends FrameOneButtonRefType> {
    color: FrameOneButtonColor; // Manually set allowed colors for specific styling; update _frame-one-button.scss file to implement.
    size: FrameOneButtonSize;
    leftIcon?: FC;
    rightIcon?: FC;
    className?: string;

    to?: string; // When present, this component renders a <Link/> tag from react-router-dom and acts like a link to same-site pages. Otherwise, behaves like a regular button. Overrides presence of href prop.
    href?: string; // When present, this component renders a <a/> tag. Otherwise, behaves like a regular button.
    onClick?: (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void; // onClick action for the "button". Should be applied no matter if a <Link/>, <a/>, or <button/> is rendered.
    forwardRef?: MutableRefObject<U>;
    forwardProps?: T; // Props forwarded to <Link/> / <a/> / <button/> component.
    showSpinner?: boolean;
    spinnerLoadingText?: string; // If `showSpinner` is true, this will be the text beside it
    showSpinnerWithoutGlobalLoading?: boolean;
}

function FrameButton<T extends FrameOneButtonType, U extends FrameOneButtonRefType = HTMLButtonElement>(props: PropsWithChildren<Props<T, U>>): ReactElement {

    const LeftIcon: any = props.leftIcon;
    const RightIcon: any = props.rightIcon;

    const isLoading = useSelector((store: IStore) => store.metaStore.loadingIncrement);
    const disabled = isLoading > 0 || (props.forwardProps as ButtonHTMLAttributes<HTMLButtonElement>)?.disabled;

    function onClickHelper(event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>): void {
        if (props.onClick) {
            props.onClick(event);
        }
        event.stopPropagation();
    }

    // Generate the className for the button to be rendered, so we can assign it easily below in each branch of logic.
    const fullClassNames: string = classNames(
        "frame-one-button",
        `frame-one-button_color_${props.color}`,
        `frame-one-button_size_${props.size}`,
        props.className,
        {
            "frame-one-button_disabled": disabled,
        },
    );

    // Generate the content here so we can selectively use it in the return logic below.
    const content: ReactNode =
		<>
		    {props.leftIcon && <LeftIcon className="frame-one-button_left-icon"/>}
		    {props.children && <span>{props.children}</span>}
		    {props.rightIcon && <RightIcon className="frame-one-button_right-icon"/>}
		</>;
    if (props.to) {
        return (
            <Link
                {...props.forwardProps as LinkProps}
                to={props.to}
                onClick={onClickHelper}
                className={fullClassNames}
                ref={props.forwardRef as MutableRefObject<HTMLAnchorElement>}
            >
                {content}
            </Link>
        );
    } else if (props.href) {
        return (
            <a
                {...props.forwardProps as AnchorHTMLAttributes<HTMLAnchorElement>}
                href={props.href}
                onClick={onClickHelper}
                target="_blank"
                rel="noopener noreferrer"
                className={fullClassNames}
                ref={props.forwardRef as MutableRefObject<HTMLAnchorElement>}
            >
                {content}
            </a>
        );
    } else {
        return (
            <button
                {...props.forwardProps as ButtonHTMLAttributes<HTMLButtonElement>}
                onClick={onClickHelper}
                className={fullClassNames}
                ref={props.forwardRef as MutableRefObject<HTMLButtonElement>}
                disabled={disabled}
            >
                {props.showSpinner && isLoading > 0 || props.showSpinnerWithoutGlobalLoading
                    ? <div className="frame-one-button_loading">
                        {props.spinnerLoadingText &&
                        <p className="frame-one-button_loading_text">
							    {props.spinnerLoadingText}
                        </p>
                        }
                        <div className="loading-spinner"/>
                    </div>
				 : content}
            </button>

        );
    }
}

FrameButton.defaultProps = {
    spinnerLoadingText: "Loading",
};

export default FrameButton;
