import {ChangeEvent, ChangeEventHandler, ReactElement, ReactNode, useEffect, useState} from "react";
import FrameOneAutoPanel from "./autoPanelComponents/FrameOneAutoPanel";
import FrameAutoPanelBody from "./autoPanelComponents/FrameAutoPanelBody";
import FrameAutoPanelFooter from "./autoPanelComponents/FrameAutoPanelFooter";
import FrameButton from "../buttons/FrameButton";
import {FaArrowLeft} from "react-icons/fa";
import {formatNumberWithCommas} from "@/utils/formatNumberWithCommas";
import {GetUserResponse, TransactionType, WithdrawsApi, WithdrawWalletChain, CreateWithdrawBody} from "@devour/client";
import getConfig from "../../utils/getConfig";
import {addError, decrementLoading, incrementLoading} from "@/redux/meta/metaActions";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import moment from "moment";
import {getTransactionSubjectCardLabel} from "@/utils/getTransactionSubjectCardLabel";
import TransactionHistoryPanel from "../TransactionHistoryPanel";
import {FiAlertCircle} from "react-icons/fi";
import classNames from "classnames";
import FrameOneSelect from "../inputs/FrameOneSelect";
import {isDesktop} from "react-device-detect";
import {useGetTransactionsPaginated} from "@/hooks/useGetTransactionsPaginated";
import Toast from "@/components/Toast";
import {defaultWithdrawForm} from "@/components/modals/RequestWithdrawModal";
import {ethers} from "ethers";

interface Props {
    isOpen: boolean;
    onClose: () => void;
}

enum DPayHistoryModalStages {
    DPayHistory,
    RequestWithdrawal,
}

function DPayHistoryModal(props: Props): ReactElement {
    const dispatch = useDispatch();
    const currentUser = useSelector((store: IStore) => store.metaStore.currentUser) as GetUserResponse;
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const [
        currentStage,
        setCurrentStage,
    ] = useState<DPayHistoryModalStages>(DPayHistoryModalStages.DPayHistory);

    const [
        withdrawForm,
        setWithdrawForm,
    ] = useState<CreateWithdrawBody>(defaultWithdrawForm);

    const {data: transactionsResponse, refetch: refetchTransactionsPaginated} = useGetTransactionsPaginated(fullToken, currentUser?.user?.id);
    const [
        showDpayErrorToast,
        setShowDpayErrorToast,
    ] = useState<boolean>(false);
    const withdrawAmountInFiat: string = formatUSD(withdrawForm.amount * transactionsResponse?.dPayPricePerUsd);

    useEffect(() => {
        if (!props.isOpen) {
            setWithdrawForm(defaultWithdrawForm);
            setTimeout(() => {
                setCurrentStage(DPayHistoryModalStages.DPayHistory);
            }, 500);
        }
    }, [props.isOpen]);

    function toggleBackButton(): void {
        switch (currentStage) {
            case DPayHistoryModalStages.DPayHistory:
                props.onClose();
                return;
            case DPayHistoryModalStages.RequestWithdrawal:
                setCurrentStage(DPayHistoryModalStages.DPayHistory);
                return;
            default:
                return;
        }
    }

    function formatUSD(balance: number): string {
        return balance
            ? Intl.NumberFormat("en-us", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }).format(balance)
            : "0";
    }

    function renderContent(): ReactElement {
        switch (currentStage) {
            case DPayHistoryModalStages.DPayHistory:
                return renderDPayHistory();
            case DPayHistoryModalStages.RequestWithdrawal:
                return renderRequestWithdraw();
            default:
                console.error("Error in rendering DPayHistoryModal: Unknown stage");
                return <div/>;
        }
    }

    function withdrawalFormOnChange(key: keyof CreateWithdrawBody): ChangeEventHandler<HTMLInputElement | HTMLSelectElement> {
        return (e) => {
            setWithdrawForm(f => {
                return {
                    ...f,
                    [key]: e.target.value,
                };
            });
        };
    }

    function setMaxWithdrawAmount(): void {
        setWithdrawForm(s => ({
            ...s,
            amount: transactionsResponse?.balance,
        }));
    }

    function withdrawAmountOnChange(e: ChangeEvent<HTMLInputElement>) {
        const re = /^[0-9\b]+$/;

        // if value is not blank, then test the regex
        if (e.target.value === "" || re.test(e.target.value)) {
            const newAmount = parseInt(e.target.value);
            setWithdrawForm(f => {
                return {
                    ...f,
                    amount: newAmount > transactionsResponse.balance
                        ? transactionsResponse.balance
                        : newAmount,
                };
            });
        }
    }

    async function completeWithdraw(): Promise<void> {
        dispatch(incrementLoading());

        try {
            await new WithdrawsApi(getConfig(fullToken)).createWithdrawRequest({
                createWithdrawBody: {
                    amount: withdrawForm.amount,
                    wallet: withdrawForm.wallet,
                    walletChain: withdrawForm.walletChain,
                },
            });
            void refetchTransactionsPaginated();
            props.onClose();
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            dispatch(decrementLoading());
        }
    }

    function getWalletAddressMaxLength(): number {
        if (withdrawForm.walletChain === WithdrawWalletChain.ETHEREUM) {
            return 42;
        } else if (withdrawForm.walletChain === WithdrawWalletChain.SOLANA) {
            return 44;
        }
    }

    function isButtonDisabled(): boolean {
        if (!withdrawForm.walletChain || !withdrawForm.amount || withdrawForm.amount <= 0) {
            return true;
        }

        switch (withdrawForm.walletChain) {
            case WithdrawWalletChain.ETHEREUM:
                return !ethers.isAddress(withdrawForm.wallet);
            case WithdrawWalletChain.SOLANA:
                return withdrawForm.wallet?.length < 43;
            default:
                return false;
        }
    }

    function renderAccountBalancePanel(): ReactElement {
        if (!transactionsResponse) {
            return <div/>;
        }
        return (
            <div className="dpay-history-modal_dpay-card_content_data">
                <img
                    className="dpay-history-modal_dpay-card_content_icon"
                    src={import.meta.env.VITE_CDN_URL + "/images/FUEL.webp"}
                    alt="DevourGO Logo"
                />
                <div className="dpay-history-modal_dpay-card_content">
                    <p className="dpay-history-modal_dpay-card_content_title">
						Account Balance
                    </p>
                    <div className="dpay-history-modal_dpay-card_content_data_inner">
                        <p className="dpay-history-modal_dpay-card_content_dpay">
                            {formatNumberWithCommas(transactionsResponse.balance)} FUEL
                        </p>
                        <p className="dpay-history-modal_dpay-card_content_fiat">
							${formatUSD(transactionsResponse.balanceInUsd)} USD
                        </p>
                    </div>
                </div>
            </div>
        );
    }

    /**
	 * DPay History Helpers
	 */

    function renderTransactions(): ReactNode {
        if (!transactionsResponse) {
            return <div className="dpay-history-modal_body"/>;
        }

        const transactionsData = transactionsResponse?.transactions?.data;
        if (transactionsData?.length === 0) {
            // No transaction history
            return (
                <div className="dpay-history-modal_body">
                    <div className="dpay-history-modal_body_placeholder">
                        <img
                            src={import.meta.env.VITE_CDN_URL + "/images/firework.svg"}
                            className="go-vip-share2earn_body_no-actions-container_graphic"
                            alt="firework"
                        />
                    </div>
                    <p className="dpay-history-modal_body_placeholder_text">
						No {import.meta.env.VITE_TOKEN_NAME} history... yet!
                    </p>
                </div>
            );
        }

        return transactionsData?.map(transaction => <TransactionHistoryPanel
            key={`transaction_${transaction.id}`}
            month={moment(transaction.createdAt).format("MMM")}
            day={moment(transaction.createdAt).format("D")}
            leftTitle={transaction.type === TransactionType.DEBIT
                ? `${import.meta.env.VITE_TOKEN_NAME} received`
                : `${import.meta.env.VITE_TOKEN_NAME} withdrawn`}
            leftSubtitle={getTransactionSubjectCardLabel(transaction)}
            rightTitle={`${transaction.type === TransactionType.DEBIT
                ? "+"
                : "-"}${transaction.amount} ${import.meta.env.VITE_TOKEN_NAME}`}
            rightSubtitle={`${formatUSD(transaction.amount * transactionsResponse?.dPayPricePerUsd)} USD`}
        />);
    }

    function renderDPayHistory(): ReactElement {
        return (
            <>
                <FrameAutoPanelBody
                    className="dpay-history-modal_body"
                    modalOnTablet={true}
                >
                    <div className="dpay-history-modal_header">
                        <button
                            className="dpay-history-modal_header_icon"
                            onClick={toggleBackButton}
                        >
                            <FaArrowLeft
                                className="frame-one-bottom-panel_header_title-row_close-icon"
                            />
                        </button>
                        <p className="dpay-history-modal_header_title">
                            {import.meta.env.VITE_TOKEN_NAME} History
                        </p>
                    </div>
                    {renderAccountBalancePanel()}
                    <div className={classNames(
                        "dpay-history-modal_border_bottom",
                        "dpay-history-modal_center",
                    )}>
                        <FrameButton
                            color="purple"
                            size="narrow"
                            onClick={() => {
                                !transactionsResponse
                                    ? setShowDpayErrorToast(true)
                                    : setCurrentStage(DPayHistoryModalStages.RequestWithdrawal);
                            }}
                            className="dpay-history-modal_center_button"
                        >
							Request Withdrawal
                        </FrameButton>
                    </div>
                    <div
                        className={classNames("dpay-history-modal_footer_cards-display")}
                    >
                        <div className="dpay-history-modal_footer_title">
                            <p className="dpay-history-modal_footer_text_bold">
								History
                            </p>
                            <a
                                className="dpay-history-modal_footer_text_button_text"
                                href={`${import.meta.env.VITE_WEB_URL}/withdraw`}
                            >
								Show all
                            </a>
                        </div>
                        {renderTransactions()}
                    </div>
                </FrameAutoPanelBody>
            </>
        );
    }

    /**
	 * Withdraw Helpers
	 */

    function renderRequestWithdraw(): ReactElement {
        return (
            <>
                <FrameAutoPanelBody className="dpay-history-modal_body">
                    <div className="dpay-history-modal_header">
                        <button
                            className="dpay-history-modal_header_icon"
                            onClick={toggleBackButton}
                        >
                            <FaArrowLeft
                                className="frame-one-bottom-panel_header_title-row_close-icon"
                            />
                        </button>
                        <p className="dpay-history-modal_header_title">
							Request Withdrawal
                        </p>
                    </div>
                    <div className="dpay-history-modal_border_bottom">
                        {renderAccountBalancePanel()}
                    </div>
                    <div className="dpay-history-modal_withdraw_warning">
                        <FiAlertCircle
                            className="dpay-history-modal_withdraw_warning_icon"
                        />
                        <p className="dpay-history-modal_withdraw_warning_text">
							You will be assessed a withdrawal fee of $3.00 USD in FUEL.
                        </p>
                    </div>

                    <div className="dpay-history-modal_withdraw_footer_title">
                        <p className="dpay-history-modal_footer_text">
							Chain
                        </p>
                    </div>
                    <div className={classNames(
                        "dpay-history-modal_border_bottom_thin",
                        "dpay-history-modal_withdraw_select",
                    )}>
                        <FrameOneSelect
                            value={withdrawForm.walletChain}
                            onChange={withdrawalFormOnChange("walletChain")}
                        >
                            <option disabled={true}>Select Chain</option>
                            {Object.values(WithdrawWalletChain).map((chain: WithdrawWalletChain) => <option key={chain} value={chain}>{chain}</option>)}
                        </FrameOneSelect>
                    </div>

                    <div className="dpay-history-modal_withdraw_footer_title">
                        <p className="dpay-history-modal_footer_text">
							Recipient Address
                        </p>
                    </div>
                    <div className={classNames(
                        "dpay-history-modal_border_bottom_thin",
                        "dpay-history-modal_withdraw_select",
                    )}>
                        <input
                            placeholder="Wallet Address"
                            value={withdrawForm.wallet}
                            onChange={withdrawalFormOnChange("wallet")}
                            maxLength={getWalletAddressMaxLength()}
                        />
                    </div>
                    <div className="dpay-history-modal_withdraw_footer_title">
                        <p className="dpay-history-modal_footer_text">
							Specify FUEL Amount
                        </p>
                        <button
                            className="dpay-history-modal_footer_text_button"
                            onClick={setMaxWithdrawAmount}
                        >
                            <p className="dpay-history-modal_footer_text_button_text">
								Max amount
                            </p>
                        </button>
                    </div>
                    <div
                        className={classNames(
                            "dpay-history-modal_withdraw",
                            isDesktop && "dpay-history-modal_border_bottom_thin",
                        )}
                        style={{
                            paddingBottom: isDesktop && "1rem",
                        }}
                    >
                        <div className="dpay-history-modal_withdraw_body">
                            <input
                                type="number"
                                value={withdrawForm.amount}
                                onChange={withdrawAmountOnChange}
                                className="dpay-history-modal_withdraw_input"
                                style={{
                                    width: `${withdrawForm.amount.toString().length}rem`,
                                }}
                            />
                            <p className="dpay-history-modal_withdraw_bold">FUEL</p>
                        </div>
                        {withdrawAmountInFiat &&
                        <div className="dpay-history-modal_withdraw_footer">
							    <p className="dpay-history-modal_footer_text_amount">
									${withdrawAmountInFiat} USD
							    </p>
                        </div>
                        }
                    </div>
                </FrameAutoPanelBody>
                <FrameAutoPanelFooter className="dpay-history-modal_footer">
                    <FrameButton
                        color="purple"
                        size="narrow"
                        className="dpay-history-modal_bottom-button"
                        onClick={completeWithdraw}
                        forwardProps={{
                            disabled: isButtonDisabled(),
                        }}
                    >
						Confirm
                    </FrameButton>
                </FrameAutoPanelFooter>
            </>
        );
    }

    function handleToastDismissal() {
        setShowDpayErrorToast(false);
    }

    return (
        <>
            <Toast
                duration={3000}
                isOpen={showDpayErrorToast}
                message={`Uh oh... it looks like ${import.meta.env.VITE_TOKEN_NAME} prices are unavailable right now. Please check back in a bit!`}
                variant="error"
                onDismiss={handleToastDismissal}
                removeMarginAdjustment={true}
            />
            <FrameOneAutoPanel
                isOpen={props.isOpen}
                toggle={props.onClose}
                contentClassName="dpay-history-modal"
                size="sm"
                modalOnTablet={true}
            >
                {renderContent()}
            </FrameOneAutoPanel>
        </>

    );
}

export default DPayHistoryModal;
