import {Dispatch, ReactElement, SetStateAction} from "react";
import {NftOwnership} from "@devour/client";
import {useNftInformationFromUri} from "@/hooks/useNftInformationFromUri.ts";

interface Props {
    nft: NftOwnership;
}

function GoVipRewardNftCard(props: Props): ReactElement {
    const [
        _loading,
        nftInformation,
    ] = useNftInformationFromUri(props.nft.tokenUri);

    return (
        <>
            {nftInformation?.imageBase64 && nftInformation?.imageBase64.startsWith("data:video")
                ? <video
                    className="govip-rewards-drawer_inventory_nfts_card_video"
                    loop
                    autoPlay
                    src={nftInformation.imageBase64}
                    playsInline={true}
                />
                : nftInformation?.imageResolveUrl || nftInformation?.imageBase64
                    ? <img
                        className="govip-rewards-drawer_inventory_nfts_card_image"
                        alt="GoFriends Card Thumbnail"
                        src={nftInformation?.imageBase64 || nftInformation?.imageResolveUrl}
                    />
                    : null}
        </>
    );
}

export default GoVipRewardNftCard;