/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DpayUserItem,
    DpayUserItemFromJSON,
    DpayUserItemFromJSONTyped,
    DpayUserItemToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetTotalDpayResponse
 */
export interface GetTotalDpayResponse {
    /**
     * 
     * @type {number}
     * @memberof GetTotalDpayResponse
     */
    vDpayTotal: number;
    /**
     * 
     * @type {number}
     * @memberof GetTotalDpayResponse
     */
    totalInUsd: number;
    /**
     * 
     * @type {Array<DpayUserItem>}
     * @memberof GetTotalDpayResponse
     */
    topUsers?: Array<DpayUserItem>;
}

export function GetTotalDpayResponseFromJSON(json: any): GetTotalDpayResponse {
    return GetTotalDpayResponseFromJSONTyped(json, false);
}

export function GetTotalDpayResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetTotalDpayResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'vDpayTotal': json['vDpayTotal'],
        'totalInUsd': json['totalInUsd'],
        'topUsers': !exists(json, 'topUsers') ? undefined : ((json['topUsers'] as Array<any>).map(DpayUserItemFromJSON)),
    };
}

export function GetTotalDpayResponseToJSON(value?: GetTotalDpayResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'vDpayTotal': value.vDpayTotal,
        'totalInUsd': value.totalInUsd,
        'topUsers': value.topUsers === undefined ? undefined : ((value.topUsers as Array<any>).map(DpayUserItemToJSON)),
    };
}


