import getConfig from "@/utils/getConfig";
import {BrandsApi, GetBrandMapRestaurantsResponse, HandoffOptions} from "@devour/client";
import {keepPreviousData, useQuery} from "@tanstack/react-query";


/**
 *
 * @param slug
 * @param placeId
 * @param handoff
 * @param offset
 * @param limit
 * @param search
 */
async function fetchBrandMapRestaurants(slug: string, placeId: string, handoff: HandoffOptions, offset: number, limit: number, search: string): Promise<GetBrandMapRestaurantsResponse> {
    return await new BrandsApi(getConfig()).getBrandMapRestaurants({
        slug,
        placeId,
        handoff,
        search,
        offset,
        limit,
    });
}

function getBrandMapRestaurantsQuery(slug: string, placeId: string, handoff: HandoffOptions, offset: number, limit: number, search: string) {
    return {
        queryKey: [
            "brand-map-restaurants",
            placeId,
            handoff,
            offset,
            limit,
            search,
            slug,
        ],
        queryFn: () => fetchBrandMapRestaurants(slug, placeId, handoff, offset, limit, search),
        staleTime: 30 * 60000,
        placeholderData: keepPreviousData,
        refetchOnWindowFocus: false,
        enabled: !!slug,
    } as const;
}

export default function useGetBrandMapRestaurants(slug: string, placeId: string, handoff: HandoffOptions, offset?: number, limit?: number, search?: string) {
    return useQuery(getBrandMapRestaurantsQuery(slug, placeId, handoff, offset, limit, search));
}