import {PropsWithChildren, ReactElement} from "react";

interface Props {
    month: string;
    day: string;
    leftTitle: string;
    leftSubtitle: string;
    rightTitle: string;
    rightSubtitle: string;
}

function TransactionHistoryPanel(props: PropsWithChildren<Props>): ReactElement {

    return (
        <div className="tx-history-panel">
            <div className="tx-history-panel_left">
                <div className="tx-history-panel_left_dating">
                    <h6 className="tx-history-panel_left_dating_month">
                        {props.month}
                    </h6>

                    <p className="tx-history-panel_left_dating_date">
                        {props.day}
                    </p>
                </div>
            </div>
            <div className="tx-history-panel_tx-details">
                <p className="tx-history-panel_tx-details_action">
                    {props.leftTitle}
                </p>

                <p className="p2-sub-body">
                    {props.leftSubtitle}
                </p>

                {props.children}
            </div>
            <div className="tx-history-panel_right">
                <p className="tx-history-panel_right_crypto">
                    {props.rightTitle}
                </p>

                <p className="p2-sub-body">
                    {props.rightSubtitle}
                </p>
            </div>
        </div>
    );
}

export default TransactionHistoryPanel;
