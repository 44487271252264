import {ReactElement, useContext, useState} from "react";
import {GetMenuResponse, MenuOrderItem} from "@devour/client";
import DevourCartItemRemovePanel from "./DevourCartItemRemovePanel";
import {htmlDecode} from "@/utils/htmlDecode";
import {TbMinus, TbTrashXFilled} from "react-icons/tb";
import {HiPlus} from "react-icons/hi";
import {RestaurantContext} from "@/pages/restaurants/context/RestaurantContext";
import getRestaurantMenuItemImage from "@/utils/getRestaurantMenuItemImage";
import MenuItemCustomizationModal from "@/components/modals/MenuItemCustomizationModal";
import {findMenuItemResponse} from "@/utils/validateMenuOrderItem";

interface Props {
    restaurantMenu: GetMenuResponse;
    menuOrderItem: MenuOrderItem;
    index: number;
    onRemoveItem: (index: number) => void;
    onUpdateQuantity: (index: number, quantity: number) => void;
}

function DevourCartCard(props: Props): ReactElement {

    const {menuOrder, restaurant} = useContext(RestaurantContext);
    const [
        removeItemPanel,
        setRemoveItemPanel,
    ] = useState<boolean>(false);
    const [
        editItemPanel,
        setEditItemPanel,
    ] = useState<boolean>(false);

    if (!props.menuOrderItem || !props.restaurantMenu) {
        return null;
    }
    const menuItem = findMenuItemResponse(props.menuOrderItem, props.restaurantMenu.menus);
    const menuItemImage = getRestaurantMenuItemImage(props.restaurantMenu, props.menuOrderItem);

    function quantityOnChange(quantity: number): void {
        if (quantity === 0) {
            setRemoveItemPanel(true);
        } else {
            props.onUpdateQuantity(props.index, quantity);
        }
    }

    return (
        <>
            {restaurant && <MenuItemCustomizationModal
                isOpen={editItemPanel}
                placeId={menuOrder.address.placeId}
                restaurantId={restaurant?.id}
                restaurantMenu={props.restaurantMenu}
                menuItem={menuItem}
                onClose={() => setEditItemPanel(false)}
                defaultValues={props.menuOrderItem}
                replaceIndex={props.index}
            />}

            <DevourCartItemRemovePanel
                menuOrderItem={props.menuOrderItem}
                index={props.index}
                onRemoveItem={props.onRemoveItem}
                isOpen={removeItemPanel}
                onClose={() => setRemoveItemPanel(false)}
            />

            <div
                className="devour-cart-card"
                onClick={() => setEditItemPanel(true)}
            >
                {menuItemImage &&
                    <div className="devour-cart-card_image">
                        <img
                            src={menuItemImage}
                            alt={props.menuOrderItem.name}
                            onError={({currentTarget}) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = `${import.meta.env.VITE_CDN_URL}/images/empty.png`;
                            }}
                        />
                    </div>
                }
                <div className="devour-cart-card_details">
                    <h5>{htmlDecode(props.menuOrderItem?.name)}</h5>
                    <div className="devour-cart-card_item-price">
												${props.menuOrderItem.subtotal.toFixed(2)}
                    </div>

                    <p>
                        {props.menuOrderItem.customizations.map((customization, index) => {
                            return (
                                <span key={`order-item-${index}`}>
                                    {customization.quantity > 1 && `${customization.quantity}x `}
                                    {htmlDecode(customization?.name)}
                                    {index + 1 < props.menuOrderItem.customizations.length && <br/>}
                                </span>
                            );
                        })}
                    </p>
                    {props.menuOrderItem.notes && <p>{props.menuOrderItem.notes}</p>}
                </div>
                <div className="devour-cart-card_quantity">
                    <div
                        className="devour-cart-card_quantity_button"
                        onClick={(e) => {
                            e.stopPropagation();
                            if (menuOrder?.callbackComplete) {
                                quantityOnChange(props.menuOrderItem.quantity - 1);
                            }
                        }}
                    >
                        {props.menuOrderItem.quantity === 1
                            ? <TbTrashXFilled/>
                            : <TbMinus/>}
                    </div>
                    <div className="devour-cart-card_quantity_number">
                        {props.menuOrderItem.quantity}
                    </div>
                    <div
                        className="devour-cart-card_quantity_button"
                        onClick={(e) => {
                            e.stopPropagation();
                            if (menuOrder?.callbackComplete) {
                                quantityOnChange(props.menuOrderItem.quantity + 1);
                            }
                        }}
                    >
                        <HiPlus/>
                    </div>
                </div>
            </div>
        </>
    );
}


export default DevourCartCard;
