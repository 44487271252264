/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TopCommunityLeader
 */
export interface TopCommunityLeader {
    /**
     * 
     * @type {string}
     * @memberof TopCommunityLeader
     */
    communityIcon?: string;
    /**
     * 
     * @type {string}
     * @memberof TopCommunityLeader
     */
    communityName: string;
    /**
     * 
     * @type {number}
     * @memberof TopCommunityLeader
     */
    totalUserPoints: number;
    /**
     * 
     * @type {string}
     * @memberof TopCommunityLeader
     */
    userNickname: string;
    /**
     * 
     * @type {string}
     * @memberof TopCommunityLeader
     */
    userPfpUrl: string;
}

export function TopCommunityLeaderFromJSON(json: any): TopCommunityLeader {
    return TopCommunityLeaderFromJSONTyped(json, false);
}

export function TopCommunityLeaderFromJSONTyped(json: any, ignoreDiscriminator: boolean): TopCommunityLeader {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'communityIcon': !exists(json, 'communityIcon') ? undefined : json['communityIcon'],
        'communityName': json['communityName'],
        'totalUserPoints': json['totalUserPoints'],
        'userNickname': json['userNickname'],
        'userPfpUrl': json['userPfpUrl'],
    };
}

export function TopCommunityLeaderToJSON(value?: TopCommunityLeader | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'communityIcon': value.communityIcon,
        'communityName': value.communityName,
        'totalUserPoints': value.totalUserPoints,
        'userNickname': value.userNickname,
        'userPfpUrl': value.userPfpUrl,
    };
}


