/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Asset,
    AssetFromJSON,
    AssetFromJSONTyped,
    AssetToJSON,
    GetBrandMapMainPromoResponseAllOf,
    GetBrandMapMainPromoResponseAllOfFromJSON,
    GetBrandMapMainPromoResponseAllOfFromJSONTyped,
    GetBrandMapMainPromoResponseAllOfToJSON,
    OrderDiscount,
    OrderDiscountFromJSON,
    OrderDiscountFromJSONTyped,
    OrderDiscountToJSON,
    OrderDiscountCalculation,
    OrderDiscountCalculationFromJSON,
    OrderDiscountCalculationFromJSONTyped,
    OrderDiscountCalculationToJSON,
    OrderDiscountFrequencyQualifier,
    OrderDiscountFrequencyQualifierFromJSON,
    OrderDiscountFrequencyQualifierFromJSONTyped,
    OrderDiscountFrequencyQualifierToJSON,
    OrderDiscountOperator,
    OrderDiscountOperatorFromJSON,
    OrderDiscountOperatorFromJSONTyped,
    OrderDiscountOperatorToJSON,
    OrderDiscountOrigin,
    OrderDiscountOriginFromJSON,
    OrderDiscountOriginFromJSONTyped,
    OrderDiscountOriginToJSON,
    OrderDiscountType,
    OrderDiscountTypeFromJSON,
    OrderDiscountTypeFromJSONTyped,
    OrderDiscountTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetBrandMapMainPromoResponse
 */
export interface GetBrandMapMainPromoResponse extends OrderDiscount {
    /**
     * 
     * @type {string}
     * @memberof GetBrandMapMainPromoResponse
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof GetBrandMapMainPromoResponse
     */
    lastRedeemedAt?: number;
    /**
     * 
     * @type {string}
     * @memberof GetBrandMapMainPromoResponse
     */
    lastRedeemedOrderId?: string;
}

export function GetBrandMapMainPromoResponseFromJSON(json: any): GetBrandMapMainPromoResponse {
    return GetBrandMapMainPromoResponseFromJSONTyped(json, false);
}

export function GetBrandMapMainPromoResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetBrandMapMainPromoResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...OrderDiscountFromJSONTyped(json, ignoreDiscriminator),
        'id': json['id'],
        'lastRedeemedAt': !exists(json, 'lastRedeemedAt') ? undefined : json['lastRedeemedAt'],
        'lastRedeemedOrderId': !exists(json, 'lastRedeemedOrderId') ? undefined : json['lastRedeemedOrderId'],
    };
}

export function GetBrandMapMainPromoResponseToJSON(value?: GetBrandMapMainPromoResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...OrderDiscountToJSON(value),
        'id': value.id,
        'lastRedeemedAt': value.lastRedeemedAt,
        'lastRedeemedOrderId': value.lastRedeemedOrderId,
    };
}


