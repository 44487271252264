/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Represents sensitive API keys for a business.
 * @export
 * @interface BulkToggleDevourIQResponse
 */
export interface BulkToggleDevourIQResponse {
    /**
     * 
     * @type {boolean}
     * @memberof BulkToggleDevourIQResponse
     */
    dryRun: boolean;
    /**
     * 
     * @type {number}
     * @memberof BulkToggleDevourIQResponse
     */
    updatedCount: number;
}

export function BulkToggleDevourIQResponseFromJSON(json: any): BulkToggleDevourIQResponse {
    return BulkToggleDevourIQResponseFromJSONTyped(json, false);
}

export function BulkToggleDevourIQResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BulkToggleDevourIQResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dryRun': json['dryRun'],
        'updatedCount': json['updatedCount'],
    };
}

export function BulkToggleDevourIQResponseToJSON(value?: BulkToggleDevourIQResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dryRun': value.dryRun,
        'updatedCount': value.updatedCount,
    };
}


