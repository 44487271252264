/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BanOrUnbanUserFromAllCommunitiesBody
 */
export interface BanOrUnbanUserFromAllCommunitiesBody {
    /**
     * 
     * @type {string}
     * @memberof BanOrUnbanUserFromAllCommunitiesBody
     */
    userId: string;
    /**
     * 
     * @type {boolean}
     * @memberof BanOrUnbanUserFromAllCommunitiesBody
     */
    ban: boolean;
}

export function BanOrUnbanUserFromAllCommunitiesBodyFromJSON(json: any): BanOrUnbanUserFromAllCommunitiesBody {
    return BanOrUnbanUserFromAllCommunitiesBodyFromJSONTyped(json, false);
}

export function BanOrUnbanUserFromAllCommunitiesBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): BanOrUnbanUserFromAllCommunitiesBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': json['userId'],
        'ban': json['ban'],
    };
}

export function BanOrUnbanUserFromAllCommunitiesBodyToJSON(value?: BanOrUnbanUserFromAllCommunitiesBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'ban': value.ban,
    };
}


