/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Asset,
    AssetFromJSON,
    AssetFromJSONTyped,
    AssetToJSON,
    BrandMapBannerImageType,
    BrandMapBannerImageTypeFromJSON,
    BrandMapBannerImageTypeFromJSONTyped,
    BrandMapBannerImageTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface BrandMapBannerImage
 */
export interface BrandMapBannerImage {
    /**
     * 
     * @type {Asset}
     * @memberof BrandMapBannerImage
     */
    image?: Asset;
    /**
     * 
     * @type {BrandMapBannerImageType}
     * @memberof BrandMapBannerImage
     */
    imageType?: BrandMapBannerImageType;
}

export function BrandMapBannerImageFromJSON(json: any): BrandMapBannerImage {
    return BrandMapBannerImageFromJSONTyped(json, false);
}

export function BrandMapBannerImageFromJSONTyped(json: any, ignoreDiscriminator: boolean): BrandMapBannerImage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'image': !exists(json, 'image') ? undefined : AssetFromJSON(json['image']),
        'imageType': !exists(json, 'imageType') ? undefined : BrandMapBannerImageTypeFromJSON(json['imageType']),
    };
}

export function BrandMapBannerImageToJSON(value?: BrandMapBannerImage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'image': AssetToJSON(value.image),
        'imageType': BrandMapBannerImageTypeToJSON(value.imageType),
    };
}


