import {FC, PropsWithChildren, ReactElement} from "react";
import {FaArrowLeft} from "react-icons/fa";
import Spacer from "@/components/Spacer";
import {FiX} from "react-icons/fi";
import classNames from "classnames";
import {IoChevronBackOutline} from "react-icons/io5";
import Tooltip from "@/components/Tooltip";
import {BsFillQuestionCircleFill} from "react-icons/bs";

interface Props {
    title: string | ReactElement;
    toggle?: () => void;
    showXBottomPanel?: boolean;
    isTitleBold?: boolean;
    showLeftChevron?: boolean;
    tooltipContent?: string;
    leftIcon?: FC;
    centerTitle?: boolean;
}

function FrameBottomPanelHeader(props: PropsWithChildren<Props>): ReactElement {
    const LeftIcon: any = props.leftIcon;

    return (
        <div className="frame-one-bottom-panel_header">
            <div className="frame-one-bottom-panel_header_title-row">
                {props.toggle && !props.showXBottomPanel &&
                <>
                    {props?.showLeftChevron
                        ? <IoChevronBackOutline
                            className="frame-one-modal_header_title-row_left-chevron"
                            onClick={props.toggle}
                        />
                        : <FaArrowLeft
                            className="frame-one-bottom-panel_header_title-row_close-icon"
                            onClick={props.toggle}
                        />
                    }
                </>
                }
                {props.leftIcon && <LeftIcon className="frame-one-modal_header_title-row_left-icon"/>}

                {props?.centerTitle
                    ? <div className="frame-one-modal_header_title-row_center-title">
                        <h4
                            className={classNames("frame-one-modal_header_title-row_title", {
                                "frame-one-modal_header_title-row_normal-weight": !props.isTitleBold,
                                "frame-one-modal_header_title-row_bolder-weight": props.isTitleBold,
                            })}
                        >
                            {props.title}
                        </h4>
                    </div>
                    : <h4
                        className={classNames("frame-one-modal_header_title-row_title", {
                            "frame-one-modal_header_title-row_normal-weight": !props.isTitleBold,
                            "frame-one-modal_header_title-row_bolder-weight": props.isTitleBold,
                        })}
                    >
                        {props.title}
                    </h4>
                }

                {props.tooltipContent &&
                <Tooltip
                    outsideIcon={BsFillQuestionCircleFill}
                    containerElementClassname="frame-one-bottom-panel"
                >
                    <p>
                        {props.tooltipContent}
                    </p>
                </Tooltip>
                }
                {props.toggle && props.showXBottomPanel &&
                <>
                    <Spacer/>
                    <FiX
                        className="frame-one-modal_header_title-row_close-icon"
                        onClick={props.toggle}
                    />
                </>
                }
            </div>

            {props.children &&
            <div className="frame-one-bottom-panel_header_children">
                {props.children}
            </div>
            }
        </div>
    );
}

export default FrameBottomPanelHeader;
