/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GleamCampaign,
    GleamCampaignFromJSON,
    GleamCampaignFromJSONTyped,
    GleamCampaignToJSON,
    GleamCoupon,
    GleamCouponFromJSON,
    GleamCouponFromJSONTyped,
    GleamCouponToJSON,
    GleamEntry,
    GleamEntryFromJSON,
    GleamEntryFromJSONTyped,
    GleamEntryToJSON,
    GleamReward,
    GleamRewardFromJSON,
    GleamRewardFromJSONTyped,
    GleamRewardToJSON,
    GleamSocialLink,
    GleamSocialLinkFromJSON,
    GleamSocialLinkFromJSONTyped,
    GleamSocialLinkToJSON,
    GleamUser,
    GleamUserFromJSON,
    GleamUserFromJSONTyped,
    GleamUserToJSON,
} from './';

/**
 * 
 * @export
 * @interface GleamWebhookPayload
 */
export interface GleamWebhookPayload {
    /**
     * 
     * @type {GleamCampaign}
     * @memberof GleamWebhookPayload
     */
    campaign: GleamCampaign;
    /**
     * 
     * @type {GleamReward}
     * @memberof GleamWebhookPayload
     */
    reward: GleamReward;
    /**
     * 
     * @type {GleamCoupon}
     * @memberof GleamWebhookPayload
     */
    coupon: GleamCoupon;
    /**
     * 
     * @type {GleamUser}
     * @memberof GleamWebhookPayload
     */
    user: GleamUser;
    /**
     * 
     * @type {GleamEntry}
     * @memberof GleamWebhookPayload
     */
    entry: GleamEntry;
    /**
     * 
     * @type {Array<GleamSocialLink>}
     * @memberof GleamWebhookPayload
     */
    social_links: Array<GleamSocialLink>;
}

export function GleamWebhookPayloadFromJSON(json: any): GleamWebhookPayload {
    return GleamWebhookPayloadFromJSONTyped(json, false);
}

export function GleamWebhookPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): GleamWebhookPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'campaign': GleamCampaignFromJSON(json['campaign']),
        'reward': GleamRewardFromJSON(json['reward']),
        'coupon': GleamCouponFromJSON(json['coupon']),
        'user': GleamUserFromJSON(json['user']),
        'entry': GleamEntryFromJSON(json['entry']),
        'social_links': ((json['social_links'] as Array<any>).map(GleamSocialLinkFromJSON)),
    };
}

export function GleamWebhookPayloadToJSON(value?: GleamWebhookPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'campaign': GleamCampaignToJSON(value.campaign),
        'reward': GleamRewardToJSON(value.reward),
        'coupon': GleamCouponToJSON(value.coupon),
        'user': GleamUserToJSON(value.user),
        'entry': GleamEntryToJSON(value.entry),
        'social_links': ((value.social_links as Array<any>).map(GleamSocialLinkToJSON)),
    };
}


