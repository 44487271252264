import GameContainer from "./GameContainer";
import {FC, useEffect, useState} from "react";
import {GamesApi, Token} from "@devour/client";
import getConfig from "../../utils/getConfig";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {addError} from "@/redux/meta/metaActions";

interface StateProps {
    fullToken: Token;
}

const InTheWeeds: FC<InTheWeedsProps> = (props) => {

    const [
        message,
        setMessage,
    ] = useState<string>();

    useEffect(() => {
        async function listener(event: any) {

            const data = event.data;

            if (data.level_status === "win") {
                try {
                    const {message} = await new GamesApi(getConfig(props.fullToken)).inTheWeeds({
                        inTheWeedsRequestBody: {
                            stars_obtained: data?.stars_obtained,
                            level_status: data?.level_status,
                            level_played: data?.level_played,
                        },
                    });

                    setMessage(message);
                } catch (err) {
                    props.dispatch(await addError(err));
                }
            }
        }

        window.addEventListener("message", listener, false);

        return () => window.removeEventListener("message", listener);
    }, [props.fullToken.token]);

    return (
        <GameContainer
            baseUrl={`${import.meta.env.VITE_CDN_URL}/games/in-the-weeds/index.html`}
            gameId="in-the-weeds"
            width="960"
            height="540"
            message={message}
        />
    );
};

function connector() {
    return connect((store: IStore): StateProps => {
        return {
            fullToken: store.authStore.fullToken,
        };
    });
}

type InTheWeedsProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(InTheWeeds);
