/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum LoginAccessPoint {
    PAGE = 'PAGE',
    ORDERING = 'ORDERING'
}

export function LoginAccessPointFromJSON(json: any): LoginAccessPoint {
    return LoginAccessPointFromJSONTyped(json, false);
}

export function LoginAccessPointFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoginAccessPoint {
    return json as LoginAccessPoint;
}

export function LoginAccessPointToJSON(value?: LoginAccessPoint | null): any {
    return value as any;
}

