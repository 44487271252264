/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AtlasHashResponse
 */
export interface AtlasHashResponse {
    /**
     * 
     * @type {string}
     * @memberof AtlasHashResponse
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof AtlasHashResponse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AtlasHashResponse
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof AtlasHashResponse
     */
    userHash: string;
}

export function AtlasHashResponseFromJSON(json: any): AtlasHashResponse {
    return AtlasHashResponseFromJSONTyped(json, false);
}

export function AtlasHashResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AtlasHashResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': json['userId'],
        'name': json['name'],
        'email': json['email'],
        'userHash': json['userHash'],
    };
}

export function AtlasHashResponseToJSON(value?: AtlasHashResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'name': value.name,
        'email': value.email,
        'userHash': value.userHash,
    };
}


