import {
    ButtonHTMLAttributes,
    Dispatch,
    ReactElement,
    SetStateAction,
    useState,
} from "react";
import {useSelector} from "react-redux";
import {
    DpayPurchase,
    DpayPurchaseRequestBody,
} from "@devour/client";
import {StripePaymentMethodObject} from "@/types/Stripe";
import {IStore} from "@/redux/defaultStore";
import {useGetTransactionsPaginated} from "@/hooks/useGetTransactionsPaginated";
import {NumericFormat} from "react-number-format";
import {formatPriceAddCommaPreserveDecimals} from "@/utils/currencyFormatters";
import {formatNumberWithCommas} from "@/utils/formatNumberWithCommas";
import {AiFillCreditCard, AiOutlinePlus} from "react-icons/ai";
import {capitalize} from "lodash";
import {FaArrowRight, FaCheckCircle, FaRedoAlt} from "react-icons/fa";
import FrameOneCheckbox from "@/components/inputs/FrameOneCheckbox";
import FrameButton from "@/components/buttons/FrameButton";
import {isDesktop, isTablet} from "react-device-detect";
import classNames from "classnames";
import {NumberFormatValues} from "react-number-format/types/types";
import {ReactComponent as Disclaimer} from "../../svgs/disclaimer.svg";
import SignUpToast from "@/components/SignUpToast";

const minUSD = 25;
const maxUSD = 1000;

interface Props {
    fetchPrice: (values: DpayPurchaseRequestBody) => Promise<void>;
    dpayPurchase: DpayPurchase;
    selectedPayment: StripePaymentMethodObject;
    setSelectedPayment: Dispatch<SetStateAction<StripePaymentMethodObject>>;
    setShowAddCardModal: Dispatch<SetStateAction<boolean>>;
    setShowConfirmModal: Dispatch<SetStateAction<boolean>>;
    disclaimerCheck: boolean;
    setDisclaimerCheck: Dispatch<SetStateAction<boolean>>;
    formValues: DpayPurchaseRequestBody;
    setFormValues: Dispatch<SetStateAction<DpayPurchaseRequestBody>>;
    paymentMethods: Array<StripePaymentMethodObject>;
    countdown: string;
}

function LoadDpaySelectPayment(props: Props): ReactElement {
    const currentUser = useSelector((store: IStore) => store.metaStore.currentUser?.user);
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const loadingIncrement = useSelector((store: IStore) => store.metaStore.loadingIncrement);
    const {
        data: transactionsData,
        isError: isTransactionsDataError,
    } = useGetTransactionsPaginated(fullToken, currentUser?.id);

    const [
        showToast,
        setShowToast,
    ] = useState<boolean>(false);

    function openAddCardModal() {
        if (currentUser) {
            props.setShowAddCardModal(true);
        } else {
            setShowToast(true);
            setTimeout(() => {
                setShowToast(false);
            }, 4000);
        }
    }

    function fiatOnChange(values: NumberFormatValues): void {
        props.setFormValues({
            ...props.formValues,
            fiat: values.floatValue,
        });
    }

    function dpayOnChange(values: NumberFormatValues): void {
        props.setFormValues({
            ...props.formValues,
            dpay: values.floatValue,
        });
    }

    function fiatOffFocus(): void {
        // Update quote with new amounts
        void props.fetchPrice({fiat: props.formValues.fiat});
    }

    function dpayOffFocus(): void {
        // Update quote with new amounts
        void props.fetchPrice({dpay: props.formValues.dpay});
    }

    function onDisclaimerToggle() {
        props.setDisclaimerCheck(s => !s);
    }

    async function onSelectPaymentMethod(paymentMethod: StripePaymentMethodObject): Promise<void> {
        void props.fetchPrice({paymentMethodId: paymentMethod.id});
        props.setSelectedPayment(paymentMethod);
    }

    function renderPaymentMethod(payment: StripePaymentMethodObject) {
        return (
            <li
                key={payment.id}
                className={classNames("buy-dpay-page_panel_payment-methods_list_item", {
                    "buy-dpay-page_panel_payment-methods_list_item_selected-payment": props.selectedPayment?.id === payment.id,
                    "buy-dpay-page_panel_payment-methods_list_item_disabled": !props.dpayPurchase,
                })}
                onClick={() => onSelectPaymentMethod(payment)}
            >
                <div className="buy-dpay-page_panel_payment-methods_list_item_info">
                    <AiFillCreditCard/>
                    <strong>{capitalize(payment.card.brand)}****{payment.card.last4}</strong>
                </div>
                {props.selectedPayment?.id === payment.id &&
										<FaCheckCircle
										    className="buy-dpay-page_panel_payment-methods_list_item_selected"/>
                }
            </li>
        );
    }

    return (
        <>
            <SignUpToast
                message="Join DevourGO to buy DPAY!"
                isOpen={showToast}
                onDismiss={() => setShowToast(false)}
            />
            <div className="buy-dpay-page_panel_header">
                <img
                    className="buy-dpay-page_panel_header_icon"
                    src={`${import.meta.env.VITE_CDN_URL}/images/FUEL.webp`}
                    alt="DevourGO Logo"
                />
                <h3>Load {import.meta.env.VITE_TOKEN_NAME}</h3>
            </div>
            <div className="buy-dpay-page_panel_instructions">
                {(isDesktop || isTablet) && "Your gateway to endless possibilities in DevourGo starts here! "}
								Specify the total USD amount or {import.meta.env.VITE_TOKEN_NAME} to
								load on your account.
            </div>
            <div className="buy-dpay-page_panel_row">
                <div className="buy-dpay-page_panel_row_label">Current {import.meta.env.VITE_TOKEN_NAME} Price</div>
                <div
                    className="buy-dpay-page_panel_row_value"
                >
                    {props.dpayPurchase
                        ? `${props.dpayPurchase?.price.toFixed(7)}`
                        : "Unavailable"}
                </div>
            </div>
            <div className="buy-dpay-page_panel_form">
                <label className="buy-dpay-page_panel_form_field">
                    <div className="buy-dpay-page_panel_form_field_label">
                        <h5>USD Amount</h5> <span>${minUSD}-${maxUSD}</span>
                    </div>
                    <div className="buy-dpay-page_panel_form_field_input">
                        <NumericFormat
                            value={props.formValues.fiat}
                            prefix="$"
                            onValueChange={fiatOnChange}
                            onBlur={fiatOffFocus}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            allowNegative={false}
                            thousandSeparator=","
                            disabled={loadingIncrement > 0}
                        />
												USD
                    </div>
                </label>
                <label className="buy-dpay-page_panel_form_field">
                    <div className="buy-dpay-page_panel_form_field_label">
                        <h5>{import.meta.env.VITE_TOKEN_NAME} Amount</h5>
                        <span>
                            {props.dpayPurchase &&
																<>
																    {formatPriceAddCommaPreserveDecimals(Math.ceil(minUSD / props.dpayPurchase?.price), 0)} - {" "}
																    {formatPriceAddCommaPreserveDecimals(Math.floor(maxUSD / props.dpayPurchase?.price), 0)}
																</>
                            }
                        </span>
                    </div>
                    <div className="buy-dpay-page_panel_form_field_input">
                        <NumericFormat
                            value={props.formValues.dpay}
                            onValueChange={dpayOnChange}
                            onBlur={dpayOffFocus}
                            decimalScale={0}
                            fixedDecimalScale={true}
                            allowNegative={false}
                            thousandSeparator=","
                            disabled={loadingIncrement > 0}
                        />
                        {import.meta.env.VITE_TOKEN_NAME}
                    </div>
                </label>
                {currentUser
                    ? <div className="buy-dpay-page_panel_form_balance">My Total Balance: &nbsp;
                        <strong>{!isTransactionsDataError
                            ? `${formatNumberWithCommas(transactionsData?.balance || 0)} ${import.meta.env.VITE_TOKEN_NAME}`
                            : "Unavailable"
                        }</strong>
                    </div>
                    : null}
            </div>
            <div className="buy-dpay-page_panel_payment-methods">
                <h4>Payment Methods</h4>
                <ul className="buy-dpay-page_panel_payment-methods_list">
                    {props.paymentMethods.map(renderPaymentMethod)}
                    <li
                        className={classNames(
                            "buy-dpay-page_panel_payment-methods_list_item",
                            {"buy-dpay-page_panel_payment-methods_list_item_disabled": !currentUser},
                        )}
                        onClick={() => openAddCardModal()}
                    >
                        <div className="buy-dpay-page_panel_payment-methods_list_item_info">
                            <AiOutlinePlus/>
                            <strong>Add Credit Card</strong>
                        </div>
                    </li>
                </ul>

            </div>
            {props.dpayPurchase?.paymentMethodId &&
								<div className="buy-dpay-page_panel_footer">
								    {props.countdown != null
								        ? <>
								            {props.countdown &&
																<div className="buy-dpay-page_panel_footer_countdown">
																		The current price is available for the next {props.countdown}
																</div>
								            }
								            <FrameOneCheckbox
								                onToggle={onDisclaimerToggle}
								                checked={props.disclaimerCheck}
								                background={"purple"}
								            >
																I understand I am purchasing on-account {import.meta.env.VITE_TOKEN_NAME} that is pegged to the
																on-chain {import.meta.env.VITE_TOKEN_NAME} price,
                                which can fluctuate in value.
								            </FrameOneCheckbox>
								            <FrameButton
								                <ButtonHTMLAttributes<HTMLButtonElement>>
								                color="purple"
								                size="large"
								                className="buy-dpay-page_panel_footer_submit-button"
								                onClick={() => props.setShowConfirmModal(true)}
								                showSpinner={true}
								                rightIcon={FaArrowRight}
								                forwardProps={{
								                    type: "button",
								                    disabled: !props.disclaimerCheck,
								                }}
								            >
																Load {import.meta.env.VITE_TOKEN_NAME} Now
								            </FrameButton>
								        </>
								        : <div className="buy-dpay-page_panel_footer_quote-expired">
								            <Disclaimer/>
								            <p>The quote has expired.</p>
								            <p>Please get a new quote by clicking the below button.</p>
								            <FrameButton
								                <ButtonHTMLAttributes<HTMLButtonElement>>
								                color="purple"
								                size="normal"
								                className="buy-dpay-page_panel_footer_quote-expired_button"
								                onClick={() => props.fetchPrice(props.formValues)}
								                showSpinner={true}
								                rightIcon={FaRedoAlt}
								            >
																Refresh Quote
								            </FrameButton>
								        </div>
								    }
								</div>
            }
        </>
    );
}

export default LoadDpaySelectPayment;
