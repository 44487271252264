/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BannedUser,
    BannedUserFromJSON,
    BannedUserFromJSONTyped,
    BannedUserToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetUsersBannedFromCommunityResponse
 */
export interface GetUsersBannedFromCommunityResponse {
    /**
     * 
     * @type {Array<BannedUser>}
     * @memberof GetUsersBannedFromCommunityResponse
     */
    bannedUsers: Array<BannedUser>;
}

export function GetUsersBannedFromCommunityResponseFromJSON(json: any): GetUsersBannedFromCommunityResponse {
    return GetUsersBannedFromCommunityResponseFromJSONTyped(json, false);
}

export function GetUsersBannedFromCommunityResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetUsersBannedFromCommunityResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bannedUsers': ((json['bannedUsers'] as Array<any>).map(BannedUserFromJSON)),
    };
}

export function GetUsersBannedFromCommunityResponseToJSON(value?: GetUsersBannedFromCommunityResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bannedUsers': ((value.bannedUsers as Array<any>).map(BannedUserToJSON)),
    };
}


