import {ReactElement} from "react";
import {BrandMap} from "@devour/client";
import classNames from "classnames";

interface Props {
    brandMap: BrandMap;
}

/**
 * Note: needs the restaurant expansion to be complete before more work is done
 * @param props
 * @constructor
 */
function BrandCartPromoCard(props: Props): ReactElement {

    return (
        <div
            className={classNames(`brand-cart-promo-card theme-${props.brandMap.colorTheme}`, {
                "brand-cart-promo-card_with-bg": props.brandMap?.promoCartBackground?.url,
                "brand-cart-promo-card_without-bg": !props.brandMap?.promoCartBackground?.url,
            })}
            style={{backgroundImage: `url(${props.brandMap?.promoCartBackground?.url
                ? props.brandMap.promoCartBackground.url
                : ""})`}}
        >
            <div className="brand-cart-promo-card_contents">
                {props.brandMap?.promoCartHero?.url &&
                <div
                    className="brand-cart-promo-card_contents_image"
                    style={{backgroundImage: `url(${props.brandMap.promoCartHero.url})`}}
                />
                }
                <div className="brand-cart-promo-card_contents_description">
                    <p className="brand-cart-promo-card_contents_description_title">{props.brandMap.promoTitle}</p>
                    <p className="brand-cart-promo-card_contents_description_tagline">{props.brandMap.promoTagline}</p>
                </div>
            </div>

        </div>
    );
}

export default BrandCartPromoCard;
