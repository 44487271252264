import {useQuery} from "@tanstack/react-query";
import {Token, GetUserRedeemableNftOwnershipsResponse, NftsApi} from "@devour/client";
import getConfig from "@/utils/getConfig";

async function fetchRedeemableNftOwnerships(): Promise<GetUserRedeemableNftOwnershipsResponse> {
    return await new NftsApi(getConfig()).getUserRedeemableNftOwnerships();
}

function redeemableNftOwnershipsQuery(fullToken: Token) {
    return {
        queryKey: [
            "redeemable-nft-ownerships",
            fullToken?.id ?? "unauthenticated",
        ],
        queryFn: () => fullToken ? fetchRedeemableNftOwnerships() : null,
        keepPreviousData: true,
        staleTime: 60000,
        enabled: !!fullToken,
        refetchOnWindowFocus: false,
    } as const;
}

export function useGetRedeemableNftOwnerships(fullToken: Token) {
    return useQuery(redeemableNftOwnershipsQuery(fullToken));
}
