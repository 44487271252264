import {ReactElement, useContext, useEffect, useState} from "react";
import FrameButton from "../buttons/FrameButton";
import {Address, AddressBook, DeliveryHandoffInstructions, ErrorType, MenuOrdersApi, UtilsApi} from "@devour/client";
import {connect, ConnectedProps, useDispatch} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {
    addError,
    refreshMenuOrderCart,
} from "../../redux/meta/metaActions";
import RestaurantAddressEntryPromptAddressDisplayCard from "../brands/RestaurantAddressEntryPromptAddressDisplayCard";
import RestaurantAddressAutoPanel from "@/pages/restaurants/components/RestaurantAddressAutoPanel";
import {isAddressBook} from "@/utils/typeGuards";
import getConfig from "@/utils/getConfig";
import {RestaurantContext} from "@/pages/restaurants/context/RestaurantContext";

interface StateProps {
    lastSearchedPlaceId: string;
}

interface Props {
    onSetAddress: (address: Address, placeId: string) => void;
}

function RestaurantAddressEntryPrompt(props: RestaurantAddressEntryPromptProps): ReactElement {

    const dispatch = useDispatch();
    const [
        showModal,
        setShowModal,
    ] = useState(false);
    const [
        address,
        setAddress,
    ] = useState<Address>(null);
    const {menuOrder} = useContext(RestaurantContext);

    useEffect(() => {
        if (props.lastSearchedPlaceId) {
            void getAddress();
        }
    }, [props.lastSearchedPlaceId]);

    function toggleModal(): void {
        setShowModal(s => !s);
    }

    async function getAddress() {
        try {
            const newAddress = await new UtilsApi().getAddressFromPlaceId({
                placeId: props.lastSearchedPlaceId,
            });
            setAddress(newAddress);
        } catch (e) {
            props.dispatch(await addError(e));
        } finally {
        }
    }

    async function onConfirmAddressEntry(address: Address): Promise<void> {
        if (!address.line1.trim()) {
            dispatch(await addError({
                type: ErrorType.APP,
                message: "This address is invalid. Please try again.",
            }));
            return;
        }

        if (menuOrder) {
            await updateCheckoutAddress(address);
        }

        let placeId = address.placeId;

        if (!placeId) {
            try {
                const placeIdRes = await new UtilsApi().getPlaceIdFromAddress({
                    address: address,
                });
                placeId = placeIdRes.placeId;
            } catch (e) {
                dispatch(await addError(e));
                return;
            }
        }

        props.onSetAddress(address, placeId);
        setShowModal(false);
    }

    async function updateCheckoutAddress (newAddress: Address | AddressBook) {
        try {
            let deliveryNotes: string = menuOrder.deliveryNotes;
            if (isAddressBook(address)) {
                switch (address.handoffInstructions) {
                    case DeliveryHandoffInstructions.HANDITTOME:
                        deliveryNotes = address.deliveryInstructions
                            ? `Hand it to me | ${address.deliveryInstructions}`
                            : "Hand it to me";
                        break;
                    case DeliveryHandoffInstructions.LEAVEITATMYDOOR:
                        deliveryNotes = address.deliveryInstructions
                            ? `Leave it at my door | ${address.deliveryInstructions}`
                            : "Leave it at my door";
                        break;
                    default:
                        deliveryNotes = address.deliveryInstructions;
                }
            }
            await new MenuOrdersApi(getConfig()).updateMenuOrder({
                id: menuOrder.id,
                createMenuOrderBody: {
                    address: newAddress,
                    deliveryNotes: deliveryNotes,
                },
            });
            props.dispatch(refreshMenuOrderCart());
        } catch (error) {
            console.log(error);
        }
    }
    return (
        <>
            <RestaurantAddressAutoPanel
                defaultAddress={address}
                isOpen={showModal}
                onAddressBookSubmit={onConfirmAddressEntry}
                onManualSubmit={onConfirmAddressEntry}
                onClose={() => setShowModal(false)}
                showHandoffOptions={true}
            />

            <div className="restaurant-address-entry-prompt">
                {!address
                    ? <div className="restaurant-address-entry-prompt_empty">
                        <h4 className="restaurant-address-entry-prompt_empty_title">
							Enter your delivery address
                        </h4>
                        <p className="restaurant-address-entry-prompt_empty_description">
							Enter your address and we’ll check if we can deliver to you
                        </p>

                        <FrameButton
                            color="purple"
                            size="large"
                            onClick={toggleModal}
                        >
							Enter address
                        </FrameButton>
                    </div>
				 : <div className="restaurant-address-entry-prompt_full">
                        <h4 className="restaurant-address-entry-prompt_full_title">
							Delivering to:
                        </h4>
                        <div className="restaurant-address-entry-prompt_full_card-container">
                            <div className="restaurant-address-entry-prompt_full_card-container_spacer">
                                <RestaurantAddressEntryPromptAddressDisplayCard
                                    address={address}
                                    onEdit={toggleModal}
                                />
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    );
}

// export default RestaurantAddressEntryPrompt;
function connector() {
    return connect((store: IStore, props: Props): StateProps & Props => {
        return {
            lastSearchedPlaceId: store.metaStore.lastSearchedPlaceId,
            ...props,
        };
    });
}

type RestaurantAddressEntryPromptProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(RestaurantAddressEntryPrompt);
