/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Asset,
    AssetFromJSON,
    AssetFromJSONTyped,
    AssetToJSON,
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
    NftTracker,
    NftTrackerFromJSON,
    NftTrackerFromJSONTyped,
    NftTrackerToJSON,
    TimeStamps,
    TimeStampsFromJSON,
    TimeStampsFromJSONTyped,
    TimeStampsToJSON,
} from './';

/**
 * Represents metadata of a redeemable NFT reward
 * @export
 * @interface NftRewardMetadata
 */
export interface NftRewardMetadata {
    /**
     * 
     * @type {string}
     * @memberof NftRewardMetadata
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof NftRewardMetadata
     */
    createdAt: number;
    /**
     * 
     * @type {number}
     * @memberof NftRewardMetadata
     */
    updatedAt: number;
    /**
     * 
     * @type {NftTracker}
     * @memberof NftRewardMetadata
     */
    nftTracker: NftTracker;
    /**
     * ABI of the NFT contract
     * @type {string}
     * @memberof NftRewardMetadata
     */
    contractAbi: string;
    /**
     * 
     * @type {string}
     * @memberof NftRewardMetadata
     */
    origin?: string;
    /**
     * 
     * @type {string}
     * @memberof NftRewardMetadata
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof NftRewardMetadata
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof NftRewardMetadata
     */
    isEnabled?: boolean;
    /**
     * 
     * @type {Asset}
     * @memberof NftRewardMetadata
     */
    image?: Asset;
    /**
     * 
     * @type {Asset}
     * @memberof NftRewardMetadata
     */
    redemptionAnimation?: Asset;
    /**
     * If enabled, a sparkle transition effect will display over the redemption image when reward is being redeemed
     * @type {boolean}
     * @memberof NftRewardMetadata
     */
    transitionEffectEnabled?: boolean;
}

export function NftRewardMetadataFromJSON(json: any): NftRewardMetadata {
    return NftRewardMetadataFromJSONTyped(json, false);
}

export function NftRewardMetadataFromJSONTyped(json: any, ignoreDiscriminator: boolean): NftRewardMetadata {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'nftTracker': NftTrackerFromJSON(json['nftTracker']),
        'contractAbi': json['contractAbi'],
        'origin': !exists(json, 'origin') ? undefined : json['origin'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'isEnabled': !exists(json, 'isEnabled') ? undefined : json['isEnabled'],
        'image': !exists(json, 'image') ? undefined : AssetFromJSON(json['image']),
        'redemptionAnimation': !exists(json, 'redemptionAnimation') ? undefined : AssetFromJSON(json['redemptionAnimation']),
        'transitionEffectEnabled': !exists(json, 'transitionEffectEnabled') ? undefined : json['transitionEffectEnabled'],
    };
}

export function NftRewardMetadataToJSON(value?: NftRewardMetadata | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'nftTracker': NftTrackerToJSON(value.nftTracker),
        'contractAbi': value.contractAbi,
        'origin': value.origin,
        'title': value.title,
        'description': value.description,
        'isEnabled': value.isEnabled,
        'image': AssetToJSON(value.image),
        'redemptionAnimation': AssetToJSON(value.redemptionAnimation),
        'transitionEffectEnabled': value.transitionEffectEnabled,
    };
}


