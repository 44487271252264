/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RemoveBrandPromoBody
 */
export interface RemoveBrandPromoBody {
    /**
     * 
     * @type {string}
     * @memberof RemoveBrandPromoBody
     */
    brand: string;
    /**
     * 
     * @type {string}
     * @memberof RemoveBrandPromoBody
     */
    promo: string;
}

export function RemoveBrandPromoBodyFromJSON(json: any): RemoveBrandPromoBody {
    return RemoveBrandPromoBodyFromJSONTyped(json, false);
}

export function RemoveBrandPromoBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): RemoveBrandPromoBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'brand': json['brand'],
        'promo': json['promo'],
    };
}

export function RemoveBrandPromoBodyToJSON(value?: RemoveBrandPromoBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'brand': value.brand,
        'promo': value.promo,
    };
}


