/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiError,
    ApiErrorFromJSON,
    ApiErrorToJSON,
    CreatePaymentMethodBody,
    CreatePaymentMethodBodyFromJSON,
    CreatePaymentMethodBodyToJSON,
    InlineResponse200,
    InlineResponse200FromJSON,
    InlineResponse200ToJSON,
    InlineResponse2001,
    InlineResponse2001FromJSON,
    InlineResponse2001ToJSON,
    InlineResponse2002,
    InlineResponse2002FromJSON,
    InlineResponse2002ToJSON,
    InlineResponse2003,
    InlineResponse2003FromJSON,
    InlineResponse2003ToJSON,
    InlineResponse2004,
    InlineResponse2004FromJSON,
    InlineResponse2004ToJSON,
    InlineResponse2005,
    InlineResponse2005FromJSON,
    InlineResponse2005ToJSON,
    InlineResponse2006,
    InlineResponse2006FromJSON,
    InlineResponse2006ToJSON,
    InlineResponse2007,
    InlineResponse2007FromJSON,
    InlineResponse2007ToJSON,
    InlineResponse2008,
    InlineResponse2008FromJSON,
    InlineResponse2008ToJSON,
    InlineResponse2009,
    InlineResponse2009FromJSON,
    InlineResponse2009ToJSON,
    UpdatePaymentMethodBody,
    UpdatePaymentMethodBodyFromJSON,
    UpdatePaymentMethodBodyToJSON,
} from '../models';

export interface StripeAccountsLoginRequest {
    id: string;
}

export interface StripeAccountsOnboardingRequest {
    id: string;
    businessId?: string;
}

export interface StripeAccountsVerifyRequest {
    id: string;
}

export interface StripePaymentMethodAddRequest {
    createPaymentMethodBody?: CreatePaymentMethodBody;
}

export interface StripePaymentMethodDeleteRequest {
    id: string;
}

export interface StripePaymentMethodGetRequest {
    id: string;
}

export interface StripePaymentMethodUpdateRequest {
    id: string;
    updatePaymentMethodBody?: UpdatePaymentMethodBody;
}

/**
 * CommerceApi - interface
 * @export
 * @interface CommerceApiInterface
 */
export interface CommerceApiInterface {
    /**
     * Used to create and add a new connect stripe account for merchants.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommerceApiInterface
     */
    stripeAccountsAddRaw(): Promise<runtime.ApiResponse<InlineResponse200>>;

    /**
     * Used to create and add a new connect stripe account for merchants.
     */
    stripeAccountsAdd(): Promise<InlineResponse200>;

    /**
     * Used to get a list of connected accounts for a merchant.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommerceApiInterface
     */
    stripeAccountsListRaw(): Promise<runtime.ApiResponse<InlineResponse2001>>;

    /**
     * Used to get a list of connected accounts for a merchant.
     */
    stripeAccountsList(): Promise<InlineResponse2001>;

    /**
     * Used to create a signed URL for stripe connect account onboarding.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommerceApiInterface
     */
    stripeAccountsLoginRaw(requestParameters: StripeAccountsLoginRequest): Promise<runtime.ApiResponse<InlineResponse2002>>;

    /**
     * Used to create a signed URL for stripe connect account onboarding.
     */
    stripeAccountsLogin(requestParameters: StripeAccountsLoginRequest): Promise<InlineResponse2002>;

    /**
     * Used to create a signed URL for stripe connect account onboarding.
     * @param {string} id 
     * @param {string} [businessId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommerceApiInterface
     */
    stripeAccountsOnboardingRaw(requestParameters: StripeAccountsOnboardingRequest): Promise<runtime.ApiResponse<InlineResponse2003>>;

    /**
     * Used to create a signed URL for stripe connect account onboarding.
     */
    stripeAccountsOnboarding(requestParameters: StripeAccountsOnboardingRequest): Promise<InlineResponse2003>;

    /**
     * Used to verify if the user has successfully connected their stripe account.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommerceApiInterface
     */
    stripeAccountsVerifyRaw(requestParameters: StripeAccountsVerifyRequest): Promise<runtime.ApiResponse<InlineResponse2004>>;

    /**
     * Used to verify if the user has successfully connected their stripe account.
     */
    stripeAccountsVerify(requestParameters: StripeAccountsVerifyRequest): Promise<InlineResponse2004>;

    /**
     * Used to create a signed URL for stripe customer portal.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommerceApiInterface
     */
    stripeCustomersPortalRaw(): Promise<runtime.ApiResponse<InlineResponse2005>>;

    /**
     * Used to create a signed URL for stripe customer portal.
     */
    stripeCustomersPortal(): Promise<InlineResponse2005>;

    /**
     * Used to get information from a stripe customer.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommerceApiInterface
     */
    stripeCustomersRetrieveRaw(): Promise<runtime.ApiResponse<InlineResponse2006>>;

    /**
     * Used to get information from a stripe customer.
     */
    stripeCustomersRetrieve(): Promise<InlineResponse2006>;

    /**
     * Used to add a new payment method to the customer\'s account.
     * @param {CreatePaymentMethodBody} [createPaymentMethodBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommerceApiInterface
     */
    stripePaymentMethodAddRaw(requestParameters: StripePaymentMethodAddRequest): Promise<runtime.ApiResponse<InlineResponse2008>>;

    /**
     * Used to add a new payment method to the customer\'s account.
     */
    stripePaymentMethodAdd(requestParameters: StripePaymentMethodAddRequest): Promise<InlineResponse2008>;

    /**
     * Used to detach the payment method from the customer\'s account.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommerceApiInterface
     */
    stripePaymentMethodDeleteRaw(requestParameters: StripePaymentMethodDeleteRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Used to detach the payment method from the customer\'s account.
     */
    stripePaymentMethodDelete(requestParameters: StripePaymentMethodDeleteRequest): Promise<void>;

    /**
     * Used to get a payment method from the customer\'s account.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommerceApiInterface
     */
    stripePaymentMethodGetRaw(requestParameters: StripePaymentMethodGetRequest): Promise<runtime.ApiResponse<InlineResponse2009>>;

    /**
     * Used to get a payment method from the customer\'s account.
     */
    stripePaymentMethodGet(requestParameters: StripePaymentMethodGetRequest): Promise<InlineResponse2009>;

    /**
     * Used to get a list of payment methods from the customer\'s account.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommerceApiInterface
     */
    stripePaymentMethodListRaw(): Promise<runtime.ApiResponse<InlineResponse2007>>;

    /**
     * Used to get a list of payment methods from the customer\'s account.
     */
    stripePaymentMethodList(): Promise<InlineResponse2007>;

    /**
     * Used to update the expiry month & year of the payment method.
     * @param {string} id 
     * @param {UpdatePaymentMethodBody} [updatePaymentMethodBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommerceApiInterface
     */
    stripePaymentMethodUpdateRaw(requestParameters: StripePaymentMethodUpdateRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Used to update the expiry month & year of the payment method.
     */
    stripePaymentMethodUpdate(requestParameters: StripePaymentMethodUpdateRequest): Promise<void>;

}

/**
 * no description
 */
export class CommerceApi extends runtime.BaseAPI implements CommerceApiInterface {

    /**
     * Used to create and add a new connect stripe account for merchants.
     */
    async stripeAccountsAddRaw(): Promise<runtime.ApiResponse<InlineResponse200>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Merchant", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stripe/accounts/add`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse200FromJSON(jsonValue));
    }

    /**
     * Used to create and add a new connect stripe account for merchants.
     */
    async stripeAccountsAdd(): Promise<InlineResponse200> {
        const response = await this.stripeAccountsAddRaw();
        return await response.value();
    }

    /**
     * Used to get a list of connected accounts for a merchant.
     */
    async stripeAccountsListRaw(): Promise<runtime.ApiResponse<InlineResponse2001>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/stripe/accounts/list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2001FromJSON(jsonValue));
    }

    /**
     * Used to get a list of connected accounts for a merchant.
     */
    async stripeAccountsList(): Promise<InlineResponse2001> {
        const response = await this.stripeAccountsListRaw();
        return await response.value();
    }

    /**
     * Used to create a signed URL for stripe connect account onboarding.
     */
    async stripeAccountsLoginRaw(requestParameters: StripeAccountsLoginRequest): Promise<runtime.ApiResponse<InlineResponse2002>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling stripeAccountsLogin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Merchant", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stripe/accounts/{id}/login`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2002FromJSON(jsonValue));
    }

    /**
     * Used to create a signed URL for stripe connect account onboarding.
     */
    async stripeAccountsLogin(requestParameters: StripeAccountsLoginRequest): Promise<InlineResponse2002> {
        const response = await this.stripeAccountsLoginRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used to create a signed URL for stripe connect account onboarding.
     */
    async stripeAccountsOnboardingRaw(requestParameters: StripeAccountsOnboardingRequest): Promise<runtime.ApiResponse<InlineResponse2003>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling stripeAccountsOnboarding.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.businessId !== undefined) {
            queryParameters['businessId'] = requestParameters.businessId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Merchant", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stripe/accounts/{id}/onboarding`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2003FromJSON(jsonValue));
    }

    /**
     * Used to create a signed URL for stripe connect account onboarding.
     */
    async stripeAccountsOnboarding(requestParameters: StripeAccountsOnboardingRequest): Promise<InlineResponse2003> {
        const response = await this.stripeAccountsOnboardingRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used to verify if the user has successfully connected their stripe account.
     */
    async stripeAccountsVerifyRaw(requestParameters: StripeAccountsVerifyRequest): Promise<runtime.ApiResponse<InlineResponse2004>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling stripeAccountsVerify.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/stripe/accounts/{id}/verify`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2004FromJSON(jsonValue));
    }

    /**
     * Used to verify if the user has successfully connected their stripe account.
     */
    async stripeAccountsVerify(requestParameters: StripeAccountsVerifyRequest): Promise<InlineResponse2004> {
        const response = await this.stripeAccountsVerifyRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used to create a signed URL for stripe customer portal.
     */
    async stripeCustomersPortalRaw(): Promise<runtime.ApiResponse<InlineResponse2005>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stripe/customers-portal`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2005FromJSON(jsonValue));
    }

    /**
     * Used to create a signed URL for stripe customer portal.
     */
    async stripeCustomersPortal(): Promise<InlineResponse2005> {
        const response = await this.stripeCustomersPortalRaw();
        return await response.value();
    }

    /**
     * Used to get information from a stripe customer.
     */
    async stripeCustomersRetrieveRaw(): Promise<runtime.ApiResponse<InlineResponse2006>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stripe/customers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2006FromJSON(jsonValue));
    }

    /**
     * Used to get information from a stripe customer.
     */
    async stripeCustomersRetrieve(): Promise<InlineResponse2006> {
        const response = await this.stripeCustomersRetrieveRaw();
        return await response.value();
    }

    /**
     * Used to add a new payment method to the customer\'s account.
     */
    async stripePaymentMethodAddRaw(requestParameters: StripePaymentMethodAddRequest): Promise<runtime.ApiResponse<InlineResponse2008>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stripe/payment-methods`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreatePaymentMethodBodyToJSON(requestParameters.createPaymentMethodBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2008FromJSON(jsonValue));
    }

    /**
     * Used to add a new payment method to the customer\'s account.
     */
    async stripePaymentMethodAdd(requestParameters: StripePaymentMethodAddRequest): Promise<InlineResponse2008> {
        const response = await this.stripePaymentMethodAddRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used to detach the payment method from the customer\'s account.
     */
    async stripePaymentMethodDeleteRaw(requestParameters: StripePaymentMethodDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling stripePaymentMethodDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stripe/payment-methods/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Used to detach the payment method from the customer\'s account.
     */
    async stripePaymentMethodDelete(requestParameters: StripePaymentMethodDeleteRequest): Promise<void> {
        await this.stripePaymentMethodDeleteRaw(requestParameters);
    }

    /**
     * Used to get a payment method from the customer\'s account.
     */
    async stripePaymentMethodGetRaw(requestParameters: StripePaymentMethodGetRequest): Promise<runtime.ApiResponse<InlineResponse2009>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling stripePaymentMethodGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stripe/payment-methods/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2009FromJSON(jsonValue));
    }

    /**
     * Used to get a payment method from the customer\'s account.
     */
    async stripePaymentMethodGet(requestParameters: StripePaymentMethodGetRequest): Promise<InlineResponse2009> {
        const response = await this.stripePaymentMethodGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used to get a list of payment methods from the customer\'s account.
     */
    async stripePaymentMethodListRaw(): Promise<runtime.ApiResponse<InlineResponse2007>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stripe/payment-methods`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2007FromJSON(jsonValue));
    }

    /**
     * Used to get a list of payment methods from the customer\'s account.
     */
    async stripePaymentMethodList(): Promise<InlineResponse2007> {
        const response = await this.stripePaymentMethodListRaw();
        return await response.value();
    }

    /**
     * Used to update the expiry month & year of the payment method.
     */
    async stripePaymentMethodUpdateRaw(requestParameters: StripePaymentMethodUpdateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling stripePaymentMethodUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stripe/payment-methods/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePaymentMethodBodyToJSON(requestParameters.updatePaymentMethodBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Used to update the expiry month & year of the payment method.
     */
    async stripePaymentMethodUpdate(requestParameters: StripePaymentMethodUpdateRequest): Promise<void> {
        await this.stripePaymentMethodUpdateRaw(requestParameters);
    }

}
