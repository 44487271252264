/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateSEOBody
 */
export interface UpdateSEOBody {
    /**
     * 
     * @type {string}
     * @memberof UpdateSEOBody
     */
    url: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateSEOBody
     */
    tags: Array<string>;
}

export function UpdateSEOBodyFromJSON(json: any): UpdateSEOBody {
    return UpdateSEOBodyFromJSONTyped(json, false);
}

export function UpdateSEOBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateSEOBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'url': json['url'],
        'tags': json['tags'],
    };
}

export function UpdateSEOBodyToJSON(value?: UpdateSEOBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'url': value.url,
        'tags': value.tags,
    };
}


