/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetUsersResponseAllOf,
    GetUsersResponseAllOfFromJSON,
    GetUsersResponseAllOfFromJSONTyped,
    GetUsersResponseAllOfToJSON,
    PaginatedResponse,
    PaginatedResponseFromJSON,
    PaginatedResponseFromJSONTyped,
    PaginatedResponseToJSON,
    PaginationInfo,
    PaginationInfoFromJSON,
    PaginationInfoFromJSONTyped,
    PaginationInfoToJSON,
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetUsersResponse
 */
export interface GetUsersResponse extends PaginatedResponse {
    /**
     * 
     * @type {Array<User>}
     * @memberof GetUsersResponse
     */
    users: Array<User>;
}

export function GetUsersResponseFromJSON(json: any): GetUsersResponse {
    return GetUsersResponseFromJSONTyped(json, false);
}

export function GetUsersResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetUsersResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...PaginatedResponseFromJSONTyped(json, ignoreDiscriminator),
        'users': ((json['users'] as Array<any>).map(UserFromJSON)),
    };
}

export function GetUsersResponseToJSON(value?: GetUsersResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...PaginatedResponseToJSON(value),
        'users': ((value.users as Array<any>).map(UserToJSON)),
    };
}


