import {ReactElement, useContext, useState} from "react";
import CheckoutDetailsHeader from "@/components/checkout/checkoutDetails/CheckoutDetailsHeader";
import { HandoffOptions, MenuOrdersApi} from "@devour/client";
import getConfig from "@/utils/getConfig";
import {useDispatch} from "react-redux";
import {addError} from "@/redux/meta/metaActions";
import CheckoutDetailsContent from "@/components/checkout/checkoutDetails/CheckoutDetailsContent";
import CheckoutDetailsHandoff from "@/components/checkout/checkoutDetails/CheckoutDetailsHandoff";
import {useParams} from "react-router";
import {useMenuOrder} from "@/hooks/menuOrder/useMenuOrder";
import CheckoutDetailsContactInfoModal
    from "@/components/checkout/checkoutDetails/CheckoutDetailsContactInfoModal";
import Skeleton from "@/components/skeletons/Skeleton";
import {RestaurantContext} from "@/pages/restaurants/context/RestaurantContext";

function CheckoutDetails(): ReactElement {
    const { menuOrderId: contextMenuOrderId } = useContext(RestaurantContext);
    const { menuOrderId: paramMenuOrderId } = useParams<{ menuOrderId: string }>();
    const menuOrderId = paramMenuOrderId || contextMenuOrderId;

    const dispatch = useDispatch();
    const {data: menuOrder, isLoading: isMenuOrderLoading, refetch} = useMenuOrder(menuOrderId);
    const [
        isHandoffUpdating,
        setIsHandoffUpdating,
    ] = useState(false);
    const [
        showContactInfoModal,
        setShowContactInfoModal,
    ] = useState<boolean>(!menuOrder?.phoneNumber || !menuOrder?.firstName || !menuOrder?.lastName);

    /**
     * Trigger default values to be applied to menu order.
     */
    async function triggerMenuOrderUpdate(): Promise<void> {
        try {
            await new MenuOrdersApi(getConfig()).checkoutMenuOrder({
                id: menuOrder.id,
            });
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            await refetch();
        }
    }

    return (
        <div className="checkout-details">
            <CheckoutDetailsContactInfoModal
                isOpen={showContactInfoModal}
                onClose={() => setShowContactInfoModal(false)}
            />

            <CheckoutDetailsHeader/>

            <CheckoutDetailsHandoff
                backToRestaurantPageOnAddressUpdate={false}
                onDone={triggerMenuOrderUpdate}
                isHandoffUpdating={isHandoffUpdating}
                setIsHandoffUpdating={setIsHandoffUpdating}
                menuOrderId={menuOrderId}
            />

            {isMenuOrderLoading || isHandoffUpdating
                ? <Skeleton
                    rows={menuOrder?.handoff === HandoffOptions.DELIVERY
                        ? 3
                        : 2}
                    classes="checkout-details_content_skeleton"
                />
                : <CheckoutDetailsContent setShowContactInfoModal={setShowContactInfoModal}/>
            }

        </div>
    );
}

export default CheckoutDetails;

