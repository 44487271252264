/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ErrorType {
    APP = 'APP',
    API = 'API',
    FORM = 'FORM'
}

export function ErrorTypeFromJSON(json: any): ErrorType {
    return ErrorTypeFromJSONTyped(json, false);
}

export function ErrorTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ErrorType {
    return json as ErrorType;
}

export function ErrorTypeToJSON(value?: ErrorType | null): any {
    return value as any;
}

