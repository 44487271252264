/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EvmWallet,
    EvmWalletFromJSON,
    EvmWalletFromJSONTyped,
    EvmWalletToJSON,
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetUserResponse
 */
export interface GetUserResponse {
    /**
     * 
     * @type {User}
     * @memberof GetUserResponse
     */
    user: User;
    /**
     * 
     * @type {Array<EvmWallet>}
     * @memberof GetUserResponse
     */
    wallets: Array<EvmWallet>;
}

export function GetUserResponseFromJSON(json: any): GetUserResponse {
    return GetUserResponseFromJSONTyped(json, false);
}

export function GetUserResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetUserResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user': UserFromJSON(json['user']),
        'wallets': ((json['wallets'] as Array<any>).map(EvmWalletFromJSON)),
    };
}

export function GetUserResponseToJSON(value?: GetUserResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user': UserToJSON(value.user),
        'wallets': ((value.wallets as Array<any>).map(EvmWalletToJSON)),
    };
}


