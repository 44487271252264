/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Address,
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
    BusinessServiceAvailability,
    BusinessServiceAvailabilityFromJSON,
    BusinessServiceAvailabilityFromJSONTyped,
    BusinessServiceAvailabilityToJSON,
    HandoffOptions,
    HandoffOptionsFromJSON,
    HandoffOptionsFromJSONTyped,
    HandoffOptionsToJSON,
    MenuItemSearchEssential,
    MenuItemSearchEssentialFromJSON,
    MenuItemSearchEssentialFromJSONTyped,
    MenuItemSearchEssentialToJSON,
    OrderDiscountSearchEssential,
    OrderDiscountSearchEssentialFromJSON,
    OrderDiscountSearchEssentialFromJSONTyped,
    OrderDiscountSearchEssentialToJSON,
    PhoneNumber,
    PhoneNumberFromJSON,
    PhoneNumberFromJSONTyped,
    PhoneNumberToJSON,
} from './';

/**
 * 
 * @export
 * @interface RestaurantSearchResult
 */
export interface RestaurantSearchResult {
    /**
     * 
     * @type {string}
     * @memberof RestaurantSearchResult
     */
    _id: string;
    /**
     * 
     * @type {string}
     * @memberof RestaurantSearchResult
     */
    name: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RestaurantSearchResult
     */
    taxonomies?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof RestaurantSearchResult
     */
    headerImage?: string;
    /**
     * 
     * @type {string}
     * @memberof RestaurantSearchResult
     */
    icon?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RestaurantSearchResult
     */
    isOpen: boolean;
    /**
     * 
     * @type {Address}
     * @memberof RestaurantSearchResult
     */
    address: Address;
    /**
     * 
     * @type {number}
     * @memberof RestaurantSearchResult
     */
    distance?: number;
    /**
     * Extension to the URL on the DevourGO webpage.
     * @type {string}
     * @memberof RestaurantSearchResult
     */
    url?: string;
    /**
     * See moment.tz.names()
     * @type {string}
     * @memberof RestaurantSearchResult
     */
    timeZone: string;
    /**
     * 
     * @type {PhoneNumber}
     * @memberof RestaurantSearchResult
     */
    phoneNumber: PhoneNumber;
    /**
     * 
     * @type {number}
     * @memberof RestaurantSearchResult
     */
    prepTime: number;
    /**
     * 
     * @type {number}
     * @memberof RestaurantSearchResult
     */
    distanceDeliveryMax?: number;
    /**
     * 
     * @type {number}
     * @memberof RestaurantSearchResult
     */
    suspendUntil?: number;
    /**
     * 
     * @type {Array<BusinessServiceAvailability>}
     * @memberof RestaurantSearchResult
     */
    serviceAvailabilities: Array<BusinessServiceAvailability>;
    /**
     * 
     * @type {Array<HandoffOptions>}
     * @memberof RestaurantSearchResult
     */
    handoffOptions?: Array<HandoffOptions>;
    /**
     * 
     * @type {Array<OrderDiscountSearchEssential>}
     * @memberof RestaurantSearchResult
     */
    promos: Array<OrderDiscountSearchEssential>;
    /**
     * 
     * @type {Array<MenuItemSearchEssential>}
     * @memberof RestaurantSearchResult
     */
    secretMenuItems: Array<MenuItemSearchEssential>;
}

export function RestaurantSearchResultFromJSON(json: any): RestaurantSearchResult {
    return RestaurantSearchResultFromJSONTyped(json, false);
}

export function RestaurantSearchResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): RestaurantSearchResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        '_id': json['_id'],
        'name': json['name'],
        'taxonomies': !exists(json, 'taxonomies') ? undefined : json['taxonomies'],
        'headerImage': !exists(json, 'headerImage') ? undefined : json['headerImage'],
        'icon': !exists(json, 'icon') ? undefined : json['icon'],
        'isOpen': json['isOpen'],
        'address': AddressFromJSON(json['address']),
        'distance': !exists(json, 'distance') ? undefined : json['distance'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'timeZone': json['timeZone'],
        'phoneNumber': PhoneNumberFromJSON(json['phoneNumber']),
        'prepTime': json['prepTime'],
        'distanceDeliveryMax': !exists(json, 'distanceDeliveryMax') ? undefined : json['distanceDeliveryMax'],
        'suspendUntil': !exists(json, 'suspendUntil') ? undefined : json['suspendUntil'],
        'serviceAvailabilities': ((json['serviceAvailabilities'] as Array<any>).map(BusinessServiceAvailabilityFromJSON)),
        'handoffOptions': !exists(json, 'handoffOptions') ? undefined : ((json['handoffOptions'] as Array<any>).map(HandoffOptionsFromJSON)),
        'promos': ((json['promos'] as Array<any>).map(OrderDiscountSearchEssentialFromJSON)),
        'secretMenuItems': ((json['secretMenuItems'] as Array<any>).map(MenuItemSearchEssentialFromJSON)),
    };
}

export function RestaurantSearchResultToJSON(value?: RestaurantSearchResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        '_id': value._id,
        'name': value.name,
        'taxonomies': value.taxonomies,
        'headerImage': value.headerImage,
        'icon': value.icon,
        'isOpen': value.isOpen,
        'address': AddressToJSON(value.address),
        'distance': value.distance,
        'url': value.url,
        'timeZone': value.timeZone,
        'phoneNumber': PhoneNumberToJSON(value.phoneNumber),
        'prepTime': value.prepTime,
        'distanceDeliveryMax': value.distanceDeliveryMax,
        'suspendUntil': value.suspendUntil,
        'serviceAvailabilities': ((value.serviceAvailabilities as Array<any>).map(BusinessServiceAvailabilityToJSON)),
        'handoffOptions': value.handoffOptions === undefined ? undefined : ((value.handoffOptions as Array<any>).map(HandoffOptionsToJSON)),
        'promos': ((value.promos as Array<any>).map(OrderDiscountSearchEssentialToJSON)),
        'secretMenuItems': ((value.secretMenuItems as Array<any>).map(MenuItemSearchEssentialToJSON)),
    };
}


