import {ButtonHTMLAttributes, ReactElement} from "react";
import FrameOneAutoPanel from "./autoPanelComponents/FrameOneAutoPanel";
import FrameAutoPanelBody from "./autoPanelComponents/FrameAutoPanelBody";
import FrameButton from "@/components/buttons/FrameButton";
import FrameAutoPanelHeader from "@/components/modals/autoPanelComponents/FrameAutoPanelHeader";
import {PiWarningCircleBold} from "react-icons/pi";

interface Props {
    isOpen: boolean;
    toggle: () => void;
}

function PaymentFailedModal(props: Props): ReactElement {


    return (
        <FrameOneAutoPanel
            isOpen={props.isOpen}
            toggle={props.toggle}
            contentClassName="payment-failed-modal"
            size="xs"
        >
            <FrameAutoPanelHeader
                title=""
                toggle={props.toggle}
            />
            <FrameAutoPanelBody className="payment-failed-modal_body">
                <div className="payment-failed-modal_body_icon">
                    <PiWarningCircleBold/>
                </div>

                <h4>
					Payment Failed
                </h4>

                <p>
					Oops, it looks like there was a hiccup processing your transaction.
					Please try using a different card or try again.
                </p>

                <FrameButton
                    <ButtonHTMLAttributes<HTMLButtonElement>>
                    color="purple"
                    size="normal"
                    onClick={props.toggle}
                >
					Try Again
                </FrameButton>

                <p className="payment-failed-modal_body_contact">
                    <a href="mailto:hello@devourgo.io">
						Contact Customer Support
                    </a>
                </p>

            </FrameAutoPanelBody>
        </FrameOneAutoPanel>
    );
}

export default PaymentFailedModal;
