/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NftOwnership,
    NftOwnershipFromJSON,
    NftOwnershipFromJSONTyped,
    NftOwnershipToJSON,
} from './';

/**
 * Represents an array of arrays where each parent array contains all the nftOwnerships for a particular user.
 * @export
 * @interface NftOwnershipMatrix
 */
export interface NftOwnershipMatrix extends Array<NftOwnership> {
}

export function NftOwnershipMatrixFromJSON(json: any): NftOwnershipMatrix {
    return NftOwnershipMatrixFromJSONTyped(json, false);
}

export function NftOwnershipMatrixFromJSONTyped(json: any, ignoreDiscriminator: boolean): NftOwnershipMatrix {
    return json;
}

export function NftOwnershipMatrixToJSON(value?: NftOwnershipMatrix | null): any {
    return value;
}


