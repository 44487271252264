/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Business,
    BusinessFromJSON,
    BusinessFromJSONTyped,
    BusinessToJSON,
    GetBrandBusinessesResponseAllOf,
    GetBrandBusinessesResponseAllOfFromJSON,
    GetBrandBusinessesResponseAllOfFromJSONTyped,
    GetBrandBusinessesResponseAllOfToJSON,
    PaginatedResponse,
    PaginatedResponseFromJSON,
    PaginatedResponseFromJSONTyped,
    PaginatedResponseToJSON,
    PaginationInfo,
    PaginationInfoFromJSON,
    PaginationInfoFromJSONTyped,
    PaginationInfoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetBrandBusinessesResponse
 */
export interface GetBrandBusinessesResponse extends PaginatedResponse {
    /**
     * 
     * @type {Array<Business>}
     * @memberof GetBrandBusinessesResponse
     */
    brandBusinesses: Array<Business>;
    /**
     * 
     * @type {Array<Business>}
     * @memberof GetBrandBusinessesResponse
     */
    businessesWithMultipleBrands: Array<Business>;
}

export function GetBrandBusinessesResponseFromJSON(json: any): GetBrandBusinessesResponse {
    return GetBrandBusinessesResponseFromJSONTyped(json, false);
}

export function GetBrandBusinessesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetBrandBusinessesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...PaginatedResponseFromJSONTyped(json, ignoreDiscriminator),
        'brandBusinesses': ((json['brandBusinesses'] as Array<any>).map(BusinessFromJSON)),
        'businessesWithMultipleBrands': ((json['businessesWithMultipleBrands'] as Array<any>).map(BusinessFromJSON)),
    };
}

export function GetBrandBusinessesResponseToJSON(value?: GetBrandBusinessesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...PaginatedResponseToJSON(value),
        'brandBusinesses': ((value.brandBusinesses as Array<any>).map(BusinessToJSON)),
        'businessesWithMultipleBrands': ((value.businessesWithMultipleBrands as Array<any>).map(BusinessToJSON)),
    };
}


