/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiError,
    ApiErrorFromJSON,
    ApiErrorToJSON,
    GetNftGroupingCategoryResponse,
    GetNftGroupingCategoryResponseFromJSON,
    GetNftGroupingCategoryResponseToJSON,
    NftGroupingCategoryBody,
    NftGroupingCategoryBodyFromJSON,
    NftGroupingCategoryBodyToJSON,
} from '../models';

export interface CreateNftGroupingCategoryRequest {
    nftGroupingCategoryBody?: NftGroupingCategoryBody;
}

export interface DeleteNftGroupingCategoryRequest {
    id: string;
}

export interface UpdateNftGroupingCategoryRequest {
    id: string;
    nftGroupingCategoryBody?: NftGroupingCategoryBody;
}

/**
 * NFTGroupingsCategoriesApi - interface
 * @export
 * @interface NFTGroupingsCategoriesApiInterface
 */
export interface NFTGroupingsCategoriesApiInterface {
    /**
     * Used by admins to create new nft category.
     * @param {NftGroupingCategoryBody} [nftGroupingCategoryBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NFTGroupingsCategoriesApiInterface
     */
    createNftGroupingCategoryRaw(requestParameters: CreateNftGroupingCategoryRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Used by admins to create new nft category.
     */
    createNftGroupingCategory(requestParameters: CreateNftGroupingCategoryRequest): Promise<void>;

    /**
     * Used by admins to delete existing categories.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NFTGroupingsCategoriesApiInterface
     */
    deleteNftGroupingCategoryRaw(requestParameters: DeleteNftGroupingCategoryRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Used by admins to delete existing categories.
     */
    deleteNftGroupingCategory(requestParameters: DeleteNftGroupingCategoryRequest): Promise<void>;

    /**
     * Used to get a list of all nfts categories.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NFTGroupingsCategoriesApiInterface
     */
    getNftGroupingCategoriesRaw(): Promise<runtime.ApiResponse<GetNftGroupingCategoryResponse>>;

    /**
     * Used to get a list of all nfts categories.
     */
    getNftGroupingCategories(): Promise<GetNftGroupingCategoryResponse>;

    /**
     * Used by admins to update existing nft grouping category.
     * @param {string} id 
     * @param {NftGroupingCategoryBody} [nftGroupingCategoryBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NFTGroupingsCategoriesApiInterface
     */
    updateNftGroupingCategoryRaw(requestParameters: UpdateNftGroupingCategoryRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Used by admins to update existing nft grouping category.
     */
    updateNftGroupingCategory(requestParameters: UpdateNftGroupingCategoryRequest): Promise<void>;

}

/**
 * no description
 */
export class NFTGroupingsCategoriesApi extends runtime.BaseAPI implements NFTGroupingsCategoriesApiInterface {

    /**
     * Used by admins to create new nft category.
     */
    async createNftGroupingCategoryRaw(requestParameters: CreateNftGroupingCategoryRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/nft-categories`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NftGroupingCategoryBodyToJSON(requestParameters.nftGroupingCategoryBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Used by admins to create new nft category.
     */
    async createNftGroupingCategory(requestParameters: CreateNftGroupingCategoryRequest): Promise<void> {
        await this.createNftGroupingCategoryRaw(requestParameters);
    }

    /**
     * Used by admins to delete existing categories.
     */
    async deleteNftGroupingCategoryRaw(requestParameters: DeleteNftGroupingCategoryRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteNftGroupingCategory.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/nft-category/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Used by admins to delete existing categories.
     */
    async deleteNftGroupingCategory(requestParameters: DeleteNftGroupingCategoryRequest): Promise<void> {
        await this.deleteNftGroupingCategoryRaw(requestParameters);
    }

    /**
     * Used to get a list of all nfts categories.
     */
    async getNftGroupingCategoriesRaw(): Promise<runtime.ApiResponse<GetNftGroupingCategoryResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/nft-categories`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetNftGroupingCategoryResponseFromJSON(jsonValue));
    }

    /**
     * Used to get a list of all nfts categories.
     */
    async getNftGroupingCategories(): Promise<GetNftGroupingCategoryResponse> {
        const response = await this.getNftGroupingCategoriesRaw();
        return await response.value();
    }

    /**
     * Used by admins to update existing nft grouping category.
     */
    async updateNftGroupingCategoryRaw(requestParameters: UpdateNftGroupingCategoryRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateNftGroupingCategory.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/nft-category/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: NftGroupingCategoryBodyToJSON(requestParameters.nftGroupingCategoryBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Used by admins to update existing nft grouping category.
     */
    async updateNftGroupingCategory(requestParameters: UpdateNftGroupingCategoryRequest): Promise<void> {
        await this.updateNftGroupingCategoryRaw(requestParameters);
    }

}
