/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DevourIQWebhookBodyUberEatsResult
 */
export interface DevourIQWebhookBodyUberEatsResult {
    /**
     * 
     * @type {string}
     * @memberof DevourIQWebhookBodyUberEatsResult
     */
    orderURL: string;
    /**
     * 
     * @type {string}
     * @memberof DevourIQWebhookBodyUberEatsResult
     */
    orderUUID: string;
}

export function DevourIQWebhookBodyUberEatsResultFromJSON(json: any): DevourIQWebhookBodyUberEatsResult {
    return DevourIQWebhookBodyUberEatsResultFromJSONTyped(json, false);
}

export function DevourIQWebhookBodyUberEatsResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): DevourIQWebhookBodyUberEatsResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'orderURL': json['orderURL'],
        'orderUUID': json['orderUUID'],
    };
}

export function DevourIQWebhookBodyUberEatsResultToJSON(value?: DevourIQWebhookBodyUberEatsResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'orderURL': value.orderURL,
        'orderUUID': value.orderUUID,
    };
}


