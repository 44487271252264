import { isMobile, isTablet } from "react-device-detect";

const OnboardingModalGetStarted = () => {

    return (
        <>
            <img className="onboarding-modal_get-started-step_bg-img" src={`${import.meta.env.VITE_CDN_URL}/images/onboarding-bg-${isMobile ? "sm" : "lg"}.webp`} alt="get-started-bg" />
            <div className="onboarding-modal_get-started-step">
                <div className="onboarding-modal_get-started-step_title">
                    <span>Welcome to</span>
                    <img
                        src={`${import.meta.env.VITE_CDN_URL}/images/DevourGo-logo-white.png`}
                        alt="Devour logo"
                    />
                </div>

                <div className="onboarding-modal_get-started-step_subtitle">
                    <p>Be part of the food ordering revolution.</p>
                </div>
            </div>
        </>
    );

};

export default OnboardingModalGetStarted;
