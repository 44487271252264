import {ReactElement, useEffect} from "react";
import useWindowSize from "../hooks/useWindowSize";
import {useNavigate} from "react-router-dom";
import AffiliateCodeGeneration from "@/components/AffiliateCodeGeneration";

function MobileAffiliatesPage(): ReactElement {

    const navigate = useNavigate();
    const [
        windowWidth,
        windowHeight,
    ] = useWindowSize();

    useEffect(() => {
        if (windowWidth > 768) {
            navigate("/account/#affiliates");
        }

    }, [windowWidth]);

    return (
        <div className="mobile-refer-a-friend">
            <div className="account-page_referrals">
                <h1>Affiliates</h1>

                <div className="mobile-refer-a-friend_info-container">
                    <img
                        src={import.meta.env.VITE_CDN_URL + "/images/referafriend.webp"}
                        alt="Refer a Friend Graphic"
                        className="mobile-refer-a-friend_info-container_graphic"
                    />
                </div>

                <AffiliateCodeGeneration/>
            </div>

        </div>
    );
}

export default MobileAffiliatesPage;
