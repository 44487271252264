import getOwnedNftData from "@/utils/getOwnedNftData";
import { getChains } from "@/utils/parseChain";
import { NftGrouping, NftGroupingCategory, NftOwnershipMatrix, NftTracker } from "@devour/client";
import { FC, useState } from "react";
import { isMobile, isTablet } from "react-device-detect";
import DrawerModalView from "./communityInfoDrawer/DrawerModalView";
import GoFrensCommunityInfoDrawer from "./GoFrensCommunityInfoDrawer";
import classNames from "classnames";

interface popupData {
    grouping: NftGrouping;
    trackers: NftTracker[];
    owned: number;
}

const defaultPopupData: popupData = {
    grouping: {
        id: "",
        name: "",
        icon: null,
        hero: null,
        categories: [],
        chains: [],
        numOfMembers: 0,
        createdAt: 0,
        updatedAt: 0,
    },
    trackers: [],
    owned: 0,
};

interface props {
    groupings: NftGrouping[];
    trackers: NftTracker[];
    ownerships: NftOwnershipMatrix[];
    categories: NftGroupingCategory[];
}

const GoFrensCommunityListView: FC<props> = ({ groupings, categories, ownerships, trackers }) => {
    const [popupData, setPopupData] = useState<popupData>(defaultPopupData);
    const [showDrawer, setShowDrawer] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const getGroupingChains = (grouping: NftGrouping) => {
        const chains = getChains(false);
        const groupingChains = grouping.chains?.map((evmChain) => {
            const foundChain = chains.find((chain) => chain.chain == evmChain);
            if (foundChain) {
                return foundChain[isMobile ? "nameShort" : "name"];
            }
        });
        const uniqueGroupingChains = Array.from(new Set(groupingChains));
        return uniqueGroupingChains;
    };

    const getGroupingCategories = (grouping: NftGrouping) => {
        const groupingCategories = grouping.categories?.map((categoryId) => {
            return categories.find((category) => category.id == categoryId)?.name;
        });
        return groupingCategories;
    };

    const handleGroupingClick = (grouping: NftGrouping) => {
        const groupedInfo = getOwnedNftData(grouping, trackers, ownerships);
        setPopupData({
            grouping,
            owned: groupedInfo.ownedCount,
            trackers: groupedInfo.groupingTrackers,
        });
        if (!isTablet) {
            setShowDrawer(true);
        } else {
            setShowModal(true);
        }
    };

    const renderGroupingRow = (grouping: NftGrouping) => {
        const chains = getGroupingChains(grouping);
        const groupingCategories = getGroupingCategories(grouping);
        const placeholderText = <span className="placeholder-text">-</span>;
        return (
            <div
                onClick={() => handleGroupingClick(grouping)}
                key={grouping.id}
                className="gofrens-community-listview_body_row"
            >
                <div className="gofrens-community-listview_body_row_item collection-column">
                    <img
                        className="gofrens-community-listview_body_row_item_image"
                        src={grouping.icon?.url ?? grouping.hero.url}
                        alt="grouping image"
                    />
                    <div className="collection-column_info">
                        <span>{grouping.name}</span>
                        {isMobile &&
                          <span className="collection-column_info_category">
                              {groupingCategories?.length > 0 ? `${groupingCategories[0]} - ` : ""}{" "}
                              {chains.length > 0 ? chains[0] : placeholderText}
                          </span>
                        }
                    </div>
                </div>
                {!isMobile &&
          <>
              <div className="gofrens-community-listview_body_row_item">
                  <span>{groupingCategories?.length > 0 ? groupingCategories[0] : placeholderText}</span>
              </div>
              <div className="gofrens-community-listview_body_row_item">
                  <span>{chains?.length ? chains[0] : placeholderText}</span>
              </div>
          </>
                }
                <div className="gofrens-community-listview_body_row_item right-align">
                    <span>{grouping.numOfMembers ?? 0}</span>
                </div>
                <div className="gofrens-community-listview_body_row_item right-align">
                    <span>{grouping.rank ?? placeholderText}</span>
                </div>
            </div>
        );
    };

    return (
        <>
            <GoFrensCommunityInfoDrawer show={showDrawer} toggle={() => setShowDrawer(false)} {...popupData} />
            <DrawerModalView show={showModal} toggle={() => setShowModal(false)} {...popupData} />

            <div className="gofrens-community-listview">
                <div className="gofrens-community-listview_header">
                    <div className="gofrens-community-listview_header_item">
                        <span>Collection</span>
                    </div>
                    {!isMobile &&
            <>
                <div className="gofrens-community-listview_header_item">
                    <span>Categories</span>
                </div>
                <div className="gofrens-community-listview_header_item">
                    <span>Chains</span>
                </div>
            </>
                    }
                    <div className="gofrens-community-listview_header_item right-align">
                        <span className="members-heading">
                            <span className="members-heading_small">Members</span>
                            <span className="members-heading_large">DevourGO Members</span>
                        </span>
                    </div>
                    <div className="gofrens-community-listview_header_item right-align">
                        <span>Rank</span>
                    </div>
                </div>
                <div className="gofrens-community-listview_body">
                    {groupings.map((grouping) => renderGroupingRow(grouping))}
                </div>
            </div>
        </>
    );
};

export default GoFrensCommunityListView;
