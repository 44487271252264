/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AccountExperienceTransaction,
    AccountExperienceTransactionFromJSON,
    AccountExperienceTransactionFromJSONTyped,
    AccountExperienceTransactionToJSON,
} from './';

/**
 * 
 * @export
 * @interface AccountLevel
 */
export interface AccountLevel {
    /**
     * 
     * @type {number}
     * @memberof AccountLevel
     */
    level: number;
    /**
     * 
     * @type {number}
     * @memberof AccountLevel
     */
    totalExperience: number;
    /**
     * 
     * @type {number}
     * @memberof AccountLevel
     */
    experienceRequiredForNextLevel: number;
    /**
     * 
     * @type {number}
     * @memberof AccountLevel
     */
    experienceGainedAtThisLevel: number;
    /**
     * 
     * @type {boolean}
     * @memberof AccountLevel
     */
    userOwnsIndustryCollectionNft: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountLevel
     */
    userRequiresIndustryCollectionNftToLevelUp: boolean;
    /**
     * 
     * @type {Array<AccountExperienceTransaction>}
     * @memberof AccountLevel
     */
    transactions: Array<AccountExperienceTransaction>;
    /**
     * 
     * @type {boolean}
     * @memberof AccountLevel
     */
    lootboxOnePreviouslyGained?: boolean;
}

export function AccountLevelFromJSON(json: any): AccountLevel {
    return AccountLevelFromJSONTyped(json, false);
}

export function AccountLevelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountLevel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'level': json['level'],
        'totalExperience': json['totalExperience'],
        'experienceRequiredForNextLevel': json['experienceRequiredForNextLevel'],
        'experienceGainedAtThisLevel': json['experienceGainedAtThisLevel'],
        'userOwnsIndustryCollectionNft': json['userOwnsIndustryCollectionNft'],
        'userRequiresIndustryCollectionNftToLevelUp': json['userRequiresIndustryCollectionNftToLevelUp'],
        'transactions': ((json['transactions'] as Array<any>).map(AccountExperienceTransactionFromJSON)),
        'lootboxOnePreviouslyGained': !exists(json, 'lootboxOnePreviouslyGained') ? undefined : json['lootboxOnePreviouslyGained'],
    };
}

export function AccountLevelToJSON(value?: AccountLevel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'level': value.level,
        'totalExperience': value.totalExperience,
        'experienceRequiredForNextLevel': value.experienceRequiredForNextLevel,
        'experienceGainedAtThisLevel': value.experienceGainedAtThisLevel,
        'userOwnsIndustryCollectionNft': value.userOwnsIndustryCollectionNft,
        'userRequiresIndustryCollectionNftToLevelUp': value.userRequiresIndustryCollectionNftToLevelUp,
        'transactions': ((value.transactions as Array<any>).map(AccountExperienceTransactionToJSON)),
        'lootboxOnePreviouslyGained': value.lootboxOnePreviouslyGained,
    };
}


