/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RankedMember
 */
export interface RankedMember {
    /**
     * 
     * @type {string}
     * @memberof RankedMember
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof RankedMember
     */
    nickname: string;
    /**
     * 
     * @type {string}
     * @memberof RankedMember
     */
    gravatar: string;
    /**
     * 
     * @type {number}
     * @memberof RankedMember
     */
    pointsEarnedForCommunity: number;
    /**
     * 
     * @type {number}
     * @memberof RankedMember
     */
    rankWithinCommunity?: number;
}

export function RankedMemberFromJSON(json: any): RankedMember {
    return RankedMemberFromJSONTyped(json, false);
}

export function RankedMemberFromJSONTyped(json: any, ignoreDiscriminator: boolean): RankedMember {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'nickname': json['nickname'],
        'gravatar': json['gravatar'],
        'pointsEarnedForCommunity': json['pointsEarnedForCommunity'],
        'rankWithinCommunity': !exists(json, 'rankWithinCommunity') ? undefined : json['rankWithinCommunity'],
    };
}

export function RankedMemberToJSON(value?: RankedMember | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'nickname': value.nickname,
        'gravatar': value.gravatar,
        'pointsEarnedForCommunity': value.pointsEarnedForCommunity,
        'rankWithinCommunity': value.rankWithinCommunity,
    };
}


