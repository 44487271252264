import {ChangeEvent, ReactElement, useContext, useState} from "react";
import {MenuCategorySearchEssential, MenuItemSearchEssential} from "@devour/client";
import {RestaurantContext} from "../context/RestaurantContext";
import MenuItemPreloadCard from "@/pages/restaurants/components/MenuItemCardPreload";
import SearchInput from "@/components/inputs/SearchInput";
import {isDesktop} from "react-device-detect";
import Skeleton from "react-loading-skeleton";

function RestaurantMenusPreload(): ReactElement {

    const {restaurant} = useContext(RestaurantContext);
    const menuCategoriesRoot = restaurant?.menus;
    const [
        searchValue,
        setSearchValue,
    ] = useState("");

    function renderMenuItems(menuItems: Array<MenuItemSearchEssential> = []): Array<JSX.Element> {
        return menuItems
            .filter((menuItem) => filterItem(menuItem, searchValue))
            .map((menuItem) => <li
                key={menuItem.id}
                className="restaurant-menus-preload_category_list_item"
            >
                <MenuItemPreloadCard
                    menuItem={menuItem}
                />
            </li>);
    }

    function renderMenuCategory(category: MenuCategorySearchEssential, nestedLevel: number = 2): JSX.Element {
        const menuItems = category.menuItems;
        let heading: JSX.Element;
        switch (nestedLevel) {
            case 2:
                heading = <h2>{category?.name}</h2>;
                break;
            case 3:
                heading = <h3>{category?.name}</h3>;
                break;
            case 4:
                heading = <h4>{category?.name}</h4>;
                break;
            case 5:
                heading = <h5>{category?.name}</h5>;
                break;
            case 6:
            default:
                heading = <h6>{category?.name}</h6>;
                break;
        }

        return (
            <div
                key={category.id}
                id={`menu-category-${category.id}`}
                className="restaurant-menus-preload_category"
            >
                {menuItems?.length > 0 &&
                <>
					    {heading}
					    <ul className="restaurant-menus-preload_category_list">
					        {renderMenuItems(category.menuItems)}
					    </ul>
                </>
                }
                {category.menuSubcategories?.map((subcategory) => renderMenuCategory(subcategory, nestedLevel + 1))}
            </div>
        );
    }

    /**
	 * Get only categories & sub categories than have any items that match the user's search.
	 *
	 * @param _sortedRootCats
	 */
    function getFilteredCategories(_sortedRootCats: Array<MenuCategorySearchEssential>): Array<MenuCategorySearchEssential> {
        if (!_sortedRootCats) {
            return [];
        }
        return _sortedRootCats.reduce((acc: Array<MenuCategorySearchEssential>, category) => {
            const foundMenuItems = category.menuItems?.filter(item => filterItem(item, searchValue));

            if (foundMenuItems.length > 0) {
                acc.push(category);
            }

            if (category.menuSubcategories.length > 0) {
                acc = acc.concat(getFilteredCategories(category.menuSubcategories));
            }

            return acc;
        }, []);
    }

    const filteredCategories = getFilteredCategories(menuCategoriesRoot);

    function handleSearchChange(e: ChangeEvent<HTMLInputElement>): void {
        setSearchValue(e.target.value);
    }

    function clearSearch(): void {
        setSearchValue("");
    }

    function RestaurantMenuCategoryJumperSkeleton(): ReactElement {
        return (
            <div className="category-jumper restaurant-menus-browse_jumper-search-container_preload-skeleton-adjust">
                {Array.from({length: 10}, (_, i) => <Skeleton width={100} height={32} key={i}/>)}
            </div>
        );
    }

    if (!menuCategoriesRoot) {
        return null;
    }

    return (
        <div className="restaurant-menus-preload">

            <div className="restaurant-menus-browse_jumper-search-container">
                {RestaurantMenuCategoryJumperSkeleton()}
                {isDesktop &&
                <div className="restaurant-menus-browse_jumper-search-container_preload-width-adjust">
					    <SearchInput
					        placeholder="Search restaurant menu"
					        value={searchValue}
					        onChange={handleSearchChange}
					        maxLength={15}
					        onClear={clearSearch}
					        className="restaurant-menus-browse_jumper-search-container_input"
					    />
                </div>
                }

            </div>

            <div className="restaurant-menus-preload_results">
                {filteredCategories.map((category) => renderMenuCategory(category, 2))}
            </div>
        </div>
    );
}

export default RestaurantMenusPreload;

function filterItem(item: MenuItemSearchEssential, search: string): boolean {
    return item?.name?.toLowerCase()?.includes(search.toLowerCase()) ||
        item.description?.toLowerCase()?.includes(search.toLowerCase());
}
