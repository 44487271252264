/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BusinessPermission,
    BusinessPermissionFromJSON,
    BusinessPermissionFromJSONTyped,
    BusinessPermissionToJSON,
    BusinessUser,
    BusinessUserFromJSON,
    BusinessUserFromJSONTyped,
    BusinessUserToJSON,
} from './';

/**
 * Represents detailed information about a business user.
 * @export
 * @interface BusinessUserFull
 */
export interface BusinessUserFull extends BusinessUser {
    /**
     * 
     * @type {string}
     * @memberof BusinessUserFull
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessUserFull
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessUserFull
     */
    lastName: string;
}

export function BusinessUserFullFromJSON(json: any): BusinessUserFull {
    return BusinessUserFullFromJSONTyped(json, false);
}

export function BusinessUserFullFromJSONTyped(json: any, ignoreDiscriminator: boolean): BusinessUserFull {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...BusinessUserFromJSONTyped(json, ignoreDiscriminator),
        'email': json['email'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
    };
}

export function BusinessUserFullToJSON(value?: BusinessUserFull | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...BusinessUserToJSON(value),
        'email': value.email,
        'firstName': value.firstName,
        'lastName': value.lastName,
    };
}


