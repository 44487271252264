/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Represents sensitive API keys for a business.
 * @export
 * @interface BusinessKeys
 */
export interface BusinessKeys {
    /**
     * 
     * @type {string}
     * @memberof BusinessKeys
     */
    firstDeliveryId?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessKeys
     */
    chowlyApi?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessKeys
     */
    itsaCheckmateAccess?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BusinessKeys
     */
    isPos: boolean;
    /**
     * 
     * @type {string}
     * @memberof BusinessKeys
     */
    stripeAccountId?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessKeys
     */
    uberEatsUUID?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessKeys
     */
    uberEatsUrl?: string;
    /**
     * 
     * @type {number}
     * @memberof BusinessKeys
     */
    oloVendorId?: number;
}

export function BusinessKeysFromJSON(json: any): BusinessKeys {
    return BusinessKeysFromJSONTyped(json, false);
}

export function BusinessKeysFromJSONTyped(json: any, ignoreDiscriminator: boolean): BusinessKeys {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstDeliveryId': !exists(json, 'firstDeliveryId') ? undefined : json['firstDeliveryId'],
        'chowlyApi': !exists(json, 'chowlyApi') ? undefined : json['chowlyApi'],
        'itsaCheckmateAccess': !exists(json, 'itsaCheckmateAccess') ? undefined : json['itsaCheckmateAccess'],
        'isPos': json['isPos'],
        'stripeAccountId': !exists(json, 'stripeAccountId') ? undefined : json['stripeAccountId'],
        'uberEatsUUID': !exists(json, 'uberEatsUUID') ? undefined : json['uberEatsUUID'],
        'uberEatsUrl': !exists(json, 'uberEatsUrl') ? undefined : json['uberEatsUrl'],
        'oloVendorId': !exists(json, 'oloVendorId') ? undefined : json['oloVendorId'],
    };
}

export function BusinessKeysToJSON(value?: BusinessKeys | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firstDeliveryId': value.firstDeliveryId,
        'chowlyApi': value.chowlyApi,
        'itsaCheckmateAccess': value.itsaCheckmateAccess,
        'isPos': value.isPos,
        'stripeAccountId': value.stripeAccountId,
        'uberEatsUUID': value.uberEatsUUID,
        'uberEatsUrl': value.uberEatsUrl,
        'oloVendorId': value.oloVendorId,
    };
}


