/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
    TimeStamps,
    TimeStampsFromJSON,
    TimeStampsFromJSONTyped,
    TimeStampsToJSON,
    WalletType,
    WalletTypeFromJSON,
    WalletTypeFromJSONTyped,
    WalletTypeToJSON,
} from './';

/**
 * Represents a signed wallet for a particular account.
 * @export
 * @interface EvmWallet
 */
export interface EvmWallet {
    /**
     * 
     * @type {string}
     * @memberof EvmWallet
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof EvmWallet
     */
    createdAt: number;
    /**
     * 
     * @type {number}
     * @memberof EvmWallet
     */
    updatedAt: number;
    /**
     * 
     * @type {WalletType}
     * @memberof EvmWallet
     */
    type: WalletType;
    /**
     * 
     * @type {string}
     * @memberof EvmWallet
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof EvmWallet
     */
    userId: string;
}

export function EvmWalletFromJSON(json: any): EvmWallet {
    return EvmWalletFromJSONTyped(json, false);
}

export function EvmWalletFromJSONTyped(json: any, ignoreDiscriminator: boolean): EvmWallet {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'type': WalletTypeFromJSON(json['type']),
        'address': json['address'],
        'userId': json['userId'],
    };
}

export function EvmWalletToJSON(value?: EvmWallet | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'type': WalletTypeToJSON(value.type),
        'address': value.address,
        'userId': value.userId,
    };
}


