import {ReactElement} from "react";
import {
    MenuItem,
    NftGrouping,
} from "@devour/client";
import {verifyNftGroupOwnership} from "@/utils/verifyNftGroupOwnership";
import FrameOneModal from "../../../components/modals/modalComponents/FrameOneModal";
import FrameModalHeader from "../../../components/modals/modalComponents/FrameModalHeader";
import FrameModalBody from "../../../components/modals/modalComponents/FrameModalBody";
import {useGetUnauthenticatedNftGroupings} from "@/hooks/useGetUnauthenticatedNftGroupings";
import {useGetNftOwnershipsForUser} from "@/hooks/useGetNftOwnershipsForUser";
import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";

interface Props {
    menuItem: MenuItem;
    onToggle: () => void;
    display: boolean;
}

function MenuItemCardNftGroupingsModal(props: Props): ReactElement {
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const {data: allNftGroupings} = useGetUnauthenticatedNftGroupings(false);
    const {data: nftOwnershipResponse} = useGetNftOwnershipsForUser(fullToken);
    const menuItemNftGroupings: Array<NftGrouping> = props.menuItem.nftGroupings
        .map((menuGroupId) => {
            return allNftGroupings?.nftGroupings.find(grouping => grouping?.id === menuGroupId);
        }).filter((grouping) => {
            return grouping && verifyNftGroupOwnership(grouping.id, nftOwnershipResponse);
        });

    function renderGrouping(grouping: NftGrouping): JSX.Element {
        let ownedCount = 0;

        if (nftOwnershipResponse?.nftOwnerships) {
            for (const nftOwnership of nftOwnershipResponse.nftOwnerships[0]) {
                if (nftOwnershipResponse?.nftTrackers?.find(t => t.id === nftOwnership.nftTrackerId)?.groupingId === grouping.id) {
                    ownedCount++;
                }
            }
        }

        return (
            <li
                key={grouping.id}
                className="menu-item-card-nft-groupings-modal_list_item"
            >
                <div className="menu-item-card-nft-groupings-modal_list_item_icon">
                    <div className="menu-item-card-nft-groupings-modal_list_item_icon_inner">
                        {grouping.icon &&
                        <img
							    src={grouping.icon.url}
							    alt={`${grouping?.name} icon`}
                        />
                        }
                    </div>
                </div>
                <div className="menu-item-card-nft-groupings-modal_list_item_group">
                    <div className="menu-item-card-nft-groupings-modal_list_item_name">
                        {grouping?.name}
                    </div>
                    <div className="menu-item-card-nft-groupings-modal_list_item_owned">
                        {ownedCount} owned
                    </div>
                </div>
            </li>
        );
    }

    if (!props.menuItem) {
        return null;
    }

    return (
        <FrameOneModal
            contentClassName="menu-item-card-nft-groupings-modal"
            isOpen={props.display}
            toggle={props.onToggle}
        >
            <FrameModalHeader
                title="View all"
                toggle={props.onToggle}
            />

            <FrameModalBody className="menu-item-card-nft-groupings-modal_body">
                <p>Selected Item</p>
                <div className="menu-item-card-nft-groupings-modal_selected-item">
                    {props.menuItem?.images?.length > 0 &&
                    <div className="menu-item-card-nft-groupings-modal_selected-item_image">
						    <div className="menu-item-card-nft-groupings-modal_selected-item_image_inner">
						        <img
						            src={props.menuItem?.images[0].url}
						            alt={props.menuItem?.name}
						            onError={({currentTarget}) => {
						                currentTarget.onerror = null; // prevents looping
						                currentTarget.src = import.meta.env.VITE_CDN_URL + "/images/placeholderitem.webp";
						            }}
						        />
						    </div>
                    </div>
                    }
                    <div className="menu-item-card-nft-groupings-modal_selected-item_name">
                        {props.menuItem?.name}
                    </div>
                </div>
                <p>
					Unlocked by {menuItemNftGroupings.length} item{menuItemNftGroupings.length !== 1 && "s"}
                </p>
                <ul className="menu-item-card-nft-groupings-modal_list">
                    {menuItemNftGroupings.map(renderGrouping)}
                </ul>
            </FrameModalBody>
        </FrameOneModal>
    );
}

export default MenuItemCardNftGroupingsModal;
