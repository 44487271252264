/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AdminDebitOrCreditDpayRequestBody,
    AdminDebitOrCreditDpayRequestBodyFromJSON,
    AdminDebitOrCreditDpayRequestBodyToJSON,
    ApiError,
    ApiErrorFromJSON,
    ApiErrorToJSON,
    DpayPurchase,
    DpayPurchaseFromJSON,
    DpayPurchaseToJSON,
    DpayPurchaseRequestBody,
    DpayPurchaseRequestBodyFromJSON,
    DpayPurchaseRequestBodyToJSON,
    GetDpayPriceResponse,
    GetDpayPriceResponseFromJSON,
    GetDpayPriceResponseToJSON,
    GetDpayPurchasesResponse,
    GetDpayPurchasesResponseFromJSON,
    GetDpayPurchasesResponseToJSON,
    GetTotalDpayResponse,
    GetTotalDpayResponseFromJSON,
    GetTotalDpayResponseToJSON,
    GetTransactionsPaginatedResponse,
    GetTransactionsPaginatedResponseFromJSON,
    GetTransactionsPaginatedResponseToJSON,
    GetTransactionsPaginatedSort,
    GetTransactionsPaginatedSortFromJSON,
    GetTransactionsPaginatedSortToJSON,
    GetTransactionsResponse,
    GetTransactionsResponseFromJSON,
    GetTransactionsResponseToJSON,
    InlineResponse20016,
    InlineResponse20016FromJSON,
    InlineResponse20016ToJSON,
    SortOrder,
    SortOrderFromJSON,
    SortOrderToJSON,
} from '../models';

export interface AdminDebitOrCreditDpayRequest {
    adminDebitOrCreditDpayRequestBody?: AdminDebitOrCreditDpayRequestBody;
}

export interface GetDpayPurchaseTransactionsRequest {
    search?: string;
    limit?: number;
    offset?: number;
    numberOfAvailableOffsets?: number;
}

export interface GetIndustryCollectionPayoutsReportRequest {
    month?: string;
    year?: string;
}

export interface GetTransactionsRequest {
    id: string;
}

export interface GetTransactionsPaginatedRequest {
    id: string;
    sort?: GetTransactionsPaginatedSort;
    order?: SortOrder;
    limit?: number;
    offset?: number;
}

export interface SubmitDpayPurchaseRequest {
    id: string;
}

export interface UpsertDpayPurchaseRequest {
    dpayPurchaseRequestBody?: DpayPurchaseRequestBody;
}

export interface VerifyDpayPurchaseRequest {
    id: string;
}

/**
 * TransactionsApi - interface
 * @export
 * @interface TransactionsApiInterface
 */
export interface TransactionsApiInterface {
    /**
     * Allows an admin to debit or credit an arbitrary amount of dpay
     * @param {AdminDebitOrCreditDpayRequestBody} [adminDebitOrCreditDpayRequestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsApiInterface
     */
    adminDebitOrCreditDpayRaw(requestParameters: AdminDebitOrCreditDpayRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Allows an admin to debit or credit an arbitrary amount of dpay
     */
    adminDebitOrCreditDpay(requestParameters: AdminDebitOrCreditDpayRequest): Promise<void>;

    /**
     * Gets the current price of Dpay in USD.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsApiInterface
     */
    getDpayPriceRaw(): Promise<runtime.ApiResponse<GetDpayPriceResponse>>;

    /**
     * Gets the current price of Dpay in USD.
     */
    getDpayPrice(): Promise<GetDpayPriceResponse>;

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {number} [numberOfAvailableOffsets] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsApiInterface
     */
    getDpayPurchaseTransactionsRaw(requestParameters: GetDpayPurchaseTransactionsRequest): Promise<runtime.ApiResponse<GetDpayPurchasesResponse>>;

    /**
     */
    getDpayPurchaseTransactions(requestParameters: GetDpayPurchaseTransactionsRequest): Promise<GetDpayPurchasesResponse>;

    /**
     * Will generate a report of all the industry collection payouts for a given month and year.
     * @param {string} [month] 
     * @param {string} [year] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsApiInterface
     */
    getIndustryCollectionPayoutsReportRaw(requestParameters: GetIndustryCollectionPayoutsReportRequest): Promise<runtime.ApiResponse<Blob>>;

    /**
     * Will generate a report of all the industry collection payouts for a given month and year.
     */
    getIndustryCollectionPayoutsReport(requestParameters: GetIndustryCollectionPayoutsReportRequest): Promise<Blob>;

    /**
     * Get total FIAT DPAY amount of all existing users (in DPAY and in USD)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsApiInterface
     */
    getTotalDpayAllUsersRaw(): Promise<runtime.ApiResponse<GetTotalDpayResponse>>;

    /**
     * Get total FIAT DPAY amount of all existing users (in DPAY and in USD)
     */
    getTotalDpayAllUsers(): Promise<GetTotalDpayResponse>;

    /**
     * Used by users to get their current dpay balance and transactions stored on their account
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsApiInterface
     */
    getTransactionsRaw(requestParameters: GetTransactionsRequest): Promise<runtime.ApiResponse<GetTransactionsResponse>>;

    /**
     * Used by users to get their current dpay balance and transactions stored on their account
     */
    getTransactions(requestParameters: GetTransactionsRequest): Promise<GetTransactionsResponse>;

    /**
     * Used by users to get their current dpay balance and paginated transactions stored on their account
     * @param {string} id 
     * @param {GetTransactionsPaginatedSort} [sort] 
     * @param {SortOrder} [order] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsApiInterface
     */
    getTransactionsPaginatedRaw(requestParameters: GetTransactionsPaginatedRequest): Promise<runtime.ApiResponse<GetTransactionsPaginatedResponse>>;

    /**
     * Used by users to get their current dpay balance and paginated transactions stored on their account
     */
    getTransactionsPaginated(requestParameters: GetTransactionsPaginatedRequest): Promise<GetTransactionsPaginatedResponse>;

    /**
     * Used to purchase token with fiat
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsApiInterface
     */
    submitDpayPurchaseRaw(requestParameters: SubmitDpayPurchaseRequest): Promise<runtime.ApiResponse<InlineResponse20016>>;

    /**
     * Used to purchase token with fiat
     */
    submitDpayPurchase(requestParameters: SubmitDpayPurchaseRequest): Promise<InlineResponse20016>;

    /**
     * Creates or updates a new price quote for DPAY purchase.
     * @param {DpayPurchaseRequestBody} [dpayPurchaseRequestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsApiInterface
     */
    upsertDpayPurchaseRaw(requestParameters: UpsertDpayPurchaseRequest): Promise<runtime.ApiResponse<DpayPurchase>>;

    /**
     * Creates or updates a new price quote for DPAY purchase.
     */
    upsertDpayPurchase(requestParameters: UpsertDpayPurchaseRequest): Promise<DpayPurchase>;

    /**
     * Verify that the payment has been successfully processed and gives tokens to customer.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsApiInterface
     */
    verifyDpayPurchaseRaw(requestParameters: VerifyDpayPurchaseRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Verify that the payment has been successfully processed and gives tokens to customer.
     */
    verifyDpayPurchase(requestParameters: VerifyDpayPurchaseRequest): Promise<void>;

}

/**
 * no description
 */
export class TransactionsApi extends runtime.BaseAPI implements TransactionsApiInterface {

    /**
     * Allows an admin to debit or credit an arbitrary amount of dpay
     */
    async adminDebitOrCreditDpayRaw(requestParameters: AdminDebitOrCreditDpayRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("GodAdmin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/transactions/admin-debit-or-credit-dpay`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AdminDebitOrCreditDpayRequestBodyToJSON(requestParameters.adminDebitOrCreditDpayRequestBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Allows an admin to debit or credit an arbitrary amount of dpay
     */
    async adminDebitOrCreditDpay(requestParameters: AdminDebitOrCreditDpayRequest): Promise<void> {
        await this.adminDebitOrCreditDpayRaw(requestParameters);
    }

    /**
     * Gets the current price of Dpay in USD.
     */
    async getDpayPriceRaw(): Promise<runtime.ApiResponse<GetDpayPriceResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/transactions/dpay-price`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetDpayPriceResponseFromJSON(jsonValue));
    }

    /**
     * Gets the current price of Dpay in USD.
     */
    async getDpayPrice(): Promise<GetDpayPriceResponse> {
        const response = await this.getDpayPriceRaw();
        return await response.value();
    }

    /**
     */
    async getDpayPurchaseTransactionsRaw(requestParameters: GetDpayPurchaseTransactionsRequest): Promise<runtime.ApiResponse<GetDpayPurchasesResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.numberOfAvailableOffsets !== undefined) {
            queryParameters['numberOfAvailableOffsets'] = requestParameters.numberOfAvailableOffsets;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/transactions/dpay-purchases`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetDpayPurchasesResponseFromJSON(jsonValue));
    }

    /**
     */
    async getDpayPurchaseTransactions(requestParameters: GetDpayPurchaseTransactionsRequest): Promise<GetDpayPurchasesResponse> {
        const response = await this.getDpayPurchaseTransactionsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Will generate a report of all the industry collection payouts for a given month and year.
     */
    async getIndustryCollectionPayoutsReportRaw(requestParameters: GetIndustryCollectionPayoutsReportRequest): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.month !== undefined) {
            queryParameters['month'] = requestParameters.month;
        }

        if (requestParameters.year !== undefined) {
            queryParameters['year'] = requestParameters.year;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/transactions/industry_collection_payouts_report`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Will generate a report of all the industry collection payouts for a given month and year.
     */
    async getIndustryCollectionPayoutsReport(requestParameters: GetIndustryCollectionPayoutsReportRequest): Promise<Blob> {
        const response = await this.getIndustryCollectionPayoutsReportRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get total FIAT DPAY amount of all existing users (in DPAY and in USD)
     */
    async getTotalDpayAllUsersRaw(): Promise<runtime.ApiResponse<GetTotalDpayResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/transactions/get-total-vdpay-all-users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetTotalDpayResponseFromJSON(jsonValue));
    }

    /**
     * Get total FIAT DPAY amount of all existing users (in DPAY and in USD)
     */
    async getTotalDpayAllUsers(): Promise<GetTotalDpayResponse> {
        const response = await this.getTotalDpayAllUsersRaw();
        return await response.value();
    }

    /**
     * Used by users to get their current dpay balance and transactions stored on their account
     */
    async getTransactionsRaw(requestParameters: GetTransactionsRequest): Promise<runtime.ApiResponse<GetTransactionsResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getTransactions.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/transactions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetTransactionsResponseFromJSON(jsonValue));
    }

    /**
     * Used by users to get their current dpay balance and transactions stored on their account
     */
    async getTransactions(requestParameters: GetTransactionsRequest): Promise<GetTransactionsResponse> {
        const response = await this.getTransactionsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used by users to get their current dpay balance and paginated transactions stored on their account
     */
    async getTransactionsPaginatedRaw(requestParameters: GetTransactionsPaginatedRequest): Promise<runtime.ApiResponse<GetTransactionsPaginatedResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getTransactionsPaginated.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/transactions-paginated/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetTransactionsPaginatedResponseFromJSON(jsonValue));
    }

    /**
     * Used by users to get their current dpay balance and paginated transactions stored on their account
     */
    async getTransactionsPaginated(requestParameters: GetTransactionsPaginatedRequest): Promise<GetTransactionsPaginatedResponse> {
        const response = await this.getTransactionsPaginatedRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used to purchase token with fiat
     */
    async submitDpayPurchaseRaw(requestParameters: SubmitDpayPurchaseRequest): Promise<runtime.ApiResponse<InlineResponse20016>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling submitDpayPurchase.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/transactions/dpay-purchase/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20016FromJSON(jsonValue));
    }

    /**
     * Used to purchase token with fiat
     */
    async submitDpayPurchase(requestParameters: SubmitDpayPurchaseRequest): Promise<InlineResponse20016> {
        const response = await this.submitDpayPurchaseRaw(requestParameters);
        return await response.value();
    }

    /**
     * Creates or updates a new price quote for DPAY purchase.
     */
    async upsertDpayPurchaseRaw(requestParameters: UpsertDpayPurchaseRequest): Promise<runtime.ApiResponse<DpayPurchase>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/transactions/dpay-purchases`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DpayPurchaseRequestBodyToJSON(requestParameters.dpayPurchaseRequestBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DpayPurchaseFromJSON(jsonValue));
    }

    /**
     * Creates or updates a new price quote for DPAY purchase.
     */
    async upsertDpayPurchase(requestParameters: UpsertDpayPurchaseRequest): Promise<DpayPurchase> {
        const response = await this.upsertDpayPurchaseRaw(requestParameters);
        return await response.value();
    }

    /**
     * Verify that the payment has been successfully processed and gives tokens to customer.
     */
    async verifyDpayPurchaseRaw(requestParameters: VerifyDpayPurchaseRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling verifyDpayPurchase.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/transactions/dpay-purchase/{id}/verify`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Verify that the payment has been successfully processed and gives tokens to customer.
     */
    async verifyDpayPurchase(requestParameters: VerifyDpayPurchaseRequest): Promise<void> {
        await this.verifyDpayPurchaseRaw(requestParameters);
    }

}
