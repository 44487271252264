/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Merchant,
    MerchantFromJSON,
    MerchantFromJSONTyped,
    MerchantToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetBusinessBillingResponse
 */
export interface GetBusinessBillingResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetBusinessBillingResponse
     */
    isAccountConnected: boolean;
    /**
     * See https://stripe.com/docs/api/accounts/retrieve
     * @type {object}
     * @memberof GetBusinessBillingResponse
     */
    stripeAccount?: object;
    /**
     * Stripe client secret for Stripe Connect embedded components. https://stripe.com/docs/connect/get-started-connect-embedded-components
     * @type {string}
     * @memberof GetBusinessBillingResponse
     */
    clientSecret?: string;
    /**
     * 
     * @type {Merchant}
     * @memberof GetBusinessBillingResponse
     */
    user?: Merchant;
}

export function GetBusinessBillingResponseFromJSON(json: any): GetBusinessBillingResponse {
    return GetBusinessBillingResponseFromJSONTyped(json, false);
}

export function GetBusinessBillingResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetBusinessBillingResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isAccountConnected': json['isAccountConnected'],
        'stripeAccount': !exists(json, 'stripeAccount') ? undefined : json['stripeAccount'],
        'clientSecret': !exists(json, 'clientSecret') ? undefined : json['clientSecret'],
        'user': !exists(json, 'user') ? undefined : MerchantFromJSON(json['user']),
    };
}

export function GetBusinessBillingResponseToJSON(value?: GetBusinessBillingResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isAccountConnected': value.isAccountConnected,
        'stripeAccount': value.stripeAccount,
        'clientSecret': value.clientSecret,
        'user': MerchantToJSON(value.user),
    };
}


