import Skeleton from "react-loading-skeleton";
import {ReactElement} from "react";
function GoFrensLeaderboardMyRankInfoSkeleton(): ReactElement {
    return (
        <div className="gofrens-my-community-leaderboard_top_my-info">
            <div className="gofrens-my-community-leaderboard_top_my-info_container_skeleton-adjust">
                <h5><Skeleton height={30}/></h5>
                <p>
                    <Skeleton height={34}/>
                </p>
                <p>
                    <Skeleton height={30}/>
                </p>
            </div>
            <div className="gofrens-my-community-leaderboard_top_my-info_container_skeleton-adjust">
                <h5><Skeleton height={30}/></h5>
                <p>
                    <Skeleton height={34}/>
                </p>
                <p>
                    <Skeleton height={30}/>
                </p>
            </div>
        </div>
    );
}
export default GoFrensLeaderboardMyRankInfoSkeleton;