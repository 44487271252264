import {ButtonHTMLAttributes, ChangeEventHandler, FormEvent, ReactElement, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {Address, AddressBooksApi, CreateAddressBookBody, Token, UsersApi} from "@devour/client";
import FrameOneAutoPanel from "./autoPanelComponents/FrameOneAutoPanel";
import FrameAutoPanelHeader from "./autoPanelComponents/FrameAutoPanelHeader";
import FrameAutoPanelBody from "./autoPanelComponents/FrameAutoPanelBody";
import FrameAutoPanelFooter from "./autoPanelComponents/FrameAutoPanelFooter";
import FrameButton from "../buttons/FrameButton";
import FrameOneAddressInputGroup from "../inputs/FrameOneAddressInputGroup";
import {
    addError,
    decrementLoading,
    incrementLoading,
    updateCurrentUser,
} from "@/redux/meta/metaActions";
import getConfig from "../../utils/getConfig";
import {omit} from "lodash";

const defaultAddressForm: CreateAddressBookBody = {
    line1: "",
    line2: "",
    locality: "",
    administrativeArea: "",
    postalCode: "",
    country: "US",
    deliveryInstructions: "",
};

interface Props {
    isOpen: boolean;
    onClose: () => void;
    onDone: (newAddress: string) => void;
}

function ManageAddressBookAddAddressModal(props: Props): ReactElement {
    const dispatch = useDispatch();
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken) as Token;
    const [addressForm, setAddressForm] = useState<CreateAddressBookBody>(defaultAddressForm);

    useEffect(() => {
        if (props.isOpen) {
            setAddressForm(defaultAddressForm);
        }
    }, [props.isOpen]);

    /**
	 * Handle all text input onChange events.
	 *
	 * @param key
	 */
    function inputOnChange(key: keyof CreateAddressBookBody): ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> {
        return (e) => {
            setAddressForm((_addressForm) => {
                return {
                    ..._addressForm,
                    [key]: e.target.value,
                };
            });
        };
    }

    async function submitNewAddress(e: FormEvent<HTMLFormElement>): Promise<void> {
        e.preventDefault();
        dispatch(incrementLoading());

        try {
            const res = await new AddressBooksApi(getConfig(fullToken)).createAddressBook({
                createAddressBookBody: addressForm,
            });

            const userRes = await new UsersApi(getConfig(fullToken)).getProfile();
            dispatch(updateCurrentUser(userRes));

            props.onDone(res.id);
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            dispatch(decrementLoading());
        }
    }

    return (
        <form onSubmit={submitNewAddress}>
            <FrameOneAutoPanel
                isOpen={props.isOpen}
                toggle={props.onClose}
                size="sm"
                contentClassName="manage-address-book-add-modal"
            >
                <FrameAutoPanelHeader
                    title="Add New Address"
                    toggle={props.onClose}
                />

                <FrameAutoPanelBody className="manage-address-book-add-modal_body">
                    <FrameOneAddressInputGroup
                        value={omit(addressForm, "deliveryInstructions") as Address}
                        onChange={setAddressForm}
                    />

                    <div className="manage-address-book-add-modal_body_instructions">
                        <label>Delivery Instructions</label>
                        <textarea
                            placeholder="Enter Delivery Instructions for this Address..."
                            value={addressForm.deliveryInstructions}
                            onChange={inputOnChange("deliveryInstructions")}
                            maxLength={130}
                        />
                    </div>
                </FrameAutoPanelBody>

                <FrameAutoPanelFooter>
                    <FrameButton
                        <ButtonHTMLAttributes<HTMLButtonElement>>
                        color="purple"
                        size="normal"
                        forwardProps={{
                            type: "submit",
                        }}
                    >
						Confirm Address
                    </FrameButton>
                </FrameAutoPanelFooter>
            </FrameOneAutoPanel>
        </form>
    );
}

export default ManageAddressBookAddAddressModal;
