import {ReactElement} from "react";
import classNames from "classnames";

interface Props {
    className: string;
}
function GoFrensTutorialModalNabNft(props: Props): ReactElement {

    return (
        <div className={classNames("gofrens-tutorial-modal-nab-nft", props.className)}>
            <div>
                <p className="gofrens-tutorial-modal-nab-nft_step">
					Step 1: Get Started
                </p>
                <div className="gofrens-tutorial-modal-nab-nft_title">
                    <img
                        className="gofrens-tutorial-modal-nab-nft_title_icon"
                        src={import.meta.env.VITE_CDN_URL + "/images/Tutorial-step1-heart.webp"}
                        alt="Nab an NFT"
                    />
                    <span>
					Nab an NFT
                    </span>
                </div>
                <div className="gofrens-tutorial-modal-nab-nft_description">
					Your exclusive NFT is the key to unlock the thrilling world of GoFriends - pursue one from GoFriends
					communities!
                </div>
            </div>
            <div className="gofrens-tutorial-modal-nab-nft_image">
                <a
                    href="https://opensea.io/collection/the-industry-collection"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img
                        src={import.meta.env.VITE_CDN_URL + "/images/tutorial-step1.webp"}
                        alt="Go to OpenSea"
                    />
                </a>
            </div>
        </div>
    );
}

export default GoFrensTutorialModalNabNft;
