import useThemePreference from "@/hooks/useThemePreference";
import {ReactElement} from "react";

function CheckoutPageHeader(): ReactElement {
    const { getThemedImageUrl } = useThemePreference();
    return (

        <div className="checkout-page_header">

            <img
                src={getThemedImageUrl(import.meta.env.VITE_CDN_URL + "/images/DevourGO_Final-01.webp")}
                alt="DevourGO Logo"
                className="checkout-page_header_logo"
            />

        </div>
    );
}

export default CheckoutPageHeader;

