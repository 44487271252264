import {Restaurant, MenuOrder} from "@devour/client";
import {createContext, Dispatch, SetStateAction} from "react";
import {BrandMapStates} from "@/pages/brandMap/context/BrandMapContext";

export interface IRestaurantContext {
    restaurant?: Restaurant;
    menuOrder?: MenuOrder;
    setIsUpdateTriggered?: Dispatch<SetStateAction<boolean>>; // Trigger re-render of parent NewCheckout
    activeMenuItemId?: string;
    setActiveMenuItemId?: Dispatch<SetStateAction<string>>;
    embeddedMenu?: boolean;
    setCheckoutState?: Dispatch<SetStateAction<BrandMapStates>>;
    menuOrderId?: string;
}

const RESTAURANT_DEFAULT_VALUE: IRestaurantContext = {
    restaurant: undefined,
    menuOrder: undefined,
    activeMenuItemId: "",
    setActiveMenuItemId: () => {
    },
    embeddedMenu: undefined,
    setCheckoutState: () => {
    },
    menuOrderId: "",
};

export const RestaurantContext = createContext<IRestaurantContext>(RESTAURANT_DEFAULT_VALUE);
