import useThemePreference from "@/hooks/useThemePreference";
import {ReactElement} from "react";

function AuthHeaderLogo(): ReactElement {
    const { getThemedImageUrl } = useThemePreference();
    return (
        <div className="auth-header-logo">
            <img
                src={getThemedImageUrl(import.meta.env.VITE_CDN_URL + "/images/DevourGO_Final-01.webp")}
                alt="DevourGO Logo"
            />
        </div>
    );
}

export default AuthHeaderLogo;
