import {Dispatch, ReactElement, SetStateAction} from "react";
import {NftOwnershipInformation, NftGrouping, RestaurantSearchResult} from "@devour/client";
import {FaAngleDown} from "react-icons/fa";
import {getExclusiveItemNfts} from "../../../utils/getExclusiveItemNfts";

interface Props {
    restaurants: Array<RestaurantSearchResult>;
    isOpen: boolean;
    updateOpenStatus: Dispatch<SetStateAction<boolean>>;
    nftOwnershipResponse: NftOwnershipInformation;
}

function RestaurantSearchNftGroupingBanner(props: Props): ReactElement {

    const nftGroupingIdsActive: Array<string> = props.restaurants.map((restaurant) => {

        return getExclusiveItemNfts(restaurant);

    }) // Map to `Array<Array<string>>`
        .flat(); // Flatten to `Array<string>`

    const allNftGroupings = props.nftOwnershipResponse?.nftGroupings.concat(props.nftOwnershipResponse?.myGroupings);

    const usedNfts: Array<NftGrouping> = allNftGroupings.filter((grouping) => {
        if (props.nftOwnershipResponse?.nftOwnerships?.[0]) {
            for (const nftOwnership of props.nftOwnershipResponse?.nftOwnerships?.[0]) {
                const userHasNft = props.nftOwnershipResponse?.nftTrackers?.find(t => t.id === nftOwnership.nftTrackerId)?.groupingId === grouping.id;
                const restaurantsUseNft = nftGroupingIdsActive?.includes(grouping.id);
                if (userHasNft && restaurantsUseNft) {
                    return true;
                }
            }
        }

        return false;
    });

    return (
        <button
            className="restaurants-search-nft-grouping-banner"
            onClick={() => props.updateOpenStatus(!props.isOpen)}
        >
            <div className="restaurants-search-nft-grouping-banner_text">
                <FaAngleDown/>
                <span>Available Promotions</span>
            </div>
            <div className="restaurants-search-nft-grouping-banner_counter">
                {usedNfts?.length}
            </div>
        </button>
    );

}

export default RestaurantSearchNftGroupingBanner;
