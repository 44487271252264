/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DpayMenuOrders,
    DpayMenuOrdersFromJSON,
    DpayMenuOrdersFromJSONTyped,
    DpayMenuOrdersToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetMenuOrdersDpayDataResponse
 */
export interface GetMenuOrdersDpayDataResponse {
    /**
     * 
     * @type {Array<DpayMenuOrders>}
     * @memberof GetMenuOrdersDpayDataResponse
     */
    menuOrders: Array<DpayMenuOrders>;
}

export function GetMenuOrdersDpayDataResponseFromJSON(json: any): GetMenuOrdersDpayDataResponse {
    return GetMenuOrdersDpayDataResponseFromJSONTyped(json, false);
}

export function GetMenuOrdersDpayDataResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetMenuOrdersDpayDataResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'menuOrders': ((json['menuOrders'] as Array<any>).map(DpayMenuOrdersFromJSON)),
    };
}

export function GetMenuOrdersDpayDataResponseToJSON(value?: GetMenuOrdersDpayDataResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'menuOrders': ((value.menuOrders as Array<any>).map(DpayMenuOrdersToJSON)),
    };
}


