import {ReactElement, useEffect, useRef, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../../redux/defaultStore";

const displayLoadingUiTimeout = 4000; // change this number to alter how long loadingIncrement must be greater than 0 before the loading animations pops up

interface StateProps {
    loadingIncrement: number;
}

interface Props {
    forceRender?: boolean; // For use on individual component levels.
}

function LoadingManager(props: LoadingManagerProps): ReactElement {

    const [
        showLoading,
        setShowLoading,
    ] = useState(false);
    const loadingIncrementRef = useRef(0);

    /**
	 * Logic for only showing the loading UI if x time has passed.
	 *
	 */
    useEffect(() => {
        if (props.loadingIncrement === loadingIncrementRef.current) {
            return;
        }

        loadingIncrementRef.current = props.loadingIncrement;

        if (loadingIncrementRef.current === 0) {
            setShowLoading(false);
            return;
        }

        function check(): void {
            if (loadingIncrementRef.current > 0) {
                setShowLoading(true);
            }
        }

        let timeout;
        timeout = setTimeout(check, displayLoadingUiTimeout);

        return () => {
            clearTimeout(timeout);
        };
    }, [props.loadingIncrement]);

    if (showLoading || props.forceRender) {
        return (
            <div className="loading-manager">
                {/* <object*/}
                {/*	data="/svg/frame-one-loader.svg"*/}
                {/*	className="loading-manager_svg"*/}
                {/*	aria-label="Loading Spinner"*/}
                {/* />*/}
                <div className="spinner"/>
            </div>
        );
    }

    return null;
}

function connector() {
    return connect((store: IStore, props: Props): StateProps & Props => {
        return {
            loadingIncrement: store.metaStore.loadingIncrement,
            ...props,
        };
    });
}

type LoadingManagerProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(LoadingManager);
