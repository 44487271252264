export function sortNftGroupingsByPriority(a, b): number {
    if (a.sortPriority == null) {
        if (b.sortPriority == null) {
            return 0;
        } else {
            return 1;
        }
    } else {
        if (b.sortPriority == null) {
            return -1;
        } else {
            return b.sortPriority - a.sortPriority;
        }
    }
}