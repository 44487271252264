/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Asset,
    AssetFromJSON,
    AssetFromJSONTyped,
    AssetToJSON,
} from './';

/**
 * 
 * @export
 * @interface BrandMapAdvertisementBlock
 */
export interface BrandMapAdvertisementBlock {
    /**
     * 
     * @type {Asset}
     * @memberof BrandMapAdvertisementBlock
     */
    image?: Asset;
    /**
     * 
     * @type {string}
     * @memberof BrandMapAdvertisementBlock
     */
    url?: string;
}

export function BrandMapAdvertisementBlockFromJSON(json: any): BrandMapAdvertisementBlock {
    return BrandMapAdvertisementBlockFromJSONTyped(json, false);
}

export function BrandMapAdvertisementBlockFromJSONTyped(json: any, ignoreDiscriminator: boolean): BrandMapAdvertisementBlock {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'image': !exists(json, 'image') ? undefined : AssetFromJSON(json['image']),
        'url': !exists(json, 'url') ? undefined : json['url'],
    };
}

export function BrandMapAdvertisementBlockToJSON(value?: BrandMapAdvertisementBlock | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'image': AssetToJSON(value.image),
        'url': value.url,
    };
}


