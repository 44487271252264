/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DevourIQGetItemAvailabilityResponse
 */
export interface DevourIQGetItemAvailabilityResponse {
    /**
     * 
     * @type {string}
     * @memberof DevourIQGetItemAvailabilityResponse
     */
    menuItemUuid?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DevourIQGetItemAvailabilityResponse
     */
    isAvailable?: boolean;
}

export function DevourIQGetItemAvailabilityResponseFromJSON(json: any): DevourIQGetItemAvailabilityResponse {
    return DevourIQGetItemAvailabilityResponseFromJSONTyped(json, false);
}

export function DevourIQGetItemAvailabilityResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DevourIQGetItemAvailabilityResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'menuItemUuid': !exists(json, 'menuItemUuid') ? undefined : json['menuItemUuid'],
        'isAvailable': !exists(json, 'isAvailable') ? undefined : json['isAvailable'],
    };
}

export function DevourIQGetItemAvailabilityResponseToJSON(value?: DevourIQGetItemAvailabilityResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'menuItemUuid': value.menuItemUuid,
        'isAvailable': value.isAvailable,
    };
}


