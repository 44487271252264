import {ReactElement} from "react";
import FrameButton from "@/components/buttons/FrameButton";
import {useDispatch} from "react-redux";
import {toggleSidebar} from "@/redux/meta/metaActions";

function SidebarIndustryPassCard(): ReactElement {
    const dispatch = useDispatch();

    function toggleSidebarHelper(): void {
        dispatch(toggleSidebar(false));
    }

    return (
        <>
            <div className="sidebar-industry-card">
                <div className="sidebar-industry-card_top">
                    <p className="sidebar-industry-card_top_dollar">$0</p>
                    <p className="sidebar-industry-card_top_fee">Delivery Fee</p>
                    <p className="sidebar-industry-card_top_description">
                        <span
                            className="industry-pass-promo-banner_left_opacity-adjust">Start saving more by getting an </span>
                        <strong>Industry Pass</strong>!
                    </p>
                </div>
                <div className="sidebar-industry-card_bottom">
                    <img
                        src={import.meta.env.VITE_CDN_URL + "/images/industry-banner-graphic-desktop.webp"}
                        alt="industry banner"
                        className="sidebar-industry-card_bottom_graphic"
                    />
                    <FrameButton
                        to={"/the-industry-pass"}
                        color="white-drop-shadow-devour"
                        size="narrow"
                        className="sidebar-industry-card_bottom_button"
                        onClick={toggleSidebarHelper}
                    >
                        Start Saving!
                    </FrameButton>
                </div>
            </div>
        </>
    );
}

export default SidebarIndustryPassCard;
