import {findStoreHours} from "@/utils/CheckoutDetailsScheduleTime/findStoreHours";
import {BusinessPeriod, BusinessServiceAvailability, Restaurant} from "@devour/client";
import moment from "moment-timezone";
import {findServiceAvailabilityOverride} from "@/utils/serviceAvailabilities";
import {DateInfo} from "@/components/checkout/checkoutDetails/CheckoutDetailsScheduleTimes";

export interface Slot {
    formattedSlot: string;
    scheduleTime: number;
}

/**
 * Generates time slots for the selected date (returns array of time slots)
 * If it is today, add 2 hours from now
 * For other days, set the start time to the store's opening time
 * Round up the start time minutes to the nearest 30 minutes.
 * Ensure it does not exceed the store's closing time or the day.
 *
 * @param restaurant
 * @param selectedDate
 */
export const generateTimeSlots = (restaurant: Restaurant, selectedDate: DateInfo): Array<Slot> => {

    // Business' store hours
    const restaurantTimeZone = restaurant.timeZone;
    const storeHours: Array<BusinessServiceAvailability> = restaurant.serviceAvailabilities;
    const thisDayMoment = moment.unix(selectedDate.unix);
    const storeHoursToday = findStoreHours(selectedDate, restaurantTimeZone, storeHours);
    let timePeriods: Array<BusinessPeriod> = storeHoursToday.timePeriods;
    if (restaurant.serviceAvailabilitiesOverride?.length) {
        const serviceAvilabilityOverride = findServiceAvailabilityOverride(restaurantTimeZone, thisDayMoment, restaurant.serviceAvailabilitiesOverride);
        if (serviceAvilabilityOverride) {
            timePeriods = serviceAvilabilityOverride.timePeriods;
        }
    }
    const isSelectedDateToday = moment.unix(selectedDate.unix).isSame(moment().tz(restaurantTimeZone), "day");
    const slots: Array<Slot> = [];
    const minIncrement = 30;
    const hourBeforeMidnight = 23;
    const hoursGap = 2; // Give at least 2 hours period from now for the restaurant to prepare the order

    let startTime = moment().tz(restaurantTimeZone);

    if (isSelectedDateToday) {
        // If it is today, start from now + 2 hours
        startTime = startTime.add(hoursGap, "hours");
    } else if (timePeriods[0]) { // Check that the restaurant is open on this day
        // For other days, start from the restaurant opening time
        startTime = moment.tz(timePeriods[0].timeStart, "HH:mm", restaurantTimeZone);
    } else {
        // restaurant not open on this day.
        return [];
    }

    // eslint-disable-next-line max-len
    // Adjust start time mins. Round up to the nearest 30 minutes unless it pushes past 23:59 (we don't want this)
    const minutes = startTime.minutes();
    if (minutes > 0 && !(startTime.hours() === hourBeforeMidnight && minutes > minIncrement)) {
        startTime.add(minIncrement - minutes % minIncrement, "minutes");
        startTime.seconds(0);
    }

    const endTime = moment.tz(timePeriods[0].timeEnd, "HH:mm", restaurantTimeZone);

    while (startTime.isBefore(endTime)) {
        const slotEndTime = startTime.clone().add(minIncrement, "minutes");

        // Ensure slotEndTime does not exceed the store's closing time or the day
        if (slotEndTime.isAfter(endTime) || slotEndTime.format("HH:mm") === "00:00") {
            break; // Exit the loop to avoid adding slots that extend into the next day
        }

        const formattedSlot = `${startTime.format("h:mm A")} - ${slotEndTime.format("h:mm A")}`;

        slots.push({
            formattedSlot,
            scheduleTime: startTime.unix(),
        });

        // Update startTime to the next interval start time
        startTime = slotEndTime;
    }

    return slots;
};
