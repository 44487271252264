/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Brand,
    BrandFromJSON,
    BrandFromJSONTyped,
    BrandToJSON,
    GetBrandsResponseAllOf,
    GetBrandsResponseAllOfFromJSON,
    GetBrandsResponseAllOfFromJSONTyped,
    GetBrandsResponseAllOfToJSON,
    PaginatedResponse,
    PaginatedResponseFromJSON,
    PaginatedResponseFromJSONTyped,
    PaginatedResponseToJSON,
    PaginationInfo,
    PaginationInfoFromJSON,
    PaginationInfoFromJSONTyped,
    PaginationInfoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetBrandsResponse
 */
export interface GetBrandsResponse extends PaginatedResponse {
    /**
     * 
     * @type {Array<Brand>}
     * @memberof GetBrandsResponse
     */
    brands: Array<Brand>;
}

export function GetBrandsResponseFromJSON(json: any): GetBrandsResponse {
    return GetBrandsResponseFromJSONTyped(json, false);
}

export function GetBrandsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetBrandsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...PaginatedResponseFromJSONTyped(json, ignoreDiscriminator),
        'brands': ((json['brands'] as Array<any>).map(BrandFromJSON)),
    };
}

export function GetBrandsResponseToJSON(value?: GetBrandsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...PaginatedResponseToJSON(value),
        'brands': ((value.brands as Array<any>).map(BrandToJSON)),
    };
}


