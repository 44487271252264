import {ReactElement} from "react";
import {isDesktop} from "react-device-detect";
import GoFrensMyCommunityLeaderboardSkeleton
    from "@/components/skeletons/GoFrensPage/GoFrensMyCommunityLeaderboardSkeleton";

function GoFrensTopLeaderboardSkeleton(): ReactElement {

    if (!isDesktop) {
        return <GoFrensMyCommunityLeaderboardSkeleton rows={5}/>;
    }
    return (
        <>
            <GoFrensMyCommunityLeaderboardSkeleton
                width="47%"
                rows={5}
            />
            <GoFrensMyCommunityLeaderboardSkeleton
                width="47%"
                rows={5}
            />
        </>
    );
}

export default GoFrensTopLeaderboardSkeleton;
