import {ReactElement, useRef} from "react";
import LoadDpayPanel from "@/components/loadDpay/LoadDpayPanel";
import {getWindowBreakpoint} from "@/utils/getWindowBreakpoint";
import useWindowSize from "@/hooks/useWindowSize";
import {isDesktop, isTablet} from "react-device-detect";
import BackgroundTriangleSVG from "@/components/BackgroundTriangleSVG";
import FrameButton from "@/components/buttons/FrameButton";
import {ReactComponent as EllipseTop} from "../svgs/ellipse-gradient-2.svg";
import {ReactComponent as EllipseMid} from "../svgs/ellipse-gradient-1.svg";
import {ReactComponent as EllipseBottom} from "../svgs/ellipse-gradient-3.svg";
import {ReactComponent as DoubleArrowDown} from "../svgs/double-arrow-down-gradient.svg";
import {ReactComponent as DMDoubleArrowDown} from "../svgs/DM-double-arrow-down-gradient.svg";
import {useNavigate} from "react-router-dom";
import useThemePreference from "@/hooks/useThemePreference";
import {useGate} from "statsig-react";


function BuyDPay(): ReactElement {
    const aboutSectionRef = useRef(null);
    const windowBreakPoint = getWindowBreakpoint(useWindowSize()[0]);
    const navigate = useNavigate();
    const { isOnDarkMode, getThemedImageUrl } = useThemePreference();
    const { value: dpayStatus } = useGate(import.meta.env.VITE_TOKEN_STATSIG_STATUS);

    function scrollToAboutSection(): void {
        aboutSectionRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "start",
        });
    }

    function renderPromoCards() {
        return (
            <div className="buy-dpay-page_about_content_promos">
                <div className="buy-dpay-page_about_content_promos_card">
                    <img
                        src={`${import.meta.env.VITE_CDN_URL}/images/load-FUEL-Mobile.webp`}
                        className="buy-dpay-page_about_content_promos_card_img-container_image"
                        alt="DPAY payment method"
                    />
                    <div className="buy-dpay-page_about_content_promos_card_info">
                        <span className="buy-dpay-page_about_content_promos_card_info_label">{import.meta.env.VITE_TOKEN_NAME} Payment Method</span>
                        <h2>Use {import.meta.env.VITE_TOKEN_NAME} For Order Payments</h2>
                        <p>
                            Seamlessly pay for your DevourGO orders with {import.meta.env.VITE_TOKEN_NAME},
                            making your dining experiences more efficient and valuable!
                        </p>
                    </div>
                </div>
                <div className="buy-dpay-page_about_content_promos_card">
                    <img
                        src={`${import.meta.env.VITE_CDN_URL}/images/load-FUEL-Wallet.webp`}
                        className="buy-dpay-page_about_content_promos_card_img-container_image"
                        alt="DPAY back"
                    />
                    <div className="buy-dpay-page_about_content_promos_card_info">
                        <span className="buy-dpay-page_about_content_promos_card_info_label">5% {import.meta.env.VITE_TOKEN_NAME} Back</span>
                        <h2>Unlock 5% {import.meta.env.VITE_TOKEN_NAME} Back Rewards</h2>
                        <p>
                            Every time you use {import.meta.env.VITE_TOKEN_NAME} for DevourGO orders, enjoy the thrill of earning 5% {import.meta.env.VITE_TOKEN_NAME} Back rewards.
                        </p>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="buy-dpay-page">
            <div
                className="buy-dpay-page_header"
                style={{
                    backgroundImage: `url(${getThemedImageUrl(`${import.meta.env.VITE_CDN_URL}/images/load-FUEL-bnr-${windowBreakPoint}.webp`)})`,
                }}
            >
                <div className="buy-dpay-page_header_text-container">
                    <h1 className="buy-dpay-page_header_text-container_title">
                        <span className="buy-dpay-page_header_text-container_title_highlight">
                            Fuel-Up
                        </span>
                    </h1>
                    <h4 className="buy-dpay-page_header_text-container_subtitle">
                        <span>Your Ultimate Payment {"\n"}</span>
                        <span>& Rewards Token for DevourGo</span>
                    </h4>
                    {(isDesktop || isTablet) &&
                        <div className="buy-dpay-page_header_text-container_info">
                            Dive into the world of {import.meta.env.VITE_TOKEN_NAME} by using a debit or credit card to purchase your {import.meta.env.VITE_TOKEN_NAME} tokens,
                            opening
                            the door to endless possibilities in the DevourGO universe.
                        </div>
                    }
                </div>
                <LoadDpayPanel/>

                {false &&
                    <div className="buy-dpay-page_header_swap-links">
                        <strong>Prefer to Swap on-chain?</strong>
                        <span> {import.meta.env.VITE_TOKEN_NAME}/ETH - </span>
                        <a
                            href={`https://app.uniswap.org/swap?inputCurrency=ETH&outputCurrency=${import.meta.env.VITE_DPAY_TOKEN_ADDRESS_ETHEREUM}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Uniswap
                        </a>
                        <span>{import.meta.env.VITE_TOKEN_NAME}/SOL - </span>
                        <a
                            href="https://jup.ag/swap/SOL-DPAY"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Jupiter
                        </a>
                    </div>
                }

                {isOnDarkMode
                    ? <DMDoubleArrowDown
                        className="buy-dpay-page_header_scroll-arrow"
                        onClick={scrollToAboutSection}
                    />
                    : <DoubleArrowDown
                        className="buy-dpay-page_header_scroll-arrow"
                        onClick={scrollToAboutSection}
                    />}

            </div>
            <div className="buy-dpay-page_about">
                <BackgroundTriangleSVG
                    size={windowBreakPoint}
                    rotated={true}
                    flipped={false}
                    colour={isOnDarkMode
                        ? "#100F15"
                        : "white"}
                />
                <div
                    className="buy-dpay-page_about_content"
                    ref={aboutSectionRef}
                >
                    <div className="buy-dpay-page_about_content_header">
                        <h1>
                            <span>WHAT CAN YOU DO {"\n"}</span>
                            <span>WITH {import.meta.env.VITE_TOKEN_NAME}?</span>
                        </h1>
                    </div>
                    {renderPromoCards()}
                    <div className="buy-dpay-page_about_content_transition">
                        <div className="buy-dpay-page_about_content_transition_ellipses">
                            <EllipseTop />
                            <EllipseMid />
                            <EllipseBottom />
                        </div>
                        <div className="buy-dpay-page_about_content_transition_text">
                            Want to save more? <br /> Discover our VIP Pass.
                        </div>
                    </div>
                    <div className="buy-dpay-page_about_content_industry-pass">
                        <div className="buy-dpay-page_about_content_industry-pass_info">
                            <h2>
                                <span>Earn {import.meta.env.VITE_TOKEN_NAME} With </span>
                                <span className="buy-dpay-page_about_content_industry-pass_info_highlight"> The Industry Pass</span>
                            </h2>
                            <p>
                                Obtain {import.meta.env.VITE_TOKEN_NAME} effortlessly by holding our
                                exclusive Industry Pass — making your {import.meta.env.VITE_TOKEN_NAME} experience even more rewarding.
                            </p>
                            <FrameButton
                                color="purple"
                                size="large"
                                className="buy-dpay-page_about_content_industry-pass_button"
                                onClick={() => navigate("/the-industry-pass")}
                            >
                                Explore The Industry Pass
                            </FrameButton>
                        </div>
                        <img
                            src={`${import.meta.env.VITE_CDN_URL}/images/BuyDPAY-IndustryPass-img.webp`}
                            alt="Industry Pass Promo"
                        />
                    </div>
                </div>

            </div>
        </div>
    );
}

export default BuyDPay;