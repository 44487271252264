import {useLocation, useNavigate} from "react-router";
import {FiChevronLeft} from "react-icons/fi";
import {NftGrouping, NftOwnership, Token} from "@devour/client";
import {ReactNode, useEffect, useState} from "react";
import GoFrensCommunityCard from "@/components/goFrens/GoFrensCommunityCard";
import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {useGetNftOwnershipsForUser} from "@/hooks/useGetNftOwnershipsForUser";
import GoFrensNftGroupingsSkeleton from "@/components/skeletons/GoFrensPage/GoFrensNftGroupingsSkeleton";
import GoFrensNftCard from "@/components/goFrens/GoFrensNftCard";
import {useGetNftOwnerships} from "@/hooks/useGetNftOwnerships";
import {isDesktop, isMobile, isTablet} from "react-device-detect";
import GoFrensMyNftsSkeleton from "@/components/skeletons/GoFrensPage/GoFrensMyNftsSkeleton";

export default function GoFrensSearchResultsPage() {
    const location = useLocation();
    const history = useNavigate();
    const {keyword, communities, otherCommunities} = location.state;

    const fullToken = useSelector<IStore>(state => state.authStore.fullToken);
    const [
        nftResults,
        setNftResults,
    ] = useState<Array<NftOwnership>>(undefined);
    const {data: nftOwnershipsData} = useGetNftOwnerships(fullToken as Token);
    const { data: nftOwnershipResponse} = useGetNftOwnershipsForUser(fullToken as Token);

    useEffect(() => {
        if (nftOwnershipsData) {
            const filteredCommunitiesIds = communities.map(community => community.id);
            const filteredNfts = nftOwnershipsData.nftOwnerships.filter(ownership => filteredCommunitiesIds.includes(ownership.nftGroupingId));
            setNftResults(filteredNfts);
        }
    }, [nftOwnershipsData]);

    function renderNftGrouping(grouping: NftGrouping): ReactNode {
        let ownedCount = 0;
        if (nftOwnershipsData?.nftOwnerships) {
            for (const nftOwnership of nftOwnershipsData.nftOwnerships) {
                if (nftOwnership.tracker.groupingId === grouping.id) {
                    ownedCount++;
                }
            }
        }
        return (
            <GoFrensCommunityCard
                key={grouping.id}
                grouping={grouping}
                trackers={nftOwnershipResponse?.nftTrackers?.filter(t => t.groupingId === grouping.id)}
                owned={ownedCount}
                onlyShowOwned={true}
            />
        );
    }
    function renderResultsContent() {
        return (
            <div className="gofrens-search_container_results">
                <div className="gofrens-search-container_results_communities">
                    <p className="gofrens-search_container_results_communities_header">
                        Communities
                    </p>
                    {communities.length &&
                    <div className="gofrens-search_container_results_communities_body">
                        {nftOwnershipResponse
                            ? communities.map(community => renderNftGrouping(community))
                            : <GoFrensNftGroupingsSkeleton noOfCards={isDesktop
                                ? 4
                                : 2}/>}
                    </div>
                    }
                </div>
                <div className="gofrens-search_container_results_nfts">
                    <p className="gofrens-search_container_results_nfts_header">
                        NFTs
                    </p>
                    {nftResults?.length &&
                    <div className="gofrens-search_container_results_nfts_body">
                        {nftOwnershipsData
                            ? nftResults.map(ownership => <GoFrensNftCard
                                key={ownership.id}
                                ownership={ownership}
                                className="gofrens-search-result"
                            />)
                            : <GoFrensMyNftsSkeleton noOfCards={isDesktop
                                ? 6
                                : !isTablet && isMobile
                                    ? 2
                                    : 4} />}
                    </div>
                    }
                </div>
            </div>
        );
    }
    function renderNoResultsFound() {
        return (
            <>
                <div className="gofrens-search_container_no-results">
                    <div className="gofrens-search_container_no-results_content">
                        <img
                            src={import.meta.env.VITE_CDN_URL + "/images/gofrens-question-mark.webp"}
                            alt="question-mark"/>
                        <h3>Oops, Nothing Found!</h3>
                        <p className="gofrens-search_container_no-results_content_message">Sorry, we couldn't find anything. Feel free to broaden your search or check other communities below.</p>

                    </div>
                </div>
                <div className="gofrens-search_container_other-communities">
                    <h3 className="gofrens-search_container_other-communities_title">
                        Explore Other Communities
                    </h3>
                    <div className="gofrens-search_container_other-communities_body">
                        {otherCommunities.length &&
                        <>
                            {nftOwnershipResponse
                                ? otherCommunities.map(community => <GoFrensCommunityCard
                                    key={community.id}
                                    grouping={community}
                                    trackers={nftOwnershipResponse?.nftTrackers?.filter(t => t.groupingId === community.id)}
                                    owned={0}
                                />)
                                : <GoFrensNftGroupingsSkeleton noOfCards={isDesktop
                                    ? 4
                                    : 2}/>}
                        </>
                        }
                    </div>
                </div>
            </>
        );
    }
    return (
        <div className="gofrens-search">
            <div className="gofrens-search_container">
                <div
                    className="gofrens-search_container_back"
                    onClick={() => {
                        history("/gofriends");
                    }}
                >
                    <FiChevronLeft className="view-all_back_icon"/>
                    <p>Go Back</p>
                </div>
                <h3 className="gofrens-search_container_title">Search Result for "{keyword}"</h3>
                {communities.length > 0
                    ? renderResultsContent()
                    : renderNoResultsFound()
                }
            </div>

        </div>
    );
}