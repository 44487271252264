import {ReactElement, useEffect} from "react";
import {addError, decrementLoading, incrementLoading, updateCurrentUser} from "../redux/meta/metaActions";
import {connect, useSelector} from "react-redux";
import {useNavigate, useSearchParams} from "react-router-dom";
import {UsersApi} from "@devour/client";
import getConfig, {getMagicConfig} from "../utils/getConfig";
import {login} from "../redux/auth/authActions";
import {getRedirectRoute} from "../utils/redirectRouteHandler";
import {IStore} from "../redux/defaultStore";
import {magic} from "../utils/magic";

function MagicRedirectSocial(props): ReactElement {

    let [
        searchParams,
        setSearchParams,
    ] = useSearchParams();
    const navigate = useNavigate();
    const lastSearchedPlaceId = useSelector((store: IStore) => store.metaStore.lastSearchedPlaceId);
    const lastRestaurantId = useSelector((store: IStore) => store.metaStore.lastRestaurantId);
    const lastMenuOrderId = useSelector((store: IStore) => store.metaStore.lastMenuOrderId);
    const currentUser = useSelector((store: IStore) => store.metaStore.currentUser);
    const addressBooks = currentUser?.user?.addresses;

    useEffect(() => {
        props.dispatch(incrementLoading());

        void handleRedirect();

        return () => {
            props.dispatch(decrementLoading());
        };
    }, []);

    async function handleRedirect() {

        // check to see if a redirect query param is in the query
        const redirect = searchParams.get("redirect");
        const redirectExists = redirect != null && redirect !== "" && redirect !== "null";

        // check to see if the magic_credential is in the query
        const magicCredential = searchParams.get("magic_credential");

        try {

            // call the login api with the magic_credential, if the API returns 200 with a token, then log the user in as normal
            const res = await new UsersApi(getMagicConfig(magicCredential)).magicLogin();

            // grab the profile to save into redux
            const userRes = await new UsersApi(getConfig(res.token)).getProfile();

            // login to magic locally
            await magic.auth.loginWithCredential(magicCredential);

            //  save the token and profile into redux
            await props.dispatch(login(res.token));
            await props.dispatch(updateCurrentUser(userRes));

            // redirect the user to the appropriate page
            navigate(await getRedirectRoute(redirect, lastSearchedPlaceId, lastRestaurantId, lastMenuOrderId, addressBooks), {replace: true});

        } catch (err) {
            // if the API returns an error and the error has status 403, then the user needs to be redirected to the signup page
            // this should also include a ?redirect query param if one is provided on this page
            if (err.status === 401) {
                navigate(`/sign-up?magic_credential=${magicCredential}${redirectExists
                    ? `&redirect=${redirect}`
                    : ""}`, {replace: true});
            } else {
                props.dispatch(await addError(err));
                navigate(`/log-in${redirectExists
                    ? `&redirect=${redirect}`
                    : ""}`, {replace: true});
            }

        }
    }

    return null;
}

export default connect()(MagicRedirectSocial);
