/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MenuOrderItemBase,
    MenuOrderItemBaseFromJSON,
    MenuOrderItemBaseFromJSONTyped,
    MenuOrderItemBaseToJSON,
    MenuOrderSubItem,
    MenuOrderSubItemFromJSON,
    MenuOrderSubItemFromJSONTyped,
    MenuOrderSubItemToJSON,
} from './';

/**
 * Represents a single item in the menu order.
 * @export
 * @interface MenuOrderItem
 */
export interface MenuOrderItem extends MenuOrderItemBase {
    /**
     * 
     * @type {string}
     * @memberof MenuOrderItem
     */
    menuItemId?: string;
    /**
     * 
     * @type {number}
     * @memberof MenuOrderItem
     */
    subtotal: number;
    /**
     * 
     * @type {number}
     * @memberof MenuOrderItem
     */
    tax: number;
    /**
     * 
     * @type {Array<MenuOrderSubItem>}
     * @memberof MenuOrderItem
     */
    customizations?: Array<MenuOrderSubItem>;
}

export function MenuOrderItemFromJSON(json: any): MenuOrderItem {
    return MenuOrderItemFromJSONTyped(json, false);
}

export function MenuOrderItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): MenuOrderItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...MenuOrderItemBaseFromJSONTyped(json, ignoreDiscriminator),
        'menuItemId': !exists(json, 'menuItemId') ? undefined : json['menuItemId'],
        'subtotal': json['subtotal'],
        'tax': json['tax'],
        'customizations': !exists(json, 'customizations') ? undefined : ((json['customizations'] as Array<any>).map(MenuOrderSubItemFromJSON)),
    };
}

export function MenuOrderItemToJSON(value?: MenuOrderItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...MenuOrderItemBaseToJSON(value),
        'menuItemId': value.menuItemId,
        'subtotal': value.subtotal,
        'tax': value.tax,
        'customizations': value.customizations === undefined ? undefined : ((value.customizations as Array<any>).map(MenuOrderSubItemToJSON)),
    };
}


