import { BusinessServiceAvailabilityOverride } from "@devour/client";
import moment, {Moment} from "moment-timezone";

/**
 * Find the service availability override for the day if it exists.
 * @param timeZone
 * @param momentCheck
 * @param serviceAvailabilitiesOverride
 */
export function findServiceAvailabilityOverride(timeZone: string, momentCheck: Moment, serviceAvailabilitiesOverride: Array<BusinessServiceAvailabilityOverride>): BusinessServiceAvailabilityOverride | false {
    if (!serviceAvailabilitiesOverride?.length) {
        return false;
    }

    const serviceDayOverride = serviceAvailabilitiesOverride.find((service) => {
        return moment.tz(service.date, timeZone).isSame(momentCheck, "day");
    });

    return serviceDayOverride || false;
}

