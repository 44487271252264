/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Represents sensitive API keys for a business.
 * @export
 * @interface BulkToggleDevourIQBody
 */
export interface BulkToggleDevourIQBody {
    /**
     * 
     * @type {boolean}
     * @memberof BulkToggleDevourIQBody
     */
    dryRun: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BulkToggleDevourIQBody
     */
    isEnabled: boolean;
}

export function BulkToggleDevourIQBodyFromJSON(json: any): BulkToggleDevourIQBody {
    return BulkToggleDevourIQBodyFromJSONTyped(json, false);
}

export function BulkToggleDevourIQBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): BulkToggleDevourIQBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dryRun': json['dryRun'],
        'isEnabled': json['isEnabled'],
    };
}

export function BulkToggleDevourIQBodyToJSON(value?: BulkToggleDevourIQBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dryRun': value.dryRun,
        'isEnabled': value.isEnabled,
    };
}


