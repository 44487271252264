/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ImportBusinessResponseItem
 */
export interface ImportBusinessResponseItem {
    /**
     * 
     * @type {boolean}
     * @memberof ImportBusinessResponseItem
     */
    isSuccess?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ImportBusinessResponseItem
     */
    isNew?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ImportBusinessResponseItem
     */
    errorMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportBusinessResponseItem
     */
    businessId?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportBusinessResponseItem
     */
    chowlyApi?: string;
    /**
     * 
     * @type {number}
     * @memberof ImportBusinessResponseItem
     */
    oloVendorId?: number;
    /**
     * 
     * @type {number}
     * @memberof ImportBusinessResponseItem
     */
    createdAt?: number;
}

export function ImportBusinessResponseItemFromJSON(json: any): ImportBusinessResponseItem {
    return ImportBusinessResponseItemFromJSONTyped(json, false);
}

export function ImportBusinessResponseItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImportBusinessResponseItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isSuccess': !exists(json, 'isSuccess') ? undefined : json['isSuccess'],
        'isNew': !exists(json, 'isNew') ? undefined : json['isNew'],
        'errorMessage': !exists(json, 'errorMessage') ? undefined : json['errorMessage'],
        'businessId': !exists(json, 'businessId') ? undefined : json['businessId'],
        'chowlyApi': !exists(json, 'chowlyApi') ? undefined : json['chowlyApi'],
        'oloVendorId': !exists(json, 'oloVendorId') ? undefined : json['oloVendorId'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
    };
}

export function ImportBusinessResponseItemToJSON(value?: ImportBusinessResponseItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isSuccess': value.isSuccess,
        'isNew': value.isNew,
        'errorMessage': value.errorMessage,
        'businessId': value.businessId,
        'chowlyApi': value.chowlyApi,
        'oloVendorId': value.oloVendorId,
        'createdAt': value.createdAt,
    };
}


