import {useQuery} from "@tanstack/react-query";
import {CommerceApi, InlineResponse2007, Token} from "@devour/client";
import getConfig from "@/utils/getConfig";

async function fetchStripePaymentMethodList(): Promise<InlineResponse2007> {
    return await new CommerceApi(getConfig()).stripePaymentMethodList();
}

function stripePaymentMethodListQuery(fullToken: Token) {
    return {
        queryKey: [
            "stripe-payment-method-list",
            fullToken?.id,
        ],
        queryFn: () => fetchStripePaymentMethodList(),
        staleTime: 24 * 60 * 60000,
        enabled: !!fullToken,
    } as const;
}

export function useGetStripePaymentMethodList(fullToken: Token) {
    return useQuery(stripePaymentMethodListQuery(fullToken));
}