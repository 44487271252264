import {ReactElement, useState} from "react";
import {formatNumberWithCommas} from "@/utils/formatNumberWithCommas";
import {FiChevronRight} from "react-icons/fi";
import {useSelector} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import DPayHistoryModal from "../modals/DPayHistoryModal";
import classNames from "classnames";
import Toast from "../Toast";
import Skeleton from "react-loading-skeleton";
import {useGetTransactionsPaginated} from "@/hooks/useGetTransactionsPaginated";
import {useGate} from "statsig-react";
import {isDesktop, isTablet} from "react-device-detect";
import SignUpToast from "../SignUpToast";

function GoVipHeaderDpayCard(): ReactElement {

    const currentUser = useSelector((store: IStore) => store.metaStore.currentUser);
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const {value: dpayStatus} = useGate(import.meta.env.VITE_TOKEN_STATSIG_STATUS);

    const [
        showDPayHistoryModal,
        setShowDPayHistoryModal,
    ] = useState<boolean>(false);

    const [
        showToast,
        setShowToast,
    ] = useState<boolean>(false);

    const {
        data: transactionsData,
        isError: isTransactionsDataError,
    } = useGetTransactionsPaginated(fullToken, currentUser?.user?.id);
    const [
        showDpayErrorToast,
        setShowDpayErrorToast,
    ] = useState<boolean>(false);

    function handleToastDismissal() {
        setShowToast(false);
        setShowDpayErrorToast(false);
    }

    function toggleDPayHistoryModal() {
        if (fullToken) {
            setShowDPayHistoryModal(!showDPayHistoryModal);
        } else {
            setShowToast(true);
        }
    }

    if (!dpayStatus) {
        return null;
    }

    return (
        <>
            <SignUpToast
                isOpen={showToast}
                onDismiss={handleToastDismissal}
            />

            <Toast
                duration={3000}
                isOpen={showDpayErrorToast}
                message={`Uh oh... it looks like ${import.meta.env.VITE_TOKEN_NAME} prices are unavailable right now. Please check back in a bit!`}
                variant="error"
                onDismiss={handleToastDismissal}
            />
            {fullToken &&
                <>
                    <DPayHistoryModal
                        isOpen={showDPayHistoryModal}
                        onClose={toggleDPayHistoryModal}
                    />
                </>
            }

            <div className={classNames("go-vip-top_header_dpay-card", {
                "desktop": isDesktop && !isTablet,
            })}
            onClick={() => {
                !transactionsData && isTransactionsDataError
                    ? setShowDpayErrorToast(true)
                    : toggleDPayHistoryModal();
            }}
            >
                <div className="go-vip-top_header_dpay-card_content">
                    {fullToken && !transactionsData && isTransactionsDataError
                        ? <div>FUEL prices are unavailable at the moment.</div>
                        : <><div className="go-vip-top_header_dpay-card_content_icon_container">
                            <img
                                className="go-vip-top_header_dpay-card_content_icon"
                                src={`${import.meta.env.VITE_CDN_URL}/images/FUEL.webp`}
                                alt="DevourGO Logo"
                            />
                            <h5>{import.meta.env.VITE_TOKEN_NAME} Balance</h5>
                        </div>
                        <div className="go-vip-top_header_dpay-card_content_left-spacer"/>
                        <div className="go-vip-top_header_dpay-card_content_balances">
                            <p className="go-vip-top_header_dpay-card_content_balances_dpay">
                                {!fullToken
                                    ? `0 ${import.meta.env.VITE_TOKEN_NAME}`
                                    : !transactionsData
                                        ? <Skeleton width={200}/>
                                        : `${formatNumberWithCommas(transactionsData.balance)} ${import.meta.env.VITE_TOKEN_NAME}`}
                            </p>
                            <p className="go-vip-top_header_dpay-card_content_balances_fiat">
                                {!fullToken
                                    ? "0 USD"
                                    : !transactionsData
                                        ? <Skeleton width={200}/>
                                        : `$${Intl.NumberFormat("en-us", {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        }).format(transactionsData.balanceInUsd)} USD`
                                }
                            </p>
                        </div>
                        <div className="go-vip-top_header_dpay-card_content_right-spacer"/>
                        <FiChevronRight className="go-vip-top_header_dpay-card_content_chevron"/>
                        </>
                    }
                </div>
            </div>
        </>
    );
}

export default GoVipHeaderDpayCard;
