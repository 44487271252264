import {isDesktop, isTablet} from "react-device-detect";

/**
 * Function checks if the FrameOneAutoPanel should be a modal instead of the bottom panel
 */
export function isModal(modalOnTablet: boolean, modalOnMobile: boolean): boolean {
    if (isDesktop) {
        return true;
    }
    if (isTablet) {
        return !!modalOnTablet;
    }
    return !!modalOnMobile;
}
