import { FC, useEffect, useState } from "react";
import { OnboardingModalSteps } from "./OnboardingModalSteps";
import classNames from "classnames";
import FrameOneAutoPanel from "@/components/modals/autoPanelComponents/FrameOneAutoPanel";
import FrameAutoPanelBody from "@/components/modals/autoPanelComponents/FrameAutoPanelBody";
import OnboardingModalGetStarted from "./OnboardingModalGetStarted";
import FrameButton from "@/components/buttons/FrameButton";
import OnboardingModalCommonStep from "./OnboardingModalCommonStep";
import { isMobile, isTablet } from "react-device-detect";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import OnboardingStepper from "./OnboardingStepper";
import { useSwipeable } from "react-swipeable";
import { IoClose } from "react-icons/io5";

interface stepData {
    render: JSX.Element;
    showStepper: boolean;
    wrapInArrows: boolean;
    button?: JSX.Element;
}

interface props {
    isOpen: boolean;
    toggle: (openLogin?: boolean) => void;
}

const OnboardingModal: FC<props> = ({ isOpen, toggle }) => {
    const [currentStep, setCurrentStep] = useState<OnboardingModalSteps>(OnboardingModalSteps.GET_STARTED_STEP);
    const imgPrefix = isMobile ? "sm" : "lg";
    const canSwipe = currentStep !== OnboardingModalSteps.GET_STARTED_STEP && isMobile && isOpen;
    const steps: Record<OnboardingModalSteps, stepData> = {
        [OnboardingModalSteps.GET_STARTED_STEP]: {
            render: <OnboardingModalGetStarted />,
            showStepper: false,
            wrapInArrows: false,
            button:
        <FrameButton
            onClick={() => setCurrentStep(OnboardingModalSteps.RESTAURANTS_STEP)}
            size="large"
            className="get-started-button"
            color="purple"
        >
          Get started
        </FrameButton>
            ,
        },

        [OnboardingModalSteps.RESTAURANTS_STEP]: {
            render:
        <OnboardingModalCommonStep
            image={`/images/onboarding-img1-${imgPrefix}.webp`}
            title="Browse 80,000+ Restaurants. Order!"
            subtitle="Discover local restaurants and order your favorite meals."
        />,
            showStepper: true,
            wrapInArrows: true,
        },

        [OnboardingModalSteps.PLAY_AND_CONNECT_STEP]: {
            render:
        <OnboardingModalCommonStep
            image={`/images/onboarding-img2-${imgPrefix}.webp`}
            title="Play and Connect!"
            subtitle="Complete fun quests while you eat to earn points in GoVIP."
        />,
            showStepper: true,
            wrapInArrows: true,
        },

        [OnboardingModalSteps.DPAY_STEP]: {
            render:
        <OnboardingModalCommonStep
            image={`/images/onboarding-img3-${imgPrefix}.webp`}
            title="Discover and Use Fuel Token."
            subtitle="Utilize Fuel to unlock endless possibilities in DevourGO."
        />,
            showStepper: true,
            wrapInArrows: true,
        },

        [OnboardingModalSteps.DEALS_STEP]: {
            render:
        <OnboardingModalCommonStep
            image={`/images/onboarding-img4-${imgPrefix}.webp`}
            title={<>Exclusive Deals <br/> — Everywhere.</>}
            subtitle="Dive in and discover exciting ways to save on every order!"
        />,
            showStepper: false,
            wrapInArrows: true,
            button:
        <FrameButton
            onClick={() => {
                toggle();
            }}
            size="large"
            className="jump-to-button"
            color="purple"
        >
          Jump into DevourGO!
        </FrameButton>
            ,
        },
    };

    const handleNextStep = () => {
        if (currentStep === OnboardingModalSteps.DEALS_STEP) {
            toggle();
        } else {
            setCurrentStep(currentStep + 1);
        }
    };

    const handlePreviousStep = () => {
        setCurrentStep(currentStep - 1);
    };

    const swipeHandlers = useSwipeable({
        onSwipedLeft: () => {
            canSwipe && handleNextStep();
        },
        onSwipedRight: () => {
            canSwipe && handlePreviousStep();
        },
        trackMouse: true,
    });

    useEffect(() => {
        if (!isOpen) {
            setCurrentStep(OnboardingModalSteps.GET_STARTED_STEP);
        }
    }, [isOpen]);

    const renderHeadingButton = () => {
        switch (currentStep) {
            case OnboardingModalSteps.GET_STARTED_STEP:
                return (
                    <>
                        <IoClose scale={2} onClick={() => toggle()} className="close-button"/>
                        <FrameButton
                            onClick={() => {
                                toggle(true);
                            }}
                            size="pill"
                            className="login-button"
                            color="purple-gradient-dark"
                        >
                          Login
                        </FrameButton>
                    </>


                );
            case OnboardingModalSteps.DEALS_STEP:
                return (
                    <FrameButton
                        onClick={() => setCurrentStep(OnboardingModalSteps.GET_STARTED_STEP)}
                        size="normal"
                        className="back-button"
                        color="purple-text-no-border"
                    >
                    Back
                    </FrameButton>
                );
            default:
                return (
                    <FrameButton
                        onClick={() => setCurrentStep(OnboardingModalSteps.DEALS_STEP)}
                        size="normal"
                        className="skip-button"
                        color="purple-text-no-border"
                    >
                   Skip
                    </FrameButton>
                );
        }
    };

    const renderStep = () => {
        const step = steps[currentStep];
        return (
            <>
                {step.wrapInArrows && !isMobile &&
                <div className="onboarding-modal_arrow-wrapper">
                    <div className="onboarding-modal_arrow-wrapper_arrow">
                        <BiChevronLeft onClick={handlePreviousStep} />
                    </div>
                    <div className="onboarding-modal_arrow-wrapper_arrow">
                        {currentStep !== OnboardingModalSteps.DEALS_STEP && <BiChevronRight onClick={handleNextStep} />}
                    </div>
                </div>
                }
                {step.render}
                {step.showStepper &&
                  <OnboardingStepper onNextPressed={handleNextStep} currentStep={currentStep} onStepChange={setCurrentStep} />
                }
                <div className="onboarding-modal_header-actions">{renderHeadingButton()}</div>
                {step.button && <div className="onboarding-modal_button-container">{step.button}</div>}
            </>
        );
    };

    return (
        <div {...swipeHandlers}>
            <FrameOneAutoPanel
                isOpen={isOpen}
                toggle={toggle}
                containerClassName="onboarding-modal-container"
                contentClassName={classNames("onboarding-modal", `step-${currentStep}`)}
                size="md"
                modalOnTablet={true}
            >
                <FrameAutoPanelBody className="onboarding-modal_body" modalOnTablet={true}>
                    {renderStep()}
                </FrameAutoPanelBody>
            </FrameOneAutoPanel>
        </div>
    );
};

export default OnboardingModal;
