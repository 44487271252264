/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetUserCommunityMembershipResponse
 */
export interface GetUserCommunityMembershipResponse {
    /**
     * 
     * @type {string}
     * @memberof GetUserCommunityMembershipResponse
     */
    community: string;
    /**
     * 
     * @type {number}
     * @memberof GetUserCommunityMembershipResponse
     */
    rankWithinCommunity: number;
    /**
     * 
     * @type {number}
     * @memberof GetUserCommunityMembershipResponse
     */
    pointsEarnedForCommunity: number;
    /**
     * 
     * @type {string}
     * @memberof GetUserCommunityMembershipResponse
     */
    nftGroupingId: string;
}

export function GetUserCommunityMembershipResponseFromJSON(json: any): GetUserCommunityMembershipResponse {
    return GetUserCommunityMembershipResponseFromJSONTyped(json, false);
}

export function GetUserCommunityMembershipResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetUserCommunityMembershipResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'community': json['community'],
        'rankWithinCommunity': json['rankWithinCommunity'],
        'pointsEarnedForCommunity': json['pointsEarnedForCommunity'],
        'nftGroupingId': json['nftGroupingId'],
    };
}

export function GetUserCommunityMembershipResponseToJSON(value?: GetUserCommunityMembershipResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'community': value.community,
        'rankWithinCommunity': value.rankWithinCommunity,
        'pointsEarnedForCommunity': value.pointsEarnedForCommunity,
        'nftGroupingId': value.nftGroupingId,
    };
}


