import {ReactElement} from "react";
import {NftOwnershipInformation, SearchRestaurantsResponse, RestaurantSearchResult} from "@devour/client";
import {useParams} from "react-router";
import {RestaurantSearchFiltersQuery} from "@/utils/restaurantsSearchFilter";
import RestaurantCard from "@/pages/restaurants/components/RestaurantCard";

interface Props {
    restaurantFilters: RestaurantSearchFiltersQuery;
    allRestaurantSearchResults: SearchRestaurantsResponse;
    nftOwnershipResponse: NftOwnershipInformation;
}

function RestaurantSearchTextNoResults(props: Props): ReactElement {
    const {placeId} = useParams<{ placeId: string; }>();

    function renderRestaurant(restaurant: RestaurantSearchResult): JSX.Element {
        return (
            <li
                key={restaurant._id}
                className="restaurants-search-results_list_item"
            >
                <RestaurantCard
                    placeId={placeId}
                    restaurant={restaurant}
                    restaurantFilters={props.restaurantFilters}
                    nftOwnershipResponse={props.nftOwnershipResponse}
                    showPromos="auto"
                />
            </li>
        );
    }


    return (
        <div className="restaurants-search-text-no-results">
            <h2>
                Results for "
                {props.restaurantFilters.textSearch}
                "
            </h2>
            <div className="restaurants-search-text-no-results_wrapper">
                <img
                    src={import.meta.env.VITE_CDN_URL + "/images/map-marker-empty.webp"}
                    alt="Map Marker"
                />
                <h4>
                    Oops, Nothing Found!
                </h4>
                <p>
                    Hungry for something specific? Don’t worry, we’ve got plenty more to offer.
                    <br/>
                    Feel free to broaden your search or check our recommendation below.
                </p>
            </div>

            <h4>
                Recommended Restaurants
            </h4>

            <ul className="restaurants-search-results_list">
                {props.allRestaurantSearchResults.restaurantsPaginated.map(renderRestaurant)}
            </ul>

        </div>
    );
}


export default RestaurantSearchTextNoResults;
