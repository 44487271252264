/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Address,
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
    DeliveryHandoffInstructions,
    DeliveryHandoffInstructionsFromJSON,
    DeliveryHandoffInstructionsFromJSONTyped,
    DeliveryHandoffInstructionsToJSON,
    GeoPoint,
    GeoPointFromJSON,
    GeoPointFromJSONTyped,
    GeoPointToJSON,
} from './';

/**
 * Represents the address book model.
 * @export
 * @interface AddressBook
 */
export interface AddressBook {
    /**
     * 
     * @type {string}
     * @memberof AddressBook
     */
    id: string;
    /**
     * 
     * @type {GeoPoint}
     * @memberof AddressBook
     */
    location?: GeoPoint;
    /**
     * 
     * @type {string}
     * @memberof AddressBook
     */
    line1?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressBook
     */
    line2?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressBook
     */
    locality: string;
    /**
     * 
     * @type {string}
     * @memberof AddressBook
     */
    administrativeArea: string;
    /**
     * 
     * @type {string}
     * @memberof AddressBook
     */
    postalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressBook
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof AddressBook
     */
    placeId?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressBook
     */
    user: string;
    /**
     * 
     * @type {string}
     * @memberof AddressBook
     */
    deliveryInstructions?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AddressBook
     */
    isDefault?: boolean;
    /**
     * 
     * @type {DeliveryHandoffInstructions}
     * @memberof AddressBook
     */
    handoffInstructions?: DeliveryHandoffInstructions;
}

export function AddressBookFromJSON(json: any): AddressBook {
    return AddressBookFromJSONTyped(json, false);
}

export function AddressBookFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddressBook {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'location': !exists(json, 'location') ? undefined : GeoPointFromJSON(json['location']),
        'line1': !exists(json, 'line1') ? undefined : json['line1'],
        'line2': !exists(json, 'line2') ? undefined : json['line2'],
        'locality': json['locality'],
        'administrativeArea': json['administrativeArea'],
        'postalCode': !exists(json, 'postalCode') ? undefined : json['postalCode'],
        'country': json['country'],
        'placeId': !exists(json, 'placeId') ? undefined : json['placeId'],
        'user': json['user'],
        'deliveryInstructions': !exists(json, 'deliveryInstructions') ? undefined : json['deliveryInstructions'],
        'isDefault': !exists(json, 'isDefault') ? undefined : json['isDefault'],
        'handoffInstructions': !exists(json, 'handoffInstructions') ? undefined : DeliveryHandoffInstructionsFromJSON(json['handoffInstructions']),
    };
}

export function AddressBookToJSON(value?: AddressBook | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'location': GeoPointToJSON(value.location),
        'line1': value.line1,
        'line2': value.line2,
        'locality': value.locality,
        'administrativeArea': value.administrativeArea,
        'postalCode': value.postalCode,
        'country': value.country,
        'placeId': value.placeId,
        'user': value.user,
        'deliveryInstructions': value.deliveryInstructions,
        'isDefault': value.isDefault,
        'handoffInstructions': DeliveryHandoffInstructionsToJSON(value.handoffInstructions),
    };
}


