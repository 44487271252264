import {ReactElement} from "react";
import FrameOneModal from "@/components/modals/modalComponents/FrameOneModal";
import DrawerHeaderGraphic from "@/components/goFrens/communityInfoDrawer/DrawerHeaderGraphic";
import DrawerCommunityInfo from "@/components/goFrens/communityInfoDrawer/DrawerCommunityInfo";
import DrawerOwnedNfts from "@/components/goFrens/communityInfoDrawer/DrawerOwnedNfts";

import {NftGrouping, NftTracker} from "@devour/client";
import FrameModalFooter from "@/components/modals/modalComponents/FrameModalFooter";
import FrameModalBody from "@/components/modals/modalComponents/FrameModalBody";
import DrawerFooterComponents from "@/components/goFrens/communityInfoDrawer/DrawerFooterComponents";

interface Props {
    show: boolean;
    toggle: () => void;
    grouping: NftGrouping;
    trackers: Array<NftTracker>;
    owned: number;
}

function DrawerModalView(props: Props): ReactElement {

    return (
        <FrameOneModal
            size="md"
            isOpen={props.show}
            toggle={props.toggle}
            contentClassName="drawer-modal-view"
        >
            <FrameModalBody className="drawer-modal-view_body">
                <DrawerHeaderGraphic
                    toggle={props.toggle}
                    grouping={props.grouping}
                />
                <div className="gofrens-community-info-drawer_contents_main">
                    <DrawerCommunityInfo
                        grouping={props.grouping}
                    />
                    <DrawerOwnedNfts
                        owned={props.owned}
                        grouping={props.grouping}
                        show={props.show}
                    />
                </div>
            </FrameModalBody>
            <FrameModalFooter className="drawer-modal-view_footer">
                <DrawerFooterComponents
                    tracker={props.trackers.find(tracker => !tracker.isIneligibleForLeaderboards)}
                    grouping={props.grouping}
                    owned={props.owned}
                    show={props.show}
                />
            </FrameModalFooter>

        </FrameOneModal>
    );
}

export default DrawerModalView;
