/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum AccountExperienceTransactionType {
    CREDIT = 'CREDIT',
    DEBIT = 'DEBIT'
}

export function AccountExperienceTransactionTypeFromJSON(json: any): AccountExperienceTransactionType {
    return AccountExperienceTransactionTypeFromJSONTyped(json, false);
}

export function AccountExperienceTransactionTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountExperienceTransactionType {
    return json as AccountExperienceTransactionType;
}

export function AccountExperienceTransactionTypeToJSON(value?: AccountExperienceTransactionType | null): any {
    return value as any;
}

