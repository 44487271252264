import {ReactElement, useEffect, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import classNames from "classnames";
import {isDesktop} from "react-device-detect";
import useWindowSize from "../../hooks/useWindowSize";
import Toast from "../Toast";
import {AccountLevel, StandardUser, Token, UsersApi} from "@devour/client";
import getConfig from "../../utils/getConfig";
import {addError, updateAccountLevel} from "@/redux/meta/metaActions";

interface StateProps {
    fullToken?: Token;
    accountLevel?: AccountLevel;
    user?: StandardUser;
}

interface Props {
    baseUrl: string;
    gameId: string;
    width: string;
    height: string;
    noMobile?: boolean;
    message?: string; // when a new message is sent, a modal will pop up with this message, if the message is changed, the modal will pop up again
    onDismissToast?: () => Promise<void>;
}

function GameContainer(props: GameContainerProps): ReactElement {

    const [
        iFrameLoading,
        setIFrameLoading,
    ] = useState<boolean>(true);
    const [
        showToast,
        setShowToast,
    ] = useState<boolean>(false);
    const [
        windowWidth,
        windowHeight,
    ] = useWindowSize();

    useEffect(() => {
        if (props.message != null && props.message.length > 0) {
            setShowToast(true);
            return () => {
                async function updateLevelData() {
                    try {
                        const levelData = await new UsersApi(getConfig(props.fullToken)).getLevel({
                            id: props.user.id,
                        });
                        await props.dispatch(updateAccountLevel(levelData));

                    } catch (err) {
                        props.dispatch(await addError(err));
                    }
                }

                setShowToast(false);
                void updateLevelData();

            };
        }
    }, [props.message]);

    async function handleToastDismissal() {
        if (props.onDismissToast) {
            await props.onDismissToast();
        }
        setShowToast(false);
    }

    function onLoad(): void {
        setIFrameLoading(false);
    }

    return (
        <>
            <div className="game-container">
                {iFrameLoading &&
                <div className="spinner"/>
                }

                <iframe
                    className={
                        classNames([
                            "game-container_game",
                            iFrameLoading
                                ? "game-container_hidden"
                                : "game-container_visible",
                            {"game-container_game_mobile": !isDesktop && (props.noMobile === false || props.noMobile == null)},
                        ])
                    }

                    title="Devour Game"
                    src={`${props.baseUrl}?token=${props.fullToken
                        ? props.fullToken.token
                        : ""}&gameId=${props.gameId}&userId=${props.fullToken
                        ? props.fullToken.token
                        : ""}&origin=${window.location.origin}&backend=${import.meta.env.VITE_BACKEND_REST_URL}`}
                    width={isDesktop || props?.noMobile
                        ? props.width
                        : windowWidth}
                    height={isDesktop || props?.noMobile
                        ? props.height
                        : windowHeight}
                    onLoad={onLoad}
                />
            </div>

            <Toast
                forceWidth={"30rem"}
                message={props.message}
                isOpen={showToast}
                showButton={true}
                buttonMessage={"Got it!"}
                onDismiss={handleToastDismissal}
            />
        </>
    );
}

function connector() {
    return connect((store: IStore, props: Props): StateProps & Props => {
        return {
            fullToken: store.authStore.fullToken,
            user: store.metaStore?.currentUser?.user as unknown as StandardUser,
            accountLevel: store.metaStore.accountLevel,
            ...props,
        };
    });
}

type GameContainerProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(GameContainer);
