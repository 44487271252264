import {ButtonHTMLAttributes, ReactElement} from "react";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {useGetUserProfile} from "@/hooks/useGetUserProfile";
import FrameOneModal from "@/components/modals/modalComponents/FrameOneModal";
import FrameModalBody from "@/components/modals/modalComponents/FrameModalBody";
import {IoMdExit} from "react-icons/io";
import {GoFrensApi, NftGrouping, Token} from "@devour/client";
import FrameButton from "@/components/buttons/FrameButton";
import {addError, decrementLoading, incrementLoading} from "@/redux/meta/metaActions";
import getConfig from "@/utils/getConfig";
import {useGetNftOwnerships} from "@/hooks/useGetNftOwnerships";
import {useGetNftOwnershipsForUser} from "@/hooks/useGetNftOwnershipsForUser";
import useThemePreference from "@/hooks/useThemePreference";

interface Props {
    isOpen: boolean;
    toggle: () => void;
    grouping: NftGrouping;
}

function GoFrensLeaveCommunityModal(props: Props): ReactElement {
    const dispatch = useDispatch();
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const {refetch: refetchUserProfileData} = useGetUserProfile(fullToken);
    const {refetch: refetchNftOwnerships} = useGetNftOwnerships(fullToken as Token);
    const {refetch: refetchNftOwnershipsForUser} = useGetNftOwnershipsForUser(fullToken as Token);
    const { isOnDarkMode } = useThemePreference();

    async function handleUserLeavingCommunity() {
        dispatch(incrementLoading());
        try {
            await new GoFrensApi(getConfig(fullToken)).removeUserFromCommunity();
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            dispatch(decrementLoading());
            props.toggle();
            await Promise.all([
                refetchUserProfileData(),
                refetchNftOwnershipsForUser(),
                refetchNftOwnerships(),
            ]);
        }
    }

    function getCooldownInDays() {
        const now = new Date();
        const beginningOfNextMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1);
        const timeDiffInMs = beginningOfNextMonth.getTime() - now.getTime();
        const cooldownInDays = Math.ceil(timeDiffInMs / (1000 * 60 * 60 * 24));
        return `${cooldownInDays} day${cooldownInDays > 1 ? "s" : ""}`;
    }

    return (
        <FrameOneModal
            isOpen={props.isOpen}
            toggle={props.toggle}
            contentClassName="gofrens-leave-community-modal"
        >
            <FrameModalBody className="gofrens-leave-community-modal_body">
                <IoMdExit className="gofrens-leave-community-modal_body_exit-icon"/>
                <h3 className="gofrens-leave-community-modal_body_title">
                    Leave This Community?
                </h3>
                <p className="gofrens-leave-community-modal_body_disclaimer">
                    If you leave <strong>{props.grouping.name}</strong> now, you will not be able to join a new community in {getCooldownInDays()}.
                    While you can rejoin <strong>{props.grouping.name}</strong> if you wish, the record of your point
                    contributions will be reset and you will be unranked upon rejoining.
                </p>
                <FrameButton
                    <ButtonHTMLAttributes<HTMLButtonElement>>
                    color={isOnDarkMode ? "danger-outline" : "danger"}
                    size="narrow"
                    className="gofrens-leave-community-modal_body_confirm-btn"
                    onClick={async () => await handleUserLeavingCommunity()}
                >
                    Leave Now
                </FrameButton>
                <FrameButton
                    <ButtonHTMLAttributes<HTMLButtonElement>>
                    color="purple-text-no-border"
                    size="narrow"
                    className="gofrens-leave-community-modal_body_cancel-btn"
                    onClick={props.toggle}
                >
                    Stay with {props.grouping.name}
                </FrameButton>
            </FrameModalBody>
        </FrameOneModal>
    );
}

export default GoFrensLeaveCommunityModal;