/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
    BusinessServiceAvailability,
    BusinessServiceAvailabilityFromJSON,
    BusinessServiceAvailabilityFromJSONTyped,
    BusinessServiceAvailabilityToJSON,
    MenuItem,
    MenuItemFromJSON,
    MenuItemFromJSONTyped,
    MenuItemToJSON,
    TimeStamps,
    TimeStampsFromJSON,
    TimeStampsFromJSONTyped,
    TimeStampsToJSON,
} from './';

/**
 * Wrapper a group of menu sections.
 * @export
 * @interface MenuCategory
 */
export interface MenuCategory {
    /**
     * 
     * @type {string}
     * @memberof MenuCategory
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof MenuCategory
     */
    createdAt: number;
    /**
     * 
     * @type {number}
     * @memberof MenuCategory
     */
    updatedAt: number;
    /**
     * 
     * @type {string}
     * @memberof MenuCategory
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof MenuCategory
     */
    business: string;
    /**
     * 
     * @type {string}
     * @memberof MenuCategory
     */
    parent?: string;
    /**
     * Sort order for this on the frontend
     * @type {number}
     * @memberof MenuCategory
     */
    sortOrder?: number;
    /**
     * 
     * @type {boolean}
     * @memberof MenuCategory
     */
    isEnabled?: boolean;
    /**
     * 
     * @type {Array<MenuCategory>}
     * @memberof MenuCategory
     */
    menuSubcategories?: Array<MenuCategory>;
    /**
     * 
     * @type {Array<MenuItem>}
     * @memberof MenuCategory
     */
    menuItems?: Array<MenuItem>;
    /**
     * 
     * @type {Array<BusinessServiceAvailability>}
     * @memberof MenuCategory
     */
    serviceAvailabilities?: Array<BusinessServiceAvailability>;
    /**
     * 
     * @type {string}
     * @memberof MenuCategory
     */
    chowlyId?: string;
    /**
     * 
     * @type {string}
     * @memberof MenuCategory
     */
    itsaCheckmateId?: string;
    /**
     * 
     * @type {number}
     * @memberof MenuCategory
     */
    oloId?: number;
}

export function MenuCategoryFromJSON(json: any): MenuCategory {
    return MenuCategoryFromJSONTyped(json, false);
}

export function MenuCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): MenuCategory {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'name': json['name'],
        'business': json['business'],
        'parent': !exists(json, 'parent') ? undefined : json['parent'],
        'sortOrder': !exists(json, 'sortOrder') ? undefined : json['sortOrder'],
        'isEnabled': !exists(json, 'isEnabled') ? undefined : json['isEnabled'],
        'menuSubcategories': !exists(json, 'menuSubcategories') ? undefined : ((json['menuSubcategories'] as Array<any>).map(MenuCategoryFromJSON)),
        'menuItems': !exists(json, 'menuItems') ? undefined : ((json['menuItems'] as Array<any>).map(MenuItemFromJSON)),
        'serviceAvailabilities': !exists(json, 'serviceAvailabilities') ? undefined : ((json['serviceAvailabilities'] as Array<any>).map(BusinessServiceAvailabilityFromJSON)),
        'chowlyId': !exists(json, 'chowlyId') ? undefined : json['chowlyId'],
        'itsaCheckmateId': !exists(json, 'itsaCheckmateId') ? undefined : json['itsaCheckmateId'],
        'oloId': !exists(json, 'oloId') ? undefined : json['oloId'],
    };
}

export function MenuCategoryToJSON(value?: MenuCategory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'name': value.name,
        'business': value.business,
        'parent': value.parent,
        'sortOrder': value.sortOrder,
        'isEnabled': value.isEnabled,
        'menuSubcategories': value.menuSubcategories === undefined ? undefined : ((value.menuSubcategories as Array<any>).map(MenuCategoryToJSON)),
        'menuItems': value.menuItems === undefined ? undefined : ((value.menuItems as Array<any>).map(MenuItemToJSON)),
        'serviceAvailabilities': value.serviceAvailabilities === undefined ? undefined : ((value.serviceAvailabilities as Array<any>).map(BusinessServiceAvailabilityToJSON)),
        'chowlyId': value.chowlyId,
        'itsaCheckmateId': value.itsaCheckmateId,
        'oloId': value.oloId,
    };
}


