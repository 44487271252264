/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Transaction,
    TransactionFromJSON,
    TransactionFromJSONTyped,
    TransactionToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetTransactionsResponse
 */
export interface GetTransactionsResponse {
    /**
     * 
     * @type {number}
     * @memberof GetTransactionsResponse
     */
    balance: number;
    /**
     * 
     * @type {number}
     * @memberof GetTransactionsResponse
     */
    dPayPricePerUsd?: number;
    /**
     * 
     * @type {number}
     * @memberof GetTransactionsResponse
     */
    balanceInUsd?: number;
    /**
     * 
     * @type {Array<Transaction>}
     * @memberof GetTransactionsResponse
     */
    transactions: Array<Transaction>;
}

export function GetTransactionsResponseFromJSON(json: any): GetTransactionsResponse {
    return GetTransactionsResponseFromJSONTyped(json, false);
}

export function GetTransactionsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetTransactionsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'balance': json['balance'],
        'dPayPricePerUsd': !exists(json, 'dPayPricePerUsd') ? undefined : json['dPayPricePerUsd'],
        'balanceInUsd': !exists(json, 'balanceInUsd') ? undefined : json['balanceInUsd'],
        'transactions': ((json['transactions'] as Array<any>).map(TransactionFromJSON)),
    };
}

export function GetTransactionsResponseToJSON(value?: GetTransactionsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'balance': value.balance,
        'dPayPricePerUsd': value.dPayPricePerUsd,
        'balanceInUsd': value.balanceInUsd,
        'transactions': ((value.transactions as Array<any>).map(TransactionToJSON)),
    };
}


