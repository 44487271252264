import {ReactElement} from "react";

interface Props {
    cryptoBalance: number;
    usdBalance: number;
}

function DpayBalanceCard(props: Props): ReactElement {

    return (
        <div className="dpay-balance-card">
            <div className="dpay-balance-card_img-con">
                <img
                    src={import.meta.env.VITE_CDN_URL + "/images/FUEL.webp"}
                    alt="DPAY Token Icon"
                />
            </div>

            {
                props.cryptoBalance != null && props.usdBalance != null
                    ? <div className="dpay-balance-card_stats">
                        <h3 className="dpay-balance-card_stats_crypto">
                            {props.cryptoBalance} {import.meta.env.VITE_TOKEN_NAME}
                        </h3>

                        <h6 className="dpay-balance-card_stats_usd">
                            {`$${Intl.NumberFormat("en-us", {minimumFractionDigits: 2,
                                maximumFractionDigits: 2}).format(props.usdBalance)} USD`}
                        </h6>
                    </div>
				 : <div className="dpay-balance-card_stats">
                        {import.meta.env.VITE_TOKEN_NAME} prices are unavailable at the moment.
                    </div>

            }

        </div>
    );
}

export default DpayBalanceCard;
