/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DpayMenuOrders
 */
export interface DpayMenuOrders {
    /**
     * 
     * @type {string}
     * @memberof DpayMenuOrders
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof DpayMenuOrders
     */
    grandTotalFiat?: number;
    /**
     * 
     * @type {string}
     * @memberof DpayMenuOrders
     */
    createdAt: string;
    /**
     * 
     * @type {number}
     * @memberof DpayMenuOrders
     */
    dpayUsed?: number;
    /**
     * 
     * @type {number}
     * @memberof DpayMenuOrders
     */
    onChainUsed?: number;
    /**
     * 
     * @type {string}
     * @memberof DpayMenuOrders
     */
    etherscanLink?: string;
    /**
     * 
     * @type {number}
     * @memberof DpayMenuOrders
     */
    vdpayUsed?: number;
    /**
     * 
     * @type {string}
     * @memberof DpayMenuOrders
     */
    walletType?: string;
    /**
     * 
     * @type {number}
     * @memberof DpayMenuOrders
     */
    dpayTotalFiat?: number;
}

export function DpayMenuOrdersFromJSON(json: any): DpayMenuOrders {
    return DpayMenuOrdersFromJSONTyped(json, false);
}

export function DpayMenuOrdersFromJSONTyped(json: any, ignoreDiscriminator: boolean): DpayMenuOrders {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'grandTotalFiat': !exists(json, 'grandTotalFiat') ? undefined : json['grandTotalFiat'],
        'createdAt': json['createdAt'],
        'dpayUsed': !exists(json, 'dpayUsed') ? undefined : json['dpayUsed'],
        'onChainUsed': !exists(json, 'onChainUsed') ? undefined : json['onChainUsed'],
        'etherscanLink': !exists(json, 'etherscanLink') ? undefined : json['etherscanLink'],
        'vdpayUsed': !exists(json, 'vdpayUsed') ? undefined : json['vdpayUsed'],
        'walletType': !exists(json, 'walletType') ? undefined : json['walletType'],
        'dpayTotalFiat': !exists(json, 'dpayTotalFiat') ? undefined : json['dpayTotalFiat'],
    };
}

export function DpayMenuOrdersToJSON(value?: DpayMenuOrders | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'grandTotalFiat': value.grandTotalFiat,
        'createdAt': value.createdAt,
        'dpayUsed': value.dpayUsed,
        'onChainUsed': value.onChainUsed,
        'etherscanLink': value.etherscanLink,
        'vdpayUsed': value.vdpayUsed,
        'walletType': value.walletType,
        'dpayTotalFiat': value.dpayTotalFiat,
    };
}


