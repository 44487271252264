/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MenuOrderItem,
    MenuOrderItemFromJSON,
    MenuOrderItemFromJSONTyped,
    MenuOrderItemToJSON,
    MenuOrderSubItem,
    MenuOrderSubItemFromJSON,
    MenuOrderSubItemFromJSONTyped,
    MenuOrderSubItemToJSON,
    TimeStamps,
    TimeStampsFromJSON,
    TimeStampsFromJSONTyped,
    TimeStampsToJSON,
} from './';

/**
 * 
 * @export
 * @interface PreviousMenuOrderItem
 */
export interface PreviousMenuOrderItem {
    /**
     * 
     * @type {string}
     * @memberof PreviousMenuOrderItem
     */
    externalId?: string;
    /**
     * 
     * @type {string}
     * @memberof PreviousMenuOrderItem
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof PreviousMenuOrderItem
     */
    price: number;
    /**
     * 
     * @type {number}
     * @memberof PreviousMenuOrderItem
     */
    quantity: number;
    /**
     * 
     * @type {number}
     * @memberof PreviousMenuOrderItem
     */
    taxRate: number;
    /**
     * 
     * @type {string}
     * @memberof PreviousMenuOrderItem
     */
    notes?: string;
    /**
     * 
     * @type {string}
     * @memberof PreviousMenuOrderItem
     */
    menuItemId?: string;
    /**
     * 
     * @type {number}
     * @memberof PreviousMenuOrderItem
     */
    subtotal: number;
    /**
     * 
     * @type {number}
     * @memberof PreviousMenuOrderItem
     */
    tax: number;
    /**
     * 
     * @type {Array<MenuOrderSubItem>}
     * @memberof PreviousMenuOrderItem
     */
    customizations?: Array<MenuOrderSubItem>;
    /**
     * 
     * @type {number}
     * @memberof PreviousMenuOrderItem
     */
    createdAt: number;
    /**
     * 
     * @type {number}
     * @memberof PreviousMenuOrderItem
     */
    updatedAt: number;
}

export function PreviousMenuOrderItemFromJSON(json: any): PreviousMenuOrderItem {
    return PreviousMenuOrderItemFromJSONTyped(json, false);
}

export function PreviousMenuOrderItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): PreviousMenuOrderItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'externalId': !exists(json, 'externalId') ? undefined : json['externalId'],
        'name': json['name'],
        'price': json['price'],
        'quantity': json['quantity'],
        'taxRate': json['taxRate'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'menuItemId': !exists(json, 'menuItemId') ? undefined : json['menuItemId'],
        'subtotal': json['subtotal'],
        'tax': json['tax'],
        'customizations': !exists(json, 'customizations') ? undefined : ((json['customizations'] as Array<any>).map(MenuOrderSubItemFromJSON)),
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
    };
}

export function PreviousMenuOrderItemToJSON(value?: PreviousMenuOrderItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'externalId': value.externalId,
        'name': value.name,
        'price': value.price,
        'quantity': value.quantity,
        'taxRate': value.taxRate,
        'notes': value.notes,
        'menuItemId': value.menuItemId,
        'subtotal': value.subtotal,
        'tax': value.tax,
        'customizations': value.customizations === undefined ? undefined : ((value.customizations as Array<any>).map(MenuOrderSubItemToJSON)),
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
    };
}


