/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NftGrouping,
    NftGroupingFromJSON,
    NftGroupingFromJSONTyped,
    NftGroupingToJSON,
    NftOwnershipMatrix,
    NftOwnershipMatrixFromJSON,
    NftOwnershipMatrixFromJSONTyped,
    NftOwnershipMatrixToJSON,
    NftTracker,
    NftTrackerFromJSON,
    NftTrackerFromJSONTyped,
    NftTrackerToJSON,
} from './';

/**
 * Represents a collection of the trackers and groupings and their corresponding information in regards to ownership of users.
 * @export
 * @interface NftOwnershipInformation
 */
export interface NftOwnershipInformation {
    /**
     * 
     * @type {Array<string>}
     * @memberof NftOwnershipInformation
     */
    userIds: Array<string>;
    /**
     * 
     * @type {Array<NftOwnershipMatrix>}
     * @memberof NftOwnershipInformation
     */
    nftOwnerships: Array<NftOwnershipMatrix>;
    /**
     * 
     * @type {Array<NftGrouping>}
     * @memberof NftOwnershipInformation
     */
    myGroupings: Array<NftGrouping>;
    /**
     * 
     * @type {Array<NftGrouping>}
     * @memberof NftOwnershipInformation
     */
    nftGroupings: Array<NftGrouping>;
    /**
     * 
     * @type {Array<NftTracker>}
     * @memberof NftOwnershipInformation
     */
    nftTrackers: Array<NftTracker>;
}

export function NftOwnershipInformationFromJSON(json: any): NftOwnershipInformation {
    return NftOwnershipInformationFromJSONTyped(json, false);
}

export function NftOwnershipInformationFromJSONTyped(json: any, ignoreDiscriminator: boolean): NftOwnershipInformation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userIds': json['userIds'],
        'nftOwnerships': ((json['nftOwnerships'] as Array<any>).map(NftOwnershipMatrixFromJSON)),
        'myGroupings': ((json['myGroupings'] as Array<any>).map(NftGroupingFromJSON)),
        'nftGroupings': ((json['nftGroupings'] as Array<any>).map(NftGroupingFromJSON)),
        'nftTrackers': ((json['nftTrackers'] as Array<any>).map(NftTrackerFromJSON)),
    };
}

export function NftOwnershipInformationToJSON(value?: NftOwnershipInformation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userIds': value.userIds,
        'nftOwnerships': ((value.nftOwnerships as Array<any>).map(NftOwnershipMatrixToJSON)),
        'myGroupings': ((value.myGroupings as Array<any>).map(NftGroupingToJSON)),
        'nftGroupings': ((value.nftGroupings as Array<any>).map(NftGroupingToJSON)),
        'nftTrackers': ((value.nftTrackers as Array<any>).map(NftTrackerToJSON)),
    };
}


