/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
    TimeStamps,
    TimeStampsFromJSON,
    TimeStampsFromJSONTyped,
    TimeStampsToJSON,
} from './';

/**
 * Represents a file sitting in google cloud storage.
 * @export
 * @interface Asset
 */
export interface Asset {
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof Asset
     */
    createdAt: number;
    /**
     * 
     * @type {number}
     * @memberof Asset
     */
    updatedAt: number;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    fileName: string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    pipeUrl: string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    sourcePipeUrl: string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    redirectUrl: string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    sourceRedirectUrl: string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    base64Url: string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    sourceBase64Url: string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    cdnUrl: string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    contentType: string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    originalContentType?: string;
    /**
     * 
     * @type {number}
     * @memberof Asset
     */
    size: number;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    owner?: string;
}

export function AssetFromJSON(json: any): Asset {
    return AssetFromJSONTyped(json, false);
}

export function AssetFromJSONTyped(json: any, ignoreDiscriminator: boolean): Asset {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'fileName': json['fileName'],
        'url': json['url'],
        'pipeUrl': json['pipeUrl'],
        'sourcePipeUrl': json['sourcePipeUrl'],
        'redirectUrl': json['redirectUrl'],
        'sourceRedirectUrl': json['sourceRedirectUrl'],
        'base64Url': json['base64Url'],
        'sourceBase64Url': json['sourceBase64Url'],
        'cdnUrl': json['cdnUrl'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'contentType': json['contentType'],
        'originalContentType': !exists(json, 'originalContentType') ? undefined : json['originalContentType'],
        'size': json['size'],
        'owner': !exists(json, 'owner') ? undefined : json['owner'],
    };
}

export function AssetToJSON(value?: Asset | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'fileName': value.fileName,
        'url': value.url,
        'pipeUrl': value.pipeUrl,
        'sourcePipeUrl': value.sourcePipeUrl,
        'redirectUrl': value.redirectUrl,
        'sourceRedirectUrl': value.sourceRedirectUrl,
        'base64Url': value.base64Url,
        'sourceBase64Url': value.sourceBase64Url,
        'cdnUrl': value.cdnUrl,
        'name': value.name,
        'description': value.description,
        'contentType': value.contentType,
        'originalContentType': value.originalContentType,
        'size': value.size,
        'owner': value.owner,
    };
}


