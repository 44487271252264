import {ReactElement} from "react";
import FrameOneAutoPanel from "@/components/modals/autoPanelComponents/FrameOneAutoPanel";
import FrameAutoPanelHeader from "@/components/modals/autoPanelComponents/FrameAutoPanelHeader";
import FrameAutoPanelBody from "@/components/modals/autoPanelComponents/FrameAutoPanelBody";
import FrameAutoPanelFooter from "@/components/modals/autoPanelComponents/FrameAutoPanelFooter";
import {
    refreshMenuOrderCart,
    removeDevourIqMenuOrderError,
    removeDpayPriceExpiryTime,
    removeMenuOrder, removeMenuOrderError,
} from "@/redux/meta/metaActions";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import FrameButton from "@/components/buttons/FrameButton";

function DevourIQMenuOrderErrorModal(): ReactElement {
    const dispatch = useDispatch();
    const devourIqMenuOrderError = useSelector((store: IStore) => store.metaStore.devourIqMenuOrderError);

    function onCloseModal() {
        dispatch(removeDevourIqMenuOrderError());
    }

    return (
        <FrameOneAutoPanel
            contentClassName="menu-order-error-modal"
            isOpen={Boolean(devourIqMenuOrderError?.errorMessage)}
            toggle={onCloseModal}
            size="xs"
        >
            <FrameAutoPanelHeader
                title="Cart Error"
                toggle={onCloseModal}
            />
            <FrameAutoPanelBody>
                <p>
                    {devourIqMenuOrderError?.errorMessage}
                </p>
            </FrameAutoPanelBody>
            <FrameAutoPanelFooter>
                <FrameButton
                    color="purple"
                    size="normal"
                    onClick={onCloseModal}
                >
										Dismiss
                </FrameButton>
            </FrameAutoPanelFooter>

        </FrameOneAutoPanel>
    );
}

export default DevourIQMenuOrderErrorModal;
