/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AddressBook,
    AddressBookFromJSON,
    AddressBookFromJSONTyped,
    AddressBookToJSON,
    Asset,
    AssetFromJSON,
    AssetFromJSONTyped,
    AssetToJSON,
    PhoneNumber,
    PhoneNumberFromJSON,
    PhoneNumberFromJSONTyped,
    PhoneNumberToJSON,
    StandardUser,
    StandardUserFromJSON,
    StandardUserFromJSONTyped,
    StandardUserToJSON,
    UserType,
    UserTypeFromJSON,
    UserTypeFromJSONTyped,
    UserTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface Partner
 */
export interface Partner extends StandardUser {
}

export function PartnerFromJSON(json: any): Partner {
    return PartnerFromJSONTyped(json, false);
}

export function PartnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): Partner {
    return json;
}

export function PartnerToJSON(value?: Partner | null): any {
    return value;
}


