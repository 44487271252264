import {ReactElement, useEffect} from "react";
import {ReferralsApi} from "@devour/client";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {useSearchParams} from "react-router-dom";
import getConfig from "../utils/getConfig";
import {newReferralCode, removeReferralCode} from "../redux/affiliateReferrals/affiliateActions";

function ReferralHandler(): ReactElement {
    const dispatch = useDispatch();
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const reduxReferralCode = useSelector((store: IStore) => store.affiliateStore.referralCode);
    const reduxReferralExpiry = useSelector((store: IStore) => store.affiliateStore.referralExpiry);
    const [searchParams] = useSearchParams();
    const freshReferralCode = searchParams.get("ref");

    useEffect(() => {
        void handleReferralId();
    }, [fullToken?.id, freshReferralCode, reduxReferralCode]);

    async function handleReferralId() {
        let useReferralCode: string;
        if (freshReferralCode) {
            useReferralCode = freshReferralCode;
        } else if (reduxReferralCode && Date.now() < reduxReferralExpiry) {
            useReferralCode = reduxReferralCode;
        }

        if (!useReferralCode) {
            return;
        }

        if (fullToken) {

            try {

                /*
                 * If the user is logged in, create a referral relationship.
                 * If the referral code is already applied nothing will happen.
                 */
                await new ReferralsApi(getConfig(fullToken)).createReferralRelationship({
                    createReferralRelationshipBody: {
                        referralCode: useReferralCode,
                    },
                });

                dispatch(removeReferralCode());
            } catch (err) {
                console.error("Unable to update referral code", err);
            }

        } else {
            // If the user is not logged in, store the referral code in redux until they log in or sign up.
            dispatch(newReferralCode(useReferralCode));
        }
    }

    return null;
}

export default ReferralHandler;
