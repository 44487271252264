import {PaginationInfo} from "@devour/client";
import {ISelectOption} from "../inputs/SelectOptionsFactory";
import {FC} from "react";
import {FiChevronDown} from "react-icons/fi";

interface IProps extends PaginationInfo {
    onChangeLimit: (newLimit: number) => void;
}

export const framePaginationLimiterOptions: Array<ISelectOption<number>> = [
    {
        value: 10,
        label: "10",
    },
    {
        value: 12,
        label: "12",
    },
    {
        value: 24,
        label: "24",
    },
    {
        value: 36,
        label: "36",
    },
    {
        value: 48,
        label: "48",
    },
];

const FramePaginatorLimiter: FC<IProps> = (props) => {
    function onChangeHelper(e): void {
        props.onChangeLimit(parseInt(e?.target?.value));
    }

    return (
        <div className="frame-paginator_limiter-container">
            <div className="frame-paginator_limiter-container_limiter">
                <label>
                    {`${props.lowerRange} - ${props.upperRange} out of ${props.total}`}
                </label>

                <div className="f1-select-wrapper">

                    <select
                        value={props.limit}
                        onChange={onChangeHelper}
                    >
                        {framePaginationLimiterOptions?.map((option, i: number) => {
                            return (
                                <option
                                    key={`limiter-option_${i}`}
                                    value={option.value}
                                >
                                    {option.label}
                                </option>
                            );
                        })}
                    </select>
                    <div className="f1-select-wrapper_icon-container">
                        <FiChevronDown/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FramePaginatorLimiter;
