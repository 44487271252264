import FrameButton from "@/components/buttons/FrameButton";
import useThemePreference from "@/hooks/useThemePreference";
import { IStore } from "@/redux/defaultStore";
import { restaurantSearchRedirect } from "@/utils/restaurantSearchRedirect";
import { FaArrowRight } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

const MenuOrderPlaceholder = () => {
    const {getThemedImageUrl} = useThemePreference();
    const navigate = useNavigate();
    const currentUser = useSelector((store: IStore) => store.metaStore.currentUser);
    const lastSearchedPlaceId = useSelector((store: IStore) => store.metaStore.lastSearchedPlaceId);

    async function handleOrderFoodRouteForAuthedUser(): Promise<void> {
        const redirectRoute = await restaurantSearchRedirect(currentUser?.user?.addresses, lastSearchedPlaceId);
        if (redirectRoute) {
            navigate(redirectRoute);
        } else {
            navigate("/restaurants");
        }

    }

    return (
        <div className="menu-order-placeholder">
            <img src={getThemedImageUrl(`${import.meta.env.VITE_CDN_URL}/images/EmptyCart.webp`)} alt="Empty cart image" />
            <h3>No orders yet</h3>
            <p>It looks like you haven't placed any orders yet. Start your order and add items to your cart to begin your dining experience!</p>
            <FrameButton
                onClick={handleOrderFoodRouteForAuthedUser}
                color="purple"
                size="large"
                rightIcon={() => <FaArrowRight/>}>
                <span>Order food</span>
            </FrameButton>
        </div>
    );
};

export default MenuOrderPlaceholder;