import {useMemo} from "react";


export const ELLIPSIS = "...";

function getRange(start: number, end: number): Array<number> {
    const length: number = end - start + 1;
    return Array.from({length}, (_, idx) => idx + start);
}

export default function usePagination(total: number, limit: number, currentPage: number): Array<string | number> {
    return useMemo(() => {
        // siblingCount: number of pages visible on either side of current page
        const siblingCount: number = 1;
        const totalPageCount: number = Math.ceil(total / limit);

        // first page + dots + sibling + current + sibling + dots + last -> 1 ... 4 5 6 ... 10
        const minButtonCount: number = siblingCount * 2 + 5;

        if (totalPageCount <= minButtonCount) {
            return getRange(1, totalPageCount);
        }

        const leftSiblingIdx: number = Math.max(currentPage - siblingCount, 1);
        const rightSiblingIdx: number = Math.min(currentPage + siblingCount, totalPageCount);

        // start showing dots when there are 3 pages before left-most sibling
        const showLeftDots: boolean = leftSiblingIdx > 3;

        // start showing dots when there are 2 pages after right-most sibling
        const showRightDots: boolean = rightSiblingIdx < totalPageCount - 2;

        if (showLeftDots && showRightDots) {
            const middleRange: Array<number> = getRange(leftSiblingIdx, rightSiblingIdx);
            return [
                1,
                ELLIPSIS,
                ...middleRange,
                ELLIPSIS,
                totalPageCount,
            ];

        } else if (!showLeftDots && showRightDots) {
            // display to the left: current page, 2 siblings, and the first 2 pages
            const leftItemCount: number = 3 + 2 * siblingCount;
            const leftRange: Array<number> = getRange(1, leftItemCount);
            return [
                ...leftRange,
                ELLIPSIS,
                totalPageCount,
            ];

        } else if (showLeftDots && !showRightDots) {
            // display to the right: current page, 2 siblings, and the last 2 pages
            const rightItemCount: number = 3 + 2 * siblingCount;
            const rightRange: Array<number> = getRange(totalPageCount - rightItemCount + 1, totalPageCount);
            return [
                1,
                ELLIPSIS,
                ...rightRange,
            ];
        }
    }, [
        total,
        limit,
        currentPage,
    ]);
}
