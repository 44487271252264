/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DevourIQGetItemAvailabilityResponse,
    DevourIQGetItemAvailabilityResponseFromJSON,
    DevourIQGetItemAvailabilityResponseFromJSONTyped,
    DevourIQGetItemAvailabilityResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface DevourIQGetStoreAvailabilityResponse
 */
export interface DevourIQGetStoreAvailabilityResponse {
    /**
     * 
     * @type {string}
     * @memberof DevourIQGetStoreAvailabilityResponse
     */
    storeUuuid?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DevourIQGetStoreAvailabilityResponse
     */
    isOpen?: boolean;
    /**
     * 
     * @type {Array<DevourIQGetItemAvailabilityResponse>}
     * @memberof DevourIQGetStoreAvailabilityResponse
     */
    items?: Array<DevourIQGetItemAvailabilityResponse>;
}

export function DevourIQGetStoreAvailabilityResponseFromJSON(json: any): DevourIQGetStoreAvailabilityResponse {
    return DevourIQGetStoreAvailabilityResponseFromJSONTyped(json, false);
}

export function DevourIQGetStoreAvailabilityResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DevourIQGetStoreAvailabilityResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'storeUuuid': !exists(json, 'storeUuuid') ? undefined : json['storeUuuid'],
        'isOpen': !exists(json, 'isOpen') ? undefined : json['isOpen'],
        'items': !exists(json, 'items') ? undefined : ((json['items'] as Array<any>).map(DevourIQGetItemAvailabilityResponseFromJSON)),
    };
}

export function DevourIQGetStoreAvailabilityResponseToJSON(value?: DevourIQGetStoreAvailabilityResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'storeUuuid': value.storeUuuid,
        'isOpen': value.isOpen,
        'items': value.items === undefined ? undefined : ((value.items as Array<any>).map(DevourIQGetItemAvailabilityResponseToJSON)),
    };
}


