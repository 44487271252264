import {ApiError} from "@devour/client";

export function isAPIError(err: any): err is ApiError {

    if (!err.hasOwnProperty("message") || typeof err.message !== "string") {
        return false;
    }

    if (err.hasOwnProperty("errors") && err.errors != null) {

        if (!Array.isArray(err.errors) || !err.errors.every(isAPIError)) {
            return false;
        }

    }

    return true;
}
