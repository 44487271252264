/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
    GenericCounterKey,
    GenericCounterKeyFromJSON,
    GenericCounterKeyFromJSONTyped,
    GenericCounterKeyToJSON,
    TimeStamps,
    TimeStampsFromJSON,
    TimeStampsFromJSONTyped,
    TimeStampsToJSON,
} from './';

/**
 * This is a generic collection that will allow an arbitrary key to be set and incremented.
 * @export
 * @interface GenericCounter
 */
export interface GenericCounter {
    /**
     * 
     * @type {string}
     * @memberof GenericCounter
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof GenericCounter
     */
    createdAt: number;
    /**
     * 
     * @type {number}
     * @memberof GenericCounter
     */
    updatedAt: number;
    /**
     * 
     * @type {GenericCounterKey}
     * @memberof GenericCounter
     */
    key: GenericCounterKey;
    /**
     * 
     * @type {string}
     * @memberof GenericCounter
     */
    count: string;
}

export function GenericCounterFromJSON(json: any): GenericCounter {
    return GenericCounterFromJSONTyped(json, false);
}

export function GenericCounterFromJSONTyped(json: any, ignoreDiscriminator: boolean): GenericCounter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'key': GenericCounterKeyFromJSON(json['key']),
        'count': json['count'],
    };
}

export function GenericCounterToJSON(value?: GenericCounter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'key': GenericCounterKeyToJSON(value.key),
        'count': value.count,
    };
}


