import {useQuery} from "@tanstack/react-query";
import {GleamApi, GleamCompetitionActionsResponse} from "@devour/client";

async function fetchGleamActions(id: string): Promise<GleamCompetitionActionsResponse> {
    return await new GleamApi().gleamCompetitionActions({
        id: id,
    });
}

function useGleamActionsQuery(id: string) {
    return {
        queryKey: [
            "gleam-actions",
            id,
        ],
        queryFn: () => fetchGleamActions(id),
        staleTime: 60 * 60000,
        enabled: !!id,
    } as const;
}

export function useGetGleamActions(id: string) {
    return useQuery(useGleamActionsQuery(id));
}