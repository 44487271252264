import {ReactElement, useState} from "react";
import classNames from "classnames";
import AnimateHeight from "react-animate-height";
import {v4 as uuid} from "uuid";
import Spacer from "@/components/Spacer";
import {FaChevronUp} from "react-icons/fa";

interface Props {
    header: ReactElement;
    body: ReactElement | ReactElement[];
    showDivider?: boolean;
    showBorder?: boolean;
    duration?: number;
    padding?: string;
    borderRadius?: string;
    showChevron?: boolean;
    isOpenDefault?: boolean;
    onClick?: () => void;
}

function Accordion(props: Props): ReactElement {

    const [
        showBody,
        setShowBody,
    ] = useState<boolean>(!!props?.isOpenDefault);

    function handleClick(): void {
        setShowBody(!showBody);
        if (props.onClick) {
            props.onClick();
        }
    }

    return (
        <div
            style={{
                padding: props?.padding
                    ? props.padding
                    : "1.25rem",
                borderRadius: props?.borderRadius
                    ? props.borderRadius
                    : "1rem",
            }}
            className={classNames("accordion", {
                "accordion_border": props?.showBorder,
            })}
        >
            <div
                onClick={handleClick}
                className="accordion_header"
            >
                {props.header}
                {props.showChevron &&
                <>
                    <Spacer/>
                    <FaChevronUp
                        className={classNames("menu-item-customization-form_group_details_hide-icon", {
                            "menu-item-customization-form_group_details_hide-icon_flipped": !showBody,
                        })}
                    />
                </>
                }
            </div>
            <AnimateHeight
                id={uuid()}
                duration={props?.duration
                    ? props.duration
                    : 400}
                height={!showBody
                    ? 0
                    : "auto"}
            >
                {props.showDivider && <hr />}
                {props.body}
            </AnimateHeight>
        </div>
    );
}

export default Accordion;
