import {PropsWithChildren, ReactElement, useState} from "react";
import classNames from "classnames";

interface Props {
    className?: string;
    tooltipContent: string;
    shouldShowTooltip: boolean;
}

function TooltipWrapper({tooltipContent, shouldShowTooltip, className, children}: PropsWithChildren<Props>): ReactElement {
    const [
        isHovering,
        setIsHovering,
    ] = useState<boolean>(false);

    function toggleHoverTooltip(): void {
        setIsHovering(s => !s);
    }

    return (
        <div
            className={classNames("tooltip", className, {
                "is-hover": isHovering,
            })}
            onMouseEnter={toggleHoverTooltip}
            onMouseLeave={toggleHoverTooltip}
        >
            <div className="tooltip_icon">
                {children}
            </div>
            {shouldShowTooltip &&
            <div className="tooltip_content">
                <div className="tooltip-wrapper_text">{tooltipContent}</div>
            </div>
            }

        </div>
    );
}

export default TooltipWrapper;
