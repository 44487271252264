import {ChangeEvent, ChangeEventHandler} from "react";

export function genericOnChangeHelper<T>(form: T, setForm: (_form: T) => void, key: T extends null | undefined ? string : keyof T): ChangeEventHandler<HTMLInputElement> {
    return (e: ChangeEvent<HTMLInputElement>) => {
        setForm({
            ...form,
            [key]: e?.target?.value,
        });
    };
}
