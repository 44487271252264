import {ReactElement, ReactNode} from "react";
import classNames from "classnames";

export enum GoFrensTagType {
    PERCENT = "percent",
    BOGO = "bogo",
    FLAT_RATE = "flat",
}

interface Props {
    thumbnailUrl: string;
    title: string;
    subtitles?: string[];
    description?: string;
    tag?: string;
    tagColour?: GoFrensTagType;
    children?: ReactNode;
    width?: number | string;
    increasedImgHeight?: boolean;
    reducedInnerPadding?: boolean;
}

function GoFrensRewards(props: Props): ReactElement {

    function renderSubtitles(): ReactElement {
        if (!props.subtitles) {
            return null;
        }

        const subsArr: ReactElement[] = [];
        for (let i = 0; i < props.subtitles.length; i++) {
            subsArr.push(<p key={`subtitle_s${i}`}>{props.subtitles[i]}</p>);
            if (i < props.subtitles.length - 1) {
                subsArr.push(<div
                    className="gofrens-card_ellipse"
                    key={`ellipse_${i}`}
                >
                    <div className="gofrens-card_ellipse_content"/>
                </div>);
            }
        }

        return (
            <div className="gofrens-card_subtitles">
                {subsArr}
            </div>
        );
    }

    return (
        <div
            className="gofrens-card"
            style={{
                minWidth: props.width,
                maxWidth: props.width,
                paddingBottom: !props.reducedInnerPadding
                    ? "0.5rem"
                    : "0",
            }}
        >
            <div
                className={classNames("gofrens-card_image-container", {
                    "gofrens-card_image-container_normal-height": !props.increasedImgHeight,
                    "gofrens-card_image-container_increased-height": props.increasedImgHeight,
                })}
            >
                {props.tag && props.tagColour &&
                <div
                    className={classNames(
                        "gofrens-card_promo-tag",
                        props.tagColour && "gofrens-card_promo-tag_" + props.tagColour,
                    )}
                >
                    <p>{props.tag}</p>
                </div>
                }
                {props.thumbnailUrl &&
                <img
                    className="gofrens-card_image-container_image-wrapper"
                    alt="GoFriends Card Thumbnail"
                    src={props.thumbnailUrl}
                />
                }
            </div>
            <div className="gofrens-card_content">
                <div
                    className={classNames({
                        "gofrens-card_content_inner": !props.reducedInnerPadding,
                        "gofrens-card_content_inner-reduced-padding": props.reducedInnerPadding,
                    })}
                >
                    <h4>{props.title}</h4>
                    {renderSubtitles()}
                    <p className="gofrens-card_content_description">
                        {props.description}
                    </p>
                </div>
                {props.children}
            </div>
        </div>
    );
}

export default GoFrensRewards;