import {ReactElement, useEffect, useState} from "react";
import {NftGrouping, Token} from "@devour/client";
import {useSelector} from "react-redux";
import FrameOneSwitchInput from "@/components/inputs/FrameOneSwitchInput";
import {LuLayoutGrid, LuList} from "react-icons/lu";
import {CiSquareQuestion} from "react-icons/ci";
import {IStore} from "@/redux/defaultStore";
import GoFrensNftCard from "@/components/goFrens/GoFrensNftCard";
import {isDesktop, isTablet} from "react-device-detect";
import GoFrensOwnershipRowInfo from "@/components/goFrens/GoFrensOwnershipRowInfo";
import {useGetNftOwnerships} from "@/hooks/useGetNftOwnerships";

interface Props {
    show: boolean;
    grouping: NftGrouping;
    owned: number;
}

enum NftOwnershipViewState {
    GRID,
    ROW,
}

function DrawerOwnedNfts(props: Props): ReactElement {
    const [
        nftOwnershipState,
        setNftOwnershipState,
    ] = useState<NftOwnershipViewState>(NftOwnershipViewState.GRID);
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);

    const {data: nftOwnershipsData} = useGetNftOwnerships(fullToken as Token);

    useEffect(() => {
        if (props.show) {
            setNftOwnershipState(NftOwnershipViewState.GRID);
        }
    }, [props.show]);

    function toggleView() {
        if (nftOwnershipState === NftOwnershipViewState.GRID) {
            setNftOwnershipState(NftOwnershipViewState.ROW);
        } else {
            setNftOwnershipState(NftOwnershipViewState.GRID);
        }
    }

    function renderNoneOwnedView() {
        return (
            <div className="gofrens-community-info-drawer_contents_owned-nfts_view_none">
                <CiSquareQuestion className="gofrens-community-info-drawer_contents_owned-nfts_view_none_graphic"/>
                <div className="gofrens-community-info-drawer_contents_owned-nfts_view_none_title">
                    There are no NFTs you own
                </div>
                <div className="gofrens-community-info-drawer_contents_owned-nfts_view_none_subtitle">
                    {`Buy ${props?.grouping?.name} today to rep this community and start earning benefits!`}
                </div>
            </div>
        );
    }

    function renderGridView(): ReactElement {
        if (props.show && nftOwnershipsData?.nftOwnerships) {
            const className = `gofrens-community-info-drawer_contents_owned-nfts_view_grid${!(isDesktop || isTablet) ? " is-mobile" : ""}`;


            return (
                <div className={className}>
                    {nftOwnershipsData?.nftOwnerships.map((ownership) => {
                        if (ownership?.nftGroupingId === props?.grouping?.id) {
                            return (
                                <GoFrensNftCard
                                    key={ownership.id}
                                    ownership={ownership}
                                    isSmallSize={true}
                                />
                            );
                        }
                    })}
                </div>
            );
        }
        return null;
    }

    function renderRowView() {
        return (
            <div className="gofrens-community-info-drawer_contents_owned-nfts_view_row">
                {nftOwnershipsData?.nftOwnerships.map((ownership) => {
                    if (ownership?.nftGroupingId === props?.grouping?.id) {
                        return (
                            <GoFrensOwnershipRowInfo
                                key={ownership.id}
                                grouping={props.grouping}
                                ownership={ownership}
                            />
                        );
                    }
                })}
            </div>
        );
    }

    return (
        <>
            <div className="gofrens-community-info-drawer_contents_owned-nfts">
                <div className="gofrens-community-info-drawer_contents_owned-nfts_top-row">
                    <p className="gofrens-community-info-drawer_contents_owned-nfts_top-row_title">
                        Owned NFTs
                        <span className="gofrens-community-info-drawer_contents_owned-nfts_top-row_title_count">
                            {props?.owned}
                        </span>
                    </p>
                    {props?.owned > 0 &&
                    <div className="gofrens-community-info-drawer_contents_owned-nfts_top-row_toggle">
                        <FrameOneSwitchInput
                            <NftOwnershipViewState>
                            name="nft-view"
                            value={nftOwnershipState}
                            onToggle={toggleView}
                            options={[
                                {
                                    render:
                                            <LuLayoutGrid
                                                className="gofrens-community-info-drawer_contents_owned-nfts_top-row_toggle_icon"
                                            />,
                                    value: NftOwnershipViewState.GRID,
                                },
                                {
                                    render:
                                            <LuList
                                                className="gofrens-community-info-drawer_contents_owned-nfts_top-row_toggle_icon"
                                            />,
                                    value: NftOwnershipViewState.ROW,
                                },
                            ]}
                        />
                    </div>
                    }
                </div>
                <div className="gofrens-community-info-drawer_contents_owned-nfts_view">
                    {props?.owned > 0
                        ? <>
                            {nftOwnershipState === NftOwnershipViewState.ROW
                                ? renderRowView()
                                : renderGridView()}
                        </>
                        : renderNoneOwnedView()}
                </div>
            </div>
        </>
    );
}

export default DrawerOwnedNfts;
