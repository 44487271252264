import {ReactElement} from "react";
import Skeleton from "react-loading-skeleton";
import {AiOutlineUnorderedList} from "react-icons/ai";
import Divider from "@/components/Divider";
import {FiChevronRight} from "react-icons/fi";
import {isDesktop} from "react-device-detect";

function RestaurantMenuBrowseInfoSkeleton(): ReactElement {
    return (
        <>
            <div className={isDesktop
                ? "restaurant-menus-header_info"
                : ""}
            style={{width: "100%",
                alignItems: "center"}}>
                <div className="restaurant-page-skeleton_header">
                    <div className="restaurant-page_title">
                        <div className="restaurant-page-skeleton_header_title react-loading-skeleton"/>
                        <FiChevronRight className="restaurant-page_title_container_icon"/>
                    </div>
                    <div className="restaurant-page-skeleton_header_text react-loading-skeleton"/>
                    <div className="restaurant-page-skeleton_header_taxonomy">
                        {Array.from({length: 5}, (_, i) => <div className="restaurant-page-skeleton_header_taxonomy_tags react-loading-skeleton"
                            key={i}/>)}
                    </div>
                </div>
                {!isDesktop && <br/>}

                <div className="restaurant-menus-header_info_handoff">
                    {isDesktop &&
                    <>
                        <div className="restaurant-page-skeleton_header_switch react-loading-skeleton"/>
                        <br />
                    </>
                    }

                    <div className="restaurant-handoff-options">
                        <div className="restaurant-handoff-options_info">
                            <div className="restaurant-handoff-options_info_left skeleton-adjust"
                                style={{backgroundColor: "transparent"}}>
                                <Skeleton height={50}/>
                            </div>
                            <div className="restaurant-handoff-options_info_right skeleton-adjust"
                                style={{backgroundColor: "transparent"}}>
                                <Skeleton height={50}/>
                            </div>
                        </div>
                    </div>
                    {!isDesktop &&
                    <>
                        <br />
                        <div className="restaurant-page-skeleton_header_switch react-loading-skeleton"/>
                    </>
                    }
                </div>
            </div>
        </>
    );
}

function RestaurantMenuCategoryJumperSkeleton(): ReactElement {
    return (
        <>
            {!isDesktop &&
            <div className="restaurant-page-skeleton_search react-loading-skeleton"/>
            }
            <div className="restaurant-page-skeleton_jumper-row">
                <div className="category-jumper restaurant-menus-browse_jumper-search-container_preload-skeleton-adjust">
                    {Array.from({length: 10}, (_, i) => <Skeleton width={100} height={32} key={i}/>)}
                </div>
                {isDesktop && <div className="restaurant-page-skeleton_search react-loading-skeleton"/>}
            </div>
        </>
    );
}

function OrderAgainSkeleton(): ReactElement {
    return (
        <div className="restaurant-menus-browse_order-agains restaurant-page-skeleton_order-again">
            <div className="restaurant-page-skeleton_cat-title react-loading-skeleton"/>
            <ul className="restaurant-menus-browse_order-again_list">
                {Array.from({length: 3}, (_, i) => <li className='restaurant-menus-browse_order-again_list_item' key={i}>
                    <Skeleton className='menu-item-card react-loading-skeleton' height={173}/>
                </li>)}
            </ul>
        </div>
    );
}

function RestaurantMenuItemsSkeleton(): ReactElement {
    return (
        <div className='restaurant-menus-browse_category'>
            <div className="restaurant-page-skeleton_cat-title react-loading-skeleton"/>
            <ul className='restaurant-menus-browse_category_list'>
                {Array.from({length: 6}, (_, i) => <li className='restaurant-menus-browse_category_list_item' key={i}>
                    {/* some dummy tricks to maintain the card style */}
                    <div className='menu-item-card react-loading-skeleton'>
                        <div className='menu-item-card_left'>
                            <div className='menu-item-card_header' style={{flexDirection: "column"}}>
                                <div className='menu-item-card_name' style={{visibility: "hidden"}}>
                                        Apple Pear
                                </div>
                                <div className='menu-item-card_description' style={{visibility: "hidden"}}>
                                        spring mix + kale, almonds, apples, pears, white cheddar, basil, white balsamic
                                        vinaigrette
                                </div>
                                <div className='menu-item-card_price' style={{visibility: "hidden"}}>$11.55</div>
                            </div>
                        </div>
                        <div className='menu-item-card_right'></div>
                    </div>
                </li>)}
            </ul>
        </div>
    );
}

function RestaurantMenuSkeleton(): ReactElement {
    return (
        <div className="restaurant-menu-page-content-skeleton_main">
            <div className="restaurant-page_section-margin">
                <RestaurantMenuBrowseInfoSkeleton/>
            </div>
            <Divider/>
            <div className="restaurant-page_section-margin">
                <RestaurantMenuCategoryJumperSkeleton/>
            </div>
            <div className="restaurant-page_section-margin">
                <OrderAgainSkeleton/>
            </div>
            <Divider/>
            <div className="restaurant-page_section-margin">
                <RestaurantMenuItemsSkeleton/>
            </div>
        </div>
    );
}

export default RestaurantMenuSkeleton;