import {ButtonHTMLAttributes, Dispatch, ReactElement, SetStateAction} from "react";
import {
    GetGlobalOrderDiscountsResponse,
    OrderDiscount,
    RestaurantSearchResult,
} from "@devour/client";
import RestaurantCard from "./RestaurantCard";
import {useParams} from "react-router";
import GlobalPromoCard from "@/pages/restaurants/components/GlobalPromoCard";
import FrameButton from "@/components/buttons/FrameButton";
import {isDesktop, isMobile, isTablet} from "react-device-detect";
import {FaChevronRight} from "react-icons/fa";
import {ViewStates} from "@/pages/restaurants/RestaurantSearchResultsPage";
import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {useGetNftOwnershipsForUser} from "@/hooks/useGetNftOwnershipsForUser";
import Skeleton from "react-loading-skeleton";

interface Props {
    globalOrderDiscounts: GetGlobalOrderDiscountsResponse;
    goFrensRestaurants: Array<RestaurantSearchResult>;
    setViewState: Dispatch<SetStateAction<ViewStates>>;
}


function RestaurantSearchGoFrensPromotions(props: Props): ReactElement {
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const {data: nftOwnershipResponse, isFetching: isNftOwnershipDataFetching} = useGetNftOwnershipsForUser(fullToken);

    const {placeId} = useParams<{ placeId: string; }>();

    function renderGlobalPromo(promo: OrderDiscount): ReactElement {
        return (
            <GlobalPromoCard
                key={promo.id}
                promo={promo}
                nftOwnershipResponse={nftOwnershipResponse}
                className="restaurants-search-results-page_list_item"
            />
        );
    }

    function renderRestaurant(restaurant: RestaurantSearchResult): ReactElement {
        return (
            <div
                key={restaurant._id}
                className="restaurants-search-results-page_list_item"
            >
                <RestaurantCard
                    placeId={placeId}
                    restaurant={restaurant}
                    nftOwnershipResponse={nftOwnershipResponse}
                    showPromos="on"
                />
            </div>
        );
    }

    if (isNftOwnershipDataFetching) {
        let numOfSkeletons = 4; // desktop
        if (isTablet) {
            numOfSkeletons = 2; // tablet
        } else if (isMobile) {
            numOfSkeletons = 1; // mobile
        }

        return (
            <div className="restaurants-search-gofrens-promotions">
                <div className="restaurants-search-gofrens-promotions_title">
                    <Skeleton height={36} width={250}/>
                </div>
                <div className="restaurants-search-results-page_list">
                    {Array.from({length: numOfSkeletons}, (_, i) => <div className="restaurants-search-results_list_item" key={i}>
                        <div className="restaurant_search_item_card ">
                            <Skeleton height={163}/>
                        </div>
                        <div className="restaurant-search_item_description">
                            <Skeleton/>
                        </div>
                    </div>)}
                </div>
            </div>
        );
    }

    if (!props.goFrensRestaurants.length && !props.globalOrderDiscounts?.orderDiscounts?.length) {
        return null;
    }

    return (
        <div className="restaurants-search-gofrens-promotions">
            <div className="restaurants-search-gofrens-promotions_title">
                <h3>Available Promotions</h3>
                <FrameButton
                    <ButtonHTMLAttributes<HTMLButtonElement>>
                    color="white-drop-shadow"
                    size={isMobile && !isTablet
                        ? "icon"
                        : "narrow"}
                    leftIcon={isMobile && !isTablet
                        ? FaChevronRight
                        : undefined}
                    className="restaurants-search-gofrens-promotions_show-all"
                    onClick={() => props.setViewState(ViewStates.PROMOTIONS)}
                >
                    {isDesktop || isTablet
                        ? "View All"
                        : ""}
                </FrameButton>
            </div>
            <div className="restaurants-search-results-page_list">
                {props.globalOrderDiscounts?.orderDiscounts?.length > 0 && props.globalOrderDiscounts.orderDiscounts.map(renderGlobalPromo)}
                {props.goFrensRestaurants.length > 0 && props.goFrensRestaurants.map(renderRestaurant)}
            </div>
        </div>
    );
}


export default RestaurantSearchGoFrensPromotions;
