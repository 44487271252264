/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddWalletRequestBody
 */
export interface AddWalletRequestBody {
    /**
     * 
     * @type {string}
     * @memberof AddWalletRequestBody
     */
    signature: string;
    /**
     * 
     * @type {string}
     * @memberof AddWalletRequestBody
     */
    publicKey: string;
}

export function AddWalletRequestBodyFromJSON(json: any): AddWalletRequestBody {
    return AddWalletRequestBodyFromJSONTyped(json, false);
}

export function AddWalletRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddWalletRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'signature': json['signature'],
        'publicKey': json['publicKey'],
    };
}

export function AddWalletRequestBodyToJSON(value?: AddWalletRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'signature': value.signature,
        'publicKey': value.publicKey,
    };
}


