/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetWithdrawFeeAmountResponse
 */
export interface GetWithdrawFeeAmountResponse {
    /**
     * 
     * @type {number}
     * @memberof GetWithdrawFeeAmountResponse
     */
    withdrawFeeInUsd: number;
    /**
     * 
     * @type {number}
     * @memberof GetWithdrawFeeAmountResponse
     */
    withdrawFeeInDpay: number;
    /**
     * 
     * @type {number}
     * @memberof GetWithdrawFeeAmountResponse
     */
    dpayPriceInUsd: number;
}

export function GetWithdrawFeeAmountResponseFromJSON(json: any): GetWithdrawFeeAmountResponse {
    return GetWithdrawFeeAmountResponseFromJSONTyped(json, false);
}

export function GetWithdrawFeeAmountResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetWithdrawFeeAmountResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'withdrawFeeInUsd': json['withdrawFeeInUsd'],
        'withdrawFeeInDpay': json['withdrawFeeInDpay'],
        'dpayPriceInUsd': json['dpayPriceInUsd'],
    };
}

export function GetWithdrawFeeAmountResponseToJSON(value?: GetWithdrawFeeAmountResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'withdrawFeeInUsd': value.withdrawFeeInUsd,
        'withdrawFeeInDpay': value.withdrawFeeInDpay,
        'dpayPriceInUsd': value.dpayPriceInUsd,
    };
}


