import {ChangeEvent, Dispatch, ReactElement, ReactNode, SetStateAction, useState} from "react";
import {NftOwnershipInformation, NftGrouping, RestaurantSearchResult} from "@devour/client";
import FrameOneAutoPanel from "../../../components/modals/autoPanelComponents/FrameOneAutoPanel";
import FrameAutoPanelHeader from "../../../components/modals/autoPanelComponents/FrameAutoPanelHeader";
import FrameAutoPanelBody from "../../../components/modals/autoPanelComponents/FrameAutoPanelBody";
import FrameAutoPanelFooter from "../../../components/modals/autoPanelComponents/FrameAutoPanelFooter";
import NftSelectCard2 from "../../../components/NftSelectCard2";
import {cloneDeep} from "lodash";
import {RestaurantSearchFiltersQuery} from "../../../utils/restaurantsSearchFilter";
import {AiOutlineSearch} from "react-icons/ai";
import FrameButton from "../../../components/buttons/FrameButton";
import {getExclusiveItemNfts} from "../../../utils/getExclusiveItemNfts";

interface Props {
    restaurants: Array<RestaurantSearchResult>;
    isOpen: boolean;
    updateOpenStatus: Dispatch<SetStateAction<boolean>>;
    restaurantFilters: RestaurantSearchFiltersQuery;
    updateRestaurantFilters: Dispatch<SetStateAction<RestaurantSearchFiltersQuery>>;
    nftOwnershipResponse: NftOwnershipInformation;
}

function RestaurantSearchNftGroupingBottomPanel(props: Props): ReactElement {

    const [
        searchQuery,
        setSearchQuery,
    ] = useState<string>("");

    const nftGroupingIdsActive: Array<string> = props.restaurants.map((restaurant) => {

        return getExclusiveItemNfts(restaurant);

    }) // Map to `Array<Array<string>>`
        .flat(); // Flatten to `Array<string>`

    const allNftGroupings = props.nftOwnershipResponse?.nftGroupings.concat(props.nftOwnershipResponse?.myGroupings);
    const usedNfts: Array<NftGrouping> = allNftGroupings.filter((grouping) => {
        if (searchQuery && !grouping?.name.toLowerCase().includes(searchQuery.trim().toLowerCase())) {
            return false;
        }
        if (props.nftOwnershipResponse?.nftOwnerships?.[0]) {
            for (const nftOwnership of props.nftOwnershipResponse?.nftOwnerships?.[0]) {
                const userHasNft = props.nftOwnershipResponse?.nftTrackers?.find(t => t.id === nftOwnership.nftTrackerId)?.groupingId === grouping.id;
                const restaurantsUseNft = nftGroupingIdsActive.includes(grouping.id);
                if (userHasNft && restaurantsUseNft) {
                    return true;
                }
            }
        }

        return false;
    });

    function onChangeHelper(e: ChangeEvent<HTMLInputElement>) {
        setSearchQuery(e.target.value);
    }

    /**
	 * Handle NFT Grouping change.
	 */
    function nftGroupingOnChange(groupingId: string): void {
        // Attempt to find this option in the checked items array
        const newGroupings: Array<string> = cloneDeep(props.restaurantFilters.nftGroupings || []);

        const thisIndex = newGroupings.indexOf(groupingId);

        if (thisIndex > -1) {
            // Remove this option from list of selected items
            newGroupings.splice(thisIndex, 1);
        } else {
            newGroupings.push(groupingId);
        }

        props.updateRestaurantFilters({
            ...props.restaurantFilters,
            nftGroupings: newGroupings,
        });
    }

    function nftGroupingSelectAll(): void {
        // new Set to quickly remove duplicates that get found
        const newGroupings: Array<string> = [...new Set(props.nftOwnershipResponse?.nftOwnerships?.[0]?.map((ownership) => ownership.nftGroupingId))];

        props.updateRestaurantFilters({
            ...props.restaurantFilters,
            nftGroupings: newGroupings,
        });
    }

    function nftGroupingDeselectAll(): void {
        props.updateRestaurantFilters({
            ...props.restaurantFilters,
            nftGroupings: [],
        });
    }

    function renderNftCards(grouping: NftGrouping, i: number): ReactNode {

        const merchantsSupporting = props.restaurants.reduce((acc, rest: RestaurantSearchResult) => {
            const findPromos = rest.promos?.find(d => d.isDisplayedGoFrens && d.nftGroupings.includes(grouping.id));
            const findSecretItems = rest.secretMenuItems?.find((item) => item.nftGroupings.includes(grouping.id));
            if (findPromos || findSecretItems) {
                return acc + 1;
            }

            return acc;
        }, 0);

        return (
            <li key={`nft-grouping-card_${i}`}>
                <NftSelectCard2
                    grouping={grouping}
                    isSelected={props.restaurantFilters.nftGroupings?.includes(grouping.id)}
                    onSelect={() => nftGroupingOnChange(grouping.id)}
                    merchantsSupporting={merchantsSupporting}
                />
            </li>
        );
    }

    function togglePanel() {
        props.updateOpenStatus(!props.isOpen);
    }

    if (!props.restaurants) {
        return null;
    }

    return (
        <FrameOneAutoPanel
            isOpen={props.isOpen}
            toggle={togglePanel}
            contentClassName="restaurant-search-nft-grouping-bottom-panel"
            maxHeight={true}
            size="lg"
        >
            <FrameAutoPanelHeader
                title="Available Promotions"
                toggle={togglePanel}
            />

            <FrameAutoPanelBody className="restaurant-search-nft-grouping-bottom-panel_body">

                <div className="restaurant-search-nft-grouping-bottom-panel_search">
                    <div className="restaurant-search-nft-grouping-bottom-panel_search_input">
                        <AiOutlineSearch/>
                        <input
                            type="text"
                            placeholder="Search your promotions"
                            value={searchQuery}
                            onChange={onChangeHelper}
                        />
                    </div>
                    <div className="restaurant-search-nft-grouping-bottom-panel_search_select-all">
                        <button
                            className="restaurant-search-nft-grouping-bottom-panel_search_select-all_button"
                            type="button"
                            onClick={nftGroupingSelectAll}
                        >
							Select all
                        </button>
                    </div>
                </div>

                <div className="restaurant-search-nft-grouping-bottom-panel_info">

                    {searchQuery
                        ? <div className="restaurant-search-nft-grouping-bottom-panel_info_counter">
                            {usedNfts.length} results
                        </div>
					 : <div className="restaurant-search-nft-grouping-bottom-panel_info_counter">
                            {props.restaurantFilters.nftGroupings.length} collections selected
                        </div>
                    }
                    <div className="restaurant-search-nft-grouping-bottom-panel_info_deselect">
                        <button
                            onClick={nftGroupingDeselectAll}
                            type="button"
                        >
							Deselect all
                        </button>
                    </div>
                </div>

                <ul className="restaurant-search-nft-grouping-bottom-panel_list">
                    {usedNfts.map(renderNftCards)}
                </ul>

            </FrameAutoPanelBody>
            <FrameAutoPanelFooter>
                <FrameButton
                    color="purple"
                    size="large"
                    onClick={togglePanel}
                    className={"restaurant-search-nft-grouping-bottom-panel_confirm-button"}
                >
					Confirm
                </FrameButton>
            </FrameAutoPanelFooter>
        </FrameOneAutoPanel>
    );
}

export default RestaurantSearchNftGroupingBottomPanel;
