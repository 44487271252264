import {ReactElement} from "react";
import {MenuOrder, OrderDiscountType} from "@devour/client";
import MenuOrderDiscounts from "./MenuOrderDiscounts";
import MenuOrderFeesTaxes from "./MenuOrderFeesTaxes";
import Skeleton from "react-loading-skeleton";

interface Props {
    menuOrder: MenuOrder;
}

function MenuOrderCheckoutTotals(props: Props): ReactElement {

    const freeDelivery = props.menuOrder?.discounts?.find(discount => discount.type === OrderDiscountType.DELIVERY && discount.amount === props.menuOrder.deliveryCharge);

    function renderPayments(): JSX.Element {
        // Don't display tax discounts to customer
        const displayedPayments = props.menuOrder.discounts?.filter((discount) => {
            return discount.type === OrderDiscountType.PAYMENT;
        });
        if (!displayedPayments?.length) {
            return null;
        }
        return (
            <div className="menu-order-checkout-payments">
                {displayedPayments.map((discount, index) => <div
                    className="menu-order-checkout-totals_row"
                    key={`menu-order-discount-${discount.label}-${index}`}
                >
                    <div className="menu-order-checkout-totals_row_label">
                        <p>{discount.label}</p>
                    </div>
                    <div className="menu-order-checkout-totals_row_value">
							- ${discount.amount.toFixed(2)}
                    </div>
                </div>)}
            </div>
        );
    }

    function renderRefundTotal() {

        const refundTotal = props.menuOrder.refunds?.reduce((total, refund) => total + refund.fiat, 0);

        return (
            <>
                <hr className="menu-order-checkout-totals_row_returnal_hr" />
                <div className="menu-order-checkout-totals_row">
                    <div className="menu-order-checkout-totals_row_returnal_label">
                        <p className="devour-cart-card_fees-font-adjust">
						Returned
                        </p>
                    </div>
                    <div className="menu-order-checkout-totals_row_returnal_value">
                        <p className="devour-cart-card_fees-font-adjust">
                            {props.menuOrder.refunds?.length > 0
                                ? `-$${refundTotal}`
                                : <Skeleton height={10}/>}
                        </p>
                    </div>
                </div>
                <div className="menu-order-checkout-totals_row">
                    <div className="menu-order-checkout-totals_row_returnal_total-label">
                        <p className="devour-cart-card_fees-font-adjust">
						Final total
                        </p>
                    </div>
                    <div className="menu-order-checkout-totals_row_returnal_total-value">
                        <p className="devour-cart-card_fees-font-adjust">
                            {props.menuOrder.callbackComplete
                                ? <strong>${(props.menuOrder.stripeTotal - refundTotal).toFixed(2)}</strong>
                                : <Skeleton height={10}/>}
                        </p>
                    </div>
                </div>
            </>
        );

    }

    return (
        <div className="menu-order-checkout-totals">
            <div className="menu-order-checkout-totals_row">
                <div className="menu-order-checkout-totals_row_label">
                    <p className="devour-cart-card_subtotal-margin-adjust">
                        Subtotal
                    </p>
                </div>
                <div className="menu-order-checkout-totals_row_value">
                    <p className="devour-cart-card_subtotal-margin-adjust">
						${props.menuOrder.subtotal.toFixed(2)}
                    </p>
                </div>
            </div>

            <MenuOrderDiscounts
                menuOrder={props.menuOrder}
            />

            <div className="menu-order-checkout-totals_row">
                {freeDelivery
                    ? <><span className="menu-order-checkout-totals_free-delivery">Free Delivery</span>
                        <span className="menu-order-checkout-totals_free-delivery-amount">
                            <span className="menu-order-checkout-totals_free-delivery-amount_before">
                                <s>${props.menuOrder?.deliveryCharge.toFixed(2)}</s>
                            </span>
                            <span className="menu-order-checkout-totals_free-delivery-amount_after">
                        -${props.menuOrder?.deliveryCharge.toFixed(2)}
                            </span>

                        </span>
                    </>
                    : <><div className="menu-order-checkout-totals_row_label">
                        <p className="devour-cart-card_fees-font-adjust">
                            Delivery Fee
                        </p>
                    </div><div className="menu-order-checkout-totals_row_value">
                        <p className="devour-cart-card_fees-font-adjust">
                            {props.menuOrder.callbackComplete
                                ? `$${props.menuOrder.deliveryCharge.toFixed(2)}`
                                : <Skeleton height={10} />}
                        </p>
                    </div></>

                }
            </div>
            <MenuOrderFeesTaxes
                menuOrder={props.menuOrder}
            />

            {props.menuOrder.tipDelivery > 0 &&
            <div className="menu-order-checkout-totals_tip-display-only">
				    <div className="menu-order-checkout-totals_row">
				        <div className="menu-order-checkout-totals_row_label">
				            <p className="devour-cart-card_fees-font-adjust">
								Driver Tip
				            </p>
				        </div>
				        <div className="menu-order-checkout-totals_row_value">
				            <p className="devour-cart-card_fees-font-adjust">
								${props.menuOrder.tipDelivery.toFixed(2)}
				            </p>
				        </div>
				    </div>
            </div>
            }

            {renderPayments()}

            <div
                className="menu-order-checkout-totals_row menu-order-checkout-totals_display devour-cart-card_fees-margin-top-adjust">
                <div className="menu-order-checkout-totals_row_label">
                    <p className="menu-order-checkout-totals_row_label_total-label">
                        <strong>Total</strong>
                    </p>
                </div>
                <div className="menu-order-checkout-totals_row_value_total-value">
                    {props.menuOrder.callbackComplete
                        ? <strong>${props.menuOrder.stripeTotal.toFixed(2)}</strong>
                        : <Skeleton height={10}/>}
                </div>
            </div>

            {props.menuOrder.refunds?.length > 0 && renderRefundTotal()}

        </div>
    );
}

export default MenuOrderCheckoutTotals;
