/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NftGrouping,
    NftGroupingFromJSON,
    NftGroupingFromJSONTyped,
    NftGroupingToJSON,
    NftTracker,
    NftTrackerFromJSON,
    NftTrackerFromJSONTyped,
    NftTrackerToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetNftGroupingResponse
 */
export interface GetNftGroupingResponse {
    /**
     * 
     * @type {NftGrouping}
     * @memberof GetNftGroupingResponse
     */
    nftGrouping: NftGrouping;
    /**
     * 
     * @type {Array<NftTracker>}
     * @memberof GetNftGroupingResponse
     */
    nftTrackers: Array<NftTracker>;
}

export function GetNftGroupingResponseFromJSON(json: any): GetNftGroupingResponse {
    return GetNftGroupingResponseFromJSONTyped(json, false);
}

export function GetNftGroupingResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetNftGroupingResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nftGrouping': NftGroupingFromJSON(json['nftGrouping']),
        'nftTrackers': ((json['nftTrackers'] as Array<any>).map(NftTrackerFromJSON)),
    };
}

export function GetNftGroupingResponseToJSON(value?: GetNftGroupingResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nftGrouping': NftGroupingToJSON(value.nftGrouping),
        'nftTrackers': ((value.nftTrackers as Array<any>).map(NftTrackerToJSON)),
    };
}


