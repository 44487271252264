import {ReactElement} from "react";
import {MenuOrderItem, MenuOrderSubItem} from "@devour/client";

interface Props {
    item: MenuOrderItem;
}

function OrderReceiptMenuItem(props: Props): ReactElement {

    function renderOrderCustomizationItem(customizationItem: MenuOrderSubItem, index: number, isSub = false): JSX.Element {
        return (
            <>
                <div
                    key={`order-customization-${index}`}
                    className="order-receipt-menu-item_rest-col_content"
                >
                    <div className="order-receipt-menu-item_rest-col_content_custom-wrapper">
                        <small className="order-history-details-order-item_content_description">
                            {isSub && "-"} {customizationItem?.name}
                        </small>

                        <small>
                            {customizationItem.price > 0 && ` +$${customizationItem.price.toFixed(2)}`}
                        </small>
                    </div>


                </div>
                {customizationItem.customizations?.length > 0 &&
                <div className="order-receipt-menu-item_rest-col_content_sub">
					    {customizationItem.customizations.map((c, i) => renderOrderCustomizationItem(c, i, true))}
                </div>
                }
            </>
        );
    }

    if (!props.item) {
        return null;
    }

    return (
        <div className="order-receipt-menu-item">
            <div className="order-receipt-menu-item_qty-col">
                <div className="order-receipt-menu-item_qty-col_qty">
                    <span>
                        {props.item.quantity}x
                    </span>
                </div>
            </div>

            <div className="order-receipt-menu-item_rest-col">
                <div className="order-receipt-menu-item_rest-col_content">
                    <p className="order-history-details-order-item_content_title">
                        {props.item?.name}
                    </p>


                    <p className="order-history-details-order-item_content_title">
                        ${(props.item.price * props.item.quantity).toFixed(2)}
                    </p>
                </div>

                {props.item.customizations &&
                <div className="order-receipt-menu-item_rest-col_content_spacer">
                    {props.item.customizations && props.item.customizations.map((c, i) => renderOrderCustomizationItem(c, i, false))}
                </div>
                }

                {props.item.notes &&
                <div
                    className="order-receipt-menu-item_rest-col_content order-receipt-menu-item_rest-col_content_spacer">
                    <p className="order-history-details-order-item_content_description">
                        {props.item.notes}
                    </p>
                </div>
                }
            </div>
        </div>
    );
}

export default OrderReceiptMenuItem;
