/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ServiceFeeType,
    ServiceFeeTypeFromJSON,
    ServiceFeeTypeFromJSONTyped,
    ServiceFeeTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface MenuOrderServiceFee
 */
export interface MenuOrderServiceFee {
    /**
     * 
     * @type {ServiceFeeType}
     * @memberof MenuOrderServiceFee
     */
    type: ServiceFeeType;
    /**
     * 
     * @type {string}
     * @memberof MenuOrderServiceFee
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof MenuOrderServiceFee
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof MenuOrderServiceFee
     */
    amount: number;
}

export function MenuOrderServiceFeeFromJSON(json: any): MenuOrderServiceFee {
    return MenuOrderServiceFeeFromJSONTyped(json, false);
}

export function MenuOrderServiceFeeFromJSONTyped(json: any, ignoreDiscriminator: boolean): MenuOrderServiceFee {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': ServiceFeeTypeFromJSON(json['type']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'amount': json['amount'],
    };
}

export function MenuOrderServiceFeeToJSON(value?: MenuOrderServiceFee | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': ServiceFeeTypeToJSON(value.type),
        'name': value.name,
        'description': value.description,
        'amount': value.amount,
    };
}


