/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrderCancelBody
 */
export interface OrderCancelBody {
    /**
     * 
     * @type {string}
     * @memberof OrderCancelBody
     */
    cancellationReason?: string;
}

export function OrderCancelBodyFromJSON(json: any): OrderCancelBody {
    return OrderCancelBodyFromJSONTyped(json, false);
}

export function OrderCancelBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderCancelBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cancellationReason': !exists(json, 'cancellationReason') ? undefined : json['cancellationReason'],
    };
}

export function OrderCancelBodyToJSON(value?: OrderCancelBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cancellationReason': value.cancellationReason,
    };
}


