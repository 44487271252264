import {Transaction, TransactionSubject} from "@devour/client";

export function getTransactionSubjectCardLabel(transaction: Transaction): string | undefined {

    /**
	 * Custom function to truncate added information on notes
	 * @param str - the note
	 * @param override - string to match and splice from
	 */
    function _customTruncate(str: string, override: string = "Added by") {
        let index = str.indexOf(override);
        if (index !== -1) {
            str = str.slice(0, index);
        }
        index = str.indexOf(" -");
        if (index !== -1) {
            str = ": " + str.slice(0, index);
        }
        return str;
    }

    switch (transaction.subject) {
        case TransactionSubject.WITHDRAW:
            return "Withdraw from account";
        case TransactionSubject.WITHDRAWCANCELLED:
            return `Withdraw request was cancelled, ${import.meta.env.VITE_TOKEN_NAME} returned`;
        case TransactionSubject.WITHDRAWREJECTED:
            return `Withdraw request was cancelled by admin staff, ${import.meta.env.VITE_TOKEN_NAME} returned`;
        case TransactionSubject.DEPOSIT:
            return "Into account";
        case TransactionSubject.LEVELUPREWARD:
            return `${import.meta.env.VITE_TOKEN_NAME} reward for levelling up!`;
        case TransactionSubject.REWARD:
            return `${import.meta.env.VITE_TOKEN_NAME} reward`;
        case TransactionSubject.COINBASEREFUND:
            return "Coinbase payment refund";
        case TransactionSubject.REFUND:
            return "Refund";
        case TransactionSubject.OTHER:
            return "Other";
        case TransactionSubject.ADMINDEBIT:
            return `Debit from admin${transaction.notes && `${_customTruncate(transaction.notes)}`}`;
        case TransactionSubject.ADMINCREDIT:
            return `Removal by admin${transaction.notes && `${_customTruncate(transaction.notes)}`}`;
        case TransactionSubject.INDUSTRYCOLLECTIONOWNERSHIPREWARD:
            return "For owning an industry collection NFT";
        case TransactionSubject.MENUORDERPAYMENT:
            return "For menu order payment";
        case TransactionSubject.MENUORDERDPAYPAYMENTREWARD:
            return `Reward for paying for your menu order via ${import.meta.env.VITE_TOKEN_NAME}`;
        case TransactionSubject.MENUORDERINDUSTRYWALLETREWARD:
            return "Reward owning an industry NFT when ordering";
        case TransactionSubject.MENUORDERREWARDCLAWBACK:
            return `Order Refund - ${import.meta.env.VITE_TOKEN_NAME} Back return`;
        case TransactionSubject.REWARDFORREFERRINGUSER:
            return "Reward for referring user that ordered on the platform";
        case TransactionSubject.FIATPURCHASEDPAY:
            return "Loaded with USD";
        case TransactionSubject.MINTINDUSTRYPASSPURCHASE:
            return "Mint The Industry Pass";
        case TransactionSubject.MINTINDUSTRYPASSREFUND:
            return "Refund minting The Industry Pass";
        case TransactionSubject.ONCHAINREFUNDFORSTRIPEFAIL:
            return `Auto-refund of ${import.meta.env.VITE_TOKEN_NAME} used due to Stripe error.`;
        case TransactionSubject.NFTREDEEMDPAYREWARD:
            return transaction.notes;
    }
}
