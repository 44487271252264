import {useQuery} from "@tanstack/react-query";
import {OrderDiscountsApi, Token, GetGlobalOrderDiscountsResponse} from "@devour/client";
import getConfig from "@/utils/getConfig";

async function fetchGlobalOrderDiscountsForUser(): Promise<GetGlobalOrderDiscountsResponse> {
    return await new OrderDiscountsApi(getConfig()).getGlobalOrderDiscounts();
}

function globalOrderDiscounts(fullToken: Token) {
    return {
        queryKey: [
            "global-order-discounts",
            fullToken?.id,
        ],
        queryFn: () => fetchGlobalOrderDiscountsForUser(),
        keepPreviousData: true,
        staleTime: 30 * 60000,
        refetchOnWindowFocus: false,
        enabled: !!fullToken,
    } as const;
}

export function useGetGlobalOrderDiscountsForUser(fullToken: Token) {
    return useQuery(globalOrderDiscounts(fullToken));
}
