import { FC } from "react";
import FrameOneModal from "./modalComponents/FrameOneModal";
import { isMobile } from "react-device-detect";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import MagicLogin from "@/pages/MagicLogin";


interface props {
    isOpen: boolean;
    onClose: () => void;
}

const MagicLoginModal: FC<props> = ({isOpen, onClose}) => {


    return <FrameOneModal contentClassName="magic-login-modal" size="sm2" isOpen={isOpen} setCloseModal={onClose}>
        <FrameModalHeader toggle={onClose}>
            <img src={import.meta.env.VITE_CDN_URL + "/images/DevourGo-logo-white.png"} alt="" />
        </FrameModalHeader>
        <FrameModalBody>
            <MagicLogin isOnDialog={true}/>
        </FrameModalBody>
    </FrameOneModal>;
};

export default MagicLoginModal;
