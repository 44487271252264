import {ReactElement, useState} from "react";
import {MenuOrder} from "@devour/client";
import FrameButton from "@/components/buttons/FrameButton";
import OrderCancelModal from "@/components/menuOrder/OrderCancelModal";
import {useCancelMenuOrderCheck} from "@/hooks/menuOrder/useCancelMenuOrderCheck";

interface Props {
    menuOrder: MenuOrder;
}

function CancelMenuOrder(props: Props): ReactElement {
    const [showModal, setShowModal] = useState<boolean>(false);
    const {data: cancelMenuOrderCheck, refetch: refetchCancelMenuOrderCheck} = useCancelMenuOrderCheck(props.menuOrder.id);

    async function onDone() {
        setShowModal(false);
        await refetchCancelMenuOrderCheck();
    }

    if (cancelMenuOrderCheck?.hasCancelled) {
        return (
            <div className="order-cancelled">
								Your order has been cancelled and refunded.
            </div>
        );
    }

    if (!cancelMenuOrderCheck?.isCancellable) {
        return null;
    }

    return (
        <div className="order-cancel">

            <OrderCancelModal
                isOpen={showModal}
                menuOrder={props.menuOrder}
                onClose={() => setShowModal(false)}
                onDone={onDone}
            />

            <FrameButton
                color="danger"
                size="large"
                onClick={() => setShowModal(true)}
                className="order-cancel_button"
            >
                Cancel Order
            </FrameButton>

        </div>
    );
}

export default CancelMenuOrder;
