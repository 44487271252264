/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateNftRewardPrizeBody
 */
export interface CreateNftRewardPrizeBody {
    /**
     * 
     * @type {string}
     * @memberof CreateNftRewardPrizeBody
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateNftRewardPrizeBody
     */
    prizeRevealName: string;
    /**
     * 
     * @type {number}
     * @memberof CreateNftRewardPrizeBody
     */
    quantity?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateNftRewardPrizeBody
     */
    emailOnWin: boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateNftRewardPrizeBody
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof CreateNftRewardPrizeBody
     */
    winningImage?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateNftRewardPrizeBody
     */
    sortOrder?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateNftRewardPrizeBody
     */
    onlyAvailableAfterWins?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateNftRewardPrizeBody
     */
    description?: string;
}

export function CreateNftRewardPrizeBodyFromJSON(json: any): CreateNftRewardPrizeBody {
    return CreateNftRewardPrizeBodyFromJSONTyped(json, false);
}

export function CreateNftRewardPrizeBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateNftRewardPrizeBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'prizeRevealName': json['prizeRevealName'],
        'quantity': !exists(json, 'quantity') ? undefined : json['quantity'],
        'emailOnWin': json['emailOnWin'],
        'amount': json['amount'],
        'winningImage': !exists(json, 'winningImage') ? undefined : json['winningImage'],
        'sortOrder': !exists(json, 'sortOrder') ? undefined : json['sortOrder'],
        'onlyAvailableAfterWins': !exists(json, 'onlyAvailableAfterWins') ? undefined : json['onlyAvailableAfterWins'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function CreateNftRewardPrizeBodyToJSON(value?: CreateNftRewardPrizeBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'prizeRevealName': value.prizeRevealName,
        'quantity': value.quantity,
        'emailOnWin': value.emailOnWin,
        'amount': value.amount,
        'winningImage': value.winningImage,
        'sortOrder': value.sortOrder,
        'onlyAvailableAfterWins': value.onlyAvailableAfterWins,
        'description': value.description,
    };
}


