/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpsertNftRewardBody
 */
export interface UpsertNftRewardBody {
    /**
     * 
     * @type {string}
     * @memberof UpsertNftRewardBody
     */
    nftTracker: string;
    /**
     * 
     * @type {string}
     * @memberof UpsertNftRewardBody
     */
    contractAbi: string;
    /**
     * 
     * @type {string}
     * @memberof UpsertNftRewardBody
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof UpsertNftRewardBody
     */
    origin?: string;
    /**
     * 
     * @type {string}
     * @memberof UpsertNftRewardBody
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof UpsertNftRewardBody
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpsertNftRewardBody
     */
    isEnabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpsertNftRewardBody
     */
    redemptionAnimation?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpsertNftRewardBody
     */
    transitionEffectEnabled?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpsertNftRewardBody
     */
    prizes: Array<string>;
}

export function UpsertNftRewardBodyFromJSON(json: any): UpsertNftRewardBody {
    return UpsertNftRewardBodyFromJSONTyped(json, false);
}

export function UpsertNftRewardBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpsertNftRewardBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nftTracker': json['nftTracker'],
        'contractAbi': json['contractAbi'],
        'image': !exists(json, 'image') ? undefined : json['image'],
        'origin': !exists(json, 'origin') ? undefined : json['origin'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'isEnabled': !exists(json, 'isEnabled') ? undefined : json['isEnabled'],
        'redemptionAnimation': !exists(json, 'redemptionAnimation') ? undefined : json['redemptionAnimation'],
        'transitionEffectEnabled': !exists(json, 'transitionEffectEnabled') ? undefined : json['transitionEffectEnabled'],
        'prizes': json['prizes'],
    };
}

export function UpsertNftRewardBodyToJSON(value?: UpsertNftRewardBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nftTracker': value.nftTracker,
        'contractAbi': value.contractAbi,
        'image': value.image,
        'origin': value.origin,
        'title': value.title,
        'description': value.description,
        'isEnabled': value.isEnabled,
        'redemptionAnimation': value.redemptionAnimation,
        'transitionEffectEnabled': value.transitionEffectEnabled,
        'prizes': value.prizes,
    };
}


