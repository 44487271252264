/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LevelUpEntry,
    LevelUpEntryFromJSON,
    LevelUpEntryFromJSONTyped,
    LevelUpEntryToJSON,
} from './';

/**
 * 
 * @export
 * @interface LevelUpTable
 */
export interface LevelUpTable extends Array<LevelUpEntry> {
}

export function LevelUpTableFromJSON(json: any): LevelUpTable {
    return LevelUpTableFromJSONTyped(json, false);
}

export function LevelUpTableFromJSONTyped(json: any, ignoreDiscriminator: boolean): LevelUpTable {
    return json;
}

export function LevelUpTableToJSON(value?: LevelUpTable | null): any {
    return value;
}


