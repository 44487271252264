/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ValidateDeliveryDistanceReason {
    TooFar = 'tooFar',
    DeliveryNotOffered = 'deliveryNotOffered',
    GoogleApiError = 'googleApiError'
}

export function ValidateDeliveryDistanceReasonFromJSON(json: any): ValidateDeliveryDistanceReason {
    return ValidateDeliveryDistanceReasonFromJSONTyped(json, false);
}

export function ValidateDeliveryDistanceReasonFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidateDeliveryDistanceReason {
    return json as ValidateDeliveryDistanceReason;
}

export function ValidateDeliveryDistanceReasonToJSON(value?: ValidateDeliveryDistanceReason | null): any {
    return value as any;
}

