import {InputHTMLAttributes, ReactElement} from "react";
import {omit} from "lodash";
import {isAndroidApp} from "@/utils/isGoNative";
import Toast from "@/components/Toast";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    isCopiedVisible: boolean;
    disabled: boolean;
}

function ReferralCopiedInput(props: Props): ReactElement {

    return (
        <>
            <div className="copied-input_input-container">
                <input
                    {...omit(props, "isCopiedVisible", "disabled")}
                    value={props.value || ""}
                    readOnly={true}
                />
            </div>
            <div
                className={isAndroidApp()
                    ? "copied-input_copy-tag-container-android"
                    : "copied-input_copy-tag-container"}>
                <Toast
                    duration={3000}
                    message={"Copied!"}
                    isOpen={props.isCopiedVisible}
                    showButton={false}
                />
            </div>
        </>
    );
}

export default ReferralCopiedInput;
