import {ButtonHTMLAttributes, ReactElement, useContext, useEffect, useState} from "react";
import {HandoffOptions} from "@devour/client";
import {FaChevronLeft, FaChevronRight} from "react-icons/fa6";
import moment from "moment-timezone";
import FrameOneModal from "@/components/modals/modalComponents/FrameOneModal";
import {useParams} from "react-router";
import {useMenuOrder} from "@/hooks/menuOrder/useMenuOrder";
import {createScheduleString} from "@/utils/CheckoutDetailsScheduleTime/createScheduleString";
import {isMobile, isTablet} from "react-device-detect";
import CheckoutDetailsScheduleTimes from "@/components/checkout/checkoutDetails/CheckoutDetailsScheduleTimes";
import FrameButton from "@/components/buttons/FrameButton";
import classNames from "classnames";
import {IoCheckmarkCircle} from "react-icons/io5";
import {useUpdateMenuOrderScheduleTime} from "@/hooks/menuOrder/useUpdateMenuOrderScheduleTime";
import {RestaurantContext} from "@/pages/restaurants/context/RestaurantContext";
import {useRestaurant} from "@/hooks/useRestaurant";

export enum ScheduleType {
    STANDARD = "STANDARD",
    SCHEDULE = "SCHEDULE",
}

export interface ScheduleData {
    schedule: ScheduleType;
    scheduledTime: string;
}

const defaultScheduleValues: ScheduleData = {
    schedule: ScheduleType.STANDARD,
    scheduledTime: null,
};

interface Props {
    isOpen: boolean;
    onClose: () => void;
}

function CheckoutDetailsScheduleTimeModal(props: Props): ReactElement {
    const { menuOrderId: contextMenuOrderId } = useContext(RestaurantContext);
    const { menuOrderId: paramMenuOrderId } = useParams<{ menuOrderId: string }>();
    const menuOrderId = paramMenuOrderId || contextMenuOrderId;

    const [
        scheduleData,
        setScheduleData,
    ] = useState<ScheduleData>(defaultScheduleValues);
    const {data: menuOrder} = useMenuOrder(menuOrderId);
    const {data: restaurant} = useRestaurant(menuOrder?.business);
    const {
        mutate: updateMenuOrderScheduleTime,
    } = useUpdateMenuOrderScheduleTime(menuOrderId);
    const [
        showScheduleTimesModal,
        setShowScheduleTimesModal,
    ] = useState<boolean>(false);
    const prepTimeRange = `${restaurant?.prepTime} - ${restaurant?.prepTime + 10} min`;
    const restaurantTimeZone = restaurant?.timeZone || moment.tz.guess();


    useEffect(() => {
        if (menuOrder) {
            if (menuOrder?.scheduledTime) {
                setScheduleData({
                    schedule: ScheduleType.SCHEDULE,
                    scheduledTime: createScheduleString(menuOrder.scheduledTime, restaurantTimeZone, true),
                });
            }
        }
    }, [menuOrder]);

    /**
     * Closes all scheduling time modals
     */
    function scheduleTimesOnClose(): void {
        setShowScheduleTimesModal(false);
        props.onClose();
    }

    /**
     * When the standard button is clicked, update the menu order's scheduled time,
     * close all modals, and set the schedule mode to "Standard"
     */
    function handleStandardClick(): void {
        // Close all modals
        props.onClose();

        updateMenuOrderScheduleTime(0);

        setScheduleData({
            schedule: ScheduleType.STANDARD,
            scheduledTime: null,
        });
    }

    /**
     * Render the schedule string to display
     */
    function renderScheduleString() {
        if (isMobile && !isTablet) {
            /*
             * String is in format "Mon, 9:00 - 9:30 PM"
             * Split the string into two parts, "Mon" and "9:00 - 9:30 PM"
             */
            const parts = scheduleData.scheduledTime.split(/,\s/);

            const day = `${parts[0]},`;
            const time = parts[1];

            // Insert line break if on mobile
            return <p>{day}<br/>{time}</p>;
        }
        return scheduleData.scheduledTime;

    }

    /**
     * Render the Schedule button
     */
    function renderScheduleButton() {
        return (
            <FrameButton
                <ButtonHTMLAttributes<HTMLButtonElement>>
                color="white-drop-shadow"
                size="large"
                className={classNames("checkout-details_schedule-modal_button", {
                    "checkout-details_schedule-modal_button_selected": scheduleData.schedule === ScheduleType.STANDARD,
                })}
                onClick={() => handleStandardClick()}
            >
                <div className="checkout-details_schedule-modal_button_left">
                    <p className="checkout-details_schedule-modal_button_left_titles">Standard</p>

                    <div className={classNames("checkout-details_schedule-modal_button_time", {
                        "checkout-details_schedule-modal_button_time-selected": scheduleData.schedule === ScheduleType.STANDARD,
                    })}>
                        {menuOrder && prepTimeRange}
                    </div>
                </div>

                {scheduleData.schedule === ScheduleType.STANDARD &&
                <IoCheckmarkCircle className="checkout-details_schedule-modal_button_checkmark"/>
                }
            </FrameButton>
        );
    }

    /**
     * Render the Standard button
     */
    function renderStandardButton() {
        return (
            <div className="checkout-details_schedule-modal_schedule-button">
                <FrameButton
                    <ButtonHTMLAttributes<HTMLButtonElement>>
                    color="white-drop-shadow"
                    size="large"
                    className={classNames("checkout-details_schedule-modal_button", {
                        "checkout-details_schedule-modal_button_selected": scheduleData.schedule === ScheduleType.SCHEDULE,
                    })}
                    onClick={() => setShowScheduleTimesModal(!showScheduleTimesModal)}
                >
                    <div className="checkout-details_schedule-modal_button_left">
                        <p className="checkout-details_schedule-modal_button_left_titles">Schedule</p>
                        <div
                            className={classNames("checkout-details_schedule-modal_button_time", {
                                "checkout-details_schedule-modal_button_time-selected": scheduleData.schedule === ScheduleType.SCHEDULE,
                            })}
                        >
                            {scheduleData.schedule === ScheduleType.STANDARD
                                ? "Select a time"
                                : renderScheduleString()}
                        </div>
                    </div>

                    {scheduleData.schedule === ScheduleType.SCHEDULE
                        ? <IoCheckmarkCircle
                            className="checkout-details_schedule-modal_button_checkmark"
                        />
                        : <FaChevronRight
                            className="checkout-details_schedule-modal_button_right"
                        />
                    }
                </FrameButton>

                {scheduleData.schedule === ScheduleType.SCHEDULE &&
                <FaChevronRight
                    className="checkout-details_schedule-modal_schedule-right"
                    onClick={() => setShowScheduleTimesModal(!showScheduleTimesModal)}
                />
                }
            </div>
        );
    }

    /**
     * Render the Modal's header with the line
     */
    function renderModalHeader() {
        return (
            <>
                <div className="checkout-details_schedule-modal_header">
                    <button
                        onClick={() => props.onClose()}
                        className="checkout-details_schedule-modal_times-back"
                    >
                        <FaChevronLeft className="checkout-details_schedule-modal_back"/>
                    </button>

                    <p>
                        Set {menuOrder.handoff === HandoffOptions.DELIVERY
                            ? "Delivery Time"
                            : "Pickup Time"}
                    </p>
                </div>

                <div className="checkout-details_schedule-modal_line-container">
                    <hr className="checkout-details_schedule-modal_line"/>
                </div>
            </>
        );
    }

    return (
        <FrameOneModal
            isOpen={props.isOpen}
            toggle={props.onClose}
            size="xs"
            contentClassName="checkout-details_schedule-modal"
        >

            {showScheduleTimesModal &&
            <CheckoutDetailsScheduleTimes
                isOpen={showScheduleTimesModal}
                onClose={scheduleTimesOnClose}
                mode={scheduleData.schedule}
            />
            }

            {renderModalHeader()}

            <div className="checkout-details_schedule-modal_buttons">
                {renderScheduleButton()}
                {renderStandardButton()}
            </div>
        </FrameOneModal>
    );
}

export default CheckoutDetailsScheduleTimeModal;
