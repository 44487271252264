/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
    TimeStamps,
    TimeStampsFromJSON,
    TimeStampsFromJSONTyped,
    TimeStampsToJSON,
    TransactionSubject,
    TransactionSubjectFromJSON,
    TransactionSubjectFromJSONTyped,
    TransactionSubjectToJSON,
    TransactionType,
    TransactionTypeFromJSON,
    TransactionTypeFromJSONTyped,
    TransactionTypeToJSON,
} from './';

/**
 * Represents a single transaction of dpay.
 * @export
 * @interface Transaction
 */
export interface Transaction {
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    createdAt: number;
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    updatedAt: number;
    /**
     * 
     * @type {TransactionSubject}
     * @memberof Transaction
     */
    subject: TransactionSubject;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    notes?: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    adminNotes?: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    user: string;
    /**
     * 
     * @type {TransactionType}
     * @memberof Transaction
     */
    type: TransactionType;
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    amount: number;
    /**
     * 
     * @type {object}
     * @memberof Transaction
     */
    reference?: object;
}

export function TransactionFromJSON(json: any): Transaction {
    return TransactionFromJSONTyped(json, false);
}

export function TransactionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Transaction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'subject': TransactionSubjectFromJSON(json['subject']),
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'adminNotes': !exists(json, 'adminNotes') ? undefined : json['adminNotes'],
        'user': json['user'],
        'type': TransactionTypeFromJSON(json['type']),
        'amount': json['amount'],
        'reference': !exists(json, 'reference') ? undefined : json['reference'],
    };
}

export function TransactionToJSON(value?: Transaction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'subject': TransactionSubjectToJSON(value.subject),
        'notes': value.notes,
        'adminNotes': value.adminNotes,
        'user': value.user,
        'type': TransactionTypeToJSON(value.type),
        'amount': value.amount,
        'reference': value.reference,
    };
}


