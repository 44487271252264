import {User} from "@devour/client";

export function getUserFullName(user: User): string {
    if (user) {
        if (user.firstName && user.lastName) {
            return capitalizeFirstLetter(user.firstName) + " " + capitalizeFirstLetter(user.lastName);
        } else if (user.firstName) {
            return capitalizeFirstLetter(user.firstName);
        } else if (user.lastName) {
            return capitalizeFirstLetter(user.lastName);
        }
    }

    return "User";
}

function capitalizeFirstLetter(name: string): string {
    return name.charAt(0).toUpperCase() + name.slice(1);
}
