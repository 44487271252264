import {ButtonHTMLAttributes, ReactElement, useState, MouseEvent} from "react";
import {IoClose} from "react-icons/io5";
import FrameButton from "@/components/buttons/FrameButton";
import GoFrensTutorialModal from "@/components/modals/GoFrensTutorialModal/GoFrensTutorialModal";
import GoFrensPromotionInviteCard from "@/components/goFrens/GoFrensPromotionInviteCard";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {updateIsGoFrensTutorialBannerDismissed} from "@/redux/meta/metaActions";

function GoFrensTutorial(): ReactElement {
    const isTutorialBannerDismissed = useSelector((store: IStore) => store.metaStore.isGoFrensTutorialBannerDismissed);
    const [
        showTutorialModal,
        setShowTutorialModal,
    ] = useState<boolean>(false);
    const dispatch = useDispatch();

    function handleBannerClick(e: MouseEvent<HTMLDivElement | HTMLButtonElement>) {
        e.stopPropagation();
        const currTarget = e.currentTarget as HTMLElement;
        if (currTarget.classList.contains("reset-button")) {
            dispatch(updateIsGoFrensTutorialBannerDismissed(true));
        } else {
            setShowTutorialModal(true);
        }
    }

    return (
        <>
            <GoFrensTutorialModal
                isOpen={showTutorialModal}
                toggle={() => setShowTutorialModal(!showTutorialModal)}
            />
            <div className="gofrens_tutorial">
                {!isTutorialBannerDismissed &&
                <div
					    className="gofrens_tutorial_banner"
					    style={{
					        backgroundImage: `url(${import.meta.env.VITE_CDN_URL + "/images/tutorial-rank.webp"})`,
                    }}
					    onClick={handleBannerClick}
                >
					    <div className="gofrens_tutorial_banner_text">
					        <h2>
								How does GoFriends work?
					        </h2>
					    </div>
					    <div className="gofrens_tutorial_banner_close">
					        <button
					            className="reset-button"
					            onClick={handleBannerClick}
					        >
					            <IoClose/>
					        </button>
					    </div>
                </div>
                }

                <div className="gofrens_tutorial_row">
                    <GoFrensPromotionInviteCard />
                    <div className="gofrens_tutorial_get-started">
                        <div className="gofrens_tutorial_get-started_text">
                            <h4>
								Getting started with GoFriends!
                            </h4>
                            <p>
								Get started with GoFriends by going though a simple 4-step checklist.
                            </p>
                        </div>
                        <div className="gofrens_tutorial_get-started_button">
                            <FrameButton
                                <ButtonHTMLAttributes<HTMLButtonElement>>
                                color="purple-outline"
                                size="narrow"
                                onClick={() => setShowTutorialModal(true)}
                            >
								Get Started
                            </FrameButton>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default GoFrensTutorial;
