/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum TransactionSubject {
    WITHDRAW = 'WITHDRAW',
    WITHDRAWCANCELLED = 'WITHDRAW_CANCELLED',
    WITHDRAWREJECTED = 'WITHDRAW_REJECTED',
    DEPOSIT = 'DEPOSIT',
    REWARD = 'REWARD',
    LEVELUPREWARD = 'LEVEL_UP_REWARD',
    REFUND = 'REFUND',
    COINBASEREFUND = 'COINBASE_REFUND',
    OTHER = 'OTHER',
    ADMINDEBIT = 'ADMIN_DEBIT',
    ADMINCREDIT = 'ADMIN_CREDIT',
    INDUSTRYCOLLECTIONOWNERSHIPREWARD = 'INDUSTRY_COLLECTION_OWNERSHIP_REWARD',
    MENUORDERPAYMENT = 'MENU_ORDER_PAYMENT',
    MENUORDERREWARD = 'MENU_ORDER_REWARD',
    MENUORDERINDUSTRYWALLETREWARD = 'MENU_ORDER_INDUSTRY_WALLET_REWARD',
    MENUORDERDPAYPAYMENTREWARD = 'MENU_ORDER_DPAY_PAYMENT_REWARD',
    MENUORDERREWARDCLAWBACK = 'MENU_ORDER_REWARD_CLAWBACK',
    LOOTBOXBURNED = 'LOOTBOX_BURNED',
    REWARDFORREFERRINGUSER = 'REWARD_FOR_REFERRING_USER',
    FIATPURCHASEDPAY = 'FIAT_PURCHASE_DPAY',
    MINTINDUSTRYPASSPURCHASE = 'MINT_INDUSTRY_PASS_PURCHASE',
    MINTINDUSTRYPASSREFUND = 'MINT_INDUSTRY_PASS_REFUND',
    ONCHAINREFUNDFORSTRIPEFAIL = 'ONCHAIN_REFUND_FOR_STRIPE_FAIL',
    NFTREDEEMDPAYREWARD = 'NFT_REDEEM_DPAY_REWARD'
}

export function TransactionSubjectFromJSON(json: any): TransactionSubject {
    return TransactionSubjectFromJSONTyped(json, false);
}

export function TransactionSubjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransactionSubject {
    return json as TransactionSubject;
}

export function TransactionSubjectToJSON(value?: TransactionSubject | null): any {
    return value as any;
}

