import {ReactElement} from "react";
import Spacer from "@/components/Spacer";
import {FiX} from "react-icons/fi";

interface Props {
    toggle: () => void;
}

/**
 * Used in designs such as in this one:
 * https://www.figma.com/file/FjtQC9gvdzpt34GbjkO5bb/Checkout-2.0?node-id=1%3A33797
 *
 */
function FrameModalNoHeaderToggle(props: Props): ReactElement {

    return (
        <div className="frame-one-modal_no-header-toggle">
            <Spacer />
            <FiX
                className="frame-one-modal_no-header-toggle_x"
                onClick={props.toggle}
            />
        </div>
    );
}

export default FrameModalNoHeaderToggle;
