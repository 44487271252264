/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum RelationshipType {
    REFERRAL = 'REFERRAL',
    AFFILIATE = 'AFFILIATE'
}

export function RelationshipTypeFromJSON(json: any): RelationshipType {
    return RelationshipTypeFromJSONTyped(json, false);
}

export function RelationshipTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): RelationshipType {
    return json as RelationshipType;
}

export function RelationshipTypeToJSON(value?: RelationshipType | null): any {
    return value as any;
}

