import {ReactElement, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import IndustryPassPromoModal from "@/components/modals/IndustryPassPromoModal";
import {useLocation} from "react-router";
import {useGate} from "statsig-react";

function IndustryPassPromoHandler(): ReactElement {

    // if the user selects "don't show again", it'll be dismissed forever
    const isDismissedDuringLogin = useSelector((store: IStore) => store.metaStore.isIndustryDismissed);

    // if they click the X it's just dismissed during their active session
    const isDismissedForSession = useSelector((store: IStore) => store.metaStore.isIndustryDismissedForSession);

    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const accountLevel = useSelector((store: IStore) => store.metaStore.accountLevel);
    const {value: dpayStatus} = useGate(import.meta.env.VITE_TOKEN_STATSIG_STATUS);

    const [
        showModal,
        setShowModal,
    ] = useState<boolean>(false);

    // only show the modal where it makes sense - GoVIP and restaurants search page
    const location = useLocation();
    const routes: Array<string> = [
        "restaurants/",
        "go-vip",
    ];

    function toggleModal(): void {
        setShowModal(false);
    }

    function isRouteIncluded(): boolean {
        const isIncluded = routes.filter((route) => location.pathname.includes(route));
        return isIncluded.length > 0;
    }

    function isModalActive() {
        if (!fullToken || !accountLevel) {
            return false;
        }
        if (accountLevel?.userOwnsIndustryCollectionNft) {
            return false;
        }
        return isRouteIncluded() && !isDismissedDuringLogin && !isDismissedForSession && dpayStatus;
    }

    useEffect(() => {
        if (isModalActive()) {
            setShowModal(true);
        }
    }, [
        location.pathname,
        accountLevel,
    ]);

    return (
        <IndustryPassPromoModal
            isOpen={showModal}
            toggle={toggleModal}
        />
    );
}

export default IndustryPassPromoHandler;
