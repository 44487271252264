import { FiChevronRight } from "react-icons/fi";
import Spacer from "../Spacer";
import { useGetUserLeaderboardRank } from "@/hooks/useGetUserLeaderboardRank";
import { useGetUserLevel } from "@/hooks/useGetUserLevel";
import { IStore } from "@/redux/defaultStore";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import GoVipLeaderboardsModal from "../modals/GoVipLeaderboardsModal";
import Toast from "../Toast";
import { useNavigate } from "react-router";
import { getRankSuffix } from "@/utils/getRankSuffix";
import { toggleLoginOpen } from "@/redux/meta/metaActions";
import SignUpToast from "../SignUpToast";

function GoVipRankButton() {

    const [
        showLeaderboardsModal,
        setShowLeaderboardsModal,
    ] = useState<boolean>(false);
    const history = useNavigate();
    const [
        showToast,
        setShowToast,
    ] = useState<boolean>(false);
    const currentUser = useSelector((store: IStore) => store.metaStore.currentUser);
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const {data: userLeaderboardRankData, refetch: refetchUserLeaderboardRank} = useGetUserLeaderboardRank(fullToken);
    const {data: userLevelData} = useGetUserLevel(fullToken, currentUser?.user?.id);
    const dispatch = useDispatch();
    const leaderboardRank: number = userLeaderboardRankData?.rank;
    const shouldShowUserRank: boolean = userLevelData?.level > 1;

    const rank = useMemo(() => {
        let rank = 1;
        if (!shouldShowUserRank) {
            return "Unranked";
        } if (leaderboardRank > 0) {
            rank = leaderboardRank;
        }
        return `Rank ${rank}${getRankSuffix(rank)}`;

    }, [userLeaderboardRankData, fullToken]);

    useEffect(() => {
        if (fullToken) {
            refetchUserLeaderboardRank();
        }
    }, [userLevelData?.totalExperience]);

    function handleToastDismissal() {
        setShowToast(false);
    }

    function toggleLeaderboardsModal() {
        if (fullToken) {
            setShowLeaderboardsModal(!showLeaderboardsModal);
        } else {
            setShowToast(true);
        }
    }

    return (
        <>
            <SignUpToast
                isOpen={showToast}
                onDismiss={handleToastDismissal}
            />

            {fullToken &&
                <>
                    {leaderboardRank &&
                        <GoVipLeaderboardsModal
                            leaderboardRank={leaderboardRank}
                            isOpen={showLeaderboardsModal}
                            onClose={toggleLeaderboardsModal}
                            shouldShowUserRank={shouldShowUserRank}
                        />
                    }
                </>
            }

            <div className="go-vip-top_header_rank-button" onClick={toggleLeaderboardsModal}>
                <div className="go-vip-top_header_rank-button_row">
                    <p>{rank}</p>
                    <FiChevronRight className="go-vip-top_header_global-rank-card_bottom-row_chevron"/>
                </div>
            </div>
        </>
    );
}

export default GoVipRankButton;
