/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BrandMapPromoHeader
 */
export interface BrandMapPromoHeader {
    /**
     * 
     * @type {string}
     * @memberof BrandMapPromoHeader
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandMapPromoHeader
     */
    description?: string;
}

export function BrandMapPromoHeaderFromJSON(json: any): BrandMapPromoHeader {
    return BrandMapPromoHeaderFromJSONTyped(json, false);
}

export function BrandMapPromoHeaderFromJSONTyped(json: any, ignoreDiscriminator: boolean): BrandMapPromoHeader {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': !exists(json, 'title') ? undefined : json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function BrandMapPromoHeaderToJSON(value?: BrandMapPromoHeader | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'description': value.description,
    };
}


