/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BulkReimportMenuBody
 */
export interface BulkReimportMenuBody {
    /**
     * 
     * @type {Array<string>}
     * @memberof BulkReimportMenuBody
     */
    businessIds?: Array<string>;
}

export function BulkReimportMenuBodyFromJSON(json: any): BulkReimportMenuBody {
    return BulkReimportMenuBodyFromJSONTyped(json, false);
}

export function BulkReimportMenuBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): BulkReimportMenuBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'businessIds': !exists(json, 'businessIds') ? undefined : json['businessIds'],
    };
}

export function BulkReimportMenuBodyToJSON(value?: BulkReimportMenuBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'businessIds': value.businessIds,
    };
}


