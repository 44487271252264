/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiError,
    ApiErrorFromJSON,
    ApiErrorToJSON,
    GleamCompetitionActionsResponse,
    GleamCompetitionActionsResponseFromJSON,
    GleamCompetitionActionsResponseToJSON,
    GleamWebhookPayload,
    GleamWebhookPayloadFromJSON,
    GleamWebhookPayloadToJSON,
} from '../models';

export interface GleamCompetitionActionsRequest {
    id: string;
}

export interface GleamWebhookRequest {
    gleamWebhookPayload?: GleamWebhookPayload;
}

/**
 * GleamApi - interface
 * @export
 * @interface GleamApiInterface
 */
export interface GleamApiInterface {
    /**
     * Returns Gleam competition actions available to the user.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GleamApiInterface
     */
    gleamCompetitionActionsRaw(requestParameters: GleamCompetitionActionsRequest): Promise<runtime.ApiResponse<GleamCompetitionActionsResponse>>;

    /**
     * Returns Gleam competition actions available to the user.
     */
    gleamCompetitionActions(requestParameters: GleamCompetitionActionsRequest): Promise<GleamCompetitionActionsResponse>;

    /**
     * Used as a universal webhook for getting details back from gleam
     * @param {GleamWebhookPayload} [gleamWebhookPayload] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GleamApiInterface
     */
    gleamWebhookRaw(requestParameters: GleamWebhookRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Used as a universal webhook for getting details back from gleam
     */
    gleamWebhook(requestParameters: GleamWebhookRequest): Promise<void>;

}

/**
 * no description
 */
export class GleamApi extends runtime.BaseAPI implements GleamApiInterface {

    /**
     * Returns Gleam competition actions available to the user.
     */
    async gleamCompetitionActionsRaw(requestParameters: GleamCompetitionActionsRequest): Promise<runtime.ApiResponse<GleamCompetitionActionsResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling gleamCompetitionActions.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/gleam/competition-actions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GleamCompetitionActionsResponseFromJSON(jsonValue));
    }

    /**
     * Returns Gleam competition actions available to the user.
     */
    async gleamCompetitionActions(requestParameters: GleamCompetitionActionsRequest): Promise<GleamCompetitionActionsResponse> {
        const response = await this.gleamCompetitionActionsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used as a universal webhook for getting details back from gleam
     */
    async gleamWebhookRaw(requestParameters: GleamWebhookRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/gleam/webhook`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GleamWebhookPayloadToJSON(requestParameters.gleamWebhookPayload),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Used as a universal webhook for getting details back from gleam
     */
    async gleamWebhook(requestParameters: GleamWebhookRequest): Promise<void> {
        await this.gleamWebhookRaw(requestParameters);
    }

}
