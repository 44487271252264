import {GetNftOwnershipsResponse, Token, NftsApi} from "@devour/client";
import getConfig from "@/utils/getConfig";
import {useQuery} from "@tanstack/react-query";

async function fetchNftOwnershipsForUser(fullToken): Promise<GetNftOwnershipsResponse> {
    return new NftsApi(getConfig(fullToken)).getNftOwnerships();
}

function nftOwnershipsQuery(fullToken: Token) {
    return {
        queryKey: [
            "get-nft-ownerships",
            fullToken?.id,
        ],
        queryFn: () => fullToken ? fetchNftOwnershipsForUser(fullToken) : null,
        staleTime: 60000,
        enabled: !!fullToken,
    } as const;
}

export function useGetNftOwnerships(fullToken) {
    return useQuery(nftOwnershipsQuery(fullToken));
}
