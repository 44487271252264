/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BrandMapAdvertisementBlocksBody
 */
export interface BrandMapAdvertisementBlocksBody {
    /**
     * 
     * @type {string}
     * @memberof BrandMapAdvertisementBlocksBody
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandMapAdvertisementBlocksBody
     */
    url?: string;
}

export function BrandMapAdvertisementBlocksBodyFromJSON(json: any): BrandMapAdvertisementBlocksBody {
    return BrandMapAdvertisementBlocksBodyFromJSONTyped(json, false);
}

export function BrandMapAdvertisementBlocksBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): BrandMapAdvertisementBlocksBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'image': !exists(json, 'image') ? undefined : json['image'],
        'url': !exists(json, 'url') ? undefined : json['url'],
    };
}

export function BrandMapAdvertisementBlocksBodyToJSON(value?: BrandMapAdvertisementBlocksBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'image': value.image,
        'url': value.url,
    };
}


