/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GleamUser
 */
export interface GleamUser {
    /**
     * 
     * @type {string}
     * @memberof GleamUser
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GleamUser
     */
    first_name: string;
    /**
     * 
     * @type {string}
     * @memberof GleamUser
     */
    last_name: string;
    /**
     * 
     * @type {string}
     * @memberof GleamUser
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof GleamUser
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof GleamUser
     */
    region: string;
    /**
     * 
     * @type {number}
     * @memberof GleamUser
     */
    city: number;
    /**
     * 
     * @type {string}
     * @memberof GleamUser
     */
    country_code: string;
    /**
     * 
     * @type {number}
     * @memberof GleamUser
     */
    actions_completed?: number;
    /**
     * 
     * @type {object}
     * @memberof GleamUser
     */
    details: object;
}

export function GleamUserFromJSON(json: any): GleamUser {
    return GleamUserFromJSONTyped(json, false);
}

export function GleamUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): GleamUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'first_name': json['first_name'],
        'last_name': json['last_name'],
        'email': json['email'],
        'country': json['country'],
        'region': json['region'],
        'city': json['city'],
        'country_code': json['country_code'],
        'actions_completed': !exists(json, 'actions_completed') ? undefined : json['actions_completed'],
        'details': json['details'],
    };
}

export function GleamUserToJSON(value?: GleamUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'first_name': value.first_name,
        'last_name': value.last_name,
        'email': value.email,
        'country': value.country,
        'region': value.region,
        'city': value.city,
        'country_code': value.country_code,
        'actions_completed': value.actions_completed,
        'details': value.details,
    };
}


