import React, { FC } from "react";
import { OnboardingModalSteps } from "./OnboardingModalSteps";
import FrameButton from "@/components/buttons/FrameButton";
import classNames from "classnames";
import { useSwipeable } from "react-swipeable";
import { isMobile } from "react-device-detect";

interface Props {
    currentStep: OnboardingModalSteps;
    onStepChange: (step: OnboardingModalSteps) => void;
    onNextPressed: () => void;
}

const OnboardingStepper: FC<Props> = ({ currentStep, onStepChange, onNextPressed }) => {
    const steps = [
        OnboardingModalSteps.RESTAURANTS_STEP,
        OnboardingModalSteps.PLAY_AND_CONNECT_STEP,
        OnboardingModalSteps.DPAY_STEP,
        OnboardingModalSteps.DEALS_STEP,
    ];

    return (
        <div className="onboarding-modal_stepper">
            <div className="onboarding-modal_stepper_steps">
                {steps.map((step, index) =>
                    <div
                        key={step}
                        className={classNames("onboarding-modal_stepper_steps_step", {
                            active: currentStep === step,
                        })}
                        onClick={() => onStepChange(step)}
                    >
                        <div className="onboarding-modal_stepper_steps_step_circle"></div>
                    </div>)}
            </div>
            {!isMobile && <FrameButton onClick={onNextPressed} size="pill" className="next-button" color="purple-gradient-dark">
                Next
            </FrameButton>}
        </div>
    );
};

export default OnboardingStepper;
