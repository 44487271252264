import {ReactElement} from "react";
import {NftOwnershipInformation, OrderDiscount} from "@devour/client";
import {useGetUnauthenticatedNftGroupings} from "@/hooks/useGetUnauthenticatedNftGroupings";

interface Props {
    promo: OrderDiscount;
    nftOwnershipResponse: NftOwnershipInformation;
    className: string;
}

function GlobalPromoCard(props: Props): ReactElement {
    const {data: allNftGroupings} = useGetUnauthenticatedNftGroupings(false);
    const userOwnerships = props.nftOwnershipResponse?.nftOwnerships[0];
    const promoGroupingIdsSet = new Set(props.promo.nftGroupings);

    const nftGrouping = allNftGroupings?.nftGroupings.find(grouping => promoGroupingIdsSet.has(grouping.id) &&
        userOwnerships?.some(ownership => ownership.nftGroupingId === grouping.id));

    if (!props.promo || !nftGrouping) {
        return null;
    }

    return (
        <div className={props.className}>
            <div className="global-promo-card">
                <div className="global-promo-card_image">
                    <img
                        src={nftGrouping.hero?.url || import.meta.env.VITE_CDN_URL + "/images/placeholderitem.webp"}
                        alt={nftGrouping?.name}
                    />
                </div>
                <div className="global-promo-card_row-between">
                    <div
                        className="global-promo-card_name"
                        title={props.promo.label}
                    >
                        {props.promo.label}
                    </div>
                </div>
                <div className="global-promo-card_details">
					Valid at any DevourGO Location
                </div>
            </div>
        </div>

    );
}

export default GlobalPromoCard;
