import {UserRankData} from "@devour/client";
import Spacer from "../Spacer";
import {formatNumberWithCommas} from "../../utils/formatNumberWithCommas";
import classNames from "classnames";
import {isDesktop, isMobile, isTablet} from "react-device-detect";
import {truncateText} from "../../utils/truncateText";

interface Props {
    user: UserRankData
    isLast: boolean;
}

function GoVipLeaderboardRow(props: Props) {

    return (
        <div
            className={classNames("go-vip-leaderboards_top-section_body_ranked-row", {
                "go-vip-leaderboards_top-section_body_ranked-row_border": !props.isLast,
            })}
        >
            <div className="go-vip-leaderboards_top-section_body_ranked-row_rank">
                <p>{props.user.rank}</p>
            </div>
            <img
                className="go-vip-leaderboards_top-section_body_ranked-row_pfp"
                src={props.user.pfpUrl}
                alt="User Profile"
            />
            <p className="go-vip-leaderboards_top-section_body_ranked-row_nickname">
                {isMobile && !isTablet
                    ? truncateText(20, props.user.nickname)
                    : isDesktop
                        ? truncateText(30, props.user.nickname)
                        : props.user.nickname}
            </p>
            <Spacer/>
            <div>
                <p className="go-vip-leaderboards_top-section_body_ranked-row_points">
                    {`${formatNumberWithCommas(props.user.experiencePoints)} pts`}
                </p>
                <p className="go-vip-leaderboards_top-section_user-row_tag
                            go-vip-leaderboards_top-section_user-row_levels"
                >
                    {`Level ${props.user.level}`}
                </p>
            </div>

        </div>
    );
}

export default GoVipLeaderboardRow;
