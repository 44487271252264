import {ReactElement} from "react";
import classNames from "classnames";

interface Props {
    className: string;
}
function GoFrensTutorialModalRackExp(props: Props): ReactElement {

    return (
        <div className={classNames("gofrens-tutorial-modal-rack-exp", props.className)}>
            <div>
                <p className="gofrens-tutorial-modal-rack-exp_step">
					Step 3: Achieve ultimate goal
                </p>
                <div className="gofrens-tutorial-modal-rack-exp_title">
                    <img
                        className="gofrens-tutorial-modal-rack-exp_title_icon"
                        src={import.meta.env.VITE_CDN_URL + "/images/tutorial-step3-trophy.webp"}
                        alt="Rack Up EXP"
                    />
                    <span>
					Rack Up EXP
                    </span>
                </div>
                <div className="gofrens-tutorial-modal-rack-exp_description">
					Dominate leaderboards to open prizes. Psst, here's the secret: earn more EXP by diving into GoVIP games!
                </div>
            </div>
            <div className="gofrens-tutorial-modal-rack-exp_image">
                <img
                    src={import.meta.env.VITE_CDN_URL + "/images/tutorial-step3.webp"}
                    alt="Leaderboards"
                />
            </div>
        </div>
    );
}

export default GoFrensTutorialModalRackExp;
