/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Address,
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
    DeliveryHandoffInstructions,
    DeliveryHandoffInstructionsFromJSON,
    DeliveryHandoffInstructionsFromJSONTyped,
    DeliveryHandoffInstructionsToJSON,
    HandoffOptions,
    HandoffOptionsFromJSON,
    HandoffOptionsFromJSONTyped,
    HandoffOptionsToJSON,
    MenuOrderDiscount,
    MenuOrderDiscountFromJSON,
    MenuOrderDiscountFromJSONTyped,
    MenuOrderDiscountToJSON,
    MenuOrderItem,
    MenuOrderItemFromJSON,
    MenuOrderItemFromJSONTyped,
    MenuOrderItemToJSON,
    MenuOrderLog,
    MenuOrderLogFromJSON,
    MenuOrderLogFromJSONTyped,
    MenuOrderLogToJSON,
    MenuOrderServiceFee,
    MenuOrderServiceFeeFromJSON,
    MenuOrderServiceFeeFromJSONTyped,
    MenuOrderServiceFeeToJSON,
    MenuOrderStatus,
    MenuOrderStatusFromJSON,
    MenuOrderStatusFromJSONTyped,
    MenuOrderStatusToJSON,
    OloError,
    OloErrorFromJSON,
    OloErrorFromJSONTyped,
    OloErrorToJSON,
    OrderRefund,
    OrderRefundFromJSON,
    OrderRefundFromJSONTyped,
    OrderRefundToJSON,
    PhoneNumber,
    PhoneNumberFromJSON,
    PhoneNumberFromJSONTyped,
    PhoneNumberToJSON,
    TimeStamps,
    TimeStampsFromJSON,
    TimeStampsFromJSONTyped,
    TimeStampsToJSON,
} from './';

/**
 * Represents a single menu order in the system.
 * @export
 * @interface MenuOrder
 */
export interface MenuOrder {
    /**
     * 
     * @type {string}
     * @memberof MenuOrder
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof MenuOrder
     */
    createdAt: number;
    /**
     * 
     * @type {number}
     * @memberof MenuOrder
     */
    updatedAt: number;
    /**
     * 
     * @type {string}
     * @memberof MenuOrder
     */
    currency: string;
    /**
     * 
     * @type {string}
     * @memberof MenuOrder
     */
    customer: string;
    /**
     * 
     * @type {string}
     * @memberof MenuOrder
     */
    business: string;
    /**
     * 
     * @type {number}
     * @memberof MenuOrder
     */
    subtotal: number;
    /**
     * 
     * @type {number}
     * @memberof MenuOrder
     */
    stripeTotal: number;
    /**
     * 
     * @type {number}
     * @memberof MenuOrder
     */
    stripeFee?: number;
    /**
     * 
     * @type {number}
     * @memberof MenuOrder
     */
    marketplaceWithholding?: number;
    /**
     * 
     * @type {number}
     * @memberof MenuOrder
     */
    tax: number;
    /**
     * 
     * @type {number}
     * @memberof MenuOrder
     */
    deliveryCharge: number;
    /**
     * 
     * @type {number}
     * @memberof MenuOrder
     */
    tipRestaurant?: number;
    /**
     * 
     * @type {number}
     * @memberof MenuOrder
     */
    tipDelivery?: number;
    /**
     * 
     * @type {Array<MenuOrderDiscount>}
     * @memberof MenuOrder
     */
    discounts?: Array<MenuOrderDiscount>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MenuOrder
     */
    discountCodes?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof MenuOrder
     */
    paymentMethodId?: string;
    /**
     * 
     * @type {string}
     * @memberof MenuOrder
     */
    paymentIntentId?: string;
    /**
     * 
     * @type {string}
     * @memberof MenuOrder
     */
    paymentTransferId?: string;
    /**
     * 
     * @type {number}
     * @memberof MenuOrder
     */
    dpay: number;
    /**
     * 
     * @type {number}
     * @memberof MenuOrder
     */
    vdpay?: number;
    /**
     * 
     * @type {number}
     * @memberof MenuOrder
     */
    onChainDpay?: number;
    /**
     * 
     * @type {boolean}
     * @memberof MenuOrder
     */
    isMagicWallet?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MenuOrder
     */
    transactionHash?: string;
    /**
     * 
     * @type {string}
     * @memberof MenuOrder
     */
    dpayTransaction?: string;
    /**
     * 
     * @type {number}
     * @memberof MenuOrder
     */
    dpayRewardsIndustry: number;
    /**
     * 
     * @type {string}
     * @memberof MenuOrder
     */
    dpayRewardsIndustryTransaction?: string;
    /**
     * 
     * @type {number}
     * @memberof MenuOrder
     */
    dpayRewardsPayment?: number;
    /**
     * 
     * @type {string}
     * @memberof MenuOrder
     */
    dpayRewardsPaymentTransaction?: string;
    /**
     * 
     * @type {number}
     * @memberof MenuOrder
     */
    dpayFiatAtOrderTime?: number;
    /**
     * 
     * @type {number}
     * @memberof MenuOrder
     */
    experienceRewards?: number;
    /**
     * 
     * @type {string}
     * @memberof MenuOrder
     */
    experienceRewardsTransaction?: string;
    /**
     * 
     * @type {MenuOrderStatus}
     * @memberof MenuOrder
     */
    status?: MenuOrderStatus;
    /**
     * 
     * @type {Array<MenuOrderLog>}
     * @memberof MenuOrder
     */
    log?: Array<MenuOrderLog>;
    /**
     * 
     * @type {Address}
     * @memberof MenuOrder
     */
    address: Address;
    /**
     * 
     * @type {number}
     * @memberof MenuOrder
     */
    distance?: number;
    /**
     * 
     * @type {string}
     * @memberof MenuOrder
     */
    restaurantNotes?: string;
    /**
     * 
     * @type {string}
     * @memberof MenuOrder
     */
    deliveryNotes?: string;
    /**
     * 
     * @type {DeliveryHandoffInstructions}
     * @memberof MenuOrder
     */
    deliveryHandoffInstructions?: DeliveryHandoffInstructions;
    /**
     * 
     * @type {number}
     * @memberof MenuOrder
     */
    scheduledTime?: number;
    /**
     * 
     * @type {number}
     * @memberof MenuOrder
     */
    readyBy?: number;
    /**
     * 
     * @type {number}
     * @memberof MenuOrder
     */
    marketplacePercentage?: number;
    /**
     * 
     * @type {number}
     * @memberof MenuOrder
     */
    marketplaceFee?: number;
    /**
     * 
     * @type {Array<MenuOrderServiceFee>}
     * @memberof MenuOrder
     */
    serviceFees?: Array<MenuOrderServiceFee>;
    /**
     * 
     * @type {number}
     * @memberof MenuOrder
     */
    merchantOwed?: number;
    /**
     * 
     * @type {number}
     * @memberof MenuOrder
     */
    grandTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof MenuOrder
     */
    transferAmount?: number;
    /**
     * 
     * @type {HandoffOptions}
     * @memberof MenuOrder
     */
    handoff?: HandoffOptions;
    /**
     * 
     * @type {string}
     * @memberof MenuOrder
     */
    pickupCode?: string;
    /**
     * 
     * @type {Array<MenuOrderItem>}
     * @memberof MenuOrder
     */
    orderItems: Array<MenuOrderItem>;
    /**
     * 
     * @type {string}
     * @memberof MenuOrder
     */
    email?: string;
    /**
     * 
     * @type {PhoneNumber}
     * @memberof MenuOrder
     */
    phoneNumber?: PhoneNumber;
    /**
     * 
     * @type {string}
     * @memberof MenuOrder
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof MenuOrder
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof MenuOrder
     */
    errorMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof MenuOrder
     */
    cancellationReason?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MenuOrder
     */
    stripeCheckout?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MenuOrder
     */
    paidToDevour?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MenuOrder
     */
    paymentIntent?: string;
    /**
     * 
     * @type {string}
     * @memberof MenuOrder
     */
    chowlyId?: string;
    /**
     * 
     * @type {string}
     * @memberof MenuOrder
     */
    deliveryId?: string;
    /**
     * 
     * @type {string}
     * @memberof MenuOrder
     */
    dspId?: string;
    /**
     * 
     * @type {string}
     * @memberof MenuOrder
     */
    discountSelected?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MenuOrder
     */
    discountNullify?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MenuOrder
     */
    callbackComplete?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MenuOrder
     */
    coinbaseChargeId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MenuOrder
     */
    isCoinbase?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MenuOrder
     */
    coinbaseChargeData?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MenuOrder
     */
    exceededSlippage?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MenuOrder
     */
    validationErrorMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof MenuOrder
     */
    devourIqNotification?: string;
    /**
     * 
     * @type {Array<OrderRefund>}
     * @memberof MenuOrder
     */
    refunds?: Array<OrderRefund>;
    /**
     * 
     * @type {string}
     * @memberof MenuOrder
     */
    uberEatsUuid?: string;
    /**
     * 
     * @type {string}
     * @memberof MenuOrder
     */
    orderUuid?: string;
    /**
     * 
     * @type {string}
     * @memberof MenuOrder
     */
    oloBasketId?: string;
    /**
     * 
     * @type {string}
     * @memberof MenuOrder
     */
    oloOrderGuid?: string;
    /**
     * 
     * @type {Array<OloError>}
     * @memberof MenuOrder
     */
    oloErrors?: Array<OloError>;
}

export function MenuOrderFromJSON(json: any): MenuOrder {
    return MenuOrderFromJSONTyped(json, false);
}

export function MenuOrderFromJSONTyped(json: any, ignoreDiscriminator: boolean): MenuOrder {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'currency': json['currency'],
        'customer': json['customer'],
        'business': json['business'],
        'subtotal': json['subtotal'],
        'stripeTotal': json['stripeTotal'],
        'stripeFee': !exists(json, 'stripeFee') ? undefined : json['stripeFee'],
        'marketplaceWithholding': !exists(json, 'marketplaceWithholding') ? undefined : json['marketplaceWithholding'],
        'tax': json['tax'],
        'deliveryCharge': json['deliveryCharge'],
        'tipRestaurant': !exists(json, 'tipRestaurant') ? undefined : json['tipRestaurant'],
        'tipDelivery': !exists(json, 'tipDelivery') ? undefined : json['tipDelivery'],
        'discounts': !exists(json, 'discounts') ? undefined : ((json['discounts'] as Array<any>).map(MenuOrderDiscountFromJSON)),
        'discountCodes': !exists(json, 'discountCodes') ? undefined : json['discountCodes'],
        'paymentMethodId': !exists(json, 'paymentMethodId') ? undefined : json['paymentMethodId'],
        'paymentIntentId': !exists(json, 'paymentIntentId') ? undefined : json['paymentIntentId'],
        'paymentTransferId': !exists(json, 'paymentTransferId') ? undefined : json['paymentTransferId'],
        'dpay': json['dpay'],
        'vdpay': !exists(json, 'vdpay') ? undefined : json['vdpay'],
        'onChainDpay': !exists(json, 'onChainDpay') ? undefined : json['onChainDpay'],
        'isMagicWallet': !exists(json, 'isMagicWallet') ? undefined : json['isMagicWallet'],
        'transactionHash': !exists(json, 'transactionHash') ? undefined : json['transactionHash'],
        'dpayTransaction': !exists(json, 'dpayTransaction') ? undefined : json['dpayTransaction'],
        'dpayRewardsIndustry': json['dpayRewardsIndustry'],
        'dpayRewardsIndustryTransaction': !exists(json, 'dpayRewardsIndustryTransaction') ? undefined : json['dpayRewardsIndustryTransaction'],
        'dpayRewardsPayment': !exists(json, 'dpayRewardsPayment') ? undefined : json['dpayRewardsPayment'],
        'dpayRewardsPaymentTransaction': !exists(json, 'dpayRewardsPaymentTransaction') ? undefined : json['dpayRewardsPaymentTransaction'],
        'dpayFiatAtOrderTime': !exists(json, 'dpayFiatAtOrderTime') ? undefined : json['dpayFiatAtOrderTime'],
        'experienceRewards': !exists(json, 'experienceRewards') ? undefined : json['experienceRewards'],
        'experienceRewardsTransaction': !exists(json, 'experienceRewardsTransaction') ? undefined : json['experienceRewardsTransaction'],
        'status': !exists(json, 'status') ? undefined : MenuOrderStatusFromJSON(json['status']),
        'log': !exists(json, 'log') ? undefined : ((json['log'] as Array<any>).map(MenuOrderLogFromJSON)),
        'address': AddressFromJSON(json['address']),
        'distance': !exists(json, 'distance') ? undefined : json['distance'],
        'restaurantNotes': !exists(json, 'restaurantNotes') ? undefined : json['restaurantNotes'],
        'deliveryNotes': !exists(json, 'deliveryNotes') ? undefined : json['deliveryNotes'],
        'deliveryHandoffInstructions': !exists(json, 'deliveryHandoffInstructions') ? undefined : DeliveryHandoffInstructionsFromJSON(json['deliveryHandoffInstructions']),
        'scheduledTime': !exists(json, 'scheduledTime') ? undefined : json['scheduledTime'],
        'readyBy': !exists(json, 'readyBy') ? undefined : json['readyBy'],
        'marketplacePercentage': !exists(json, 'marketplacePercentage') ? undefined : json['marketplacePercentage'],
        'marketplaceFee': !exists(json, 'marketplaceFee') ? undefined : json['marketplaceFee'],
        'serviceFees': !exists(json, 'serviceFees') ? undefined : ((json['serviceFees'] as Array<any>).map(MenuOrderServiceFeeFromJSON)),
        'merchantOwed': !exists(json, 'merchantOwed') ? undefined : json['merchantOwed'],
        'grandTotal': !exists(json, 'grandTotal') ? undefined : json['grandTotal'],
        'transferAmount': !exists(json, 'transferAmount') ? undefined : json['transferAmount'],
        'handoff': !exists(json, 'handoff') ? undefined : HandoffOptionsFromJSON(json['handoff']),
        'pickupCode': !exists(json, 'pickupCode') ? undefined : json['pickupCode'],
        'orderItems': ((json['orderItems'] as Array<any>).map(MenuOrderItemFromJSON)),
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : PhoneNumberFromJSON(json['phoneNumber']),
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'errorMessage': !exists(json, 'errorMessage') ? undefined : json['errorMessage'],
        'cancellationReason': !exists(json, 'cancellationReason') ? undefined : json['cancellationReason'],
        'stripeCheckout': !exists(json, 'stripeCheckout') ? undefined : json['stripeCheckout'],
        'paidToDevour': !exists(json, 'paidToDevour') ? undefined : json['paidToDevour'],
        'paymentIntent': !exists(json, 'paymentIntent') ? undefined : json['paymentIntent'],
        'chowlyId': !exists(json, 'chowlyId') ? undefined : json['chowlyId'],
        'deliveryId': !exists(json, 'deliveryId') ? undefined : json['deliveryId'],
        'dspId': !exists(json, 'dspId') ? undefined : json['dspId'],
        'discountSelected': !exists(json, 'discountSelected') ? undefined : json['discountSelected'],
        'discountNullify': !exists(json, 'discountNullify') ? undefined : json['discountNullify'],
        'callbackComplete': !exists(json, 'callbackComplete') ? undefined : json['callbackComplete'],
        'coinbaseChargeId': !exists(json, 'coinbaseChargeId') ? undefined : json['coinbaseChargeId'],
        'isCoinbase': !exists(json, 'isCoinbase') ? undefined : json['isCoinbase'],
        'coinbaseChargeData': !exists(json, 'coinbaseChargeData') ? undefined : json['coinbaseChargeData'],
        'exceededSlippage': !exists(json, 'exceededSlippage') ? undefined : json['exceededSlippage'],
        'validationErrorMessage': !exists(json, 'validationErrorMessage') ? undefined : json['validationErrorMessage'],
        'devourIqNotification': !exists(json, 'devourIqNotification') ? undefined : json['devourIqNotification'],
        'refunds': !exists(json, 'refunds') ? undefined : ((json['refunds'] as Array<any>).map(OrderRefundFromJSON)),
        'uberEatsUuid': !exists(json, 'uberEatsUuid') ? undefined : json['uberEatsUuid'],
        'orderUuid': !exists(json, 'orderUuid') ? undefined : json['orderUuid'],
        'oloBasketId': !exists(json, 'oloBasketId') ? undefined : json['oloBasketId'],
        'oloOrderGuid': !exists(json, 'oloOrderGuid') ? undefined : json['oloOrderGuid'],
        'oloErrors': !exists(json, 'oloErrors') ? undefined : ((json['oloErrors'] as Array<any>).map(OloErrorFromJSON)),
    };
}

export function MenuOrderToJSON(value?: MenuOrder | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'currency': value.currency,
        'customer': value.customer,
        'business': value.business,
        'subtotal': value.subtotal,
        'stripeTotal': value.stripeTotal,
        'stripeFee': value.stripeFee,
        'marketplaceWithholding': value.marketplaceWithholding,
        'tax': value.tax,
        'deliveryCharge': value.deliveryCharge,
        'tipRestaurant': value.tipRestaurant,
        'tipDelivery': value.tipDelivery,
        'discounts': value.discounts === undefined ? undefined : ((value.discounts as Array<any>).map(MenuOrderDiscountToJSON)),
        'discountCodes': value.discountCodes,
        'paymentMethodId': value.paymentMethodId,
        'paymentIntentId': value.paymentIntentId,
        'paymentTransferId': value.paymentTransferId,
        'dpay': value.dpay,
        'vdpay': value.vdpay,
        'onChainDpay': value.onChainDpay,
        'isMagicWallet': value.isMagicWallet,
        'transactionHash': value.transactionHash,
        'dpayTransaction': value.dpayTransaction,
        'dpayRewardsIndustry': value.dpayRewardsIndustry,
        'dpayRewardsIndustryTransaction': value.dpayRewardsIndustryTransaction,
        'dpayRewardsPayment': value.dpayRewardsPayment,
        'dpayRewardsPaymentTransaction': value.dpayRewardsPaymentTransaction,
        'dpayFiatAtOrderTime': value.dpayFiatAtOrderTime,
        'experienceRewards': value.experienceRewards,
        'experienceRewardsTransaction': value.experienceRewardsTransaction,
        'status': MenuOrderStatusToJSON(value.status),
        'log': value.log === undefined ? undefined : ((value.log as Array<any>).map(MenuOrderLogToJSON)),
        'address': AddressToJSON(value.address),
        'distance': value.distance,
        'restaurantNotes': value.restaurantNotes,
        'deliveryNotes': value.deliveryNotes,
        'deliveryHandoffInstructions': DeliveryHandoffInstructionsToJSON(value.deliveryHandoffInstructions),
        'scheduledTime': value.scheduledTime,
        'readyBy': value.readyBy,
        'marketplacePercentage': value.marketplacePercentage,
        'marketplaceFee': value.marketplaceFee,
        'serviceFees': value.serviceFees === undefined ? undefined : ((value.serviceFees as Array<any>).map(MenuOrderServiceFeeToJSON)),
        'merchantOwed': value.merchantOwed,
        'grandTotal': value.grandTotal,
        'transferAmount': value.transferAmount,
        'handoff': HandoffOptionsToJSON(value.handoff),
        'pickupCode': value.pickupCode,
        'orderItems': ((value.orderItems as Array<any>).map(MenuOrderItemToJSON)),
        'email': value.email,
        'phoneNumber': PhoneNumberToJSON(value.phoneNumber),
        'firstName': value.firstName,
        'lastName': value.lastName,
        'errorMessage': value.errorMessage,
        'cancellationReason': value.cancellationReason,
        'stripeCheckout': value.stripeCheckout,
        'paidToDevour': value.paidToDevour,
        'paymentIntent': value.paymentIntent,
        'chowlyId': value.chowlyId,
        'deliveryId': value.deliveryId,
        'dspId': value.dspId,
        'discountSelected': value.discountSelected,
        'discountNullify': value.discountNullify,
        'callbackComplete': value.callbackComplete,
        'coinbaseChargeId': value.coinbaseChargeId,
        'isCoinbase': value.isCoinbase,
        'coinbaseChargeData': value.coinbaseChargeData,
        'exceededSlippage': value.exceededSlippage,
        'validationErrorMessage': value.validationErrorMessage,
        'devourIqNotification': value.devourIqNotification,
        'refunds': value.refunds === undefined ? undefined : ((value.refunds as Array<any>).map(OrderRefundToJSON)),
        'uberEatsUuid': value.uberEatsUuid,
        'orderUuid': value.orderUuid,
        'oloBasketId': value.oloBasketId,
        'oloOrderGuid': value.oloOrderGuid,
        'oloErrors': value.oloErrors === undefined ? undefined : ((value.oloErrors as Array<any>).map(OloErrorToJSON)),
    };
}


