import {ReactElement, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {addError} from "@/redux/meta/metaActions";
import getConfig from "../../../utils/getConfig";
import {ThirdwebApi, NftPurchaseUpsert} from "@devour/client";
import MintIndustryPassModalSuccess from "@/components/modals/mintIndustryPassModal/MintIndustryPassModalSuccess";
import {useParams} from "react-router";

interface Props {
    resetSuccess: () => void;
}

function MintIndustryPassSuccessWrapper(props: Props): ReactElement {

    const {nftPurchaseId} = useParams<{ nftPurchaseId: string; }>();
    const [
        nftPurchase,
        setNftPurchase,
    ] = useState<NftPurchaseUpsert>();
    const dispatch = useDispatch();

    useEffect(() => {
        void getClaimableNft();
    }, [nftPurchaseId]);

    async function getClaimableNft(): Promise<void> {
        if (!nftPurchaseId) {
            return;
        }
        try {
            const res = await new ThirdwebApi(getConfig()).getNftPurchase({
                id: nftPurchaseId,
            });
            setNftPurchase(res);
        } catch (e) {
            dispatch(await addError(e));
        }
    }

    if (!nftPurchase) {
        return null;
    }

    return (
        <div className="the-industry-pass-page_purchase_modal-template">
            <div className="mint-industry-credit-card">
                <div className="mint-industry-credit-card_body">
                    <MintIndustryPassModalSuccess
                        nftPurchase={nftPurchase}
                        resetStates={props.resetSuccess}
                    />
                </div>
            </div>
        </div>
    );
}

export default MintIndustryPassSuccessWrapper;
