import {truncateText} from "./truncateText";

export function formatGoVIPNames(firstName: string, lastName: string, nickname?: string): string {
    const truncatedNickname = truncateText(15, nickname || "");
    const truncatedFirstName = truncateText(15, firstName || "");

    if (firstName && lastName && nickname) {
        return `${truncatedFirstName} ${lastName[0]}. (${truncatedNickname})`;
    }

    if (firstName) {
        if (lastName) {
            return `${truncatedFirstName} ${lastName}`;
        }
        if (nickname) {
            return `${truncatedFirstName} (${truncatedNickname})`;
        }
        return `${truncatedFirstName}`;
    }

    if (nickname) {
        return truncatedNickname;
    }

    return "GoVIP Player";

}
