/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CheckTokenExpirationResponse
 */
export interface CheckTokenExpirationResponse {
    /**
     * 
     * @type {boolean}
     * @memberof CheckTokenExpirationResponse
     */
    expired: boolean;
}

export function CheckTokenExpirationResponseFromJSON(json: any): CheckTokenExpirationResponse {
    return CheckTokenExpirationResponseFromJSONTyped(json, false);
}

export function CheckTokenExpirationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CheckTokenExpirationResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'expired': json['expired'],
    };
}

export function CheckTokenExpirationResponseToJSON(value?: CheckTokenExpirationResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'expired': value.expired,
    };
}


