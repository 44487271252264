import {ReactElement} from "react";
import {Link} from "react-router-dom";
import classNames from "classnames";

interface Props {
    toggle: () => void;
    className: string;
}

function GoFrensTutorialModalRepCommunity(props: Props): ReactElement {

    return (
        <div className={classNames("gofrens-tutorial-modal-rep-community", props.className)}>
            <div>
                <p className="gofrens-tutorial-modal-rep-community_step">
					Step 2: Follow next quest
                </p>
                <div className="gofrens-tutorial-modal-rep-community_title">
                    <img
                        className="gofrens-tutorial-modal-rep-community_title_icon"
                        src={import.meta.env.VITE_CDN_URL + "/images/tutorial-step2-speaker.webp"}
                        alt="Rep a Community"
                    />
                    <span>
					Rep a Community
                    </span>
                </div>
                <div className="gofrens-tutorial-modal-rep-community_description">
					Dive into exclusive Web3 communities! Choose where you nabbed your NFT, and represent your community
					with pride!
                </div>
            </div>
            <div className="gofrens-tutorial-modal-rep-community_image">
                <Link
                    to="/gofriends"
                    onClick={props.toggle}
                >
                    <img
                        src={import.meta.env.VITE_CDN_URL + "/images/tutorial-step2.webp"}
                        alt="Rep This Community"
                    />
                </Link>
            </div>
        </div>
    );
}

export default GoFrensTutorialModalRepCommunity;
