/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum BrandMapBannerImageType {
    DIAMOND = 'DIAMOND',
    RECTANGLE = 'RECTANGLE'
}

export function BrandMapBannerImageTypeFromJSON(json: any): BrandMapBannerImageType {
    return BrandMapBannerImageTypeFromJSONTyped(json, false);
}

export function BrandMapBannerImageTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): BrandMapBannerImageType {
    return json as BrandMapBannerImageType;
}

export function BrandMapBannerImageTypeToJSON(value?: BrandMapBannerImageType | null): any {
    return value as any;
}

