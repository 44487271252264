/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ServiceFeeType {
    DEVOURGOSERVICE = 'DEVOURGO_SERVICE',
    PROMOSERVICEREDUCTION = 'PROMO_SERVICE_REDUCTION'
}

export function ServiceFeeTypeFromJSON(json: any): ServiceFeeType {
    return ServiceFeeTypeFromJSONTyped(json, false);
}

export function ServiceFeeTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceFeeType {
    return json as ServiceFeeType;
}

export function ServiceFeeTypeToJSON(value?: ServiceFeeType | null): any {
    return value as any;
}

