/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaginationInfo
 */
export interface PaginationInfo {
    /**
     * 
     * @type {boolean}
     * @memberof PaginationInfo
     */
    enablePrevious: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaginationInfo
     */
    enableNext: boolean;
    /**
     * 
     * @type {number}
     * @memberof PaginationInfo
     */
    lowerRange: number;
    /**
     * 
     * @type {number}
     * @memberof PaginationInfo
     */
    upperRange: number;
    /**
     * 
     * @type {number}
     * @memberof PaginationInfo
     */
    total: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof PaginationInfo
     */
    availableOffsets: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof PaginationInfo
     */
    limit: number;
    /**
     * 
     * @type {number}
     * @memberof PaginationInfo
     */
    offset: number;
}

export function PaginationInfoFromJSON(json: any): PaginationInfo {
    return PaginationInfoFromJSONTyped(json, false);
}

export function PaginationInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginationInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'enablePrevious': json['enablePrevious'],
        'enableNext': json['enableNext'],
        'lowerRange': json['lowerRange'],
        'upperRange': json['upperRange'],
        'total': json['total'],
        'availableOffsets': json['availableOffsets'],
        'limit': json['limit'],
        'offset': json['offset'],
    };
}

export function PaginationInfoToJSON(value?: PaginationInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'enablePrevious': value.enablePrevious,
        'enableNext': value.enableNext,
        'lowerRange': value.lowerRange,
        'upperRange': value.upperRange,
        'total': value.total,
        'availableOffsets': value.availableOffsets,
        'limit': value.limit,
        'offset': value.offset,
    };
}


