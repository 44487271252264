import {ReactElement, useState} from "react";
import DevourTopNavUserData from "./DevourTopNavUserData";
import SignUpToast from "./SignUpToast";


interface Props {
    logoUrl?: string;
    text?: string;
}

function DevourTopNav(props: Props): ReactElement {
    const [
        showToast,
        setShowToast,
    ] = useState<boolean>(false);
    function handleToastDismissal() {
        setShowToast(false);
    }

    return (
        <>
            <SignUpToast
                message='Join DevourGO and start your GoFriends journey!'
                isOpen={showToast}
                onDismiss={handleToastDismissal}
            />
            <div className="devour-top-nav">
                <div className="devour-top-nav_navbar">
                    {props.logoUrl && <img
                        className="devour-top-nav_navbar_logo"
                        src={props.logoUrl}
                        alt="GoFrens"
                    />}
                    {props.text && <h4 className="devour-top-nav_navbar_text">{props.text}</h4>}
                    <DevourTopNavUserData dropdownAnchorElement=".devour-top-nav" onShowSignInToast={() => setShowToast(true)} />
                </div>
            </div>
        </>
    );
}

export default DevourTopNav;
