import {memo, ReactElement, useEffect} from "react";
import {BrandMap, Restaurant, RestaurantSearchResult} from "@devour/client";
import {GoogleMap, LoadScript, Marker} from "@react-google-maps/api";
import {isEqual} from "lodash";

interface Props {
    activeBusinessId: string;
    businessesToRender: Array<Restaurant | RestaurantSearchResult>;
    brandMap: BrandMap;
    saveMapRef: (mapRef: google.maps.Map) => void;
    center: google.maps.LatLngLiteral;
    onMapPinClick: (business: Restaurant | RestaurantSearchResult) => void;
    defaultZoom?: number;
}

function BrandMapLandingPageGoogleMapsWrapper(props: Props): ReactElement {
    return (
        <LoadScript googleMapsApiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY_FRONTEND_ONLY}>
            <GoogleMap
                onLoad={props.saveMapRef}
                options={{
                    disableDefaultUI: true,
                    streetViewControl: true,
                    clickableIcons: false,
                    zoomControl: true,
                    styles: [
                        {
                            featureType: "poi",
                            stylers: [
                                {
                                    visibility: "off",
                                },
                            ],
                        },
                        {
                            featureType: "transit",
                            stylers: [
                                {
                                    visibility: "off",
                                },
                            ],
                        },
                    ],
                }}
                mapContainerStyle={{
                    width: "100%",
                    height: "100%",
                }}
                center={props.center}
                zoom={props.defaultZoom}
            >
                <>
                    {props.businessesToRender?.map(business => {
                        const businessId = "_id" in business
                            ? business._id
                            : business.id;
                        const isActive = businessId === props.activeBusinessId;

                        return (
                            <Marker
                                key={`brand-map-business-marker_${businessId}`}
                                onClick={() => props.onMapPinClick(business)}
                                opacity={business.isOpen ? 1 : 0.4}
                                icon={`${import.meta.env.VITE_CDN_URL}/images/map-pin${isActive ? "-active" : ""}.svg`}
                                position={{
                                    lat: business.address.location.coordinates[1],
                                    lng: business.address.location.coordinates[0],
                                }}
                            />
                        );
                    })}
                </>
            </GoogleMap>
        </LoadScript>
    );
}

export default memo(BrandMapLandingPageGoogleMapsWrapper, (prevProps: Readonly<Props>, nextProps: Readonly<Props>) => {
    const brandMapIdPrev = prevProps.brandMap.brand.id;
    const brandMapIdNext = nextProps.brandMap.brand.id;
    const brandMapActiveIdPrev = prevProps.activeBusinessId;
    const brandMapActiveIdNext = nextProps.activeBusinessId;
    const brandMapBusinessIdsPrev = prevProps.businessesToRender.map(b => "_id" in b
        ? b._id
        : b.id);
    const brandMapBusinessIdsNext = nextProps.businessesToRender.map(b => "_id" in b
        ? b._id
        : b.id);
    return brandMapIdPrev === brandMapIdNext && brandMapActiveIdPrev === brandMapActiveIdNext && isEqual(brandMapBusinessIdsPrev, brandMapBusinessIdsNext);
});
