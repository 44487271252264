import {ButtonHTMLAttributes, ReactElement} from "react";
import FrameButton from "@/components/buttons/FrameButton";
import {FaPlus} from "react-icons/fa6";

interface Props {
    onClick: () => void;
}

function CheckoutPaymentAddCreditButton(props: Props): ReactElement {

    return (
        <>
            <FrameButton
                <ButtonHTMLAttributes<HTMLButtonElement>>
                color="white-drop-shadow"
                size="large"
                className="checkout-payments-credit_button"
                onClick={props.onClick}>

                <div className="checkout-payments-credit_button_left">
                    <FaPlus/>

                    <p>Add Credit Card</p>
                </div>

            </FrameButton>
        </>
    );
}

export default CheckoutPaymentAddCreditButton;
