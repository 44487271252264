export const dpayAbi = [
    {
        "inputs": [
            {
                "components": [
                    {
                        "internalType": "string",
                        "name": "name_",
                        "type": "string",
                    }, {
                        "internalType": "string",
                        "name": "symbol_",
                        "type": "string",
                    }, {
                        "internalType": "uint8",
                        "name": "decimals_",
                        "type": "uint8",
                    }, {
                        "internalType": "address",
                        "name": "creator_",
                        "type": "address",
                    }, {
                        "internalType": "uint256",
                        "name": "tTotal_",
                        "type": "uint256",
                    }, {
                        "internalType": "uint256",
                        "name": "_maxTax",
                        "type": "uint256",
                    }, {
                        "components": [
                            {
                                "internalType": "bool",
                                "name": "transactionTax",
                                "type": "bool",
                            }, {
                                "internalType": "bool",
                                "name": "buyBackTax",
                                "type": "bool",
                            }, {
                                "internalType": "bool",
                                "name": "holderTax",
                                "type": "bool",
                            }, {"internalType": "bool",
                                "name": "lpTax",
                                "type": "bool"}, {
                                "internalType": "bool",
                                "name": "canBlacklist",
                                "type": "bool",
                            }, {"internalType": "bool",
                                "name": "canMint",
                                "type": "bool"}, {
                                "internalType": "bool",
                                "name": "canPause",
                                "type": "bool",
                            }, {"internalType": "bool",
                                "name": "maxBalanceAfterBuy",
                                "type": "bool"},
                        ],
                        "internalType": "struct TaxSettings",
                        "name": "_settings",
                        "type": "tuple",
                    }, {
                        "components": [
                            {
                                "internalType": "bool",
                                "name": "transactionTax",
                                "type": "bool",
                            }, {
                                "internalType": "bool",
                                "name": "buyBackTax",
                                "type": "bool",
                            }, {
                                "internalType": "bool",
                                "name": "holderTax",
                                "type": "bool",
                            }, {"internalType": "bool",
                                "name": "lpTax",
                                "type": "bool"}, {
                                "internalType": "bool",
                                "name": "canBlacklist",
                                "type": "bool",
                            }, {"internalType": "bool",
                                "name": "canMint",
                                "type": "bool"}, {
                                "internalType": "bool",
                                "name": "canPause",
                                "type": "bool",
                            }, {"internalType": "bool",
                                "name": "maxBalanceAfterBuy",
                                "type": "bool"},
                        ],
                        "internalType": "struct TaxSettings",
                        "name": "_lockedSettings",
                        "type": "tuple",
                    }, {
                        "components": [
                            {
                                "components": [
                                    {
                                        "internalType": "uint256",
                                        "name": "buy",
                                        "type": "uint256",
                                    }, {"internalType": "uint256",
                                        "name": "sell",
                                        "type": "uint256"},
                                ],
                                "internalType": "struct Fee",
                                "name": "transactionTax",
                                "type": "tuple",
                            }, {
                                "internalType": "uint256",
                                "name": "buyBackTax",
                                "type": "uint256",
                            }, {
                                "internalType": "uint256",
                                "name": "holderTax",
                                "type": "uint256",
                            }, {"internalType": "uint256",
                                "name": "lpTax",
                                "type": "uint256"},
                        ],
                        "internalType": "struct Fees",
                        "name": "_fees",
                        "type": "tuple",
                    }, {
                        "internalType": "address",
                        "name": "_transactionTaxWallet",
                        "type": "address",
                    }, {
                        "components": [
                            {
                                "internalType": "string",
                                "name": "name",
                                "type": "string",
                            }, {
                                "components": [
                                    {
                                        "internalType": "uint256",
                                        "name": "buy",
                                        "type": "uint256",
                                    }, {"internalType": "uint256",
                                        "name": "sell",
                                        "type": "uint256"},
                                ],
                                "internalType": "struct Fee",
                                "name": "fee",
                                "type": "tuple",
                            }, {
                                "internalType": "address",
                                "name": "wallet",
                                "type": "address",
                            }, {"internalType": "bool",
                                "name": "withdrawAsGas",
                                "type": "bool"},
                        ],
                        "internalType": "struct CustomTax[]",
                        "name": "_customTaxes",
                        "type": "tuple[]",
                    }, {
                        "internalType": "uint256",
                        "name": "lpWalletThreshold",
                        "type": "uint256",
                    }, {
                        "internalType": "uint256",
                        "name": "buyBackWalletThreshold",
                        "type": "uint256",
                    }, {
                        "internalType": "uint256",
                        "name": "_taxHelperIndex",
                        "type": "uint256",
                    }, {
                        "internalType": "address",
                        "name": "admin_",
                        "type": "address",
                    }, {
                        "internalType": "address",
                        "name": "recoveryAdmin_",
                        "type": "address",
                    }, {
                        "internalType": "bool",
                        "name": "isLossless_",
                        "type": "bool",
                    }, {
                        "components": [
                            {
                                "internalType": "uint256",
                                "name": "startBlock",
                                "type": "uint256",
                            }, {
                                "internalType": "uint256",
                                "name": "endDate",
                                "type": "uint256",
                            }, {
                                "internalType": "uint256",
                                "name": "increment",
                                "type": "uint256",
                            }, {
                                "internalType": "uint256",
                                "name": "initialMaxHold",
                                "type": "uint256",
                            }, {"internalType": "bool",
                                "name": "isActive",
                                "type": "bool"},
                        ],
                        "internalType": "struct AntiBotSettings",
                        "name": "_antiBotSettings",
                        "type": "tuple",
                    }, {
                        "internalType": "uint256",
                        "name": "_maxBalanceAfterBuy",
                        "type": "uint256",
                    }, {
                        "components": [
                            {
                                "internalType": "uint256",
                                "name": "endDate",
                                "type": "uint256",
                            }, {"internalType": "bool",
                                "name": "isActive",
                                "type": "bool"},
                        ],
                        "internalType": "struct SwapWhitelistingSettings",
                        "name": "_swapWhitelistingSettings",
                        "type": "tuple",
                    },
                ],
                "internalType": "struct TaxToken.ConstructorParams",
                "name": "params",
                "type": "tuple",
            }, {"internalType": "address",
                "name": "_factory",
                "type": "address"},
        ],
        "stateMutability": "nonpayable",
        "type": "constructor",
    }, {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "owner",
                "type": "address",
            }, {
                "indexed": true,
                "internalType": "address",
                "name": "spender",
                "type": "address",
            }, {"indexed": false,
                "internalType": "uint256",
                "name": "value",
                "type": "uint256"},
        ],
        "name": "Approval",
        "type": "event",
    }, {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "previousOwner",
                "type": "address",
            }, {"indexed": true,
                "internalType": "address",
                "name": "newOwner",
                "type": "address"},
        ],
        "name": "OwnershipTransferred",
        "type": "event",
    }, {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "from",
                "type": "address",
            }, {
                "indexed": true,
                "internalType": "address",
                "name": "to",
                "type": "address",
            }, {"indexed": false,
                "internalType": "uint256",
                "name": "value",
                "type": "uint256"},
        ],
        "name": "Transfer",
        "type": "event",
    }, {"stateMutability": "nonpayable",
        "type": "fallback"}, {
        "inputs": [],
        "name": "CONTRACT_VERSION",
        "outputs": [
            {"internalType": "uint256",
                "name": "",
                "type": "uint256"},
        ],
        "stateMutability": "view",
        "type": "function",
    }, {
        "inputs": [],
        "name": "MaxCustom",
        "outputs": [
            {"internalType": "uint8",
                "name": "",
                "type": "uint8"},
        ],
        "stateMutability": "view",
        "type": "function",
    }, {
        "inputs": [],
        "name": "MaxTax",
        "outputs": [
            {"internalType": "uint256",
                "name": "",
                "type": "uint256"},
        ],
        "stateMutability": "view",
        "type": "function",
    }, {
        "inputs": [
            {
                "internalType": "address",
                "name": "_address1",
                "type": "address",
            }, {"internalType": "address",
                "name": "_address2",
                "type": "address"},
        ],
        "name": "_allowances",
        "outputs": [
            {"internalType": "uint256",
                "name": "",
                "type": "uint256"},
        ],
        "stateMutability": "view",
        "type": "function",
    }, {
        "inputs": [],
        "name": "_getCurrentSupply",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256",
            }, {"internalType": "uint256",
                "name": "",
                "type": "uint256"},
        ],
        "stateMutability": "view",
        "type": "function",
    }, {
        "inputs": [],
        "name": "_getRate",
        "outputs": [
            {"internalType": "uint256",
                "name": "",
                "type": "uint256"},
        ],
        "stateMutability": "view",
        "type": "function",
    }, {
        "inputs": [
            {"internalType": "address",
                "name": "_address",
                "type": "address"},
        ],
        "name": "_isExcluded",
        "outputs": [
            {"internalType": "bool",
                "name": "",
                "type": "bool"},
        ],
        "stateMutability": "view",
        "type": "function",
    }, {
        "inputs": [],
        "name": "_tFeeTotal",
        "outputs": [
            {"internalType": "uint256",
                "name": "",
                "type": "uint256"},
        ],
        "stateMutability": "view",
        "type": "function",
    }, {
        "inputs": [],
        "name": "admin",
        "outputs": [
            {"internalType": "address",
                "name": "",
                "type": "address"},
        ],
        "stateMutability": "view",
        "type": "function",
    }, {
        "inputs": [
            {
                "internalType": "address",
                "name": "_owner",
                "type": "address",
            }, {"internalType": "address",
                "name": "spender",
                "type": "address"},
        ],
        "name": "allowance",
        "outputs": [
            {"internalType": "uint256",
                "name": "",
                "type": "uint256"},
        ],
        "stateMutability": "view",
        "type": "function",
    }, {
        "inputs": [],
        "name": "antiBotSettings",
        "outputs": [
            {
                "components": [
                    {
                        "internalType": "uint256",
                        "name": "startBlock",
                        "type": "uint256",
                    }, {
                        "internalType": "uint256",
                        "name": "endDate",
                        "type": "uint256",
                    }, {
                        "internalType": "uint256",
                        "name": "increment",
                        "type": "uint256",
                    }, {
                        "internalType": "uint256",
                        "name": "initialMaxHold",
                        "type": "uint256",
                    }, {"internalType": "bool",
                        "name": "isActive",
                        "type": "bool"},
                ],
                "internalType": "struct AntiBotSettings",
                "name": "",
                "type": "tuple",
            },
        ],
        "stateMutability": "view",
        "type": "function",
    }, {
        "inputs": [
            {
                "internalType": "address",
                "name": "spender",
                "type": "address",
            }, {"internalType": "uint256",
                "name": "amount",
                "type": "uint256"},
        ],
        "name": "approve",
        "outputs": [
            {"internalType": "bool",
                "name": "",
                "type": "bool"},
        ],
        "stateMutability": "nonpayable",
        "type": "function",
    }, {
        "inputs": [
            {"internalType": "address",
                "name": "account",
                "type": "address"},
        ],
        "name": "balanceOf",
        "outputs": [
            {"internalType": "uint256",
                "name": "",
                "type": "uint256"},
        ],
        "stateMutability": "view",
        "type": "function",
    }, {
        "inputs": [
            {"internalType": "uint256",
                "name": "amount",
                "type": "uint256"},
        ],
        "name": "burn",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function",
    }, {
        "inputs": [
            {"internalType": "uint256",
                "name": "_amount",
                "type": "uint256"},
        ],
        "name": "buyBackBurn",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function",
    }, {
        "inputs": [],
        "name": "buyBackWallet",
        "outputs": [
            {"internalType": "address",
                "name": "",
                "type": "address"},
        ],
        "stateMutability": "view",
        "type": "function",
    }, {
        "inputs": [],
        "name": "customTaxLength",
        "outputs": [
            {"internalType": "uint256",
                "name": "",
                "type": "uint256"},
        ],
        "stateMutability": "view",
        "type": "function",
    }, {
        "inputs": [
            {"internalType": "uint256",
                "name": "_index",
                "type": "uint256"},
        ],
        "name": "customTaxes",
        "outputs": [
            {
                "components": [
                    {
                        "internalType": "string",
                        "name": "name",
                        "type": "string",
                    }, {
                        "components": [
                            {
                                "internalType": "uint256",
                                "name": "buy",
                                "type": "uint256",
                            }, {"internalType": "uint256",
                                "name": "sell",
                                "type": "uint256"},
                        ],
                        "internalType": "struct Fee",
                        "name": "fee",
                        "type": "tuple",
                    }, {
                        "internalType": "address",
                        "name": "wallet",
                        "type": "address",
                    }, {"internalType": "bool",
                        "name": "withdrawAsGas",
                        "type": "bool"},
                ],
                "internalType": "struct CustomTax",
                "name": "",
                "type": "tuple",
            },
        ],
        "stateMutability": "view",
        "type": "function",
    }, {
        "inputs": [],
        "name": "decimals",
        "outputs": [
            {"internalType": "uint8",
                "name": "",
                "type": "uint8"},
        ],
        "stateMutability": "view",
        "type": "function",
    }, {
        "inputs": [
            {
                "internalType": "address",
                "name": "spender",
                "type": "address",
            }, {"internalType": "uint256",
                "name": "subtractedValue",
                "type": "uint256"},
        ],
        "name": "decreaseAllowance",
        "outputs": [
            {"internalType": "bool",
                "name": "",
                "type": "bool"},
        ],
        "stateMutability": "nonpayable",
        "type": "function",
    }, {
        "inputs": [],
        "name": "factory",
        "outputs": [
            {"internalType": "address",
                "name": "",
                "type": "address"},
        ],
        "stateMutability": "view",
        "type": "function",
    }, {
        "inputs": [],
        "name": "fees",
        "outputs": [
            {
                "components": [
                    {
                        "components": [
                            {
                                "internalType": "uint256",
                                "name": "buy",
                                "type": "uint256",
                            }, {"internalType": "uint256",
                                "name": "sell",
                                "type": "uint256"},
                        ],
                        "internalType": "struct Fee",
                        "name": "transactionTax",
                        "type": "tuple",
                    }, {
                        "internalType": "uint256",
                        "name": "buyBackTax",
                        "type": "uint256",
                    }, {
                        "internalType": "uint256",
                        "name": "holderTax",
                        "type": "uint256",
                    }, {"internalType": "uint256",
                        "name": "lpTax",
                        "type": "uint256"},
                ],
                "internalType": "struct Fees",
                "name": "",
                "type": "tuple",
            },
        ],
        "stateMutability": "view",
        "type": "function",
    }, {
        "inputs": [
            {
                "internalType": "address",
                "name": "spender",
                "type": "address",
            }, {"internalType": "uint256",
                "name": "addedValue",
                "type": "uint256"},
        ],
        "name": "increaseAllowance",
        "outputs": [
            {"internalType": "bool",
                "name": "",
                "type": "bool"},
        ],
        "stateMutability": "nonpayable",
        "type": "function",
    }, {
        "inputs": [
            {"internalType": "address",
                "name": "_address",
                "type": "address"},
        ],
        "name": "isBlacklisted",
        "outputs": [
            {"internalType": "bool",
                "name": "",
                "type": "bool"},
        ],
        "stateMutability": "view",
        "type": "function",
    }, {
        "inputs": [],
        "name": "isLocked",
        "outputs": [
            {
                "components": [
                    {
                        "internalType": "bool",
                        "name": "transactionTax",
                        "type": "bool",
                    }, {"internalType": "bool",
                        "name": "buyBackTax",
                        "type": "bool"}, {
                        "internalType": "bool",
                        "name": "holderTax",
                        "type": "bool",
                    }, {"internalType": "bool",
                        "name": "lpTax",
                        "type": "bool"}, {
                        "internalType": "bool",
                        "name": "canBlacklist",
                        "type": "bool",
                    }, {"internalType": "bool",
                        "name": "canMint",
                        "type": "bool"}, {
                        "internalType": "bool",
                        "name": "canPause",
                        "type": "bool",
                    }, {"internalType": "bool",
                        "name": "maxBalanceAfterBuy",
                        "type": "bool"},
                ],
                "internalType": "struct TaxSettings",
                "name": "",
                "type": "tuple",
            },
        ],
        "stateMutability": "view",
        "type": "function",
    }, {
        "inputs": [],
        "name": "isLosslessOn",
        "outputs": [
            {"internalType": "bool",
                "name": "",
                "type": "bool"},
        ],
        "stateMutability": "view",
        "type": "function",
    }, {
        "inputs": [],
        "name": "isLosslessTurnOffProposed",
        "outputs": [
            {"internalType": "bool",
                "name": "",
                "type": "bool"},
        ],
        "stateMutability": "view",
        "type": "function",
    }, {
        "inputs": [],
        "name": "lossless",
        "outputs": [
            {"internalType": "contract ILosslessController",
                "name": "",
                "type": "address"},
        ],
        "stateMutability": "view",
        "type": "function",
    }, {
        "inputs": [],
        "name": "losslessTurnOffTimestamp",
        "outputs": [
            {"internalType": "uint256",
                "name": "",
                "type": "uint256"},
        ],
        "stateMutability": "view",
        "type": "function",
    }, {
        "inputs": [
            {"internalType": "address",
                "name": "_address",
                "type": "address"},
        ],
        "name": "lpTokens",
        "outputs": [
            {"internalType": "bool",
                "name": "",
                "type": "bool"},
        ],
        "stateMutability": "view",
        "type": "function",
    }, {
        "inputs": [],
        "name": "lpWallet",
        "outputs": [
            {"internalType": "address",
                "name": "",
                "type": "address"},
        ],
        "stateMutability": "view",
        "type": "function",
    }, {
        "inputs": [],
        "name": "marketInit",
        "outputs": [
            {"internalType": "bool",
                "name": "",
                "type": "bool"},
        ],
        "stateMutability": "view",
        "type": "function",
    }, {
        "inputs": [],
        "name": "marketInitBlockTime",
        "outputs": [
            {"internalType": "uint256",
                "name": "",
                "type": "uint256"},
        ],
        "stateMutability": "view",
        "type": "function",
    }, {
        "inputs": [],
        "name": "maxBalanceAfterBuy",
        "outputs": [
            {"internalType": "uint256",
                "name": "",
                "type": "uint256"},
        ],
        "stateMutability": "view",
        "type": "function",
    }, {
        "inputs": [
            {"internalType": "uint256",
                "name": "amount",
                "type": "uint256"},
        ],
        "name": "mint",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function",
    }, {
        "inputs": [],
        "name": "name",
        "outputs": [
            {"internalType": "string",
                "name": "",
                "type": "string"},
        ],
        "stateMutability": "view",
        "type": "function",
    }, {
        "inputs": [],
        "name": "owner",
        "outputs": [
            {"internalType": "address",
                "name": "",
                "type": "address"},
        ],
        "stateMutability": "view",
        "type": "function",
    }, {
        "inputs": [],
        "name": "pairAddress",
        "outputs": [
            {"internalType": "address",
                "name": "",
                "type": "address"},
        ],
        "stateMutability": "view",
        "type": "function",
    }, {
        "inputs": [],
        "name": "paused",
        "outputs": [
            {"internalType": "bool",
                "name": "",
                "type": "bool"},
        ],
        "stateMutability": "view",
        "type": "function",
    }, {
        "inputs": [],
        "name": "recoveryAdmin",
        "outputs": [
            {"internalType": "address",
                "name": "",
                "type": "address"},
        ],
        "stateMutability": "view",
        "type": "function",
    }, {
        "inputs": [],
        "name": "renounceOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function",
    }, {
        "inputs": [],
        "name": "swapWhitelistingSettings",
        "outputs": [
            {
                "components": [
                    {
                        "internalType": "uint256",
                        "name": "endDate",
                        "type": "uint256",
                    }, {"internalType": "bool",
                        "name": "isActive",
                        "type": "bool"},
                ],
                "internalType": "struct SwapWhitelistingSettings",
                "name": "",
                "type": "tuple",
            },
        ],
        "stateMutability": "view",
        "type": "function",
    }, {
        "inputs": [],
        "name": "symbol",
        "outputs": [
            {"internalType": "string",
                "name": "",
                "type": "string"},
        ],
        "stateMutability": "view",
        "type": "function",
    }, {
        "inputs": [],
        "name": "taxHelperIndex",
        "outputs": [
            {"internalType": "uint256",
                "name": "",
                "type": "uint256"},
        ],
        "stateMutability": "view",
        "type": "function",
    }, {
        "inputs": [],
        "name": "taxSettings",
        "outputs": [
            {
                "components": [
                    {
                        "internalType": "bool",
                        "name": "transactionTax",
                        "type": "bool",
                    }, {"internalType": "bool",
                        "name": "buyBackTax",
                        "type": "bool"}, {
                        "internalType": "bool",
                        "name": "holderTax",
                        "type": "bool",
                    }, {"internalType": "bool",
                        "name": "lpTax",
                        "type": "bool"}, {
                        "internalType": "bool",
                        "name": "canBlacklist",
                        "type": "bool",
                    }, {"internalType": "bool",
                        "name": "canMint",
                        "type": "bool"}, {
                        "internalType": "bool",
                        "name": "canPause",
                        "type": "bool",
                    }, {"internalType": "bool",
                        "name": "maxBalanceAfterBuy",
                        "type": "bool"},
                ],
                "internalType": "struct TaxSettings",
                "name": "",
                "type": "tuple",
            },
        ],
        "stateMutability": "view",
        "type": "function",
    }, {
        "inputs": [],
        "name": "timelockPeriod",
        "outputs": [
            {"internalType": "uint256",
                "name": "",
                "type": "uint256"},
        ],
        "stateMutability": "view",
        "type": "function",
    }, {
        "inputs": [
            {"internalType": "uint256",
                "name": "rAmount",
                "type": "uint256"},
        ],
        "name": "tokenFromReflection",
        "outputs": [
            {"internalType": "uint256",
                "name": "",
                "type": "uint256"},
        ],
        "stateMutability": "view",
        "type": "function",
    }, {
        "inputs": [],
        "name": "totalSupply",
        "outputs": [
            {"internalType": "uint256",
                "name": "",
                "type": "uint256"},
        ],
        "stateMutability": "view",
        "type": "function",
    }, {
        "inputs": [],
        "name": "transactionTaxWallet",
        "outputs": [
            {"internalType": "address",
                "name": "",
                "type": "address"},
        ],
        "stateMutability": "view",
        "type": "function",
    }, {
        "inputs": [
            {
                "internalType": "address",
                "name": "recipient",
                "type": "address",
            }, {"internalType": "uint256",
                "name": "amount",
                "type": "uint256"},
        ],
        "name": "transfer",
        "outputs": [
            {"internalType": "bool",
                "name": "",
                "type": "bool"},
        ],
        "stateMutability": "nonpayable",
        "type": "function",
    }, {
        "inputs": [
            {
                "internalType": "address",
                "name": "sender",
                "type": "address",
            }, {
                "internalType": "address",
                "name": "recipient",
                "type": "address",
            }, {"internalType": "uint256",
                "name": "amount",
                "type": "uint256"},
        ],
        "name": "transferFrom",
        "outputs": [
            {"internalType": "bool",
                "name": "",
                "type": "bool"},
        ],
        "stateMutability": "nonpayable",
        "type": "function",
    }, {
        "inputs": [
            {"internalType": "address[]",
                "name": "from",
                "type": "address[]"},
        ],
        "name": "transferOutBlacklistedFunds",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function",
    }, {
        "inputs": [
            {"internalType": "address",
                "name": "newOwner",
                "type": "address"},
        ],
        "name": "transferOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function",
    },
];
