/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TopLeaderboardCommunity
 */
export interface TopLeaderboardCommunity {
    /**
     * 
     * @type {string}
     * @memberof TopLeaderboardCommunity
     */
    communityIcon?: string;
    /**
     * 
     * @type {string}
     * @memberof TopLeaderboardCommunity
     */
    communityName: string;
    /**
     * 
     * @type {number}
     * @memberof TopLeaderboardCommunity
     */
    totalPoints: number;
}

export function TopLeaderboardCommunityFromJSON(json: any): TopLeaderboardCommunity {
    return TopLeaderboardCommunityFromJSONTyped(json, false);
}

export function TopLeaderboardCommunityFromJSONTyped(json: any, ignoreDiscriminator: boolean): TopLeaderboardCommunity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'communityIcon': !exists(json, 'communityIcon') ? undefined : json['communityIcon'],
        'communityName': json['communityName'],
        'totalPoints': json['totalPoints'],
    };
}

export function TopLeaderboardCommunityToJSON(value?: TopLeaderboardCommunity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'communityIcon': value.communityIcon,
        'communityName': value.communityName,
        'totalPoints': value.totalPoints,
    };
}


