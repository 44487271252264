/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Address,
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
    DeliveryHandoffInstructions,
    DeliveryHandoffInstructionsFromJSON,
    DeliveryHandoffInstructionsFromJSONTyped,
    DeliveryHandoffInstructionsToJSON,
    HandoffOptions,
    HandoffOptionsFromJSON,
    HandoffOptionsFromJSONTyped,
    HandoffOptionsToJSON,
    MenuOrderItem,
    MenuOrderItemFromJSON,
    MenuOrderItemFromJSONTyped,
    MenuOrderItemToJSON,
    PhoneNumberBody,
    PhoneNumberBodyFromJSON,
    PhoneNumberBodyFromJSONTyped,
    PhoneNumberBodyToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreateMenuOrderBody
 */
export interface CreateMenuOrderBody {
    /**
     * 
     * @type {string}
     * @memberof CreateMenuOrderBody
     */
    business?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateMenuOrderBody
     */
    deliveryQuoteId?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateMenuOrderBody
     */
    tipRestaurant?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateMenuOrderBody
     */
    tipDelivery?: number;
    /**
     * 
     * @type {Address}
     * @memberof CreateMenuOrderBody
     */
    address?: Address;
    /**
     * 
     * @type {string}
     * @memberof CreateMenuOrderBody
     */
    restaurantNotes?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateMenuOrderBody
     */
    deliveryNotes?: string;
    /**
     * 
     * @type {DeliveryHandoffInstructions}
     * @memberof CreateMenuOrderBody
     */
    deliveryHandoffInstructions?: DeliveryHandoffInstructions;
    /**
     * 
     * @type {number}
     * @memberof CreateMenuOrderBody
     */
    scheduledTime?: number;
    /**
     * 
     * @type {HandoffOptions}
     * @memberof CreateMenuOrderBody
     */
    handoff?: HandoffOptions;
    /**
     * 
     * @type {Array<MenuOrderItem>}
     * @memberof CreateMenuOrderBody
     */
    orderItems?: Array<MenuOrderItem>;
    /**
     * 
     * @type {string}
     * @memberof CreateMenuOrderBody
     */
    email?: string;
    /**
     * 
     * @type {PhoneNumberBody}
     * @memberof CreateMenuOrderBody
     */
    phoneNumber?: PhoneNumberBody;
    /**
     * 
     * @type {string}
     * @memberof CreateMenuOrderBody
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateMenuOrderBody
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateMenuOrderBody
     */
    paymentMethodId?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateMenuOrderBody
     */
    dpay?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateMenuOrderBody
     */
    vdpay?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateMenuOrderBody
     */
    onChainDpay?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateMenuOrderBody
     */
    dpayFiatAtOrderTime?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateMenuOrderBody
     */
    isMagicWallet?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateMenuOrderBody
     */
    isCoinbase?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateMenuOrderBody
     */
    discountSelected?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateMenuOrderBody
     */
    discountCodes?: Array<string>;
}

export function CreateMenuOrderBodyFromJSON(json: any): CreateMenuOrderBody {
    return CreateMenuOrderBodyFromJSONTyped(json, false);
}

export function CreateMenuOrderBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateMenuOrderBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'business': !exists(json, 'business') ? undefined : json['business'],
        'deliveryQuoteId': !exists(json, 'deliveryQuoteId') ? undefined : json['deliveryQuoteId'],
        'tipRestaurant': !exists(json, 'tipRestaurant') ? undefined : json['tipRestaurant'],
        'tipDelivery': !exists(json, 'tipDelivery') ? undefined : json['tipDelivery'],
        'address': !exists(json, 'address') ? undefined : AddressFromJSON(json['address']),
        'restaurantNotes': !exists(json, 'restaurantNotes') ? undefined : json['restaurantNotes'],
        'deliveryNotes': !exists(json, 'deliveryNotes') ? undefined : json['deliveryNotes'],
        'deliveryHandoffInstructions': !exists(json, 'deliveryHandoffInstructions') ? undefined : DeliveryHandoffInstructionsFromJSON(json['deliveryHandoffInstructions']),
        'scheduledTime': !exists(json, 'scheduledTime') ? undefined : json['scheduledTime'],
        'handoff': !exists(json, 'handoff') ? undefined : HandoffOptionsFromJSON(json['handoff']),
        'orderItems': !exists(json, 'orderItems') ? undefined : ((json['orderItems'] as Array<any>).map(MenuOrderItemFromJSON)),
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : PhoneNumberBodyFromJSON(json['phoneNumber']),
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'paymentMethodId': !exists(json, 'paymentMethodId') ? undefined : json['paymentMethodId'],
        'dpay': !exists(json, 'dpay') ? undefined : json['dpay'],
        'vdpay': !exists(json, 'vdpay') ? undefined : json['vdpay'],
        'onChainDpay': !exists(json, 'onChainDpay') ? undefined : json['onChainDpay'],
        'dpayFiatAtOrderTime': !exists(json, 'dpayFiatAtOrderTime') ? undefined : json['dpayFiatAtOrderTime'],
        'isMagicWallet': !exists(json, 'isMagicWallet') ? undefined : json['isMagicWallet'],
        'isCoinbase': !exists(json, 'isCoinbase') ? undefined : json['isCoinbase'],
        'discountSelected': !exists(json, 'discountSelected') ? undefined : json['discountSelected'],
        'discountCodes': !exists(json, 'discountCodes') ? undefined : json['discountCodes'],
    };
}

export function CreateMenuOrderBodyToJSON(value?: CreateMenuOrderBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'business': value.business,
        'deliveryQuoteId': value.deliveryQuoteId,
        'tipRestaurant': value.tipRestaurant,
        'tipDelivery': value.tipDelivery,
        'address': AddressToJSON(value.address),
        'restaurantNotes': value.restaurantNotes,
        'deliveryNotes': value.deliveryNotes,
        'deliveryHandoffInstructions': DeliveryHandoffInstructionsToJSON(value.deliveryHandoffInstructions),
        'scheduledTime': value.scheduledTime,
        'handoff': HandoffOptionsToJSON(value.handoff),
        'orderItems': value.orderItems === undefined ? undefined : ((value.orderItems as Array<any>).map(MenuOrderItemToJSON)),
        'email': value.email,
        'phoneNumber': PhoneNumberBodyToJSON(value.phoneNumber),
        'firstName': value.firstName,
        'lastName': value.lastName,
        'paymentMethodId': value.paymentMethodId,
        'dpay': value.dpay,
        'vdpay': value.vdpay,
        'onChainDpay': value.onChainDpay,
        'dpayFiatAtOrderTime': value.dpayFiatAtOrderTime,
        'isMagicWallet': value.isMagicWallet,
        'isCoinbase': value.isCoinbase,
        'discountSelected': value.discountSelected,
        'discountCodes': value.discountCodes,
    };
}


