import { HandoffOptions, MenuOrder } from "@devour/client";
import MenuSuccessTrackerProgressBar from "./MenuSuccessTrackerProgressBar";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import moment from "moment";
import { useMenuOrderTracker } from "@/hooks/menuOrder/useMenuOrderTracker";
import {
    completedTrackingStatuses,
    cookingTrackingStatuses,
    ongoingTrackingStatuses,
    OrderTrackerDataFullfillmentStatus,
} from "@/types/OrderTrackerData";
import { useSearchParams } from "react-router-dom";
import MenuOrderSuccessTrackerMap from "./MenuOrderSuccessTrackerMap";
import FrameButton from "../buttons/FrameButton";
import classNames from "classnames";
import { MdImage, MdMap, MdOpenInFull } from "react-icons/md";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { useRestaurant } from "@/hooks/useRestaurant";
import { getScheduledOrderData } from "@/utils/CheckoutDetailsScheduleTime/createScheduleString";

interface props {
    order: MenuOrder;
}

interface statusData {
    title: string;
    subtitle: string;
    stepNumber?: number;
    maxSteps?: number;
}
const prepareTime = 30;
const MenuOrderSuccessTracker: FC<props> = ({ order }) => {
    const isDelivery = order.handoff === HandoffOptions.DELIVERY;
    const [isShowingDropoutImage, setIsShowingDropoutImage] = useState(false);
    const [displayDropoutImage, setDisplayDropoutImage] = useState(false);
    const [searchParams] = useSearchParams();
    const { data: restaurant } = useRestaurant(order.business);
    const restaurantTimeZone = restaurant?.timeZone || moment.tz.guess();
    const { data, isLoading, refetch } = useMenuOrderTracker(
        order.deliveryId,
        searchParams.get("debug")
            ? {
                order: order,
                restaurant: restaurant,
                status: searchParams.get("debug") as OrderTrackerDataFullfillmentStatus,
            }
            : undefined,
    );
    const [currentDate, setCurrentDate] = useState(new Date());
    const trackerFetchIntervalKey = useRef(null);
    const currentTimeIntervalKey = useRef(null);
    const canShowDropoutImage =
        data && completedTrackingStatuses.includes(data.fulfillment.status) && data.order.dropoff_photo;
    useEffect(() => {
        if (isDelivery) {
            trackerFetchIntervalKey.current = setInterval(() => {
                if (!isLoading) {
                    refetch();
                }
            }, 5000);
        }
        return () => {
            if (trackerFetchIntervalKey.current) {
                clearInterval(trackerFetchIntervalKey.current);
            }
        };
    }, [isLoading, isDelivery]);

    useEffect(() => {
        if (!isDelivery) {
            currentTimeIntervalKey.current = setInterval(() => {
                setCurrentDate(new Date());
            }, 1000);
        }
        return () => {
            if (currentTimeIntervalKey.current) {
                clearInterval(currentTimeIntervalKey.current);
            }
        };
    }, [isDelivery]);

    const shouldShowOrderData = () => {
        if (isDelivery) {
            if (!data) {
                return false;
            }
            return [...cookingTrackingStatuses, ...ongoingTrackingStatuses, ...completedTrackingStatuses].includes(data.fulfillment.status);
        }
        return true;
    };

    const getOrderReadyBy = () => {
        if (order.scheduledTime) {
            return moment(getScheduledOrderData(order.scheduledTime, restaurantTimeZone)?.startTime);
        }
        return order.readyBy ? moment(order.readyBy) : null;
    };

    const orderStatusData = useMemo((): statusData => {
        if (isDelivery) {
            const receivingStatus = {
                title: "Receiving Your Order",
                subtitle: "Your order is in the works! Chefs are whipping up your favorite eats.",
            };
            if (!data) {
                return receivingStatus;
            }
            if (cookingTrackingStatuses.includes(data.fulfillment.status)) {
                return {
                    title: "Cooking Up Your Order 🍔",
                    subtitle: "Your order is in the works! Chefs are whipping up your favorite eats.",
                    stepNumber: 1,
                    maxSteps: 3,
                };
            }

            if (ongoingTrackingStatuses.includes(data.fulfillment.status)) {
                return {
                    title: "On the Way to You 🚀",
                    subtitle: "Your order is out for delivery! Track its journey as it makes its way to you.",
                    stepNumber: 2,
                    maxSteps: 3,
                };
            }

            if (completedTrackingStatuses.includes(data.fulfillment.status)) {
                return {
                    title: "Yum, Delivered! 🎉",
                    subtitle: "Your order has arrived! Time to dig in and enjoy your delicious meal.",
                    stepNumber: 3,
                    maxSteps: 3,
                };
            }

            return receivingStatus;
        }

        return {
            title: `📣 Pickup Ready at ${getOrderReadyBy().tz(restaurantTimeZone)
                .format("LT")}!`,
            subtitle: "Your order will be ready for pickup soon! Make sure to arrive on time. Enjoy your meal!",
        };
    }, [data, isDelivery]);

    const getOrderProgress = () => {
        if (!isDelivery && getOrderReadyBy()) {
            const startDate = new Date(order.createdAt);
            const futureDate = getOrderReadyBy().add(prepareTime, "minutes")
                .toDate();
            const elapsedTime = currentDate.getTime() - startDate.getTime();
            const totalTimeSpan = futureDate.getTime() - startDate.getTime();
            const percentageCompletion = elapsedTime / totalTimeSpan * 100;
            return Math.floor(Math.min(Math.max(percentageCompletion, 0), 100));
        }
        return null;
    };

    return (
        <div className="menu-order-success-tracker">
            {shouldShowOrderData() &&
                <div className="menu-order-success-tracker_order-data">
                    <div className="menu-order-success-tracker_order-data_delivery">
                        <h4 className="menu-order-success-tracker_order-data_delivery_title">
                            Estimated {isDelivery ? "Delivery" : "Pickup"} Time
                        </h4>
                        <h4 className="menu-order-success-tracker_order-data_delivery_time">
                            {" "}
                            {getOrderReadyBy().tz(restaurantTimeZone)
                                .format("LT")} -{" "}
                            {getOrderReadyBy().tz(restaurantTimeZone)
                                .add(prepareTime, "minutes")
                                .format("LT")}
                        </h4>
                    </div>
                    <span className="menu-order-success-tracker_order-data_order-chip">
                        <span>Order</span> #{order.pickupCode || order.id}
                    </span>
                </div>
            }
            <MenuSuccessTrackerProgressBar
                progress={getOrderProgress()}
                currentStep={orderStatusData.stepNumber}
                stepsCount={orderStatusData.maxSteps}
            />
            <div className="menu-order-success-tracker_heading">
                <h2 className="menu-order-success-tracker_heading_title">{orderStatusData.title}</h2>
                <p className="menu-order-success-tracker_heading_subtitle">{orderStatusData.subtitle}</p>
            </div>

            {canShowDropoutImage && displayDropoutImage &&
                <Lightbox mainSrc={data.order.dropoff_photo} onCloseRequest={() => setDisplayDropoutImage(false)} />
            }

            {shouldShowOrderData() &&
                <>
                    {canShowDropoutImage &&
                        <div className="menu-order-success-tracker_dropout-switch">
                            <FrameButton
                                onClick={() => setIsShowingDropoutImage(false)}
                                color="purple"
                                className={classNames({
                                    active: !isShowingDropoutImage,
                                })}
                                size="narrow"
                                leftIcon={() => <MdMap />}
                            >
                                Map
                            </FrameButton>
                            <FrameButton
                                onClick={() => setIsShowingDropoutImage(true)}
                                color="purple"
                                className={classNames({
                                    active: isShowingDropoutImage,
                                })}
                                size="narrow"
                                leftIcon={() => <MdImage />}
                            >
                                Image
                            </FrameButton>
                        </div>
                    }
                    <div
                        className={classNames("menu-order-success-tracker_map", {
                            "show-dropout": isShowingDropoutImage,
                        })}
                    >
                        <MenuOrderSuccessTrackerMap order={order} trackerData={data} />
                        {canShowDropoutImage &&
                            <>
                                <img
                                    src={data.order.dropoff_photo}
                                    className="menu-order-success-tracker_map_dropout-img"
                                    alt=""
                                />
                                {isShowingDropoutImage &&
                                    <FrameButton
                                        onClick={() => setDisplayDropoutImage(true)}
                                        color="dark"
                                        className="menu-order-success-tracker_map_expand-button"
                                        size="icon"
                                        leftIcon={() => <MdOpenInFull />}
                                    ></FrameButton>
                                }
                            </>
                        }
                    </div>
                </>
            }
        </div>
    );
};

export default MenuOrderSuccessTracker;
