import {EVMChain, NftRewardMetadata, NftRewardPrize, NftsApi, NftTracker, Token} from "@devour/client";
import {magic, magicPolygon} from "@/utils/magic";
import getConfig from "@/utils/getConfig";
import * as ethers from "ethers";

const burnableNftChains = [
    EVMChain.POLYGON,
    EVMChain.ETHEREUMMAINNET,
];

/**
 * Interface for the response from the Magic relayer API
 */
interface RelayerResponse {
    request_id: string;
    state: string;
    tx_hash: string | null;
    tx_receipt: any | null;
    gas_spent: number | null;
    success: boolean;
    failure_code: string | null;
}

/**
 * Fetches the transaction state from the Magic relayer API and handles retries if necessary.
 */
async function fetchTransactionState(requestId: string): Promise<RelayerResponse | null> {
    await new Promise(resolve => setTimeout(resolve, 5000)); // Wait for 5 seconds before making the request
    const response = await fetch(`https://gas-api.magic.link/v1/relayer/get-request-state?request_id=${requestId}`);
    if (!response?.ok) {
        throw new Error("Failed to fetch transaction state");
    }

    const relayerResponse: RelayerResponse = await response.json();
    if (relayerResponse.success && !relayerResponse.tx_hash && !relayerResponse.failure_code) {
        // If the transaction is still pending and there's no error, retry after 5 seconds
        return fetchTransactionState(requestId);
    } else if (!relayerResponse.success || relayerResponse.failure_code) {
        // If there's an error or a failure code, throw an error
        throw new Error("Transaction failed or encountered an error: " + relayerResponse.request_id);
    }

    return relayerResponse;
}


/**
 * Burns a reward NFT and returns the prize won.
 * @param nftId
 * @param nftRewardMetadata
 * @param fullToken
 */
export async function redeemRewardNft(nftId: number, nftRewardMetadata: NftRewardMetadata, fullToken: Token): Promise<NftRewardPrize> {

    const nftTracker: NftTracker = nftRewardMetadata.nftTracker;

    if (!burnableNftChains.includes(nftTracker.evmChain)) {
        throw new Error("Only Polygon and Ethereum Mainnet chains supported at this time.");
    }

    // Check if there's any prizes remaining, this is to prevent the NFT from being destroyed if there's no prizes left to be won
    await new NftsApi(getConfig(fullToken)).validateEligiblePrizesRemaining({
        id: nftRewardMetadata.id,
    });

    // If so then proceed to the burn
    const provider = new ethers.BrowserProvider(nftTracker.evmChain === EVMChain.POLYGON
        ? magicPolygon.rpcProvider
        : magic.rpcProvider);
    const signer = await provider.getSigner();
    const contract = new ethers.Contract(nftTracker.contractAddress, JSON.parse(nftRewardMetadata.contractAbi), signer);

    // Burn the NFT
    let hash: string;

    try {
        if (nftTracker.evmChain === EVMChain.ETHEREUMMAINNET) {
            const tx = await contract.burn(nftId);
            const receipt = await tx.wait();
            hash = receipt.hash;
        } else if (nftTracker.evmChain === EVMChain.POLYGON) {
            const transaction = await contract.burn.populateTransaction(nftId);
            const gaslessRequest = await magicPolygon.wallet.sendGaslessTransaction(signer.address, transaction);

            // Poll to get transaction hash when it is complete
            const transactionState = await fetchTransactionState(gaslessRequest.request_id);
            hash = transactionState.tx_hash;
        }
    } catch (error) {
        throw new Error("An error occurred while burning the NFT: " + error.message);
    }


    // Get the prize won from the reward
    const prizeWon = await new NftsApi(getConfig(fullToken)).redeemNftReward({
        redeemNftRewardBody: {
            transactionHash: hash,
            nftRewardMetadataId: nftRewardMetadata.id,
        },
    });

    return prizeWon.nftPrize;
}