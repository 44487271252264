/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ConfigResponse
 */
export interface ConfigResponse {
    /**
     * 
     * @type {number}
     * @memberof ConfigResponse
     */
    distanceDeliveryMax: number;
    /**
     * 
     * @type {number}
     * @memberof ConfigResponse
     */
    distancePickupMax: number;
}

export function ConfigResponseFromJSON(json: any): ConfigResponse {
    return ConfigResponseFromJSONTyped(json, false);
}

export function ConfigResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConfigResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'distanceDeliveryMax': json['distanceDeliveryMax'],
        'distancePickupMax': json['distancePickupMax'],
    };
}

export function ConfigResponseToJSON(value?: ConfigResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'distanceDeliveryMax': value.distanceDeliveryMax,
        'distancePickupMax': value.distancePickupMax,
    };
}


