/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiError,
    ApiErrorFromJSON,
    ApiErrorToJSON,
    BurgerStackRequestBody,
    BurgerStackRequestBodyFromJSON,
    BurgerStackRequestBodyToJSON,
    CandyBlastRequestBody,
    CandyBlastRequestBodyFromJSON,
    CandyBlastRequestBodyToJSON,
    GameDataBody,
    GameDataBodyFromJSON,
    GameDataBodyToJSON,
    GameEventRewardResponse,
    GameEventRewardResponseFromJSON,
    GameEventRewardResponseToJSON,
    GetCompletedLevelsResponse,
    GetCompletedLevelsResponseFromJSON,
    GetCompletedLevelsResponseToJSON,
    GetGameDataResponse,
    GetGameDataResponseFromJSON,
    GetGameDataResponseToJSON,
    InTheWeedsRequestBody,
    InTheWeedsRequestBodyFromJSON,
    InTheWeedsRequestBodyToJSON,
    PizzaCafeRequestBody,
    PizzaCafeRequestBodyFromJSON,
    PizzaCafeRequestBodyToJSON,
    RallyChampionRequestBody,
    RallyChampionRequestBodyFromJSON,
    RallyChampionRequestBodyToJSON,
} from '../models';

export interface BurgerStackRequest {
    burgerStackRequestBody?: BurgerStackRequestBody;
}

export interface CandyBlastRequest {
    candyBlastRequestBody?: CandyBlastRequestBody;
}

export interface GetCompletedLevelsRequest {
    id: string;
}

export interface InTheWeedsRequest {
    inTheWeedsRequestBody?: InTheWeedsRequestBody;
}

export interface LoadRequest {
    gameId: string;
}

export interface PizzaCafeRequest {
    pizzaCafeRequestBody?: PizzaCafeRequestBody;
}

export interface RallyChampionRequest {
    rallyChampionRequestBody?: RallyChampionRequestBody;
}

export interface SaveRequest {
    gameDataBody?: GameDataBody;
}

/**
 * GamesApi - interface
 * @export
 * @interface GamesApiInterface
 */
export interface GamesApiInterface {
    /**
     * Used for tracking and rewarding users for playing burger stack.
     * @param {BurgerStackRequestBody} [burgerStackRequestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamesApiInterface
     */
    burgerStackRaw(requestParameters: BurgerStackRequest): Promise<runtime.ApiResponse<GameEventRewardResponse>>;

    /**
     * Used for tracking and rewarding users for playing burger stack.
     */
    burgerStack(requestParameters: BurgerStackRequest): Promise<GameEventRewardResponse>;

    /**
     * Used for tracking and rewarding users for playing candy blast.
     * @param {CandyBlastRequestBody} [candyBlastRequestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamesApiInterface
     */
    candyBlastRaw(requestParameters: CandyBlastRequest): Promise<runtime.ApiResponse<GameEventRewardResponse>>;

    /**
     * Used for tracking and rewarding users for playing candy blast.
     */
    candyBlast(requestParameters: CandyBlastRequest): Promise<GameEventRewardResponse>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamesApiInterface
     */
    getCompletedLevelsRaw(requestParameters: GetCompletedLevelsRequest): Promise<runtime.ApiResponse<GetCompletedLevelsResponse>>;

    /**
     */
    getCompletedLevels(requestParameters: GetCompletedLevelsRequest): Promise<GetCompletedLevelsResponse>;

    /**
     * Used for tracking and rewarding users for playing in the weeds.
     * @param {InTheWeedsRequestBody} [inTheWeedsRequestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamesApiInterface
     */
    inTheWeedsRaw(requestParameters: InTheWeedsRequest): Promise<runtime.ApiResponse<GameEventRewardResponse>>;

    /**
     * Used for tracking and rewarding users for playing in the weeds.
     */
    inTheWeeds(requestParameters: InTheWeedsRequest): Promise<GameEventRewardResponse>;

    /**
     * 
     * @param {string} gameId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamesApiInterface
     */
    loadRaw(requestParameters: LoadRequest): Promise<runtime.ApiResponse<GetGameDataResponse>>;

    /**
     */
    load(requestParameters: LoadRequest): Promise<GetGameDataResponse>;

    /**
     * Used for tracking and rewarding users for playing pizza cafe.
     * @param {PizzaCafeRequestBody} [pizzaCafeRequestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamesApiInterface
     */
    pizzaCafeRaw(requestParameters: PizzaCafeRequest): Promise<runtime.ApiResponse<GameEventRewardResponse>>;

    /**
     * Used for tracking and rewarding users for playing pizza cafe.
     */
    pizzaCafe(requestParameters: PizzaCafeRequest): Promise<GameEventRewardResponse>;

    /**
     * Used for tracking and rewarding users for playing rally champion.
     * @param {RallyChampionRequestBody} [rallyChampionRequestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamesApiInterface
     */
    rallyChampionRaw(requestParameters: RallyChampionRequest): Promise<runtime.ApiResponse<GameEventRewardResponse>>;

    /**
     * Used for tracking and rewarding users for playing rally champion.
     */
    rallyChampion(requestParameters: RallyChampionRequest): Promise<GameEventRewardResponse>;

    /**
     * Used by games to save user game data
     * @param {GameDataBody} [gameDataBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamesApiInterface
     */
    saveRaw(requestParameters: SaveRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Used by games to save user game data
     */
    save(requestParameters: SaveRequest): Promise<void>;

    /**
     * Used for tracking and rewarding users for daily Spin.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamesApiInterface
     */
    spin2WinRaw(): Promise<runtime.ApiResponse<GameEventRewardResponse>>;

    /**
     * Used for tracking and rewarding users for daily Spin.
     */
    spin2Win(): Promise<GameEventRewardResponse>;

}

/**
 * no description
 */
export class GamesApi extends runtime.BaseAPI implements GamesApiInterface {

    /**
     * Used for tracking and rewarding users for playing burger stack.
     */
    async burgerStackRaw(requestParameters: BurgerStackRequest): Promise<runtime.ApiResponse<GameEventRewardResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/game_events/burger-stack`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BurgerStackRequestBodyToJSON(requestParameters.burgerStackRequestBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GameEventRewardResponseFromJSON(jsonValue));
    }

    /**
     * Used for tracking and rewarding users for playing burger stack.
     */
    async burgerStack(requestParameters: BurgerStackRequest): Promise<GameEventRewardResponse> {
        const response = await this.burgerStackRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used for tracking and rewarding users for playing candy blast.
     */
    async candyBlastRaw(requestParameters: CandyBlastRequest): Promise<runtime.ApiResponse<GameEventRewardResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/game_events/candy-blast`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CandyBlastRequestBodyToJSON(requestParameters.candyBlastRequestBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GameEventRewardResponseFromJSON(jsonValue));
    }

    /**
     * Used for tracking and rewarding users for playing candy blast.
     */
    async candyBlast(requestParameters: CandyBlastRequest): Promise<GameEventRewardResponse> {
        const response = await this.candyBlastRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getCompletedLevelsRaw(requestParameters: GetCompletedLevelsRequest): Promise<runtime.ApiResponse<GetCompletedLevelsResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCompletedLevels.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/game_events/get-completed-levels/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCompletedLevelsResponseFromJSON(jsonValue));
    }

    /**
     */
    async getCompletedLevels(requestParameters: GetCompletedLevelsRequest): Promise<GetCompletedLevelsResponse> {
        const response = await this.getCompletedLevelsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used for tracking and rewarding users for playing in the weeds.
     */
    async inTheWeedsRaw(requestParameters: InTheWeedsRequest): Promise<runtime.ApiResponse<GameEventRewardResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/game_events/in-the-weeds`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InTheWeedsRequestBodyToJSON(requestParameters.inTheWeedsRequestBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GameEventRewardResponseFromJSON(jsonValue));
    }

    /**
     * Used for tracking and rewarding users for playing in the weeds.
     */
    async inTheWeeds(requestParameters: InTheWeedsRequest): Promise<GameEventRewardResponse> {
        const response = await this.inTheWeedsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async loadRaw(requestParameters: LoadRequest): Promise<runtime.ApiResponse<GetGameDataResponse>> {
        if (requestParameters.gameId === null || requestParameters.gameId === undefined) {
            throw new runtime.RequiredError('gameId','Required parameter requestParameters.gameId was null or undefined when calling load.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/game_events/load/{gameId}`.replace(`{${"gameId"}}`, encodeURIComponent(String(requestParameters.gameId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetGameDataResponseFromJSON(jsonValue));
    }

    /**
     */
    async load(requestParameters: LoadRequest): Promise<GetGameDataResponse> {
        const response = await this.loadRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used for tracking and rewarding users for playing pizza cafe.
     */
    async pizzaCafeRaw(requestParameters: PizzaCafeRequest): Promise<runtime.ApiResponse<GameEventRewardResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/game_events/pizza-cafe`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PizzaCafeRequestBodyToJSON(requestParameters.pizzaCafeRequestBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GameEventRewardResponseFromJSON(jsonValue));
    }

    /**
     * Used for tracking and rewarding users for playing pizza cafe.
     */
    async pizzaCafe(requestParameters: PizzaCafeRequest): Promise<GameEventRewardResponse> {
        const response = await this.pizzaCafeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used for tracking and rewarding users for playing rally champion.
     */
    async rallyChampionRaw(requestParameters: RallyChampionRequest): Promise<runtime.ApiResponse<GameEventRewardResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/game_events/rally-champ`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RallyChampionRequestBodyToJSON(requestParameters.rallyChampionRequestBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GameEventRewardResponseFromJSON(jsonValue));
    }

    /**
     * Used for tracking and rewarding users for playing rally champion.
     */
    async rallyChampion(requestParameters: RallyChampionRequest): Promise<GameEventRewardResponse> {
        const response = await this.rallyChampionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used by games to save user game data
     */
    async saveRaw(requestParameters: SaveRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/game_events/save`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: GameDataBodyToJSON(requestParameters.gameDataBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Used by games to save user game data
     */
    async save(requestParameters: SaveRequest): Promise<void> {
        await this.saveRaw(requestParameters);
    }

    /**
     * Used for tracking and rewarding users for daily Spin.
     */
    async spin2WinRaw(): Promise<runtime.ApiResponse<GameEventRewardResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/game_events/spin2win`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GameEventRewardResponseFromJSON(jsonValue));
    }

    /**
     * Used for tracking and rewarding users for daily Spin.
     */
    async spin2Win(): Promise<GameEventRewardResponse> {
        const response = await this.spin2WinRaw();
        return await response.value();
    }

}
