import {useQueries, useQuery} from "@tanstack/react-query";
import {BusinessesApi, Restaurant, RestaurantSearchResult} from "@devour/client";
import getConfig from "../utils/getConfig";

async function fetchRestaurant(restaurantId: string, placeId?: string): Promise<Restaurant> {
    if (!restaurantId) {
        return;
    }

    return await new BusinessesApi(getConfig()).getRestaurant({
        id: restaurantId,
        placeId: placeId,
    });
}

function restaurantQuery(restaurantId: string, placeId?: string) {
    return {
        queryKey: [
            "restaurant",
            restaurantId,
            placeId,
        ],
        queryFn: () => fetchRestaurant(restaurantId, placeId),
        keepPreviousData: true,
        staleTime: 60000,
        refetchOnWindowFocus: false,
        enabled: !!restaurantId,
    } as const;
}

export function useRestaurant(restaurantId: string, placeId?: string) {
    // const queryClient = useQueryClient();

    return useQuery(restaurantQuery(restaurantId, placeId));
}

export function useRestaurants(restaurants: RestaurantSearchResult[], placeId?: string) {
    const queries = restaurants?.map(r => restaurantQuery(r._id, placeId));

    return useQueries({
        queries: queries ?? [],
    });
}
