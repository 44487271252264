/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MenuOrderStatus,
    MenuOrderStatusFromJSON,
    MenuOrderStatusFromJSONTyped,
    MenuOrderStatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface MenuOrderLog
 */
export interface MenuOrderLog {
    /**
     * 
     * @type {number}
     * @memberof MenuOrderLog
     */
    date: number;
    /**
     * 
     * @type {MenuOrderStatus}
     * @memberof MenuOrderLog
     */
    status: MenuOrderStatus;
}

export function MenuOrderLogFromJSON(json: any): MenuOrderLog {
    return MenuOrderLogFromJSONTyped(json, false);
}

export function MenuOrderLogFromJSONTyped(json: any, ignoreDiscriminator: boolean): MenuOrderLog {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'date': json['date'],
        'status': MenuOrderStatusFromJSON(json['status']),
    };
}

export function MenuOrderLogToJSON(value?: MenuOrderLog | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date': value.date,
        'status': MenuOrderStatusToJSON(value.status),
    };
}


