
// Convert scheduled unix value to a readable string
import moment from "moment-timezone";


export function getScheduledOrderData(unix: number, timeZone: string) {
    const startTime = moment.unix(unix).tz(timeZone);
    const endTime = moment(startTime).add(30, "minutes");
    return {startTime,
        endTime};
}

/*
 * If mobile is true, string format will be: "Mon, 2:00 - 2:30 PM"
 * Else: "Mon, 2:00 PM - 2:30 PM"
 */
export function createScheduleString(unix: number, timeZone: string, mobile: boolean): string {
    const { endTime, startTime } = getScheduledOrderData(unix, timeZone);
    if (mobile) {
        // Extract the "AM/PM" part from the end time to use it once
        const endAmPm = endTime.format("A");

        // Format start and end times without the "AM/PM" part and append it once at the end
        return `${startTime.format("ddd, h:mm")} - ${endTime.format("h:mm")} ${endAmPm}`;
    }
    return `${startTime.format("ddd, h:mm A")} - ${endTime.format("h:mm A")}`;

}
