import {FC, useEffect, useState} from "react";
import {UpdateNotificationsBody, UsersApi} from "@devour/client";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {addError, decrementLoading, incrementLoading, updateCurrentUser} from "../redux/meta/metaActions";
import getConfig from "../utils/getConfig";
import {useGetUserProfile} from "@/hooks/useGetUserProfile";
import ToggleSwitch from "./ToggleSwitch";

const UserNotificationsEdit: FC = () => {
    const dispatch = useDispatch();

    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const {user} = useSelector((store: IStore) => store.metaStore.currentUser);
    const [
        formValues,
        setFormValues,
    ] = useState<UpdateNotificationsBody>({
        notificationMarketing: user.notificationMarketing || false,
        notificationMenuOrderStatus: user.notificationMenuOrderStatus || false,
    });

    const {refetch: refetchUserProfile} = useGetUserProfile(fullToken);

    useEffect(() => {
        submit().then();
    }, [formValues]);

    async function submit() {
        dispatch(incrementLoading());
        try {
            await new UsersApi(getConfig(fullToken)).updateNotifications({
                updateNotificationsBody: formValues,
            });

            // Just a temp fix here -> the best way is to replace the User varaible from store.metaStore.currentUser with useGetUserProfile
            const userRes = await new UsersApi(getConfig(fullToken)).getProfile();
            await dispatch(updateCurrentUser(userRes));
            refetchUserProfile();
        } catch (err) {
            dispatch(await addError(err));
        }
        dispatch(decrementLoading());
    }

    function onOrderStatusNotification() {
        setFormValues({
            ...formValues,
            notificationMenuOrderStatus: !formValues.notificationMenuOrderStatus,
        });
    }

    function onMarketingNotification() {
        setFormValues({
            ...formValues,
            notificationMarketing: !formValues.notificationMarketing,
        });
    }

    return (
        <div className="user-notifications-edit">
            <div className="user-notifications-edit_row">
                <div className="user-notifications-edit_row_description">
                    <p>
                        <strong>Receive order status updates</strong>
                    </p>
                    <p>
						If enabled, we will send you push notifications updating you on the status of your orders
                    </p>
                </div>
                <ToggleSwitch checked={user.notificationMenuOrderStatus} onChange={onOrderStatusNotification}/>
            </div>
            <div className="user-notifications-edit_row">
                <div className="user-notifications-edit_row_description">
                    <p>
                        <strong>Receive promotion and marketing updates</strong>
                    </p>
                    <p>
						If enabled, we will send you push notifications updating you on new promotions from us
                    </p>
                </div>
                <ToggleSwitch checked={user.notificationMarketing} onChange={onMarketingNotification}/>
            </div>
        </div>
    );
};

export default UserNotificationsEdit;
