/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum BonusDpayOption {
    FLATAMOUNT = 'FLAT_AMOUNT',
    MULTIPLEAMOUNT = 'MULTIPLE_AMOUNT'
}

export function BonusDpayOptionFromJSON(json: any): BonusDpayOption {
    return BonusDpayOptionFromJSONTyped(json, false);
}

export function BonusDpayOptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): BonusDpayOption {
    return json as BonusDpayOption;
}

export function BonusDpayOptionToJSON(value?: BonusDpayOption | null): any {
    return value as any;
}

