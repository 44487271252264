import {useContext, useEffect, useRef} from "react";
import {BrandMapContext, BrandMapStates} from "@/pages/brandMap/context/BrandMapContext";
import {useParams} from "react-router";
import {useGetBrandMap} from "@/hooks/useGetBrandMap";
import classNames from "classnames";
import {BrandMapColorTheme} from "@devour/client";

export default function BrandMapProgressBar() {
    const {slug} = useParams<{ slug: string }>();
    const {data: brandMap} = useGetBrandMap(slug);
    const {brandMapState} = useContext(BrandMapContext);
    const containerRef = useRef(null);
    const isDefaultState = brandMapState === BrandMapStates.NOT_INITIATED;
    const isLocationActive = brandMapState >= BrandMapStates.SELECT_LOCATION || isDefaultState;
    const isOrderActive = brandMapState >= BrandMapStates.ORDER_IN_PROGRESS || isDefaultState;
    const isDarkText = brandMap?.colorTheme === BrandMapColorTheme.DARK;

    useEffect(() => {
        if (brandMap?.colorBackground) {
            containerRef.current?.style.setProperty("--map-background-color", brandMap?.colorBackground);
        }
    }, [brandMap?.colorBackground]);

    function getIconImageUrl(state: BrandMapStates, isActive: boolean): string {
        let imageFilename: string;
        switch (state) {
            case BrandMapStates.SELECT_PROMOTION:
                imageFilename = "brand-progress-promo";
                break;
            case BrandMapStates.SELECT_LOCATION:
                imageFilename = "brand-progress-location";
                break;
            case BrandMapStates.ORDER_IN_PROGRESS:
                imageFilename = "brand-progress-order";
                break;
            default:
                return;
        }

        if (isActive && isDarkText || !isActive && !isDarkText) {
            imageFilename += "-dark";
        }
        return `${import.meta.env.VITE_CDN_URL}/images/${imageFilename}.webp`;
    }
    return (
        <div className="brand-map-progress" ref={containerRef}>
            {isDefaultState
                ? <h3>How does it work?</h3>
                : <h3>Order from DevourGO</h3>
            }
            <div className={classNames("brand-map-progress_steps", {
                "in-progress": !isDefaultState,
            })}>
                <div className="brand-map-progress_step is-active">
                    <img
                        src={getIconImageUrl(BrandMapStates.SELECT_PROMOTION, true)}
                        alt="Progress Promo"/>
                    <h5 className="brand-map-progress_step_label is-active">Apply a Promotion(s)</h5>
                </div>

                <div
                    className={classNames("brand-map-progress_step", {
                        "is-active": isLocationActive,
                    })}>
                    <div className="brand-map-progress_step_bar-container">
                        <div className="brand-map-progress_step_bar" />
                    </div>
                    <img
                        src={getIconImageUrl(BrandMapStates.SELECT_LOCATION, isLocationActive)}
                        alt="Progress Promo"/>
                    <h5 className="brand-map-progress_step_label">Select a Location</h5>
                </div>

                <div className={classNames("brand-map-progress_step", {
                    "is-active": isOrderActive,
                })}>
                    <div className="brand-map-progress_step_bar-container">
                        <div className="brand-map-progress_step_bar"/>
                    </div>
                    <img
                        src={getIconImageUrl(BrandMapStates.ORDER_IN_PROGRESS, isOrderActive)}
                        alt="Progress Promo"/>
                    <h5 className="brand-map-progress_step_label">Make an Order</h5>
                </div>
            </div>

        </div>
    );
}