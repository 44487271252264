import React, {ReactElement, ReactNode, useEffect, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import classNames from "classnames";
import {IStore} from "../../../redux/defaultStore";
import {decrementModalCount, incrementModalCount} from "../../../redux/meta/metaActions";
import variables from "../../../style/variables/scssVariables";
import {getScrollbarWidth, isDocumentOverflow} from "../../../utils/isDocumentOverflow";

// Get the modal timing variable from the css exports
const modalTiming: number = parseInt(variables["frame-one-modal-transition-timing"]);
const initialModalzIndex: number = 50;

interface StateProps {
    activeModals: number;
}

interface Props {
    isOpen: boolean;
    children: ReactNode;
    toggle?: () => void;
    size?: "xs" | "xs2" | "sm" | "sm2" | "md" | "lg" | "xl" | "max";
    contentClassName?: string;
    containerClassName?: string;
    disableOverlayDismiss?: boolean;
    setCloseModal?: React.Dispatch<React.SetStateAction<() => void>>;
}

function FrameOneModal(props: FrameOneModalProps): ReactElement {

    const {isOpen, activeModals, dispatch, setCloseModal} = props;
    const [
        lock,
        setLock,
    ] = useState(false);
    const [
        zIndex,
        setZIndex,
    ] = useState(initialModalzIndex); // Interim solution for nested modals to handle their render order without requiring config for each implementation.
    const [
        intermediateController,
        setIntermediateController,
    ] = useState(undefined); // used as an intermediary to help control the animations & visual state of the modal.
    const [
        shader,
        setShader,
    ] = useState(undefined); // helps control the background color of the overlay.
    const [
        forceCloser,
        setForceCloser,
    ] = useState(true); // used on a delay to let the animation play out when the modal closes before the "display" is set back to "none" to prevent cutting the animation short.

    useEffect(() => {
        if (setCloseModal) {
            setCloseModal(() => {
                return () => {
                    props.toggle();
                };
            });
        }
    }, []);

    useEffect(() => {
        if (isOpen && !lock) {
            setLock(true);
            setIntermediateController(isOpen);
            setForceCloser(false);
            dispatch(incrementModalCount());
        } else if (isOpen === false && lock) {
            setLock(false);
            dispatch(decrementModalCount());
            setShader(false);
        }
    }, [
        isOpen,
        lock,
    ]);

    useEffect(() => {
        if (isOpen && !lock) {
            setZIndex(initialModalzIndex + activeModals);
        }
    }, [activeModals]);

    useEffect(() => {
        function turnOnShader(): void {
            setShader(true);
        }

        function turnOnForceCloser(): void {
            setForceCloser(true);
        }

        if (intermediateController) {
            setTimeout(() => {
                turnOnShader();
            }, 100);
        } else if (intermediateController === false) {
            setTimeout(() => {
                turnOnForceCloser();
            }, modalTiming);
        }
    }, [intermediateController]);

    useEffect(() => {
        function turnOffIntermediate(): void {
            setIntermediateController(false);
        }

        if (shader === false) {
            setTimeout(() => {
                turnOffIntermediate();
            }, 100);
        }
    }, [shader]);

    useEffect(() => {

        // Disables all scrolling until there's no active modals.
        // if you're not using some kind of toggle to close your modal
        // which handles the modal count, you need to manually decrement
        // via props.dispatch.

        if (props.activeModals > 0) {
            document.body.style.overflow = "hidden";
            if (isDocumentOverflow() && getScrollbarWidth() > 0) {
                document.body.style.paddingRight = "1rem"; // only apply the padding if there's a scrollbar
            }
        } else {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0";
        }

    }, [props.activeModals]);


    function toggleHelper(): void {
        if (props.toggle) {
            props.toggle();
        }
    }

    if (forceCloser) {
        return null;
    }

    return (
        <div
            style={{zIndex}}
            className={classNames("frame-one-modal-container", {
                "frame-one-modal-container_closed": !intermediateController && forceCloser,
                "frame-one-modal-container_open": intermediateController,
                "frame-one-modal-container_shader": shader,
                [props.containerClassName]: props.containerClassName,

            })}
        >
            <div className="frame-one-modal-container_inner">
                <div className="frame-one-modal-container_inner_spacer"/>

                <div
                    className={classNames("frame-one-modal", "frame-one-modal-container_inner_content", `frame-one-modal-container_inner_content_size-${props.size}`, props.contentClassName, {
                        "frame-one-modal-container_inner_content_closed": !shader,
                        "frame-one-modal-container_inner_content_open": shader,
                    })}
                >
                    {props.children}
                </div>

                <div className="frame-one-modal-container_inner_spacer"/>

                <div
                    onClick={props.disableOverlayDismiss
                        ? null
                        : toggleHelper}
                    className="frame-one-modal-container_inner_clicker"
                />
            </div>
        </div>
    );
}

function connector() {
    return connect((store: IStore, props: Props): StateProps & Props => {
        return {
            activeModals: store.metaStore.activeModals,
            ...props,
        };
    });
}

FrameOneModal.defaultProps = {
    size: "xs",
} as Props;

type FrameOneModalProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(FrameOneModal);
