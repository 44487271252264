/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InlineResponse20012
 */
export interface InlineResponse20012 {
    /**
     * 
     * @type {boolean}
     * @memberof InlineResponse20012
     */
    isCancellable: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InlineResponse20012
     */
    hasCancelled: boolean;
}

export function InlineResponse20012FromJSON(json: any): InlineResponse20012 {
    return InlineResponse20012FromJSONTyped(json, false);
}

export function InlineResponse20012FromJSONTyped(json: any, ignoreDiscriminator: boolean): InlineResponse20012 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isCancellable': json['isCancellable'],
        'hasCancelled': json['hasCancelled'],
    };
}

export function InlineResponse20012ToJSON(value?: InlineResponse20012 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isCancellable': value.isCancellable,
        'hasCancelled': value.hasCancelled,
    };
}


