/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum SortOrder {
    Asc = 'asc',
    Desc = 'desc'
}

export function SortOrderFromJSON(json: any): SortOrder {
    return SortOrderFromJSONTyped(json, false);
}

export function SortOrderFromJSONTyped(json: any, ignoreDiscriminator: boolean): SortOrder {
    return json as SortOrder;
}

export function SortOrderToJSON(value?: SortOrder | null): any {
    return value as any;
}

