/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetNftTrackersResponseAllOf,
    GetNftTrackersResponseAllOfFromJSON,
    GetNftTrackersResponseAllOfFromJSONTyped,
    GetNftTrackersResponseAllOfToJSON,
    NftTracker,
    NftTrackerFromJSON,
    NftTrackerFromJSONTyped,
    NftTrackerToJSON,
    PaginatedResponse,
    PaginatedResponseFromJSON,
    PaginatedResponseFromJSONTyped,
    PaginatedResponseToJSON,
    PaginationInfo,
    PaginationInfoFromJSON,
    PaginationInfoFromJSONTyped,
    PaginationInfoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetNftTrackersResponse
 */
export interface GetNftTrackersResponse extends PaginatedResponse {
    /**
     * 
     * @type {Array<NftTracker>}
     * @memberof GetNftTrackersResponse
     */
    nftTrackers: Array<NftTracker>;
}

export function GetNftTrackersResponseFromJSON(json: any): GetNftTrackersResponse {
    return GetNftTrackersResponseFromJSONTyped(json, false);
}

export function GetNftTrackersResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetNftTrackersResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...PaginatedResponseFromJSONTyped(json, ignoreDiscriminator),
        'nftTrackers': ((json['nftTrackers'] as Array<any>).map(NftTrackerFromJSON)),
    };
}

export function GetNftTrackersResponseToJSON(value?: GetNftTrackersResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...PaginatedResponseToJSON(value),
        'nftTrackers': ((value.nftTrackers as Array<any>).map(NftTrackerToJSON)),
    };
}


