import {ReactElement, useContext, useState, MouseEvent, ButtonHTMLAttributes} from "react";
import {
    PreviousMenuOrderItem,
    GetMenuResponse,
} from "@devour/client";
import classNames from "classnames";
import FrameButton from "../../../components/buttons/FrameButton";
import MenuItemCustomizationModal from "../../../components/modals/MenuItemCustomizationModal";
import {FaAngleDown} from "react-icons/fa";
import MenuItemCardNftGroupings from "./MenuItemCardNftGroupings";
import {isDesktop} from "react-device-detect";
import {RestaurantContext} from "../context/RestaurantContext";
import MenuItemCardAddButton from "./MenuItemCardAddButton";
import {htmlDecode} from "@/utils/htmlDecode";
import {findMenuItemResponse} from "@/utils/validateMenuOrderItem";
import {calculateTotalLoop} from "@/utils/calculateMenuOrderTotal";
import moment from "moment-timezone";

interface Props {
    placeId: string;
    restaurantMenu: GetMenuResponse;
    menuOrderItem: PreviousMenuOrderItem;
}

const calculateMenuOrderCustomizations = (customizations) => {
    return customizations.map((c) => {
        // Recursively process sub-customizations if they exist
        const updatedSubCustomizations = c.customizations
            ? calculateMenuOrderCustomizations(c.customizations)
            : [];

        return {
            ...c,
            menuCustomizationItemId: c.externalId || c.menuCustomizationItemId,
            customizations: updatedSubCustomizations,
        };
    });
};

function OrderAgainCard(props: Props): ReactElement {

    const {restaurant} = useContext(RestaurantContext);
    const [
        showCustomizationModal,
        setShowCustomizationModal,
    ] = useState<boolean>(false);
    const [
        lockNftGroupings,
        setLockNftGroupings,
    ] = useState<boolean>(false);
    const [
        hoverNftGroupings,
        setHoverNftGroupings,
    ] = useState<boolean>(false);
    const menuItem = findMenuItemResponse(props.menuOrderItem, props.restaurantMenu.menus);
    const calculatedMenuOrderCustomizations = calculateMenuOrderCustomizations(props.menuOrderItem.customizations);
    const calculateMenuOrderItem: PreviousMenuOrderItem = {
        ...props.menuOrderItem,
        menuItemId: props.menuOrderItem.externalId || props.menuOrderItem.menuItemId,
        quantity: 1,
        customizations: calculatedMenuOrderCustomizations,
    };

    function toggleCustomizationModal(): void {
        setShowCustomizationModal(s => !s);
    }

    function toggleLockNftGroupings(event: MouseEvent<HTMLButtonElement>): void {
        setLockNftGroupings(s => !s);
        event.stopPropagation();
    }

    function cardOnClick(event: MouseEvent<HTMLDivElement>): void {
        toggleCustomizationModal();
        event.stopPropagation();
    }

    function toggleHoverNftGroupings(): void {
        if (isDesktop) {
            setHoverNftGroupings(s => !s);
        }
    }

    if (!menuItem || !props.restaurantMenu) {
        return null;
    }

    return (
        <>
            {restaurant && <MenuItemCustomizationModal
                isOpen={showCustomizationModal}
                placeId={props.placeId}
                restaurantId={restaurant.id}
                restaurantMenu={props.restaurantMenu}
                menuItem={menuItem}
                onClose={toggleCustomizationModal}
                defaultValues={calculateMenuOrderItem}
            />}

            <div
                className={classNames("menu-item-card", {
                    "is-enabled": restaurant.isOpen && menuItem.isEnabled,
                    "is-lock-nft": lockNftGroupings,
                    "is-hover-nft": hoverNftGroupings,
                })}
                onClick={cardOnClick}
            >

                <div className="menu-item-card_left">
                    {menuItem.nftGroupings?.length > 0 &&
												<div className="menu-item-card_secret-item">
												    <FrameButton
												        <ButtonHTMLAttributes<HTMLButtonElement>>
												        onClick={toggleLockNftGroupings}
												        color="purple"
												        size="pill"
												        forwardProps={{
												            onMouseEnter: toggleHoverNftGroupings,
												            onMouseLeave: toggleHoverNftGroupings,
												        }}
												    >
																Secret
												    </FrameButton>
												</div>
                    }
                    <div className="menu-item-card_header">
                        <div className="menu-item-card_name">
                            {htmlDecode(menuItem?.name)}
                        </div>
                        {menuItem.nftGroupings?.length > 0 &&
														<button
														    title="Secret item NFT requirements"
														    className="reset-button"
														    onClick={toggleLockNftGroupings}
														    onMouseEnter={toggleHoverNftGroupings}
														    onMouseLeave={toggleHoverNftGroupings}
														>
														    <FaAngleDown/>
														</button>
                        }
                    </div>

                    <div className="menu-item-card_description">
                        {props.menuOrderItem.customizations?.length
                            ? props.menuOrderItem.customizations.map((customization, index) => {
                                return (
                                    <span key={customization.menuCustomizationItemId}>
                                        {htmlDecode(customization?.name)}
                                        {index + 1 < props.menuOrderItem.customizations.length && ", "}
                                    </span>
                                );
                            })
                            : htmlDecode(menuItem.description)}
                    </div>

                    <div className={classNames("menu-item-card_price", {
                        "is-free": calculateTotalLoop([calculateMenuOrderItem], 1).subtotal <= 0,
                    })}
                    >
												${calculateTotalLoop([calculateMenuOrderItem], 1).subtotal.toFixed(2)}
                    </div>

                    <div className="menu-item-card_date">
												Last ordered on {moment(props.menuOrderItem.updatedAt).format("MMM D YYYY")}
                    </div>

                    <MenuItemCardNftGroupings
                        menuItem={menuItem}
                    />

                </div>

                <div className="menu-item-card_right">
                    {menuItem.images?.length > 0 &&
												<div className="menu-item-card_image">
												    <div className="menu-item-card_image_inner">
												        <img
												            src={menuItem?.images[0].url}
												            alt={menuItem?.name}
												            onError={({currentTarget}) => {
												                currentTarget.onerror = null; // prevents looping
												                currentTarget.src = `${import.meta.env.VITE_CDN_URL}/images/empty.png`;
												            }}
												        />
												    </div>
												</div>
                    }

                    <MenuItemCardAddButton
                        menuItem={menuItem}
                        setShowCustomizationModal={setShowCustomizationModal}
                        placeId={props.placeId}
                        defaultQuickAddItem={calculateMenuOrderItem}
                    />
                </div>

            </div>
        </>
    );
}

export default OrderAgainCard;
