import {ReactElement} from "react";
import {isDesktop} from "react-device-detect";
import ProgressBar from "@ramonak/react-progress-bar";
import {useSelector} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import useThemePreference from "@/hooks/useThemePreference";

function GoVipExperienceBar(): ReactElement {

    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const accountLevel = useSelector((store: IStore) => store.metaStore.accountLevel);
    const { isOnDarkMode } = useThemePreference();
    return (
        <ProgressBar
            height="0.5rem"
            margin={isDesktop
                ? "0 0 0.6rem 0"
                : "1rem 0 0.6rem 0"}
            bgColor={isOnDarkMode
                ? "linear-gradient(180deg, #AA96EF 0%, #DDD5F9 100%)"
                : "linear-gradient(#FFFFFF, #9178EA)"}
            baseBgColor={isOnDarkMode
                ? "rgba(170, 150, 239, 0.25)"
                : "#9576E6"}
            completed={fullToken && accountLevel
                ? accountLevel.experienceGainedAtThisLevel
                : 0}
            maxCompleted={fullToken && accountLevel
                ? accountLevel.experienceGainedAtThisLevel +
                accountLevel.experienceRequiredForNextLevel
                : 0}
            isLabelVisible={false}
        />
    );
}

export default GoVipExperienceBar;
