/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ValidateMenuOrderItemsResultError
 */
export interface ValidateMenuOrderItemsResultError {
    /**
     * 
     * @type {string}
     * @memberof ValidateMenuOrderItemsResultError
     */
    errorMessage: string;
    /**
     * 
     * @type {string}
     * @memberof ValidateMenuOrderItemsResultError
     */
    externalId?: string;
    /**
     * 
     * @type {string}
     * @memberof ValidateMenuOrderItemsResultError
     */
    menuItemId?: string;
}

export function ValidateMenuOrderItemsResultErrorFromJSON(json: any): ValidateMenuOrderItemsResultError {
    return ValidateMenuOrderItemsResultErrorFromJSONTyped(json, false);
}

export function ValidateMenuOrderItemsResultErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidateMenuOrderItemsResultError {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'errorMessage': json['errorMessage'],
        'externalId': !exists(json, 'externalId') ? undefined : json['externalId'],
        'menuItemId': !exists(json, 'menuItemId') ? undefined : json['menuItemId'],
    };
}

export function ValidateMenuOrderItemsResultErrorToJSON(value?: ValidateMenuOrderItemsResultError | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'errorMessage': value.errorMessage,
        'externalId': value.externalId,
        'menuItemId': value.menuItemId,
    };
}


