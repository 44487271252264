/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CandyBlastRequestBody
 */
export interface CandyBlastRequestBody {
    /**
     * 
     * @type {number}
     * @memberof CandyBlastRequestBody
     */
    level: number;
}

export function CandyBlastRequestBodyFromJSON(json: any): CandyBlastRequestBody {
    return CandyBlastRequestBodyFromJSONTyped(json, false);
}

export function CandyBlastRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CandyBlastRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'level': json['level'],
    };
}

export function CandyBlastRequestBodyToJSON(value?: CandyBlastRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'level': value.level,
    };
}


