/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Asset,
    AssetFromJSON,
    AssetFromJSONTyped,
    AssetToJSON,
    GetAssetsResponseAllOf,
    GetAssetsResponseAllOfFromJSON,
    GetAssetsResponseAllOfFromJSONTyped,
    GetAssetsResponseAllOfToJSON,
    PaginatedResponse,
    PaginatedResponseFromJSON,
    PaginatedResponseFromJSONTyped,
    PaginatedResponseToJSON,
    PaginationInfo,
    PaginationInfoFromJSON,
    PaginationInfoFromJSONTyped,
    PaginationInfoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetAssetsResponse
 */
export interface GetAssetsResponse extends PaginatedResponse {
    /**
     * 
     * @type {Array<Asset>}
     * @memberof GetAssetsResponse
     */
    assets: Array<Asset>;
}

export function GetAssetsResponseFromJSON(json: any): GetAssetsResponse {
    return GetAssetsResponseFromJSONTyped(json, false);
}

export function GetAssetsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAssetsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...PaginatedResponseFromJSONTyped(json, ignoreDiscriminator),
        'assets': ((json['assets'] as Array<any>).map(AssetFromJSON)),
    };
}

export function GetAssetsResponseToJSON(value?: GetAssetsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...PaginatedResponseToJSON(value),
        'assets': ((value.assets as Array<any>).map(AssetToJSON)),
    };
}


