import {BrandMapContext, BrandMapStates} from "@/pages/brandMap/context/BrandMapContext";
import {IoIosArrowForward} from "react-icons/io";
import classNames from "classnames";
import {useContext, useEffect} from "react";

interface Props {
    toggle: (isOpen: boolean) => void;
    isOpen: boolean;
    content: any;
}

const BrandOrderMobileDrawer = (props: Props) => {
    const {content, toggle, isOpen} = props;
    const {brandMapState} = useContext(BrandMapContext);

    useEffect(() => {
        if (brandMapState === BrandMapStates.ORDER_IN_PROGRESS ||
            brandMapState === BrandMapStates.CHECKOUT_IN_PROGRESS ||
            brandMapState === BrandMapStates.SUCCESS) {
            toggle(true);
        }
    }, [brandMapState]);

    function renderDrawerTitle() {
        if (brandMapState === BrandMapStates.ORDER_IN_PROGRESS) {
            return "Restaurant";
        }

        if (brandMapState === BrandMapStates.CHECKOUT_IN_PROGRESS) {
            return "Checkout";
        }

        if (brandMapState === BrandMapStates.SUCCESS) {
            return "Track Order";
        }
    }

    return (
        <>
            <div
                className={classNames("brand-map-restaurant-drawer", {
                    "open-drawer": isOpen,
                })}
            >

                <div
                    onClick={() => toggle(!isOpen)}
                    className={classNames("brand-map-restaurant-drawer_header", {
                        "open-drawer": isOpen,
                    })}>
                    <span className="brand-map-restaurant-drawer_title">{renderDrawerTitle()}</span>
                    <IoIosArrowForward className={classNames("arrow", {
                        "arrow-up": isOpen,
                    })}/>
                </div>

                {content}
            </div>
        </>
    );
};

export default BrandOrderMobileDrawer;