import {PropsWithChildren, ReactElement} from "react";
import {FiCheck} from "react-icons/fi";
import classNames from "classnames";

export type FrameOneCheckboxBackground = "purple";

interface Props {
    onToggle: () => void; // No value or boolean passed into callback intentionally. Write logic in your implementing component to toggle the value in your form + any side effects.
    checked: boolean;
    disabled?: boolean; // true -> user can't interact with checkbox.
    className?: string;
    background?: FrameOneCheckboxBackground;
}

function FrameOneCheckbox(props: PropsWithChildren<Props>): ReactElement {

    /*
     * onClick helper for when clicking on a part of the component that is not directly the input itself.
     * Doesn't call the toggle function if this input is set as disabled.
     *
     */
    function onClickHelper(): void {
        if (!props.disabled) {
            props.onToggle();
        }
    }

    return (
        <div
            onClick={onClickHelper}
            className={classNames(
                "frame-one-checkbox",
                props.className, {
                    "frame-one-checkbox_disabled": props.disabled,
                    [`frame-one-checkbox_background_${props.background}`]: Boolean(props.background),
                },
            )}
        >
            <div>
                <div className="pretty p-svg p-curve p-smooth">
                    <input
                        type="checkbox"
                        onChange={props.onToggle}
                        checked={props.checked}
                        disabled={props.disabled}
                        onClick={onClickHelper}
                    />

                    <div className={classNames("state", "p-dark-purple", {"checked": props.checked})}>
                        <FiCheck className="svg svg-icon"/>
                        <label/>
                    </div>
                </div>
            </div>

            <label className="frame-one-checkbox_real-label">
                {props.children}
            </label>
        </div>
    );
}

export default FrameOneCheckbox;
