/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Asset,
    AssetFromJSON,
    AssetFromJSONTyped,
    AssetToJSON,
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
    TimeStamps,
    TimeStampsFromJSON,
    TimeStampsFromJSONTyped,
    TimeStampsToJSON,
} from './';

/**
 * Represents a prize in an NFT reward.
 * @export
 * @interface NftRewardPrize
 */
export interface NftRewardPrize {
    /**
     * 
     * @type {string}
     * @memberof NftRewardPrize
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof NftRewardPrize
     */
    createdAt: number;
    /**
     * 
     * @type {number}
     * @memberof NftRewardPrize
     */
    updatedAt: number;
    /**
     * 
     * @type {string}
     * @memberof NftRewardPrize
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof NftRewardPrize
     */
    prizeRevealName: string;
    /**
     * 
     * @type {number}
     * @memberof NftRewardPrize
     */
    quantity: number;
    /**
     * 
     * @type {number}
     * @memberof NftRewardPrize
     */
    wonQuantity: number;
    /**
     * 
     * @type {boolean}
     * @memberof NftRewardPrize
     */
    emailOnWin: boolean;
    /**
     * 
     * @type {number}
     * @memberof NftRewardPrize
     */
    amount: number;
    /**
     * 
     * @type {Asset}
     * @memberof NftRewardPrize
     */
    winningImage?: Asset;
    /**
     * 
     * @type {number}
     * @memberof NftRewardPrize
     */
    sortOrder?: number;
    /**
     * number of times this current reward has been redeemed for this prize to be available
     * @type {number}
     * @memberof NftRewardPrize
     */
    onlyAvailableAfterWins?: number;
    /**
     * 
     * @type {string}
     * @memberof NftRewardPrize
     */
    description?: string;
}

export function NftRewardPrizeFromJSON(json: any): NftRewardPrize {
    return NftRewardPrizeFromJSONTyped(json, false);
}

export function NftRewardPrizeFromJSONTyped(json: any, ignoreDiscriminator: boolean): NftRewardPrize {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'name': json['name'],
        'prizeRevealName': json['prizeRevealName'],
        'quantity': json['quantity'],
        'wonQuantity': json['wonQuantity'],
        'emailOnWin': json['emailOnWin'],
        'amount': json['amount'],
        'winningImage': !exists(json, 'winningImage') ? undefined : AssetFromJSON(json['winningImage']),
        'sortOrder': !exists(json, 'sortOrder') ? undefined : json['sortOrder'],
        'onlyAvailableAfterWins': !exists(json, 'onlyAvailableAfterWins') ? undefined : json['onlyAvailableAfterWins'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function NftRewardPrizeToJSON(value?: NftRewardPrize | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'name': value.name,
        'prizeRevealName': value.prizeRevealName,
        'quantity': value.quantity,
        'wonQuantity': value.wonQuantity,
        'emailOnWin': value.emailOnWin,
        'amount': value.amount,
        'winningImage': AssetToJSON(value.winningImage),
        'sortOrder': value.sortOrder,
        'onlyAvailableAfterWins': value.onlyAvailableAfterWins,
        'description': value.description,
    };
}


