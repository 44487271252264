/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RankedMember,
    RankedMemberFromJSON,
    RankedMemberFromJSONTyped,
    RankedMemberToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetMonthlyTopMembersLeaderboardResponse
 */
export interface GetMonthlyTopMembersLeaderboardResponse {
    /**
     * 
     * @type {Array<RankedMember>}
     * @memberof GetMonthlyTopMembersLeaderboardResponse
     */
    topMembers: Array<RankedMember>;
}

export function GetMonthlyTopMembersLeaderboardResponseFromJSON(json: any): GetMonthlyTopMembersLeaderboardResponse {
    return GetMonthlyTopMembersLeaderboardResponseFromJSONTyped(json, false);
}

export function GetMonthlyTopMembersLeaderboardResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetMonthlyTopMembersLeaderboardResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'topMembers': ((json['topMembers'] as Array<any>).map(RankedMemberFromJSON)),
    };
}

export function GetMonthlyTopMembersLeaderboardResponseToJSON(value?: GetMonthlyTopMembersLeaderboardResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'topMembers': ((value.topMembers as Array<any>).map(RankedMemberToJSON)),
    };
}


