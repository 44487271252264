export function truncateMiddle(base: string, length: number): string {
    if (!base || !base.length) {
        return "";
    }
    const half = (length - 3) / 2;
    const firstLength = Math.ceil(half);
    const secondLength = Math.floor(half);
    const firstString = base.substring(0, firstLength);
    const secondString = base.slice(-secondLength);
    return `${firstString}...${secondString}`;
}
