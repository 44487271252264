/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum BusinessType {
    CORPORATE = 'CORPORATE',
    RESTAURANT = 'RESTAURANT'
}

export function BusinessTypeFromJSON(json: any): BusinessType {
    return BusinessTypeFromJSONTyped(json, false);
}

export function BusinessTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): BusinessType {
    return json as BusinessType;
}

export function BusinessTypeToJSON(value?: BusinessType | null): any {
    return value as any;
}

