import {ReactElement } from "react";
import GoVipHeaderAccountLevel from "./GoVipHeaderAccountLevel";
import { isDesktop } from "react-device-detect";
import useThemePreference from "@/hooks/useThemePreference";

function GoVipHeader(): ReactElement {
    const { getThemedImageUrl } = useThemePreference();
    const getBackgroundImage = () => {
        if (isDesktop) {
            return `${import.meta.env.VITE_CDN_URL}/images/govip-header-bg-desktop.webp`;
        }
        return `${import.meta.env.VITE_CDN_URL}/images/govip-header-bg-mobile.webp`;
    };

    return (
        <div className="go-vip-top_header"
            style={{
                backgroundImage: `url(${getThemedImageUrl(getBackgroundImage())})`,
                backgroundSize: "cover",
            }}
        >
            {/* <GoVipHeaderUserData/> */}
            <GoVipHeaderAccountLevel/>
        </div>
    );
}

export default GoVipHeader;
