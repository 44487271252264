/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateSEOBody
 */
export interface CreateSEOBody {
    /**
     * 
     * @type {string}
     * @memberof CreateSEOBody
     */
    url: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateSEOBody
     */
    tags: Array<string>;
}

export function CreateSEOBodyFromJSON(json: any): CreateSEOBody {
    return CreateSEOBodyFromJSONTyped(json, false);
}

export function CreateSEOBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateSEOBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'url': json['url'],
        'tags': json['tags'],
    };
}

export function CreateSEOBodyToJSON(value?: CreateSEOBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'url': value.url,
        'tags': value.tags,
    };
}


