import FrameOneModal from "@/components/modals/modalComponents/FrameOneModal";
import FrameModalBody from "@/components/modals/modalComponents/FrameModalBody";
import {IoCloseSharp} from "react-icons/io5";
import {AiOutlineExclamationCircle} from "react-icons/ai";
import FrameButton from "@/components/buttons/FrameButton";

interface Props {
    isOpen: boolean;
    toggle: () => void;
    onConfirm: () => void;
}

export default function BuyIndustryPassConfirmModal(props: Props) {
    function onPaymentConfirm() {
        props.toggle();
        props.onConfirm();
    }

    return (
        <FrameOneModal
            isOpen={props.isOpen}
            toggle={props.toggle}
            contentClassName="govip-nft-redeem-confirm-modal"
        >
            <FrameModalBody className="govip-nft-redeem-confirm-modal_body">
                <div className="govip-nft-redeem-confirm-modal_body_close-wrapper">
                    <IoCloseSharp onClick={props.toggle}/>
                </div>

                <div className="govip-nft-redeem-confirm-modal_body_content">
                    <AiOutlineExclamationCircle />
                    <div className="govip-nft-redeem-confirm-modal_body_content_header">
                        Get your VIP Pass Now?
                    </div>
                    <div className="govip-nft-redeem-confirm-modal_body_content_message">
                        Do not hit submit again while pass is minting or it will result in duplicate orders.
                        The purchase of an Industry Pass is final.
                    </div>
                </div>

                <FrameButton
                    color="purple"
                    size="narrow"
                    className="govip-nft-redeem-confirm-modal_body_button"
                    onClick={onPaymentConfirm}
                >
                    Confirm
                </FrameButton>


            </FrameModalBody>
        </FrameOneModal>
    );
}