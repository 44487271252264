import {NftOwnershipInformation, NftOwnershipMatrix} from "@devour/client";

/**
 * Verify the user owns an NFT in the group.
 * @param groupingId
 * @param nftOwnershipResponse
 * @param userId
 */
export function verifyNftGroupOwnership(groupingId: string, nftOwnershipResponse: NftOwnershipInformation, userId?: string): boolean {
    if (!nftOwnershipResponse?.nftOwnerships?.length) {

        return false;

    }

    let userNftArray: NftOwnershipMatrix;
    if (userId) {
        // `NftOwnershipInformation.nftOwnerships` is an array of users with an array of owned NFTs.
        // Find the NFT array for the requested user.
        userNftArray = nftOwnershipResponse.nftOwnerships.find(ownershipArray => {
            return ownershipArray[0].userId.toString() === userId.toString();
        });
    } else {
        // No user ID to filter by. `nftOwnershipResponse` should be pre-filtered so just grab the first item.
        userNftArray = nftOwnershipResponse.nftOwnerships[0];
    }

    if (!userNftArray?.length) {
        return false;
    }

    for (const nftOwnership of userNftArray) {
        const nftTrackersFind = nftOwnershipResponse?.nftTrackers?.find(t => {
            return t.id === nftOwnership.nftTrackerId;
        });
        if (nftTrackersFind?.groupingId.toString() === groupingId.toString()) {
            return true;
        }
    }
    return false;
}
