/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NftCommunity,
    NftCommunityFromJSON,
    NftCommunityFromJSONTyped,
    NftCommunityToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetNftCommunityFromGroupingResponse
 */
export interface GetNftCommunityFromGroupingResponse {
    /**
     * 
     * @type {NftCommunity}
     * @memberof GetNftCommunityFromGroupingResponse
     */
    community: NftCommunity;
}

export function GetNftCommunityFromGroupingResponseFromJSON(json: any): GetNftCommunityFromGroupingResponse {
    return GetNftCommunityFromGroupingResponseFromJSONTyped(json, false);
}

export function GetNftCommunityFromGroupingResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetNftCommunityFromGroupingResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'community': NftCommunityFromJSON(json['community']),
    };
}

export function GetNftCommunityFromGroupingResponseToJSON(value?: GetNftCommunityFromGroupingResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'community': NftCommunityToJSON(value.community),
    };
}


