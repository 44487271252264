/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum OrderDiscountType {
    ORDER = 'ORDER',
    DELIVERY = 'DELIVERY',
    PAYMENT = 'PAYMENT',
    SALESTAX = 'SALES_TAX',
    CATEGORY = 'CATEGORY',
    MENUITEM = 'MENU_ITEM'
}

export function OrderDiscountTypeFromJSON(json: any): OrderDiscountType {
    return OrderDiscountTypeFromJSONTyped(json, false);
}

export function OrderDiscountTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderDiscountType {
    return json as OrderDiscountType;
}

export function OrderDiscountTypeToJSON(value?: OrderDiscountType | null): any {
    return value as any;
}

