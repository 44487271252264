/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BrandMapHtmlSection
 */
export interface BrandMapHtmlSection {
    /**
     * 
     * @type {string}
     * @memberof BrandMapHtmlSection
     */
    content?: string;
    /**
     * 
     * @type {number}
     * @memberof BrandMapHtmlSection
     */
    widthPercentage?: number;
}

export function BrandMapHtmlSectionFromJSON(json: any): BrandMapHtmlSection {
    return BrandMapHtmlSectionFromJSONTyped(json, false);
}

export function BrandMapHtmlSectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): BrandMapHtmlSection {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'content': !exists(json, 'content') ? undefined : json['content'],
        'widthPercentage': !exists(json, 'widthPercentage') ? undefined : json['widthPercentage'],
    };
}

export function BrandMapHtmlSectionToJSON(value?: BrandMapHtmlSection | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'content': value.content,
        'widthPercentage': value.widthPercentage,
    };
}


