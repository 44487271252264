import { FC } from "react";

interface props {
    tabs: string[];
    activeTab: string;
    onTabClicked: (tab: string) => void;
}

const Tabs: FC<props> = ({activeTab, onTabClicked, tabs}) => {
    const currentTab = activeTab ?? tabs[0] ?? "";

    return <div className="tabs">
        {tabs.map((tab, index) => <div key={tab + index} className={`tabs_tab ${currentTab == tab ? "active" : ""}`}
            onClick={() => onTabClicked(tab)}>
            <span className="tabs_tab_label">{tab}</span>
        </div>) }
    </div>;
};


export default Tabs;