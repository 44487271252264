import {ReactElement, ReactNode, useEffect, useState} from "react";
import {connect, ConnectedProps, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {EvmWallet, GetUserResponse} from "@devour/client";
import FrameOneModal from "./modalComponents/FrameOneModal";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import RemoveWalletButton from "../RemoveWalletButton";
import {useGetUserProfile} from "@/hooks/useGetUserProfile";

interface StateProps {
    currentUser: GetUserResponse;
}

interface Props {
    isOpen: boolean;
    onClose: () => void;
}

function RemoveAWalletModal(props: RemoveAWalletModalProps): ReactElement {
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const {data: userProfileData} = useGetUserProfile(fullToken);

    function handleOnDoneRemoveWallet(): void {
        if (userProfileData?.wallets?.length === 0) {
            props.onClose();
        }
    }

    function renderWallets(wallet: EvmWallet): ReactNode {
        return (
            <RemoveWalletButton
                key={`user-wallet_${wallet.id}`}
                wallet={wallet}
                onDone={handleOnDoneRemoveWallet}
            />
        );
    }

    return (
        <FrameOneModal
            isOpen={props.isOpen}
            toggle={props.onClose}
            contentClassName="remove-wallet-modal"
        >
            <FrameModalHeader
                title="Disconnect a Wallet"
                toggle={props.onClose}
            />

            <FrameModalBody className="remove-wallet-modal_body">
                <p>
					Select the wallet you wish to remove from your account.
                </p>

                <div className="remove-wallet-modal_body_wallets">
                    {userProfileData?.wallets?.map(renderWallets)}
                </div>
            </FrameModalBody>
        </FrameOneModal>
    );
}

function connector() {
    return connect((store: IStore, props: Props): StateProps & Props => {
        return {
            currentUser: store.metaStore.currentUser,
            ...props,
        };
    });
}

type RemoveAWalletModalProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(RemoveAWalletModal);
