import {PaginationInfo} from "@devour/client";

interface ParsedPaginationParameters {
    limit: number,
    offset: number,
    numberOfAvailableOffsets: number,
}

export function getPaginationInfo(paginationParameters: ParsedPaginationParameters, total: number): PaginationInfo {
    const {limit, offset, numberOfAvailableOffsets} = paginationParameters;

    // send empty pagination info if no results are found
    if (total === 0) {
        return {
            enablePrevious: false,
            enableNext: false,
            lowerRange: 0,
            upperRange: 0,
            total: 0,
            availableOffsets: Array(numberOfAvailableOffsets).fill(undefined),
            limit,
            offset,
        };
    }

    // calculate pagination info
    const skip = offset * limit;
    if (skip >= total) {
        console.error("The requested page is outside of the range.");
        return;
    }

    // determine if the "enable next" button should be active
    const enableNext = limit < total - skip;

    return {
        enablePrevious: offset !== 0,
        enableNext,
        lowerRange: skip + 1,
        upperRange: skip + limit > total
            ? total
            : skip + limit,
        total,
        availableOffsets: findAvailableOffsets(paginationParameters, total),
        limit,
        offset,
    };
}

function findAvailableOffsets(paginationParameters: ParsedPaginationParameters, total: number): Array<number> {
    const {limit, offset, numberOfAvailableOffsets} = paginationParameters;

    const indexOfOffset = Math.floor((numberOfAvailableOffsets - 1) / 2);
    const maxOffset = total % limit === 0
        ? total / limit - 1
        : Math.floor(total / limit);
    const availableOffsets = [];

    let currentOffset = offset - indexOfOffset;
    for (let i = 0; i < numberOfAvailableOffsets; i++) {
        if (currentOffset < 0 || currentOffset > maxOffset) {
            availableOffsets.push(undefined);
        } else {
            availableOffsets.push(currentOffset);
        }

        currentOffset = currentOffset + 1;
    }

    return availableOffsets;
}
