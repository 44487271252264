import useThemePreference from "@/hooks/useThemePreference";
import {ReactElement} from "react";

interface Props {
    step: number;
    text: string;
    imageUrl: string;
}

function ContactModal(props: Props): ReactElement {
    const { getThemedImageUrl } = useThemePreference();
    return (
        <div className="solana-wallet-modal_body_app_steps_container_row">

            <div className="solana-wallet-modal_body_app_steps_container_row_info">
                <div>
                    <div className="solana-wallet-modal_body_app_steps_container_row_tag">
                        <p>{`Step ${props.step}`}</p>
                    </div>
                    <p className="solana-wallet-modal_body_app_steps_container_row_info_details">
                        {props.text}
                    </p>
                </div>
                <img
                    src={getThemedImageUrl(props.imageUrl)}
                    alt="solana-step"
                />
            </div>

        </div>
    );
}

export default ContactModal;
