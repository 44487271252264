import {ReactElement, ReactNode, useState} from "react";
import {AddressBook} from "@devour/client";
import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import FrameOneAutoPanel from "./autoPanelComponents/FrameOneAutoPanel";
import FrameAutoPanelHeader from "./autoPanelComponents/FrameAutoPanelHeader";
import FrameAutoPanelBody from "./autoPanelComponents/FrameAutoPanelBody";
import {HiPlus} from "react-icons/hi";
import ManageAddressBookAddAddressModal from "./ManageAddressBookAddAddressModal";
import ManageAddressBookModalAddressDisplay from "../ManageAddressBookModalAddressDisplay";
import ManageAddressBookUpdateAddressModal from "@/components/modals/ManageAddressBookUpdateAddressModal";

interface Props {
    isOpen: boolean;
    onClose: () => void;
}

function ManageAddressBookModal(props: Props): ReactElement {

    const user = useSelector((store: IStore) => store.metaStore.currentUser?.user);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);
    const [selectedAddressBook, setSelectedAddressBook] = useState<AddressBook>();

    function toggleAddModal(done?: boolean): void {
        setShowAddModal(s => done
            ? false
            : !s);
    }

    function onUpdateAddress(addressBook: AddressBook): void {
        setSelectedAddressBook(addressBook);
        setShowUpdateModal(true);
    }

    function onCloseUpdateModal(): void {
        setShowUpdateModal(false);
        setSelectedAddressBook(undefined);
    }

    function renderAddress(addressBook: AddressBook, i: number): ReactNode {

        return (
            <div
                className="manage-address-book-modal_body_addresses_list_address"
                key={`address-display_${i}`}
            >
                <ManageAddressBookModalAddressDisplay
                    addressBook={addressBook}
                    showDelete={true}
                    onUpdateAddress={onUpdateAddress}
                />
                <hr/>
            </div>
        );
    }

    return (
        <>
            <ManageAddressBookUpdateAddressModal
                isOpen={showUpdateModal}
                onClose={onCloseUpdateModal}
                onSave={onCloseUpdateModal}
                addressBook={selectedAddressBook}
            />

            <ManageAddressBookAddAddressModal
                isOpen={showAddModal}
                onClose={toggleAddModal}
                onDone={() => toggleAddModal(true)}
            />

            <FrameOneAutoPanel
                isOpen={props.isOpen}
                toggle={props.onClose}
                size="xs2"
                contentClassName="manage-address-book-modal"
            >
                <FrameAutoPanelHeader
                    title="Address Book"
                    toggle={props.onClose}
                />

                <FrameAutoPanelBody className="manage-address-book-modal_body">
                    <div className="manage-address-book-modal_body_addresses">
                        {user?.addresses?.length > 0
                            ? <div className="manage-address-book-modal_body_addresses_list">
                                {user.addresses.map(renderAddress)}
                            </div>
						 : <div className="manage-address-book-modal_body_addresses_empty">
                                <p>
									You currently have no addresses saved. Click the button below to add one.
                                </p>
                            </div>
                        }
                    </div>

                    <div
                        className="manage-address-book-modal_body_add-button"
                        onClick={() => toggleAddModal()}
                    >
                        <HiPlus/>
                        <p>
							Add a new address
                        </p>
                    </div>

                </FrameAutoPanelBody>
            </FrameOneAutoPanel>
        </>
    );
}

export default ManageAddressBookModal;
