import {resolve} from "ipfs-race";

// includes all values from...
// https://github.com/ethereum/EIPs/blob/master/EIPS/eip-721.md
// https://github.com/ethereum/EIPs/blob/master/EIPS/eip-1155.md#erc-1155-metadata-uri-json-schema
// https://docs.opensea.io/docs/metadata-standards
export interface INFTSchema {
    // https://github.com/ethereum/EIPs/blob/master/EIPS/eip-721.md
    name: string;
    description: string;
    image?: string;

    // https://docs.opensea.io/docs/metadata-standards
    image_data?: string;
    external_url?: string;
    background_color?: string; // Background color of the item on OpenSea. Must be a six-character hexadecimal without a pre-pended #.
    animation_url?: string;
    youtube_url?: string;
    attributes?: Array<{
        trait_type: string, // name of trait
        value: number | string, // value
        display_type?: "number" | "boost_percentage" | "boost_number" | "data" // only needed for numbers, not strings https://docs.opensea.io/docs/metadata-standards
        max_value?: number
    }>

    // https://github.com/ethereum/EIPs/blob/master/EIPS/eip-1155.md#erc-1155-metadata-uri-json-schema
    properties?: object;
    decimals?: number;
    localization?: {
        uri: string,
        default: string,
        locales: string[]
    }
}

export interface NftInformationFromTokenUriOutput {
    imageBase64?: string;
    imageResolveUrl?: string;
    metadataResolveUrl?: string;
    metadata: INFTSchema;
}

export async function getNftInformationFromTokenUri(tokenUri: string): Promise<NftInformationFromTokenUriOutput> {
    // tokenUri returned for nfts in Base chain is data:application/json, but not ipfs://
    // since the data comes from simplehash, don't see if there is any chance to get tokenUri with prefix ipfs:// for Base nfts
    // so added below code to handle the image, since the purpose of this function is fetching the image
    if (tokenUri.substring(0, 21) === "data:application/json") {
        const json = atob(tokenUri.substring(29));
        const result = JSON.parse(json);

        let imageOrAnimationUrl = result.image || result.animation_url;
        if (!imageOrAnimationUrl) {
            throw new Error("No image or animation URL found in the NFT metadata.");
        }

        const {
            response: imageResponse,
            urlResolvedFrom: imageResolveUrl,
        } = await resolve(imageOrAnimationUrl, {fetchOverride: window.fetch.bind(window)});
        const imageBase64 = await blobToBase64(await imageResponse.blob());

        return {
            metadata: result,
            imageBase64,
            imageResolveUrl,
            metadataResolveUrl: "",
        };
    }

    // try to resolve the tokenUri via the ipfs-race library
    const {response: metadataResponse, urlResolvedFrom: metadataResolveUrl} = await resolve(tokenUri, {
        ipfsGateways: [
            "https://ipfs.io",
            "https://gateway.ipfs.io",
            "https://gateway.pinata.cloud",
            "https://cloudflare-ipfs.com",
        ],
        fetchOverride: window.fetch.bind(window),
    });
    const metadata: INFTSchema = await metadataResponse.json();

    // Check if an image exists, if not, check for animation_url
    const imageUrl = metadata.image || metadata.animation_url;
    if (!imageUrl) {
        return { metadata };
    }

    try {
        // Resolve the link to the image or animation_url
        const {response: imageResponse, urlResolvedFrom: imageResolveUrl} = await resolve(imageUrl, {fetchOverride: window.fetch.bind(window)});
        const imageBase64 = await blobToBase64(await imageResponse.blob());
        return {
            metadata,
            imageBase64,
            imageResolveUrl,
            metadataResolveUrl,
        };

    } catch {
        // Means there's an issue with resolving imageURL, just return the imageURL
        return {
            metadata,
            imageBase64: null,
            imageResolveUrl: imageUrl,
            metadataResolveUrl,
        };
    }
}

function blobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result as string);
        reader.readAsDataURL(blob);
    });
}