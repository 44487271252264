/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BurgerStackRequestBody
 */
export interface BurgerStackRequestBody {
    /**
     * represents the total money earned in the game
     * @type {number}
     * @memberof BurgerStackRequestBody
     */
    money: number;
}

export function BurgerStackRequestBodyFromJSON(json: any): BurgerStackRequestBody {
    return BurgerStackRequestBodyFromJSONTyped(json, false);
}

export function BurgerStackRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): BurgerStackRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'money': json['money'],
    };
}

export function BurgerStackRequestBodyToJSON(value?: BurgerStackRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'money': value.money,
    };
}


