/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AdminDebitOrCreditDpayRequestBody
 */
export interface AdminDebitOrCreditDpayRequestBody {
    /**
     * 
     * @type {string}
     * @memberof AdminDebitOrCreditDpayRequestBody
     */
    userId: string;
    /**
     * 
     * @type {number}
     * @memberof AdminDebitOrCreditDpayRequestBody
     */
    amountInDpay?: number;
    /**
     * Setting this to true will add dpay, setting to false will credit dpay
     * @type {boolean}
     * @memberof AdminDebitOrCreditDpayRequestBody
     */
    debit: boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminDebitOrCreditDpayRequestBody
     */
    note?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminDebitOrCreditDpayRequestBody
     */
    adminNote?: string;
}

export function AdminDebitOrCreditDpayRequestBodyFromJSON(json: any): AdminDebitOrCreditDpayRequestBody {
    return AdminDebitOrCreditDpayRequestBodyFromJSONTyped(json, false);
}

export function AdminDebitOrCreditDpayRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminDebitOrCreditDpayRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': json['userId'],
        'amountInDpay': !exists(json, 'amountInDpay') ? undefined : json['amountInDpay'],
        'debit': json['debit'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'adminNote': !exists(json, 'adminNote') ? undefined : json['adminNote'],
    };
}

export function AdminDebitOrCreditDpayRequestBodyToJSON(value?: AdminDebitOrCreditDpayRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'amountInDpay': value.amountInDpay,
        'debit': value.debit,
        'note': value.note,
        'adminNote': value.adminNote,
    };
}


