import {ReactElement} from "react";
import {FaArrowLeft} from "react-icons/fa";
import {isMobile} from "react-device-detect";
import classNames from "classnames";

interface Props {
    title: string;
    subtitle?: string;
    toggle?: () => void;
    thumbnailUrl?: string;
    isDisabled?: boolean;
}

function GoVipModalHeader(props: Props): ReactElement {

    return (
        <div
            className={classNames("go-vip-all-play2earn-games_header", {
                "go-vip-all-play2earn-games_header_gradient-bg": !props.thumbnailUrl,
                "go-vip-all-play2earn-games_header_img-bg": props.thumbnailUrl,
                "game-thumbnail_alert-and-img-con_image-wrapper_black-and-white-filter": props.isDisabled,
            })}
            style={{backgroundImage: props.thumbnailUrl
                ? `url(${props.thumbnailUrl})`
                : ""}}
        >
            {props.toggle &&
            <FaArrowLeft
				    className={classNames("go-vip-all-play2earn-games_header_arrow", {
				        "go-vip-all-play2earn-games_header_arrow_gradient-bg": !props.thumbnailUrl,
				        "go-vip-all-play2earn-games_header_arrow_img-bg": props.thumbnailUrl,
                })}

				    onClick={props.toggle}
            />
            }

            {isMobile
                ? <h2>{props.title}</h2>
			 : <h3>{props.title}</h3>
            }

            {props.subtitle &&
            <p>{props.subtitle}</p>
            }

        </div>
    );
}

export default GoVipModalHeader;
