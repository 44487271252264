import {useQuery} from "@tanstack/react-query";
import {MenusApi, GetOrderItemsResponse, Token} from "@devour/client";
import getConfig from "@/utils/getConfig";

async function fetchRestaurantPreviousOrderedItems(restaurantId: string, fullToken: Token): Promise<GetOrderItemsResponse> {
    if (!restaurantId || !fullToken) {
        return {
            orderItems: [],
        };
    }

    return await new MenusApi(getConfig(fullToken)).getPreviousOrderItems({
        id: restaurantId,
    });
}

function restaurantPreviousOrderedItemsQuery(restaurantId: string, fullToken: Token) {
    return {
        queryKey: [
            "restaurant-previous-ordered-items",
            restaurantId,
            fullToken?.id ?? "unauthenticated",
        ],
        queryFn: () => fetchRestaurantPreviousOrderedItems(restaurantId, fullToken),
        keepPreviousData: true,
        staleTime: 60000,
        refetchOnWindowFocus: false,
    } as const;
}

export function useRestaurantPreviousOrderedItems(restaurantId: string, fullToken: Token) {
    // const queryClient = useQueryClient();

    return useQuery(restaurantPreviousOrderedItemsQuery(restaurantId, fullToken));

}
