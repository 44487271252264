import {useQuery} from "@tanstack/react-query";
import {
    AddressBook,
    BusinessesApi,
    HandoffOptions,
    SearchRestaurantsPromosResponse,
    Token,
} from "@devour/client";
import getConfig from "@/utils/getConfig";

async function fetchGofrensRewards(fullToken: Token, address: AddressBook, useAddress: boolean): Promise<SearchRestaurantsPromosResponse> {
    return await new BusinessesApi(getConfig(fullToken)).searchRestaurantsPromos({
        handoff: address && useAddress
            ? HandoffOptions.DELIVERY
            : HandoffOptions.PICKUP,
        placeId: address && useAddress
            ? address.placeId
            : undefined,
        addressId: address && useAddress
            ? address.id
            : undefined,
    });
}

function gofrensRewardsQuery(fullToken: Token, address: AddressBook, useAddress: boolean) {
    return {
        queryKey: [
            "user-gofrens-rewards" + (address
                ? "-address"
                : ""),
            fullToken?.id,
            useAddress,
        ],
        queryFn: () => fetchGofrensRewards(fullToken, address, useAddress),
        staleTime: 60000,
        enabled: !!fullToken,
    } as const;
}

export function useGetGoFrensRewards(fullToken: Token, useAddress: boolean, address?: AddressBook) {
    return useQuery(gofrensRewardsQuery(fullToken, address, useAddress));
}