import {ReactElement, useState, useEffect, useContext, ButtonHTMLAttributes} from "react";
import {
    UtilsApi,
    Address,
    MenuOrdersApi,
    AddressBook,
    HandoffOptions,
    ErrorType,
} from "@devour/client";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {
    addError,
    refreshMenuOrderCart, updateLastSearchedPlaceId,
} from "@/redux/meta/metaActions";
import {useNavigate, useParams} from "react-router";
import FrameButton from "../../../components/buttons/FrameButton";
import {BsFillHouseDoorFill} from "react-icons/bs";
import {RxCaretDown} from "react-icons/rx";
import RestaurantAddressAutoPanel from "./RestaurantAddressAutoPanel";
import getConfig from "../../../utils/getConfig";
import {RestaurantContext} from "../context/RestaurantContext";
import {isAddressBook} from "@/utils/typeGuards";
import RestaurantSearchAddressButtonSkeleton from "../../../components/skeletons/RestaurantSearchAddressButtonSkeleton";
import {truncateText} from "@/utils/truncateText";
import TooltipWrapper from "@/components/TooltipWrapper";


const MAX_ADDRESS_LENGTH = 42;

function RestaurantPageAddressButton(): ReactElement {

    const history = useNavigate();
    const dispatch = useDispatch();
    const currentUser = useSelector((store: IStore) => store.metaStore.currentUser);
    const selectedHandoff = useSelector((store: IStore) => store.metaStore.handoff);
    const {placeId} = useParams<{ restaurantId: string; placeId: string; }>();
    const [
        address,
        setAddress,
    ] = useState<Address>();
    const [
        showRestaurantSearchAddressModal,
        setShowRestaurantSearchAddressModal,
    ] = useState<boolean>(false);
    const {menuOrder, restaurant} = useContext(RestaurantContext);
    const [
        isAddressLoading,
        setIsAddressLoading,
    ] = useState<boolean>(false);
    const findSelectedAddressBook = currentUser?.user?.addresses?.find((addressBook) => addressBook.placeId === placeId);

    useEffect(() => {
        getAddress().then();
    }, [placeId]);

    async function getAddress() {
        if (!placeId) {
            return;
        }
        setIsAddressLoading(true);
        try {
            const newAddress = await new UtilsApi().getAddressFromPlaceId({
                placeId: placeId,
            });
            setAddress(newAddress);
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            setIsAddressLoading(false);
        }
    }

    async function updateExistingShoppingCart(newAddress: Address | AddressBook) {
        try {
            await new MenuOrdersApi(getConfig()).updateMenuOrder({
                id: menuOrder.id,
                createMenuOrderBody: {
                    address: newAddress,
                    deliveryNotes: isAddressBook(address)
                        ? address?.deliveryInstructions
                        : undefined,
                    deliveryHandoffInstructions: isAddressBook(address)
                        ? address?.handoffInstructions
                        : undefined,
                },
            });
            dispatch(refreshMenuOrderCart());
        } catch {
        }
    }

    async function onAddressSubmit(newAddress: Address): Promise<void> {
        if (selectedHandoff === HandoffOptions.DELIVERY && !newAddress.line1.trim()) {
            dispatch(await addError({
                type: ErrorType.APP,
                message: "This address is not valid as a delivery destination.",
            }));
            return;
        }

        if (menuOrder) {
            await updateExistingShoppingCart(newAddress);
        }

        let placeId: string = newAddress?.placeId;
        if (!placeId) {
            const res = await new UtilsApi().getPlaceIdFromAddress({
                address: newAddress,
            });
            placeId = res.placeId;
        }

        dispatch(updateLastSearchedPlaceId(placeId));
        setShowRestaurantSearchAddressModal(false);
        history(`${restaurant.url}/${placeId}`);
    }

    if (!placeId) {
        return null;
    }

    if (isAddressLoading) return <RestaurantSearchAddressButtonSkeleton/>;

    return (
        <div className="restaurant-page-address-button">
            <RestaurantAddressAutoPanel
                defaultAddressBook={findSelectedAddressBook}
                isOpen={showRestaurantSearchAddressModal}
                onClose={() => setShowRestaurantSearchAddressModal(false)}
                onAddressSubmit={onAddressSubmit}
            />

            <TooltipWrapper className="tooltip-wrapper" tooltipContent={address?.line1 && address.line1.trim() !== "" && address.line1} shouldShowTooltip={address?.line1.length > MAX_ADDRESS_LENGTH}>
                <FrameButton
                    <ButtonHTMLAttributes<HTMLButtonElement>>
                    color="gray"
                    size="normal"
                    className="restaurant-page-address-button_button"
                    onClick={() => setShowRestaurantSearchAddressModal(true)}
                    forwardProps={{type: "button"}}
                    leftIcon={BsFillHouseDoorFill}
                    rightIcon={RxCaretDown}
                >
                    {address?.line1 && address.line1.trim() !== ""
                        ? truncateText(MAX_ADDRESS_LENGTH, address.line1)
                        : "Address"}
                </FrameButton>
            </TooltipWrapper>
        </div>
    );
}

export default RestaurantPageAddressButton;
