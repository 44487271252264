/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreatePaymentMethodBody
 */
export interface CreatePaymentMethodBody {
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentMethodBody
     */
    cardNumber: string;
    /**
     * 
     * @type {number}
     * @memberof CreatePaymentMethodBody
     */
    expMonth: number;
    /**
     * 
     * @type {number}
     * @memberof CreatePaymentMethodBody
     */
    expYear: number;
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentMethodBody
     */
    cvc: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentMethodBody
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentMethodBody
     */
    postalCode: string;
}

export function CreatePaymentMethodBodyFromJSON(json: any): CreatePaymentMethodBody {
    return CreatePaymentMethodBodyFromJSONTyped(json, false);
}

export function CreatePaymentMethodBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreatePaymentMethodBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cardNumber': json['cardNumber'],
        'expMonth': json['expMonth'],
        'expYear': json['expYear'],
        'cvc': json['cvc'],
        'country': json['country'],
        'postalCode': json['postalCode'],
    };
}

export function CreatePaymentMethodBodyToJSON(value?: CreatePaymentMethodBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cardNumber': value.cardNumber,
        'expMonth': value.expMonth,
        'expYear': value.expYear,
        'cvc': value.cvc,
        'country': value.country,
        'postalCode': value.postalCode,
    };
}


