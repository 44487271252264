import {ButtonHTMLAttributes, ReactElement} from "react";
import {CommerceApi, UsersApi} from "@devour/client";
import FrameButton from "../buttons/FrameButton";
import FrameOneAutoPanel from "./autoPanelComponents/FrameOneAutoPanel";
import FrameAutoPanelHeader from "./autoPanelComponents/FrameAutoPanelHeader";
import FrameAutoPanelBody from "./autoPanelComponents/FrameAutoPanelBody";
import {addError, decrementLoading, incrementLoading, updateCurrentUser} from "@/redux/meta/metaActions";
import getConfig from "../../utils/getConfig";
import {connect, ConnectedProps, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";

interface StateProps {
}

interface Props {
    isOpen: boolean;
    onClose: () => void;
    paymentId: string;
}

function CardPaymentRemoveModal(props: CreditCardAddModalProps): ReactElement {
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const currentUser = useSelector((store: IStore) => store.metaStore.currentUser?.user);

    async function onRemoveConfirm(): Promise<void> {
        props.dispatch(incrementLoading());
        try {
            await new CommerceApi(getConfig()).stripePaymentMethodDelete({
                id: props.paymentId,
            });
            if (currentUser?.defaultPaymentMethodId === props.paymentId) {
                // remove as default paymentId
                await new UsersApi(getConfig(fullToken)).updateDefaultPayment({
                    defaultPaymentMethodId: "",
                });
                const userRes = await new UsersApi(getConfig(fullToken)).getProfile();
                await props.dispatch(updateCurrentUser(userRes));
            }
            props.onClose();
        } catch (e) {
            props.dispatch(await addError(e));
        } finally {
            props.dispatch(decrementLoading());
        }
    }

    return (
        <FrameOneAutoPanel
            isOpen={props.isOpen}
            toggle={props.onClose}
            size="sm"
            contentClassName="card-payment-remove-modal"
        >
            <FrameAutoPanelHeader
                title="Delete Card"
                toggle={props.onClose}
            />

            <FrameAutoPanelBody className="card-payment-remove-modal_body">
                <p>
					Really delete this card?
                </p>

                <FrameButton
                    <ButtonHTMLAttributes<HTMLButtonElement>>
                    color="danger"
                    size="normal"
                    onClick={onRemoveConfirm}
                    className="card-payment-remove-modal_body_remove-button"
                    forwardProps={{
                        type: "button",
                    }}
                >
					Delete card
                </FrameButton>

                <FrameButton
                    <ButtonHTMLAttributes<HTMLButtonElement>>
                    color="gray"
                    size="normal"
                    onClick={props.onClose}
                    className="card-payment-remove-modal_body_cancel-button"
                    forwardProps={{
                        type: "button",
                    }}
                >
					Go back
                </FrameButton>

            </FrameAutoPanelBody>
        </FrameOneAutoPanel>
    );
}

function connector() {
    return connect((store: IStore, props: Props): StateProps & Props => {
        return {
            ...props,
        };
    });
}

type CreditCardAddModalProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(CardPaymentRemoveModal);
