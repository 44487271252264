/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Address,
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
    Asset,
    AssetFromJSON,
    AssetFromJSONTyped,
    AssetToJSON,
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
    BusinessLabel,
    BusinessLabelFromJSON,
    BusinessLabelFromJSONTyped,
    BusinessLabelToJSON,
    BusinessType,
    BusinessTypeFromJSON,
    BusinessTypeFromJSONTyped,
    BusinessTypeToJSON,
    PhoneNumber,
    PhoneNumberFromJSON,
    PhoneNumberFromJSONTyped,
    PhoneNumberToJSON,
    TimeStamps,
    TimeStampsFromJSON,
    TimeStampsFromJSONTyped,
    TimeStampsToJSON,
} from './';

import {
     RestaurantFromJSONTyped
} from './';

/**
 * Represents a single business entity.
 * @export
 * @interface Business
 */
export interface Business {
    /**
     * 
     * @type {string}
     * @memberof Business
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof Business
     */
    createdAt: number;
    /**
     * 
     * @type {number}
     * @memberof Business
     */
    updatedAt: number;
    /**
     * 
     * @type {BusinessType}
     * @memberof Business
     */
    type: BusinessType;
    /**
     * 
     * @type {string}
     * @memberof Business
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Business
     */
    internalName?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Business
     */
    taxonomies?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Business
     */
    brands?: Array<string>;
    /**
     * 
     * @type {Array<BusinessLabel>}
     * @memberof Business
     */
    labels?: Array<BusinessLabel>;
    /**
     * 
     * @type {Asset}
     * @memberof Business
     */
    icon?: Asset;
    /**
     * 
     * @type {Asset}
     * @memberof Business
     */
    headerImage?: Asset;
    /**
     * 
     * @type {string}
     * @memberof Business
     */
    parent?: string;
    /**
     * 
     * @type {string}
     * @memberof Business
     */
    stripeAccountId?: string;
    /**
     * 
     * @type {string}
     * @memberof Business
     */
    description?: string;
    /**
     * 
     * @type {Address}
     * @memberof Business
     */
    address: Address;
    /**
     * 
     * @type {string}
     * @memberof Business
     */
    currency: string;
    /**
     * 
     * @type {string}
     * @memberof Business
     */
    email?: string;
    /**
     * Distance between origin and destination in KM.
     * @type {number}
     * @memberof Business
     */
    distance?: number;
    /**
     * Control allowing merchants to permanently close the business. If false, do not show location to customers or merchants.
     * @type {boolean}
     * @memberof Business
     */
    isEnabled?: boolean;
    /**
     * Control allowing admins to permanently close the business. If true, do not show location to customers or merchants.
     * @type {boolean}
     * @memberof Business
     */
    isArchived?: boolean;
    /**
     * Control allowing merchants to temporarily close the business until the specified time.
     * @type {number}
     * @memberof Business
     */
    suspendUntil?: number;
    /**
     * Control allowing admins to disable the business. If false, do not show location to customers or merchants.
     * @type {boolean}
     * @memberof Business
     */
    isActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Business
     */
    website?: string;
    /**
     * 
     * @type {string}
     * @memberof Business
     */
    websiteMobile?: string;
    /**
     * Extension to the URL on the DevourGO webpage.
     * @type {string}
     * @memberof Business
     */
    url: string;
    /**
     * See moment.tz.names()
     * @type {string}
     * @memberof Business
     */
    timeZone: string;
    /**
     * 
     * @type {PhoneNumber}
     * @memberof Business
     */
    phoneNumber?: PhoneNumber;
    /**
     * 
     * @type {number}
     * @memberof Business
     */
    marketplacePercentageDelivery?: number;
    /**
     * 
     * @type {number}
     * @memberof Business
     */
    marketplacePercentagePickup?: number;
}

export function BusinessFromJSON(json: any): Business {
    return BusinessFromJSONTyped(json, false);
}

export function BusinessFromJSONTyped(json: any, ignoreDiscriminator: boolean): Business {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    if (!ignoreDiscriminator) {
        if (json['type'] === 'RESTAURANT') {
            return RestaurantFromJSONTyped(json, true);
        }
    }
    return {
        
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'type': BusinessTypeFromJSON(json['type']),
        'name': json['name'],
        'internalName': !exists(json, 'internalName') ? undefined : json['internalName'],
        'taxonomies': !exists(json, 'taxonomies') ? undefined : json['taxonomies'],
        'brands': !exists(json, 'brands') ? undefined : json['brands'],
        'labels': !exists(json, 'labels') ? undefined : ((json['labels'] as Array<any>).map(BusinessLabelFromJSON)),
        'icon': !exists(json, 'icon') ? undefined : AssetFromJSON(json['icon']),
        'headerImage': !exists(json, 'headerImage') ? undefined : AssetFromJSON(json['headerImage']),
        'parent': !exists(json, 'parent') ? undefined : json['parent'],
        'stripeAccountId': !exists(json, 'stripeAccountId') ? undefined : json['stripeAccountId'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'address': AddressFromJSON(json['address']),
        'currency': json['currency'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'distance': !exists(json, 'distance') ? undefined : json['distance'],
        'isEnabled': !exists(json, 'isEnabled') ? undefined : json['isEnabled'],
        'isArchived': !exists(json, 'isArchived') ? undefined : json['isArchived'],
        'suspendUntil': !exists(json, 'suspendUntil') ? undefined : json['suspendUntil'],
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'website': !exists(json, 'website') ? undefined : json['website'],
        'websiteMobile': !exists(json, 'websiteMobile') ? undefined : json['websiteMobile'],
        'url': json['url'],
        'timeZone': json['timeZone'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : PhoneNumberFromJSON(json['phoneNumber']),
        'marketplacePercentageDelivery': !exists(json, 'marketplacePercentageDelivery') ? undefined : json['marketplacePercentageDelivery'],
        'marketplacePercentagePickup': !exists(json, 'marketplacePercentagePickup') ? undefined : json['marketplacePercentagePickup'],
    };
}

export function BusinessToJSON(value?: Business | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'type': BusinessTypeToJSON(value.type),
        'name': value.name,
        'internalName': value.internalName,
        'taxonomies': value.taxonomies,
        'brands': value.brands,
        'labels': value.labels === undefined ? undefined : ((value.labels as Array<any>).map(BusinessLabelToJSON)),
        'icon': AssetToJSON(value.icon),
        'headerImage': AssetToJSON(value.headerImage),
        'parent': value.parent,
        'stripeAccountId': value.stripeAccountId,
        'description': value.description,
        'address': AddressToJSON(value.address),
        'currency': value.currency,
        'email': value.email,
        'distance': value.distance,
        'isEnabled': value.isEnabled,
        'isArchived': value.isArchived,
        'suspendUntil': value.suspendUntil,
        'isActive': value.isActive,
        'website': value.website,
        'websiteMobile': value.websiteMobile,
        'url': value.url,
        'timeZone': value.timeZone,
        'phoneNumber': PhoneNumberToJSON(value.phoneNumber),
        'marketplacePercentageDelivery': value.marketplacePercentageDelivery,
        'marketplacePercentagePickup': value.marketplacePercentagePickup,
    };
}


