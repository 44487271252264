import {useQuery} from "@tanstack/react-query";
import {MenuOrdersApi} from "@devour/client";
import getConfig from "@/utils/getConfig";

export const useMenuOrder = (menuOrderId: string) => {

    // Define the query function directly inside useQuery
    const result = useQuery({
        queryKey: [
            "menuOrder",
            menuOrderId,
        ],
        queryFn: async () => {
            if (!menuOrderId) {
                return null;
            }
            return new MenuOrdersApi(getConfig()).getMenuOrder({
                id: menuOrderId,
                validateOrderItems: "true",
            });
        },
        // Options object
        enabled: Boolean(menuOrderId), // Conditional fetching based on menuOrderId presence
    });

    return {
        data: result.data,
        refetch: result.refetch,
        isLoading: result.isLoading,
    };
};