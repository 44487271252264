/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum BrandMapStatus {
    DRAFT = 'DRAFT',
    PUBLISHED = 'PUBLISHED',
    DISABLED = 'DISABLED'
}

export function BrandMapStatusFromJSON(json: any): BrandMapStatus {
    return BrandMapStatusFromJSONTyped(json, false);
}

export function BrandMapStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): BrandMapStatus {
    return json as BrandMapStatus;
}

export function BrandMapStatusToJSON(value?: BrandMapStatus | null): any {
    return value as any;
}

