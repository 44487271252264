import {ReactElement, ReactNode, useState} from "react";
import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {
    AddressBook,
    OrderDiscount,
    Restaurant,
    Token,
} from "@devour/client";
import {FiChevronLeft} from "react-icons/fi";
import {useNavigate} from "react-router-dom";
import {isDesktop} from "react-device-detect";
import FrameOneSwitchInput from "@/components/inputs/FrameOneSwitchInput";
import FrameButton from "@/components/buttons/FrameButton";
import {useGetGoFrensRewards} from "@/hooks/useGetGoFrensRewards";
import GoFrensCardsSkeleton from "@/components/skeletons/GoFrensPage/GoFrensCardsSkeleton";
import Toast from "@/components/Toast";
import classNames from "classnames";
import GoFrensRestaurantPromoCard from "@/components/goFrens/GoFrensRestaurantPromoCard";
import {useGetGlobalOrderDiscountsForUser} from "@/hooks/useGetGlobalOrderDiscountsForUser";
import GoFrensGlobalPromoCard from "@/components/goFrens/GoFrensGlobalPromoCard";
import {useGetNftOwnershipsForUser} from "@/hooks/useGetNftOwnershipsForUser";

function GoFrensRewardsPage(): ReactElement {
    const navigate = useNavigate();
    const fullToken: Token = useSelector<IStore>(state => state.authStore.fullToken) as Token;
    const lastSearchedPlaceId = useSelector((store: IStore) => store.metaStore.lastSearchedPlaceId);
    const currentUser = useSelector((store: IStore) => store.metaStore.currentUser);
    const history = useNavigate();
    const [
        useAddress,
        setUseAddress,
    ] = useState<boolean>(false);
    const [
        showToast,
        setShowToast,
    ] = useState<boolean>(false);

    const address: AddressBook = currentUser?.user?.addresses.filter(a => a.isDefault)[0] || currentUser?.user?.addresses[0];

    const {data: allRestaurantsRes} = useGetGoFrensRewards(fullToken, false);
    const {data: nearbyRestaurantsRes} = useGetGoFrensRewards(fullToken, true, address);
    const {data: globalOrderDiscounts} = useGetGlobalOrderDiscountsForUser(fullToken);
    const {data: nftOwnershipResponse} = useGetNftOwnershipsForUser(fullToken);
    const restaurantRes = useAddress
        ? nearbyRestaurantsRes
        : allRestaurantsRes;

    function updateRestaurantQuery(value: 0 | 1): void {
        setUseAddress(value === 1);
    }

    function renderRestaurantCards(restaurant: Restaurant): ReactNode {
        if (!restaurant || !restaurant.promos || restaurant.promos.length === 0) {
            return null;
        }

        const navigationButton: ReactNode =
            <div className="gofrens-rewards_body_cards_button">
                <FrameButton
                    color="gray"
                    size="narrow"
                    to={`${restaurant.url}/${lastSearchedPlaceId
                        ? lastSearchedPlaceId
                        : ""}`}
                    className="gofrens-rewards_body_cards_button_inner"
                >
                    <p>Order Now</p>
                </FrameButton>
            </div>;
        return (
            <GoFrensRestaurantPromoCard
                key={restaurant.id}
                nftOwnershipResponse={nftOwnershipResponse}
                restaurant={restaurant}
                children={navigationButton}
                width={!isDesktop
                    ? "15rem"
                    : "20rem"}
            />
        );
    }

    function renderGlobalPromoRestaurantCards(orderDiscount: OrderDiscount): ReactNode {
        return (
            <GoFrensGlobalPromoCard
                key={orderDiscount.id}
                nftOwnershipResponse={nftOwnershipResponse}
                promo={orderDiscount}
                width={!isDesktop && "18rem"}
            />
        );
    }

    if (!currentUser || restaurantRes?.restaurantsGoFrens?.length === 0 && globalOrderDiscounts?.orderDiscounts?.length === 0) {
        navigate("/gofriends");
    }

    function renderContent(): ReactElement {
        if (!restaurantRes && !globalOrderDiscounts) {
            return (
                <div className="view-all_card-container">
                    <GoFrensCardsSkeleton width={!isDesktop
                        ? "15rem"
                        : "20rem"}/>
                </div>);
        }

        return (
            <div className="view-all_card-container">
                {globalOrderDiscounts?.orderDiscounts?.map(renderGlobalPromoRestaurantCards)}
                {restaurantRes?.restaurantsGoFrens?.map(renderRestaurantCards)}
            </div>
        );
    }

    const switchInput: ReactElement =
        <div className={classNames(
            "gofrens-rewards_title_switch-container",
            !isDesktop && "gofrens-rewards_title_switch-container_mobile",
        )}>
            <div onClick={() => {
                if (!address) {
                    setShowToast(true);
                }
            }}>
                <FrameOneSwitchInput
                    <0 | 1>
                    name="gofrens-rewards-use-address"
                    value={useAddress
                        ? 1
                        : 0}
                    disabled={!address}
                    options={[
                        {
                            render: "All",
                            value: 0,
                        },
                        {
                            render: "Near You",
                            value: 1,
                        },
                    ]}
                    onToggle={updateRestaurantQuery}
                />
            </div>
        </div>;
    function handleToastDismissal() {
        setShowToast(false);
    }

    return (
        <>
            <Toast
                duration={5000}
                forceWidth="30rem"
                message="Your location isn't set up yet. Please enter your address on the Account page."
                isOpen={showToast}
                showButton={true}
                buttonMessage={"Your Account"}
                buttonFunction={() => {
                    history("/account");
                }}
                onDismiss={handleToastDismissal}
            />
            <div className="view-all">
                <div
                    className="view-all_back"
                    onClick={() => {
                        history("/gofriends");
                    }}
                >
                    <FiChevronLeft className="view-all_back_icon"/>
                    <p>Back to GoFriends</p>
                </div>

                <div className="gofrens-rewards">
                    <div className="gofrens-rewards_header">
                        <div className="gofrens-rewards_title">
                            <h3>Rewards</h3>
                            <p>Claim your community benefits!</p>
                        </div>
                        <div className="gofrens-rewards_title_buttons">
                            {isDesktop && switchInput}
                        </div>
                    </div>
                    {!isDesktop && switchInput}
                    {renderContent()}
                </div>
            </div>
        </>
    );
}

export default GoFrensRewardsPage;
