/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Address,
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
    CreateMenuOrderResponseAllOf,
    CreateMenuOrderResponseAllOfFromJSON,
    CreateMenuOrderResponseAllOfFromJSONTyped,
    CreateMenuOrderResponseAllOfToJSON,
    DeliveryHandoffInstructions,
    DeliveryHandoffInstructionsFromJSON,
    DeliveryHandoffInstructionsFromJSONTyped,
    DeliveryHandoffInstructionsToJSON,
    HandoffOptions,
    HandoffOptionsFromJSON,
    HandoffOptionsFromJSONTyped,
    HandoffOptionsToJSON,
    MenuOrder,
    MenuOrderFromJSON,
    MenuOrderFromJSONTyped,
    MenuOrderToJSON,
    MenuOrderDiscount,
    MenuOrderDiscountFromJSON,
    MenuOrderDiscountFromJSONTyped,
    MenuOrderDiscountToJSON,
    MenuOrderItem,
    MenuOrderItemFromJSON,
    MenuOrderItemFromJSONTyped,
    MenuOrderItemToJSON,
    MenuOrderLog,
    MenuOrderLogFromJSON,
    MenuOrderLogFromJSONTyped,
    MenuOrderLogToJSON,
    MenuOrderServiceFee,
    MenuOrderServiceFeeFromJSON,
    MenuOrderServiceFeeFromJSONTyped,
    MenuOrderServiceFeeToJSON,
    MenuOrderStatus,
    MenuOrderStatusFromJSON,
    MenuOrderStatusFromJSONTyped,
    MenuOrderStatusToJSON,
    OloError,
    OloErrorFromJSON,
    OloErrorFromJSONTyped,
    OloErrorToJSON,
    OrderRefund,
    OrderRefundFromJSON,
    OrderRefundFromJSONTyped,
    OrderRefundToJSON,
    PhoneNumber,
    PhoneNumberFromJSON,
    PhoneNumberFromJSONTyped,
    PhoneNumberToJSON,
    ValidateMenuOrderItemsResultError,
    ValidateMenuOrderItemsResultErrorFromJSON,
    ValidateMenuOrderItemsResultErrorFromJSONTyped,
    ValidateMenuOrderItemsResultErrorToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreateMenuOrderResponse
 */
export interface CreateMenuOrderResponse extends MenuOrder {
    /**
     * 
     * @type {Array<ValidateMenuOrderItemsResultError>}
     * @memberof CreateMenuOrderResponse
     */
    validationErrors?: Array<ValidateMenuOrderItemsResultError>;
}

export function CreateMenuOrderResponseFromJSON(json: any): CreateMenuOrderResponse {
    return CreateMenuOrderResponseFromJSONTyped(json, false);
}

export function CreateMenuOrderResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateMenuOrderResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...MenuOrderFromJSONTyped(json, ignoreDiscriminator),
        'validationErrors': !exists(json, 'validationErrors') ? undefined : ((json['validationErrors'] as Array<any>).map(ValidateMenuOrderItemsResultErrorFromJSON)),
    };
}

export function CreateMenuOrderResponseToJSON(value?: CreateMenuOrderResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...MenuOrderToJSON(value),
        'validationErrors': value.validationErrors === undefined ? undefined : ((value.validationErrors as Array<any>).map(ValidateMenuOrderItemsResultErrorToJSON)),
    };
}


