/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum OrderDiscountOrigin {
    DEVOUR = 'DEVOUR',
    MERCHANT = 'MERCHANT',
    DPAY = 'DPAY'
}

export function OrderDiscountOriginFromJSON(json: any): OrderDiscountOrigin {
    return OrderDiscountOriginFromJSONTyped(json, false);
}

export function OrderDiscountOriginFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderDiscountOrigin {
    return json as OrderDiscountOrigin;
}

export function OrderDiscountOriginToJSON(value?: OrderDiscountOrigin | null): any {
    return value as any;
}

