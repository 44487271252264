import {ContractTransactionReceipt} from "ethers";
import * as ethers from "ethers";
import {magic} from "@/utils/magic";

export async function waitForMagicTransaction(receipt: ContractTransactionReceipt, numberOfBlocksToWait: number) {
    const provider = new ethers.BrowserProvider(magic.rpcProvider);
    // checks for a new block each second
    do {
        await new Promise((resolve) => setTimeout(resolve, 1000));
    } while (receipt.blockNumber + numberOfBlocksToWait > await provider.getBlockNumber());

    return true;
}