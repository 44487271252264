import {ReactElement} from "react";
import {PaginationInfo} from "@devour/client";
import usePagination, {ELLIPSIS} from "@/hooks/usePagination";
import classNames from "classnames";
import {FaChevronLeft, FaChevronRight} from "react-icons/fa";

interface Props extends PaginationInfo {
    onChangeOffset: (offset: number) => void;
}

function FramePaginationButtons(props: Props): ReactElement {
    const paginationRange = usePagination(
        props.total,
        props.limit,
        props.offset + 1,
    );

    function changePageHelper(offset: number): void {
        if (props.offset === offset) {
            return;
        }
        props.onChangeOffset(offset);
    }

    function renderPaginatorButton(offset: number): ReactElement {
        return (
            <li key={`paginator-button-offset_${offset}`}>
                <button
                    className={classNames("frame-paginator_buttons-container_button", {
                        "frame-paginator_buttons-container_button_active": props.offset === offset,
                    })}
                    onClick={() => changePageHelper(offset)}
                >
                    {offset + 1}
                </button>
            </li>

        );
    }

    return (
        <div className="frame-paginator_buttons-container">
            <ul className="frame-paginator_buttons-container_buttons">
                <li key="paginator-button-back">
                    <button
                        className={classNames(
                            "frame-paginator_buttons-container_button",
                            "frame-paginator_buttons-container_button_arrow", {
                                "frame-paginator_buttons-container_button_arrow_disabled": !props.enablePrevious,
                            },
                        )}
                        onClick={props.enablePrevious
                            ? () => changePageHelper(props.offset - 1)
                            : undefined}
                    >
                        <FaChevronLeft />
                    </button>
                </li>

                {paginationRange?.map((pageNumber, index) => {
                    if (pageNumber === ELLIPSIS) {
                        return (
                            <li key={`paginator-button-dots-${index}`}>
                                <button className="frame-paginator_buttons-container_button dots">
                                    {ELLIPSIS}
                                </button>
                            </li>
                        );
                    }

                    return renderPaginatorButton(+pageNumber - 1);
                })}

                <li key="paginator-button-next">
                    <button
                        className={classNames(
                            "frame-paginator_buttons-container_button",
                            "frame-paginator_buttons-container_button_arrow", {
                                "frame-paginator_buttons-container_button_arrow_disabled": !props.enableNext,
                            },
                        )}
                        onClick={props.enableNext
                            ? () => changePageHelper(props.offset + 1)
                            : undefined}
                    >
                        <FaChevronRight />
                    </button>
                </li>
            </ul>
        </div>
    );
}

export default FramePaginationButtons;
