/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum GenericCounterKey {
    PLACESAPICALLS = 'PLACES_API_CALLS',
    AUTOCOMPLETEAPICALLS = 'AUTOCOMPLETE_API_CALLS'
}

export function GenericCounterKeyFromJSON(json: any): GenericCounterKey {
    return GenericCounterKeyFromJSONTyped(json, false);
}

export function GenericCounterKeyFromJSONTyped(json: any, ignoreDiscriminator: boolean): GenericCounterKey {
    return json as GenericCounterKey;
}

export function GenericCounterKeyToJSON(value?: GenericCounterKey | null): any {
    return value as any;
}

