// A dummy component created based on CreateMenuOrderCard.tsx -> they share the same classes
// Created the skeleton in this way because we want seamless experience when showing this skeleton

import {ReactElement} from "react";
import FrameButton from "../../buttons/FrameButton";
import Tag from "../../Tag";

function MenuOrderListPageSkeleton(): ReactElement {
    return (
        <>
            {Array.from({length: 3}, (_, i) => <div className="react-loading-skeleton menu-order-list-component menu-order-list-component_skeleton" key={i}>
                <div className="menu-order-list-component_header" style={{visibility: "hidden"}}>
                    <div className="menu-order-list-component_header_business-link">
                        <div className="menu-order-list-component_header_business-link_image">
                        </div>
                        <strong>Dummy Text</strong>
                    </div>
                </div>

                <div className="menu-order-list-component_body" style={{visibility: "hidden"}}>
                    <strong>Dummy Text</strong>

                    <span>Dummy Text</span>

                    <div className="menu-order-list-component_body_label">
                        <Tag
                            color="gray"
                            name='Dummy Text'
                        />
                    </div>
                </div>

                <div className="menu-order-list-component_header_buttons_mobile" style={{visibility: "hidden"}}>
                    <FrameButton
                        color="gray"
                        size="pill"
                    >
                            Dummy Text
                    </FrameButton>
                    <FrameButton
                        color="gray"
                        size="pill"
                    >
                            Dummy Text
                    </FrameButton>
                </div>
            </div>)}
        </>
    );
}

export default MenuOrderListPageSkeleton;