/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum UserType {
    GODADMIN = 'GOD_ADMIN',
    STANDARDADMIN = 'STANDARD_ADMIN',
    MERCHANT = 'MERCHANT',
    SUPPORTAGENT = 'SUPPORT_AGENT',
    PARTNER = 'PARTNER',
    STANDARDUSER = 'STANDARD_USER'
}

export function UserTypeFromJSON(json: any): UserType {
    return UserTypeFromJSONTyped(json, false);
}

export function UserTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserType {
    return json as UserType;
}

export function UserTypeToJSON(value?: UserType | null): any {
    return value as any;
}

