import {ReactElement, useContext, useEffect, useState} from "react";
import {BusinessTaxonomiesApi, BusinessTaxonomy} from "@devour/client";
import {RestaurantContext} from "../context/RestaurantContext";
import Tag from "../../../components/Tag";
import Skeleton from "react-loading-skeleton";

/**
 * Sort taxonomies
 * @param a
 * @param b
 */
function sortTaxonomy(a: BusinessTaxonomy, b: BusinessTaxonomy) {
    // Sort by priority descending
    if (a.priority > b.priority) return -1;
    if (a.priority < b.priority) return 1;

    // Sort by name alphabetically ascending
    return a?.name.localeCompare(b?.name);
}


function RestaurantPageTaxonomies(): ReactElement {
    const {restaurant} = useContext(RestaurantContext);
    const [
        businessTaxonomies,
        setBusinessTaxonomies,
    ] = useState<Array<BusinessTaxonomy>>([]);
    const [
        isBusinessTaxonomiesLoading,
        setIsBusinessTaxonomiesLoading,
    ] = useState<boolean>(false);

    useEffect(() => {
        fetchBusinessTaxonomies().then()
            .catch();
    }, []);

    async function fetchBusinessTaxonomies() {
        setIsBusinessTaxonomiesLoading(true);
        const businessTaxonomies = await new BusinessTaxonomiesApi().getBusinessTaxonomies();
        setBusinessTaxonomies(businessTaxonomies.businessTaxonomies);
        setIsBusinessTaxonomiesLoading(false);
    }

    /**
     * Map taxonomy string ID into BusinessTaxonomy
     * @param taxonomyId
     */
    function formatTaxonomy(taxonomyId: string): BusinessTaxonomy {
        return businessTaxonomies.find((findTaxonomy) => findTaxonomy.id === taxonomyId);
    }

    function renderTaxonomy(taxonomy: BusinessTaxonomy): JSX.Element {

        return (
            <Tag
                key={taxonomy?.id}
                color="gray"
                name={taxonomy?.name}
            />
        );
    }

    return (
        <>
            {isBusinessTaxonomiesLoading || !restaurant
                ? Array.from({length: 4}, (_, i) => <Skeleton width={61} height={32} key={i}/>)
                : restaurant.taxonomies
                    .map(formatTaxonomy)
                    .filter(Boolean)
                    .sort(sortTaxonomy)
                    .map(renderTaxonomy)}
        </>
    );
}

export default RestaurantPageTaxonomies;
