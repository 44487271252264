import React, {ReactElement, useState} from "react";
import FrameOneAutoPanel from "./autoPanelComponents/FrameOneAutoPanel";
import FrameAutoPanelHeader from "./autoPanelComponents/FrameAutoPanelHeader";
import FrameAutoPanelBody from "./autoPanelComponents/FrameAutoPanelBody";
import FrameButton from "@/components/buttons/FrameButton";
import {FaChevronLeft} from "react-icons/fa";
import {MenuOrder} from "@devour/client";

interface Props {
    isOpen: boolean;
    menuOrder: MenuOrder;
    onClose: (amount: number | null) => void;
}

function DeliveryTipModal(props: Props): ReactElement {

    const [
        tipAmount,
        setTipAmount,
    ] = useState<string>("");

    function updateTipAmount(e: React.ChangeEvent<HTMLInputElement>) {
        const val: string = e.target.value;
        if (isNaN(+val)) {
            return;
        }
        setTipAmount(e.target.value);
    }

    function validateTipAmount() {
        return tipAmount !== "";

    }

    async function updateMenuOrder(): Promise<void> {
        if (!validateTipAmount()) {
            return;
        }
        props.onClose(parseFloat(tipAmount));
    }

    return (
        <FrameOneAutoPanel
            isOpen={props.isOpen}
            size="sm"
        >
            <FrameAutoPanelHeader title={<div className={"delivery-tip-modal_title"}>
                <FaChevronLeft onClick={() => props.onClose(null)}/>
                Enter tip amount
            </div>}/>
            <FrameAutoPanelBody className="delivery-tip-modal_body">
                <div className={"dollar-sign"}>$</div>
                <input value={tipAmount} onChange={updateTipAmount} type={"text"} placeholder={"0.00"}/>
                <FrameButton
                    color={validateTipAmount()
                        ? "purple"
                        : "gray"}
                    onClick={updateMenuOrder}
                    size="normal"
                    forwardProps={{disabled: !validateTipAmount()}}
                >
                    Confirm
                </FrameButton>
            </FrameAutoPanelBody>
        </FrameOneAutoPanel>
    );
}

export default DeliveryTipModal;
