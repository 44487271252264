import {Magic} from "magic-sdk";
import {OAuthExtension} from "@magic-ext/oauth";
import {clusterApiUrl} from "@solana/web3.js";
import {WalletAdapterNetwork} from "@solana/wallet-adapter-base";
import {SolanaExtension} from "@magic-ext/solana";

const magic = new Magic(import.meta.env.VITE_MAGIC_LIVE_KEY, {
    extensions: [new OAuthExtension()],
});

export {magic};

// This is to enable Polygon - Magic doesn't support toggling right now, so we need to
// create a separate instance per chain.
// https://magic.link/docs/blockchains/featured-chains/polygon#mainnet

const customNodeOptions = {
    rpcUrl: "https://polygon-rpc.com/", // Polygon RPC URL
    chainId: 137, // Polygon chain id
};
const magicPolygon = new Magic(import.meta.env.VITE_MAGIC_LIVE_KEY, {
    extensions: [new OAuthExtension()],
    network: customNodeOptions,
});

export {magicPolygon};


// Solana
const network = WalletAdapterNetwork.Mainnet;
const magicSolana = new Magic(import.meta.env.VITE_MAGIC_LIVE_KEY, {
    extensions: [
        new OAuthExtension(),
        new SolanaExtension({
            rpcUrl: clusterApiUrl(network),
        }),
    ],
});

export {magicSolana};


