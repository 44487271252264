import {
    getMenuOrderPaymentMethod,
    ActivePaymentMethod,
    ActiveDpay,
} from "@/utils/getMenuOrderPaymentMethod";
import {ReactElement, useContext, useEffect, useState} from "react";
import FrameOneAutoPanel from "@/components/modals/autoPanelComponents/FrameOneAutoPanel";
import FrameAutoPanelHeader from "@/components/modals/autoPanelComponents/FrameAutoPanelHeader";
import FrameAutoPanelBody from "@/components/modals/autoPanelComponents/FrameAutoPanelBody";
import FrameButton from "@/components/buttons/FrameButton";
import {
    WALLET_ADDRESS_TRUNCATION,
} from "@/components/checkout/checkoutPayments/CheckoutPaymentDpayModal";
import {useGetStripePaymentMethodList} from "@/hooks/useGetStripePaymentMethodList";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {StripePaymentMethodObject} from "@/types/Stripe";
import CheckoutPaymentSplitSetupCreditCard
    from "@/components/checkout/checkoutPayments/CheckoutPaymentSplitSetupCreditCard";
import {useGetTransactions} from "@/hooks/useGetTransactions";
import {useParams} from "react-router";
import CheckoutPaymentVdpayCard
    from "@/components/checkout/checkoutPayments/CheckoutPaymentVdpayCard";
import {roundNumber} from "@/utils/roundNumber";
import {useAccount} from "wagmi";
import {addError} from "@/redux/meta/metaActions";
import CheckoutPaymentInsufficientDpayModal
    from "@/components/checkout/checkoutPayments/CheckoutPaymentInsufficientDpayModal";
import CheckoutPaymentLoadDpayModal
    from "@/components/checkout/checkoutPayments/CheckoutPaymentLoadDPayModal";
import CheckoutWalletConnectModal
    from "@/components/checkout/checkoutPayments/CheckoutWalletConnectModal";
import CheckoutPaymentMagicCard
    from "@/components/checkout/checkoutPayments/CheckoutPaymentMagicCard";
import {truncateMiddle} from "@/utils/truncateMiddle";
import CheckoutPaymentExternalCard
    from "@/components/checkout/checkoutPayments/CheckoutPaymentExternalCard";
import {useMenuOrder} from "@/hooks/menuOrder/useMenuOrder";
import {useAddCreditPaymentMethod} from "@/hooks/creditPaymentMethod/useAddCreditPaymentMethod";
import CheckoutPaymentSplitPayModal
    from "@/components/checkout/checkoutPayments/CheckoutPaymentSplitPayModal";
import {MenuOrdersApi} from "@devour/client";
import getConfig from "@/utils/getConfig";
import {RestaurantContext} from "@/pages/restaurants/context/RestaurantContext";
import {useGate} from "statsig-react";

interface Props {
    isOpen: boolean;
    toggle: () => void;
    showDpayContent: boolean;
    magicBalance: number;
    externalDpayBalance: number;
    resetCart: () => Promise<void>;
    isMagicConnected: boolean;
}

function CheckoutPaymentSplitSetupModal(props: Props): ReactElement {
    const {value: onChainStatus} = useGate(import.meta.env.VITE_TOKEN_STATSIG_ONCHAIN_STATUS);
    const {menuOrderId: contextMenuOrderId} = useContext(RestaurantContext);
    const {menuOrderId: paramMenuOrderId} = useParams<{ menuOrderId: string }>();
    const menuOrderId = paramMenuOrderId || contextMenuOrderId;

    const [
        selectedCreditCard,
        setSelectedCreditCard,
    ] = useState<string>(undefined);
    const [
        selectedDpayMethod,
        setSelectedDpayMethod,
    ] = useState<ActiveDpay>(undefined);

    const [
        showSplitPayModal,
        setShowSplitPayModal,
    ] = useState<boolean>(false);
    const [
        showInsufficientBalanceModal,
        setShowInsufficientBalanceModal,
    ] = useState<boolean>(false);
    const [
        showWalletConnectModal,
        setShowWalletConnectModal,
    ] = useState<boolean>(false);
    const [
        showLoadDpayModal,
        setShowDpayLoadModal,
    ] = useState<boolean>(false);
    const [
        isUpdateActive,
        setIsUpdateActive,
    ] = useState<boolean>(false);

    const dispatch = useDispatch();
    const currentUser = useSelector((store: IStore) => store.metaStore?.currentUser);
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);

    const account = useAccount();
    const {data: stripePaymentMethodData} = useGetStripePaymentMethodList(fullToken);
    const stripePaymentMethods = stripePaymentMethodData?.paymentMethods as Array<StripePaymentMethodObject>;
    const {data: menuOrder, refetch: refetchMenuOrder} = useMenuOrder(menuOrderId);
    const {data: transactionData} = useGetTransactions(fullToken, currentUser?.user?.id);

    const {
        mutate: addCreditCard,
        isPending: isAddCreditCardLoading,
    } = useAddCreditPaymentMethod(menuOrderId);

    const menuOrderPaymentMethod = getMenuOrderPaymentMethod(menuOrder, stripePaymentMethods, account, transactionData);

    const insufficientVdpay = !transactionData || transactionData.balance === 0;
    const insufficientMagic = !props.magicBalance || props.magicBalance === 0;
    const insufficientExternal = !props.externalDpayBalance || props.externalDpayBalance === 0;

    useEffect(() => {
        if (!props.isOpen) {
            setShowSplitPayModal(false);
        }

        if (props.isOpen && menuOrderPaymentMethod?.method !== ActivePaymentMethod.SPLIT) {
            setSelectedCreditCard(undefined);
            setSelectedDpayMethod(undefined);
        }

    }, [
        props.isOpen,
        menuOrderPaymentMethod?.method,
    ]);

    useEffect(() => {
        if (props.isOpen && menuOrderPaymentMethod) {
            setSavedPayment();
        }
    }, [
        props.isOpen,
        menuOrderPaymentMethod,
    ]);

    function toggleWalletConnectModal(): void {
        setShowWalletConnectModal(!showWalletConnectModal);
    }

    async function resetCartWrapper() {
        try {
            setIsUpdateActive(true);
            await props.resetCart();

        } catch (e) {
            dispatch(await addError(e));
        } finally {
            setIsUpdateActive(false);
        }
    }

    /**
     * Sets the selected DPAY method to the correct one saved.
     */
    function setSavedPayment(): void {
        if (menuOrderPaymentMethod?.method === ActivePaymentMethod.SPLIT && menuOrder?.dpay && selectedDpayMethod === undefined) {
            switch (menuOrderPaymentMethod.dpayChain) {
                case ActiveDpay.VDPAY:
                    if (transactionData?.balance >= menuOrder.vdpay) {
                        setSelectedDpayMethod(ActiveDpay.VDPAY);
                    }
                    break;
                case ActiveDpay.MAGIC:
                    if (props?.magicBalance >= menuOrder.onChainDpay) {
                        setSelectedDpayMethod(ActiveDpay.MAGIC);
                    }
                    break;
                case ActiveDpay.EXTERNAL:
                    if (props?.externalDpayBalance >= menuOrder.onChainDpay) {
                        setSelectedDpayMethod(ActiveDpay.EXTERNAL);
                    }
                    break;
            }
        }

        if (stripePaymentMethods && menuOrderPaymentMethod?.method === ActivePaymentMethod.SPLIT && selectedCreditCard === undefined) {
            setSelectedCreditCard(menuOrder?.paymentMethodId);
        }
    }

    /**
     * Checks to see if the saved DPAY method is still valid, if not then reset card and remove.
     * This function only runs on modal toggle close, due to the latency it takes on-chain wallets
     * to populate with balance.
     */
    async function removeSavedPayment(): Promise<void> {
        if (menuOrderPaymentMethod && menuOrderPaymentMethod.method === ActivePaymentMethod.SPLIT && menuOrder?.dpay) {
            let reset = false;

            if (!menuOrder.onChainDpay) {
                if (transactionData?.balance < menuOrder.vdpay) {
                    reset = true;
                }

            } else if (menuOrder.isMagicWallet) {
                if (props?.magicBalance < menuOrder.onChainDpay) {
                    reset = true;
                }

            } else if (props?.externalDpayBalance < menuOrder.onChainDpay) {
                reset = true;
            }
            if (reset) {
                await resetCartWrapper();
            }

        }
    }

    async function onToggle(): Promise<void> {
        props.toggle();
        await removeSavedPayment();
    }

    async function updateCartWithDpayAmount(dpay: number): Promise<void> {
        try {
            setIsUpdateActive(true);
            props.toggle();
            await new MenuOrdersApi(getConfig()).updateMenuOrder({
                id: menuOrder.id,
                createMenuOrderBody: {
                    paymentMethodId: selectedCreditCard,
                    dpay: dpay,
                    vdpay: selectedDpayMethod === ActiveDpay.VDPAY
                        ? dpay
                        : 0,
                    onChainDpay: selectedDpayMethod !== ActiveDpay.VDPAY
                        ? dpay
                        : 0,
                    isMagicWallet: selectedDpayMethod === ActiveDpay.MAGIC,
                    isCoinbase: false,
                },
            });

            await refetchMenuOrder();
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            setIsUpdateActive(false);
        }
    }

    async function handleUpdateCart(method: ActiveDpay): Promise<void> {
        if (selectedDpayMethod === method) {
            await resetCartWrapper();
            setSelectedDpayMethod(undefined);
        } else {
            setSelectedDpayMethod(method);
        }
    }

    async function handleUpdateSelectedCreditCard(stripeId: string): Promise<void> {
        if (selectedCreditCard === stripeId) {
            await resetCartWrapper();
            setSelectedCreditCard(undefined);
        } else {
            setSelectedCreditCard(stripeId);
        }
    }

    function toggleInsufficientModal(): void {
        setShowInsufficientBalanceModal(!showInsufficientBalanceModal);
    }

    function toggleLoadDpayModal(): void {
        setShowDpayLoadModal(!showLoadDpayModal);
    }

    function toggleSplitPayModal(): void {
        setShowSplitPayModal(!showSplitPayModal);
    }

    /**
     * Handles the onClick event when a DPAY method is selected.
     * @param method
     */
    async function handleMethodOnClick(method: ActiveDpay): Promise<void> {

        if (method === ActiveDpay.EXTERNAL) {
            if (!account.isConnected) {
                toggleWalletConnectModal();
            } else if (!insufficientExternal) {
                await handleUpdateCart(method);
                setSelectedDpayMethod(ActiveDpay.EXTERNAL);
            }

        } else {
            let noBalance: boolean;

            if (method === ActiveDpay.MAGIC) {
                noBalance = insufficientMagic;
            } else {
                noBalance = insufficientVdpay;
            }

            if (noBalance) {
                void onToggle();
                toggleInsufficientModal();
            } else {
                await handleUpdateCart(method);
            }
        }
    }

    /**
     * Converts DPAY value to USD.
     * @param dpay
     */
    function convertDpayToFiat(dpay: number): number {
        return roundNumber(dpay * menuOrder.dpayFiatAtOrderTime);
    }


    function renderPaymentCreditCardSkeleton(): ReactElement {
        return (
            <div className="checkout-split-setup-modal_body_skeleton">
                <div>
                    <div
                        className="checkout-payments-dpay-modal_skeleton_title react-loading-skeleton"/>
                    <div className="checkout-payments-dpay-modal_skeleton_methods">
                        <div
                            className="checkout-payments-dpay-modal_skeleton_methods_cards react-loading-skeleton"/>
                    </div>
                </div>
                <div>
                    <div
                        className="checkout-payments-dpay-modal_skeleton_title react-loading-skeleton"/>
                    <div className="checkout-payments-dpay-modal_skeleton_methods">
                        <div
                            className="checkout-payments-dpay-modal_skeleton_acct-balance react-loading-skeleton"/>
                        <div
                            className="checkout-payments-dpay-modal_skeleton_methods_cards react-loading-skeleton"/>
                        <div
                            className="checkout-payments-dpay-modal_skeleton_methods_cards react-loading-skeleton"/>
                    </div>
                </div>
                <div
                    className="checkout-payments-dpay-modal_skeleton_methods_cards react-loading-skeleton"/>
            </div>

        );
    }

    function getDpayBalance(): number {
        if (selectedDpayMethod === ActiveDpay.VDPAY) {
            return transactionData?.balance;
        } if (selectedDpayMethod === ActiveDpay.MAGIC) {
            return props.magicBalance;
        }
        return props.externalDpayBalance;
    }

    return (
        <>
            {stripePaymentMethods && selectedCreditCard && selectedCreditCard &&
								<CheckoutPaymentSplitPayModal
								    isOpen={showSplitPayModal}
								    toggle={toggleSplitPayModal}
								    selectedStripePayment={stripePaymentMethods.find(payment => payment.id === selectedCreditCard)}
								    selectedDpayMethod={selectedDpayMethod}
								    paymentMethod={menuOrderPaymentMethod?.method}
								    dpayBalance={getDpayBalance()}
								    wcTag={account?.address
								        ? truncateMiddle(account.address, WALLET_ADDRESS_TRUNCATION)
								        : ""}
								    updateCartWithDpayAmount={updateCartWithDpayAmount}
								/>
            }

            <CheckoutPaymentInsufficientDpayModal
                isOpen={showInsufficientBalanceModal}
                toggle={toggleInsufficientModal}
                toggleLoadDpayModal={toggleLoadDpayModal}
            />

            <CheckoutWalletConnectModal
                isOpen={showWalletConnectModal}
                toggle={toggleWalletConnectModal}
                resetCartWrapper={resetCartWrapper}
                insufficientExternal={insufficientExternal}
                setActiveDpayMethod={setSelectedDpayMethod}
            />

            <CheckoutPaymentLoadDpayModal
                isOpen={showLoadDpayModal}
                toggle={toggleLoadDpayModal}
            />

            <FrameOneAutoPanel
                isOpen={props.isOpen}
                toggle={onToggle}
                contentClassName="checkout-split-setup-modal"
                modalOnTablet={true}
                size="sm2"
            >
                {props.isOpen && !showSplitPayModal &&
										<>
										    <FrameAutoPanelHeader
										        title="Setup Split Pay"
										        isTitleBold={true}
										        toggle={onToggle}
										        tooltipContent={`Select a credit card and a ${import.meta.env.VITE_TOKEN_NAME} method you want to use for the split payment. 
                                In case of refunds, the DPAY will always be returned to your account balance, 
                                regardless of the option you initially selected.`}
										    >
										        <hr/>
										    </FrameAutoPanelHeader>
										    <FrameAutoPanelBody className="checkout-split-setup-modal_body">
										        {!props.showDpayContent || isUpdateActive || isAddCreditCardLoading
										            ? renderPaymentCreditCardSkeleton()
										            : <>
										                <div className="checkout-split-setup-modal_body_credit-card">
										                    <div
										                        className="checkout-split-setup-modal_body_section-title">Select
																						Credit
																						Card
										                    </div>

										                    <CheckoutPaymentSplitSetupCreditCard
										                        selectedPayment={selectedCreditCard}
										                        paymentMethods={stripePaymentMethods}
										                        onUpdateSelectedCreditCard={handleUpdateSelectedCreditCard}
										                        addCreditCard={addCreditCard}
										                    />
										                </div>
										                <div className="checkout-split-setup-modal_body_dpay">
										                    <div
										                        className="checkout-split-setup-modal_body_section-title">
																						Select {import.meta.env.VITE_TOKEN_NAME} Method
										                    </div>

										                    <div
										                        className="checkout-split-setup-modal_body_dpay_methods">
										                        <CheckoutPaymentVdpayCard
										                            insufficientVdpay={insufficientVdpay}
										                            activeDpayMethod={selectedDpayMethod}
										                            handleMethodOnClick={async () => {
										                                await handleMethodOnClick(ActiveDpay.VDPAY);
										                            }}
										                            dpayFiat={convertDpayToFiat(transactionData.balance)}
										                        />
										                        {onChainStatus &&
																								<>
																								    <CheckoutPaymentMagicCard
																								        insufficientMagic={insufficientMagic}
																								        activeDpayMethod={selectedDpayMethod}
																								        handleMethodOnClick={async () => {
																								            await handleMethodOnClick(ActiveDpay.MAGIC);
																								        }}
																								        isUnavailable={!props.isMagicConnected}
																								    />

																								    <CheckoutPaymentExternalCard
																								        insufficientExternal={insufficientExternal}
																								        activeDpayMethod={selectedDpayMethod}
																								        handleMethodOnClick={async () => {
																								            await handleMethodOnClick(ActiveDpay.EXTERNAL);
																								        }}
																								        wcTag={account?.address
																								            ? truncateMiddle(account.address, WALLET_ADDRESS_TRUNCATION)
																								            : ""}
																								    />
																								</>
										                        }
										                    </div>
										                </div>
										                <FrameButton
										                    color="purple"
										                    size="large"
										                    onClick={() => {
										                        setShowSplitPayModal(true);
										                    }}
										                    forwardProps={{
										                        disabled: !selectedCreditCard || selectedDpayMethod == undefined,
										                    }}
										                    className="checkout-split-setup-modal_body_continue"
										                >
																				Set Split Payments
										                </FrameButton>
										            </>
										        }
										    </FrameAutoPanelBody>
										</>
                }

            </FrameOneAutoPanel>
        </>
    );
}

export default CheckoutPaymentSplitSetupModal;
