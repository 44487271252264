/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
    TimeStamps,
    TimeStampsFromJSON,
    TimeStampsFromJSONTyped,
    TimeStampsToJSON,
} from './';

/**
 * A log that\'s generated after a user receives a lootbox upon hitting a GoVIP level
 * @export
 * @interface LootboxMintRecord
 */
export interface LootboxMintRecord {
    /**
     * 
     * @type {string}
     * @memberof LootboxMintRecord
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof LootboxMintRecord
     */
    createdAt: number;
    /**
     * 
     * @type {number}
     * @memberof LootboxMintRecord
     */
    updatedAt: number;
    /**
     * 
     * @type {string}
     * @memberof LootboxMintRecord
     */
    user: string;
    /**
     * 
     * @type {number}
     * @memberof LootboxMintRecord
     */
    userLevel: number;
    /**
     * 
     * @type {string}
     * @memberof LootboxMintRecord
     */
    transactionHash?: string;
    /**
     * 
     * @type {string}
     * @memberof LootboxMintRecord
     */
    nftTracker?: string;
    /**
     * 
     * @type {number}
     * @memberof LootboxMintRecord
     */
    nftId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof LootboxMintRecord
     */
    mintFailed?: boolean;
}

export function LootboxMintRecordFromJSON(json: any): LootboxMintRecord {
    return LootboxMintRecordFromJSONTyped(json, false);
}

export function LootboxMintRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): LootboxMintRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'user': json['user'],
        'userLevel': json['userLevel'],
        'transactionHash': !exists(json, 'transactionHash') ? undefined : json['transactionHash'],
        'nftTracker': !exists(json, 'nftTracker') ? undefined : json['nftTracker'],
        'nftId': !exists(json, 'nftId') ? undefined : json['nftId'],
        'mintFailed': !exists(json, 'mintFailed') ? undefined : json['mintFailed'],
    };
}

export function LootboxMintRecordToJSON(value?: LootboxMintRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'user': value.user,
        'userLevel': value.userLevel,
        'transactionHash': value.transactionHash,
        'nftTracker': value.nftTracker,
        'nftId': value.nftId,
        'mintFailed': value.mintFailed,
    };
}


