import {ReactElement} from "react";

interface Props {
    rows?: number;
    width?: string;
    height?: number;
    classes?: string;
}
function Skeleton(props: Props): ReactElement {
    return (
        <div
            className={`my-community-leaderboard_skeleton-adjust ${props.classes}`}
            style={{width: props?.width
                ? props.width
                : "100%" }}
        >
            {Array.from({length: props.rows}, (_, i) => <div
                key={i}
                style={{
                    height: props.height
                        ? props.height
                        : 65,
                }}
                className="react-loading-skeleton"
            />)}
        </div>
    );
}

export default Skeleton;