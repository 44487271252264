import {useMutation} from "@tanstack/react-query";
import {ApiError, CommerceApi, MenuOrdersApi, UsersApi} from "@devour/client";
import getConfig from "@/utils/getConfig";
import {addError, updateCurrentUser} from "@/redux/meta/metaActions";
import {useDispatch, useSelector} from "react-redux";
import {FormValues} from "@/components/checkout/checkoutPayments/credit/CheckoutPaymentAddCreditModal";
import {IStore} from "@/redux/defaultStore";
import {useGetStripePaymentMethodList} from "@/hooks/useGetStripePaymentMethodList";
import {useGetUserProfile} from "@/hooks/useGetUserProfile";
import {useMenuOrder} from "@/hooks/menuOrder/useMenuOrder";

/**
 * Add a new credit card to Stripe payment methods,
 * Update the user's default payment method to the new credit card,
 * Update the menu order with the new payment method
 *
 * @param menuOrderId
 */
export function useAddCreditPaymentMethod(menuOrderId: string) {
    const dispatch = useDispatch();
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);

    // Fetch list of the user's Stripe payment methods
    const {refetch: refetchPaymentMethods} = useGetStripePaymentMethodList(fullToken);

    // Fetch user profile
    const {refetch: refetchUser} = useGetUserProfile(fullToken);
    const {refetch: refetchMenuOrder} = useMenuOrder(menuOrderId);

    return useMutation({
        mutationFn: async (formValues: FormValues) => {

            const expiryYearStart = 2000;

            const paymentMethod = await new CommerceApi(getConfig()).stripePaymentMethodAdd({
                createPaymentMethodBody: {
                    cardNumber: formValues.cardNumber,
                    expMonth: parseInt(formValues.expDate.formattedValue.split("/")[0]),
                    expYear: expiryYearStart + parseInt(formValues.expDate.formattedValue.split("/")[1]),
                    cvc: formValues.securityCode,
                    postalCode: formValues.postalCode,
                    country: "US",
                },
            });

            await new UsersApi(getConfig(fullToken)).updateDefaultPayment({
                defaultPaymentMethodId: paymentMethod.paymentMethodId,
            });


            const newMenuOrder = await new MenuOrdersApi(getConfig()).updateMenuOrder({
                id: menuOrderId,
                createMenuOrderBody: {
                    paymentMethodId: paymentMethod.paymentMethodId,
                },
            });

            // Update the menuOrder data cache with the new updated menu order
            // No need to refetchMenuOrder. This eliminates the need to
            // refetch the menu order from the database, increasing speed
            await refetchMenuOrder();

            const userRes = await new UsersApi(getConfig(fullToken)).getProfile();
            dispatch(updateCurrentUser(userRes));
            return newMenuOrder;
        },
        onError: async (e) => dispatch(await addError(e as ApiError)),
        onSuccess: async () => {
            await refetchPaymentMethods();
            await refetchUser();
        },
    });
}
