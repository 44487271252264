import React, { FC } from "react";
import Toast, { ToastProps } from "./Toast";
import { useDispatch } from "react-redux";
import { toggleLoginOpen } from "@/redux/meta/metaActions";

type props = Omit<ToastProps, "buttonFunction" | "buttonMessage" | "duration" | "showButton" | "variant" | "message" > & {
    message?: string | React.ReactNode;
};

const SignUpToast: FC<props> = (props) => {
    const dispatch = useDispatch();
    return <Toast
        duration={3000}
        message={props.message ?? "Join DevourGO and start your GoVIP Journey!"}
        isOpen={props.isOpen}
        showButton={true}
        buttonMessage={"Sign Up"}
        buttonFunction={() => {
            dispatch(toggleLoginOpen(true));
        }}
        onDismiss={props.onDismiss}
        {...props}
    />;

};


export default SignUpToast;
