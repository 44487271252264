import {NumberFormatValues} from "react-number-format/types/types";

export interface CreditCardFormValues {
    cardNumber: string;
    expDate: NumberFormatValues;
    securityCode: string;
    country: string;
    postalCode: string;
}

export function isCreditCardValid(formValues: CreditCardFormValues) {
    return Object.values(formValues).every(val => {
        if (typeof val === "object" && val !== null) {
            // check expDate
            return val?.floatValue !== undefined && val?.value?.trim() !== "";
        }
        return val?.trim() !== "";
    });
}