import { useEffect } from "react";

const useTabIsClosing = (trigger: () => void, dependencies: any[] = []) => {
    const handleMouseLeave = (e: MouseEvent) => {
        if (e.clientY < 0) {
            trigger();
        }
    };
    useEffect(() => {
        document.addEventListener("mouseout", handleMouseLeave);
        return () => {
            document.removeEventListener("mouseout", handleMouseLeave);
        };

    }, dependencies);
};

export default useTabIsClosing;
