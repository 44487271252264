/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiError,
    ApiErrorFromJSON,
    ApiErrorToJSON,
    Asset,
    AssetFromJSON,
    AssetToJSON,
    AssetBase64Response,
    AssetBase64ResponseFromJSON,
    AssetBase64ResponseToJSON,
    CreateAssetUploadedBody,
    CreateAssetUploadedBodyFromJSON,
    CreateAssetUploadedBodyToJSON,
    GetAssetsResponse,
    GetAssetsResponseFromJSON,
    GetAssetsResponseToJSON,
    GetAssetsSort,
    GetAssetsSortFromJSON,
    GetAssetsSortToJSON,
    GetUploadAssetUrlBody,
    GetUploadAssetUrlBodyFromJSON,
    GetUploadAssetUrlBodyToJSON,
    GetUploadAssetUrlResponse,
    GetUploadAssetUrlResponseFromJSON,
    GetUploadAssetUrlResponseToJSON,
    SortOrder,
    SortOrderFromJSON,
    SortOrderToJSON,
    StorageDetails,
    StorageDetailsFromJSON,
    StorageDetailsToJSON,
} from '../models';

export interface CreateAssetRequest {
    asset: Blob;
    name?: string;
    description?: string;
    contentType?: string;
}

export interface CreateAssetUploadedRequest {
    createAssetUploadedBody?: CreateAssetUploadedBody;
}

export interface DeleteAssetRequest {
    id: string;
}

export interface GetAssetsRequest {
    search?: string;
    owner?: string;
    sort?: GetAssetsSort;
    order?: SortOrder;
    limit?: number;
    offset?: number;
    numberOfAvailableOffsets?: number;
}

export interface GetUploadAssetUrlRequest {
    getUploadAssetUrlBody?: GetUploadAssetUrlBody;
}

export interface UpdateAssetRequest {
    id: string;
    asset?: Blob;
    name?: string;
    description?: string;
    contentType?: string;
}

export interface ViewAssetBase64Request {
    urlExtension: string;
}

export interface ViewAssetPipeRequest {
    urlExtension: string;
}

export interface ViewAssetRedirectRequest {
    urlExtension: string;
}

/**
 * AssetsApi - interface
 * @export
 * @interface AssetsApiInterface
 */
export interface AssetsApiInterface {
    /**
     * Uploads an asset.
     * @param {Blob} asset The binary for the asset
     * @param {string} [name] 
     * @param {string} [description] 
     * @param {string} [contentType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetsApiInterface
     */
    createAssetRaw(requestParameters: CreateAssetRequest): Promise<runtime.ApiResponse<Asset>>;

    /**
     * Uploads an asset.
     */
    createAsset(requestParameters: CreateAssetRequest): Promise<Asset>;

    /**
     * Used to create an asset after uploading it.
     * @param {CreateAssetUploadedBody} [createAssetUploadedBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetsApiInterface
     */
    createAssetUploadedRaw(requestParameters: CreateAssetUploadedRequest): Promise<runtime.ApiResponse<Asset>>;

    /**
     * Used to create an asset after uploading it.
     */
    createAssetUploaded(requestParameters: CreateAssetUploadedRequest): Promise<Asset>;

    /**
     * Deletes an existing asset
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetsApiInterface
     */
    deleteAssetRaw(requestParameters: DeleteAssetRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Deletes an existing asset
     */
    deleteAsset(requestParameters: DeleteAssetRequest): Promise<void>;

    /**
     * Gets all assets this user has access to.
     * @param {string} [search] 
     * @param {string} [owner] 
     * @param {GetAssetsSort} [sort] 
     * @param {SortOrder} [order] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {number} [numberOfAvailableOffsets] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetsApiInterface
     */
    getAssetsRaw(requestParameters: GetAssetsRequest): Promise<runtime.ApiResponse<GetAssetsResponse>>;

    /**
     * Gets all assets this user has access to.
     */
    getAssets(requestParameters: GetAssetsRequest): Promise<GetAssetsResponse>;

    /**
     * Used to tell how much storage space the user has used, has left, and what the total amount allowed is.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetsApiInterface
     */
    getStorageDetailsRaw(): Promise<runtime.ApiResponse<StorageDetails>>;

    /**
     * Used to tell how much storage space the user has used, has left, and what the total amount allowed is.
     */
    getStorageDetails(): Promise<StorageDetails>;

    /**
     * creates a write-only signed URL to upload an asset.
     * @param {GetUploadAssetUrlBody} [getUploadAssetUrlBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetsApiInterface
     */
    getUploadAssetUrlRaw(requestParameters: GetUploadAssetUrlRequest): Promise<runtime.ApiResponse<GetUploadAssetUrlResponse>>;

    /**
     * creates a write-only signed URL to upload an asset.
     */
    getUploadAssetUrl(requestParameters: GetUploadAssetUrlRequest): Promise<GetUploadAssetUrlResponse>;

    /**
     * Updates an existing asset.
     * @param {string} id 
     * @param {Blob} [asset] The binary for the asset
     * @param {string} [name] 
     * @param {string} [description] 
     * @param {string} [contentType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetsApiInterface
     */
    updateAssetRaw(requestParameters: UpdateAssetRequest): Promise<runtime.ApiResponse<Asset>>;

    /**
     * Updates an existing asset.
     */
    updateAsset(requestParameters: UpdateAssetRequest): Promise<Asset>;

    /**
     * Gets the base64 of a particular asset.
     * @param {string} urlExtension urlExtension of the asset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetsApiInterface
     */
    viewAssetBase64Raw(requestParameters: ViewAssetBase64Request): Promise<runtime.ApiResponse<AssetBase64Response>>;

    /**
     * Gets the base64 of a particular asset.
     */
    viewAssetBase64(requestParameters: ViewAssetBase64Request): Promise<AssetBase64Response>;

    /**
     * pipes the asset from Google Storage.
     * @param {string} urlExtension urlExtension of an asset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetsApiInterface
     */
    viewAssetPipeRaw(requestParameters: ViewAssetPipeRequest): Promise<runtime.ApiResponse<Blob>>;

    /**
     * pipes the asset from Google Storage.
     */
    viewAssetPipe(requestParameters: ViewAssetPipeRequest): Promise<Blob>;

    /**
     * pipes the asset from Google Storage.
     * @param {string} urlExtension urlExtension of an asset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetsApiInterface
     */
    viewAssetRedirectRaw(requestParameters: ViewAssetRedirectRequest): Promise<runtime.ApiResponse<Blob>>;

    /**
     * pipes the asset from Google Storage.
     */
    viewAssetRedirect(requestParameters: ViewAssetRedirectRequest): Promise<Blob>;

}

/**
 * no description
 */
export class AssetsApi extends runtime.BaseAPI implements AssetsApiInterface {

    /**
     * Uploads an asset.
     */
    async createAssetRaw(requestParameters: CreateAssetRequest): Promise<runtime.ApiResponse<Asset>> {
        if (requestParameters.asset === null || requestParameters.asset === undefined) {
            throw new runtime.RequiredError('asset','Required parameter requestParameters.asset was null or undefined when calling createAsset.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.asset !== undefined) {
            formParams.append('asset', requestParameters.asset as any);
        }

        if (requestParameters.name !== undefined) {
            formParams.append('name', requestParameters.name as any);
        }

        if (requestParameters.description !== undefined) {
            formParams.append('description', requestParameters.description as any);
        }

        if (requestParameters.contentType !== undefined) {
            formParams.append('contentType', requestParameters.contentType as any);
        }

        const response = await this.request({
            path: `/assets`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AssetFromJSON(jsonValue));
    }

    /**
     * Uploads an asset.
     */
    async createAsset(requestParameters: CreateAssetRequest): Promise<Asset> {
        const response = await this.createAssetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used to create an asset after uploading it.
     */
    async createAssetUploadedRaw(requestParameters: CreateAssetUploadedRequest): Promise<runtime.ApiResponse<Asset>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/assets/uploaded`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateAssetUploadedBodyToJSON(requestParameters.createAssetUploadedBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AssetFromJSON(jsonValue));
    }

    /**
     * Used to create an asset after uploading it.
     */
    async createAssetUploaded(requestParameters: CreateAssetUploadedRequest): Promise<Asset> {
        const response = await this.createAssetUploadedRaw(requestParameters);
        return await response.value();
    }

    /**
     * Deletes an existing asset
     */
    async deleteAssetRaw(requestParameters: DeleteAssetRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteAsset.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/assets/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes an existing asset
     */
    async deleteAsset(requestParameters: DeleteAssetRequest): Promise<void> {
        await this.deleteAssetRaw(requestParameters);
    }

    /**
     * Gets all assets this user has access to.
     */
    async getAssetsRaw(requestParameters: GetAssetsRequest): Promise<runtime.ApiResponse<GetAssetsResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.owner !== undefined) {
            queryParameters['owner'] = requestParameters.owner;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.numberOfAvailableOffsets !== undefined) {
            queryParameters['numberOfAvailableOffsets'] = requestParameters.numberOfAvailableOffsets;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/assets`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAssetsResponseFromJSON(jsonValue));
    }

    /**
     * Gets all assets this user has access to.
     */
    async getAssets(requestParameters: GetAssetsRequest): Promise<GetAssetsResponse> {
        const response = await this.getAssetsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used to tell how much storage space the user has used, has left, and what the total amount allowed is.
     */
    async getStorageDetailsRaw(): Promise<runtime.ApiResponse<StorageDetails>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/assets/storage-details`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StorageDetailsFromJSON(jsonValue));
    }

    /**
     * Used to tell how much storage space the user has used, has left, and what the total amount allowed is.
     */
    async getStorageDetails(): Promise<StorageDetails> {
        const response = await this.getStorageDetailsRaw();
        return await response.value();
    }

    /**
     * creates a write-only signed URL to upload an asset.
     */
    async getUploadAssetUrlRaw(requestParameters: GetUploadAssetUrlRequest): Promise<runtime.ApiResponse<GetUploadAssetUrlResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/assets/get-upload-url`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetUploadAssetUrlBodyToJSON(requestParameters.getUploadAssetUrlBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUploadAssetUrlResponseFromJSON(jsonValue));
    }

    /**
     * creates a write-only signed URL to upload an asset.
     */
    async getUploadAssetUrl(requestParameters: GetUploadAssetUrlRequest): Promise<GetUploadAssetUrlResponse> {
        const response = await this.getUploadAssetUrlRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates an existing asset.
     */
    async updateAssetRaw(requestParameters: UpdateAssetRequest): Promise<runtime.ApiResponse<Asset>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateAsset.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.asset !== undefined) {
            formParams.append('asset', requestParameters.asset as any);
        }

        if (requestParameters.name !== undefined) {
            formParams.append('name', requestParameters.name as any);
        }

        if (requestParameters.description !== undefined) {
            formParams.append('description', requestParameters.description as any);
        }

        if (requestParameters.contentType !== undefined) {
            formParams.append('contentType', requestParameters.contentType as any);
        }

        const response = await this.request({
            path: `/assets/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AssetFromJSON(jsonValue));
    }

    /**
     * Updates an existing asset.
     */
    async updateAsset(requestParameters: UpdateAssetRequest): Promise<Asset> {
        const response = await this.updateAssetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Gets the base64 of a particular asset.
     */
    async viewAssetBase64Raw(requestParameters: ViewAssetBase64Request): Promise<runtime.ApiResponse<AssetBase64Response>> {
        if (requestParameters.urlExtension === null || requestParameters.urlExtension === undefined) {
            throw new runtime.RequiredError('urlExtension','Required parameter requestParameters.urlExtension was null or undefined when calling viewAssetBase64.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/assets/base-64/{urlExtension}`.replace(`{${"urlExtension"}}`, encodeURIComponent(String(requestParameters.urlExtension))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AssetBase64ResponseFromJSON(jsonValue));
    }

    /**
     * Gets the base64 of a particular asset.
     */
    async viewAssetBase64(requestParameters: ViewAssetBase64Request): Promise<AssetBase64Response> {
        const response = await this.viewAssetBase64Raw(requestParameters);
        return await response.value();
    }

    /**
     * pipes the asset from Google Storage.
     */
    async viewAssetPipeRaw(requestParameters: ViewAssetPipeRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.urlExtension === null || requestParameters.urlExtension === undefined) {
            throw new runtime.RequiredError('urlExtension','Required parameter requestParameters.urlExtension was null or undefined when calling viewAssetPipe.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/assets/pipe/{urlExtension}`.replace(`{${"urlExtension"}}`, encodeURIComponent(String(requestParameters.urlExtension))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * pipes the asset from Google Storage.
     */
    async viewAssetPipe(requestParameters: ViewAssetPipeRequest): Promise<Blob> {
        const response = await this.viewAssetPipeRaw(requestParameters);
        return await response.value();
    }

    /**
     * pipes the asset from Google Storage.
     */
    async viewAssetRedirectRaw(requestParameters: ViewAssetRedirectRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.urlExtension === null || requestParameters.urlExtension === undefined) {
            throw new runtime.RequiredError('urlExtension','Required parameter requestParameters.urlExtension was null or undefined when calling viewAssetRedirect.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/assets/redirect/{urlExtension}`.replace(`{${"urlExtension"}}`, encodeURIComponent(String(requestParameters.urlExtension))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * pipes the asset from Google Storage.
     */
    async viewAssetRedirect(requestParameters: ViewAssetRedirectRequest): Promise<Blob> {
        const response = await this.viewAssetRedirectRaw(requestParameters);
        return await response.value();
    }

}
