import {useMutation} from "@tanstack/react-query";
import {ApiError, CommerceApi, MenuOrdersApi, UsersApi} from "@devour/client";
import getConfig from "@/utils/getConfig";
import {
    addError,
    removeDpayPriceExpiryTime,
    removeMenuOrder,
    updateCurrentUser,
} from "@/redux/meta/metaActions";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {useGetStripePaymentMethodList} from "@/hooks/useGetStripePaymentMethodList";
import {useGetUserProfile} from "@/hooks/useGetUserProfile";
import {useMenuOrder} from "@/hooks/menuOrder/useMenuOrder";
import {useNavigate} from "react-router-dom";
import {StripePaymentMethodObject} from "@/types/Stripe";
import {useRestaurant} from "@/hooks/useRestaurant";

/**
 * Delete a credit card from Stripe payment methods,
 * Update the user's default payment method if the selected card was the default
 *
 */
export function useDeleteCreditPaymentMethod(menuOrderId: string) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const currentUser = useSelector((store: IStore) => store.metaStore.currentUser?.user);

    // Fetch list of the user's Stripe payment methods
    const {data: paymentMethodData, refetch: refetchPaymentMethods} = useGetStripePaymentMethodList(fullToken);
    const paymentMethods = (paymentMethodData?.paymentMethods) as Array<StripePaymentMethodObject>;

    // Fetch user profile
    const {refetch: refetchUser} = useGetUserProfile(fullToken);

    const {data: menuOrder, refetch: refetchMenuOrder} = useMenuOrder(menuOrderId);
    const {data: restaurant} = useRestaurant(menuOrder?.business);

    async function updateMenuOrderPaymentMethod(paymentMethodId: string): Promise<void> {
        if (!menuOrderId) {
            return;
        }

        try {
            await new MenuOrdersApi(getConfig()).updateMenuOrder({
                id: menuOrderId,
                createMenuOrderBody: {
                    paymentMethodId: paymentMethodId,
                },
            });

            await refetchMenuOrder();
        } catch (e) {
            dispatch(await addError(e));
            dispatch(removeMenuOrder(menuOrder?.business));
            await refetchMenuOrder();
            dispatch(removeDpayPriceExpiryTime());
            navigate(`${restaurant?.url}/${menuOrder?.address?.placeId}`);
        }
    }

    return useMutation({
        mutationFn: async (paymentId: string) => {

            await new CommerceApi(getConfig()).stripePaymentMethodDelete({
                id: paymentId,
            });

            if (currentUser?.defaultPaymentMethodId === paymentId) {

                let newDefaultPaymentMethod = "";
                const remainingPaymentMethods = paymentMethods.filter(method => {
                    return method.id !== paymentId;
                });

                if (remainingPaymentMethods.length) {
                    newDefaultPaymentMethod = remainingPaymentMethods[0].id;
                }
                // Clear the user's default payment method if the deleted card was the default.
                await new UsersApi(getConfig(fullToken)).updateDefaultPayment({
                    defaultPaymentMethodId: newDefaultPaymentMethod,
                });

                // Update redux store just in case other components depend on it.
                const userRes = await new UsersApi(getConfig(fullToken)).getProfile();
                dispatch(updateCurrentUser(userRes));
                await updateMenuOrderPaymentMethod(userRes.user.defaultPaymentMethodId);
            }
        },
        onError: async (e) => dispatch(await addError(e as ApiError)),
        onSuccess: async () => {
            await refetchPaymentMethods();
            await refetchUser();
        },
    });
}
