import {PropsWithChildren, ReactElement} from "react";

interface Props {
    month: string;
    day: string;
    leftTitle: string;
    leftSubtitle: string;
    leftSubTitleHref?: string;
    rightTitle: string;
    rightSubtitle: string;
    rightSubtitleHref?: string;
}

function TransactionHistoryCard(props: PropsWithChildren<Props>): ReactElement {

    return (
        <div className="tx-history-card">
            <div className="tx-history-card_left">
                <div className="tx-history-card_left_dating">
                    <h6 className="tx-history-card_left_dating_month">
                        {props.month}
                    </h6>

                    <h4 className="tx-history-card_left_dating_date">
                        {props.day}
                    </h4>
                </div>

                <div className="tx-history-card_left_tx-details">
                    <h5 className="tx-history-card_left_tx-details_action">
                        {props.leftTitle}
                    </h5>

                    <p className="p2-sub-body">
                        {props.leftSubTitleHref
                            ? <a
                                href={props.leftSubTitleHref}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {props.leftSubtitle}
                            </a>
						 : props.leftSubtitle}
                    </p>

                    {props.children}
                </div>
            </div>

            <div className="tx-history-card_right">
                <h5 className="tx-history-card_right_crypto">
                    {props.rightTitle}
                </h5>

                <p className="p2-sub-body">
                    {props.rightSubtitleHref
                        ? <a
                            href={props.rightSubtitleHref}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {props.rightSubtitle}
                        </a>
					 : props.rightSubtitle}
                </p>
            </div>
        </div>
    );
}

export default TransactionHistoryCard;
