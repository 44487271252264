import {OrderDiscount, OrderDiscountSearchEssential} from "@devour/client";

enum GoFrensTagType {
    PERCENT = "percent",
    BOGO = "bogo",
    FLAT_RATE = "flat",
}


export function getPromoInfo(promo: OrderDiscount | OrderDiscountSearchEssential): [GoFrensTagType, string] {
    let tagType: GoFrensTagType;
    let promoTitle: string;

    if (promo?.label.toLowerCase().includes("free") || promo?.label.includes("%") || promo?.label.includes("percent") || promo?.label.includes("per cent")) {
        tagType = GoFrensTagType.PERCENT;
    } else if (promo?.label.includes("$")) {
        tagType = GoFrensTagType.FLAT_RATE;
    } else if (promo?.label.includes("BOGO")) {
        tagType = GoFrensTagType.BOGO;
    }

    switch (tagType) {
        case GoFrensTagType.PERCENT:
            promoTitle = `${promo?.amount}% OFF`;
            break;
        case GoFrensTagType.FLAT_RATE:
            promoTitle = `$${promo?.amount} OFF`;
            break;
        case GoFrensTagType.BOGO:
            promoTitle = "BOGO";
            break;
        default:
            break;
    }

    return [
        tagType,
        promoTitle,
    ];
}
