import {ReactElement} from "react";
import Skeleton from "react-loading-skeleton";

function RestaurantMenuPageTopBarSkeleton(): ReactElement {
    return (
        <div className='restaurant-menu-page-top-bar-skeleton'>
            <div className='restaurant-menu-page-top-bar-skeleton_address'>
                <Skeleton width={163} height={48} style={{borderRadius: "16px"}}/>
            </div>
            <div className='restaurant-menu-page-top-bar-skeleton_right'>
                <div className='restaurant-menu-page-top-bar-skeleton_right_cart'>
                    <Skeleton width={80} height={48} style={{borderRadius: "16px"}}/>
                </div>
                <div className='restaurant-menu-page-top-bar-skeleton_right_account'>
                    <Skeleton width={165} height={48} style={{borderRadius: "16px"}}/>
                </div>
            </div>

        </div>
    );
}

export default RestaurantMenuPageTopBarSkeleton;