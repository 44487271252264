/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum BusinessPermission {
    BILLING = 'BILLING',
    USERS = 'USERS',
    MENUS = 'MENUS',
    CONTACT = 'CONTACT',
    ORDERSVIEW = 'ORDERS_VIEW',
    OPERATIONS = 'OPERATIONS'
}

export function BusinessPermissionFromJSON(json: any): BusinessPermission {
    return BusinessPermissionFromJSONTyped(json, false);
}

export function BusinessPermissionFromJSONTyped(json: any, ignoreDiscriminator: boolean): BusinessPermission {
    return json as BusinessPermission;
}

export function BusinessPermissionToJSON(value?: BusinessPermission | null): any {
    return value as any;
}

