/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BrandMapBannerImageBody
 */
export interface BrandMapBannerImageBody {
    /**
     * 
     * @type {string}
     * @memberof BrandMapBannerImageBody
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandMapBannerImageBody
     */
    imageType?: string;
}

export function BrandMapBannerImageBodyFromJSON(json: any): BrandMapBannerImageBody {
    return BrandMapBannerImageBodyFromJSONTyped(json, false);
}

export function BrandMapBannerImageBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): BrandMapBannerImageBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'image': !exists(json, 'image') ? undefined : json['image'],
        'imageType': !exists(json, 'imageType') ? undefined : json['imageType'],
    };
}

export function BrandMapBannerImageBodyToJSON(value?: BrandMapBannerImageBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'image': value.image,
        'imageType': value.imageType,
    };
}


