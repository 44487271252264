import {ReactElement, useEffect, useState} from "react";
import FrameOneAutoPanel from "./autoPanelComponents/FrameOneAutoPanel";
import FrameAutoPanelHeader from "./autoPanelComponents/FrameAutoPanelHeader";
import FrameAutoPanelBody from "./autoPanelComponents/FrameAutoPanelBody";
import {useNavigate} from "react-router";

interface Props {
    isOpen: boolean;
    error: string;
}

function RefreshModal(props: Props): ReactElement {

    const SECONDS_TO_REFRESH = 10;

    const [
        seconds,
        setSeconds,
    ] = useState<number>(SECONDS_TO_REFRESH);
    const [
        redirected,
        setRedirected,
    ] = useState<boolean>(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (props.isOpen) {
            if (seconds > 0) {
                setTimeout(() => {
                    setSeconds(seconds - 1);
                }, 1000);
            } else {
                if (!redirected) {
                    setRedirected(true);
                    navigate(0);
                }
            }
        } else {
            setSeconds(SECONDS_TO_REFRESH);
        }
    }, [
        props.isOpen,
        seconds,
    ]);

    return (
        <FrameOneAutoPanel
            isOpen={props.isOpen}
            contentClassName="contact-modal"
            size="md"
        >
            <FrameAutoPanelHeader
                title={`Error: ${props.error}`}
            />
            <FrameAutoPanelBody className="contact-modal_body">
                <p>
                    {`This page will automatically refresh in ${seconds} seconds.`}
                </p>
                <p>
					Oh no! An error occurred while trying to process your on-chain transaction. Please try again, or
					contact support if you need more help.
                </p>
                <p className="share-to-earn_redirect-text">
                    <a href="javascript:void(0)" onClick={() => navigate(0)}>Click here</a> to manually refresh.
                </p>
            </FrameAutoPanelBody>
        </FrameOneAutoPanel>
    );
}

export default RefreshModal;
