/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BusinessServiceAvailability,
    BusinessServiceAvailabilityFromJSON,
    BusinessServiceAvailabilityFromJSONTyped,
    BusinessServiceAvailabilityToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreateMenuCategoryBody
 */
export interface CreateMenuCategoryBody {
    /**
     * 
     * @type {string}
     * @memberof CreateMenuCategoryBody
     */
    parent?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateMenuCategoryBody
     */
    business?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateMenuCategoryBody
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof CreateMenuCategoryBody
     */
    sortOrder?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateMenuCategoryBody
     */
    isEnabled?: boolean;
    /**
     * 
     * @type {Array<BusinessServiceAvailability>}
     * @memberof CreateMenuCategoryBody
     */
    serviceAvailabilities?: Array<BusinessServiceAvailability>;
}

export function CreateMenuCategoryBodyFromJSON(json: any): CreateMenuCategoryBody {
    return CreateMenuCategoryBodyFromJSONTyped(json, false);
}

export function CreateMenuCategoryBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateMenuCategoryBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'parent': !exists(json, 'parent') ? undefined : json['parent'],
        'business': !exists(json, 'business') ? undefined : json['business'],
        'name': json['name'],
        'sortOrder': !exists(json, 'sortOrder') ? undefined : json['sortOrder'],
        'isEnabled': !exists(json, 'isEnabled') ? undefined : json['isEnabled'],
        'serviceAvailabilities': !exists(json, 'serviceAvailabilities') ? undefined : ((json['serviceAvailabilities'] as Array<any>).map(BusinessServiceAvailabilityFromJSON)),
    };
}

export function CreateMenuCategoryBodyToJSON(value?: CreateMenuCategoryBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'parent': value.parent,
        'business': value.business,
        'name': value.name,
        'sortOrder': value.sortOrder,
        'isEnabled': value.isEnabled,
        'serviceAvailabilities': value.serviceAvailabilities === undefined ? undefined : ((value.serviceAvailabilities as Array<any>).map(BusinessServiceAvailabilityToJSON)),
    };
}


