import {ButtonHTMLAttributes, ReactElement, useContext, useRef} from "react";
import FrameButton from "./buttons/FrameButton";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router";
import {RestaurantContext} from "../pages/restaurants/context/RestaurantContext";
import {toggleLoginOpen, updateLastMenuOrderId} from "../redux/meta/metaActions";
import {HiArrowRight} from "react-icons/hi";
import {BrandMapStates} from "@/pages/brandMap/context/BrandMapContext";
import { useOnLogin } from "@/hooks/useOnLogin";
import { store } from "@/redux";

interface Props {
    toggle: () => void;
    disabled?: boolean;
}

function CartCheckoutButton(props: Props): ReactElement {
    const hasPendingCheckout = useRef(false);
    const history = useNavigate();
    const dispatch = useDispatch();
    const {menuOrder, embeddedMenu, setCheckoutState} = useContext(RestaurantContext);

    function onCheckoutAttempt() {
        const fullToken = store.getState().authStore.fullToken;
        dispatch(updateLastMenuOrderId(menuOrder?.id));

        if (embeddedMenu) {
            props.toggle();
            setCheckoutState(BrandMapStates.CHECKOUT_IN_PROGRESS);
            return;
        }
        if (fullToken) {
            history(`/checkout/${menuOrder?.id}`);
        } else {
            hasPendingCheckout.current = true;
            dispatch(toggleLoginOpen(true));
        }
    }

    useOnLogin(() => {
        if (hasPendingCheckout.current && menuOrder) {
            onCheckoutAttempt();
        }
    }, [menuOrder]);


    return (
        <>
            <FrameButton
                <ButtonHTMLAttributes<HTMLButtonElement>>
                color={props.disabled
                    ? "gray"
                    : "purple"}
                size="large"
                onClick={onCheckoutAttempt}
                showSpinner={true}
                className={`cart-checkout-button ${props.disabled
                    ? " disabled"
                    : ""}`}
            >
                <span>Checkout</span>
                {!props.disabled
                    ? <span>
					${menuOrder.grandTotal.toFixed(2)}
                        <HiArrowRight size=".5em"/>
                    </span>
                    : <span>
					$0.00
                        <HiArrowRight size=".5em"/>
                    </span>
                }
            </FrameButton>
        </>
    );
}

export default CartCheckoutButton;
