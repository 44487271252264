/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GeoJsonType,
    GeoJsonTypeFromJSON,
    GeoJsonTypeFromJSONTyped,
    GeoJsonTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface GeoPoint
 */
export interface GeoPoint {
    /**
     * 
     * @type {GeoJsonType}
     * @memberof GeoPoint
     */
    type: GeoJsonType;
    /**
     * 
     * @type {Array<number>}
     * @memberof GeoPoint
     */
    coordinates: Array<number>;
}

export function GeoPointFromJSON(json: any): GeoPoint {
    return GeoPointFromJSONTyped(json, false);
}

export function GeoPointFromJSONTyped(json: any, ignoreDiscriminator: boolean): GeoPoint {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': GeoJsonTypeFromJSON(json['type']),
        'coordinates': json['coordinates'],
    };
}

export function GeoPointToJSON(value?: GeoPoint | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': GeoJsonTypeToJSON(value.type),
        'coordinates': value.coordinates,
    };
}


