/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiError,
    ApiErrorFromJSON,
    ApiErrorToJSON,
    FrontendErrorBody,
    FrontendErrorBodyFromJSON,
    FrontendErrorBodyToJSON,
    InlineResponse20010,
    InlineResponse20010FromJSON,
    InlineResponse20010ToJSON,
} from '../models';

export interface CreateFrontendErrorRequest {
    frontendErrorBody?: FrontendErrorBody;
}

/**
 * FrontendErrorsApi - interface
 * @export
 * @interface FrontendErrorsApiInterface
 */
export interface FrontendErrorsApiInterface {
    /**
     * Used to create new frontend error log
     * @param {FrontendErrorBody} [frontendErrorBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontendErrorsApiInterface
     */
    createFrontendErrorRaw(requestParameters: CreateFrontendErrorRequest): Promise<runtime.ApiResponse<InlineResponse20010>>;

    /**
     * Used to create new frontend error log
     */
    createFrontendError(requestParameters: CreateFrontendErrorRequest): Promise<InlineResponse20010>;

}

/**
 * no description
 */
export class FrontendErrorsApi extends runtime.BaseAPI implements FrontendErrorsApiInterface {

    /**
     * Used to create new frontend error log
     */
    async createFrontendErrorRaw(requestParameters: CreateFrontendErrorRequest): Promise<runtime.ApiResponse<InlineResponse20010>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/frontend-errors`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FrontendErrorBodyToJSON(requestParameters.frontendErrorBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20010FromJSON(jsonValue));
    }

    /**
     * Used to create new frontend error log
     */
    async createFrontendError(requestParameters: CreateFrontendErrorRequest): Promise<InlineResponse20010> {
        const response = await this.createFrontendErrorRaw(requestParameters);
        return await response.value();
    }

}
