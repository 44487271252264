import {ReactElement, ReactNode} from "react";
import {AddressBook} from "@devour/client";
import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import ManageAddressBookModalAddressDisplay
    from "../../../components/ManageAddressBookModalAddressDisplay";

interface Props {
    onAddressBookSubmit: (addressBook: AddressBook) => Promise<void>;
    onUpdateAddress: (addressBook: AddressBook) => void;
    defaultSelectedAddressId?: string;
}

function RestaurantAddressAutoPanelAddressBook(props: Props): ReactElement {
    const currentUser = useSelector((store: IStore) => store.metaStore.currentUser);
    const addressBookSelected = currentUser?.user?.addresses?.find((addressBook) => addressBook.id === props.defaultSelectedAddressId);

    function renderAddress(addressBook: AddressBook, i: number): ReactNode {
        return (
            <div
                className="restaurant-search-address-bottom-panel_body_address-book_item"
                key={`address-display_${i}`}
            >
                <ManageAddressBookModalAddressDisplay
                    addressBook={addressBook}
                    selected={addressBookSelected?.id === addressBook.id}
                    onSelect={(newAddressBook) => props.onAddressBookSubmit(newAddressBook)}
                    onUpdateAddress={props.onUpdateAddress}
                />
            </div>
        );
    }

    if (!currentUser?.user?.addresses?.length) {
        return null;
    }

    return (
        <div className="restaurant-search-address-bottom-panel_body_address-book">
            <p className="restaurant-search-address-bottom-panel_body_address-book_title">
								Saved Address
            </p>
            {currentUser.user.addresses.map(renderAddress)}
        </div>
    );
}

export default RestaurantAddressAutoPanelAddressBook;
