import {ReactElement} from "react";
import Skeleton from "react-loading-skeleton";

function GoVipPageShare2EarnSkeleton(): ReactElement {
    return (
        <div className='go-vip-share2earn_body'>
            {Array.from({length: 6}, (_, i) => <div
                className="go-vip-share2earn_action-card go-vip-share2earn_action-card_hover go-vip-share2earn_action-card_skeleton"
                key={i}
            >
                <Skeleton height={98} borderRadius='1rem'/>
            </div>)}
        </div>
    );
}

export default GoVipPageShare2EarnSkeleton;