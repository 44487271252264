/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GleamEntryUpload
 */
export interface GleamEntryUpload {
    /**
     * 
     * @type {string}
     * @memberof GleamEntryUpload
     */
    original_url: string;
    /**
     * 
     * @type {string}
     * @memberof GleamEntryUpload
     */
    thumb_url: string;
    /**
     * 
     * @type {string}
     * @memberof GleamEntryUpload
     */
    post_url: string;
    /**
     * 
     * @type {string}
     * @memberof GleamEntryUpload
     */
    post_text: string;
    /**
     * 
     * @type {string}
     * @memberof GleamEntryUpload
     */
    content_type: string;
}

export function GleamEntryUploadFromJSON(json: any): GleamEntryUpload {
    return GleamEntryUploadFromJSONTyped(json, false);
}

export function GleamEntryUploadFromJSONTyped(json: any, ignoreDiscriminator: boolean): GleamEntryUpload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'original_url': json['original_url'],
        'thumb_url': json['thumb_url'],
        'post_url': json['post_url'],
        'post_text': json['post_text'],
        'content_type': json['content_type'],
    };
}

export function GleamEntryUploadToJSON(value?: GleamEntryUpload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'original_url': value.original_url,
        'thumb_url': value.thumb_url,
        'post_url': value.post_url,
        'post_text': value.post_text,
        'content_type': value.content_type,
    };
}


