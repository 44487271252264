/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddBrandMerchantBody
 */
export interface AddBrandMerchantBody {
    /**
     * 
     * @type {string}
     * @memberof AddBrandMerchantBody
     */
    brand: string;
    /**
     * 
     * @type {string}
     * @memberof AddBrandMerchantBody
     */
    merchant: string;
    /**
     * 
     * @type {boolean}
     * @memberof AddBrandMerchantBody
     */
    createMaps: boolean;
}

export function AddBrandMerchantBodyFromJSON(json: any): AddBrandMerchantBody {
    return AddBrandMerchantBodyFromJSONTyped(json, false);
}

export function AddBrandMerchantBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddBrandMerchantBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'brand': json['brand'],
        'merchant': json['merchant'],
        'createMaps': json['createMaps'],
    };
}

export function AddBrandMerchantBodyToJSON(value?: AddBrandMerchantBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'brand': value.brand,
        'merchant': value.merchant,
        'createMaps': value.createMaps,
    };
}


