import {
    ButtonHTMLAttributes,
    ChangeEventHandler,
    FormEvent,
    ReactElement,
    useContext,
    useEffect,
    useState,
} from "react";
import FrameButton from "../../buttons/FrameButton";
import FrameOneAutoPanel from "../../modals/autoPanelComponents/FrameOneAutoPanel";
import FrameAutoPanelHeader from "../../modals/autoPanelComponents/FrameAutoPanelHeader";
import FrameAutoPanelBody from "../../modals/autoPanelComponents/FrameAutoPanelBody";
import {addError, refreshMenuOrderCart, updateCurrentUser} from "@/redux/meta/metaActions";
import {useDispatch, useSelector} from "react-redux";
import {useMenuOrder} from "@/hooks/menuOrder/useMenuOrder";
import {useParams} from "react-router";
import {
    UpdateMenuOrderDeliveryNotesFormValues,
    useUpdateMenuOrderDeliveryNotes,
} from "@/hooks/menuOrder/useUpdateMenuOrderDeliveryNotes";
import {RestaurantContext} from "@/pages/restaurants/context/RestaurantContext";
import {AddressBooksApi, DeliveryHandoffInstructions, UsersApi } from "@devour/client";
import classNames from "classnames";
import {IStore} from "@/redux/defaultStore";
import getConfig from "@/utils/getConfig";

const defaultValues: UpdateMenuOrderDeliveryNotesFormValues = {
    deliveryNotes: "",
    deliveryHandoffInstructions: undefined,
};

interface Props {
    isOpen: boolean;
    onClose: () => void;
    // React Query's mutate function to update the delivery notes
    updateMenuOrderDeliveryNotes: ReturnType<typeof useUpdateMenuOrderDeliveryNotes>["mutate"];
}

function CheckoutDetailsDeliveryNotesModal(props: Props): ReactElement {
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const currentUser = useSelector((store: IStore) => store.metaStore.currentUser);
    const { menuOrderId: contextMenuOrderId } = useContext(RestaurantContext);
    const { menuOrderId: paramMenuOrderId } = useParams<{ menuOrderId: string }>();
    const menuOrderId = paramMenuOrderId || contextMenuOrderId;

    const {data: menuOrder} = useMenuOrder(menuOrderId);
    const [
        formValues,
        setFormValues,
    ] = useState<UpdateMenuOrderDeliveryNotesFormValues>(defaultValues);
    const dispatch = useDispatch();

    useEffect(() => {
        if (menuOrder && props.isOpen) {
            setFormValues({
                deliveryNotes: menuOrder.deliveryNotes,
                deliveryHandoffInstructions: menuOrder.deliveryHandoffInstructions,
            });
        }
    }, [menuOrder, props.isOpen]);

    /**
     * When form is submitted, close the modal and update the menu order's delivery notes.
     *
     * @param e
     */
    async function onFormSubmit(e: FormEvent<HTMLFormElement>): Promise<void> {
        e.preventDefault();
        try {
            // React Query's mutate function is used to call API and update the delivery notes
            props.updateMenuOrderDeliveryNotes(formValues);
            setFormValues(defaultValues);
            dispatch(refreshMenuOrderCart());
            props.onClose();
        } catch (e) {
            dispatch(await addError(e));
        }

        // Update linked address book form
        const findAddressInBook = currentUser?.user?.addresses.find(a => a.placeId === menuOrder.address.placeId);
        if (findAddressInBook) {
            await new AddressBooksApi(getConfig(fullToken)).updateAddressBook({
                id: findAddressInBook.id,
                createAddressBookBody: {
                    ...findAddressInBook,
                    deliveryInstructions: formValues.deliveryNotes,
                    handoffInstructions: formValues.deliveryHandoffInstructions,
                },
            });
            const userRes = await new UsersApi(getConfig(fullToken)).getProfile();
            dispatch(updateCurrentUser(userRes));

        }

    }

    /**
     * Handle delivery handoff onChange events.
     *
     * @param handoffInstructions
     */
    function deliveryHandoffOnChange(handoffInstructions: DeliveryHandoffInstructions): void {
        if (formValues.deliveryHandoffInstructions === handoffInstructions) {
            // "New" value is the same as previous value. Reset the value back to null or "OTHER"
            setFormValues({
                ...formValues,
                deliveryHandoffInstructions: DeliveryHandoffInstructions.OTHER,
            });
        } else {
            setFormValues({
                ...formValues,
                deliveryHandoffInstructions: handoffInstructions,
            });
        }
    }


    /**
     * Handle all text input onChange events.
     *
     * @param key
     */
    function inputOnChange(key: keyof UpdateMenuOrderDeliveryNotesFormValues): ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement> {
        return (e) => {
            setFormValues({
                ...formValues,
                [key]: e.target.value,
            });
        };
    }

    /**
     * When closing the modal without saving,
     * set the form value to the menu order's delivery notes.
     */
    function onCloseModal() {
        props.onClose();
    }

    return (
        <div className="checkout-details_delivery-notes">
            <FrameOneAutoPanel
                isOpen={props.isOpen}
                toggle={onCloseModal}
                size="sm"
                contentClassName="menu-order-delivery-notes-modal"
                modalOnTablet={true}
            >
                <FrameAutoPanelHeader
                    title="Add delivery instructions"
                    toggle={onCloseModal}
                    modalOnTablet={true}
                />

                <FrameAutoPanelBody
                    modalOnTablet={true}
                    className="menu-order-delivery-notes-modal_body"
                >

                    <hr/>

                    <form onSubmit={onFormSubmit}>

                        <div className="menu-order-delivery-notes-modal_body_delivery-notes">
                            <div
                                className="menu-order-delivery-notes-modal_body_delivery-notes_options">
                                <button
                                    className={classNames("menu-order-delivery-notes-modal_body_delivery-notes_options_item", {
                                        "is-active": formValues.deliveryHandoffInstructions === DeliveryHandoffInstructions.HANDITTOME,
                                    })}
                                    type="button"
                                    onClick={() => deliveryHandoffOnChange(DeliveryHandoffInstructions.HANDITTOME)}
                                >
                                    Hand it to me
                                </button>
                                <button
                                    className={classNames("menu-order-delivery-notes-modal_body_delivery-notes_options_item", {
                                        "is-active": formValues.deliveryHandoffInstructions === DeliveryHandoffInstructions.LEAVEITATMYDOOR,
                                    })}
                                    type="button"
                                    onClick={() => deliveryHandoffOnChange(DeliveryHandoffInstructions.LEAVEITATMYDOOR)}
                                >
                                    Leave it at my door
                                </button>
                            </div>


                            <textarea
                                className="checkout-details_delivery-notes_textarea"
                                placeholder="Add delivery instructions"
                                value={formValues.deliveryNotes}
                                onChange={inputOnChange("deliveryNotes")}
                            />
                        </div>

                        <FrameButton
                            <ButtonHTMLAttributes<HTMLButtonElement>>
                            color="purple"
                            size="normal"
                            className="menu-order-delivery-notes-modal_body_submit-button"
                            showSpinner={false}
                            forwardProps={{
                                type: "submit",
                            }}
                        >
                            Save
                        </FrameButton>
                    </form>
                </FrameAutoPanelBody>
            </FrameOneAutoPanel>
        </div>
    );
}

export default CheckoutDetailsDeliveryNotesModal;
