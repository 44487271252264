/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiError,
    ApiErrorFromJSON,
    ApiErrorToJSON,
    CheckTokenExpirationResponse,
    CheckTokenExpirationResponseFromJSON,
    CheckTokenExpirationResponseToJSON,
    TokenBody,
    TokenBodyFromJSON,
    TokenBodyToJSON,
} from '../models';

export interface CheckTokenExpirationRequest {
    tokenBody?: TokenBody;
}

export interface DeleteTokenRequest {
    tokenBody?: TokenBody;
}

/**
 * TokensApi - interface
 * @export
 * @interface TokensApiInterface
 */
export interface TokensApiInterface {
    /**
     * It takes in a token and returns a boolean if its still active. If the token is still active, then extend the token\'s expiration.
     * @param {TokenBody} [tokenBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokensApiInterface
     */
    checkTokenExpirationRaw(requestParameters: CheckTokenExpirationRequest): Promise<runtime.ApiResponse<CheckTokenExpirationResponse>>;

    /**
     * It takes in a token and returns a boolean if its still active. If the token is still active, then extend the token\'s expiration.
     */
    checkTokenExpiration(requestParameters: CheckTokenExpirationRequest): Promise<CheckTokenExpirationResponse>;

    /**
     * deletes a token
     * @param {TokenBody} [tokenBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokensApiInterface
     */
    deleteTokenRaw(requestParameters: DeleteTokenRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * deletes a token
     */
    deleteToken(requestParameters: DeleteTokenRequest): Promise<void>;

}

/**
 * no description
 */
export class TokensApi extends runtime.BaseAPI implements TokensApiInterface {

    /**
     * It takes in a token and returns a boolean if its still active. If the token is still active, then extend the token\'s expiration.
     */
    async checkTokenExpirationRaw(requestParameters: CheckTokenExpirationRequest): Promise<runtime.ApiResponse<CheckTokenExpirationResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/tokens/check-expiration`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TokenBodyToJSON(requestParameters.tokenBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CheckTokenExpirationResponseFromJSON(jsonValue));
    }

    /**
     * It takes in a token and returns a boolean if its still active. If the token is still active, then extend the token\'s expiration.
     */
    async checkTokenExpiration(requestParameters: CheckTokenExpirationRequest): Promise<CheckTokenExpirationResponse> {
        const response = await this.checkTokenExpirationRaw(requestParameters);
        return await response.value();
    }

    /**
     * deletes a token
     */
    async deleteTokenRaw(requestParameters: DeleteTokenRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/tokens`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: TokenBodyToJSON(requestParameters.tokenBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * deletes a token
     */
    async deleteToken(requestParameters: DeleteTokenRequest): Promise<void> {
        await this.deleteTokenRaw(requestParameters);
    }

}
