/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum WithdrawWalletChain {
    ETHEREUM = 'ETHEREUM',
    SOLANA = 'SOLANA'
}

export function WithdrawWalletChainFromJSON(json: any): WithdrawWalletChain {
    return WithdrawWalletChainFromJSONTyped(json, false);
}

export function WithdrawWalletChainFromJSONTyped(json: any, ignoreDiscriminator: boolean): WithdrawWalletChain {
    return json as WithdrawWalletChain;
}

export function WithdrawWalletChainToJSON(value?: WithdrawWalletChain | null): any {
    return value as any;
}

