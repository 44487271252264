/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum GeoJsonType {
    Point = 'Point'
}

export function GeoJsonTypeFromJSON(json: any): GeoJsonType {
    return GeoJsonTypeFromJSONTyped(json, false);
}

export function GeoJsonTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): GeoJsonType {
    return json as GeoJsonType;
}

export function GeoJsonTypeToJSON(value?: GeoJsonType | null): any {
    return value as any;
}

