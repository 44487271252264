/**
 * Unescape HTML entities to get the pure content, because React does a second level of escaping.
 * https://stackoverflow.com/a/34064434
 * @param input
 */
export function htmlDecode(input: string): string | null {
    if (!input) return "";
    const doc = new DOMParser().parseFromString(input, "text/html");
    return doc.documentElement.textContent;
}
