import {ReactElement} from "react";
import FrameOneModal from "./modalComponents/FrameOneModal";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameButton from "../buttons/FrameButton";

interface Props {
    isOpen: boolean;
    toggle: () => void;
}

function DpayCardInfoModal(props: Props): ReactElement {

    return (
        <FrameOneModal
            isOpen={props.isOpen}
            toggle={props.toggle}
            contentClassName="dpay-card-info-modal"
        >
            <FrameModalHeader
                title={`${import.meta.env.VITE_TOKEN_NAME} Details`}
                toggle={props.toggle}
            />

            <FrameModalBody className="dpay-card-info-modal_body">
                <p>
					          The {import.meta.env.VITE_TOKEN_NAME} shown reflects your DevourGO account balance,
                    not any amounts in your connected wallets.
                </p>

                <p>
                    {import.meta.env.VITE_TOKEN_NAME} is the exclusive payment and rewards of DevourGO.
                </p>

                <div className="dpay-card-info-modal_body_btn-container">
                    <FrameButton
                        color="purple"
                        size="normal"
                        onClick={props.toggle}
                    >
					              Close
                    </FrameButton>
                </div>
            </FrameModalBody>
        </FrameOneModal>
    );
}

export default DpayCardInfoModal;
