import {FC, PropsWithChildren, ReactElement} from "react";
import {FiX} from "react-icons/fi";
import {IoChevronBackOutline} from "react-icons/io5";
import {BsFillQuestionCircleFill} from "react-icons/bs";
import Tooltip from "../../Tooltip";
import classNames from "classnames";

interface Props {
    title?: string | ReactElement;
    toggle?: () => void;
    tooltipContent?: string;
    isTitleBold?: boolean;
    showLeftChevron?: boolean;
    showXTopRight?: boolean;
    leftIcon?: FC;
    centerTitle?: boolean;
}

function FrameModalHeader(props: PropsWithChildren<Props>): ReactElement {
    const LeftIcon: any = props.leftIcon;

    return (
        <div className="frame-one-modal_header">
            <div className="frame-one-modal_header_title-row">
                <div
                    className={classNames("frame-one-modal_header_tooltip-header-row", {
                        "frame-one-modal_header_tooltip-header-row_center": props?.centerTitle,
                    })}
                >
                    {props.toggle && props?.showLeftChevron &&
                    <IoChevronBackOutline
                        className="frame-one-modal_header_title-row_left-chevron"
                        onClick={props.toggle}
                    />
                    }
                    {props.leftIcon && <LeftIcon className="frame-one-modal_header_title-row_left-icon"/>}

                    {props?.centerTitle
                        ? <div className="frame-one-modal_header_title-row_center-title">
                            <h4
                                className={classNames("frame-one-modal_header_title-row_title", {
                                    "frame-one-modal_header_title-row_normal-weight": !props.isTitleBold,
                                    "frame-one-modal_header_title-row_bolder-weight": props.isTitleBold,
                                })}
                            >
                                {props.title}
                            </h4>
                        </div>
                        : <h4
                            className={classNames("frame-one-modal_header_title-row_title", {
                                "frame-one-modal_header_title-row_normal-weight": !props.isTitleBold,
                                "frame-one-modal_header_title-row_bolder-weight": props.isTitleBold,
                            })}
                        >
                            {props.title}
                        </h4>
                    }
                    {props.tooltipContent &&
                    <Tooltip
                        outsideIcon={BsFillQuestionCircleFill}
                        containerElementClassname="frame-one-modal"
                    >
                        <p>
                            {props.tooltipContent}
                        </p>
                    </Tooltip>
                    }
                </div>

                {(props.toggle && !props.showLeftChevron || props.toggle && props.showXTopRight) &&
                <FiX
                    className="frame-one-modal_header_title-row_close-icon"
                    onClick={props.toggle}
                />
                }
            </div>

            {props.children &&
            <div className="frame-one-modal_header_children">
                {props.children}
            </div>
            }
        </div>
    );
}

export default FrameModalHeader;
