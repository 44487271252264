/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BrandMap,
    BrandMapFromJSON,
    BrandMapFromJSONTyped,
    BrandMapToJSON,
    GetBrandMapsResponseAllOf,
    GetBrandMapsResponseAllOfFromJSON,
    GetBrandMapsResponseAllOfFromJSONTyped,
    GetBrandMapsResponseAllOfToJSON,
    PaginatedResponse,
    PaginatedResponseFromJSON,
    PaginatedResponseFromJSONTyped,
    PaginatedResponseToJSON,
    PaginationInfo,
    PaginationInfoFromJSON,
    PaginationInfoFromJSONTyped,
    PaginationInfoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetBrandMapsResponse
 */
export interface GetBrandMapsResponse extends PaginatedResponse {
    /**
     * 
     * @type {Array<BrandMap>}
     * @memberof GetBrandMapsResponse
     */
    brandMaps: Array<BrandMap>;
}

export function GetBrandMapsResponseFromJSON(json: any): GetBrandMapsResponse {
    return GetBrandMapsResponseFromJSONTyped(json, false);
}

export function GetBrandMapsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetBrandMapsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...PaginatedResponseFromJSONTyped(json, ignoreDiscriminator),
        'brandMaps': ((json['brandMaps'] as Array<any>).map(BrandMapFromJSON)),
    };
}

export function GetBrandMapsResponseToJSON(value?: GetBrandMapsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...PaginatedResponseToJSON(value),
        'brandMaps': ((value.brandMaps as Array<any>).map(BrandMapToJSON)),
    };
}


