/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NftRewardMetadata,
    NftRewardMetadataFromJSON,
    NftRewardMetadataFromJSONTyped,
    NftRewardMetadataToJSON,
    NftRewardPrize,
    NftRewardPrizeFromJSON,
    NftRewardPrizeFromJSONTyped,
    NftRewardPrizeToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetNftRewardResponse
 */
export interface GetNftRewardResponse {
    /**
     * 
     * @type {NftRewardMetadata}
     * @memberof GetNftRewardResponse
     */
    nftReward: NftRewardMetadata;
    /**
     * 
     * @type {Array<NftRewardPrize>}
     * @memberof GetNftRewardResponse
     */
    prizes: Array<NftRewardPrize>;
}

export function GetNftRewardResponseFromJSON(json: any): GetNftRewardResponse {
    return GetNftRewardResponseFromJSONTyped(json, false);
}

export function GetNftRewardResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetNftRewardResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nftReward': NftRewardMetadataFromJSON(json['nftReward']),
        'prizes': ((json['prizes'] as Array<any>).map(NftRewardPrizeFromJSON)),
    };
}

export function GetNftRewardResponseToJSON(value?: GetNftRewardResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nftReward': NftRewardMetadataToJSON(value.nftReward),
        'prizes': ((value.prizes as Array<any>).map(NftRewardPrizeToJSON)),
    };
}


