import {ButtonHTMLAttributes, ReactElement} from "react";
import {BsCreditCardFill} from "react-icons/bs";
import {TbArrowsSplit} from "react-icons/tb";
import Spacer from "@/components/Spacer";
import {isDesktop, isTablet} from "react-device-detect";
import classNames from "classnames";
import {GoPencil} from "react-icons/go";
import FrameButton from "@/components/buttons/FrameButton";
import {ActivePaymentMethod} from "@/utils/getMenuOrderPaymentMethod";
import Skeleton from "@/components/skeletons/Skeleton";

interface Props {
    isActive: boolean;
    paymentMethod: ActivePaymentMethod;
    activePaymentMethodTag: string;
    toggleModal: () => void;
    showEdit?: boolean;
    isLoading?: boolean;
}

type PaymentMethodData = {
    [key in ActivePaymentMethod]: {
        paymentMethod: string;
        defaultPaymentMethodTag: string;
        icon: ReactElement;
    };
};

const paymentMethodData: PaymentMethodData = {
    "DPAY": {
        paymentMethod: import.meta.env.VITE_TOKEN_NAME,
        defaultPaymentMethodTag: `Add ${import.meta.env.VITE_TOKEN_NAME} Method`,
        icon:
            <img
                className="checkout-payment-option_card_icon"
                src={import.meta.env.VITE_CDN_URL + "/images/FUEL.webp"}
                alt={`${import.meta.env.VITE_TOKEN_NAME} icon`}
            />,
    },
    "CREDIT": {
        paymentMethod: "Credit Card",
        defaultPaymentMethodTag: "Add Credit Card",
        icon: <BsCreditCardFill className="checkout-payment-option_card_icon"/>,
    },
    "SPLIT": {
        paymentMethod: "Split",
        defaultPaymentMethodTag: `Add Credit ${isDesktop || isTablet
            ? "Card and"
            : "&"} ${import.meta.env.VITE_TOKEN_NAME} Method`,
        icon: <TbArrowsSplit className="checkout-payment-option_card_icon"/>,
    },
    "COINBASE": {
        paymentMethod: "Coinbase",
        defaultPaymentMethodTag: "Use Coinbase",
        icon:
            <img
                className="checkout-payment-option_card_icon"
                src={`${import.meta.env.VITE_CDN_URL}/images/coinbase.svg`}
                alt="Coinbase"
            />,
    },
};

function CheckoutPaymentOption(props: Props) {
    if (props.isLoading) {
        return (
            <div className="checkout-payment-option">
                <Skeleton
                    rows={1}
                    height={42}
                />
            </div>
        );
    }

    return (
        <div className="checkout-payment-option">
            <div
                className={classNames("checkout-payment-option_card", {
                    "checkout-payment-option_card_active": props.isActive,
                    "checkout-payment-option_card_inactive": !props.isActive,
                })}
                onClick={() => {
                    if (!props.isActive) {
                        props.toggleModal();
                    }
                }}
            >
                {paymentMethodData[props.paymentMethod].icon}
                <p className="checkout-payment-option_card_method">
                    {paymentMethodData[props.paymentMethod].paymentMethod}
                </p>
                <Spacer/>
                <div
                    className={classNames("checkout-payment-option_card_tag", {
                        "checkout-payment-option_card_tag_active": props.isActive,
                        "checkout-payment-option_card_tag_inactive": !props.isActive,
                    })}>
                    {props.isActive
                        ? props.activePaymentMethodTag
                        : paymentMethodData[props.paymentMethod].defaultPaymentMethodTag
                    }
                </div>
            </div>
            {props.isActive && props.showEdit && (
                isDesktop || isTablet
                    ? <FrameButton
                        <ButtonHTMLAttributes<HTMLButtonElement>>
                        color="gray"
                        size="narrow"
                        className="checkout-payment-option_button"
                        onClick={props.toggleModal}
                    >
                        Edit
                    </FrameButton>
                    : <FrameButton
                        <ButtonHTMLAttributes<HTMLButtonElement>>
                        color="gray"
                        size="icon-square"
                        leftIcon={GoPencil}
                        className="checkout-payment-option_button"
                        onClick={props.toggleModal}
                    />
            )}
        </div>
    );
}

export default CheckoutPaymentOption;

