export default function decodePolyline(encoded: string): { lat: number, lng: number }[] {
    let currentLat = 0;
    let currentLng = 0;
    let index = 0;
    const coordinates: { lat: number, lng: number }[] = [];
    while (index < encoded.length) {
        let shift = 0;
        let result = 0;
        let byte: number;

        // Decode latitude
        do {
            byte = encoded.charCodeAt(index++) - 63;
            result |= (byte & 0x1f) << shift;
            shift += 5;
        } while (byte >= 0x20);

        const deltaLat = result & 1 ? ~(result >> 1) : result >> 1;
        currentLat += deltaLat;

        shift = 0;
        result = 0;

        // Decode longitude
        do {
            byte = encoded.charCodeAt(index++) - 63;
            result |= (byte & 0x1f) << shift;
            shift += 5;
        } while (byte >= 0x20);

        const deltaLng = result & 1 ? ~(result >> 1) : result >> 1;
        currentLng += deltaLng;

        coordinates.push({
            lat: currentLat / 1e5,
            lng: currentLng / 1e5,
        });
    }

    return coordinates;
}
