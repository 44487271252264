/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiError,
    ApiErrorFromJSON,
    ApiErrorToJSON,
    DevourIQGetStoreAvailabilityResponse,
    DevourIQGetStoreAvailabilityResponseFromJSON,
    DevourIQGetStoreAvailabilityResponseToJSON,
    DevourIQImportRestaurant,
    DevourIQImportRestaurantFromJSON,
    DevourIQImportRestaurantToJSON,
    DevourIQInjectManualOrderBody,
    DevourIQInjectManualOrderBodyFromJSON,
    DevourIQInjectManualOrderBodyToJSON,
    DevourIQUpdateUberEatsUrl,
    DevourIQUpdateUberEatsUrlFromJSON,
    DevourIQUpdateUberEatsUrlToJSON,
    DevourIQWebhookBody,
    DevourIQWebhookBodyFromJSON,
    DevourIQWebhookBodyToJSON,
    Restaurant,
    RestaurantFromJSON,
    RestaurantToJSON,
} from '../models';

export interface DevourIQGetOrderInjectRequest {
    id: string;
}

export interface DevourIQImportRestaurantRequest {
    devourIQImportRestaurant?: DevourIQImportRestaurant;
}

export interface DevourIQInjectManualOrderRequest {
    id: string;
    devourIQInjectManualOrderBody?: DevourIQInjectManualOrderBody;
}

export interface DevourIQReimportRestaurantByUuidRequest {
    id: string;
}

export interface DevourIQRestaurantStatusRequest {
    id: string;
}

export interface DevourIQUpdateUberEatsURLRequest {
    id: string;
    devourIQUpdateUberEatsUrl?: DevourIQUpdateUberEatsUrl;
}

export interface WebhookDevouriqRequest {
    devourIQWebhookBody?: DevourIQWebhookBody;
}

/**
 * DevourIQApi - interface
 * @export
 * @interface DevourIQApiInterface
 */
export interface DevourIQApiInterface {
    /**
     * Get order injection code for a DevourIQ order.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevourIQApiInterface
     */
    devourIQGetOrderInjectRaw(requestParameters: DevourIQGetOrderInjectRequest): Promise<runtime.ApiResponse<object>>;

    /**
     * Get order injection code for a DevourIQ order.
     */
    devourIQGetOrderInject(requestParameters: DevourIQGetOrderInjectRequest): Promise<object>;

    /**
     * Create a new restaurant and menus using DevourIQ.
     * @param {DevourIQImportRestaurant} [devourIQImportRestaurant] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevourIQApiInterface
     */
    devourIQImportRestaurantRaw(requestParameters: DevourIQImportRestaurantRequest): Promise<runtime.ApiResponse<Restaurant>>;

    /**
     * Create a new restaurant and menus using DevourIQ.
     */
    devourIQImportRestaurant(requestParameters: DevourIQImportRestaurantRequest): Promise<Restaurant>;

    /**
     * Inject the manual order to DevourIQ after customer support creates the order on Uber Eats.
     * @param {string} id 
     * @param {DevourIQInjectManualOrderBody} [devourIQInjectManualOrderBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevourIQApiInterface
     */
    devourIQInjectManualOrderRaw(requestParameters: DevourIQInjectManualOrderRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Inject the manual order to DevourIQ after customer support creates the order on Uber Eats.
     */
    devourIQInjectManualOrder(requestParameters: DevourIQInjectManualOrderRequest): Promise<void>;

    /**
     * Reimports a restaurant by the uuid
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevourIQApiInterface
     */
    devourIQReimportRestaurantByUuidRaw(requestParameters: DevourIQReimportRestaurantByUuidRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Reimports a restaurant by the uuid
     */
    devourIQReimportRestaurantByUuid(requestParameters: DevourIQReimportRestaurantByUuidRequest): Promise<void>;

    /**
     * DevourIQ webhook to update order status.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevourIQApiInterface
     */
    devourIQRestaurantStatusRaw(requestParameters: DevourIQRestaurantStatusRequest): Promise<runtime.ApiResponse<DevourIQGetStoreAvailabilityResponse>>;

    /**
     * DevourIQ webhook to update order status.
     */
    devourIQRestaurantStatus(requestParameters: DevourIQRestaurantStatusRequest): Promise<DevourIQGetStoreAvailabilityResponse>;

    /**
     * Update a Uber Eats URL and reimport menu.
     * @param {string} id 
     * @param {DevourIQUpdateUberEatsUrl} [devourIQUpdateUberEatsUrl] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevourIQApiInterface
     */
    devourIQUpdateUberEatsURLRaw(requestParameters: DevourIQUpdateUberEatsURLRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Update a Uber Eats URL and reimport menu.
     */
    devourIQUpdateUberEatsURL(requestParameters: DevourIQUpdateUberEatsURLRequest): Promise<void>;

    /**
     * DevourIQ webhook to update order status.
     * @param {DevourIQWebhookBody} [devourIQWebhookBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevourIQApiInterface
     */
    webhookDevouriqRaw(requestParameters: WebhookDevouriqRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * DevourIQ webhook to update order status.
     */
    webhookDevouriq(requestParameters: WebhookDevouriqRequest): Promise<void>;

}

/**
 * no description
 */
export class DevourIQApi extends runtime.BaseAPI implements DevourIQApiInterface {

    /**
     * Get order injection code for a DevourIQ order.
     */
    async devourIQGetOrderInjectRaw(requestParameters: DevourIQGetOrderInjectRequest): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling devourIQGetOrderInject.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Discriminated", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/devouriq/order-inject/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get order injection code for a DevourIQ order.
     */
    async devourIQGetOrderInject(requestParameters: DevourIQGetOrderInjectRequest): Promise<object> {
        const response = await this.devourIQGetOrderInjectRaw(requestParameters);
        return await response.value();
    }

    /**
     * Create a new restaurant and menus using DevourIQ.
     */
    async devourIQImportRestaurantRaw(requestParameters: DevourIQImportRestaurantRequest): Promise<runtime.ApiResponse<Restaurant>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/devouriq/create-restaurant-and-menus`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DevourIQImportRestaurantToJSON(requestParameters.devourIQImportRestaurant),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RestaurantFromJSON(jsonValue));
    }

    /**
     * Create a new restaurant and menus using DevourIQ.
     */
    async devourIQImportRestaurant(requestParameters: DevourIQImportRestaurantRequest): Promise<Restaurant> {
        const response = await this.devourIQImportRestaurantRaw(requestParameters);
        return await response.value();
    }

    /**
     * Inject the manual order to DevourIQ after customer support creates the order on Uber Eats.
     */
    async devourIQInjectManualOrderRaw(requestParameters: DevourIQInjectManualOrderRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling devourIQInjectManualOrder.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/devouriq/{id}/manual-order`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DevourIQInjectManualOrderBodyToJSON(requestParameters.devourIQInjectManualOrderBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Inject the manual order to DevourIQ after customer support creates the order on Uber Eats.
     */
    async devourIQInjectManualOrder(requestParameters: DevourIQInjectManualOrderRequest): Promise<void> {
        await this.devourIQInjectManualOrderRaw(requestParameters);
    }

    /**
     * Reimports a restaurant by the uuid
     */
    async devourIQReimportRestaurantByUuidRaw(requestParameters: DevourIQReimportRestaurantByUuidRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling devourIQReimportRestaurantByUuid.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/devouriq/{id}/reimport-restaurant-by-uuid`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Reimports a restaurant by the uuid
     */
    async devourIQReimportRestaurantByUuid(requestParameters: DevourIQReimportRestaurantByUuidRequest): Promise<void> {
        await this.devourIQReimportRestaurantByUuidRaw(requestParameters);
    }

    /**
     * DevourIQ webhook to update order status.
     */
    async devourIQRestaurantStatusRaw(requestParameters: DevourIQRestaurantStatusRequest): Promise<runtime.ApiResponse<DevourIQGetStoreAvailabilityResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling devourIQRestaurantStatus.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/devouriq/restaurant-status/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DevourIQGetStoreAvailabilityResponseFromJSON(jsonValue));
    }

    /**
     * DevourIQ webhook to update order status.
     */
    async devourIQRestaurantStatus(requestParameters: DevourIQRestaurantStatusRequest): Promise<DevourIQGetStoreAvailabilityResponse> {
        const response = await this.devourIQRestaurantStatusRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update a Uber Eats URL and reimport menu.
     */
    async devourIQUpdateUberEatsURLRaw(requestParameters: DevourIQUpdateUberEatsURLRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling devourIQUpdateUberEatsURL.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/devouriq/{id}/update-ubereats-url`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DevourIQUpdateUberEatsUrlToJSON(requestParameters.devourIQUpdateUberEatsUrl),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update a Uber Eats URL and reimport menu.
     */
    async devourIQUpdateUberEatsURL(requestParameters: DevourIQUpdateUberEatsURLRequest): Promise<void> {
        await this.devourIQUpdateUberEatsURLRaw(requestParameters);
    }

    /**
     * DevourIQ webhook to update order status.
     */
    async webhookDevouriqRaw(requestParameters: WebhookDevouriqRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/devouriq/webhook`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DevourIQWebhookBodyToJSON(requestParameters.devourIQWebhookBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * DevourIQ webhook to update order status.
     */
    async webhookDevouriq(requestParameters: WebhookDevouriqRequest): Promise<void> {
        await this.webhookDevouriqRaw(requestParameters);
    }

}
