import {BrandMap, BrandMapColorTheme, LoginAccessPoint} from "@devour/client";
import useWindowSize from "@/hooks/useWindowSize";
import FrameButton from "@/components/buttons/FrameButton";
import classNames from "classnames";
import {isMobile, isTablet} from "react-device-detect";
import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {HiChevronDoubleDown} from "react-icons/hi";
import {BsArrowRight} from "react-icons/bs";
import {BrandMapEmbeddableHtml} from "@/components/brands/BrandMapEmbeddableHtml";
import {useEffect, useRef} from "react";

interface RestaurantMapHeaderProps {
    brandMap: BrandMap;
    toggleEmailModal: (showModal: boolean) => void;
}

function RestaurantMapHeader(props: RestaurantMapHeaderProps) {
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const windowSize = useWindowSize()[0];
    const isWindowMobileView = windowSize < 576;
    const isMobileView = isMobile && !isTablet || isWindowMobileView;
    const isTextDark = props.brandMap.colorTheme === BrandMapColorTheme.DARK;

    // Banner Image Stuff
    const bannerImageAspectRatio = 1280 / 375;
    const belowBannerImageContentRef = useRef<HTMLDivElement>(null);
    const bannerImageContainerRef = useRef<HTMLDivElement>(null);

    // When window is resized, adjust the spacing below banner image container if needed
    useEffect(() => {
        adjustSpacing();
    }, [windowSize]);

    /*
     * Dynamically adjust the spacing below the banner image container.
     * When screen width is <= 1200px, the background image is switched from "cover" to "contain".
     * This can cause the image to shrink when screen width decreases,
     * leading to an increase in spacing below the banner image.
     * This function recalculates and adjusts the spacing so that it remains consistent.
     */
    function adjustSpacing() {
        const bannerImageContainer = bannerImageContainerRef.current;

        if (!bannerImageContainer) return;

        const containerWidth = bannerImageContainer.clientWidth;
        const backgroundImageHeight = containerWidth / bannerImageAspectRatio;

        // image is set to "contain" only <= 1200px
        const isImageContain = windowSize <= 1200;

        // Adjust spacing below the banner image container dynamically
        const dynamicSpacing = backgroundImageHeight - 290;

        if (isImageContain) {
            // Adjust dynamic spacing if screen width is <= 1200px. This is when image is set to "contain" from "cover"
            belowBannerImageContentRef.current.style.marginTop = `${dynamicSpacing}px`;
        } else {
            belowBannerImageContentRef.current.style.marginTop = "5rem"; // Default
        }
    }

    const renderButtons = () =>
        <div className={classNames("restaurant-map-landing_header_buttons max-width", {
            "center-buttons": !props.brandMap.titleImage,
        })}>
            <FrameButton
                color={isTextDark ? "gray-light" : "ghost"}
                size="large"
                onClick={() => props.toggleEmailModal(true)}
                className="restaurant-map-landing_header_button signup"
            >
                {props.brandMap.autoMintContract ? "Sign up and Mint" : "Sign up"}

                <BsArrowRight/>
            </FrameButton>

            <FrameButton
                color={isTextDark ? "dark-outline" : "white-outline"}
                size="large"
                onClick={() => props.toggleEmailModal(true)}
                className="restaurant-map-landing_header_button login"
            >
                Login
            </FrameButton>
        </div>;

    return (
        <div className="restaurant-map-landing_header">
            {props.brandMap.bannerImage && props.brandMap.bannerImage.image?.url &&
                <div
                    ref={bannerImageContainerRef}
                    className="restaurant-map-landing_header_banner"
                    style={{backgroundImage: `url(${props.brandMap.bannerImage.image.url})`}}
                >
                    {
                        props.brandMap.bannerImage.imageType === "DIAMOND" &&
                        <>
                            <div className="background-triangle left"
                                style={{borderLeft: `${windowSize / 2}px solid ${props.brandMap.colorBackground}`}}
                            />

                            <div className="background-triangle right"
                                style={{borderRight: `${windowSize / 2}px solid ${props.brandMap.colorBackground}`}}
                            />
                        </>
                    }
                </div>
            }

            {/* Title Description with Optional Image Section */}
            <div
                ref={belowBannerImageContentRef}
                className="restaurant-map-landing_header_text-content max-width"
                style={{background: props.brandMap.colorBackground}}
            >

                {/* MOBILE TITLE IMAGE */}
                {props.brandMap?.titleImage && isMobileView &&
                    <div className="restaurant-map-landing_header_text-content_image mobile">
                        <img src={props.brandMap.titleImage.url} alt="mobile title image" className="banner-image"/>
                    </div>
                }

                {/* TITLE AND DESCRIPTION */}
                <div className={classNames("restaurant-map-landing_header_text-content_container", {
                    "center-text": !props.brandMap.titleImage,
                })}
                >
                    <h1>
                        {props.brandMap.name || props.brandMap.brand.name}
                    </h1>

                    {props.brandMap.description &&
                        <div
                            className={classNames("restaurant-map-landing_header_text-content_description", {
                                "center-text": !props.brandMap.titleImage,
                            })}
                            dangerouslySetInnerHTML={{__html: props.brandMap.description}}
                        />
                    }

                    {/* BUTTONS */}
                    {!fullToken && props.brandMap.loginAccessPoint === LoginAccessPoint.PAGE && renderButtons()}
                </div>

                {/* TABLET/DESKTOP TITLE IMAGE */}
                {props.brandMap?.titleImage && !isMobileView &&
                    <div className="restaurant-map-landing_header_text-content_image">
                        <img src={props.brandMap.titleImage.url} alt="banner image" className="banner-image"/>
                    </div>
                }
            </div>

            <BrandMapEmbeddableHtml/>
            <HiChevronDoubleDown
                size="2.25rem"
                className="restaurant-map-landing_header_scroll-icon"
                onClick={() => {
                    document.getElementById("restaurant-map-landing_content_flow-container")?.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                    });
                }}
            />
        </div>
    );
}

export default RestaurantMapHeader;
