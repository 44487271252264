import {ReactElement} from "react";
import {Link} from "react-router-dom";
import classNames from "classnames";

interface Props {
    toggle: () => void;
    className: string;
}

function GoFrensTutorialModalUnlockRewards(props: Props): ReactElement {

    return (
        <div className={classNames("gofrens-tutorial-modal-unlock-rewards", props.className)}>
            <div>
                <p className="gofrens-tutorial-modal-unlock-rewards_step">
					Step 4: Claim benefits
                </p>
                <div className="gofrens-tutorial-modal-unlock-rewards_title">
                    <img
                        className="gofrens-tutorial-modal-unlock-rewards_title_icon"
                        src={import.meta.env.VITE_CDN_URL + "/images/tutorial-step4-gift.webp"}
                        alt="Unlock Rewards"
                    />
                    <span>
					Unlock Rewards
                    </span>
                </div>
                <div className="gofrens-tutorial-modal-unlock-rewards_description">
					Discover special deals and secret menus, exclusively on DevourGO - crafted for you, as you join the
					GoFriends community!
                </div>
            </div>
            <div className="gofrens-tutorial-modal-unlock-rewards_image">
                <Link
                    to="/restaurants"
                    onClick={props.toggle}
                >
                    <img
                        src={import.meta.env.VITE_CDN_URL + "/images/tutorial-step4.webp"}
                        alt="Order Now"
                    />
                </Link>
            </div>
        </div>
    );
}

export default GoFrensTutorialModalUnlockRewards;
