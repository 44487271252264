import {useQuery} from "@tanstack/react-query";
import {GetSEOResponse, SEOApi} from "@devour/client";

async function fetchSEOTags(url: string): Promise<GetSEOResponse> {
    return await new SEOApi().getSeoTags({
        url: url,
    });
}

function seoTagsQuery(url: string) {
    return {
        queryKey: [
            "seo-tags",
            url,
        ],
        queryFn: () => fetchSEOTags(url),
        staleTime: 30 * 60000,
        enabled: !!url,
    } as const;
}

export function useGetSEOTags(url: string) {
    return useQuery(seoTagsQuery(url));
}