/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PaginatedResponse,
    PaginatedResponseFromJSON,
    PaginatedResponseFromJSONTyped,
    PaginatedResponseToJSON,
    PaginationInfo,
    PaginationInfoFromJSON,
    PaginationInfoFromJSONTyped,
    PaginationInfoToJSON,
    Transaction,
    TransactionFromJSON,
    TransactionFromJSONTyped,
    TransactionToJSON,
} from './';

/**
 * 
 * @export
 * @interface TransactionsPaginated
 */
export interface TransactionsPaginated extends PaginatedResponse {
    /**
     * 
     * @type {Array<Transaction>}
     * @memberof TransactionsPaginated
     */
    data: Array<Transaction>;
}

export function TransactionsPaginatedFromJSON(json: any): TransactionsPaginated {
    return TransactionsPaginatedFromJSONTyped(json, false);
}

export function TransactionsPaginatedFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransactionsPaginated {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...PaginatedResponseFromJSONTyped(json, ignoreDiscriminator),
        'data': ((json['data'] as Array<any>).map(TransactionFromJSON)),
    };
}

export function TransactionsPaginatedToJSON(value?: TransactionsPaginated | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...PaginatedResponseToJSON(value),
        'data': ((value.data as Array<any>).map(TransactionToJSON)),
    };
}


