import {ReactElement} from "react";
import useWindowSize from "@/hooks/useWindowSize";
import classNames from "classnames";
import {isWindows} from "react-device-detect";
import variables from "@/style/variables/scssVariables";

export enum WindowBreakpointSizes {
    SMALL = "sm",
    MEDIUM = "md",
    LARGE = "lg",
    XLARGE = "xlg",
}

interface svgProps {
    width: number;
    height: number;
    path: string;
}

interface Props {
    size: WindowBreakpointSizes;
    rotated?: boolean;
    flipped?: boolean;
    colour?: string;
    sidebarAdjust?: boolean;
}

const dimensionsMapping: { [key: string]: svgProps } = {
    lg: {
        width: 1676,
        height: 117,
        path: "M0 116.5L1676 0V116.5H0Z",
    },
    md: {
        width: 834,
        height: 63,
        path: "M0 63L834 0.5V63H0Z",
    },
    sm: {
        width: 428,
        height: 75,
        path: "M0 55L428 0V55H0Z",
    },
};

function BackgroundTriangleSVG(props: Props): ReactElement {
    // Sidebar width = 258px
    const f1SidebarWidth: number = parseInt(variables["f1-sidebar-width"]);
    const dimensions = dimensionsMapping[props.size] || dimensionsMapping[WindowBreakpointSizes.LARGE];
    const actualWidth = document.body.clientWidth + (props.sidebarAdjust !== false && document.body.clientWidth >= 1200
        ? -f1SidebarWidth
        : 0);

    return (
        <div
            className={classNames(
                "triangle-svg",
                props.rotated && "triangle-svg_rotated",
                props.flipped && "triangle-svg_flipped",
            )}
            style={{
                marginTop: !props.rotated && -dimensions.height + 1,
            }}
        >
            <svg
                width={actualWidth.toString()}
                height={dimensions.height.toString()}
                viewBox={`0 0 ${actualWidth} ${dimensions.height}`}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d={`M0 ${dimensions.height}L${Math.ceil(actualWidth)} 0V${dimensions.height}H0Z`}
                    fill={props.colour || "#7859E6"} // default is secondary-purple
                />
            </svg>
        </div>
    );
}

export default BackgroundTriangleSVG;
