/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
    OrderRefundReason,
    OrderRefundReasonFromJSON,
    OrderRefundReasonFromJSONTyped,
    OrderRefundReasonToJSON,
    OrderRefundResponsibility,
    OrderRefundResponsibilityFromJSON,
    OrderRefundResponsibilityFromJSONTyped,
    OrderRefundResponsibilityToJSON,
    TimeStamps,
    TimeStampsFromJSON,
    TimeStampsFromJSONTyped,
    TimeStampsToJSON,
} from './';

/**
 * Represents a refund for a menu order.
 * @export
 * @interface OrderRefund
 */
export interface OrderRefund {
    /**
     * 
     * @type {string}
     * @memberof OrderRefund
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof OrderRefund
     */
    createdAt: number;
    /**
     * 
     * @type {number}
     * @memberof OrderRefund
     */
    updatedAt: number;
    /**
     * 
     * @type {string}
     * @memberof OrderRefund
     */
    admin: string;
    /**
     * 
     * @type {string}
     * @memberof OrderRefund
     */
    menuOrder: string;
    /**
     * 
     * @type {OrderRefundReason}
     * @memberof OrderRefund
     */
    reason: OrderRefundReason;
    /**
     * 
     * @type {OrderRefundResponsibility}
     * @memberof OrderRefund
     */
    responsibility: OrderRefundResponsibility;
    /**
     * 
     * @type {number}
     * @memberof OrderRefund
     */
    responsiblePartyAmount: number;
    /**
     * 
     * @type {string}
     * @memberof OrderRefund
     */
    notes?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderRefund
     */
    refundId?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderRefund
     */
    fiat: number;
    /**
     * 
     * @type {number}
     * @memberof OrderRefund
     */
    dpay: number;
    /**
     * 
     * @type {string}
     * @memberof OrderRefund
     */
    dpayTransaction?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderRefund
     */
    coinbaseDpay?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderRefund
     */
    coinbaseDpayTransaction?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderRefund
     */
    rewardsClawback: number;
    /**
     * 
     * @type {string}
     * @memberof OrderRefund
     */
    rewardsClawbackTransaction?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderRefund
     */
    transferReverseAmount: number;
    /**
     * 
     * @type {string}
     * @memberof OrderRefund
     */
    transferReverseId?: string;
}

export function OrderRefundFromJSON(json: any): OrderRefund {
    return OrderRefundFromJSONTyped(json, false);
}

export function OrderRefundFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderRefund {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'admin': json['admin'],
        'menuOrder': json['menuOrder'],
        'reason': OrderRefundReasonFromJSON(json['reason']),
        'responsibility': OrderRefundResponsibilityFromJSON(json['responsibility']),
        'responsiblePartyAmount': json['responsiblePartyAmount'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'refundId': !exists(json, 'refundId') ? undefined : json['refundId'],
        'fiat': json['fiat'],
        'dpay': json['dpay'],
        'dpayTransaction': !exists(json, 'dpayTransaction') ? undefined : json['dpayTransaction'],
        'coinbaseDpay': !exists(json, 'coinbaseDpay') ? undefined : json['coinbaseDpay'],
        'coinbaseDpayTransaction': !exists(json, 'coinbaseDpayTransaction') ? undefined : json['coinbaseDpayTransaction'],
        'rewardsClawback': json['rewardsClawback'],
        'rewardsClawbackTransaction': !exists(json, 'rewardsClawbackTransaction') ? undefined : json['rewardsClawbackTransaction'],
        'transferReverseAmount': json['transferReverseAmount'],
        'transferReverseId': !exists(json, 'transferReverseId') ? undefined : json['transferReverseId'],
    };
}

export function OrderRefundToJSON(value?: OrderRefund | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'admin': value.admin,
        'menuOrder': value.menuOrder,
        'reason': OrderRefundReasonToJSON(value.reason),
        'responsibility': OrderRefundResponsibilityToJSON(value.responsibility),
        'responsiblePartyAmount': value.responsiblePartyAmount,
        'notes': value.notes,
        'refundId': value.refundId,
        'fiat': value.fiat,
        'dpay': value.dpay,
        'dpayTransaction': value.dpayTransaction,
        'coinbaseDpay': value.coinbaseDpay,
        'coinbaseDpayTransaction': value.coinbaseDpayTransaction,
        'rewardsClawback': value.rewardsClawback,
        'rewardsClawbackTransaction': value.rewardsClawbackTransaction,
        'transferReverseAmount': value.transferReverseAmount,
        'transferReverseId': value.transferReverseId,
    };
}


