import {PropsWithChildren, ReactElement} from "react";
import {isDesktop, isTablet} from "react-device-detect";
import FrameModalFooter from "../modalComponents/FrameModalFooter";
import FrameBottomPanelFooter from "../bottomPanelComponents/FrameBottomPanelFooter";

interface Props {
    className?: string;
    modalOnTablet?: boolean;
}

function FrameAutoPanelFooter(props: PropsWithChildren<Props>): ReactElement {
    if (isDesktop || isTablet && props.modalOnTablet) {
        return (
            <FrameModalFooter
                className={props.className}
            >
                {props.children}
            </FrameModalFooter>
        );
    } else {
        return (
            <FrameBottomPanelFooter
                className={props.className}
            >
                {props.children}
            </FrameBottomPanelFooter>
        );
    }
}

export default FrameAutoPanelFooter;
