/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetRestaurantsResponseAllOf,
    GetRestaurantsResponseAllOfFromJSON,
    GetRestaurantsResponseAllOfFromJSONTyped,
    GetRestaurantsResponseAllOfToJSON,
    PaginatedResponse,
    PaginatedResponseFromJSON,
    PaginatedResponseFromJSONTyped,
    PaginatedResponseToJSON,
    PaginationInfo,
    PaginationInfoFromJSON,
    PaginationInfoFromJSONTyped,
    PaginationInfoToJSON,
    Restaurant,
    RestaurantFromJSON,
    RestaurantFromJSONTyped,
    RestaurantToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetRestaurantsResponse
 */
export interface GetRestaurantsResponse extends PaginatedResponse {
    /**
     * 
     * @type {Array<Restaurant>}
     * @memberof GetRestaurantsResponse
     */
    restaurants: Array<Restaurant>;
}

export function GetRestaurantsResponseFromJSON(json: any): GetRestaurantsResponse {
    return GetRestaurantsResponseFromJSONTyped(json, false);
}

export function GetRestaurantsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetRestaurantsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...PaginatedResponseFromJSONTyped(json, ignoreDiscriminator),
        'restaurants': ((json['restaurants'] as Array<any>).map(RestaurantFromJSON)),
    };
}

export function GetRestaurantsResponseToJSON(value?: GetRestaurantsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...PaginatedResponseToJSON(value),
        'restaurants': ((value.restaurants as Array<any>).map(RestaurantToJSON)),
    };
}


