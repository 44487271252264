/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LeaderboardsDateRange
 */
export interface LeaderboardsDateRange {
    /**
     * 
     * @type {string}
     * @memberof LeaderboardsDateRange
     */
    dateParam: string;
    /**
     * 
     * @type {string}
     * @memberof LeaderboardsDateRange
     */
    dateValue: string;
}

export function LeaderboardsDateRangeFromJSON(json: any): LeaderboardsDateRange {
    return LeaderboardsDateRangeFromJSONTyped(json, false);
}

export function LeaderboardsDateRangeFromJSONTyped(json: any, ignoreDiscriminator: boolean): LeaderboardsDateRange {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dateParam': json['dateParam'],
        'dateValue': json['dateValue'],
    };
}

export function LeaderboardsDateRangeToJSON(value?: LeaderboardsDateRange | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dateParam': value.dateParam,
        'dateValue': value.dateValue,
    };
}


