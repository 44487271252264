import InitialMetaStore, {IMetaStore} from "./meta/InitialMetaStore";
import InitialAuthStore, {IAuthStore} from "./auth/InitialAuthStore";
import InitialAffiliateStore, {IAffiliateStore} from "./affiliateReferrals/InitialAffiliateStore";

export interface IStore {
    metaStore: IMetaStore;
    authStore: IAuthStore;
    affiliateStore: IAffiliateStore
}

export default {
    metaStore: InitialMetaStore,
    authStore: InitialAuthStore,
    affiliateStore: InitialAffiliateStore,
} as IStore;
