import {ButtonHTMLAttributes, ChangeEvent, ChangeEventHandler, ReactElement, useEffect, useState} from "react";
import {DialCode, Address, UtilsApi, GetGeography} from "@devour/client";
import {PatternFormat} from "react-number-format";
import FrameOneSelect from "./FrameOneSelect";
import SelectOptionsFactory from "./SelectOptionsFactory";
import {NumberFormatValues} from "react-number-format/types/types";
import FrameOneAddressAutocomplete from "./FrameOneAddressAutocomplete";
import FrameButton from "../buttons/FrameButton";
import useThemePreference from "@/hooks/useThemePreference";

interface Props {
    placeholder?: string;
    value: Address;
    onChange: (newAddress: Address) => void;
    defaultMode?: "autocomplete" | "manual";
    showModeChangeButton?: boolean;
}

function FrameOneAddressInputGroup(props: Props): ReactElement {

    const [
        autocompleteMode,
        setAutocompleteMode,
    ] = useState<"autocomplete" | "manual">(props.defaultMode);
    const { isOnDarkMode } = useThemePreference();
    const [
        geography,
        setGeography,
    ] = useState<GetGeography>({countries: [],
        states: {},
        provinces: {}});

    useEffect(() => {
        fetchGeography().then()
            .catch();
    }, []);

    /**
     * Get the list of countries, states, provinces from our api.
     *
     */
    async function fetchGeography(): Promise<void> {
        try {
            const res = await new UtilsApi().getGeography();
            setGeography(res);
        } catch (e) {
        }
    }

    /**
     * Handle changing either of the values and pass the combined entry to the callback.
     *
     * @param e
     */
    function countryOnChange(e: ChangeEvent<HTMLSelectElement>): void {
        props.onChange({
            ...props.value,
            country: e.target.value,
            // Reset place ID & coordinates if user manually changes an input
            placeId: undefined,
            location: undefined,
        });
    }

    /**
     * Handle all text input onChange events.
     *
     * @param key
     */
    function inputOnChange(key: keyof Address): ChangeEventHandler<HTMLInputElement | HTMLSelectElement> {
        return (e) => {
            props.onChange({
                ...props.value,
                [key]: e.target.value,
                // Reset place ID & coordinates if user manually changes an input
                placeId: undefined,
                location: undefined,
            });
        };
    }

    /**
     * Handle zip code onChange events.
     *
     * @param values
     */
    function zipCodeOnChange(values: NumberFormatValues): void {
        props.onChange({
            ...props.value,
            postalCode: values.value,
            // Reset place ID & coordinates if user manually changes an input
            placeId: undefined,
            location: undefined,
        });
    }

    function autocompleteCallback(newAddress: Address) {
        props.onChange(newAddress);
        setAutocompleteMode("manual");
    }

    if (autocompleteMode === "autocomplete") {
        return (
            <div className="frame-one-address-input">
                <FrameOneAddressAutocomplete
                    callback={autocompleteCallback}
                    placeholder={props.placeholder}
                />
                {props.showModeChangeButton &&
                <FrameButton
					    <ButtonHTMLAttributes<HTMLButtonElement>>
					    forwardProps={{type: "button"}}
                    color={isOnDarkMode
                        ? "purple"
                        : "gray"}
					    size="narrow"
					    className="frame-one-address-input_manual-button"
					    onClick={() => setAutocompleteMode("manual")}
                >
						Manual Entry
                </FrameButton>
                }
            </div>
        );
    }
    return (
        <div className="frame-one-address-input">
            {props.showModeChangeButton &&
                <div className="frame-one-address-input_row">
					    <FrameButton
					        <ButtonHTMLAttributes<HTMLButtonElement>>
					        forwardProps={{type: "button"}}
					        color={isOnDarkMode
                            ? "purple"
                            : "gray"}
					        size="narrow"
					        className="frame-one-address-input_autocomplete-button"
					        onClick={() => setAutocompleteMode("autocomplete")}
					    >
							Use Autocomplete
					    </FrameButton>
                </div>
            }
            <div className="frame-one-address-input_row">
                <div className="frame-one-address-input_field frame-one-address-input_line1-container">
                    <label>Address Line 1</label>
                    <input
                        type="text"
                        placeholder="Address Line 1"
                        value={props.value.line1}
                        onChange={inputOnChange("line1")}
                        required={true}
                    />
                </div>
            </div>

            <div className="frame-one-address-input_row">
                <div className="frame-one-address-input_field frame-one-address-input_line2-container">
                    <label>Address Line 2</label>
                    <input
                        type="text"
                        placeholder="Address Line 2 (optional)"
                        value={props.value.line2}
                        onChange={inputOnChange("line2")}
                        required={false}
                    />
                </div>
            </div>

            <div className="frame-one-address-input_row">
                <div className="frame-one-address-input_field frame-one-address-input_city-container">
                    <label>City</label>
                    <input
                        type="text"
                        placeholder="City"
                        value={props.value.locality}
                        onChange={inputOnChange("locality")}
                        required={true}
                    />
                </div>

                <div className="frame-one-address-input_field frame-one-address-input_state-container">
                    {props.value.country === "US" &&
                        <>
							    <label>State</label>
							    <FrameOneSelect
							        value={props.value.administrativeArea}
							        onChange={inputOnChange("administrativeArea")}
							        required={true}
							    >
							        <option value="">Select State</option>
							        <SelectOptionsFactory
							            options={Object.keys(geography.states).map((key) => {
							                return {
							                    value: key,
							                    label: geography.states[key],
                                        };
							            })}
							        />
							    </FrameOneSelect>
                        </>
                    }
                    {props.value.country === "CA" &&
                        <>
							    <label>Province</label>
							    <FrameOneSelect
							        value={props.value.administrativeArea}
							        onChange={inputOnChange("administrativeArea")}
							        required={true}
							    >
							        <option value="">Select Province</option>
							        <SelectOptionsFactory
							            options={Object.keys(geography.provinces).map((key) => {
							                return {
							                    value: key,
							                    label: geography.provinces[key],
                                        };
							            })}
							        />
							    </FrameOneSelect>
                        </>
                    }
                    {props.value.country !== "US" && props.value.country !== "CA" &&
                        <>
							    <label>State / Province</label>
							    <input
							        type="text"
							        placeholder="State / Province"
							        value={props.value.administrativeArea}
							        onChange={inputOnChange("administrativeArea")}
							        required={false}
							    />
                        </>
                    }
                </div>
            </div>

            <div className="frame-one-address-input_row">
                <div className="frame-one-address-input_field frame-one-address-input_zip-code-container">
                    {props.value.country === "US" &&
                        <>
							    <label>Zip Code</label>
							    <PatternFormat
							        value={props.value.postalCode}
							        valueIsNumericString={true}
							        type="text"
							        format="#####"
							        placeholder="Zip Code"
							        className="frame-one-address-input_text"
							        onValueChange={zipCodeOnChange}
							    />
                        </>
                    }
                    {props.value.country === "CA" &&
                        <>
							    <label>Postal Code</label>
							    <input
							        type="text"
							        placeholder="Postal Code"
							        value={props.value.postalCode}
							        onChange={inputOnChange("postalCode")}
							        required={true}
							    />
                        </>
                    }
                    {props.value.country !== "US" && props.value.country !== "CA" &&
                        <>
							    <label>Zip Code / Postal Code</label>
							    <input
							        type="text"
							        placeholder="Zip Code / Postal Code"
							        value={props.value.postalCode}
							        onChange={inputOnChange("postalCode")}
							        required={false}
							    />
                        </>
                    }
                </div>

                <div className="frame-one-address-input_field frame-one-address-input_line2-container">
                    <label>Country</label>

                    <FrameOneSelect
                        value={props.value.country}
                        onChange={countryOnChange}
                    >
                        <SelectOptionsFactory
                            <DialCode>
                            options={geography.countries.map((d) => {
                                return {
                                    value: d.code,
                                    label: d.name,
                                };
                            })}
                        />
                    </FrameOneSelect>
                </div>
            </div>
        </div>
    );

}

FrameOneAddressInputGroup.defaultProps = {
    defaultMode: "autocomplete",
    showModeChangeButton: true,
};

export default FrameOneAddressInputGroup;
