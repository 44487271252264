/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MenuItemSearchEssential,
    MenuItemSearchEssentialFromJSON,
    MenuItemSearchEssentialFromJSONTyped,
    MenuItemSearchEssentialToJSON,
    OrderDiscountSearchEssential,
    OrderDiscountSearchEssentialFromJSON,
    OrderDiscountSearchEssentialFromJSONTyped,
    OrderDiscountSearchEssentialToJSON,
    PaginatedResponse,
    PaginatedResponseFromJSON,
    PaginatedResponseFromJSONTyped,
    PaginatedResponseToJSON,
    PaginationInfo,
    PaginationInfoFromJSON,
    PaginationInfoFromJSONTyped,
    PaginationInfoToJSON,
    RestaurantSearchResult,
    RestaurantSearchResultFromJSON,
    RestaurantSearchResultFromJSONTyped,
    RestaurantSearchResultToJSON,
    SearchRestaurantsResponseAllOf,
    SearchRestaurantsResponseAllOfFromJSON,
    SearchRestaurantsResponseAllOfFromJSONTyped,
    SearchRestaurantsResponseAllOfToJSON,
} from './';

/**
 * 
 * @export
 * @interface SearchRestaurantsResponse
 */
export interface SearchRestaurantsResponse extends PaginatedResponse {
    /**
     * 
     * @type {Array<RestaurantSearchResult>}
     * @memberof SearchRestaurantsResponse
     */
    restaurantsPaginated: Array<RestaurantSearchResult>;
    /**
     * 
     * @type {Array<RestaurantSearchResult>}
     * @memberof SearchRestaurantsResponse
     */
    restaurantsGoFrens: Array<RestaurantSearchResult>;
    /**
     * 
     * @type {Array<OrderDiscountSearchEssential>}
     * @memberof SearchRestaurantsResponse
     */
    qualifiedPromos: Array<OrderDiscountSearchEssential>;
    /**
     * 
     * @type {Array<MenuItemSearchEssential>}
     * @memberof SearchRestaurantsResponse
     */
    secretMenuItems: Array<MenuItemSearchEssential>;
}

export function SearchRestaurantsResponseFromJSON(json: any): SearchRestaurantsResponse {
    return SearchRestaurantsResponseFromJSONTyped(json, false);
}

export function SearchRestaurantsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchRestaurantsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...PaginatedResponseFromJSONTyped(json, ignoreDiscriminator),
        'restaurantsPaginated': ((json['restaurantsPaginated'] as Array<any>).map(RestaurantSearchResultFromJSON)),
        'restaurantsGoFrens': ((json['restaurantsGoFrens'] as Array<any>).map(RestaurantSearchResultFromJSON)),
        'qualifiedPromos': ((json['qualifiedPromos'] as Array<any>).map(OrderDiscountSearchEssentialFromJSON)),
        'secretMenuItems': ((json['secretMenuItems'] as Array<any>).map(MenuItemSearchEssentialFromJSON)),
    };
}

export function SearchRestaurantsResponseToJSON(value?: SearchRestaurantsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...PaginatedResponseToJSON(value),
        'restaurantsPaginated': ((value.restaurantsPaginated as Array<any>).map(RestaurantSearchResultToJSON)),
        'restaurantsGoFrens': ((value.restaurantsGoFrens as Array<any>).map(RestaurantSearchResultToJSON)),
        'qualifiedPromos': ((value.qualifiedPromos as Array<any>).map(OrderDiscountSearchEssentialToJSON)),
        'secretMenuItems': ((value.secretMenuItems as Array<any>).map(MenuItemSearchEssentialToJSON)),
    };
}


