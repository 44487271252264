/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AutoCompleteStatusResponse
 */
export interface AutoCompleteStatusResponse {
    /**
     * 
     * @type {boolean}
     * @memberof AutoCompleteStatusResponse
     */
    autoCompleteOperational?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AutoCompleteStatusResponse
     */
    otherPlacesApiOperational?: boolean;
}

export function AutoCompleteStatusResponseFromJSON(json: any): AutoCompleteStatusResponse {
    return AutoCompleteStatusResponseFromJSONTyped(json, false);
}

export function AutoCompleteStatusResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AutoCompleteStatusResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'autoCompleteOperational': !exists(json, 'autoCompleteOperational') ? undefined : json['autoCompleteOperational'],
        'otherPlacesApiOperational': !exists(json, 'otherPlacesApiOperational') ? undefined : json['otherPlacesApiOperational'],
    };
}

export function AutoCompleteStatusResponseToJSON(value?: AutoCompleteStatusResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'autoCompleteOperational': value.autoCompleteOperational,
        'otherPlacesApiOperational': value.otherPlacesApiOperational,
    };
}


