/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
    TimeStamps,
    TimeStampsFromJSON,
    TimeStampsFromJSONTyped,
    TimeStampsToJSON,
} from './';

/**
 * Represents the necessary information needed to redirect a user to a signed onboarding process.
 * @export
 * @interface OnboardingSignedUrl
 */
export interface OnboardingSignedUrl {
    /**
     * 
     * @type {string}
     * @memberof OnboardingSignedUrl
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof OnboardingSignedUrl
     */
    createdAt: number;
    /**
     * 
     * @type {number}
     * @memberof OnboardingSignedUrl
     */
    updatedAt: number;
    /**
     * The unique key used to generate the signed url and look up the details for the user.
     * @type {string}
     * @memberof OnboardingSignedUrl
     */
    signedUrlKey: string;
    /**
     * The signed url that will redirect the user to the onboarding process, it will be the login if the email already exists in the system, if not then it will be the signup.
     * @type {string}
     * @memberof OnboardingSignedUrl
     */
    signedUrl: string;
}

export function OnboardingSignedUrlFromJSON(json: any): OnboardingSignedUrl {
    return OnboardingSignedUrlFromJSONTyped(json, false);
}

export function OnboardingSignedUrlFromJSONTyped(json: any, ignoreDiscriminator: boolean): OnboardingSignedUrl {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'signedUrlKey': json['signedUrlKey'],
        'signedUrl': json['signedUrl'],
    };
}

export function OnboardingSignedUrlToJSON(value?: OnboardingSignedUrl | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'signedUrlKey': value.signedUrlKey,
        'signedUrl': value.signedUrl,
    };
}


