import {Dispatch, ReactElement, SetStateAction} from "react";
import RestaurantSearchTaxonomy from "./RestaurantSearchTaxonomy";
import {RestaurantSearchFiltersQuery} from "@/utils/restaurantsSearchFilter";

interface Props {
    restaurantFilters: RestaurantSearchFiltersQuery;
    updateRestaurantFilters: Dispatch<SetStateAction<RestaurantSearchFiltersQuery>>;
}

function RestaurantSearchFilters(props: Props): ReactElement {

    return (
        <div className="restaurants-search-filters">
            <div className="restaurants-search-filters_inner">
                <RestaurantSearchTaxonomy
                    restaurantFilters={props.restaurantFilters}
                    updateRestaurantFilters={props.updateRestaurantFilters}
                />

                {/* <div className="restaurants-search-filters_buttons">*/}
                {/*	<RestaurantSearchDistance*/}
                {/*		restaurantFilters={props.restaurantFilters}*/}
                {/*		updateRestaurantFilters={props.updateRestaurantFilters}*/}
                {/*	/>*/}
                {/* </div>*/}
            </div>
        </div>
    );
}

export default RestaurantSearchFilters;
