import {ReactElement} from "react";
import {Helmet} from "react-helmet";
import {useGetSEOTags} from "@/hooks/useGetSEOTags";

export interface SEOProps {
    description?: string;
    url?: string;
    type?: string;
    title?: string;
    additionalKeywords?: string[];
}

function SEO(props: SEOProps): ReactElement {
    const {data: seoTagsResponse} = useGetSEOTags(props.url);
    const keywords = (seoTagsResponse?.tags || []).concat(props.additionalKeywords || []);

    return (
        <Helmet
            defaultTitle={import.meta.env.VITE_SEO_PROJECT_NAME}
            titleTemplate={"%s"}
        >
            <html lang="en"/>

            {/* defaults: */}
            {props.title && <title lang="en">{props.title}</title>}
            <meta name="description"
				  content={props.description
                    ? props.description
                    : import.meta.env.VITE_SEO_PROJECT_DESCRIPTION}/>
            <meta name="robots" content={"index, follow"}/>

            {/* og: */}
            <meta name="og:title" property={"og:title"}
				  content={props.title
                    ? props.title
                    : import.meta.env.VITE_SEO_PROJECT_NAME}/>
            <meta name="og:description" property={"og:description"}
				  content={props.description
                    ? props.description
                    : import.meta.env.VITE_SEO_PROJECT_DESCRIPTION}/>
            <meta property="og:image" name="og:image" content="https://cdn.devourgo.io/images/social-preview.png"/>
            {props.url && <meta property={"og:url"} content={props.url}/>}
            <meta property={"og:type"} content={props.type
                ? props.type
                : "website"}/>
            <meta property={"og:site_name"} content={import.meta.env.VITE_PROJECT_NAME}/>

            {/* twitter: */}
            <meta name="twitter:site" content="@GoDevour"/>
            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:title" content={props.title
                ? props.title
                : import.meta.env.VITE_SEO_PROJECT_NAME}/>
            <meta name="twitter:description"
				  content={props.description
                    ? props.description
                    : import.meta.env.VITE_SEO_PROJECT_DESCRIPTION}/>
            <meta name="twitter:image" content="https://cdn.devourgo.io/images/social-preview.png"/>

            {/* Additional info: */}
            {keywords && keywords.length > 0 && <meta name="keywords" content={keywords.join(", ")}/>}
        </Helmet>
    );
}

export default SEO;
