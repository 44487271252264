import {
    Asset,
    Merchant,
    StandardUser,
    UserType,
    Business,
    Restaurant,
    BusinessType,
    AddressBook,
    Address,
} from "@devour/client";
import {FileWithSRC} from "./renderAssetsHelper";

export function isAsset(asset: Asset | FileWithSRC): asset is Asset {
    return asset?.hasOwnProperty("redirectUrl");
}

export function isMerchant(user: Merchant | StandardUser): user is Merchant {
    return user?.type === UserType.MERCHANT;
}

export function isRestaurant(business: Business): business is Restaurant {
    return business?.type === BusinessType.RESTAURANT;
}

export function isAddressBook(address: AddressBook | Address): address is AddressBook {
    return "id" in address;
}
