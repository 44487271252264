import {FC, ReactElement, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {updateActiveGameModal} from "../redux/meta/metaActions";
import GoVipGameRewardsModal from "./modals/GoVipGameRewardsModal";
import {GoVipGameData} from "./goVip/goVipText";

interface Props {
    gameData: GoVipGameData;
    gameContainer: FC;
    requiredLevel: number;
}

function GameModalHandler(props: Props): ReactElement {

    const activeGame = useSelector((store: IStore) => store.metaStore.activeGameModal);
    const dispatch = useDispatch();

    const [
        showModal,
        setShowModal,
    ] = useState(false);

    useEffect(() => {
        if (activeGame === props.gameData.game) {
            setShowModal(true);
        } else {
            setShowModal(false);
        }

    }, [activeGame]);

    function toggleModal(): void {
        dispatch(updateActiveGameModal(""));
    }

    return (
        <GoVipGameRewardsModal
            isOpen={showModal}
            onClose={toggleModal}
            gameData={props.gameData}
            gameContainer={props.gameContainer}
            requiredLevel={props.requiredLevel}
        />
    );
}

export default GameModalHandler;
