/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ReferralRelationship,
    ReferralRelationshipFromJSON,
    ReferralRelationshipFromJSONTyped,
    ReferralRelationshipToJSON,
    RelationshipType,
    RelationshipTypeFromJSON,
    RelationshipTypeFromJSONTyped,
    RelationshipTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface ReferredUserData
 */
export interface ReferredUserData {
    /**
     * 
     * @type {string}
     * @memberof ReferredUserData
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof ReferredUserData
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof ReferredUserData
     */
    email: string;
    /**
     * 
     * @type {number}
     * @memberof ReferredUserData
     */
    createdAt: number;
    /**
     * 
     * @type {number}
     * @memberof ReferredUserData
     */
    userCreatedAt: number;
    /**
     * 
     * @type {string}
     * @memberof ReferredUserData
     */
    referralId: string;
    /**
     * 
     * @type {boolean}
     * @memberof ReferredUserData
     */
    isFirstReferral: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReferredUserData
     */
    isRegistrationReferral: boolean;
    /**
     * 
     * @type {RelationshipType}
     * @memberof ReferredUserData
     */
    relationshipType?: RelationshipType;
    /**
     * 
     * @type {Array<ReferralRelationship>}
     * @memberof ReferredUserData
     */
    referralHistory: Array<ReferralRelationship>;
}

export function ReferredUserDataFromJSON(json: any): ReferredUserData {
    return ReferredUserDataFromJSONTyped(json, false);
}

export function ReferredUserDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReferredUserData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'email': json['email'],
        'createdAt': json['createdAt'],
        'userCreatedAt': json['userCreatedAt'],
        'referralId': json['referralId'],
        'isFirstReferral': json['isFirstReferral'],
        'isRegistrationReferral': json['isRegistrationReferral'],
        'relationshipType': !exists(json, 'relationshipType') ? undefined : RelationshipTypeFromJSON(json['relationshipType']),
        'referralHistory': ((json['referralHistory'] as Array<any>).map(ReferralRelationshipFromJSON)),
    };
}

export function ReferredUserDataToJSON(value?: ReferredUserData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firstName': value.firstName,
        'lastName': value.lastName,
        'email': value.email,
        'createdAt': value.createdAt,
        'userCreatedAt': value.userCreatedAt,
        'referralId': value.referralId,
        'isFirstReferral': value.isFirstReferral,
        'isRegistrationReferral': value.isRegistrationReferral,
        'relationshipType': RelationshipTypeToJSON(value.relationshipType),
        'referralHistory': ((value.referralHistory as Array<any>).map(ReferralRelationshipToJSON)),
    };
}


