/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
    TimeStamps,
    TimeStampsFromJSON,
    TimeStampsFromJSONTyped,
    TimeStampsToJSON,
} from './';

/**
 * Used to save a single message sent between customers and support.
 * @export
 * @interface ChatMessage
 */
export interface ChatMessage {
    /**
     * 
     * @type {string}
     * @memberof ChatMessage
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof ChatMessage
     */
    createdAt: number;
    /**
     * 
     * @type {number}
     * @memberof ChatMessage
     */
    updatedAt: number;
    /**
     * 
     * @type {string}
     * @memberof ChatMessage
     */
    sender?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatMessage
     */
    senderName: string;
    /**
     * 
     * @type {string}
     * @memberof ChatMessage
     */
    menuOrder?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatMessage
     */
    message: string;
    /**
     * 
     * @type {boolean}
     * @memberof ChatMessage
     */
    isSupportAgent: boolean;
}

export function ChatMessageFromJSON(json: any): ChatMessage {
    return ChatMessageFromJSONTyped(json, false);
}

export function ChatMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChatMessage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'sender': !exists(json, 'sender') ? undefined : json['sender'],
        'senderName': json['senderName'],
        'menuOrder': !exists(json, 'menuOrder') ? undefined : json['menuOrder'],
        'message': json['message'],
        'isSupportAgent': json['isSupportAgent'],
    };
}

export function ChatMessageToJSON(value?: ChatMessage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'sender': value.sender,
        'senderName': value.senderName,
        'menuOrder': value.menuOrder,
        'message': value.message,
        'isSupportAgent': value.isSupportAgent,
    };
}


